import { useRef, useEffect, useState } from 'react';
import ContentLoading from 'core/page/contentLoading';
import { screenSMin } from '../styles';

const TableLoading = () => {
  const tableElement = useRef(null);
  const [loaderWidth, setLoaderWidth] = useState(0);
  const paddingLeft = window.innerWidth < screenSMin ? 20 : 40;
  const cellPaddingLeft = 40;
  let barWidth = 0;
  let buttonBarWidth = 0;
  if (loaderWidth) {
    barWidth = window.innerWidth < screenSMin ? loaderWidth - paddingLeft : (loaderWidth - paddingLeft) / 4.5;
    buttonBarWidth = barWidth / 2;
  }
  const columnStart1 = paddingLeft;
  const columnStart2 = columnStart1 + barWidth + cellPaddingLeft;
  const columnStart3 = columnStart2 + barWidth + cellPaddingLeft;
  const columnStart4 = columnStart3 + barWidth + cellPaddingLeft;
  const columnStart5 = columnStart4 + buttonBarWidth + 8;

  useEffect(() => {
    setLoaderWidth(tableElement.current.parentElement.clientWidth - paddingLeft);
  }, [tableElement, paddingLeft]);

  return (
    <div ref={tableElement}>
      <ContentLoading height={200} width={loaderWidth}>
        {window.innerWidth < screenSMin ? (
          <>
            <rect x="20" width={barWidth} height="20" rx="6" />
            <rect x="20" y="24" width={barWidth} height="20" rx="6" />
            <rect x="20" y="60" width="119" height="16" rx="6" />
            <rect x="165" y="65" width="15" height="3" rx="1.5" />
            <rect x="20" y="116" width={barWidth} height="20" rx="6" />
            <rect x="20" y="140" width={barWidth} height="20" rx="6" />
            <rect x="20" y="176" width="119" height="16" rx="6" />
            <rect x="165" y="182" width="15" height="3" rx="1.5" />
          </>
        ) : (
          <>
            <rect x={columnStart1} width={barWidth} height="20" rx="6" />
            <rect x={columnStart1} y="56" width={barWidth} height="20" rx="6" />
            <rect x={columnStart1} y="112" width={barWidth} height="20" rx="6" />
            <rect x={columnStart1} y="168" width={barWidth} height="20" rx="6" />
            <rect x={columnStart2} width={barWidth} height="20" rx="6" />
            <rect x={columnStart2} y="56" width={barWidth} height="20" rx="6" />
            <rect x={columnStart2} y="112" width={barWidth} height="20" rx="6" />
            <rect x={columnStart2} y="168" width={barWidth} height="20" rx="6" />
            <rect x={columnStart3} width={barWidth} height="20" rx="6" />
            <rect x={columnStart3} y="56" width={barWidth} height="20" rx="6" />
            <rect x={columnStart3} y="112" width={barWidth} height="20" rx="6" />
            <rect x={columnStart3} y="168" width={barWidth} height="20" rx="6" />
            <rect x={columnStart4} y="58" width={buttonBarWidth} height="16" rx="6" />
            <rect x={columnStart5} y="63" width="15" height="3" rx="1.5" />
            <rect x={columnStart4} y="114" width={buttonBarWidth} height="16" rx="6" />
            <rect x={columnStart5} y="120" width="15" height="3" rx="1.5" />
            <rect x={columnStart4} y="170" width={buttonBarWidth} height="16" rx="6" />
            <rect x={columnStart5} y="176" width="15" height="3" rx="1.5" />
          </>
        )}
      </ContentLoading>
    </div>
  );
};

export default TableLoading;
