import PropTypes from 'prop-types';
import TextField from './text';

/**
 * Decimal field with label and errors.
 *
 * TODO add other props as necessary (className, placeholder, etc.)
 */
const DecimalField = ({ label, name, step, ...props }) => (
  <TextField label={label} name={name} type="number" step={step} {...props} />
);

DecimalField.defaultProps = {
  step: '.01',
  label: '',
};

DecimalField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  step: PropTypes.string,
};

export default DecimalField;
