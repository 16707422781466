import { snakeCase, camelCase } from 'lodash';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';

import axios from 'core/axios';
import { MonetaryField, SubmitButton, TextField, FieldErrors } from 'core/forms/fields';
import DocumentUpload from 'media/documents/upload';
import { DocumentModel } from 'media/models';
import { handleTransactionCreation } from 'core/page/transactionSigning/utils';


const PaymentTokenCreate = ({ companyId, initialCurrency }) => {

  const onSubmit = (values, actions) => {
    const data = new FormData();
    const { date, documentType, subject, files, ...restValues } = values;
    Object.entries(restValues).forEach(([key, value]) => {
      data.append(snakeCase(key), value);
    });
    data.append('legal_entity_id', companyId);
    if (files.length) {
      data.append('document.date', date);
      data.append('document.subject', subject);
      data.append('document.doc_type', documentType);
      files.forEach((file, index) => {
        data.append(`document.new_files[${index}]`, file);
      });
    }

    axios.post(`/api/v1/company/${companyId}/payment-token-create/`, data)
      .then(
        ({ data: { tx_hash: txHash } }) => {
          handleTransactionCreation(txHash, `/company/${companyId}/payment-tokens/`);
        },
        ({ response: { data: errors } }) => {
          Object.keys(errors).forEach((key) => {
            if (key === 'document') {
              Object.keys(errors[key]).forEach((docKey) => {
                actions.setFieldError(camelCase(docKey), errors[key][docKey].join(' '));
              });
            } else {
              actions.setFieldError(camelCase(key), errors[key].join(' '));
            }
          });
        },
      )
      .catch(console.error)
      .finally(() => { actions.setSubmitting(false); });
  };

  return (
    <Formik
      initialValues={{
        nonFieldErrors: '',
        name: '',
        initialAmount: 0,
        currencyCode: '',
        issuingPriceValue: (0).toFixed(2),
        peggedCurrencyCode: initialCurrency,
        ...DocumentModel.uploadInitialValues,
      }}
      validationSchema={Yup.object({
        name: Yup.string().required(gettext('This field is required')),
        initialAmount: Yup.number().moreThan(0.01, gettext('Must be above 0')).required(gettext('This field is required')),
        currencyCode: Yup.string().max(5, gettext('Line is too long')).required(gettext('This field is required')),
        issuingPriceValue: Yup.number().positive(gettext('Must be above 0')).required(gettext('This field is required')),
        peggedCurrencyCode: Yup.string().max(3, gettext('Line is too long')).required(gettext('This field is required')),
      }).concat(DocumentModel.simpleUploadValidation)}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <FieldErrors name="nonFieldErrors" />
          <TextField label={gettext('Name')} name="name" />
          <TextField label={gettext('Currency')} name="currencyCode" />
          <TextField label={gettext('Number of tokens')} name="initialAmount" type="number" />
          <MonetaryField
            label={gettext('Issuing price')}
            currencyName="peggedCurrencyCode"
            valueName="issuingPriceValue"
          />
          <DocumentUpload />
          <SubmitButton disabled={isSubmitting}>{gettext('Create')}</SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

PaymentTokenCreate.defaultProps = {
  initialCurrency: '',
};

PaymentTokenCreate.propTypes = {
  companyId: PropTypes.string.isRequired,
  initialCurrency: PropTypes.string,
};


export default PaymentTokenCreate;
