/* eslint-disable no-promise-executor-return */
/* eslint-disable no-underscore-dangle */
import { extendObservable } from 'mobx';
import pick from 'lodash/pick';
import find from 'lodash/find';
import axios from 'core/axios';
import { handleTransactionCreation } from 'core/page/transactionSigning/utils';
import { DocumentModel } from './document';
import { Model } from './model';


export default class ConsortiumMembershipModel extends Model {

  constructor({ membershipType = null, companyId = null, industry = null }) {
    let documentType = 'FU';
    if (membershipType === 'consortium-member') {
      documentType = 'CMA';
    } else if (membershipType === 'validator') {
      documentType = 'VA';
    }
    super();
    extendObservable(this, {
      membershipType,
      companyId,
      industry,
      hasIndustry: industry && industry !== 'None',
      agreementGiven: false,
      document: new DocumentModel({
        defaultDocumentType: documentType,
        documentObject: {
          documentObjectId: companyId,
          documentObjectType: 'consortium_membership',
        },
      }),
    });

    this.validateIndustry = this.validateIndustry.bind(this);
    this.validateMembershipAgreement = this.validateMembershipAgreement.bind(this);
    this.getAgreementText = this.getAgreementText.bind(this);
    this.submit = this.submit.bind(this);

  }

  get isLoading() {
    return this._isLoading || this.document.isLoading;
  }


  static get documentFormFields() {
    return ['documentId'];
  }

  static get fieldMapping() {
    return [
      { name: 'membershipType', serverName: 'membership_type' },
      { name: 'companyId', serverName: 'company_id' },
      { name: 'industry', serverName: 'industry' },
      { name: 'agreementGiven', serverName: 'agreement_given' },
      { name: 'documentId', serverName: 'document_id' },
    ];
  }

  static get agreementFormFields() {
    return ['agreementGiven'];
  }

  static get formFields() {
    return ['membershipType', 'companyId'];
  }

  static get industryFormFields() {
    return ['industry'];
  }

  get documentId() {
    return this.document.id;
  }

  // Industry

  get hasIndustryErrors() {
    return !!Object.keys(pick(this.errors, this.constructor.industryFormFields)).length;
  }

  validateIndustry = () => {
    const data = {};
    this.constructor.industryFormFields.forEach(
      (key) => {
        const mappedKey = find(this.constructor.fieldMapping, (o) => o.name === key);
        data[mappedKey.serverName] = this[mappedKey.name];
      },
    );

    this.cleanErrors();
    this.setLoading(true);
    return axios.post(`/api/v1/company/${this.companyId}/blockchain/membership/validations/industry/`, data)
      .then(
        () => { },
        ({ response: { data: errors } }) => { this.setResponseErrors(errors); },
      )
      .catch(console.error)
      .finally(() => { this.setLoading(false); });
  };

  // Agreement

  get hasAgreementErrors() {
    return !!Object.keys(pick(this.errors, this.constructor.agreementFormFields)).length;
  }

  validateMembershipAgreement = () => {
    const data = {};

    this.constructor.agreementFormFields.forEach(
      (key) => {
        const mappedKey = find(this.constructor.fieldMapping, (o) => o.name === key);
        data[mappedKey.serverName] = this[mappedKey.name];
      },
    );

    this.cleanErrors();

    this.setLoading(true);
    return axios.post(`/api/v1/company/${this.companyId}/blockchain/membership/validations/agreement/`, data)
      .then(
        () => {},
        ({ response: { data: errors } }) => { this.setResponseErrors(errors); },
      )
      .catch(console.error)
      .finally(() => { this.setLoading(false); });
  };

  getAgreementText() {
    const data = {};

    data.type = this.membershipType;

    return axios.get(
      `/api/v1/company/${this.companyId}/blockchain/membership/agreement-text/`,
      { params: data },
    );
  }

  // Documents

  get hasDocumentErrors() {
    return !!Object.keys(pick(this.errors, this.constructor.documentFormFields)).length;
  }

  // Validate a document and submitting form
  submit = () => new Promise((resolve, _reject) => {
    if (this.document.documentFiles.length) {
      resolve();
    } else {
      return (this._setFieldErrors('non_field_errors', [gettext('Document is required')]));
    }
    return '';
  }).then(
    () => {
      this.document.upload().then(
        () => {
          const data = {};
          this.constructor.documentFormFields.forEach(
            (key) => {
              const mappedKey = find(this.constructor.fieldMapping, (o) => o.name === key);
              data[mappedKey.serverName] = this[mappedKey.name];
            },
          );
          this.cleanErrors();

          this.setLoading(true);
          return axios.post(`/api/v1/company/${this.companyId}/registration/validations/documents/`, data)
            .then(
              () => { },
              ({ response: { data: errors } }) => { this.setResponseErrors(errors); },
            )
            .catch(console.error)
            .finally(() => { this.setLoading(false); });
        },
      ).then(() => {
        const data = {};
        const fields = [
          ...this.constructor.formFields,
          ...this.constructor.agreementFormFields,
          ...this.constructor.documentFormFields,
          ...this.constructor.industryFormFields,
        ];

        fields.forEach(
          (key) => {
            const mappedKey = find(this.constructor.fieldMapping, (o) => o.name === key);
            data[mappedKey.serverName] = this[mappedKey.name];
          },
        );

        this.cleanErrors();

        this.setLoading(true);
        return axios.post(`/api/v1/company/${this.companyId}/blockchain/membership/submit-application/`, data)
          .then(
            ({ data: { redirect_url: redirectUrl, tx_hash: txHash } }) => {
              handleTransactionCreation(txHash, redirectUrl);
            },
            ({ response: { data: errors } }) => { this.setResponseErrors(errors); },
          )
          .catch(console.error)
          .finally(() => { this.setLoading(false); });
      });
    },
  );
}
