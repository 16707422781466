/**
 * Detail of issuer registry.
 */

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'core/axios';
import List from 'core/lists/list';


const IssuerRegistryDetail = ({ companyId, issuerRegistryId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [issuersList, setIssuersList] = useState([]);
  const [itemDetails, setItemDetails] = useState({});

  useEffect(() => {
    const url = `/api/v1/company/${companyId}/certificates/issuer-registries/${issuerRegistryId}/`;
    axios.get(url).then(({ data }) => { setItemDetails(data); }).catch(console.error);
  }, [companyId, issuerRegistryId]);

  useEffect(() => {
    setIsLoading(true);
    const url = `/api/v1/company/${companyId}/certificates/issuer-registries/${issuerRegistryId}/issuers/`;
    axios.get(url)
      .then(({ data }) => { setIssuersList(data); })
      .catch(console.error)
      .finally(() => { setIsLoading(false); });
  }, [companyId, issuerRegistryId]);

  return (
    <>
      <span className="row-flex padded-y">
        <span className="title no-overflow">
          {gettext('Name')}
        </span>
        <span className="font-bold">{itemDetails.name}</span>
      </span>
      <section>
        <header className="row-flex not-padded top-margin underline">
          <h3 className="title font-size-16px">{gettext('Issuers')}</h3>
        </header>
        <List
          collection={issuersList}
          isLoading={isLoading}
          loadingText={gettext('Loading issuers...')}
          emptyText={gettext('No issuers')}
          rowTitle={(item) => item.name}
          rowLink={(item) => `/company/${companyId}/certificates/issuer-registries/${issuerRegistryId}/issuers/${item.id}/`}
        />
      </section>
    </>
  );
};

IssuerRegistryDetail.defaultProps = {
  companyId: null,
};

IssuerRegistryDetail.propTypes = {
  companyId: PropTypes.string,
  issuerRegistryId: PropTypes.string.isRequired,
};

export default IssuerRegistryDetail;
