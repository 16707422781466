import { convertKeysToCamelCase } from 'core/utils';

/**
 * Pricing Transactions Model
 *
 * Props under restProps are: 'id', 'name', 'invoiceStatus', 'invoiceNumber'
 */
export default class PricingTransactionsModel {
  constructor({ effectiveAt, amount, ...restProps }) {
    this.datetime = new Date(effectiveAt);
    this.price = amount;
    Object.entries(restProps).forEach(([fieldName, fieldValue]) => {
      this[fieldName] = fieldValue;
    });
  }

  static fromResponse(data) {
    const convertedCase = convertKeysToCamelCase(data);
    return new PricingTransactionsModel(Object.fromEntries(
      Object.entries(convertedCase).filter(([_, v]) => (v != null)), // remove null values before converting to model
    ));
  }

  static fromArrayResponse(data) {
    return data.map((doc) => this.fromResponse(doc));
  }
}
