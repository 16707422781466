import { getApiUrl } from 'utils/urls';
import { baseCertificatesTypePath } from 'certificates/consts';
import { isNativeAppWebview } from 'utils/general';
import axios from 'core/axios';
import { QueryKeyProp } from 'core/types';
import {
  CertificateTypeDetailModel,
  CertificateTypeListModel,
  CertificateTypeRegistriesModel,
  CertificateTypeRegistryIssuersModel,
} from '../models';

export const getCertificateTypes = (): Promise<CertificateTypeListModel[]> => (
  axios
    .get(getApiUrl(baseCertificatesTypePath))
    .then(({ data }) => CertificateTypeListModel.fromResponse(data))
    .catch(console.error)
);

export const getCertificateDetails = (certificateTypeId: string): Promise<CertificateTypeDetailModel> => (
  axios
    .get(getApiUrl(`${baseCertificatesTypePath}${certificateTypeId}/`))
    .then(({ data }) => CertificateTypeDetailModel.fromResponse(data))
    .catch(console.error)
);

export const getCertificateRegistries = ({ queryKey }: QueryKeyProp): Promise<CertificateTypeRegistriesModel[]> => {
  const [, certificateTypeId] = queryKey;

  return axios
    .get(getApiUrl(`/certificates/${certificateTypeId}/issuer-registries/`))
    .then(({ data }) => CertificateTypeRegistriesModel.fromResponse(data))
    .catch(console.error);
};

export const getRegistries = (): Promise<CertificateTypeRegistriesModel[]> => axios
  .get(getApiUrl('/certificates/issuer-registries/'))
  .then(({ data }) => CertificateTypeRegistriesModel.fromResponse(data))
  .catch(console.error);

export const getCertificateRegistry = (issuerRegistryId: string): Promise<CertificateTypeRegistriesModel> => (
  axios
    .get(getApiUrl(`/certificates/issuer-registries/${issuerRegistryId}/`))
    .then(({ data }) => CertificateTypeRegistriesModel.fromResponse(data))
    .catch(console.error)
);

export const getCertificateRegistryIssuers = (issuerRegistryId: string): Promise<CertificateTypeRegistryIssuersModel[]> => (
  axios
    .get(getApiUrl(`/certificates/issuer-registries/${issuerRegistryId}/issuers/`))
    .then(({ data }) => CertificateTypeRegistryIssuersModel.fromResponse(data))
    .catch(console.error)
);

export const onFileDownload = (
  certificateTypeId: string,
  nativeUrl: string,
) => {
  const downloadUrl = getApiUrl(`${baseCertificatesTypePath}${certificateTypeId}/file-download/`);
  window.location.href = isNativeAppWebview ? nativeUrl : downloadUrl;
};
