import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { parseErrorResponse } from 'core/forms/utils';
import {
  CheckboxField,
  ReactSelectField,
  SubmitButton,
  TextField,
  FieldErrors,
  DateField,
  RatioField,
} from 'core/forms/fields';
import { getApiUrl, getUrl } from 'utils/urls';
import { convertKeysToCamelCase } from 'core/utils';
import { DocumentModel } from 'media/models';
import axios from 'core/axios';
import { dateToday } from 'utils/date';
import SwitchableDocumentUpload from 'media/documents/switchableUpload';
import { icons } from 'core/icons';
import { handleTransactionCreation } from 'core/page/transactionSigning/utils';
import { tokenTypes, RVR_EQUITY, getTokenType } from './constants';

import TokenCreateBase from './tokenCreateBase';


const TokenConvertForm = ({ tokenId }) => {
  const [token, setToken] = useState({
    name: '',
    contractType: '',
    representsEquity: true,
    isRestricted: false,
    totalAmount: 0,
    nominalValue: 0,
    currency: '',
  });
  const [previewQueryArgs, setPreviewQueryArgs] = useState({ currency: token.currency });
  const [tokenType, setTokenType] = useState('');
  const baseUrl = getUrl('/liabilities/equity/');
  const apiBaseUrl = getApiUrl(`/tokens/${tokenId}/`);

  useEffect(() => {
    axios.get(apiBaseUrl)
      .then(({ data }) => { setToken(convertKeysToCamelCase(data)); })
      .catch(console.error);
  }, [apiBaseUrl]);

  useEffect(() => {
    const oldTokenType = getTokenType(token.representsEquity);
    setTokenType(oldTokenType.id);
  }, [token.representsEquity]);

  const onSubmit = (values, actions) => {
    actions.setSubmitting(true);
    const data = new FormData();
    data.append('name', values.name);
    data.append('currency', values.currency);
    data.append('initial_amount', values.initialAmount);
    data.append('event_date', values.eventDate);
    data.append('nominal_value', values.nominalValue);
    data.append('conversion_ratio', values.conversionRatio.join('/'));
    data.append('document_type', values.documentType);
    values.files.forEach((file) => {
      data.append('files', file);
    });
    data.append('date', values.date);
    data.append('subject', values.subject);
    data.append('represents_equity', values.tokenType === RVR_EQUITY);
    if (values.tokenType === RVR_EQUITY) {
      data.append('is_restricted', values.isRestricted);
      data.append('capital', values.capital);
    }
    axios.post(`${apiBaseUrl}convert/`, data)
      .then(
        ({ data: { tx_hash: txHash } }) => {
          handleTransactionCreation(txHash, baseUrl);
        },
        ({ response: { data: errors } }) => { parseErrorResponse(errors, actions.setFieldError); },
      )
      .catch(console.error)
      .finally(() => { actions.setSubmitting(false); });
  };

  return (
    <TokenCreateBase
      pageHeading={gettext(`Convert ${token.name}`)}
      backUrl={`${baseUrl}${tokenId}/`}
      previewQueryArgs={previewQueryArgs}
    >
      <Formik
        initialValues={{
          nonFieldErrors: '',
          name: '',
          tokenType,
          isRestricted: token.isRestricted,
          initialAmount: token.totalAmount,
          nominalValue: token.nominalValue,
          currency: token.currency,
          capital: token.totalAmount * token.nominalValue,
          eventDate: dateToday(),
          conversionRatio: [1, 1],
          ...DocumentModel.uploadInitialValues,
        }}
        enableReinitialize
        validationSchema={Yup.object({
          tokenType: Yup.string().max(4, gettext('Line is too long')).required(gettext('This field is required')),
          isRestricted: Yup.boolean(),
          name: Yup.string().required(gettext('This field is required')),
          initialAmount: Yup.number()
            .when('tokenType', {
              is: tokenType === RVR_EQUITY,
              then: Yup.number().integer(gettext('Must be integer')),
              otherwise: Yup.number(),
            }).min(0, gettext('Must be above 0')).required(gettext('This field is required')),
          nominalValue: Yup.number()
            .when('tokenType', {
              is: tokenType === RVR_EQUITY,
              then: Yup.number().moreThan(0.01, gettext('Must be above 0')).required(gettext('This field is required')),
              otherwise: Yup.number(),
            }),
          currency: Yup.string().max(3, gettext('Line is too long')).required(gettext('This field is required')),
          capital: Yup.number()
            .when('tokenType', {
              is: tokenType === RVR_EQUITY,
              then: Yup.number().min(0, gettext('Must be above 0')).required(gettext('This field is required')),
              otherwise: Yup.number(),
            }),
          eventDate: Yup.date(),
          conversionRatio: Yup.array().of(
            Yup.number().positive(gettext('Must be above 0')).integer(gettext('Must be integer')),
          ).required(gettext('This field is required')),
        }).concat(DocumentModel.simpleUploadValidation)}
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, isSubmitting }) => {
          if (values.name !== previewQueryArgs.name) {
            setPreviewQueryArgs({ ...previewQueryArgs, name: values.name });
          }
          if (values.initialAmount !== previewQueryArgs.amount) {
            setPreviewQueryArgs({ ...previewQueryArgs, amount: values.initialAmount });
          }
          if (values.nominalValue !== previewQueryArgs.nominal_value) {
            setPreviewQueryArgs({ ...previewQueryArgs, nominal_value: values.nominalValue });
          }
          if (values.currency !== previewQueryArgs.currency) {
            setPreviewQueryArgs({ ...previewQueryArgs, currency: values.currency });
          }
          return (
            <Form>
              <div className="form-sections">
                <div className="form-section">
                  <div className="form-section-header">
                    <h4 className="header-with-icon">
                      <span className="icon">{icons.info}</span>
                      {gettext('Properties')}
                    </h4>
                  </div>
                  <div className="form-section-content">
                    <FieldErrors name="nonFieldErrors" />
                    <TextField label={gettext('Name of new financial instrument')} name="name" />
                    <ReactSelectField
                      label={gettext('Share type')}
                      name="tokenType"
                      getOptionLabel={(option) => option.text}
                      getOptionValue={(option) => option.id}
                      options={tokenTypes}
                      isOptionDisabled={(option) => option.disabled}
                      setValue={(option) => setTokenType(option.id)}
                    />
                    {tokenType === RVR_EQUITY && <CheckboxField name="isRestricted" label={gettext('Restricted')} />}
                    <DateField label={gettext('Conversion date')} name="eventDate" />
                  </div>
                </div>
                <div className="form-section">
                  <div className="form-section-header">
                    <h4 className="header-with-icon">
                      <span className="icon">{icons.exchange}</span>
                      {gettext('Conversion Ratio')}
                    </h4>
                  </div>
                  <div className="form-section-content">
                    <RatioField
                      name="conversionRatio"
                      subLabels={[gettext('New shares'), gettext('Old shares')]}
                      disabled={[false, true]}
                      separator={gettext('for')}
                      onChange={(newValues) => {
                        const newInitAmount = (token.totalAmount * newValues[0]) / newValues[1];
                        setFieldValue('initialAmount', newInitAmount);
                        setFieldValue('capital', newInitAmount * values.nominalValue);
                      }}
                    />
                  </div>
                </div>
                <div className="form-section">
                  <div className="form-section-header">
                    <h4 className="header-with-icon">
                      <span className="icon">{icons.chart}</span>
                      {gettext('Shares')}
                    </h4>
                  </div>
                  <div className="form-section-content section-overview-data">
                    <div>
                      <div className="label">{gettext('Number of shares')}</div>
                      <div className="number">{values.initialAmount}</div>
                    </div>
                    <div>
                      <div className="label">{gettext('Nominal value')}</div>
                      <span className="number">{`${values.nominalValue} `}</span>
                      <span className="currency">{values.currency}</span>
                    </div>
                    {tokenType === RVR_EQUITY && (
                      <div>
                        <div className="label">{gettext('Share capital')}</div>
                        <span className="number">{`${values.capital} `}</span>
                        <span className="currency">{values.currency}</span>
                      </div>
                    )}
                  </div>
                </div>
                <SwitchableDocumentUpload />
              </div>
              <div className="form-action">
                <SubmitButton disabled={isSubmitting}>{gettext('Create')}</SubmitButton>
              </div>
            </Form>
          );
        }}
      </Formik>
    </TokenCreateBase>
  );
};


TokenConvertForm.propTypes = {
  tokenId: PropTypes.string.isRequired,
};


export default TokenConvertForm;
