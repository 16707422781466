import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { BoardMemberDetailView } from '@trustwise/design-system';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import { getBoardMemberDetails } from './actions';

export const BoardMemberDetails = () => {
  const { companyId, memberId } = useParams();
  if (!companyId || !memberId) {
    throw new Error('Url parameters companyId and memberId are required');
  }

  const { data: boardMember } = useQuery({
    queryKey: ['board_member', companyId, memberId],
    queryFn: () => getBoardMemberDetails(companyId, memberId),
  });

  return (
    <PageContent
      fullscreen
      pageHeading={boardMember?.name || ''}
      headerLeft={<BackButton href={`/${companyId}/members/`} useRouterLink />}
    >
      <BoardMemberDetailView id={Number(memberId)} {...boardMember} />
    </PageContent>
  );
};
