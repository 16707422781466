import { DRAFT as INFORMATION_DRAFT, SENT as INFORMATION_SENT } from '../entityMessages/const';

export const BOARD = 'board';
export const SHAREHOLDERS = 'shareholders';

export const EventTypes = {
  BOARD_MEETING: 'board_meeting',
  BOARD_RESOLUTION: 'board_resolution',
  BOARD_INFORMATION: 'board_information',
  SHAREHOLDERS_MEETING: 'shareholders_meeting',
  SHAREHOLDERS_RESOLUTION: 'shareholders_resolution',
  SHAREHOLDERS_INFORMATION: 'shareholders_information',
};

export const EVENT_TYPES_LABELS = {
  [EventTypes.BOARD_MEETING]: gettext('Board Meeting'),
  [EventTypes.BOARD_RESOLUTION]: gettext('Board Resolution'),
  [EventTypes.BOARD_INFORMATION]: gettext('Board Information'),
  [EventTypes.SHAREHOLDERS_MEETING]: gettext('Shareholders Meeting'),
  [EventTypes.SHAREHOLDERS_RESOLUTION]: gettext('Shareholders Resolution'),
  [EventTypes.SHAREHOLDERS_INFORMATION]: gettext('Shareholders Information'),
};

export const GROUP_INFORMATION_TYPES = [EventTypes.BOARD_INFORMATION, EventTypes.SHAREHOLDERS_INFORMATION];

export const IN_PREPARATION = 'in_preparation';
export const INVITATION_SENT = 'invitation_sent';
export const ONGOING = 'ongoing';
export const CLOSED = 'closed';
export const MINUTES_SIGNING_REQUESTED = 'minutes_signing_requested';
export const MINUTES_SIGNED = 'minutes_signed';
export const MINUTES_SENT = 'minutes_sent';
export const RESULTS_SENT = 'results_sent';
export const CANCELLED = 'cancelled';

export const EVENT_STATUSES = {
  [IN_PREPARATION]: gettext('In Preparation'),
  [INVITATION_SENT]: gettext('Invitation Sent'),
  [ONGOING]: gettext('Ongoing'),
  [CLOSED]: gettext('Closed'),
  [MINUTES_SIGNING_REQUESTED]: gettext('Minutes Signing Requested'),
  [MINUTES_SIGNED]: gettext('Minutes Signed'),
  [MINUTES_SENT]: gettext('Minutes Sent'),
  [RESULTS_SENT]: gettext('Results Sent'),
  [CANCELLED]: gettext('Cancelled'),
};

export const IN_PREPARATION_GROUP = 'in_preparation';
export const ONGOING_GROUP = 'ongoing';
export const UPCOMING_GROUP = 'upcoming';
export const PAST_GROUP = 'past';

export const EVENT_STATUS_GROUPS = {
  [IN_PREPARATION_GROUP]: [IN_PREPARATION, INFORMATION_DRAFT],
  [ONGOING_GROUP]: [ONGOING],
  [UPCOMING_GROUP]: [INVITATION_SENT],
  [PAST_GROUP]: [CANCELLED, CLOSED, MINUTES_SIGNING_REQUESTED, MINUTES_SIGNED, MINUTES_SENT, RESULTS_SENT, INFORMATION_SENT],
};

export const EVENT_LOCATIONS = {
  seat: gettext('Seat of the company'),
  custom: gettext('Custom'),
};

export const EVENT_LANGUAGES = {
  en: gettext('English'),
  de: gettext('German'),
  fr: gettext('French'),
  it: gettext('Italian'),
};

export const EVENTS_BY_PRICING_ACTIONS = {
  'board-meeting-closed': EventTypes.BOARD_MEETING,
  'board-resolution-closed': EventTypes.BOARD_RESOLUTION,
  'board-information-sent': EventTypes.BOARD_INFORMATION,
  'shareholders-meeting-closed': EventTypes.SHAREHOLDERS_MEETING,
  'shareholders-resolution-closed': EventTypes.SHAREHOLDERS_RESOLUTION,
  'shareholders-information-sent': EventTypes.SHAREHOLDERS_INFORMATION,
};

export const BOARD_EVENTS = [EventTypes.BOARD_MEETING, EventTypes.BOARD_RESOLUTION, EventTypes.BOARD_INFORMATION];
export const SHAREHOLDERS_EVENTS = [
  EventTypes.SHAREHOLDERS_MEETING, EventTypes.SHAREHOLDERS_RESOLUTION, EventTypes.SHAREHOLDERS_INFORMATION,
];

export const EVENTS_BY_GROUP = {
  [BOARD]: BOARD_EVENTS,
  [SHAREHOLDERS]: SHAREHOLDERS_EVENTS,
};
