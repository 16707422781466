import axios from 'axios';
import { useCallback, useEffect, useReducer } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { UtilityTokenDetailView as UtilityTokenDetail } from '@trustwise/design-system';
import { getApiUrl } from 'utils/urls';
import { getGlobalContext } from 'core/globals';
import { getTimeSeriesQueryArgs } from 'utils/getTimeSeriesQueryArgs';
import { PageContent } from 'core/page';
import { redirectTo } from 'core/utils';
import { onFileDownload } from 'media/utils';
import { useAbortController, useResponseStatus } from 'core/hooks';
import BackButton from 'core/page/parts/backButton';
import reducer from '../reducers';

const UtilityTokenDetailView = () => {
  const [responseStatus] = useResponseStatus();
  const [state, dispatch] = useReducer(reducer, {
    balanceData: {},
    inCirculationData: {},
  });
  const { token, balanceData, inCirculationData, transactions } = state;

  const [, updateInCirculationController] = useAbortController();
  const [, updateBalanceController] = useAbortController();

  const { activeEntity: { companyPortfolioId } } = getGlobalContext();
  const navigate = useNavigate();
  const { tokenId } = useParams();

  const basePath = `/utility-tokens/${tokenId}/`;

  useEffect(() => {
    axios.get(getApiUrl(`${basePath}in-circulation/`))
      .then(({ data: { value } }) => {
        dispatch({ type: 'fetchInCirculation', data: { amount: Number(value) } });
      })
      .catch(console.error);
  }, [basePath]);

  useEffect(() => {
    axios.get(getApiUrl(`${basePath}balance/`))
      .then(({ data: { value } }) => {
        dispatch({ type: 'fetchBalance', data: { amount: Number(value) } });
      })
      .catch(console.error);
  }, [basePath]);

  useEffect(() => {
    axios
      .get(getApiUrl(`${basePath}events/`))
      .then(({ data }) => dispatch({ type: 'fetchTransactions', data }))
      .catch(console.error);
  }, [basePath]);

  useEffect(() => {
    axios.get(getApiUrl(basePath))
      .then(({ data }) => dispatch({ type: 'fetchToken', data }))
      .catch(console.error);
  }, [basePath]);

  const onInCirculationFetch = useCallback((tab = '1y') => {
    const signal = updateInCirculationController();
    const queryArgs = getTimeSeriesQueryArgs(tab);
    dispatch({ type: 'resetInCirculation' });

    axios.get(getApiUrl(`${basePath}summary-minted-burned/`, queryArgs), { signal })
      .then(({ data: { time_series: timeSeriesRecords } }) => {
        const timeSeries = timeSeriesRecords.map(({ minted, burned, date_time: dateTime }) => ({
          minted: Number(minted),
          burned: Number(burned),
          dateTime: new Date(dateTime),
        }));

        dispatch({ type: 'fetchInCirculation', data: { timeSeries } });
      })
      .catch(console.error);
  }, [updateInCirculationController, basePath]);

  const onBalanceFetch = useCallback((tab = '1y') => {
    const signal = updateBalanceController();
    const queryArgs = getTimeSeriesQueryArgs(tab);
    dispatch({ type: 'resetBalance' });

    axios.get(getApiUrl(`${basePath}liquidity/`, queryArgs), { signal })
      .then(({ data: { time_series: timeSeriesRecords } }) => {
        const timeSeries = timeSeriesRecords.map(
          ({ balance: recordBalance, date_time: dateTime }) => ({
            balance: Number(recordBalance),
            dateTime: new Date(dateTime),
          }),
        );
        dispatch({ type: 'fetchBalance', data: { timeSeries } });
      })
      .catch(console.error);
  }, [updateBalanceController, basePath]);

  useEffect(() => {
    onBalanceFetch();
  }, [onBalanceFetch]);

  useEffect(() => {
    onInCirculationFetch();
  }, [onInCirculationFetch]);

  return (
    <PageContent
      fullscreen
      pageStatus={responseStatus}
      pageHeading={token ? token.name : ''}
      headerLeft={<BackButton onClick={() => navigate('/')} />}
    >
      <UtilityTokenDetail
        details={token}
        transactions={transactions}
        balanceChart={{ ...balanceData, onFetch: onBalanceFetch }}
        inCirculationChart={{ ...inCirculationData, onFetch: onInCirculationFetch }}
        onIssue={() => navigate('mint/', { state: { token } })}
        onSeeUnderAssets={() => redirectTo(`/assets/utility/${companyPortfolioId}/${tokenId}/`)}
        onFileDownload={(fileId, documents) => onFileDownload(
          fileId,
          documents,
          `${basePath}events/`,
        )}
      />
    </PageContent>
  );
};

export default UtilityTokenDetailView;
