/* Media constants */

export const imageTypes = ['image/jpeg', 'image/png'];
export const iconNames = [
  { id: 'avi', text: 'video' },
  { id: 'doc', text: 'word' },
  { id: 'docx', text: 'word' },
  { id: 'jpeg', text: 'image' },
  { id: 'jpg', text: 'image' },
  { id: 'mp3', text: 'audio' },
  { id: 'mp4', text: 'video' },
  { id: 'mpeg', text: 'video' },
  { id: 'pdf', text: 'pdf' },
  { id: 'png', text: 'image' },
  { id: 'ppt', text: 'powerpoint' },
  { id: 'pptx', text: 'powerpoint' },
  { id: 'rar', text: 'archive' },
  { id: 'rtf', text: 'alt' },
  { id: 'txt', text: 'alt' },
  { id: 'vsd', text: 'image' },
  { id: 'xls', text: 'excel' },
  { id: 'xlsx', text: 'excel' },
  { id: 'zip', text: 'archive' },
  { id: 'video/quicktime', text: 'video' },
  { id: 'application/mp4', text: 'video' },
  { id: 'application/msword', text: 'word' },
  { id: 'application/pdf', text: 'pdf' },
  { id: 'application/rtf', text: 'alt' },
  { id: 'application/vnd.ms-excel', text: 'excel' },
  { id: 'application/vnd.ms-powerpoint', text: 'powerpoint' },
  { id: 'application/vnd.openxmlformats-officedocument.presentationml.presentation', text: 'powerpoint' },
  { id: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', text: 'excel' },
  { id: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', text: 'word' },
  { id: 'application/vnd.rar', text: 'archive' },
  { id: 'application/zip', text: 'archive' },
  { id: 'audio/mpeg', text: 'audio' },
  { id: 'image/jpeg', text: 'image' },
  { id: 'image/png', text: 'image' },
  { id: 'text/calendar', text: 'alt' },
  { id: 'text/plain', text: 'alt' },
  { id: 'video/mpeg', text: 'video' },
  { id: 'video/x-msvideo', text: 'video' },
];

export const mediaTypes = [
  { id: 'application/mp4', text: gettext('MP4') },
  { id: 'application/msword', text: gettext('Microsoft Word') },
  { id: 'application/pdf', text: gettext('PDF') },
  { id: 'application/rtf', text: gettext('RTF') },
  { id: 'application/vnd.ms-excel', text: gettext('Microsoft Excel') },
  { id: 'application/vnd.ms-powerpoint', text: gettext('Microsoft PowerPoint') },
  { id: 'application/vnd.openxmlformats-officedocument.presentationml.presentation', text: gettext('Microsoft PowerPoint') },
  { id: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', text: gettext('Microsoft Excel') },
  { id: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', text: gettext('Microsoft Word') },
  { id: 'application/vnd.rar', text: gettext('RAR') },
  { id: 'application/vnd.visio', text: gettext('Microsoft Visio') },
  { id: 'application/zip', text: gettext('ZIP') },
  { id: 'audio/mpeg', text: gettext('MP3') },
  { id: 'image/jpeg', text: gettext('JPEG') },
  { id: 'image/png', text: gettext('PNG') },
  { id: 'text/calendar', text: gettext('iCalendar') },
  { id: 'text/plain', text: gettext('TXT') },
  { id: 'video/mpeg', text: gettext('MPEG') },
  { id: 'video/x-msvideo', text: gettext('AVI') },
  { id: 'text/csv', text: gettext('CSV') },
];
