import { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer, PropTypes as MobXPropTypes } from 'mobx-react';
import Link from '../common/link';
import { Icon } from '../common/icon';
import CloseButton from '../common/closeButton';
import AccountsSection from './accountsSection';
import { Model } from '../../models/model';

class SidebarContent extends Component {
  onCloseClick = (e) => {
    const { accountSwitcherStore: { setOpen } } = this.props;
    setOpen(false);
    e.preventDefault();
  };

  render() {
    const { collection, withLogout } = this.props;
    const emptyAccount = new Model();
    const isVerifiedPerson = collection.personal.length === 1 && !collection.personal[0].pending;

    emptyAccount.fromJSON({
      name: gettext('Add new'),
      link: '/registration/company/',
      initials: '+',
      color: '',
      class: 'empty-account',
    });

    return (
      <div className="account-switcher">
        <div className="account-switcher-header">
          <h3 className="no-overflow">{gettext('Accounts')}</h3>
          <CloseButton onClick={this.onCloseClick} />
        </div>

        <AccountsSection title={gettext('Personal')} collection={collection.personal} />

        {!!collection.delegated.length && (
          <AccountsSection title={gettext('Delegated')} collection={collection.delegated} />
        )}

        {!!collection.favourites.length && (
          <AccountsSection title={gettext('Favourites')} collection={collection.favourites} />
        )}

        {isVerifiedPerson && <AccountsSection title={gettext('Businesses')} collection={[...collection.company, emptyAccount]} />}

        {withLogout && (
          <div className="large-padding top-margin centered">
            <Link href={`${window.location.protocol}//${window.location.host}/logout_login/`}>
              {gettext('Logout')}
              &nbsp;
              <Icon name="power-off" />
            </Link>
          </div>
        )}
      </div>
    );
  }
}

SidebarContent.defaultProps = {
  withLogout: true,
};

SidebarContent.propTypes = {
  collection: MobXPropTypes.objectOrObservableObject.isRequired,
  withLogout: PropTypes.bool,
  accountSwitcherStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

export default inject('accountSwitcherStore')(observer(SidebarContent));
