import { registriesIcons } from 'core/svgs';
import convertResponseToModel from 'utils/responseToModel';
import { RegistryMemberModel } from './registryMember';

// If ever needed somewhere else in the code base, please move to a consts file in the registries folder
const RegistriesChoices = {
  artworks_curators: {
    label: gettext('Artworks Curators Registry'),
    description: gettext('Artworks Curators'),
    icon: registriesIcons.artworks_curator,
  },
  certificates_issuers: {
    label: gettext('Certificates Issuers Registry'),
    description: gettext('Certificates Issuers'),
    icon: registriesIcons.certificates_issuers,
  },
  marketplace: {
    label: gettext('Marketplace'),
    description: gettext('Marketplace'),
    icon: registriesIcons.marketplace,
  },
};

/**
 * Registries List Item Model
 *
 * Props under restProps are:
 * 'id', 'name', 'address
 */
export class RegistriesListItemModel {
  constructor({
    membersCount, registryType, ...restProps
  }) {
    this.noOfMembers = membersCount;
    this.type = registryType;
    Object.entries(RegistriesChoices[registryType]).forEach(([key, value]) => {
      this[key] = value;
    });
    Object.entries(restProps).forEach(([fieldName, fieldValue]) => {
      this[fieldName] = fieldValue;
    });
  }
}

/**
 * Registry Detail Model
 *
 * Props under restProps are:
 * 'name', 'address
 */
export class RegistryDetailModel {
  constructor({
    members, registryType, ...restProps
  }) {
    this.members = convertResponseToModel(members, RegistryMemberModel);
    this.label = RegistriesChoices[registryType].label;
    this.icon = RegistriesChoices[registryType].icon;
    Object.entries(restProps).forEach(([fieldName, fieldValue]) => {
      this[fieldName] = fieldValue;
    });
  }
}
