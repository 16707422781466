const Quill = require('quill');

const arrayOfEditors = [];

export const textEditor = {

  init() {
    if (document.querySelector('.textEditor')) {
      this.registerModule();
      this.fillEditor();
      this.registerAction();
    }
  },

  registerModule() {
    $('.textEditor').each((i, _obj) => {
      // initializing of Quill text editor
      const editor = new Quill(`.textEditorContent-${i}`, {
        modules: {
          toolbar: [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
          ],
        },
        theme: 'snow',
      });
      const hiddenInput = $(`.textEditorContent-${i}`).prevUntil('input[type=hidden]').prev();
      arrayOfEditors.push([editor, hiddenInput]);
    });
  },

  registerAction() {
    // Populate hidden input on submit
    const form = $('form').has('div.textEditorContent-0')[0];
    form.onsubmit = () => {
      $(arrayOfEditors).each((i, obj) => {
        const editorHtml = $(
          obj[0].root.innerHTML
            .replace(/>\s+</g, '>&nbsp;<')
            .replace('<br>', '&nbsp;')
            .replace('<p>&nbsp;</p>', ''),
        );
        // eslint-disable-next-line no-param-reassign
        obj[1][0].value = $('<div>').append(editorHtml).html();
      });
      return true;
    };
  },

  fillEditor() {
    // Fill text editor from hidden input
    $(arrayOfEditors).each((i, obj) => {
      const hiddenInput = obj[1];
      if (hiddenInput[0] && 'value' in hiddenInput[0]) {
        obj[0].clipboard.dangerouslyPasteHTML(hiddenInput[0].value);
      }
    });
  },
};

export default textEditor;
