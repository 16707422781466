import axios from 'core/axios';
import { getApiUrl } from 'utils/urls';

export const onObjectsSort = (path, column, order, setObjects) => {
  const orderBy = column === 'has_co_ownership' ? 'token' : column;

  return axios.get(getApiUrl(path, { order, order_by: orderBy }))
    .then(({ data }) => setObjects(data))
    .catch(console.error);
};
