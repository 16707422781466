import PropTypes from 'prop-types';
import { Formik, Form, ErrorMessage } from 'formik';
import { ModalWithContent as Modal } from '@trustwise/design-system';
import { SubmitButton } from 'core/forms/fields';
import { parseErrorResponse } from 'core/forms/utils';
import axios from 'core/axios';
import FormErrors from 'components/common/formikErrors';


const DeleteWhitelist = ({ isOpen, onClose, listName, listDetailUrl, companyId }) => {
  const onSubmit = (values, actions) => {
    axios.delete(listDetailUrl, { name: values.name })
      .then(
        () => { window.location = `/company/${companyId}/ownership/potential-shareholders/`; },
        ({ response: { data: errors } }) => { parseErrorResponse(errors, actions.setFieldError); },
      )
      .catch(console.error)
      .finally(() => { actions.setSubmitting(false); });
  };

  return (
    <Modal
      id="deleteList"
      isOpen={isOpen}
      onClose={onClose}
      title={gettext('Delete whitelist')}
    >
      <Formik
        initialValues={{ nonFieldErrors: '' }}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <ErrorMessage component={FormErrors} name="nonFieldErrors" />
            {gettext(`Are you sure you want to delete ${listName} list?`)}
            <SubmitButton disabled={isSubmitting}>{gettext('Delete')}</SubmitButton>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

DeleteWhitelist.defaultProps = {
  listName: '',
  listDetailUrl: '',
  isOpen: false,
};


DeleteWhitelist.propTypes = {
  companyId: PropTypes.string.isRequired,
  listName: PropTypes.string,
  listDetailUrl: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default DeleteWhitelist;
