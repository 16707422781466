/* List component for subscribed certificates */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';

import { BasicRow } from 'core/lists/rows/basicRow';
import { PageContent } from 'core/page';
import axios from 'core/axios';
import ContentLoadingMessage from 'utils/contentLoadingMessage';
import { toMonetaryValue, txHashResponseHandler } from 'core/utils';
import { getNativeFileUrl } from 'media/utils';
import { isNativeAppWebview } from 'utils/general';
import BackButton from 'core/page/parts/backButton';


const SubscriptionCertificatesList = ({ companyId }) => {
  const [subscriptionCertificates, setSubscriptionCertificates] = useState([]);
  const [subscription, setSubscription] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [pageMessages, setPageMessages] = useState([]);
  const { pathname } = useLocation();
  const { subscriptionId } = useParams();
  const baseUrl = `/api/v1/company/${companyId}/subscriptions/${subscriptionId}/`;
  const timestampNow = new Date() / 1000;

  useEffect(() => {
    axios.get(`${baseUrl}subscription-certificates/`)
      .then(({ data }) => { setSubscriptionCertificates(data); })
      .catch(console.error)
      .finally(() => { setIsLoading(false); });
  }, [baseUrl]);

  useEffect(() => {
    axios.get(`${baseUrl}`)
      .then(({ data }) => { setSubscription(data); })
      .catch(console.error);
  }, [baseUrl]);

  const actions = [];

  if (
    (
      subscription.total_subscribed
      && (
        timestampNow >= subscription.end_timestamp
        || (subscription.max_number_of_shares && subscription.total_subscribed === subscription.max_number_of_shares)
      )
    )
    && subscription.status === 'created'
  ) {
    actions.push({
      type: 'form',
      icon: 'project-diagram',
      label: gettext('Allot & notify subscribers'),
      onClick: () => {
        axios.post(`${baseUrl}subscription-certificates/allot/`)
          .then(({ data }) => { txHashResponseHandler(data, window.location.pathname); })
          .catch(console.error);
      },
    });
  }

  if (
    ['subscriptions_allotted', 'selecting_subscriptions'].includes(subscription.status)
    && timestampNow >= subscription.closing_timestamp
  ) {
    actions.push({
      url: `${pathname}edit-final-subscriptions/`,
      icon: 'clipboard-list',
      label: (
        subscription.status === 'subscriptions_allotted'
          ? gettext('Create list of final subscriptions')
          : gettext('Edit list of final subscriptions')
      ),
    });
  }

  if (subscription.status === 'selecting_subscriptions') {
    actions.push({
      type: 'form',
      icon: 'paper-plane',
      label: gettext('Finalize selection'),
      onClick: () => {
        axios.post(`${baseUrl}subscription-certificates/finalize-selection/`)
          .then(
            ({ data }) => { txHashResponseHandler(data, window.location.pathname); },
            ({ response: { data: errors } }) => { setPageMessages(errors); },
          )
          .catch(console.error);
      },
    });
  }

  if (['subscriptions_selected', 'attested', 'distributed'].includes(subscription.status)) {
    const subscriptionsPdfUrl = `${baseUrl}subscription-certificates/subscriptions-pdf/`;
    const pdfUrl = isNativeAppWebview ? getNativeFileUrl(
      subscriptionsPdfUrl,
      gettext('Subscriptions PDF'),
      'application/pdf',
    ) : subscriptionsPdfUrl;
    actions.push({
      useRouterLink: false,
      url: pdfUrl,
      icon: 'file-pdf',
      label: gettext('Subscriptions PDF'),
    });
  }

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Subscription Certificates')}
      headerLeft={<BackButton href={`/${subscriptionId}/`} useRouterLink />}
      actions={actions}
      pageMessages={pageMessages.map((message) => (
        <li className="error" key={message}>
          {message}
        </li>
      ))}
    >
      {Object.entries(subscriptionCertificates).length > 0 ? (
        <ol className="padded-top">
          {subscriptionCertificates.map((subscriptionCertificate) => (
            <li key={subscriptionCertificate.id} className="card-base">
              <BasicRow
                className="not-padded"
                title={subscriptionCertificate.subscriber.name}
                value={subscriptionCertificate.reference_number || ''}
              />
              <BasicRow
                className="padded-top"
                title={gettext('Number of subscription certificates')}
                value={subscriptionCertificate.no_of_shares}
              />
              <BasicRow
                className="padded-top"
                title={
                    // eslint-disable-next-line no-nested-ternary
                    subscription.status === 'created'
                      ? gettext('Estimated payment amount')
                      : subscriptionCertificate.is_selected_subscriber ? gettext('Capital paid') : gettext('Payment due')
                  }
                value={toMonetaryValue(
                  subscription.currency,
                  (subscriptionCertificate.no_of_shares * subscription.price_per_share).toFixed(2),
                )}
              />
              <BasicRow
                className="padded-top"
                title={gettext('Power of attorney granted')}
                value={subscriptionCertificate.power_of_attorney ? gettext('Yes') : gettext('No')}
              />
            </li>
          ))}
        </ol>
      ) : (
        <ContentLoadingMessage
          isLoading={isLoading}
          loadedMessage={gettext('No subscription certificates available')}
        />
      )}
    </PageContent>
  );
};

SubscriptionCertificatesList.propTypes = {
  companyId: PropTypes.string.isRequired,
};

export default SubscriptionCertificatesList;
