import { Component } from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import { observer, PropTypes as MobxPropTypes } from 'mobx-react';
import { action } from 'mobx';

class FXRatesGraphComponent extends Component {
  elementId = 'daily-rates-graph';

  updateGraph = action(() => {
    const root = $(`#${this.elementId}`);
    root.html('');
    this.drawChart(root.width());
  });

  drawChart = action((width) => {
    const { data } = this.props;

    const height = 300;
    const sortedData = data.sortBy((o) => o.date);

    const margin = {
      top: 10, right: 0, bottom: 30, left: 0,
    };

    const svg = d3.select(`#${this.elementId}`).append('svg').attr('width', '100%').attr('height', height); // create root svg

    // func for scaling the data on x axis
    const xScale = d3.scaleTime()
      .range([margin.left, width - margin.right])
      .domain([d3.min(sortedData, (d) => d.date), d3.max(sortedData, (d) => d.date)]);

    // func for scaling the data on y axis
    const yScale = d3.scaleLinear()
      .domain([d3.min(sortedData, (d) => d.value), d3.max(sortedData, (d) => d.value)])
      .range([height - margin.bottom, margin.top]);

    // create area
    const area = d3.area()
      .x((d) => xScale(d.date))
      .y0(height)
      .y1((d) => yScale(d.value));

    // Divides date for tooltip placement
    const bisectDate = d3.bisector((d) => d.date).left;

    // create linear gradient to fill the area
    svg.append('linearGradient')
      .attr('id', 'fx-gradient')
      .attr('gradientUnits', 'userSpaceOnUse')
      .attr('x1', 0)
      .attr('y1', yScale(d3.min(sortedData, (d) => d.value)))
      .attr('x2', 0)
      .attr('y2', yScale(d3.max(sortedData, (d) => d.value)))
      .selectAll('stop')
      .data([
        { offset: '0%', color: '#ffffff' },
        { offset: '50%', color: '#e6f1fc' },
        { offset: '100%', color: '#b8d6f4' },
      ])
      .enter()
      .append('stop')
      .attr('offset', (d) => d.offset)
      .attr('stop-color', (d) => d.color);

    // add bold line on top of the graph
    svg.append('path')
      .datum(sortedData)
      .attr('class', 'graph')
      .attr('fill', '#cce5df')
      .attr('stroke', '#0978e7')
      .attr('stroke-width', 3)
      .attr('d', d3.area()
        .x((d) => xScale(d.date))
        .y((d) => yScale(d.value)));

    // attach area to the graph
    svg.append('path')
      .datum(sortedData)
      .attr('class', 'area')
      .attr('d', area);

    // create focus element to represent the current point on the graph
    const focus = svg.append('g')
      .attr('class', 'focus')
      .style('display', 'none');

    // Adds circle to focus point on line
    focus.append('circle')
      .attr('r', 6)
      .attr('stroke', '#fff')
      .attr('stroke-width', '4')
      .attr('fill', '#0978e7');

    // append root element for drawing the tooltip elements inside
    const line = svg.append('g');

    // append blur in to the line as background for values
    const blur = line.append('rect')
      .attr('width', 50)
      .attr('height', 50)
      .attr('x', 0)
      .attr('y', 0)
      .style('fill', '#fff')
      .attr('filter', 'url(#blur)')
      .style('display', 'none');

    // add element for holding the text of value tooltip
    const valueToolTipText = line.append('text')
      .attr('y', 20)
      .attr('x', 9)
      .style('font-size', '0.75rem')
      .style('fill', '#666');


    // add separator between value and date on tooltip
    const separatorLine = line.append('line')
      .attr('x1', 0)
      .attr('y1', 27)
      .attr('x2', 60)
      .attr('y2', 27)
      .style('stroke-width', 1)
      .style('stroke', '#666')
      .style('opacity', 0.2)
      .style('fill', 'none')
      .style('display', 'none');

    // add date tooltip
    const dateToolTipText = line.append('text')
      .attr('y', 45)
      .attr('x', 9)
      .style('font-size', '0.75rem')
      .style('fill', '#666');

    // add blur to background
    line.append('defs')
      .append('filter')
      .attr('id', 'blur')
      .append('feGaussianBlur')
      .attr('stdDeviation', 8);

    // draw line for current selected value
    const verticalLine = line.append('line')
      .attr('x1', 0)
      .attr('y1', yScale(d3.max(sortedData, (o) => o.value)))
      .attr('x2', 0)
      .attr('y2', yScale(d3.min(sortedData, (o) => o.value)))
      .style('stroke-width', 1)
      .style('stroke', '#666')
      .style('fill', 'none')
      .style('display', 'none');

    // format date depending on whether there is new year in the set
    const tooltipDate = (date, showYear = false) => {
      if (!showYear) {
        return date.toLocaleString(undefined, { day: 'numeric', month: 'short' });
      }
      return date.toLocaleString(undefined, { day: 'numeric', month: 'short', year: '2-digit' });
    };

    // Tooltip mouseover
    function mousemove() {
      const x0 = xScale.invert(d3.mouse(this)[0]);
      const i = bisectDate(sortedData, x0, 1);
      const d0 = sortedData[i - 1];
      const d1 = sortedData[i];
      const d = x0 - d0.date > d1.date - x0 ? d1 : d0;

      const transition = d3.transition().ease(d3.easeLinear, 0);

      // depending on how close the position to the right side,
      // move the tooltip to the left of vertical line in case necessary
      if (width - xScale(d.date) <= 100) {
        separatorLine
          .attr('x2', -50);
        valueToolTipText
          .attr('x', -50);
        dateToolTipText
          .attr('x', data.hasSeveralYears ? -70 : -50);
        blur.attr('x', -55);
      } else {
        separatorLine
          .attr('x2', 60);
        valueToolTipText
          .attr('x', 9);
        dateToolTipText
          .attr('x', 9);
        blur.attr('x', 0);
      }
      focus.transition(transition).attr('transform', `translate(${xScale(d.date)},${yScale(d.value)})`);
      line.transition(transition).attr('transform', `translate(${xScale(d.date)},0)`);
      valueToolTipText.text(d.formattedValue);
      dateToolTipText.text(tooltipDate(d.date, data.hasSeveralYears));
    }

    // Creates larger area for tooltip in order to listen to hover events
    svg.append('rect')
      .attr('class', 'tooltip-overlay')
      .attr('width', width)
      .attr('height', height)
      .style('opacity', 0)
      .on('mouseover', () => {
        focus.style('display', null);
        line.style('display', null);
        verticalLine.style('display', null);
        separatorLine.style('display', null);
        blur.style('display', null);
      })
      .on('mouseout', () => {
        focus.style('display', 'none');
        line.style('display', 'none');
      })
      .on('mousemove', mousemove);
  });

  componentDidMount() {
    window.addEventListener('resize', this.updateGraph);
    this.updateGraph();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateGraph);
  }

  render() {
    return <div id={this.elementId} />;
  }
}

FXRatesGraphComponent.propTypes = {
  data: PropTypes.oneOfType([
    MobxPropTypes.arrayOrObservableArray,
    MobxPropTypes.objectOrObservableObject,
  ]),
};

FXRatesGraphComponent.defaultProps = {
  data: {},
};

export default observer(FXRatesGraphComponent);
