import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

const sentryClient = Sentry;

const initSentry = () => {
  if (process.env && process.env.SENTRY_DSN) {
    return sentryClient.init({
      dsn: process.env.SENTRY_DSN,
      integrations: [new BrowserTracing()],
      tracesSampleRate: 1.0,
    });
  }
  return null;
};

const reportSentryError = (error, context = {}) => {
  if (process.env && process.env.SENTRY_DSN) {
    sentryClient.withScope((scope) => {
      Object.keys(context).forEach((key) => {
        scope.setExtra(key, context[key]);
      });
      sentryClient.captureException(error);
    });
  }
};

export { sentryClient, initSentry, reportSentryError };
