import find from 'lodash/find';
import { chain } from 'lodash';

import RateModel from 'models/markets/rate';
import { getDateRange } from 'utils/date';
import Collection from '../collection';

export default class RatesCollection extends Collection {
  constructor() {
    super();
    this.model = RateModel;
  }

  fromJSON(jsonResponse) {
    // eslint-disable-next-line camelcase
    const { rates, from_date, to_date } = jsonResponse;

    const fromDate = new Date(from_date);
    const toDate = new Date(to_date);

    rates.forEach((o) => {
      o.date = new Date(o.date);
    });

    const dateRange = getDateRange(fromDate, toDate);

    const Model = this.model;

    dateRange.forEach((date) => {
      const model = new Model();

      const sameDateRecord = find(rates, (o) => o.date.toDateString() === date.toDateString());
      if (sameDateRecord) {
        model.fromJSON(sameDateRecord);
        this.add(model);
      } else {
        const previousRecord = chain(rates)
          .filter((o) => (o.date - date) <= 0)
          .orderBy((o) => o.date - date)
          .last()
          .value();

        if (previousRecord) {
          const duplicatedRecord = { ...previousRecord };

          duplicatedRecord.date.setDate(date.getDate());
          duplicatedRecord.date.setMonth(date.getMonth());
          duplicatedRecord.date.setYear(date.getFullYear());

          model.fromJSON(duplicatedRecord);
          this.add(model);
        }
      }
    });
  }

  get latestRate() {
    const item = this.maxBy((o) => o.date);
    return item && item.value && item.value.toFixed(4);
  }

  get lowestRate() {
    const item = this.minBy((o) => o.value);
    return item && item.value && item.value.toFixed(4);
  }

  get highestRate() {
    const item = this.maxBy((o) => o.value);
    return item && item.value && item.value.toFixed(4);
  }

  get earliest() {
    return this.minBy((o) => o.date);
  }

  get latest() {
    return this.maxBy((o) => o.date);
  }

  get hasSeveralYears() {
    if (!this.length) return false;
    const firstYear = this.earliest.date.getFullYear();
    return this.some((o) => o.date.getFullYear() !== firstYear);
  }

  static get url() {
    return `${window.location.protocol}//${window.location.host}/api/v1/fx_rates/`;
  }
}
