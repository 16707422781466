import { useState, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import {
  Routes,
  Route,
} from 'react-router-dom';
import { getApiUrl } from 'utils/urls';
import axios from 'core/axios';
import DocumentsListComponent from './documents/list';
import DocumentDetailComponent from './documents/detail';
import AddSignatoryComponent from './signatories/add';
import FileDetailComponent from './file/detail';
import DocumentEditComponent from './documents/edit';
import DocumentsArchiveList from './documents/archiveList';
import reducer from './reducer';


const DocumentsRoutes = ({
  relatedObjectModel,
  relatedObjectId,
  isEditable,
  signatoriesEnabled,
  registrationEnabled,
  queryParams,
  isArchive,
  signatoryRoles,
  isNested,
}) => {
  const indexPath = isNested ? '/' : window.location.pathname.split('documents/')[0];
  const urlParams = new URLSearchParams(queryParams);
  relatedObjectModel && urlParams.set('related_object_model', relatedObjectModel);
  relatedObjectId && urlParams.set('related_object_id', relatedObjectId);
  const stringQueryParams = urlParams.toString() ? `?${urlParams.toString()}` : '';
  const ListComponent = isArchive ? DocumentsArchiveList : DocumentsListComponent;

  const [isLoading, setIsLoading] = useState(true);
  const [state, dispatch] = useReducer(reducer, {
    isEditable,
    registrationEnabled,
    signatoriesEnabled,
    queryParams: stringQueryParams,
    documents: {},
  });
  useEffect(() => {
    axios.get(getApiUrl('/new-documents/', stringQueryParams))
      .then(({ data }) => { dispatch({ data, type: 'fetch' }); })
      .catch(console.error)
      .finally(() => { setIsLoading(false); });
  }, [stringQueryParams]);

  return (
    <Routes>
      <Route
        path={indexPath}
        element={(
          <ListComponent
            state={state}
            dispatch={dispatch}
            isLoading={isLoading}
            fetchMoreData={() => {
              axios.get(state.nextPage)
                .then(({ data }) => { dispatch({ data, type: 'fetchNext' }); })
                .catch(console.error);
            }}
          />
        )}
      />
      <Route
        path={`${indexPath}documents/:documentId/`}
        location={{ search: stringQueryParams }}
        element={(
          <DocumentDetailComponent
            indexPath={indexPath}
            useIndexPath={isArchive}
            state={state}
            dispatch={dispatch}
          />
        )}
      />
      <Route
        path={`${indexPath}documents/:documentId/edit/`}
        location={{ search: stringQueryParams }}
        element={(
          <DocumentEditComponent
            isEditable={isEditable}
            state={state}
            dispatch={dispatch}
          />
        )}
      />
      <Route
        path={`${indexPath}documents/:documentId/attachments/:fileId/`}
        location={{ search: stringQueryParams }}
        element={(
          <FileDetailComponent
            state={state}
            dispatch={dispatch}
          />
        )}
      />
      <Route
        path={`${indexPath}documents/:documentId/signatories/add/`}
        location={{ search: stringQueryParams }}
        element={(
          <AddSignatoryComponent
            signatoriesEnabled={signatoriesEnabled}
            dispatch={dispatch}
            signatoryRoles={signatoryRoles}
          />
        )}
      />
    </Routes>
  );
};

DocumentsRoutes.defaultProps = {
  relatedObjectModel: '',
  relatedObjectId: null,
  queryParams: {},
  isEditable: false,
  signatoriesEnabled: false,
  registrationEnabled: false,
  isArchive: false,
  isNested: false,
  signatoryRoles: [
    { value: 'witness', label: gettext('Witness') },
    { value: 'signatory', label: gettext('Secretary') },
    { value: 'other', label: gettext('Other') },
  ],
};

DocumentsRoutes.propTypes = {
  relatedObjectModel: PropTypes.string,
  relatedObjectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  queryParams: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  isEditable: PropTypes.bool,
  signatoriesEnabled: PropTypes.bool,
  registrationEnabled: PropTypes.bool,
  isArchive: PropTypes.bool,
  isNested: PropTypes.bool,
  signatoryRoles: PropTypes.arrayOf(PropTypes.shape({})),
};

export default DocumentsRoutes;
