import { useEffect, useReducer } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ObjectTokenizationView } from '@trustwise/design-system';
import { getGlobalContext } from 'core/globals';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import useContacts from 'contacts/hooks';
import { fetchObject, onAgreementDownload, onTokenize } from './actions';
import reducer from '../reducers';

const RegisteredObjectTokenizationView = () => {
  const navigate = useNavigate();

  const { onFetch } = useContacts(false, { 'entity-type': 'person', 'exclude-custodial': 1 });
  const { catalogType, objectType, objectId } = useParams();
  const { activeEntity: { baseCurrency } } = getGlobalContext();

  const [state, dispatch] = useReducer(reducer, { registeredObject: {} });

  const { registeredObject } = state;
  const { details } = registeredObject.details || {};

  const basePath = `/${catalogType}/${objectType}/`;
  const objectPath = `/services/registered-objects${basePath}${objectId}/`;
  const objectDetailBasePath = `${basePath}${objectId}/`;

  useEffect(() => {
    fetchObject({ objectPath, dispatch });
  }, [objectPath]);

  if (registeredObject.hasCoOwnership) {
    throw new Error('Registered object already has co-ownership');
  }

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Create Co-Ownership Quotas')}
      headerLeft={<BackButton onClick={() => navigate(objectDetailBasePath)} />}
    >
      <ObjectTokenizationView
        onSubmit={(values, actions) => onTokenize(
          { values, actions, submitPath: `${objectPath}tokenize/`, navigatePath: objectPath },
        )}
        onContactsFetch={onFetch}
        onContactSearch={({ searchString }) => onFetch(searchString)}
        onAgreementDownload={(type) => onAgreementDownload(type, objectPath)}
        details={details}
        media={registeredObject.media}
        baseCurrency={baseCurrency}
      />
    </PageContent>
  );
};

export default RegisteredObjectTokenizationView;
