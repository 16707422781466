import { convertKeysToCamelCase } from 'core/utils';
import PricingPlanModel from './pricingPlan';
import { BaseProductOfferingModel } from './productOffering';


class SubscriptionPricingPlan extends PricingPlanModel {
  constructor({ offering, ...restProps }) {
    super(restProps);
    this.offering = new BaseProductOfferingModel(convertKeysToCamelCase(offering));
  }

}

export default class LegalEntityPricingPlanModel {
  constructor({
    id, pricingPlan, activeSince, activeUntil,
  }) {
    this.id = id;
    this.pricingPlan = new SubscriptionPricingPlan(convertKeysToCamelCase(pricingPlan));
    this.activeSince = new Date(activeSince);
    this.activeUntil = new Date(activeUntil);
  }

  get offering() {
    return this.pricingPlan.offering;
  }
}
