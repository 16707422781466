/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import { Component } from 'react';
import { observer } from 'mobx-react';

import UploadTemplate from './uploadTemplate';
import Button from '../common/button';
import Loader from '../common/loader';

class DocumentUploadComponent extends Component {
  get saveDocumentLabel() {
    const { saveDocumentLabel } = this.props;
    return saveDocumentLabel || gettext('Save');
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  _handleUpload = (e) => {
    const { handleUpload, form } = this.props;

    if (handleUpload) {
      handleUpload(e, form);
    }

    form.finalize();
  };

  _handleSave = (e) => {
    const { handleSave, form } = this.props;

    if (handleSave) {
      handleSave(e, form);
    }
    form.upload();
  };

  render() {
    const {
      showSaveDocumentButton,
      form,
      isRequired,
    } = this.props;

    if (!form) return null;

    return (
      <Loader isLoading={form.isLoading}>
        <UploadTemplate
          model={form}
          isRequired={isRequired}
        />
        {showSaveDocumentButton
          ? (
            <Button
              label={this.saveDocumentLabel}
              altText={gettext('Save')}
              onClick={() => this._handleSave()}
            />
          ) : null}
      </Loader>
    );
  }
}

DocumentUploadComponent.defaultProps = {
  showSaveDocumentButton: true,
  isRequired: true,
  saveDocumentLabel: gettext('Saves'),
};

export default observer(DocumentUploadComponent);
