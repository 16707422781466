import { DataRoomPermissionType } from '@trustwise/design-system';
import { BaseLegalEntityWithID } from 'contacts/interfaces/types';
import { Model } from 'core/model';
import { SimpleLegalEntityModel } from 'contacts/models/legalEntity';

interface BaseDataRoomPermissionItemModelProps {
  /**
   * Data room permission type
   */
  permissionType: DataRoomPermissionType;
  /**
   * Indicates whether data room permission is revoked
   */
  isRevoked: boolean;
}

interface DataRoomPermissionItemModelProps extends BaseDataRoomPermissionItemModelProps {
  /**
   * Date time in which data room permission was granted
   */
  grantedAt: string;
  /**
   * Date time in which data room permission was revoked
   */
  revokedAt?: string;
  /**
   * Legal entity with the given data room permission
   */
  legalEntity: BaseLegalEntityWithID;
}

export interface IDataRoomPermissionItemModelProps extends BaseDataRoomPermissionItemModelProps {
  /**
   * Date time in which data room permission was granted
   */
  grantedAt: Date;
  /**
   * Date time in which data room permission was revoked
   */
  revokedAt?: Date;
  /**
   * Legal entity with the given data room permission
   */
  legalEntity: SimpleLegalEntityModel;
}

export class DataRoomPermissionItemModel extends Model implements IDataRoomPermissionItemModelProps {
  permissionType: DataRoomPermissionType;
  legalEntity: SimpleLegalEntityModel;
  grantedAt: Date;
  revokedAt?: Date;
  isRevoked: boolean;

  constructor({
    permissionType, legalEntity, grantedAt, revokedAt, ...restProps
  }: DataRoomPermissionItemModelProps) {
    super(restProps);
    this.permissionType = permissionType;
    this.legalEntity = SimpleLegalEntityModel.fromResponse(legalEntity);
    this.grantedAt = new Date(grantedAt);
    this.revokedAt = revokedAt ? new Date(revokedAt) : undefined;
  }
}
