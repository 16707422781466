import { snakeCase } from 'lodash';

import axios from 'core/axios';
import { getApiUrl } from 'utils/urls';
import { TriggerAllowanceStatus } from './types';
import { TriggerAllowanceStatuses, TriggerAllowanceStatusMessages } from './consts';

export interface GetTriggersStatusProps<T = Record<string, string>> {
  triggersKeys: string[];
  props?: T;
}

export const getTriggersStatus = <T extends Record<string, string>>({
  triggersKeys,
  ...props
}: GetTriggersStatusProps<T>): Promise<TriggerAllowanceStatus> => {
  const urlParams = new URLSearchParams();
  triggersKeys.forEach((key) => urlParams.append('triggers', key));
  Object.entries(props).forEach(([key, value]) => urlParams.append(snakeCase(key), value as unknown as string));

  return axios.get(getApiUrl('/pricing/triggers-allowance/', urlParams)).then(({ data: { status } }) => status);
};

export const getTriggerStatusMessage = (status?: TriggerAllowanceStatus) => {
  if (!status) {
    return undefined;
  }
  if (status === TriggerAllowanceStatuses.ALLOWED) {
    return '';
  }
  return TriggerAllowanceStatusMessages[status];
};
