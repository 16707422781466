/**
 * A generic filter form.
 */

import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { TextField, BaseButton } from 'core/forms/fields';
import { icons } from 'core/icons';


const SearchForm = ({ onSubmit, onCancel, placeholder, initialValue }) => (
  <Formik
    initialValues={{ searchString: initialValue }}
    validationSchema={Yup.object({ searchString: Yup.string() })}
    onSubmit={onSubmit}
    enableReinitialize
  >
    {({ setFieldValue, submitForm }) => (
      // @todo review utility styles used when the old form styles are gone
      <Form className="not-padded no-margin">
        <div className="search-box">
          <div className="input-icon">{icons.search}</div>
          <TextField type="search" className="search-input" onBlur={submitForm} name="searchString" placeholder={placeholder} />
        </div>
        {onCancel && (
        <BaseButton
          onClick={() => {
            setFieldValue('searchString', '');
            submitForm();
            onCancel();
          }}
        >
          {gettext('Cancel')}
        </BaseButton>
        )}
      </Form>
    )}
  </Formik>
);


SearchForm.defaultProps = {
  placeholder: gettext('Search'),
  onCancel: null,
  initialValue: '',
};

SearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  placeholder: PropTypes.string,
  initialValue: PropTypes.string,
};

export default SearchForm;
