import { observable } from 'mobx';

class TokenTransferStore {
  form;

  constructor() {
    this.form = observable({});
  }
}

export const tokenTransferStore = new TokenTransferStore();
export { TokenTransferStore };
