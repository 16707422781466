import { useState, useEffect } from 'react';

import { OwnershipWidget } from '@trustwise/design-system';
import { getApiUrl, getUrl } from 'utils/urls';
import axios from 'core/axios';
import processChartData from '../core/charts/utils';


const ShareholdersWidget = () => {
  const [totalTokens, setTotalTokens] = useState(undefined);
  const [totalShareholders, setTotalShareholders] = useState(null);
  const [chartData, setChartData] = useState({
    values: undefined,
    labels: undefined,
    descriptions: undefined,
  });

  useEffect(() => {
    axios.get(getApiUrl('/ownership/shareholders-chart-data/'))
      .then(({ data }) => {
        setTotalShareholders(data.values.length);
        setChartData(processChartData(data));
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    axios.get(getApiUrl('/ownership/total-tokens/'))
      .then(({ data }) => { setTotalTokens(data); })
      .catch(console.error);
  }, []);

  return (
    <OwnershipWidget
      sharesValue={totalTokens}
      onManage={() => { window.location = getUrl('/ownership/shareholders/'); }}
      shareholderCount={totalShareholders}
      {...chartData}
    />
  );
};

export default ShareholdersWidget;
