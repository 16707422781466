import { useEffect, useState } from 'react';
import { useMatch, useParams } from 'react-router-dom';
import { PageContent } from 'core/page';
import axios from 'core/axios';
import { txHashResponseHandler, convertKeysToCamelCase } from 'core/utils';
import { getApiUrl, getUrl } from 'utils/urls';
import ContentLoadingMessage from 'utils/contentLoadingMessage';
import { Button } from 'core/forms/fields';
import MintageEventDateForm from './parts/mintageEventDateForm';


const SubscriptionDistribute = () => {
  const [batchesInfo, setBatchesInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [showMintageReasonDateModal, setShowMintageReasonDateModal] = useState(false);
  const { url } = useMatch();

  const { subscriptionId } = useParams();
  const lastBatchRedirect = getUrl(`/liabilities/equity/subscriptions/${subscriptionId}/`);

  useEffect(() => {
    axios.get(getApiUrl(`/subscriptions/${subscriptionId}/batches-info/`))
      .then(({ data }) => { setBatchesInfo(convertKeysToCamelCase(data)); })
      .catch(console.error)
      .finally(() => { setIsLoading(false); });
  }, [subscriptionId]);

  let batchStart = 1;
  let batchEnd = batchesInfo.maxBatchSize;
  const lastBatchSize = batchesInfo.totalSubscribers % batchesInfo.maxBatchSize || batchesInfo.maxBatchSize;
  const noOfBatches = Math.ceil(batchesInfo.totalSubscribers / batchesInfo.maxBatchSize);
  const batchDistribute = [];
  for (let index = 0; index < noOfBatches; index++) {
    batchDistribute.push({
      batchStart,
      batchEnd,
      isLast: index + 1 === noOfBatches,
    });
    batchStart += batchesInfo.maxBatchSize;
    batchEnd += index + 2 === noOfBatches ? lastBatchSize : batchesInfo.maxBatchSize;
  }

  const currentBatch = batchDistribute.filter((batch) => batchesInfo.totalMintagesInitialized + 1 === batch.batchStart)[0];
  const batchSize = currentBatch && currentBatch.isLast ? lastBatchSize : batchesInfo.maxBatchSize;
  const redirectUrl = currentBatch && currentBatch.isLast ? lastBatchRedirect : url;

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Distribute shares')}
    >
      {batchDistribute.length > 0 ? (
        <>
          <p className="text-tiny">
            {gettext(
              `Due to block size limits, distribution to all ${batchesInfo.totalSubscribers} subscribers `
              + 'in one transaction is not possible. '
              + 'Please, distribute the shares to each batch of subscribers one by one.',
            )}
          </p>
          <p className="align-text-center not-padded">
            {gettext(`Batch ${batchDistribute.indexOf(currentBatch) + 1} out of ${noOfBatches}`)}
          </p>
          <p className="align-text-center text-tiny">
            (
            {gettext(`Subscribers ${currentBatch.batchStart}-${currentBatch.batchEnd}`)}
            )
          </p>
          <Button onClick={() => {
            if (batchesInfo.totalMintagesInitialized === 0) {
              setShowMintageReasonDateModal(true);
            } else {
              axios.post(getApiUrl(`/subscriptions/${subscriptionId}/distribute/`), { batch_size: batchSize })
                .then(({ data }) => { txHashResponseHandler(data, redirectUrl); })
                .catch(console.error);
            }
          }}
          >
            {gettext('Distribute')}
          </Button>
          <MintageEventDateForm
            isOpen={showMintageReasonDateModal}
            onClose={() => { setShowMintageReasonDateModal(false); }}
            redirectUrl={redirectUrl}
            subscriptionId={subscriptionId}
            batchSize={batchesInfo.maxBatchSize}
          />
        </>
      ) : <ContentLoadingMessage isLoading={isLoading} />}
    </PageContent>
  );
};

export default SubscriptionDistribute;
