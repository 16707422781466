import { useParams } from 'react-router-dom';
import { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';

import { TransferDetailView } from '@trustwise/design-system';
import { getApiUrl } from 'utils/urls';
import { onAgreementDocumentDownload } from 'registeredObjects/views/actions';
import { PageContent } from 'core/page';
import { redirectTo } from 'core/utils';
import axios from 'core/axios';
import BackButton from 'core/page/parts/backButton';
import { getObjectDetail } from 'investments/artworks/views/actions';
import { onFileDownload } from 'media/utils';
import { signFormTransaction, signTransaction } from 'utils/signTransaction';
import { onPaymentUpload } from './actions/detail';
import reducer from '../reducers';

const PropertyTokenTransferDetailView = ({ isArbiter }) => {
  const [state, dispatch] = useReducer(reducer, { businessTransaction: {}, registeredObject: {} });
  const { businessTransaction, registeredObject } = state;

  const { id } = useParams();
  const transfersPath = isArbiter ? '/arbiter/' : '/transfers/';
  const basePath = isArbiter ? '/arbiter/transfers/' : '/assets/transfers/';
  const detailPath = `${basePath}${id}/`;
  const navigatePath = `${transfersPath}${id}/`;

  useEffect(() => {
    axios.get(getApiUrl(detailPath))
      .then(({ data }) => dispatch({ type: 'fetchBusinessTransaction', data }))
      .catch(console.error);
  }, [detailPath]);

  const objectPath = businessTransaction.metadata &&
    `/registered-objects/artworks/${businessTransaction.metadata.objectType}/${businessTransaction.metadata.objectId}/`;

  useEffect(() => {
    if (objectPath) {
      getObjectDetail({ objectPath, dispatch });
    }
  }, [objectPath]);

  const agreements = registeredObject.coOwnership ? registeredObject.coOwnership.agreements.map((agreement) => ({
    type: agreement.docType,
    id: agreement.id,
    name: agreement.subject,
  })) : undefined;

  return (
    <PageContent
      fullscreen
      pageHeading={businessTransaction.tokenName ? gettext(`Transfer of ${businessTransaction.tokenName}`) : ''}
      headerLeft={<BackButton onClick={() => redirectTo(transfersPath)} />}
    >
      <TransferDetailView
        transferDetails={{
          ...businessTransaction.transferDetails,
          actions: {
            onCancel: (setDisabled) => signTransaction({
              urlPath: `${detailPath}cancel/`,
              navigatePath,
              setDisabled,
            }),
            onComplete: (setDisabled) => signTransaction({
              urlPath: `${detailPath}approve-payment/`,
              navigatePath,
              setDisabled,
            }),
            onPaymentUpload: (values, actions) => onPaymentUpload({
              values,
              actions,
              urlPath: `${detailPath}upload-payment/`,
              navigatePath,
            }),
            onAccept: (_, actions) => signFormTransaction({
              urlPath: `${detailPath}accept/`,
              navigatePath,
              actions,
            }),
            onFileDownload: (fileId) => onFileDownload(
              fileId,
              businessTransaction.documents,
              detailPath,
            ),
            onAgreementDownload: (docId) => onAgreementDocumentDownload({
              docId, documents: registeredObject.coOwnership.agreements, objectPath,
            }),
          },
          agreements,
          allowedActions: businessTransaction.allowedActions,
        }}
        stages={businessTransaction.stages}
        artwork={registeredObject.details}
        events={{
          event: registeredObject.lastEvent,
        }}
        images={{
          media: registeredObject.media,
        }}
        role={isArbiter ? 'arbiter' : businessTransaction.participantRole}
      />
    </PageContent>
  );
};

PropertyTokenTransferDetailView.propTypes = {
  isArbiter: PropTypes.bool.isRequired,
};

export default PropertyTokenTransferDetailView;
