/* Members of the selected whitelist */
import { useState, useEffect, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router-dom';
import Tooltip from 'core/tooltip';
import axios from 'core/axios';
import { PageContent } from 'core/page';
import ContentLoadingMessage from 'utils/contentLoadingMessage';
import { BaseButton } from 'core/forms/fields';
import BackButton from 'core/page/parts/backButton';
import EditWhitelist from '../edit';
import DeleteWhitelist from '../delete';
import DeleteMember from './delete';

const WhitelistMembers = ({ companyId }) => {
  const [deleteMember, setDeleteMember] = useState(null);
  const [showRenameList, setShowRenameList] = useState(false);
  const [showDeleteList, setShowDeleteList] = useState(false);
  const [members, setMembers] = useState([]);
  const [whitelist, setWhitelist] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { listId } = useParams();
  const { pathname } = useLocation();
  const membersUrl = `/api/v1/company/${companyId}/ownership/potential-shareholders/${listId}/members/`;
  const listDetailUrl = `/api/v1/company/${companyId}/ownership/potential-shareholders/${listId}/`;

  const updateWhitelist = useCallback(() => {
    axios.get(listDetailUrl)
      .then(({ data }) => {
        setWhitelist(data);
        setShowRenameList(false);
      })
      .catch(console.error);
  }, [listDetailUrl]);

  const updateMembers = useCallback(() => {
    axios.get(membersUrl)
      .then(({ data }) => {
        setDeleteMember(null);
        setMembers(data);
      })
      .catch(console.error)
      .finally(() => { setIsLoading(false); });
  }, [membersUrl]);

  useEffect(() => {
    updateWhitelist();
    updateMembers();
  }, [updateWhitelist, updateMembers]);

  return (
    <PageContent
      fullscreen
      pageHeading={whitelist.name || gettext('')}
      headerLeft={<BackButton href="/" useRouterLink />}
      actions={[
        {
          type: 'button',
          onClick: () => { setShowDeleteList(true); },
          icon: 'trash',
          label: gettext('Delete list'),
        },
        {
          type: 'button',
          onClick: () => { setShowRenameList(true); },
          icon: 'pencil-alt',
          label: gettext('Rename list'),
        },
        {
          url: `${pathname}add/`,
          icon: 'plus',
          label: gettext('Add member'),
        },
      ]}
    >
      <>
        {Object.entries(members).length > 0 ? (
          <ol>
            {members.map((member) => (
              <Fragment key={member.id}>
                <li className="row-flex underline">
                  <span className="no-overflow">
                    {member.name}
                  </span>
                  <Tooltip actions={[{
                    key: `remove-member-${member.id}`,
                    content: (
                      <BaseButton className="text-tiny dark-grey" onClick={() => { setDeleteMember(member); }}>
                        <i className="fa right-padding text-tiny fa-user-times" />
                        {gettext('Remove member')}
                      </BaseButton>
                    ),
                  }]}
                  />
                </li>
              </Fragment>
            ))}
          </ol>
        ) : (
          <ContentLoadingMessage
            isLoading={isLoading}
            loadedMessage={gettext('No members available')}
          />
        )}
        <EditWhitelist
          isOpen={showRenameList}
          onClose={() => { setShowRenameList(false); }}
          companyId={companyId}
          listName={whitelist.name}
          updateWhitelist={updateWhitelist}
          listDetailUrl={listDetailUrl}
        />
        <DeleteWhitelist
          isOpen={showDeleteList}
          onClose={() => { setShowDeleteList(false); }}
          companyId={companyId}
          listDetailUrl={listDetailUrl}
          listName={whitelist.name}
        />
        <DeleteMember
          isOpen={!!deleteMember}
          onClose={() => { setDeleteMember(null); }}
          updateMembers={updateMembers}
          companyId={companyId}
          member={deleteMember}
        />
      </>
    </PageContent>
  );
};

WhitelistMembers.propTypes = {
  companyId: PropTypes.string.isRequired,
};

export default WhitelistMembers;
