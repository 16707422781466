import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { BoardMemberDetailView } from '@trustwise/design-system';
import { BoardMemberDetailModel } from 'boardroom/models/members';
import { convertKeysToCamelCase } from 'core/utils';
import { getApiUrl } from 'utils/urls';
import { PageContent } from 'core/page';
import axios from 'core/axios';
import BackButton from 'core/page/parts/backButton';
import { onBoardMemberDismiss, onBoardMemberEdit } from './actions';

const BoardMemberDetail = () => {
  const { memberId: id } = useParams();

  const [member, setMember] = useState({});

  const basePath = '/boardroom/';
  const memberPath = `${basePath}members/${id}/`;

  useEffect(() => {
    axios.get(getApiUrl(memberPath))
      .then(({ data }) => {
        setMember(new BoardMemberDetailModel(convertKeysToCamelCase(data)));
      })
      .catch(console.error);
  }, [memberPath]);

  return (
    <PageContent
      fullscreen
      pageHeading={member.name || ''}
      headerLeft={<BackButton href="/" useRouterLink />}
    >
      <BoardMemberDetailView
        {...member}
        onBoardMemberEdit={(values, actions) => onBoardMemberEdit({
          values, actions, submitPath: `${memberPath}update/`, navigatePath: `${basePath}${id}/`,
        })}
        onBoardMemberDismiss={(values, actions) => onBoardMemberDismiss({
          values, actions, submitPath: `${memberPath}dismiss/`, navigatePath: basePath,
        })}
      />
    </PageContent>
  );
};

export default BoardMemberDetail;
