
export const documentStoreUrls = {
  personal: {
    FETCH_BY_ID: '/api/v1/profile/documents/{id}/',
    UPDATE: '/api/v1/profile/documents/{id}/',
    CREATE: '/api/v1/profile/documents/',
    DELETE_FILE: '/api/v1/profile/documents/{documentId}/files/{fileId}/',
  },
  company: {
    FETCH_BY_ID: '/api/v1/company/{companyId}/documents/{id}/',
    UPDATE: '/api/v1/company/{companyId}/documents/{id}/',
    CREATE: '/api/v1/company/{companyId}/documents/',
    DELETE_FILE: '/api/v1/company/{companyId}/documents/{documentId}/files/{fileId}/',
  },
  consortium_membership: {
    CREATE: '/api/v1/company/{companyId}/blockchain/membership/documents/',
  },
  governance_event: {
    CREATE: '/api/v1/company/{companyId}/governance-events/{eventId}/documents/',
    FETCH_BY_ID: '/api/v1/company/{companyId}/governance-events/{eventId}/documents/{id}/',
    UPDATE: '/api/v1/company/{companyId}/governance-events/{eventId}/documents/{id}/',
    DELETE_FILE: '/api/v1/company/{companyId}/governance-events/{eventId}/documents/{id}/files/{fileId}/',
  },
  agenda_item: {
    CREATE: '/api/v1/company/{companyId}/agenda-items/{agendaItemId}/documents/',
    FETCH_BY_ID: '/api/v1/company/{companyId}/agenda-items/{agendaItemId}/documents/{id}/',
    UPDATE: '/api/v1/company/{companyId}/agenda-items/{agendaItemId}/documents/{id}/',
    DELETE_FILE: '/api/v1/company/{companyId}/agenda-items/{agendaItemId}/documents/{id}/files/{fileId}/',
  },
};
