import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import { getUrl } from 'utils/urls';
import PageNotFoundContent from 'core/page/contentParts/404';
import PaymentTokenTransfer from 'investments/paymentTokens/views/transfer';
import { FullPageView } from 'core/page';
import { EquityAssetsDetail } from 'investments/equity/detail';
import { PaymentTokenAssetsListView, PaymentTokenTransactionsListView } from './paymentTokens/views';
import {
  ArtworksAssetsListView,
  ArtworkAssetDetailView,
  InitializeArtworksAssetsTransferView,
  PropertyTokenCreateOrderView,
} from './artworks/views';
import UtilityTokensAssetDetailView from './utilityTokens/views/detail';
import UtilityTokensAssetsBurnView from './utilityTokens/views/burn';
import UtilityTokensAssetsListView from './utilityTokens/views/list';
import UtilityTokensAssetsTransferView from './utilityTokens/views/transfer';
import { EquityAssetsList } from './equity/list';
import { AssetEvents } from './eventsRoutes';

const queryClient = new QueryClient();

const AssetsView = () => (
  <FullPageView>
    <QueryClientProvider client={queryClient}>
      <Router basename={getUrl('/assets/')}>
        <Routes>
          <Route path="artworks/">
            <Route index element={<ArtworksAssetsListView />} />
            <Route path=":portfolioId/:tokenId">
              <Route index element={<ArtworkAssetDetailView />} />
              <Route path="transfer/" element={<InitializeArtworksAssetsTransferView />} />
              <Route path="sell/" element={<PropertyTokenCreateOrderView />} />
            </Route>
          </Route>
          <Route path="cash/">
            <Route index element={<PaymentTokenAssetsListView />} />
            <Route path="transfers/" element={<PaymentTokenTransactionsListView />} />
            <Route path=":portfolioId/:tokenId/transfer/" element={<PaymentTokenTransfer />} />
          </Route>
          <Route path="equity/">
            <Route index element={<EquityAssetsList />} />
            <Route path=":portfolioId/:tokenId" element={<EquityAssetsDetail />} />
            <Route path=":portfolioId/:tokenId/meetings/*" element={<AssetEvents />} />
          </Route>
          <Route path="utility/">
            <Route index element={<UtilityTokensAssetsListView />} />
            <Route path=":portfolioId/:tokenId">
              <Route index element={<UtilityTokensAssetDetailView />} />
              <Route path="transfer/" element={<UtilityTokensAssetsTransferView />} />
              <Route path="burn/" element={<UtilityTokensAssetsBurnView />} />
            </Route>
          </Route>
          <Route path="*" element={<PageNotFoundContent />} />
        </Routes>
      </Router>
    </QueryClientProvider>
  </FullPageView>
);

export default AssetsView;
