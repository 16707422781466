/* Row link component */
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Icon } from 'components/common/icon';


const RowLink = ({ to, title, value, className, iconName }) => (
  <Link to={to} className={`row-flex ${className}`}>
    <div>
      {title}
    </div>
    <div className="font-bold">
      {value}
      <Icon name={iconName} className="text-tiny" />
    </div>
  </Link>
);

RowLink.defaultProps = {
  iconName: 'chevron-right',
  className: '',
  value: null,
};

RowLink.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  iconName: PropTypes.string,
  className: PropTypes.string,
};

export default RowLink;
