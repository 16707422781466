import { DataSetVerificationDecision } from '@trustwise/design-system';
import { Model } from 'core/model';
import { SimpleLegalEntityModel } from 'contacts/models/legalEntity';
import { LegalEntityResponseProps } from 'contacts/interfaces/response';
import { VerifierDataRoom, VerifierDataRoomModel } from './dataRoom';

interface BaseGrantToken {
  /**
   * Address of the token
   */
  address: string;
  /**
   * Name of the token
   */
  name: string;
}

interface GrantToken extends BaseGrantToken {
  /**
   * Total amount of available tokens
   */
  amount: string;
}

interface IGrantToken extends BaseGrantToken {
  /**
   * Total amount of available tokens
   */
  amount: number;
}

export class GrantTokenModel extends Model implements IGrantToken {
  address: string;
  name: string;
  amount: number;

  constructor({ amount, ...restProps }: GrantToken) {
    super(restProps);
    this.amount = Number(amount);
  }
}

interface BaseDataSetListItem {
  /**
   * Data set ID
   */
  id: number;
  /**
   * Data set name
   */
  name: string;
  /**
   * Data set verification decision
   */
  decision: DataSetVerificationDecision;
}

interface ReviewedDataSetListItemProps extends BaseDataSetListItem {
  /**
   * Date and time on which the data set was reviewed
   */
  reviewedAt: string;
  /**
   * Data set reviewer (person or company signatory)
   */
  reviewedBy: LegalEntityResponseProps;
  /**
   * Data set reviewer (company)
   */
  reviewedByCompany?: LegalEntityResponseProps;
  /**
   * Data room details
   */
  dataRoom: VerifierDataRoom;

}

interface ReviewedDataSetListItem extends BaseDataSetListItem {
  /**
   * Date and time on which the data set was reviewed
   */
  reviewedAt: Date;
  /**
   * Data set reviewer (person or company signatory)
   */
  reviewedBy: SimpleLegalEntityModel;
  /**
   * Data set reviewer company
   */
  reviewedByCompany?: SimpleLegalEntityModel;
  /**
   * Data room details
   */
  dataRoom: VerifierDataRoomModel;
}

export class ReviewedDataSetModel extends Model implements ReviewedDataSetListItem {
  id: number;
  name: string;
  decision: DataSetVerificationDecision;
  noOfReports?: number;
  reviewedAt: Date;
  reviewedBy: SimpleLegalEntityModel;
  reviewedByCompany?: SimpleLegalEntityModel;
  dataRoom: VerifierDataRoomModel;

  constructor({ reviewedAt, reviewedBy, reviewedByCompany, ...restProps }: ReviewedDataSetListItemProps) {
    super(restProps);
    this.reviewedAt = new Date(reviewedAt);
    this.reviewedBy = SimpleLegalEntityModel.fromResponse(reviewedBy);
    this.reviewedByCompany = SimpleLegalEntityModel.fromResponse(reviewedByCompany);
    this.dataRoom = VerifierDataRoomModel.fromResponse(restProps.dataRoom);
  }
}

interface BasePendingVerifications {
  /**
   * Data set ID
   */
  id: number;
  /**
   * Data set name
   */
  name: string;
}

interface PendingVerificationsProps extends BasePendingVerifications {
  /**
   * Data room owner
   */
  owner: LegalEntityResponseProps;
  /**
   * Date on which the data set was sealed
   */
  sealedAt: string;
  /**
   * Data room details
   */
  dataRoom: VerifierDataRoom;
}

interface IPendingVerifications extends BasePendingVerifications {
  /**
   * Date on which the data set was sealed
   */
  sealedAt: Date;
  /**
   * Data room details
   */
  dataRoom: VerifierDataRoomModel;
}

export class PendingVerificationsModel extends Model implements IPendingVerifications {
  id: number;
  name: string;
  sealedAt: Date;
  dataRoom: VerifierDataRoomModel;

  constructor({ dataRoom, sealedAt, name, ...restProps }: PendingVerificationsProps) {
    super(restProps);
    this.sealedAt = new Date(sealedAt);
    this.name = name;
    this.dataRoom = VerifierDataRoomModel.fromResponse(dataRoom);
  }
}
