/* Market subscribe form */
import { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import DOMPurify from 'dompurify';
import marked from 'marked';
import { useParams } from 'react-router-dom';

import { ModalWithContent as Modal } from '@trustwise/design-system';
import { CheckboxField, SubmitButton, TextField, FieldErrors } from 'core/forms/fields';
import { fieldRequired, parseErrorResponse } from 'core/forms/utils';
import { formatNumber, toMonetaryValue } from 'core/utils';
import { getApiUrl, getUrl } from 'utils/urls';
import ContentLoadingMessage from 'utils/contentLoadingMessage';
import axios from 'core/axios';
import { PageContent } from 'core/page';
import { ContactSelectField } from 'contacts/contactSelect/selectField';
import { contactSelectValidation } from 'contacts/contactSelect/validation';
import BackButton from 'core/page/parts/backButton';
import { handleTransactionCreation } from 'core/page/transactionSigning/utils';
import { IncludeOnlyOptions } from 'contacts/contactSelect/constants';

const SubscriptionCertificateForm = () => {
  const [showPowerOfAttorneyText, setShowPowerOfAttorneyText] = useState(false);
  const [showLegalText, setShowLegalText] = useState(false);
  const [legalText, setLegalText] = useState('');
  const [powerOfAttorney, setPowerOfAttorney] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [subscription, setSubscription] = useState({});
  const { subscriptionId } = useParams();
  const redirectUrl = getUrl(`/markets/private-equity/subscriptions/${subscriptionId}/`);

  useEffect(() => {
    setIsLoading(true);
    axios.get(getApiUrl(`/markets/subscriptions/${subscriptionId}/`))
      .then(({ data }) => { setSubscription(data); })
      .catch(console.error)
      .finally(() => { setIsLoading(false); });
  }, [subscriptionId]);

  useEffect(() => {
    axios.get(getApiUrl(`/markets/subscriptions/${subscriptionId}/get-legal-texts/`))
      .then(
        ({ data }) => {
          setLegalText(data.legal_text);
          setPowerOfAttorney(data.power_of_attorney);
        },
      )
      .catch(console.error)
      .finally(() => { setIsLoading(false); });
  }, [subscriptionId]);

  const onSubmit = (values, actions) => {
    actions.setSubmitting(true);
    const data = {
      address: values.account.portfolio,
      no_of_shares: values.noOfShares,
      power_of_attorney: values.powerOfAttorney,
    };
    axios.post(getApiUrl(`/markets/subscriptions/${subscriptionId}/subscribe/`), data)
      .then(
        ({ data: { tx_hash: txHash } }) => {
          handleTransactionCreation(txHash, redirectUrl);
        },
        ({ response: { data: errors } }) => { parseErrorResponse(errors, actions.setFieldError); },
      )
      .catch(console.error)
      .finally(() => { actions.setSubmitting(false); });
  };

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Subscription Certificate')}
      headerLeft={<BackButton href={redirectUrl} />}
    >
      {legalText && powerOfAttorney ? (
        <Formik
          initialValues={{
            nonFieldErrors: '',
            account: {},
            noOfShares: 0,
            powerOfAttorney: false,
            conditionsRead: false,
            legalConfirmation: false,
          }}
          validationSchema={Yup.object({
            account: contactSelectValidation(),
            noOfShares: Yup.number().positive(gettext('Should be above 0')).integer().required(fieldRequired),
            powerOfAttorney: Yup.bool().oneOf([true], gettext('Your agreement is necessary to proceed.')),
            conditionsRead: Yup.bool().oneOf([true], gettext('Your agreement is necessary to proceed.')),
            legalConfirmation: Yup.bool().oneOf([true], gettext('Your agreement is necessary to proceed.')),
          })}
          onSubmit={onSubmit}
        >
          {({ values, isSubmitting }) => (
            <>
              {subscription.issuer && (
                <div className="subscription-subheading">
                  <div className="avatar right-padding">
                    <span
                      className="avatar-image centered"
                      style={
                        subscription.issuer.avatar.image ?
                          { background: 'none' } : { backgroundColor: subscription.issuer.avatar.color }
                      }
                    >
                      {subscription.issuer.avatar.image ? (
                        <img alt="profile" className="image" src={subscription.issuer.avatar.image} />
                      ) : <span className="avatar-initials">{subscription.issuer.avatar.initials}</span>}
                    </span>
                  </div>
                  {subscription.issuer.name}
                  &bull;
                  {subscription.share_class.name}
                </div>
              )}
              <Form>
                <FieldErrors name="nonFieldErrors" />
                <ContactSelectField
                  name="account"
                  includeOnly={IncludeOnlyOptions.OWN}
                  withPortfolios
                  selectContactLabel={gettext('Assign a portfolio')}
                  addNewLabel={gettext('Add new portfolio')}
                  emptyValueLabel={gettext('No portfolio yet')}
                  label={gettext('Portfolio')}
                />
                <TextField label={gettext('Number of shares')} name="noOfShares" type="number" />
                <dl className="subscription-issue-price-calc">
                  <dt>{gettext('Issue Price')}</dt>
                  <dd>
                    {toMonetaryValue(subscription.currency, formatNumber(subscription.price_per_share * values.noOfShares))}
                  </dd>
                </dl>
                <CheckboxField
                  name="powerOfAttorney"
                  label={(
                    <>
                      {gettext('I confirm that I have read and accept to grant the ')}
                      <button
                        className="primary-color text-tiny"
                        type="button"
                        onClick={() => { setShowPowerOfAttorneyText(true); }}
                      >
                        {gettext('Power of Attorney.')}
                      </button>
                    </>
                  )}
                />
                <Modal
                  id="powerOfAttorneyAgreement"
                  isOpen={showPowerOfAttorneyText}
                  onClose={() => { setShowPowerOfAttorneyText(false); }}
                >
                  {/* eslint-disable-next-line react/no-danger */}
                  <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(marked(powerOfAttorney)) }} />
                </Modal>
                <CheckboxField
                  name="conditionsRead"
                  label={gettext(
                    'I confirm to have fully read, understood and that I accept the Terms of Subscription, including'
                    + ' the documents attached thereto. I have fully understood the risks related to an investment'
                    + ' according to the Terms of Subscription. In particular, I understand that the investment may'
                    + ' lead to a total loss of the entire paid-in capital. I hereby irrevocably and unconditionally'
                    + ' undertake to pay an amount equal to the full issue price for the number of shares indicated'
                    + ' above by way of fund transfer to the blocked bank account indicated by the Company.',
                  )}
                />
                <br />
                <Modal
                  id="legalText"
                  isOpen={showLegalText}
                  onClose={() => { setShowLegalText(false); }}
                >
                  {/* eslint-disable-next-line react/no-danger */}
                  <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(marked(legalText)) }} />
                </Modal>
                <CheckboxField
                  name="legalConfirmation"
                  label={gettext(
                    'I have taken note of the registration agreement and any other documents provided by the Issuer regarding'
                    + ' the opportunities and risk of the register value rights and I confirm to be bound by the registration'
                    + ' agreement.',
                  )}
                />
                <SubmitButton disabled={!values.legalConfirmation || !values.conditionsRead || isSubmitting}>
                  {gettext('Subscribe')}
                </SubmitButton>
              </Form>
            </>
          )}
        </Formik>
      ) : (
        <ContentLoadingMessage
          isLoading={isLoading}
          loadedMessage={gettext('Missing agreements')}
        />
      )}
    </PageContent>
  );
};

export default SubscriptionCertificateForm;
