import { useCallback, useEffect, useState } from 'react';

import { CbdcTokenTransferWidget } from '@trustwise/design-system';
import axios from 'axios';
import { getApiUrl } from 'utils/urls';
import { getTimeSeriesQueryArgs } from 'utils/getTimeSeriesQueryArgs';
import { useAbortController } from 'core/hooks';

const TokenTransfersWidget = () => {
  const [timeSeries, setTimeSeries] = useState();
  const [currentBalanceData, setCurrentBalanceData] = useState();
  const [, updateController] = useAbortController();

  const onFetchTimeSeries = useCallback(
    (tab = '1y') => {
      const signal = updateController();
      setTimeSeries();
      const queryArgs = getTimeSeriesQueryArgs(tab);
      return (
        axios.get(getApiUrl('/payment-tokens/issuer/transfers/summary/', queryArgs), { signal })
          .then(
            ({ data: {
              balance,
              currency_code: currencyCode,
              time_series: timeSeriesRecords,
            } }) => {
              const timeSeriesData = timeSeriesRecords.map(
                ({ received, sent, date_time: dateTime }) => ({
                  received: Number(received),
                  sent: Number(sent),
                  dateTime: new Date(dateTime),
                }),
              );
              setTimeSeries(timeSeriesData);
              setCurrentBalanceData({
                currentBalance: Number(balance),
                currencyCode,
              });
            },
          )
          .catch(console.error)
      );
    },
    [updateController],
  );

  useEffect(() => {
    onFetchTimeSeries();
  }, [onFetchTimeSeries]);

  return (
    <CbdcTokenTransferWidget
      {...currentBalanceData}
      timeSeries={timeSeries}
      onFetch={onFetchTimeSeries}
    />
  );
};

export default TokenTransfersWidget;
