import { useState, useEffect } from 'react';
import { ReactSortable } from 'react-sortablejs';

import axios from 'core/axios';
import { FullPageContent } from 'core/page';
import { getUrl, getApiUrl } from 'utils/urls';
import { BaseButton } from 'core/forms/fields';
import Widget from 'widgets/base';
import BackButton from 'core/page/parts/backButton';
import { icons } from 'core/icons';
import { getGlobalContext } from 'core/globals';
import {
  custodialDefaultWidgets,
  widgetsLabels,
  companyDefaultWidgets,
  personDefaultWidgets,
  companyAllWidgets,
  personAllWidgets,
} from './const';


const PersonaliseDashboard = () => {
  const [activeWidgets, setActiveWidgets] = useState([]);
  const [moreWidgets, setMoreWidgets] = useState([]);
  const {
    activeEntity: { isCompany },
    custodian: { active: custodianActive },
  } = getGlobalContext();

  let defaultWidgets = isCompany ? companyDefaultWidgets : personDefaultWidgets;
  let allWidgets = isCompany ? companyAllWidgets : personAllWidgets;
  if (custodianActive) {
    defaultWidgets = custodialDefaultWidgets;
    allWidgets = custodialDefaultWidgets;
  }

  useEffect(() => {
    axios.get(getApiUrl('/dashboard-widgets/'))
      .then(
        ({ data: { widgets: widgetsList } }) => {
          let userWidgets = widgetsList;
          if (!widgetsList || widgetsList.length === 0) {
            userWidgets = defaultWidgets;
          }
          setActiveWidgets(userWidgets);
          setMoreWidgets(allWidgets.filter((x) => !userWidgets.includes(x)));
        },
      )
      .catch(console.error);
  }, [defaultWidgets, allWidgets]);

  const submit = (items) => {
    const data = { widgets: items.map((x) => (typeof x === 'object' ? x.valueOf() : x)) };
    axios.patch(getApiUrl('/dashboard-widgets/'), data).then(() => { }).catch(console.error);
  };

  return (
    <FullPageContent
      pageHeading={gettext('Edit Dashboard')}
      headerLeft={<BackButton href={getUrl()} />}
    >
      <>
        <p>
          {gettext(
            'Personalise your dashboard by editing the widget list below. Add or remove, drag and drop to change the order.',
          )}
        </p>
        <div className="widgets">
          <Widget
            id="active-widgets"
            title={gettext('Active Widgets')}
          >
            <ReactSortable
              list={activeWidgets}
              setList={(items) => {
                setActiveWidgets(items);
                JSON.stringify(items) !== JSON.stringify(activeWidgets) && submit(items);
              }}
              animation={150}
              group="widgets"
            >
              {activeWidgets.map((item) => (
                <div
                  key={item}
                  className="widget-content-row"
                >
                  <BaseButton
                    className="row-content-icon"
                    onClick={() => {
                      setMoreWidgets([item, ...moreWidgets]);
                      activeWidgets.splice(activeWidgets.indexOf(item), 1);
                      setActiveWidgets(activeWidgets);
                      submit(activeWidgets);
                    }}
                  >
                    {icons.subtract}
                  </BaseButton>
                  <div className="widget-row-title">{widgetsLabels[item]}</div>
                  <div className="widget-left-column">{icons.drag}</div>
                </div>
              ))}
            </ReactSortable>
          </Widget>
          {moreWidgets.length > 0 && (
          <Widget
            id="more-widgets"
            title={gettext('More Widgets')}
          >
            <ReactSortable
              list={moreWidgets}
              setList={setMoreWidgets}
              animation={150}
              group="widgets"
            >
              {moreWidgets.map((item) => (
                <div
                  key={item}
                  className="widget-content-row"
                >
                  <BaseButton
                    className="row-content-icon"
                    onClick={() => {
                      setActiveWidgets([...activeWidgets, item]);
                      submit([...activeWidgets, item]);
                      moreWidgets.splice(moreWidgets.indexOf(item), 1);
                      setMoreWidgets(moreWidgets);
                    }}
                  >
                    {icons.add}
                  </BaseButton>
                  <div className="widget-row-title">{widgetsLabels[item]}</div>
                  <div className="widget-left-column">{icons.drag}</div>
                </div>
              ))}
            </ReactSortable>
          </Widget>
          )}
        </div>
      </>
    </FullPageContent>
  );
};

export default PersonaliseDashboard;
