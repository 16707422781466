import PropTypes from 'prop-types';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
  Link,
  Navigate,
} from 'react-router-dom';
import { FieldErrors } from 'core/forms/fields';

const formStepPropType = PropTypes.shape({
  component: PropTypes.node,
  onStepSubmit: PropTypes.func,
  path: PropTypes.string,
  pathName: PropTypes.string,
  fieldNames: PropTypes.arrayOf(PropTypes.string),
});

const FormLink = ({ children, className, step, to }) => {
  const navigate = useNavigate();
  const handleClick = (e) => {
    e.preventDefault();
    step.onStepSubmit ? step.onStepSubmit(step, navigate, to) : navigate(to);
  };
  return <a onClick={handleClick} className={className} href={to}>{ children }</a>;
};

FormLink.defaultProps = {
  className: '',
  children: null,
};

FormLink.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
  step: formStepPropType.isRequired,
};

const FormNavLink = ({ activeClassName, children, className, step, to }) => {
  const location = useLocation();
  return (
    <FormLink className={`${className} ${location.pathname === to && activeClassName}`} step={step} to={to}>
      {children}
    </FormLink>
  );
};

FormNavLink.defaultProps = {
  activeClassName: 'active',
  children: null,
  className: '',
};

FormNavLink.propTypes = {
  activeClassName: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
  step: formStepPropType.isRequired,
};


/**
 * Form Router
 *
 * @todo fix enter/return key in the form:
 *       https://github.com/formium/formik/issues/1418#issuecomment-524959877
 * @todo add support for steps without fields
 * @todo introduce class for step/route
 * @todo consider custom buttons and navigating with useNavigate after step validation instead of the current approach
 *
 */
const FormRouter = ({ basePath, formProps, steps, submitButton }) => {
  const formattedBasePath = basePath + (basePath.endsWith('/') ? '' : '/');
  // add fieldNames, isValid and path properties to each route object
  const routes = steps.map((step) => (
    {
      ...step,
      path: `${formattedBasePath}${step.pathName}/`,
      fieldNames: step.fieldNames || step.component.props.children.map(
        (child) => (child.props ? child.props.name : null),
      ).filter((x) => !!x),
      disabled: step.disabled,
      // eslint-disable-next-line react/no-this-in-sfc
      get isValid() { return !this.fieldNames.filter((fieldName) => fieldName in formProps.errors).length; },
    }
  ));
  return (
    <Router>
      {routes.length > 1 && (
        <ul className="form-step-navigation">
          {routes.map((route) => <li key={route.path}><FormNavLink step={route} to={route.path} /></li>)}
        </ul>
      )}
      <FieldErrors name="nonFieldErrors" />
      <Routes>
        {routes.map((route, index) => (
          <Route
            key={route.path}
            path={route.path}
            element={(
              <>
                { // Display current component or redirect to the first invalid step
                !routes.filter(({ isValid }, i) => i < index && !isValid).length ?
                  <fieldset>{route.component}</fieldset> : <Navigate to={routes.find(({ isValid }) => !isValid).path} />
                }
                { // Display and enable link to next step if current step is valid and this is not the last step
                  index < routes.length - 1 && (
                    <FormLink
                      step={route}
                      className={`button ${(route.isValid && !route.disabled) || 'disabled'}`}
                      to={routes[index + (route.disabled ? 0 : 1)].path}
                    >
                      {gettext('Continue')}
                    </FormLink>
                  )
                }
                { // Display submit button if this is the last step
                  index === routes.length - 1 && submitButton
                }
                { // Display back button if this is not the first step
                  !!index && (
                    <Link className="button button-outlined" to={routes[index - 1].path}>{gettext('Go Back')}</Link>
                  )
                }
              </>
          )}
          />
        ))}
        <Route path="*" element={<Navigate to={routes[0].path} replace />} />
      </Routes>
    </Router>
  );
};


FormRouter.propTypes = {
  basePath: PropTypes.string.isRequired,
  formProps: PropTypes.shape({
    dirty: PropTypes.bool,
    errors: PropTypes.shape(),
  }).isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      pathName: PropTypes.string.isRequired,
      component: PropTypes.node.isRequired,
    }),
  ).isRequired,
  submitButton: PropTypes.node.isRequired,
};


export default FormRouter;
