export const allotmentProcedureOptions = [
  { value: 'FS', label: gettext('First come first serve') },
  { value: 'PR', label: gettext('Proportional'), disabled: true },
  { value: 'RA', label: gettext('Raffle'), disabled: true },
  { value: 'MA', label: gettext('Manual'), disabled: true },
];

export const powerOfAttorneyDefault = `I hereby confer power of attorney, including the right to appoint substitutes, to each of
**[Name]**, **[Address]**, and **[Name]**, **[Address]**, individually, to subscribe on my behalf the number of shares indicated
in the Subscription Certificate pursuant to the Terms of Subscription and to irrevocably and unconditionally undertake to pay an
amount equal to the respective full issue price. This power of attorney expires on **[Date]**. This power of attorney shall be
governed by Swiss law. All disputes arising out of or in connection with this document shall be subject to the exclusive
jurisdiction of the competent courts at the seat of the Company, Switzerland.`;

export const legalTextDefault = `To the board of directors of **[Company Name]**, **[Address]**
\nI hereby subscribes irrevocably for the number of shares of the share class and the share price shown below.
\nI confirm, that I have fully read the offering and the attached documents and have fully understood the risks which might lead
to a total loss of the paid-in capital. I warrant that I am fully capable of covering such losses. I promise to pay in the full
amount for the finally allotted shares to **[Bank name]**, **[IBAN number]** until the closing date.`;
