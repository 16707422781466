import PropTypes from 'prop-types';

import TextField from './text';
import FieldLabel from './label';

/**
 * Date field with label and errors.
 *
 * TODO add other props as necessary (className, placeholder, etc.)
 */
const DateTimeField = ({ idDate, idTime, label, dateName, isOptional, timeName, ...props }) => (
  <>
    {label && <FieldLabel id={idDate || dateName} label={label} isOptional={isOptional} />}
    <div className="datetime">
      <TextField className="datetime-date" type="date" id={idDate} name={dateName} {...props} />
      <TextField className="datetime-time" type="time" id={idTime} name={timeName} />
    </div>
  </>
);

DateTimeField.defaultProps = {
  idDate: '',
  idTime: '',
  label: '',
  isOptional: undefined,
};

DateTimeField.propTypes = {
  idDate: PropTypes.string,
  idTime: PropTypes.string,
  label: PropTypes.string,
  dateName: PropTypes.string.isRequired,
  timeName: PropTypes.string.isRequired,
  isOptional: PropTypes.bool,
};

export default DateTimeField;
