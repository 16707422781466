import '@babel/polyfill';

import { getGlobalContext } from 'core/globals';
import Foundation from '../vendor/foundation';
import sortableAgendaListWithAjax from './formHelpers/sortableListWithAjax';
import { autoFillPaymentTokenName } from './formHelpers/formModulesScripts';
import { sortableTextArea } from './formHelpers/sortableTextArea';
import { displayFileInfo, preventDoubleSubmission } from './formHelpers/shared';
import { textEditor } from './formHelpers/textEditor';
import { VotingChart } from './charts/voting';
import ReactWsClient from './services/wsClient';
import ReactComponentsEntry from './renderers';
import { initSentry } from './sentry';
import { getNativeFileUrl } from './media/utils';
import { messageAutoHide, copyToClipBoard, isAndroidWebview, isNativeAppWebview } from './utils/general';
import { screenSMin } from './core/styles';

const Cookies = require('js-cookie');

Foundation.addToJquery($);


// Utils

const setTransform = (el, transform) => {
  el.style.transform = transform;
  el.style.WebkitTransform = transform;
};

const convertRatio = (r, start, end) => (
  start + ((end - start) * (1 - r))
);


document.addEventListener('DOMContentLoaded', (_event) => {
  initSentry();

  $(document).foundation();

  $('body').on(
    'click',
    'button[type="submit"], a[href^="/sign/"]',
    () => {
      const signingModal = $('#unsigned-transaction-modal');
      if (signingModal.attr('data-hide-modal') === 'True') {
        signingModal.attr('data-no-update', true);
      }
    },
  );

  textEditor.init();
  autoFillPaymentTokenName.init({
    target: $('span.payment-token-currency'),
  });
  displayFileInfo.init();
  preventDoubleSubmission.init();
  sortableAgendaListWithAjax('sortable_ajax_list');
  sortableTextArea.init({
    list: document.getElementById('sortable_textarea_list'),
    textarea: '#id_voting_options',
    newInput: '#id_new_voting_option',
  });

  /**
     * Simple navigate back in browser history
     *
     */
  $('[data-go-back]').on('click', () => {
    window.history.back();
  });
  /**
   * Simple function to scroll to the element
   *
   */

  const scrollToElement = (element) => {
    $('html, body').animate({
      scrollTop: $(element).offset().top - 100,
    }, 2000);
  };

  /**
     * Simple data attribute based visibility toggle
     *
     */
  $('[data-toggle]').on('change, click', (e) => {
    const t = $(e.currentTarget);
    if (t.is('button') && t.blur()) e.preventDefault();
    t.toggleClass('toggle-active');
    const target = $(`[data-toggled-by="${t.data('toggle')}"]`);
    target.toggleClass('deactivated');
    for (let i = 0; i < target.length; i++) {
      if (target[i].tagName === 'INPUT' && !target[i].classList.contains('deactivated')) {
        target[i].focus();
        scrollToElement(target[i]);
      }
    }
    if (target.is('[data-reset-on-toggle]')) $('textarea, input[type="text"]', target).text('');
  });

  /**
     * A simple preview mode toggle
     *
     */
  $('#id_preview_mode_toggle').attr('disabled', false).on('change', (e) => {
    Cookies.set('previewMode', $(e.target).is(':checked') ? 1 : '');
  });

  /**
    * Hide warning message id_account_created
    *
    */
  $('#id_account_created').on('click', () => {
    $('#id_account_created').addClass('hidden');
    Cookies.set('account_created_hidden', 'hidden');
  });

  /**
     * Calculate the total amount to pay for the order
     *
     */
  const inputs = $('#id_price_per_share, #id_amount_to_buy, #id_amount_to_sell, #id_amount');
  inputs.on('input', () => {
    $('#id_total').val($('#id_amount_to_buy, #id_amount_to_sell, #id_amount').val() * $('#id_price_per_share').val());
  });

  /**
     * clickable table row
     *
     */
  $('.clickable-row').on('click', (e) => {
    window.location = e.target.data('href');
  });

  const votingChart = $('#votingChartArea');
  if (votingChart.length) {
    new VotingChart({
      targetElement: '#votingChartArea',
      chartData: votingChart.data().votingData,
      options: {
        href: {
          votingStats: votingChart.data().votingStatsHref,
        },
      },
    }).render();
  }

  new ReactComponentsEntry().render();
  if (!window.location.href.match(/\/(login|signup)/)) {
    // eslint-disable-next-line no-new
    new ReactWsClient();
  }

  const expandableCard = document.getElementsByClassName('collapse-toggle');

  if (expandableCard) {
    for (let i = 0; i < expandableCard.length; i += 1) {
      // eslint-disable-next-line no-loop-func
      expandableCard[i].addEventListener('click', (e) => {
        e.target.parentElement.parentElement.classList.toggle('expanded');
        e.target.parentElement.parentElement.classList.toggle('collapsed');
        e.target.classList.toggle('fa-chevron-down');
        e.target.classList.toggle('fa-chevron-up');
      });
    }
  }

  const print = document.getElementById('print-page-content');
  if (print) {
    print.addEventListener('click', (_e) => { window.print(); });
  }

  const copyIcon = document.getElementsByClassName('fa-copy');
  if (copyIcon.length > 0) {
    for (let i = 0; i < copyIcon.length; i += 1) {
      copyIcon[i].addEventListener('click', (e) => {
        const text = e.target.dataset.walletId;
        copyToClipBoard(text);
        messageAutoHide();
      });
    }
  }

  const registrationModalElement = document.getElementById('registrationModal');
  if (registrationModalElement) {
    const registrationModal = new Foundation.Reveal($(registrationModalElement));
    registrationModal.open();
  }
  const leftButton = document.getElementById('left-button');
  if (leftButton) {
    leftButton.addEventListener('click', (_e) => {
      window.stepperComponent.previous();
    });
  }

  /**
   * Download shareholders registry excerpt.
   */
  const shareholdersExcerptForm = $('[data-shareholders-excerpt-form]');
  const updateShareholderExcerptLink = () => {
    if (!shareholdersExcerptForm.length) {
      return;
    }
    const shareholdersExcerptLink = $('a', shareholdersExcerptForm);
    const serializedInputs = $('input[type="checkbox"]', shareholdersExcerptForm).serialize();
    if (!serializedInputs) {
      shareholdersExcerptLink.addClass('disabled');
      shareholdersExcerptLink.css('pointer-events', 'none');
      return;
    }
    const shareholderId = window.location.href.match(/shareholders\/(\d*)\/excerpt/)[1];
    const queryString = `${serializedInputs}&shareholder_id=${shareholderId}`;
    const { activeEntity: { id: activeEntityId } } = getGlobalContext();
    let downloadUrl = `/company/${activeEntityId}/ownership/shareholders/report/pdf/?${queryString}`;
    if (isNativeAppWebview) {
      downloadUrl = getNativeFileUrl(downloadUrl, gettext('Shareholder registry excerpt'), 'application/pdf');
    }
    shareholdersExcerptLink.attr('href', downloadUrl);
    shareholdersExcerptLink.removeClass('disabled');
    shareholdersExcerptLink.css('pointer-events', 'all');
  };
  shareholdersExcerptForm.on('change', (_e) => { updateShareholderExcerptLink(); });
  updateShareholderExcerptLink();


  // Animations
  const animatedPageTitle = (element) => {
    const header = document.querySelector('.app-header');
    const target = document.querySelector('.intersection-observer');
    const hasBackButton = !!document.querySelector('.back-button');
    const thresholds = [...Array(100).keys()].map((x) => x / 100);
    const intersectionCallback = (entries, _observer) => {
      const r = entries[0].intersectionRatio;
      const scale = convertRatio(r, 1, 0.5);
      const x = hasBackButton ? convertRatio(r, 0, 80) : 0;
      setTransform(element, `scale(${scale}) translateX(${x}px)`);
      if (r === 0) {
        header.classList.add('is-sticky');
      } else {
        header.classList.remove('is-sticky');
      }
    };
    if (element && header && target) {
      // @todo investigate no-undef IntersectionObserver
      // eslint-disable-next-line no-undef
      const observer = new IntersectionObserver(
        intersectionCallback,
        { threshold: thresholds },
      );
      observer.observe(target);
    }
    document.body.classList.add('page-title-intersection-observer-ready');
  };
  if (window.innerWidth >= screenSMin) {
    window.animatedPageTitle = animatedPageTitle;
    animatedPageTitle(document.querySelector('.page-title'));
  }

  // Check safe area and set it if necessary in the webview
  // due to difference in behavior of safe-area env variables
  // on Android and iOS. Solution is from:
  // https://stackoverflow.com/questions/52476016/envsafe-area-inset-top-not-working-on-android-pie-webview-69/52899232#52899232s
  const checkWebviewSafeArea = () => {
    const { body } = document;
    const div = document.createElement('div');
    div.style.paddingTop = 'env(safe-area-inset-top)';
    div.style.paddingTop = 'constant(safe-area-inset-top)';
    body.appendChild(div);
    const safeAreaInsetTop = div.getBoundingClientRect().height;
    if (!safeAreaInsetTop) {
      body.classList.add('app-android-safe-area');
    }
    div.remove();
  };
  if (isAndroidWebview) {
    checkWebviewSafeArea();
  }
});


/**
 * Submit on change
 */
$('[data-submit-on-change]').on('change', (e) => {
  $(e.target).closest('form').submit();
});


/**
 * Fetch documentation content and display a modal when documentation button is clicked.
 *
 * @todo add loader when fetching data from server
 * @todo prevent double submissions
 * @todo move to dedicated module
 */
const DocumentationModal = () => {
  const APIBaseURL = 'api/v1/documentation';

  let navigationStack = [];
  const getCurrentPath = () => navigationStack[navigationStack.length - 1];

  const cache = {};
  const getCurrentContent = () => cache[getCurrentPath()];

  const elements = {
    triggers: document.querySelectorAll('[data-documentation-path]'),
    wrapper: $('<div/>', {
      class: 'documentation-container reveal text-smaller',
      'data-reveal': '',
    }),
    backButton: $('<button/>', {
      class: 'documentation-back-button',
      type: 'button',
      html: '<i class="fa icon fa-chevron-left"></i>',
    }),
    closeButton: $('<button/>', {
      class: 'close-button',
      'data-close': '',
      type: 'button',
      html: '&times;',
    }),
    content: $('<div/>', { class: 'documentation-content' }),
  };
  elements.wrapper.append(elements.content, elements.closeButton, elements.backButton);
  elements.wrapper.appendTo('body');

  const actions = {};
  actions.update = () => {
    if (getCurrentContent()) {
      actions.render();
    } else {
      const path = getCurrentPath();
      $.ajax(`/${APIBaseURL}/${path}/`).done((content) => { cache[path] = content; actions.render(); });
    }
  };
  actions.render = () => {
    elements.content.html(getCurrentContent());
    elements.backButton.css({ display: navigationStack.length > 1 ? 'block' : 'none' });
    actions.updateContentLinks();
    elements.wrapper.foundation('open');
  };
  actions.updateContentLinks = () => {
    const links = document.querySelectorAll('.documentation-content a');
    for (let i = 0; i < links.length; i++) { links[i].onclick = actions.handleContentLinkClick; }
  };
  actions.handleContentLinkClick = (e) => {
    e.preventDefault();
    navigationStack.push(e.target.href.replace(e.target.baseURI, ''));
    actions.update();
  };
  actions.handleTriggerClick = (e) => {
    navigationStack = [e.target.parentElement.dataset.documentationPath];
    actions.update();
  };
  actions.goBack = (_e) => { navigationStack.pop(); actions.render(); };

  elements.backButton.on('click', actions.goBack);
  for (let i = 0; i < elements.triggers.length; i++) { elements.triggers[i].onclick = actions.handleTriggerClick; }
};
DocumentationModal();
