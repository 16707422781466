import { extendObservable } from 'mobx';
import ConsortiumMembershipModel from '../models/consortiumMembership';

class ConsortiumMembershipStore {
  constructor() {
    extendObservable(this, {
      model: null,
    });
    this.addModel = this.addModel.bind(this);
  }

  addModel(args) {
    this.model = new ConsortiumMembershipModel({ ...args });
    return this.model;
  }
}

export const consortiumMembershipStore = new ConsortiumMembershipStore();
export { ConsortiumMembershipStore };
