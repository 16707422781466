/* eslint-disable no-underscore-dangle */
import {
  extendObservable,
  set,
  toJS,
} from 'mobx';
import uuid from 'uuid';
import find from 'lodash/find';

/**
 * @deprecated Deprecated, do not use.
 */
export default class BaseForm {
  constructor() {
    extendObservable(this, {
      errors: {},
      _isLoading: false,
      nonFieldErrors: [],
      uid: uuid.v4(),
    });

    this._handleErrors = this._handleErrors.bind(this);
    this.setErrorsFromObject = this.setErrorsFromObject.bind(this);
    this._setFieldErrors = this._setFieldErrors.bind(this);
    this.setNonFieldErrors = this.setNonFieldErrors.bind(this);
    this.setProperty = this.setProperty.bind(this);
    this.setResponseErrors = this.setResponseErrors.bind(this);
    this.setLoading = this.setLoading.bind(this);
  }

  get isValid() {
    return Object.keys(toJS(this.errors)).length === 0 && toJS(this.nonFieldErrors).length === 0;
  }

  get isPersisted() {
    return Object.prototype.hasOwnProperty.call(this, 'id') && (this.id != null);
  }

  get isLoading() {
    return this._isLoading;
  }

  static get nestedForms() {
    return {};
  }

  get nestedForms() {
    return this.constructor.nestedForms;
  }

  static get namedNestedForms() {
    return [];
  }

  get namedNestedForms() {
    return this.constructor.namedNestedForms;
  }

  _handleErrors(errData) {
    if (errData.errors) {
      this.setErrorsFromObject(errData.errors);
    }
  }

  setErrorsFromObject(errorObject) {
    Object.keys(errorObject).forEach(
      (field) => this._setFieldErrors(field, errorObject[field]),
    );
  }

  _setFieldErrors(fieldName, errors) {
    if (fieldName === 'non_field_errors') {
      this.setNonFieldErrors(errors);
    } else if (Object.keys(this.nestedForms).includes(fieldName)) {
      // TODO add Collections
      errors.forEach(
        (errFormData) => {
          Object.keys(errFormData).forEach(
            (formUid) => {
              const form = find(this.nestedForms[fieldName], (o) => o.uid === formUid);
              form.setErrorsFromObject(errFormData[form.uid]);
            },
          );
        },
      );
    } else if (this.namedNestedForms.includes(fieldName)) {
      const form = this[fieldName];
      form.setErrorsFromObject(errors);
    } else {
      const mappedKey = find(this.constructor.fieldMapping, (o) => o.serverName === fieldName);
      this.errors[mappedKey.name] = errors;
    }
  }

  setNonFieldErrors(errors) {
    this.setProperty('nonFieldErrors', errors);
  }

  cleanErrors = () => {
    this.errors = {};
    Object.keys(this.nestedForms).forEach(
      (key) => this.nestedForms[key].forEach((form) => form.cleanErrors()),
    );
    this.namedNestedForms.forEach((formName) => this[formName].cleanErrors());
  };

  setProperty(attrName, val) {
    set(this, attrName, val);
  }

  setResponseErrors(response) {
    if (response && response.responseJSON) this._handleErrors(response.responseJSON);
  }

  setLoading(val) {
    this.setProperty('_isLoading', val);
  }

  get formData() {
    const data = {};
    this.formFields.forEach(
      (key) => {
        const mappedKey = find(this.constructor.fieldMapping, (o) => o.name === key);
        data[mappedKey.serverName] = this[mappedKey.name];
      },
    );
    return data;
  }

  get formFields() {
    return this.constructor.formFields;
  }
}
