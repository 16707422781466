import { useMemo } from 'react';

import useScreenWidth from 'core/hooks/useScreenWidth';
import { screenMMin, screenSMax, screenSMin } from '../styles';

const useScreenSize = () => {
  const width = useScreenWidth();

  const sizes = useMemo(() => ({
    width,
    isXSScreen: width < screenSMin,
    isGtSScreen: width > screenSMax,
    isLtMScreen: width < screenMMin,
  }), [width]);

  return sizes;
};

export default useScreenSize;
