import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { FullPageView } from 'core/page';
import { getUrl } from 'utils/urls';
import PageNotFoundContent from 'core/page/contentParts/404';
import { CertificatesIndexView } from './indexView';
import {
  CertificateTypeDetailView,
  CertificateTypeRegistryIssuersView,
  CertificateTypesListView,
  CreateCertificateTypeView,
} from './types/views';
import {
  CertificatesIssuingOverview,
  CertificateTypesListView as IssuerCertificateTypesListView,
  CertificateTypeDetailsView,
  IssueCertificateView,
} from './issuing/views';

const queryClient = new QueryClient();

const CertificatesNavigation = () => (
  <FullPageView>
    <QueryClientProvider client={queryClient}>
      <Router basename={getUrl('/certificates')}>
        <Routes>
          <Route path="/">
            <Route index element={<CertificatesIndexView />} />
            <Route path="management/types/">
              <Route index element={<CertificateTypesListView />} />
              <Route path="create/" element={<CreateCertificateTypeView />} />
              <Route path=":certificateTypeId">
                <Route index element={<CertificateTypeDetailView />} />
                <Route path="issuer-registries/:issuerRegistryId/" element={<CertificateTypeRegistryIssuersView />} />
              </Route>
            </Route>
            <Route path="issuing/">
              <Route index element={<CertificatesIssuingOverview />} />
              <Route path="issue/" element={<IssueCertificateView />} />
              <Route path="types/" element={<IssuerCertificateTypesListView />} />
              <Route path="types/:certificateTypeId/" element={<CertificateTypeDetailsView />} />
            </Route>
          </Route>
          <Route path="*" element={<PageNotFoundContent />} />
        </Routes>
      </Router>
    </QueryClientProvider>
  </FullPageView>
);

export default CertificatesNavigation;
