import PropTypes from 'prop-types';

const ICON_WEIGHTS = {
  regular: 'far',
  bold: 'fas',
};

const Icon = ({ name, onClick, className, weight, ...props }) => (
  <i className={`${ICON_WEIGHTS[weight]} fa-${name} ${className}`} onClick={onClick} {...props} />
);

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  weight: PropTypes.string,
};

Icon.defaultProps = {
  onClick: () => {},
  className: '',
  weight: 'bold',
};

export { Icon, ICON_WEIGHTS };
