/**
 * Generic utilities
 *
 * @todo move to core
 */


// WebView
export const isAndroidWebview = window.navigator.userAgent.includes('trustwise-android-webview');
export const isAndroidNewWebview = window.navigator.userAgent.includes('trustwise-android-webview-new');
export const isIosWebview = window.navigator.userAgent.includes('trustwise-ios-webview');
export const isIosNewWebview = window.navigator.userAgent.includes('trustwise-ios-webview-new');
export const isNativeAppWebview = isIosWebview || isAndroidWebview;
export const isNewNativeAppWebview = isAndroidNewWebview || isIosNewWebview;
export const androidAppInterface = isAndroidNewWebview ? window.AndroidInterface : undefined;
export const iOsAppInterface = isIosNewWebview ? window.webkit.messageHandlers : undefined;


export function messageAutoHide() {
  const message = document.getElementsByClassName('auto-hide');
  message[0].classList.remove('hidden');
  setTimeout(() => {
    message[0].classList.add('hidden');
  }, 5000);
}


export function copyToClipBoard(str) {
  const element = document.createElement('textarea');
  element.value = str;
  document.body.appendChild(element);
  element.select();
  document.execCommand('copy');
  document.body.removeChild(element);
}
