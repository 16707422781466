import { SimpleLegalEntityModel } from 'contacts/models/legalEntity';
import { Model } from 'core/model';

/**
 * Payment Token Model
 *
 * Props under restProps are:
 * id, address, name, currencyCode, peggedCurrencyCode, portfolioId
 *
 */
export class PaymentTokenModel extends Model {
  constructor({ amount, baseCurrencyAmount, ...restProps }) {
    super(restProps);
    this.amount = Number(amount);
    this.baseCurrencyAmount = Number(baseCurrencyAmount);
  }
}

/**
 * Payment Token Transfers Model
 *
 * Props under restProps are:
 * id, address, peggedCurrencyCode
 *
 */
export class PaymentTokenTransfersModel extends Model {
  constructor({ amount, dateTime, counterparty, currencyCode, ...restProps }) {
    super(restProps);
    this.currency = currencyCode;
    this.amount = Number(amount);
    this.dateTime = new Date(dateTime);
    this.counterparty = counterparty ? new SimpleLegalEntityModel(counterparty) : undefined;
  }
}
