import PropTypes from 'prop-types';

const FxScores = ({ lowest, highest, latest }) => (
  <div className="fx-scores">
    <div className="score">
      <div className="score-value">{lowest}</div>
      <div className="score-name">{gettext('lowest')}</div>
    </div>
    <div className="score">
      <div className="score-value">{latest}</div>
      <div className="score-name">{gettext('latest')}</div>
    </div>
    <div className="score">
      <div className="score-value">{highest}</div>
      <div className="score-name">{gettext('highest')}</div>
    </div>
  </div>
);

FxScores.defaultProps = {
  lowest: 0,
  highest: 0,
  latest: 0,
};

FxScores.propTypes = {
  lowest: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  highest: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  latest: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

export default FxScores;
