/* eslint-disable no-nested-ternary */
import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

import { Formik, Form } from 'formik';
import { SubmitButton } from 'core/forms/fields';
import axios from 'core/axios';
import { parseErrorResponse } from 'core/forms/utils';
import EventShareClassesList from './parts/shareClassesList';
import ShareClassesEditFormPart, { validationShareClasses } from './parts/shareClassesEditFrom';


const EventShareClasses = ({ eventId, companyId, accountId, isEditable }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [editShareClasses, setEditShareClasses] = useState(false);
  const [shareClassesList, setShareClassesList] = useState([]);
  const [fullShareClassesList, setFullShareClassesList] = useState([]);
  const [defaultShareClassesIds, setDefaultShareClassesIds] = useState([]);

  const fetchShareClassesList = useCallback(() => {
    setIsLoading(true);
    const fetchUrl = accountId ? (
      `/api/v1/${companyId ? `company/${companyId}/` : ''}meetings/${eventId}/account/${accountId}/share-classes/`
    ) : `/api/v1/company/${companyId}/templates/${eventId}/share-classes/`;
    axios.get(fetchUrl)
      .then(({ data }) => { setShareClassesList(data); })
      .catch(console.error)
      .finally(() => { setIsLoading(false); });

  }, [eventId, companyId, accountId]);

  useEffect(() => {
    fetchShareClassesList();
  }, [fetchShareClassesList]);

  const handleListEditSwitch = (_e) => {
    setIsLoading(true);
    axios.get(`/api/v1/company/${companyId}/all-share-classes/`)
      .then(
        ({ data }) => {
          setFullShareClassesList(data);
          if (shareClassesList.length) {
            const defaults = data.filter((item) => {
              const defaultsArray = shareClassesList.filter((shareClass) => shareClass.id === item.id);
              return !(defaultsArray.length === 0);
            });
            setDefaultShareClassesIds(defaults.map((defaultItem) => defaultItem.id));
          } else {
            setDefaultShareClassesIds([]);
          }
        },
      )
      .catch(console.error)
      .finally(() => { setIsLoading(false); });
    setEditShareClasses(true);
  };

  const onSubmit = (values, actions) => {
    const data = { share_classes: values.shareClasses };
    const submitUrl = accountId ? (
      `/api/v1/company/${companyId}/meetings/${eventId}/update-share-classes/`
    ) : `/api/v1/company/${companyId}/templates/${eventId}/update-share-classes/`;
    axios.patch(submitUrl, data)
      .then(
        ({ data: { share_classes: shareClasses } }) => {
          if (!isEqual(shareClasses, defaultShareClassesIds)) {
            fetchShareClassesList();
          }
          setEditShareClasses(false);
        },
        ({ response: { data: errors } }) => { parseErrorResponse(errors, actions.setFieldError); },
      )
      .catch(console.error)
      .finally(() => { actions.setSubmitting(false); });
  };

  return (
    <>
      <header className="row-flex not-padded top-margin">
        <h3 className="title font-size-16px">{gettext('Share Classes')}</h3>
        {isEditable === 'True' && !editShareClasses && (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          <button className="left-padding" type="button" onClick={handleListEditSwitch}>
            <i className="fa primary fa-pencil-alt" aria-hidden="true" />
          </button>
        )}
      </header>

      {!editShareClasses ? <EventShareClassesList shareClasses={shareClassesList} isLoading={isLoading} />
        : (
          <Formik
            initialValues={{
              nonFieldErrors: '',
              shareClasses: defaultShareClassesIds,
            }}
            enableReinitialize
            onSubmit={onSubmit}
            validationSchema={validationShareClasses}
          >
            {({ values, isSubmitting }) => (
              <Form>
                {fullShareClassesList.length ? (
                  <>
                    <ShareClassesEditFormPart
                      allShareClasses={fullShareClassesList}
                      values={values}
                    />
                    <SubmitButton disabled={isSubmitting}>{gettext('Save')}</SubmitButton>
                  </>
                ) : (
                  isLoading ?
                    <div className="loader" />
                    : (
                      <p className="text-center">
                        {gettext('No shares available. Please, make sure you issued and distributed registered shares.')}
                      </p>
                    )
                )}
              </Form>
            )}
          </Formik>
        )}
    </>
  );
};

EventShareClasses.defaultProps = {
  companyId: null,
};

EventShareClasses.propTypes = {
  eventId: PropTypes.string.isRequired,
  accountId: PropTypes.string.isRequired,
  isEditable: PropTypes.string.isRequired,
  companyId: PropTypes.string,
};

export default EventShareClasses;
