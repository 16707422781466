import PropTypes from 'prop-types';

import { CreateEventForm } from '@trustwise/design-system';
import useContacts from 'contacts/hooks';
import { getTimeZonesOptions } from 'core/const';
import { EVENT_TYPES_LABELS } from '../const';

const GovernanceEventUpdate = ({ eventOwner, eventType, onSubmit, onCancel }) => {
  const { onFetch } = useContacts(false, { 'entity-type': 'person', 'exclude-custodial': 1 });

  return (
    <CreateEventForm
      eventType={eventType}
      companyData={eventOwner}
      timeZoneFieldOptions={getTimeZonesOptions()}
      onContactsFetch={onFetch}
      onContactSearch={({ searchString }) => onFetch(searchString)}
      onCancel={onCancel}
      onSubmit={onSubmit}
    />
  );
};

GovernanceEventUpdate.defaultProps = {
  eventOwner: {},
};

GovernanceEventUpdate.propTypes = {
  eventOwner: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    avatar: PropTypes.shape({
      initials: PropTypes.string,
      color: PropTypes.string,
      image: PropTypes.string,
    }),
  }),
  eventType: PropTypes.oneOf(Object.keys(EVENT_TYPES_LABELS)).isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};


export default GovernanceEventUpdate;
