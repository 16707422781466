/* Modal with confirmation to accept or delete contact request */
import PropTypes from 'prop-types';
import { Formik, Form, ErrorMessage } from 'formik';
import { ModalWithContent as Modal } from '@trustwise/design-system';
import FormErrors from 'components/common/formikErrors';
import { SubmitButton } from 'core/forms/fields';

const UpdateModal = ({ modalId, title, isOpen, modalContent, onSubmit, onClose }) => (
  <Modal
    id={modalId}
    title={title}
    onClose={onClose}
    isOpen={isOpen}
  >
    <Formik
      initialValues={{ nonFieldErrors: '' }}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <ErrorMessage component={FormErrors} name="nonFieldErrors" />
          <div className="text-small">
            {modalContent.confirmationText ? modalContent.confirmationText : null}
          </div>
          <SubmitButton disabled={isSubmitting}>{gettext('Confirm')}</SubmitButton>
        </Form>
      )}
    </Formik>
  </Modal>
);

UpdateModal.defaultProps = {
  isOpen: false,
  title: '',
};

UpdateModal.propTypes = {
  isOpen: PropTypes.bool,
  modalId: PropTypes.string.isRequired,
  title: PropTypes.string,
  modalContent: PropTypes.shape({
    data: PropTypes.shape({
      allowed_portfolios: PropTypes.arrayOf(PropTypes.string),
    }),
    actionType: PropTypes.string,
    header: PropTypes.string,
    confirmationText: PropTypes.string,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default UpdateModal;
