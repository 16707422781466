import { TimeSeriesValuationType } from '@trustwise/design-system';
import { SimpleLegalEntityModelProps } from 'contacts/interfaces/types';
import { SimpleLegalEntityModel } from 'contacts/models/legalEntity';
import { Model } from 'core/model';

export interface TimeSeriesModelProps {
  /**
   * Database ID of a time series
   */
  id: number;
  /**
   * Name of the related token
   */
  tokenName: string;
  /**
   * Type of the valuation
   */
  valuationType: TimeSeriesValuationType;
  /**
   * Currency of the valuation
   */
  currency: string;
  /**
   * Source of the valuation
   */
  source: string;
  /**
   * Estimated by
   */
  estimatedBy: SimpleLegalEntityModelProps;
}

export interface ITimeSeriesModelProps extends Omit<TimeSeriesModelProps, 'currency'> {
  /**
   * Price quotation
   */
  quotation: string;
}

export class TimeSeriesModel extends Model implements ITimeSeriesModelProps {
  id: number;
  tokenName: string;
  valuationType: TimeSeriesValuationType;
  quotation: string;
  source: string;
  estimatedBy: SimpleLegalEntityModelProps;

  constructor({ estimatedBy, currency, ...restProps }: TimeSeriesModelProps) {
    super(restProps);
    this.quotation = currency;
    this.estimatedBy = SimpleLegalEntityModel.fromResponse(estimatedBy);
  }
}
