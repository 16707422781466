import { snakeCase } from 'lodash';

import axios from 'core/axios';
import { convertKeysCase } from 'core/utils';
import { getApiUrl } from 'utils/urls';
import { handleTransactionCreation } from 'core/page/transactionSigning/utils';

export const onProposalsUpdate = (values, actions, dispatch, agendaItemsPath) => {
  const { agendaItemId, proposals: proposalsValues } = values;
  const data = proposalsValues.map((proposal) => {
    if (typeof proposal.id === 'string') {
      delete proposal.id;
    }
    return convertKeysCase(proposal, snakeCase);
  });

  return axios.post(
    getApiUrl(`${agendaItemsPath}${agendaItemId}/proposals/edit/`),
    data,
  )
    .then(({ data: { proposals, tx_hash: txHash } }) => {
      if (proposals) {
        dispatch({ type: 'updateAgendaItem', data: { id: agendaItemId, proposals } });
      }
      if (txHash) {
        handleTransactionCreation(txHash);
      }
    })
    .catch(console.error)
    .finally(() => { actions.setSubmitting(false); });
};
