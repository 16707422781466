/* eslint-disable no-nested-ternary */
/**
 * Base list of multisig transactions.
 */

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ScrollableContent from 'core/page/scrollableContent';
import axios from 'core/axios';


const MultisigTransactionsList = ({ fetchUrl }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [nextPage, setNextPage] = useState('');

  const parseResponseLink = (responseNextLink) => {
    let responseNextPage = null;
    if (responseNextLink) {
      responseNextPage = new URL(responseNextLink).search;
    }
    return responseNextPage;
  };

  const fetchData = () => {
    axios.get(`${fetchUrl}${nextPage}`)
      .then(({ data }) => {
        parseResponseLink(data.next);
        setNextPage(parseResponseLink(data.next));
        setItems(items.concat(data.results));
      })
      .catch(console.error);
  };

  useEffect(() => {
    axios.get(`${fetchUrl}`)
      .then(({ data }) => {
        setNextPage(parseResponseLink(data.next));
        setItems(data.results);
      })
      .catch(console.error)
      .finally(() => { setIsLoading(false); });
  }, [fetchUrl]);

  return (
    <ScrollableContent
      dataLength={items.length}
      fetchNext={fetchData}
      hasMore={Boolean(nextPage && !isLoading)}
    >
      <ul>
        {items.map((item) => (
          <li key={item.blockchain_id} style={{ padding: '0 2px' }}>
            <a className="row-flex card-base" href={item.link}>
              <span className="no-overflow">
                <span className="date display-block">{item.date_created}</span>
                <span className="title display-block">{item.init_transaction_type}</span>
                <span className="text-tiny display-block">
                  {item.date_executed ?
                    gettext(`Execution date: ${item.date_executed}`) :
                    item.status === 2 ?
                      gettext(`Expiry on: ${item.date_expired}`) :
                      gettext(`Expiry date: ${item.date_expired}`)}
                </span>
                <span className="text-tiny display-block">
                  {gettext(`Signed by: ${item.confirmed_by}`)}
                </span>
                <span className={`text-tiny font-italic ${item.status === 2 ? 'alert' : ''}`}>{item.display_status}</span>
              </span>
            </a>
          </li>
        ))}
      </ul>
      {!items.length && (
      <p className="text-center">
        {isLoading ? gettext('Loading transactions') : gettext('No transactions')}
      </p>
      )}
    </ScrollableContent>
  );
};


MultisigTransactionsList.propTypes = {
  fetchUrl: PropTypes.string.isRequired,
};

export default MultisigTransactionsList;
