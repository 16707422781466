/* File delete component */
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Formik, Form, ErrorMessage } from 'formik';
import { ModalWithContent as Modal } from '@trustwise/design-system';
import { parseErrorResponse } from 'core/forms/utils';
import axios from 'core/axios';
import FormErrors from 'components/common/formikErrors';
import { getApiUrl } from 'utils/urls';
import { SubmitButton } from 'core/forms/fields';


const FileDelete = ({ isOpen, onClose, queryParams, isEditable, fileName, onSuccessResponse }) => {
  const { documentId, fileId } = useParams();

  const onSubmit = (actions) => (
    axios
      .delete(getApiUrl(`/new-documents/${documentId}/attachments/${fileId}/`, queryParams))
      .then(
        onSuccessResponse(),
        ({ response: { data: errors } }) => { parseErrorResponse(errors, actions.setFieldError); },
      )
      .catch(console.error)
      .finally(() => { actions.setSubmitting(false); })
  );

  return (
    <Modal
      id="fileDeleteModal"
      isOpen={isOpen}
      onClose={onClose}
      title={gettext('Remove file')}
    >
      {isEditable ? (
        <Formik
          initialValues={{
            nonFieldErrors: '',
          }}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <ErrorMessage component={FormErrors} name="nonFieldErrors" />
              <div className="text-small">
                {gettext(`Are you sure you want to remove ${fileName}?`)}
              </div>
              <SubmitButton disabled={isSubmitting}>{gettext('Remove')}</SubmitButton>
            </Form>
          )}
        </Formik>
      ) : gettext('File cannot be deleted.')}
    </Modal>
  );
};

FileDelete.defaultProps = {
  isOpen: false,
  isEditable: false,
  fileName: '',
  queryParams: '',
};

FileDelete.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  queryParams: PropTypes.string,
  isEditable: PropTypes.bool,
  fileName: PropTypes.string,
  onSuccessResponse: PropTypes.func.isRequired,
};

export default FileDelete;
