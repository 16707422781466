import { Component } from 'react';

/**
 * @deprecated DO NOT USE
*/
export default class Step extends Component {
  // eslint-disable-next-line react/no-unused-class-component-methods
  handleFormChange = (val, attr) => {
    // eslint-disable-next-line react/prop-types
    const { model } = this.props;
    // eslint-disable-next-line react/prop-types
    model.setProperty(attr, val);
  };
}
