/* Full react page view */
import { createRoot } from 'react-dom/client';

interface FullPageViewProps {
  /**
   * Content to render
   */
  children: React.ReactElement;
}

export const FullPageView = ({ children }: FullPageViewProps) => {
  const contentElement = document.getElementById('full-page-component');
  if (!contentElement) {
    return null;
  }
  const root = createRoot(contentElement);

  root.render(children);
  return null;
};
