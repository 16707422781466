import PropTypes from 'prop-types';
import { getUrl } from 'utils/urls';


const Widget = ({ id, title, action, children }) => (
  <div id={`${id}-widget`} className="widget">
    <div className="widget-header">
      <h4>{title}</h4>
      <a className="widget-action" href={getUrl(action.urlName)}>{action.label}</a>
    </div>
    {children}
  </div>
);

Widget.defaultProps = {
  title: '',
  action: {},
};

Widget.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  action: PropTypes.shape({
    urlName: PropTypes.string,
    label: PropTypes.string,
  }),
  children: PropTypes.element.isRequired,
};

export default Widget;
