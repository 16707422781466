import {
  extendObservable, autorun,
} from 'mobx';
import { currencyOptions } from 'core/options';
import Store from '../store';
import RatesCollection from '../../collections/markets/ratesCollection';


const setSince = {
  '1m': (date) => date.setMonth(date.getMonth() - 1),
  '6m': (date) => date.setMonth(date.getMonth() - 6),
  '1y': (date) => date.setYear(date.getFullYear() - 1),
  '2y': (date) => date.setYear(date.getFullYear() - 2),
  '5y': (date) => date.setYear(date.getFullYear() - 5),
};

class FxRatesStore extends Store {

  constructor() {
    super();
    extendObservable(this, {
      fromCurrency: 'CHF',
      toCurrency: 'EUR',
      since: null,
      selectedSinceOption: '1m',
      data: new RatesCollection(),
      currencyOptions,
      isRendered: false,
    });
    this.setSinceDate = this.setSinceDate.bind(this);
    this.setFromCurrency = this.setFromCurrency.bind(this);
    this.setToCurrency = this.setToCurrency.bind(this);
    this.switchCurrencyPair = this.switchCurrencyPair.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.setSinceDate('1m');

    autorun(() => {
      if (this.isRendered && this.fromCurrency && this.toCurrency && this.since) {
        this.fetchData();
      }
    }, { name: 'fetchDataAutorun', delay: 50 });
  }

  static get scopeOptions() {
    return Object.keys(setSince);
  }

  setSinceDate(period) {
    const date = new Date();
    setSince[period](date);
    this.since = date;
    this.setProperty('selectedSinceOption', period);
  }

  setFromCurrency(val) {
    this.setProperty('fromCurrency', val);
  }

  setToCurrency(val) {
    this.setProperty('toCurrency', val);
  }

  switchCurrencyPair() {
    [this.fromCurrency, this.toCurrency] = [this.toCurrency, this.fromCurrency];
  }

  fetchData() {
    this.data.clean();
    const requestData = {
      from_currency: this.fromCurrency,
      to_currency: this.toCurrency,
      since: Math.round(this.since.getTime() / 1000),
    };
    return this.data.fetch(requestData);
  }
}

export const fxRatesStore = new FxRatesStore();
export { FxRatesStore };
