import PropTypes from 'prop-types';
import { Field } from 'formik';

import FieldErrors from './errors';
import FieldLabel from './label';

/**
 * Text field with label and errors.
 *
 * TODO add other props as necessary (className, placeholder, etc.)
 */
const TextField = ({ autoComplete, isOptional, id, label, name, type, hideErrors, ...props }) => (
  // div is required to keep the label and field positions unaffected by the layout of the parent components
  <div>
    {label && (<FieldLabel id={id || name} label={label} isOptional={isOptional} />)}
    {!hideErrors && <FieldErrors name={name} />}
    <Field autoComplete={autoComplete} type={type} id={id || name} name={name} {...props} />
  </div>
);

TextField.defaultProps = {
  autoComplete: '',
  id: '',
  label: '',
  type: 'text',
  hideErrors: false,
  isOptional: false,
};

TextField.propTypes = {
  autoComplete: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  hideErrors: PropTypes.bool,
  isOptional: PropTypes.bool,
};

export default TextField;
