/* eslint-disable no-nested-ternary */
import { useEffect, useState } from 'react';
import { SkeletonItem } from '@trustwise/design-system';
import axios from 'core/axios';
import { getUrl, getApiUrl } from 'utils/urls';
import { DocumentModel } from 'media/models';
import { formatDateWithWeekday } from 'utils/date';
import { EmptyContent } from 'core/page';
import convertResponseToModel from 'utils/responseToModel';
import { DocumentInCircleIcon, UsersIcon, emptyStateIcons } from 'core/svgs';
import { getGlobalContext } from 'core/globals';


const signingStatusIcons = {
  signed: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      {/* eslint-disable-next-line max-len */}
      <path fillRule="evenodd" clipRule="evenodd" d="M6.29728 11.9112C6.2743 11.896 6.25249 11.8783 6.23225 11.858L3.05 8.67579C2.88728 8.51307 2.88728 8.24926 3.05 8.08654C3.21272 7.92382 3.47654 7.92382 3.63925 8.08654L6.64508 11.0924L12.596 5.14147C12.7587 4.97876 13.0225 4.97876 13.1852 5.14147C13.3479 5.30419 13.3479 5.56801 13.1852 5.73073L6.93971 11.9762C6.77699 12.139 6.51317 12.139 6.35045 11.9762C6.33021 11.956 6.31249 11.9342 6.29728 11.9112Z" fill="#00BE91" />
    </svg>
  ),
  inProgress: (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      {/* eslint-disable-next-line max-len */}
      <path fillRule="evenodd" clipRule="evenodd" d="M12.7268 6.97271H13.5788L13.5788 6.97269H13.5788V2.33334H12.7455V4.93586C11.7267 3.39257 9.97689 2.37435 7.98928 2.37435C5.21914 2.37435 2.91093 4.35216 2.39975 6.97271H3.25179C3.74906 4.81583 5.68137 3.20768 7.98928 3.20768C9.98855 3.20768 11.706 4.41447 12.4528 6.13936L8.93947 6.13936V6.97269L12.7268 6.97269L12.7268 6.97271ZM3.25147 9.00003H2.39944L2.39944 9.00005H2.39941V13.6394H3.23275L3.23275 11.0369C4.25155 12.5802 6.00135 13.5984 7.98896 13.5984C10.7591 13.5984 13.0673 11.6206 13.5785 9.00003H12.7264C12.2292 11.1569 10.2969 12.7651 7.98896 12.7651C5.98969 12.7651 4.27228 11.5583 3.52543 9.83338H7.03876V9.00005L3.25148 9.00005L3.25147 9.00003Z" fill="#7B7B7E" />
    </svg>
  ),
};

const DocumentsSigningList = () => {
  const [documents, setDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { activeEntity: { id: activeEntityId } } = getGlobalContext();

  useEffect(() => {
    const urlQueryParams = {
      related_object_model: 'legalentity',
      related_object_id: activeEntityId,
      no_of_items: 3,
    };
    axios.get(getApiUrl('/new-documents/documents-signing/', urlQueryParams))
      .then(({ data }) => { setDocuments(convertResponseToModel(data, DocumentModel)); })
      .catch(console.error)
      .finally(() => { setIsLoading(false); });
  }, [activeEntityId]);

  if (isLoading) {
    return [1, 2, 3].map((item) => (<SkeletonItem largerPadding key={item} />));
  }

  return (
    documents.length > 0 ? (
      <ul>
        {documents.map((doc) => (
          <a
            href={getUrl(`/documents-signing/${doc.id}/`)}
            key={doc.id}
            className="widget-content-row"
          >
            <DocumentInCircleIcon className="row-content-icon" />
            <div>
              <div className="widget-row-title">{doc.subject}</div>
              <div className="widget-row-main-content">
                <UsersIcon className="row-content-icon" />
                {doc.signerContract.signatories.map((signatory) => (signatory.name)).join(', ')}
              </div>
              <div className={`tag tag-outlined ${doc.signerContract.statusTagData.className}`}>
                {signingStatusIcons[doc.signerContract.statusTagData.icon]}
                <span className="label">{doc.signerContract.statusTagData.label}</span>
              </div>
              {/* <div>{doc.tags.map(tag => tag.name).join(', ')}</div> */}
              <div />
            </div>
            <div className="widget-left-column">{formatDateWithWeekday(doc.documentDate)}</div>
          </a>
        ))}
      </ul>
    ) : (
      <EmptyContent
        iconName={emptyStateIcons.document}
        text={gettext('No items to display')}
      />
    )
  );
};

export default DocumentsSigningList;
