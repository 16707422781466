/**
 * BO reporting related constants
 */
import * as Yup from 'yup';

/**
* Form sections based on active entity.
*/
export const sections = (companyId) => [
  {
    fieldName: 'actingOnItsOwn',
    formEnabled: !!companyId,
    label: companyId ?
      gettext('The Buyer is a company acting on its own.')
      :
      gettext('I, the Buyer, am holding these shares for myself (I am the beneficial owner).'),
  },
  {
    fieldName: 'actingInConcert',
    formEnabled: true,
    label: companyId ?
      gettext('The Buyer is a company holding these shares together with third parties (acting in concert).')
      :
      gettext(`I, the Buyer, am holding these shares together with third parties (acting in concert) and we are
        holding these shares for ourselves(We are all the beneficial owners).`),
  },
  {
    fieldName: 'actingAsTrustee',
    formEnabled: true,
    label: companyId ?
      gettext(`The Buyer is a company acting as a nominee/trustee and at least one of the beneficial owners
        holds (alone or together with third parties) 25% or more of the share capital or the voting rights of the Company.`)
      :
      gettext(`I, the Buyer, am holding these shares as a nominee/trustee and at least one of the beneficial owners holds
        (alone or together with third parties) 25% or more of the share capital or the voting rights of the Company.`),
  },
];


/**
 * Validation schema for the main beneficial ownership report form.
 */
export const validationSchema = (companyId) => Yup.object().shape({
  willHaveSignificantControl: Yup.string().oneOf(['yes', 'no'], gettext('Please select one option.')),
  actingOnItsOwn: Yup.bool().when(['willHaveSignificantControl', 'actingInConcert', 'actingAsTrustee'], {
    is: (willHaveSignificantControl, actingInConcert, actingAsTrustee) => (
      willHaveSignificantControl === 'yes' && !actingInConcert && !actingAsTrustee
    ),
    then: Yup.bool().oneOf([true], gettext('At least one of the options must be selected.')),
    otherwise: Yup.bool(),
  }),
  actingInConcert: Yup.bool(),
  actingAsTrustee: Yup.bool(),
  actingOnItsOwnCollection: Yup.array().when(['willHaveSignificantControl', 'actingOnItsOwn'], {
    is: (willHaveSignificantControl, actingOnItsOwn) => companyId && willHaveSignificantControl === 'yes' && actingOnItsOwn,
    then: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(gettext('Required')),
        address: Yup.string().required(gettext('Required')),
        additional_address: Yup.string(),
        postal_code: Yup.string(),
        city: Yup.string().required(gettext('Required')),
        country_code: Yup.string().required(gettext('Required')),
      }),
    ).min(1, gettext('At least one beneficial owner needs to be added.')),
    otherwise: Yup.array(),
  }),
  actingInConcertCollection: Yup.array().when(['willHaveSignificantControl', 'actingInConcert'], {
    // TODO can be simplified to just `true` after switching to boolean value for `willHaveSignificantControl`
    is: (willHaveSignificantControl, actingInConcert) => willHaveSignificantControl === 'yes' && actingInConcert,
    then: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(gettext('Required')),
        address: Yup.string().required(gettext('Required')),
        additional_address: Yup.string(),
        postal_code: Yup.string(),
        city: Yup.string().required(gettext('Required')),
        country_code: Yup.string().required(gettext('Required')),
      }),
    ).min(1, gettext('At least one beneficial owner needs to be added.')),
    otherwise: Yup.array(),
  }),
  actingAsTrusteeCollection: Yup.array().when(['willHaveSignificantControl', 'actingAsTrustee'], {
    // TODO can be simplified to just `true` after switching to boolean value for `willHaveSignificantControl`
    is: (willHaveSignificantControl, actingAsTrustee) => willHaveSignificantControl === 'yes' && actingAsTrustee,
    then: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(gettext('Required')),
        address: Yup.string().required(gettext('Required')),
        additional_address: Yup.string(),
        postal_code: Yup.string(),
        city: Yup.string().required(gettext('Required')),
        country_code: Yup.string().required(gettext('Required')),
      }),
    ).min(1, gettext('At least one beneficial owner needs to be added.')),
    otherwise: Yup.array(),
  }),
  legalConfirmation: Yup.bool().oneOf([true], gettext('Your agreement is necessary to proceed.')),
});


export const fieldMapping = {
  willHaveSignificantControl: 'will_have_significant_control',
  actingOnItsOwn: 'i_am_beneficial_owner',
  actingInConcert: 'we_are_beneficial_owners',
  actingAsTrustee: 'i_am_trustee_of_bo_with_significant_control',
  actingOnItsOwnCollection: 'buyer_beneficial_owners',
  actingInConcertCollection: 'parties_in_concert',
  actingAsTrusteeCollection: 'beneficial_owners',
  nonFieldErrors: 'non_field_errors',
};

export const textInputs = [
  { name: 'name', label: gettext('Name') },
  { name: 'address', label: gettext('Address Line 1'), placeholder: gettext('Street and number, P.O. box, c/o') },
  { name: 'additional_address', label: gettext('Address Line 2'), placeholder: gettext('Apartment, unit, building, floor') },
  { name: 'postal_code', label: gettext('Postal code') },
  { name: 'city', label: gettext('Town/City') },
];
