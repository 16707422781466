import PropTypes from 'prop-types';

export default class SignatoryModel {
  constructor({ name, role, id, hasSigned, signedBy }) {
    this.id = id;
    this.name = name;
    this.role = role;
    this.hasSigned = hasSigned;
    this.signedBy = signedBy;
  }

  static propType = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    role: PropTypes.string,
    hasSigned: PropTypes.bool,
    signedBy: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })),
  });
}
