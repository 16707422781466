import { useEffect, useState } from 'react';
import { camelCase } from 'lodash';

import { CbdcOverviewWidget } from '@trustwise/design-system';
import axios from 'axios';
import { getApiUrl } from 'utils/urls';
import { convertKeysCase } from 'core/utils';

class OverviewWidgetModel {
  constructor({ blockedForBanknotes, currencyCode, liquidity, totalSupply }) {
    this.blockedForBanknotes = Number(blockedForBanknotes);
    this.currencyCode = currencyCode;
    this.liquidity = Number(liquidity);
    this.totalSupply = Number(totalSupply);
  }
}

const OverviewWidget = () => {
  const [overviewData, setOverviewData] = useState();

  useEffect(() => {
    axios
      .get(getApiUrl('/payment-tokens/issuer/cbdc/'))
      .then(({ data }) => {
        setOverviewData(new OverviewWidgetModel(convertKeysCase(data, camelCase)));
      })
      .catch(console.error);
  }, []);

  return (
    <CbdcOverviewWidget {...overviewData} />
  );
};

export default OverviewWidget;
