import { createContext, useContext } from 'react';

export const UnsignedTransactionContext = createContext({
  txStatus: undefined,
  dispatch: undefined,
  baseTrxUrl: '',
  transactionDetails: undefined,
  nativeSigningUrl: undefined,
  baseTxInfo: {
    unsignedHash: '',
    accountId: '',
    accountName: '',
  },
  redirect: {
    url: '',
    shouldRedirect: false,
  },
  modalState: {
    isPendingOpen: false,
    isSigningOpen: false,
  },
});

export const useUnsignedTransactionContext = () => useContext(UnsignedTransactionContext);
