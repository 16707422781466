import { useReducer, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { getApiUrl } from 'utils/urls';
import { convertKeysToCamelCase } from 'core/utils';
import axios from 'core/axios';
import { TabsWithContent } from 'core/tabs/tabs';
import { actionRequired, inProgress, completed, tabOptions } from './const';
import InboxItemModel from './model';


const reducer = (currentState, action) => {
  const { data } = action;
  switch (action.type) {
    case 'resetInboxItems':
      return {
        ...currentState,
        inboxItems: undefined,
      };
    case 'fetch': {
      const itemsAsModel = [];
      const notSeenIds = [];
      data.results.forEach((item) => {
        itemsAsModel.push(new InboxItemModel(convertKeysToCamelCase(item)));
        if (item.status !== actionRequired && item.not_seen) { notSeenIds.push(item.item_id); }
      });
      return {
        ...currentState,
        inboxItems: itemsAsModel,
        nextPage: data.next ? `${new URL(data.next).pathname}${new URL(data.next).search}` : null,
        notSeenIds,
      };
    }
    case 'fetchNext': {
      const itemsAsModel = [];
      const notSeenIds = [];
      data.results.forEach((item) => {
        itemsAsModel.push(new InboxItemModel(convertKeysToCamelCase(item)));
        if (item.status !== actionRequired && item.not_seen) { notSeenIds.push(item.item_id); }
      });
      return {
        ...currentState,
        inboxItems: currentState.inboxItems.concat(itemsAsModel),
        nextPage: data.next ? `${new URL(data.next).pathname}${new URL(data.next).search}` : null,
        notSeenIds: currentState.notSeenIds.concat(notSeenIds),
      };
    }
    case 'setTabCounts':
      return {
        ...currentState,
        tabCounts: data,
      };
    case 'updateNotSeen': {
      const notSeenItems = currentState.inboxItems.filter((item) => data.notSeenIds.includes(item.itemId));
      // eslint-disable-next-line no-param-reassign
      notSeenItems.forEach((item) => { item.notSeen = false; });
      return {
        ...currentState,
        inboxItems: currentState.inboxItems,
        tabCounts: {
          ...currentState.tabCounts,
          [data.activeTab]: currentState.tabCounts[data.activeTab] - data.notSeenIds.length,
        },
        notSeenIds: currentState.notSeenIds.filter((id) => !data.notSeenIds.includes(id)),
      };
    }
    case 'remove': {
      const { inboxItems } = currentState;
      const removeItem = inboxItems.find((item) => item.itemId === data.itemId);
      inboxItems.splice(inboxItems.indexOf(removeItem), 1);
      return {
        ...currentState,
        inboxItems,
      };
    }
    default:
      return currentState;
  }
};

const InboxNavigation = ({ listComponent, parentWidth, noOfItems, tabsClasses }) => {
  const Component = listComponent;
  const [activeTab, setActiveTab] = useState(actionRequired);
  const [state, dispatch] = useReducer(reducer, {
    tabCounts: {
      [actionRequired]: undefined,
      [inProgress]: undefined,
      [completed]: undefined,
    },
    inboxItems: undefined,
    nextPage: null,
    notSeenIds: [],
  });

  useEffect(() => {
    const urlParams = { status: activeTab };
    if (noOfItems) {
      urlParams.no_of_items = noOfItems;
    }
    axios.get(getApiUrl('/inbox/', urlParams))
      .then(({ data }) => { dispatch({ data, type: 'fetch' }); })
      .catch(console.error);
  }, [activeTab, dispatch, noOfItems]);

  useEffect(() => {
    if (state.notSeenIds.length && activeTab !== actionRequired) {
      axios.post(getApiUrl('/inbox/seen/'), { not_seen: state.notSeenIds })
        .then(({ data }) => {
          dispatch({ data: { notSeenIds: data, activeTab }, type: 'updateNotSeen' });
        })
        .catch(console.error);
    }
  }, [activeTab, state, dispatch]);


  useEffect(() => {
    axios.get(getApiUrl('/inbox/count/'))
      .then(({ data }) => { dispatch({ data, type: 'setTabCounts' }); })
      .catch(console.error);
  }, [dispatch]);

  const tabs = Array.from(
    Object.keys(tabOptions),
    (key) => ({
      isActive: activeTab === key,
      label: `${tabOptions[key].label} ${state.tabCounts[key] > 0 ? `(${state.tabCounts[key]})` : ''}`,
      onClick: () => { dispatch({ type: 'resetInboxItems' }); setActiveTab(key); },
      isDisabled: activeTab === key,
    }),
  );

  return (
    <TabsWithContent tabs={tabs} isLoading={Object.values(state.tabCounts).includes(undefined)} classNames={tabsClasses}>
      <Component
        state={state}
        dispatch={dispatch}
        activeTab={activeTab}
        parentWidth={parentWidth}
      />
    </TabsWithContent>
  );
};

InboxNavigation.defaultProps = {
  parentWidth: window.innerWidth,
  noOfItems: null,
  tabsClasses: '',
};

InboxNavigation.propTypes = {
  listComponent: PropTypes.func.isRequired,
  parentWidth: PropTypes.number,
  noOfItems: PropTypes.number,
  tabsClasses: PropTypes.string,
};

export default InboxNavigation;
