import AccountSwitcherSidebar from '../../components/accountSwitcher/sidebar';
import { renderComponent } from '../../utils/renderComponent';

export default class RightSideBarComponent {
  render(elementId) {
    return renderComponent({
      elementId,
      component: AccountSwitcherSidebar,
    });
  }
}
