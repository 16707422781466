import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import PageNotFoundContent from 'core/page/contentParts/404';
import { getUrl } from 'utils/urls';
import { FullPageView } from 'core/page';
import { DataVerificationsListView, DataSetDetailsView, SubmitVerdictView } from './views';

const queryClient = new QueryClient();

export const DataVerificationNavigation = () => (
  <FullPageView>
    <QueryClientProvider client={queryClient}>
      <Router basename={getUrl('/services/data-verification/')}>
        <Routes>
          <Route path="/">
            <Route index element={<DataVerificationsListView />} />
            <Route path="data-sets/:dataSetId/">
              <Route index element={<DataSetDetailsView />} />
              <Route path="verdict/" element={<SubmitVerdictView />} />
            </Route>
          </Route>
          <Route path="*" element={<PageNotFoundContent />} />
        </Routes>
      </Router>
    </QueryClientProvider>
  </FullPageView>
);
