import { snakeCase } from 'lodash';

import { convertKeysCase } from 'core/utils';
import { signFormTransaction } from 'utils/signTransaction';

export const onNewMemberAdd = ({ values, actions, submitPath, navigatePath }) => {
  const { boardMember, ...restProps } = values;

  if (typeof boardMember === 'undefined') {
    throw new Error("Board member wasn't provided");
  }

  const data = {
    ...convertKeysCase(restProps, snakeCase),
    legal_entity_id: boardMember.id,
  };

  return signFormTransaction({
    urlPath: submitPath,
    navigatePath,
    data,
    actions,
  });
};
