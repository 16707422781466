/* Page content component */
import { Button } from 'core/forms/fields/button';
import { getUrl } from 'utils/urls';


const PageNotFoundContent = () => {
  document.title = gettext('Page not found');

  return (
    <div className="content-empty">
      <i className="fa content-icon fa-file fa-4x" aria-hidden="true" />
      <p className="text-center">{gettext("This page doesn't exist.")}</p>
      <Button onClick={() => { window.location = getUrl(); }}>
        {gettext('Return to Dashboard')}
      </Button>
    </div>
  );
};

export default PageNotFoundContent;
