import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PaymentTokensListView } from '@trustwise/design-system';
import { getGlobalContext } from 'core/globals';
import { getUrl } from 'utils/urls';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import { getChartData } from 'investments/utils';
import { getPaymentTokens, getPaymentTokensTransfers, getTokenForPortfolio } from './actions';

const PaymentTokenAssetsListView = () => {
  const [tokens, setTokens] = useState();
  const [transfers, setTransfers] = useState();
  const navigate = useNavigate();
  const cashPathPart = '/cash/';

  const { activeEntity: { baseCurrency } } = getGlobalContext();

  useEffect(() => { getPaymentTokens(setTokens); }, []);

  useEffect(() => {
    getPaymentTokensTransfers(setTransfers, { no_of_items: 4 });
  }, []);

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Cash')}
      headerLeft={<BackButton href={getUrl('/')} />}
    >
      <PaymentTokensListView
        tokens={tokens}
        chartData={tokens ? getChartData(tokens) : undefined}
        baseCurrency={baseCurrency}
        transactions={transfers}
        onSeeAll={() => navigate(`${cashPathPart}transfers/`, { state: { previousPath: getUrl(`/assets${cashPathPart}`) } })}
        onTransfer={(tokenId, portfolioId) => navigate(
          `${cashPathPart}${portfolioId}/${tokenId}/transfer/`,
          { state: { token: getTokenForPortfolio(tokens, tokenId, portfolioId) } },
        )}
      />
    </PageContent>
  );
};

export default PaymentTokenAssetsListView;
