import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';

const FilterBasedList = () => (
  <PageContent
    fullscreen
    pageHeading={gettext('Series B capital increase')}
    headerLeft={<BackButton href="/" useRouterLink />}
  >
    <>
      <header className="row-flex not-padded top-margin underline">
        <h3 className="title font-size-16px">{gettext('Allowed domicile')}</h3>
      </header>
      <p className="text-smaller padded-top">
        AT, CH, DE
      </p>
      <header className="row-flex not-padded top-margin underline">
        <h3 className="title font-size-16px">{gettext('Investor type')}</h3>
      </header>
      <p className="text-smaller padded-top">
        {gettext('Qualified investor')}
      </p>
      <p className="align-text-center font-bold">
        {gettext('Coming soon!')}
      </p>
    </>
  </PageContent>
);

export default FilterBasedList;
