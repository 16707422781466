import { useEffect, useState } from 'react';

import { EventsWidget as UpcomingEvents } from '@trustwise/design-system';
import { getApiUrl, getUrl } from 'utils/urls';
import { getGovernanceEventDetailPath, getStatusesURLParams } from 'governanceEvents/utils';
import { GovernanceEventListItemModel } from 'governanceEvents/models';
import axios from 'core/axios';
import convertResponseToModel from 'utils/responseToModel';
import { getLinkInteraction } from 'core/utils';
import {
  ONGOING_GROUP,
  UPCOMING_GROUP,
} from '../governanceEvents/const';

const EventsWidget = () => {
  const [events, setEvents] = useState(undefined);

  useEffect(() => {
    const urlParams = new URLSearchParams({ order_by: 'date_time', no_of_items: 3 });
    getStatusesURLParams([ONGOING_GROUP, UPCOMING_GROUP], urlParams);
    axios.get(getApiUrl('/meetings/', urlParams))
      .then(({ data }) => { setEvents(convertResponseToModel(data, GovernanceEventListItemModel)); })
      .catch(console.error);
  }, []);
  return (
    <UpcomingEvents
      items={events}
      // @todo pass event type
      onItemClick={(eventId) => {
        const { eventType } = events.find((item) => item.id === eventId);
        const url = getUrl(getGovernanceEventDetailPath(eventId, eventType, '/meetings/'));
        return getLinkInteraction(url);
      }}
      onSeeAll={() => { window.location = getUrl('/meetings/'); }}
    />
  );
};

export default EventsWidget;
