import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { CertificateTypesSection } from '@trustwise/design-system';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import { getIssuerCertificateTypes } from '../actions';

export const CertificateTypesListView = () => {
  const navigate = useNavigate();

  const { data: certificateTypes } = useQuery({
    queryKey: ['issuerCertificateTypes'],
    queryFn: () => getIssuerCertificateTypes(),
  });

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Certificates Types')}
      headerLeft={<BackButton href="/issuing/" useRouterLink />}
    >
      <CertificateTypesSection
        title={gettext('All Certificate Types')}
        certificateTypes={certificateTypes}
        onItemClick={(id: number) => navigate(`${id}/`)}
      />
    </PageContent>
  );
};
