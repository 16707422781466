import { convertKeysToCamelCase } from 'core/utils';
import { SimpleDocumentModel } from '../../media/models/document';

export const quorumToRepresentation = (quorum, quorumName) => {
  const { percent, numerator, denominator } = quorum;
  return {
    [`${quorumName}Type`]: percent ? 'percent' : 'fraction', // fraction is also a default if no quorum is set
    [`${quorumName}`]: percent ||
      (numerator ? [numerator, denominator] : [2, 3]), // get quorum values or default (needed for form init values)
  };
};

export class ProposalResultsModel {
  constructor({
    passed,
    participation,
    tokens,
    capital,
    attendance,
    voters,
  }) {
    this.passed = passed;
    this.participation = convertKeysToCamelCase(participation);
    this.tokens = tokens;
    this.capital = capital;
    this.attendance = attendance ? {
      ...attendance,
      noOfVotes: attendance.votes,
    } : undefined;
    this.voters = voters ? {
      votedFor: voters.for.map((voter) => convertKeysToCamelCase(voter)),
      votedAgainst: voters.against.map((voter) => convertKeysToCamelCase(voter)),
      votedAbstain: voters.abstain.map((voter) => convertKeysToCamelCase(voter)),
    } : undefined;
  }
}

const setProposalsModel = (proposals) => proposals.map((proposal) => (proposal.results ? ({
  ...convertKeysToCamelCase(proposal),
  results: new ProposalResultsModel(proposal.results),
}) : convertKeysToCamelCase(proposal)));

export default class AgendaItemModel {
  constructor({
    id,
    title,
    description,
    protocol,
    votingType,
    quorums = null,
    documents = [],
    proposals = [],
    isResolved,
    isSimultaneous,
  }) {
    this.id = id;
    this.description = description;
    this.protocol = protocol;
    this.quorums = quorums || {};
    this.title = title;
    this.votingType = votingType;
    this._documents = SimpleDocumentModel.fromArrayResponse(documents);
    this._proposals = setProposalsModel(proposals);
    this.isResolved = isResolved;
    this.isSimultaneous = isSimultaneous;
  }

  set proposals(proposals) { this._proposals = setProposalsModel(proposals); }

  get proposals() { return this._proposals; }

  set documents(documents) { this._documents = SimpleDocumentModel.fromArrayResponse(documents); }

  get documents() { return this._documents; }

  get votingEnabled() { return !!this.votingType; }

  get doubleQuorumEnabled() {
    const tokensQuorum = this.quorums.tokens || {};
    const capitalQuorum = this.quorums.capital || {};
    return !!Object.keys(tokensQuorum).length && !!Object.keys(capitalQuorum).length;
  }

  get attendanceQuorumEnabled() {
    const attendance = this.quorums.attendance || {};
    return !!Object.keys(attendance).length;
  }

  get quorumBase() {
    if (this.votingEnabled && !this.doubleQuorumEnabled) {
      return this.quorums.capital ? 'capital' : 'tokens';
    }
    return 'tokens';
  }

  get quorumValues() {
    const tokensQuorum = this.quorums.tokens || {};
    const capitalQuorum = this.quorums.capital || {};
    const attendanceQuorum = this.quorums.attendance || {};
    return {
      ...quorumToRepresentation(attendanceQuorum, 'attendanceQuorum'),
      ...quorumToRepresentation(capitalQuorum, 'capitalQuorum'),
      ...quorumToRepresentation(tokensQuorum, 'tokensQuorum'),
    };
  }

  get isVotingOpen() { return !!this.proposals.find((proposal) => proposal.status === 'open'); }

}
