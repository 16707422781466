import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { ReactSelectField } from 'core/forms/fields';
import { Icon } from 'components/common/icon';

const CurrencyPairSelect = ({
  options, fromCurrency, toCurrency, onSetTo, onSetFrom, onSwitchClick,
}) => (
  <Formik
    initialValues={{
      fromCurrency,
      toCurrency,
    }}
    validationSchema={Yup.object({
      fromCurrency: Yup.string(),
      toCurrency: Yup.string(),
    })}
  >
    <Form>
      <div className="currency-pair-select">
        <ReactSelectField
          name="fromCurrency"
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.code}
          options={options}
          setValue={(option) => onSetFrom(option.code)}
          isSearchable
          value={options.find((option) => option.code === fromCurrency)}
        />
        <div className="currency-pair-switch">
          <Icon
            name="exchange-alt"
            onClick={onSwitchClick}
          />
        </div>
        <ReactSelectField
          name="toCurrency"
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.code}
          options={options}
          setValue={(option) => onSetTo(option.code)}
          isSearchable
          value={options.find((option) => option.code === toCurrency)}
        />
      </div>
    </Form>
  </Formik>
);

CurrencyPairSelect.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array,
  fromCurrency: PropTypes.string.isRequired,
  toCurrency: PropTypes.string.isRequired,
  onSetTo: PropTypes.func,
  onSetFrom: PropTypes.func,
  onSwitchClick: PropTypes.func,
};

CurrencyPairSelect.defaultProps = {
  options: [],
  onSetTo: () => {},
  onSetFrom: () => {},
  onSwitchClick: () => {},
};

export default observer(CurrencyPairSelect);
