import { useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from '@trustwise/design-system';
import { SimpleLegalEntityModel } from '../contacts/models/legalEntity';
import ShareholdersExcerptModal from './modals/shareholdersExcerptModal';

const SingleShareholderExcerptModal = ({ id, name, avatar }) => {
  const [isOpen, setIsOpen] = useState(false);
  const shareholder = new SimpleLegalEntityModel({
    id: parseInt(id, 10),
    name,
    avatar: JSON.parse(avatar.replaceAll("'", '"').replaceAll('None', '""')),
  });

  return (
    <>
      <Button
        mode="link"
        size="small"
        label={gettext('Excerpt')}
        onClick={() => { setIsOpen(true); }}
      />
      <ShareholdersExcerptModal
        isOpen={isOpen}
        onClose={() => { setIsOpen(false); }}
        shareholder={shareholder}
        submitUrlPath={`/ownership/shareholders/${id}/excerpt/`}
      />
    </>
  );
};

SingleShareholderExcerptModal.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
};


export default SingleShareholderExcerptModal;
