import PropTypes from 'prop-types';
import GovEventTemplateCreateForm from './form';
import { boardMeeting } from '../constants';

const BoardEventTemplateCreateForm = ({ companyId, companyTimezone }) => (
  <GovEventTemplateCreateForm
    companyId={companyId}
    companyTimezone={companyTimezone}
    templateCategory="board"
    defaultEvent={boardMeeting}
    leftUrl={`/company/${companyId}/boardroom/templates/`}
  />
);

BoardEventTemplateCreateForm.defaultProps = {
  companyTimezone: '',
};

BoardEventTemplateCreateForm.propTypes = {
  companyId: PropTypes.string.isRequired,
  companyTimezone: PropTypes.string,
};

export default BoardEventTemplateCreateForm;
