import PropTypes from 'prop-types';
import { BaseButton } from 'core/forms/fields/button';
import { Icon, ActionLabel } from './generic';


const ButtonAction = ({ className, icon, label, onClick, isDisabled, type }) => (
  <BaseButton className={className} disabled={isDisabled} onClick={onClick} type={type}>
    {icon && <Icon iconName={icon} />}
    <ActionLabel text={label} />
  </BaseButton>
);

ButtonAction.defaultProps = {
  isDisabled: false,
  type: 'button',
  className: 'button button-small button-outlined',
};

ButtonAction.propTypes = {
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['button', 'submit']),
};

export default ButtonAction;
