import { QueryClient, QueryClientProvider, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { VerificationsView } from '@trustwise/design-system';
import { FullPageView } from 'core/page';
import { getVerificationsSummary, getDataSets, getDataSet } from './actions';
import { ExternalAccessDataSetModel } from './models';

export const Verifications = () => {
  document.title = gettext('Verifiable data');
  const queryClient = useQueryClient();
  const baseUrl = '/api/v1/data-rooms/external-access';
  const accessKey = new URLSearchParams(window.location.search).get('key');

  if (!accessKey) {
    throw new Error('Access key is not provided');
  }

  const { data: summary } = useQuery({ queryKey: ['dataRoom', accessKey], queryFn: getVerificationsSummary });
  const { data: dataSets } = useQuery({ queryKey: ['dataSets', accessKey], queryFn: getDataSets });

  const { mutateAsync } = useMutation({
    mutationFn: getDataSet,
    onSuccess: (newDataSet, { dataSetId }) => {
      queryClient.setQueryData(
        ['dataSets', accessKey],
        (oldDataSets?: ExternalAccessDataSetModel[]) => (
          oldDataSets
            ? oldDataSets.map((dataSet) => (dataSet.id === dataSetId ? newDataSet : dataSet))
            : [newDataSet]
        ),
      );
    },
  });

  return (
    <FullPageView>
      <VerificationsView
        {...summary}
        dataSets={dataSets}
        onVerifierReportDownload={(fileId: number, dataSetId: number) => {
          window.location.href = `${baseUrl}/data-sets/${dataSetId}/reports/${fileId}/download/?key=${accessKey}`;
        }}
        onDataSetClick={(dataSetId: number) => mutateAsync({ dataSetId, accessKey }).then((dataSet) => dataSet)}
      />
    </FullPageView>
  );

};

export const DataSetExternalAccessView = () => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <Verifications />
    </QueryClientProvider>
  );
};

