import { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import WrappedInLink from './hoc/wrappedInLink';

class Avatar extends Component {
  get imageStyles() {
    const { color, image, size } = this.props;

    let overrideStyles = { background: color };

    if (image) {
      overrideStyles = { ...overrideStyles, background: 'none' };
    }

    if (size === 'large') {
      overrideStyles = {
        ...overrideStyles,
        maxHeight: '70px',
        maxWidth: '70px',
        minWidth: '40px',
        minHeight: '40px',
      };
    }

    return overrideStyles;
  }

  renderName = () => {
    const { name } = this.props;

    if (!name) return null;

    return <div className="cell small-12 centered"><span className="name">{name.text}</span></div>;
  };

  renderInitials = () => {
    const { initials, itemClass } = this.props;
    const itemClassNames = `avatar-initials ${itemClass}`;

    if (typeof initials === 'string') {
      return <span className={itemClassNames}>{initials}</span>;
    }

    return initials;
  };

  renderContent = () => {
    const { image, name, badge } = this.props;

    let afterImage;
    // TODO rewrite this using container class and styles
    if (name) {
      afterImage = this.renderName();
    }
    if (image) {
      return (
        <>
          <div className="avatar-image centered" style={this.imageStyles}>
            <img className="image" src={image} alt="profile" />
            {badge}
          </div>
          {afterImage}
        </>
      );
    }

    return (
      <>
        <div className="avatar-image centered" style={this.imageStyles}>
          {this.renderInitials()}
          {badge}
        </div>
        {afterImage}
      </>
    );
  };

  render() {
    const { onClick, itemClass } = this.props;
    const itemClassNames = `avatar ${itemClass}`;
    return (
      <div
        className={itemClassNames}
        onClick={onClick}
      >
        {this.renderContent()}
      </div>
    );
  }
}

Avatar.defaultProps = {
  onClick: () => {},
  image: null,
  initials: '',
  itemClass: '',
  color: '',
  size: 'small',
  name: null,
  badge: null,
};

Avatar.propTypes = {
  onClick: PropTypes.func,
  image: PropTypes.string,
  initials: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  itemClass: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  name: PropTypes.shape({
    text: PropTypes.string,
    position: PropTypes.string,
  }),
  badge: PropTypes.node,
};

export default observer(WrappedInLink(Avatar));
