import { useState, useEffect } from 'react';
import { getWidgetPadding } from 'core/styles';
import InboxNavigation from 'inbox/navigation';
import InboxItemsList from 'inbox/list';
import Widget from './base';


const InboxWidget = () => {
  const [widgetWidth, setWidgetWidth] = useState(0);
  const widgetSidesPadding = getWidgetPadding(window.innerWidth) * 2;
  useEffect(() => {
    // widget width without padding
    setWidgetWidth(document.getElementById('inbox-widget').clientWidth - widgetSidesPadding);
  }, [widgetSidesPadding]);

  return (
    <Widget
      id="inbox"
      title={gettext('Inbox')}
      action={{ label: gettext('See all'), urlName: '/inbox/' }}
    >
      <InboxNavigation
        listComponent={InboxItemsList}
        parentWidth={widgetWidth}
        noOfItems={3}
        tabsClasses="widget-tabs"
      />
    </Widget>
  );
};

InboxWidget.propTypes = {

};

export default InboxWidget;
