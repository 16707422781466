import { snakeCase } from 'lodash';

import { convertKeysCase, convertNestedKeysCase } from 'core/utils';
import { getApiUrl } from 'utils/urls';
import { onFileDownload } from 'media/utils';
import { signTransaction } from 'utils/signTransaction';
import axios from 'core/axios';

export const onAgendaItemsSort = (updatedIds, agendaItemsPath, dispatch) => {
  dispatch({ type: 'sortAgenda', data: updatedIds });
  axios.patch(getApiUrl(`${agendaItemsPath}sort/`), updatedIds)
    .then(({ data }) => dispatch({ type: 'sortAgenda', data }))
    .catch(console.error);
};

export const onAgendaItemDelete = (id, agendaItemsPath, dispatch) => {
  axios.delete(getApiUrl(`${agendaItemsPath}${id}/`))
    .then(() => dispatch({ type: 'deleteAgendaItem', data: id }))
    .catch(console.error);
};

export const onSuggestedItemAdd = (suggestedItem, agendaItemsPath, dispatch) => {
  const itemData = convertKeysCase(suggestedItem, snakeCase);
  if (!itemData.proposals) {
    delete itemData.proposals;
  }
  return axios.post(getApiUrl(agendaItemsPath), { ...itemData, from_suggested: true })
    .then(({ data }) => { dispatch({ type: 'addAgendaItem', data }); })
    .catch(console.error);
};

export const onVotingStart = (reqPath, setDisabled, proposalsIds) => (
  signTransaction({
    urlPath: reqPath,
    setDisabled,
    data: { proposals_ids: proposalsIds },
  })
);

export const onVotingStop = (reqPath, setDisabled, proposalsIds) => (
  signTransaction({
    urlPath: reqPath,
    setDisabled,
    data: { proposals_ids: proposalsIds },
  })
);

export const onMarkAsResolved = (id, agendaItemsPath, dispatch, setDisabled) => (
  axios.patch(getApiUrl(`${agendaItemsPath}${id}/resolve/`))
    .then(({ data }) => dispatch({ type: 'resolveAgendaItem', data }))
    .catch((error) => {
      console.error(error);
      setDisabled(false);
    })
);

export const onVote = (reqPath, setDisabled, votes) => {
  signTransaction({
    urlPath: reqPath,
    setDisabled,
    data: { votes },
  });
};

export const onAgendaItemUpdate = (reqPath, reqBody, actions, dispatch) => (
  axios.patch(
    getApiUrl(reqPath),
    convertNestedKeysCase(reqBody, snakeCase),
  )
    .then(({ data }) => { dispatch({ type: 'updateAgendaItem', data }); })
    .catch((error) => {
      console.error(error);
      actions.setSubmitting(false);
    })
);

export const onAgendaFileDownload = (fileId, agendaItems, eventBasePath) => {
  const documents = agendaItems.reduce((acc, agendaItem) => [...acc, ...agendaItem.documents], []);
  onFileDownload(fileId, documents, eventBasePath);
};
