import { useContext } from 'react';
import PropTypes from 'prop-types';
import { snakeCase, camelCase } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';

import { CreateAgendaForm } from '@trustwise/design-system';
import axios from 'core/axios';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import { convertKeysCase } from 'core/utils';
import { addMultipleDocuments } from 'media/utils';
import { EventsContext } from 'governanceEvents/context';
import { BOARD_EVENTS, EVENT_TYPES_LABELS } from '../../const';
import { quorumToValue } from './utils';

const BaseAgendaItemUpdate = ({ initialValues, submitUrl, pageHeading, isUpdate, eventType }) => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const method = isUpdate ? 'patch' : 'post';
  const { indexPath } = useContext(EventsContext);
  const eventDetailsPath = `${indexPath}${eventId}/`;

  const onSubmit = (values, actions) => {
    const {
      tokensQuorum,
      quorumBase,
      attendanceQuorum,
      attendanceQuorumEnabled,
      capitalQuorum,
      doubleQuorumEnabled,
      votingEnabled,
      proposals,
      votingType,
      documents,
      ...restValues
    } = values;
    const submitData = new FormData();
    Object.entries(convertKeysCase(restValues, snakeCase)).forEach(
      ([key, value]) => submitData.append(key, value),
    );
    addMultipleDocuments({ documents, submitData, isUpdate });
    if (!votingEnabled) {
      submitData.set('voting_type', '');
    } else {
      proposals.forEach((proposal, index) => {
        Object.entries(proposal).forEach(([key, value]) => {
          if (key === 'id' && typeof value === 'string') return;
          submitData.append(`proposals[${index}]${snakeCase(key)}`, value);
        });
      });
      submitData.append('voting_type', votingType);
      let quorums = {};
      const passingQuorums = {
        capital: quorumToValue(capitalQuorum),
        tokens: quorumToValue(tokensQuorum),
      };
      if (doubleQuorumEnabled) {
        quorums = passingQuorums;
      } else {
        const quorumBaseValue = BOARD_EVENTS.includes(eventType) ? 'tokens' : quorumBase;
        quorums[quorumBaseValue] = passingQuorums[quorumBaseValue];
      }
      if (attendanceQuorumEnabled) {
        quorums.attendance = quorumToValue(attendanceQuorum);
      }
      Object.entries(quorums).forEach(
        ([key, value]) => {
          Object.entries(value).forEach(([valueKey, valueItem]) => {
            submitData.append(`quorums.${key}.${valueKey}`, valueItem);
          });
        },
      );
    }
    axios[method](submitUrl, submitData)
      .then(
        (_response) => { navigate(eventDetailsPath); },
        ({ response: { data: errors } }) => {
          Object.keys(errors).forEach((key) => {
            let fieldName = key === 'quorums' ? 'tokensQuorum' : camelCase(key);
            if (Array.isArray(errors[key])) {
              actions.setFieldError(fieldName, errors[key].join(' '));
            } else {
              const subKeys = Object.keys(errors[key]);
              subKeys.forEach((subKey) => {
                switch (subKey) {
                  case 'tokens':
                    break;
                  case 'capital': {
                    if (subKeys.includes('tokens')) fieldName = 'doubleQuorum';
                    break;
                  }
                  case 'attendance':
                    fieldName = 'attendanceQuorum';
                    break;
                  default:
                    fieldName = camelCase(key);
                    break;
                }
                actions.setFieldError(fieldName, errors[key][subKey].join(' '));
              });
            }
          });
          actions.setSubmitting(false);
        },
      )
      .catch((error) => { console.error(error); actions.setSubmitting(false); });
  };

  return (
    <PageContent
      fullscreen
      pageHeading={pageHeading}
      headerLeft={<BackButton useRouterLink href={eventDetailsPath} />}
    >
      <CreateAgendaForm
        eventType={eventType}
        initialValues={initialValues}
        onSubmit={onSubmit}
        onCancel={() => { navigate(eventDetailsPath); }}
      />
    </PageContent>
  );
};

BaseAgendaItemUpdate.defaultProps = {
  initialValues: undefined,
  pageHeading: gettext('New Agenda Item'),
  isUpdate: false,
};

BaseAgendaItemUpdate.propTypes = {
  initialValues: PropTypes.shape({}),
  submitUrl: PropTypes.string.isRequired,
  eventType: PropTypes.oneOf(Object.keys(EVENT_TYPES_LABELS)).isRequired,
  pageHeading: PropTypes.string,
  isUpdate: PropTypes.bool,
};


export default BaseAgendaItemUpdate;
