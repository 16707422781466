import { useEffect, useReducer, useCallback } from 'react';

import axios from 'core/axios';

const useCeleryTaskStatus = (
  taskId,
  maxRetries = 30,
  pollingInterval = 1000,
) => {
  const reducer = (taskState, data) => ({ retries: taskState.retries + 1, ...data });
  const [task, dispatchTask] = useReducer(reducer, { retries: 0, status: null, result: null });

  const getTaskStatus = useCallback(() => {
    axios.get(`/api/v1/celery-tasks/${taskId}/status/`)
      .then(({ data }) => { dispatchTask(data); })
      .catch((error) => {
        dispatchTask({ status: 'failed' });
        console.error(error);
      });
  }, [taskId]);

  useEffect(
    () => {
    // Poll for task status
      if (!!taskId && task.retries <= maxRetries && task.status !== 'success') {
        window.setTimeout(() => { getTaskStatus(); }, pollingInterval);
      }
    },
    [getTaskStatus, task, maxRetries, pollingInterval, taskId],
  );

  useEffect(() => {
    // Reset values if any of the input values change
    dispatchTask({ retries: 0, status: null, result: null });
  }, [maxRetries, pollingInterval, taskId]);

  return {
    status: task.status,
    maxRetriesReached: task.retries === maxRetries,
    result: task.result,
  };
};

export default useCeleryTaskStatus;
