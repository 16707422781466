import FXRatesComponent from '../../components/markets/FXRates';
import { renderComponent } from '../../utils/renderComponent';
import { fxRatesStore } from '../../stores';


export default class FXRatesGraphRenderer {
  render(elementId) {
    return renderComponent({
      elementId,
      component: FXRatesComponent,
      props: { store: fxRatesStore },
    });
  }
}
