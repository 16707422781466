import { Component, Children, cloneElement } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';


export default class Wizard extends Component {
  static Page = ({ children, parentState }) => children(parentState);

  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      values: props.initialValues,
      validation: props.validationSchema,
      leftUrl: props.leftUrl,
    };
  }

  next = (values) => {
    const { children } = this.props;

    this.setState((state) => ({
      page: Math.min(state.page + 1, children.length - 1),
      values,
    }));
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  previous = () => {
    const { page, leftUrl } = this.state;
    if (page > 0) {
      this.setState({ page: page - 1 });
    } else {
      window.location.href = leftUrl;
    }
  };

  handleSubmit = (values, bag) => {
    const { children, onSubmit } = this.props;
    const { page } = this.state;
    const isLastPage = page === Children.toArray(children).length - 1;

    if (isLastPage) {
      return onSubmit(values, bag);
    }
    bag.setTouched({});
    bag.setSubmitting(false);
    this.next(values);
    return (null);
  };

  render() {
    const { children } = this.props;
    const { page, values, validation } = this.state;
    const activePage = Children.toArray(children)[page];
    const isLastPage = page === Children.toArray(children).length - 1;

    return (
      <Formik
        initialValues={values}
        enableReinitialize={false}
        validationSchema={validation[page]}
        onSubmit={this.handleSubmit}
      >
        {(props) => {
          const { handleSubmit, isSubmitting } = props;

          return (
            <form onSubmit={handleSubmit}>
              {cloneElement(activePage, { parentState: { ...props } })}
              {isLastPage ? (
                <button className="button primary" type="submit" disabled={isSubmitting}>
                  {gettext('Submit')}
                </button>
              ) : <button className="button primary" type="submit" disabled={isSubmitting}>{gettext('Next')}</button>}
            </form>
          );
        }}
      </Formik>
    );
  }
}

Wizard.defaultProps = {
  initialValues: [],
  leftUrl: '/',
};

Wizard.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.shape(),
  validationSchema: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  leftUrl: PropTypes.string,
};
