import { dateToday } from 'utils/date';

export const initialValues = {
  name: '',
  jurisdiction: '',
  companyType: 'default',
  legalId: '',
  incDate: dateToday(),
  domicile: '',
  countryCode: '',
  phoneNumber: '',
  street: '',
  additionalAddress: '',
  postalCode: '',
  city: '',
  county: '',
  email: '',
  homepage: '',
  noSingleSignatureRight: false,
  companyInFoundation: false,
  publicRegistryLink: '',
  poaFiles: [],
  excerptFiles: [],
  intentionFiles: [],
};
