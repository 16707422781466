import { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import FieldErrors from './fieldErrors';

class CharField extends Component {
  // TODO: DRY, introduce BaseField class
  get label() {
    const { label } = this.props;
    return label;
  }

  get maxLength() {
    const { maxLength } = this.props;
    if (!maxLength) {
      return '60';
    }

    return maxLength;
  }

  get isRequired() {
    const { isRequired } = this.props;

    return !!isRequired;
  }

  get value() {
    const { value } = this.props;

    if (value !== null) return value;

    return '';
  }

  get getErrors() {
    const { errors, model, attrName } = this.props;

    if (errors) return errors;
    if (
      model
      && model.errors
      && Object.keys(model.errors).includes(attrName)
    ) return model.errors[attrName];

    return [];
  }

  get inputName() {
    const { attrName, name } = this.props;
    if (name) return name;
    if (attrName) return attrName;
    return null;
  }

  get inputId() {
    const { attrName, name, formId } = this.props;
    let inputId = 'id_';
    if (name) inputId += name;
    else if (attrName) inputId += attrName;
    if (formId) inputId += `_${formId}`;
    return inputId;
  }

  handleChange = (e) => {
    const { handleChange, attrName } = this.props;
    if (attrName) return handleChange(e.target.value, attrName);

    return handleChange(e.target.value);
  };

  render() {
    const { readOnly } = this.props;

    let otherProps = {};
    if (this.inputName) {
      const name = { name: this.inputName };
      otherProps = { ...otherProps, ...name };
    }

    return (
      <div>
        <label htmlFor={this.inputId}>
          {this.label}
        </label>
        <input
          id={this.inputId}
          type="text"
          maxLength={this.maxLength}
          required={this.isRequired}
          value={this.value}
          onChange={this.handleChange}
          readOnly={readOnly}
          {...otherProps}
        />
        <FieldErrors errors={this.getErrors} />
      </div>
    );
  }
}

CharField.defaultProps = {
  label: '',
  maxLength: 60,
  isRequired: false,
  readOnly: false,
  value: '',
  errors: null,
  model: null,
  attrName: '',
  handleChange: () => {},
  name: '',
  formId: null,
};


CharField.propTypes = {
  label: PropTypes.string,
  maxLength: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  isRequired: PropTypes.bool,
  value: PropTypes.string,
  errors: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  model: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  attrName: PropTypes.string,
  handleChange: PropTypes.func,
  name: PropTypes.string,
  readOnly: PropTypes.bool,
  formId: PropTypes.string,
};

export default observer(CharField);
