import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { quorumTypesChoices, shareholderEvents, votesDistributionOptions } from '../constants';
import Loader from '../../components/common/loader';
import { RadioButtonGroup, RatioField, ReactSelectField, TextField } from '../../core/forms/fields';


export const validationQuorum = Yup.object({
  votesDistribution: Yup.string().max(2, gettext('Invalid value')),
  quorumPercent: Yup.number().min(0, gettext("Can't be 0"))
    .max(100, gettext("Can't be above 100")),
  votingPercent: Yup.number().min(0, gettext("Can't be 0"))
    .max(100, gettext("Can't be above 100")),
});


const Quorum = ({ isSubmitting, eventType, quorumType, votingType }) => (
  <Loader isLoading={isSubmitting}>
    {shareholderEvents.includes(eventType) && (
    <ReactSelectField
      label={gettext('Votes distribution base')}
      name="votesDistribution"
      options={votesDistributionOptions}
    />
    )}
    <RadioButtonGroup
      label={gettext('Attendance quorum')}
      className="radio-button-group-line"
      name="quorumType"
      options={quorumTypesChoices}
      groupId="quorumType"
    />
    {quorumType === 'fraction' ? (
      <RatioField name="quorumRatio" />
    ) : <TextField name="quorumPercent" type="number" /> }
    <RadioButtonGroup
      label={gettext('Passing quorum')}
      className="radio-button-group-line"
      name="votingType"
      options={quorumTypesChoices}
      groupId="votingType"
    />
    {votingType === 'fraction' ? (
      <RatioField name="votingRatio" />
    ) : <TextField name="votingPercent" type="number" />}
  </Loader>
);

Quorum.defaultProps = {
  isSubmitting: false,
};

Quorum.propTypes = {
  isSubmitting: PropTypes.bool,
  eventType: PropTypes.string.isRequired,
  quorumType: PropTypes.oneOf(['percent', 'fraction']).isRequired,
  votingType: PropTypes.oneOf(['percent', 'fraction']).isRequired,
};

export default Quorum;
