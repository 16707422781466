import PropTypes from 'prop-types';
import { getUrl, getApiUrl } from 'utils/urls';
import { manualEstimate, assetValuationOptions } from './options';
import BaseAddValuation from './baseAdd';


const AddAssetValuation = ({ defaultCurrency, tokenId, portfolioId }) => (
  <BaseAddValuation
    defaultCurrency={defaultCurrency}
    redirectUrl={getUrl(`/assets/equity/${portfolioId}/${tokenId}/valuation/`)}
    submitUrl={getApiUrl(`/assets/equity/${tokenId}/valuation/`)}
    valuationTypes={assetValuationOptions}
    allowedValuationTypes={[manualEstimate]}
  />
);

AddAssetValuation.defaultProps = {
  defaultCurrency: '',
};

AddAssetValuation.propTypes = {
  defaultCurrency: PropTypes.string,
  tokenId: PropTypes.string.isRequired,
  portfolioId: PropTypes.string.isRequired,
};

export default AddAssetValuation;
