import AcceptConsortiumMembership from '../components/blockchain/acceptConsortiumMembership';

import { renderComponent } from '../utils/renderComponent';
import { consortiumMembershipStore } from '../stores/consortiumMembershipStore';

export default class AcceptConsortiumMembershipComponentRenderer {
  constructor({ type = null, companyId = null, industry = null, leftUrl = null }) {
    this.membershipType = type;
    this.companyId = companyId;
    this.industry = industry;
    this.leftUrl = leftUrl;
  }

  render(elementId) {
    const model = consortiumMembershipStore.addModel({
      membershipType: this.membershipType,
      companyId: this.companyId,
      industry: this.industry,
    });
    return renderComponent({
      elementId,
      component: AcceptConsortiumMembership,
      props: { model, leftUrl: this.leftUrl },
    });
  }
}
