import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { CertificateTypeRegistryIssuersView as CertificateTypeRegistryIssuersViewDS } from '@trustwise/design-system';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import { getCertificateDetails, getCertificateRegistry, getCertificateRegistryIssuers } from '../actions';

export const CertificateTypeRegistryIssuersView = () => {
  const { state: routerState } = useLocation();
  const { certificateTypeId, issuerRegistryId } = useParams();

  if (!certificateTypeId || !issuerRegistryId) throw new Error('certificateTypeId and issuerRegistryId is required');

  const { data: certificateDetails } = useQuery({
    queryKey: ['certificate-type-details', certificateTypeId],
    queryFn: () => getCertificateDetails(certificateTypeId),
  });

  const { data: issuerRegistryDetails } = useQuery({
    queryKey: ['certificate-type-registry', issuerRegistryId],
    queryFn: () => getCertificateRegistry(issuerRegistryId),
  });

  const { data: issuers } = useQuery({
    queryKey: ['certificate-type-registry-issuers', issuerRegistryId],
    queryFn: () => getCertificateRegistryIssuers(issuerRegistryId),
  });

  return (
    <PageContent
      fullscreen
      pageHeading={issuerRegistryDetails?.name ?? ''}
      headerLeft={<BackButton href={`management/types/${certificateTypeId}/`} useRouterLink state={routerState} />}
    >
      <CertificateTypeRegistryIssuersViewDS
        // issuerRegistryDetails={issuerRegistryDetails}
        certificateDetails={certificateDetails}
        issuers={issuers}
      />
    </PageContent>
  );
};
