import { getApiUrl } from 'utils/urls';
import {
  PaymentTokenModel,
  PaymentTokenTransfersModel,
} from 'investments/paymentTokens/models';
import axios from 'core/axios';

export const getPaymentTokensTransfers = (setTransfers, queryParams) => {
  axios.get(getApiUrl('/assets/payment-tokens/transfers/', queryParams))
    .then(({ data }) => { setTransfers(PaymentTokenTransfersModel.fromResponse(data)); })
    .catch(console.error);
};

export const getPaymentTokens = (setTokens) => {
  axios.get(getApiUrl('/assets/payment-tokens/'))
    .then(({ data }) => { setTokens(PaymentTokenModel.fromResponse(data.results)); })
    .catch(console.error);
};

export const getTokenForPortfolio = (tokens, tokenId, portfolioId) => {
  if (!tokens || !tokens.length) {
    return undefined;
  }
  return tokens.find((token) => token.id === tokenId && token.portfolioId === portfolioId);
};
