import {
  action,
  set,
  toJS,
  extendObservable,
} from 'mobx';
import find from 'lodash/find';
import uuid from 'uuid';

/**
 * @deprecated DO NOT USE
 */
export class Model {
  constructor() {
    extendObservable(this, {
      errors: {},
      _isLoading: false,
      nonFieldErrors: [],
      uid: uuid.v4(),
    });
  }

  get isValid() {
    return Object.keys(toJS(this.errors)).length === 0 && toJS(this.nonFieldErrors).length === 0;
  }

  get isPersisted() {
    return Object.prototype.hasOwnProperty.call(this, 'id') && (this.id != null);
  }

  get isLoading() {
    return this._isLoading;
  }

  _handleErrors = action((errData) => {
    if (errData.errors) {
      Object.keys(errData.errors).forEach(
        (field) => this._setFieldErrors(field, errData.errors[field]),
      );
    }
  });

  _setFieldErrors = action((fieldName, errors) => {
    if (fieldName === 'non_field_errors') {
      this.setNonFieldErrors(errors);
    } else {
      const mappedKey = find(this.constructor.fieldMapping, (o) => o.serverName === fieldName);
      this.errors[mappedKey.name] = errors;
    }
  });

  setNonFieldErrors = action((errors) => {
    this.nonFieldErrors = errors;
  });

  cleanErrors = action(() => {
    this.errors = {};
    this.nonFieldErrors = [];
  });

  setProperty = action((attrName, val) => {
    set(this, attrName, val);
  });

  setResponseErrors = action((response) => {
    if (response && response.responseJSON) this._handleErrors(response.responseJSON);
  });

  setLoading = action((val) => {
    this._isLoading = val;
  });

  fromJSON = action((obj) => {
    Object.keys(obj).forEach(
      (objectKey) => {
        if (Object.prototype.hasOwnProperty.call(this, objectKey)) {
          try {
            this[`set${this.capitalize(objectKey)}`](obj[objectKey]);
          } catch (TypeError) {
            this[objectKey] = obj[objectKey];
          }
        } else {
          extendObservable(this, { [objectKey]: obj[objectKey] });
        }
      },
    );
  });

  // eslint-disable-next-line class-methods-use-this
  capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1);
}

export default Model;
