const currencyOptions = [
  { code: 'AED', label: gettext('AED - United Arab Emirates Dirham') },
  { code: 'AFN', label: gettext('AFN - Afghan Afghani') },
  { code: 'ALL', label: gettext('ALL - Albanian Lek') },
  { code: 'AMD', label: gettext('AMD - Armenian Dram') },
  { code: 'ANG', label: gettext('ANG - Netherlands Antillean Guilder') },
  { code: 'AOA', label: gettext('AOA - Angolan Kwanza') },
  { code: 'ARS', label: gettext('ARS - Argentine Peso') },
  { code: 'AUD', label: gettext('AUD - Australian Dollar') },
  { code: 'AWG', label: gettext('AWG - Aruban Florin') },
  { code: 'AZN', label: gettext('AZN - Azerbaijani Manat') },
  { code: 'BAM', label: gettext('BAM - Bosnia-Herzegovina Convertible Mark') },
  { code: 'BBD', label: gettext('BBD - Barbadian Dollar') },
  { code: 'BDT', label: gettext('BDT - Bangladeshi Taka') },
  { code: 'BGN', label: gettext('BGN - Bulgarian Lev') },
  { code: 'BHD', label: gettext('BHD - Bahraini Dinar') },
  { code: 'BIF', label: gettext('BIF - Burundian Franc') },
  { code: 'BMD', label: gettext('BMD - Bermudan Dollar') },
  { code: 'BND', label: gettext('BND - Brunei Dollar') },
  { code: 'BOB', label: gettext('BOB - Bolivian Boliviano') },
  { code: 'BRL', label: gettext('BRL - Brazilian Real') },
  { code: 'BSD', label: gettext('BSD - Bahamian Dollar') },
  { code: 'BWP', label: gettext('BWP - Botswanan Pula') },
  { code: 'BYN', label: gettext('BYN - Belarusian Rouble') },
  { code: 'BZD', label: gettext('BZD - Belize Dollar') },
  { code: 'CAD', label: gettext('CAD - Canadian Dollar') },
  { code: 'CDF', label: gettext('CDF - Congolese Franc') },
  { code: 'CHF', label: gettext('CHF - Swiss Franc') },
  { code: 'CLP', label: gettext('CLP - Chilean Peso') },
  { code: 'CNY', label: gettext('CNY - Chinese Yuan') },
  { code: 'COP', label: gettext('COP - Colombian Peso') },
  { code: 'CRC', label: gettext('CRC - Costa Rican Colón') },
  { code: 'CUP', label: gettext('CUP - Cuban Peso') },
  { code: 'CVE', label: gettext('CVE - Cape Verdean Escudo') },
  { code: 'CZK', label: gettext('CZK - Czech Koruna') },
  { code: 'DJF', label: gettext('DJF - Djiboutian Franc') },
  { code: 'DKK', label: gettext('DKK - Danish Krone') },
  { code: 'DOP', label: gettext('DOP - Dominican Peso') },
  { code: 'DZD', label: gettext('DZD - Algerian Dinar') },
  { code: 'EGP', label: gettext('EGP - Egyptian Pound') },
  { code: 'ERN', label: gettext('ERN - Eritrean Nakfa') },
  { code: 'ETB', label: gettext('ETB - Ethiopian Birr') },
  { code: 'EUR', label: gettext('EUR - Euro') },
  { code: 'FJD', label: gettext('FJD - Fijian Dollar') },
  { code: 'FKP', label: gettext('FKP - Falkland Islands Pound') },
  { code: 'GBP', label: gettext('GBP - British Pound') },
  { code: 'GEL', label: gettext('GEL - Georgian Lari') },
  { code: 'GHS', label: gettext('GHS - Ghanaian Cedi') },
  { code: 'GIP', label: gettext('GIP - Gibraltar Pound') },
  { code: 'GMD', label: gettext('GMD - Gambian Dalasi') },
  { code: 'GNF', label: gettext('GNF - Guinean Franc') },
  { code: 'GTQ', label: gettext('GTQ - Guatemalan Quetzal') },
  { code: 'GYD', label: gettext('GYD - Guyanaese Dollar') },
  { code: 'HKD', label: gettext('HKD - Hong Kong Dollar') },
  { code: 'HNL', label: gettext('HNL - Honduran Lempira') },
  { code: 'HRK', label: gettext('HRK - Croatian Kuna') },
  { code: 'HTG', label: gettext('HTG - Haitian Gourde') },
  { code: 'HUF', label: gettext('HUF - Hungarian Forint') },
  { code: 'IDR', label: gettext('IDR - Indonesian Rupiah') },
  { code: 'ILS', label: gettext('ILS - Israeli New Shekel') },
  { code: 'INR', label: gettext('INR - Indian Rupee') },
  { code: 'IQD', label: gettext('IQD - Iraqi Dinar') },
  { code: 'IRR', label: gettext('IRR - Iranian Rial') },
  { code: 'ISK', label: gettext('ISK - Icelandic Króna') },
  { code: 'JMD', label: gettext('JMD - Jamaican Dollar') },
  { code: 'JOD', label: gettext('JOD - Jordanian Dinar') },
  { code: 'JPY', label: gettext('JPY - Japanese Yen') },
  { code: 'KES', label: gettext('KES - Kenyan Shilling') },
  { code: 'KGS', label: gettext('KGS - Kyrgystani Som') },
  { code: 'KHR', label: gettext('KHR - Cambodian Riel') },
  { code: 'KMF', label: gettext('KMF - Comorian Franc') },
  { code: 'KPW', label: gettext('KPW - North Korean Won') },
  { code: 'KRW', label: gettext('KRW - South Korean Won') },
  { code: 'KWD', label: gettext('KWD - Kuwaiti Dinar') },
  { code: 'KYD', label: gettext('KYD - Cayman Islands Dollar') },
  { code: 'KZT', label: gettext('KZT - Kazakhstani Tenge') },
  { code: 'LAK', label: gettext('LAK - Laotian Kip') },
  { code: 'LBP', label: gettext('LBP - Lebanese Pound') },
  { code: 'LKR', label: gettext('LKR - Sri Lankan Rupee') },
  { code: 'LRD', label: gettext('LRD - Liberian Dollar') },
  { code: 'LYD', label: gettext('LYD - Libyan Dinar') },
  { code: 'MAD', label: gettext('MAD - Moroccan Dirham') },
  { code: 'MDL', label: gettext('MDL - Moldovan Leu') },
  { code: 'MGA', label: gettext('MGA - Malagasy Ariary') },
  { code: 'MKD', label: gettext('MKD - Macedonian Denar') },
  { code: 'MMK', label: gettext('MMK - Myanmar Kyat') },
  { code: 'MNT', label: gettext('MNT - Mongolian Tugrik') },
  { code: 'MOP', label: gettext('MOP - Macanese Pataca') },
  { code: 'MRU', label: gettext('MRU - Mauritanian Ouguiya') },
  { code: 'MUR', label: gettext('MUR - Mauritian Rupee') },
  { code: 'MVR', label: gettext('MVR - Maldivian Rufiyaa') },
  { code: 'MWK', label: gettext('MWK - Malawian Kwacha') },
  { code: 'MXN', label: gettext('MXN - Mexican Peso') },
  { code: 'MYR', label: gettext('MYR - Malaysian Ringgit') },
  { code: 'MZN', label: gettext('MZN - Mozambican Metical') },
  { code: 'NGN', label: gettext('NGN - Nigerian Naira') },
  { code: 'NIO', label: gettext('NIO - Nicaraguan Córdoba') },
  { code: 'NOK', label: gettext('NOK - Norwegian Krone') },
  { code: 'NPR', label: gettext('NPR - Nepalese Rupee') },
  { code: 'NZD', label: gettext('NZD - New Zealand Dollar') },
  { code: 'OMR', label: gettext('OMR - Omani Rial') },
  { code: 'PAB', label: gettext('PAB - Panamanian Balboa') },
  { code: 'PEN', label: gettext('PEN - Peruvian Sol') },
  { code: 'PGK', label: gettext('PGK - Papua New Guinean Kina') },
  { code: 'PHP', label: gettext('PHP - Philippine Piso') },
  { code: 'PKR', label: gettext('PKR - Pakistani Rupee') },
  { code: 'PLN', label: gettext('PLN - Polish Zloty') },
  { code: 'PYG', label: gettext('PYG - Paraguayan Guarani') },
  { code: 'QAR', label: gettext('QAR - Qatari Rial') },
  { code: 'RON', label: gettext('RON - Romanian Leu') },
  { code: 'RSD', label: gettext('RSD - Serbian Dinar') },
  { code: 'RUB', label: gettext('RUB - Russian Rouble') },
  { code: 'RWF', label: gettext('RWF - Rwandan Franc') },
  { code: 'SAR', label: gettext('SAR - Saudi Riyal') },
  { code: 'SBD', label: gettext('SBD - Solomon Islands Dollar') },
  { code: 'SCR', label: gettext('SCR - Seychellois Rupee') },
  { code: 'SDG', label: gettext('SDG - Sudanese Pound') },
  { code: 'SEK', label: gettext('SEK - Swedish Krona') },
  { code: 'SGD', label: gettext('SGD - Singapore Dollar') },
  { code: 'SHP', label: gettext('SHP - St Helena Pound') },
  { code: 'SLL', label: gettext('SLL - Sierra Leonean Leone') },
  { code: 'SOS', label: gettext('SOS - Somali Shilling') },
  { code: 'SRD', label: gettext('SRD - Surinamese Dollar') },
  { code: 'SSP', label: gettext('SSP - South Sudanese Pound') },
  { code: 'STN', label: gettext('STN - São Tomé & Príncipe Dobra') },
  { code: 'SYP', label: gettext('SYP - Syrian Pound') },
  { code: 'SZL', label: gettext('SZL - Swazi Lilangeni') },
  { code: 'THB', label: gettext('THB - Thai Baht') },
  { code: 'TJS', label: gettext('TJS - Tajikistani Somoni') },
  { code: 'TMT', label: gettext('TMT - Turkmenistani Manat') },
  { code: 'TND', label: gettext('TND - Tunisian Dinar') },
  { code: 'TOP', label: gettext('TOP - Tongan Pa\'anga') },
  { code: 'TRY', label: gettext('TRY - Turkish Lira') },
  { code: 'TTD', label: gettext('TTD - Trinidad & Tobago Dollar') },
  { code: 'TWD', label: gettext('TWD - New Taiwan Dollar') },
  { code: 'TZS', label: gettext('TZS - Tanzanian Shilling') },
  { code: 'UAH', label: gettext('UAH - Ukrainian Hryvnia') },
  { code: 'UGX', label: gettext('UGX - Ugandan Shilling') },
  { code: 'USD', label: gettext('USD - US Dollar') },
  { code: 'UYU', label: gettext('UYU - Uruguayan Peso') },
  { code: 'UZS', label: gettext('UZS - Uzbekistani Som') },
  { code: 'VES', label: gettext('VES - Venezuelan Bolívar') },
  { code: 'VND', label: gettext('VND - Vietnamese Dong') },
  { code: 'VUV', label: gettext('VUV - Vanuatu Vatu') },
  { code: 'WST', label: gettext('WST - Samoan Tala') },
  { code: 'XAF', label: gettext('XAF - Central African CFA Franc') },
  { code: 'XCD', label: gettext('XCD - East Caribbean Dollar') },
  { code: 'XOF', label: gettext('XOF - West African CFA Franc') },
  { code: 'XPF', label: gettext('XPF - CFP Franc') },
  { code: 'YER', label: gettext('YER - Yemeni Rial') },
  { code: 'ZAR', label: gettext('ZAR - South African Rand') },
  { code: 'ZMW', label: gettext('ZMW - Zambian Kwacha') },
];

export default currencyOptions;
