import PropTypes from 'prop-types';


const AccountAvatarRowPart = ({ legalEntity, isAnonymous }) => (
  isAnonymous || !legalEntity ? (
    <div className="avatar right-padding pending">
      <span className="avatar-image centered">
        <span className="avatar-initials">?</span>
      </span>
    </div>
  ) : (
    <div className={`avatar right-padding ${!legalEntity.color && 'pending'}`}>
      <span
        className="avatar-image centered"
        style={legalEntity.image ? { background: 'none' } : { backgroundColor: legalEntity.color }}
      >
        {legalEntity.image
          ? <img alt="profile" className="image" src={legalEntity.image} />
          : <span className="avatar-initials">{legalEntity.initials}</span>}
      </span>
    </div>
  )
);
AccountAvatarRowPart.defaultProps = {
  isAnonymous: false,
  legalEntity: null,
};

AccountAvatarRowPart.propTypes = {
  legalEntity: PropTypes.shape({
    color: PropTypes.string,
    image: PropTypes.string,
    initials: PropTypes.string,
  }),
  isAnonymous: PropTypes.bool,
};


export default AccountAvatarRowPart;
