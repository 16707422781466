import { Component } from 'react';

// eslint-disable-next-line react/prefer-stateless-function
const WrappedInLink = (ComponentToWrap) => class extends Component {
  render() {
    // eslint-disable-next-line react/prop-types
    const { href } = this.props;
    if (!href) {
      return <ComponentToWrap {...this.props} />;
    }
    return (
      <a href={href}>
        <ComponentToWrap {...this.props} />
      </a>
    );
  }
};

export default WrappedInLink;
