/* eslint-disable no-nested-ternary */
/**
 * List of delegate multisig transactions.
 */

import PropTypes from 'prop-types';
import MultisigTransactionsList from './transactionsList';


const DelegateMultisigTransactionsList = ({ contractAddress, itemNotificationSource }) => (
  <MultisigTransactionsList
    fetchUrl={`/api/v1/${contractAddress}/bc-transactions/`}
    itemNotificationSource={itemNotificationSource}
  />
);

DelegateMultisigTransactionsList.propTypes = {
  contractAddress: PropTypes.string.isRequired,
  itemNotificationSource: PropTypes.string.isRequired,
};

export default DelegateMultisigTransactionsList;
