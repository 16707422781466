import PropTypes from 'prop-types';
import { Field } from 'formik';

import FieldErrors from './errors';
import FieldLabel from './label';

/**
 * Text field with label and errors.
 *
 * TODO add other props as necessary (className, placeholder, etc.)
 */
const TextAreaField = ({ autoComplete, isOptional, id, label, name, rows, cols, hideErrors, ...props }) => (
  // div is required to keep the label and field positions unaffected by the layout of the parent components
  <div>
    {label && (<FieldLabel id={id || name} label={label} isOptional={isOptional} />)}
    {!hideErrors && <FieldErrors name={name} />}
    <Field as="textarea" autoComplete={autoComplete} id={id || name} name={name} rows={rows} cols={cols} {...props} />
  </div>
);

TextAreaField.defaultProps = {
  autoComplete: '',
  id: '',
  label: '',
  hideErrors: false,
  rows: 6,
  cols: 30,
  isOptional: false,
};

TextAreaField.propTypes = {
  autoComplete: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  hideErrors: PropTypes.bool,
  rows: PropTypes.number,
  cols: PropTypes.number,
  isOptional: PropTypes.bool,
};

export default TextAreaField;
