import { getApiUrl } from 'utils/urls';
import axios from 'core/axios';

export const onObjectsSort = (path, column, order, setObjects) => {
  let orderBy;

  switch (column) {
    case 'artwork':
      orderBy = 'title';
      break;
    case 'no_of_units':
      orderBy = 'amount';
      break;
    default:
      orderBy = column;
      break;
  }

  return axios.get(getApiUrl(path, { order, order_by: orderBy }))
    .then(({ data: { results } }) => setObjects(results))
    .catch(console.error);
};
