import MintedTokensList from 'mintage/list';
import Widget from './base';


const MintageWidget = () => (
  <Widget
    id="mintage"
    title={gettext('Issued tokens')}
    action={{ label: gettext('See all'), urlName: '/payment-tokens/' }}
  >
    <MintedTokensList />
  </Widget>
);

export default MintageWidget;
