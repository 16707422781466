import { useQuery } from '@tanstack/react-query';

import { CertificateTypesListView as CertificateTypesListViewDS } from '@trustwise/design-system';

import { PageContent } from 'core/page';
import { getRouterLinkInteraction } from 'core/utils';
import BackButton from 'core/page/parts/backButton';
import { getCertificateTypes } from '../actions';

export const CertificateTypesListView = () => {
  const { data } = useQuery({ queryKey: ['certificate-types'], queryFn: getCertificateTypes });

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Certificate Types')}
      headerLeft={<BackButton href="/" useRouterLink />}
    >
      <CertificateTypesListViewDS
        types={data}
        onTypeClick={(id) => getRouterLinkInteraction(`${id}/`)}
        createNewInteraction={getRouterLinkInteraction('create/')}
      />
    </PageContent>
  );
};
