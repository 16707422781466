import PropTypes from 'prop-types';

const Link = (props) => {
  const { href, children } = props;

  return (
    <a href={href} {...props}>
      {children}
    </a>
  );
};

Link.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Link;
