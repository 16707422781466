import { extendObservable } from 'mobx';
import axios from 'core/axios';
import { DocumentModel } from '../models/document';
import { documentStoreUrls } from './config';
import { interpolate } from '../utils/interpolation';

class DocumentStore {
  constructor() {
    extendObservable(this, {
      documents: [],
      documentObject: {},
      _documentTypeOptions: [],
    });
    this.addDocument = this.addDocument.bind(this);
    this.setDocumentObject = this.setDocumentObject.bind(this);
    this.fetchById = this.fetchById.bind(this);
  }

  addDocument(args) {
    const doc = new DocumentModel({ ...args, documentObject: this.documentObject });
    this.documents.push(doc);
    return doc;
  }

  setDocumentObject(val = {}) {
    this.documentObject = val;
    this.documents.map((doc) => doc.setDocumentObject(val));
  }

  getFetchUrl(docId, companyId) {
    let url = '';
    let args = {};
    switch (this.documentObject.documentObjectType) {
      case 'person':
        url = documentStoreUrls.personal.FETCH_BY_ID;
        args = { id: docId };
        break;
      case 'profile_registration':
        url = documentStoreUrls.profile_registration.FETCH_BY_ID;
        args = { id: docId };
        break;
      case 'company':
        url = documentStoreUrls.company.FETCH_BY_ID;
        args = { id: docId, companyId: this.documentObject.documentObjectId };
        break;
      case 'company_registration':
        url = documentStoreUrls.company_registration.FETCH_BY_ID;
        args = { id: docId };
        break;
      case 'governance_event':
        url = documentStoreUrls.governance_event.FETCH_BY_ID;
        args = { companyId, eventId: this.documentObject.documentObjectId, id: docId };
        break;
      case 'agenda_item':
        url = documentStoreUrls.agenda_item.FETCH_BY_ID;
        args = { companyId, agendaItemId: this.documentObject.documentObjectId, id: docId };
        break;
      default:
        throw new Error(`Fetch URL could not be determined. Related object type: "${this.documentObject.documentObjectType}".`);
    }
    return interpolate(url, args);
  }

  fetchById(docId, companyId) {
    return axios.get(this.getFetchUrl(docId, companyId))
      .then(
        ({ data: { data } }) => this.addDocument({
          id: data.id,
          date: data.date,
          subject: data.subject,
          documentFiles: data.document_files,
          defaultDocumentType: data.document_type,
        }),
      )
      .catch(console.error);
  }
}

export const documentStore = new DocumentStore();
export { DocumentStore };
