import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useReducer } from 'react';

import { PropertyTokenCreateOrderView as PropertyTokenCreateOrderViewDS } from '@trustwise/design-system';
import { PageContent } from 'core/page';
import useContacts from 'contacts/hooks';
import { ProvidedServices } from 'core/providedServices';
import { getAssetDetail, getObjectDetail, onTransferFeeCalculate } from './actions';
import { onSellOrderCreate } from './actions/createSellOrder';
import reducer from '../reducers';

const PropertyTokenCreateOrderView = () => {
  const { onFetch: onContactsFetch } = useContacts(false, { 'service-type': ProvidedServices.ARBITER, 'only-public': 1 });

  const navigate = useNavigate();
  const { portfolioId, tokenId } = useParams();

  const baseArtworksPath = `/artworks/${portfolioId}/${tokenId}/`;

  // TODO: make context for asset and object detail
  const [state, dispatch] = useReducer(reducer, { registeredObject: {}, asset: {} });
  const {
    registeredObject: { details: objectDetail, media },
    asset: { objectType, objectId, marketplaces, amount: balance, availableAmount },
  } = state;

  const objectPath = objectType && objectId && `/registered-objects/artworks/${objectType}/${objectId}/`;

  useEffect(() => {
    getAssetDetail({ tokenId, portfolioId, dispatch });
  }, [portfolioId, tokenId]);

  useEffect(() => {
    if (objectPath) {
      getObjectDetail({ objectPath, dispatch });
    }
  }, [objectPath]);

  return (
    <PageContent fullscreen pageHeading={gettext('New Sell Order')}>
      <PropertyTokenCreateOrderViewDS
        details={objectDetail ? objectDetail.details : undefined}
        media={media}
        orderDetails={{
          amount: balance,
          availableAmount,
          currency: marketplaces && marketplaces.length && marketplaces[0].currency,
        }}
        onContactsFetch={onContactsFetch}
        onContactSearch={({ searchString }) => onContactsFetch(searchString)}
        onTransferFeeCalculate={(amount, pricePerUnit, currency) => onTransferFeeCalculate({
          amount,
          pricePerUnit,
          currency,
          portfolioId,
          tokenId,
        })}
        onSubmit={(values, actions) => onSellOrderCreate({
          values: { ...values, portfolio_id: portfolioId },
          actions,
          submitPath: `/markets/${tokenId}/orders/`,
          navigatePath: `/assets${baseArtworksPath}`,
        })}
        onTransactionsClick={() => navigate(baseArtworksPath)}
      />
    </PageContent>
  );
};

export default PropertyTokenCreateOrderView;
