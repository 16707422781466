import lodash from 'lodash';
import { extendObservable } from 'mobx';

import axios from 'core/axios';

export default class Collection {
  constructor() {
    extendObservable(this, {
      _isLoading: false,
      models: [],
      url: this.constructor.url,
      fetchData: this.constructor.fetchData,
    });
    this.model = null;
    this.setProperty = this.setProperty.bind(this);
    this.fromJSON = this.fromJSON.bind(this);
    this.clean = this.clean.bind(this);
    this.add = this.add.bind(this);
    this.fetch = this.fetch.bind(this);
    this.handleFetchError = this.handleFetchError.bind(this);
    this.mixinLodashFunctions();
  }

  get length() {
    return this.models.length;
  }

  setProperty(attrName, val) {
    this[attrName] = val;
  }

  fromJSON(jsonStruct) {
    const Model = this.model;
    jsonStruct.forEach((element) => {
      const model = new Model();
      model.fromJSON(element);
      this.add(model);
    });
  }

  clean() {
    this.models.clear();
  }

  add(model) {
    this.models.push(model);
  }

  mixinLodashFunctions() {
    this.constructor.lodashFnNames.forEach(
      (func) => {
        this[func] = (...args) => lodash[func](this.models, ...args);
      },
    );
  }

  static get lodashFnNames() {
    return [
      'chain',
      'chunk',
      'findIndex',
      'findLastIndex',
      'first',
      'last',
      'nth',
      'countBy',
      'every',
      'filter',
      'find',
      'findLast',
      'forEach',
      'each',
      'forEachRight',
      'groupBy',
      'map',
      'orderBy',
      'partition',
      'reduce',
      'reduceRight',
      'reject',
      'sample',
      'sampleSize',
      'shuffle',
      'size',
      'some',
      'sortBy',
      'take',
      'maxBy',
      'minBy',
    ];
  }

  static get url() {
    return '';
  }

  static get fetchData() {
    return {};
  }

  fetch(requestData = {}) {
    this.setLoading(true);
    return axios
      .get(this.url, { params: requestData || this.fetchData })
      .then(({ data: { data } }) => { this.fromJSON(data); })
      .catch((error) => this.handleFetchError(error))
      .finally(() => { this.setLoading(false); });
  }

  // eslint-disable-next-line class-methods-use-this
  handleFetchError = (_response) => {};

  setLoading(val) {
    this.setProperty('_isLoading', val);
  }

}
