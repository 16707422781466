import { Buffer } from 'buffer';

export const getStringBetweenSubstrings = ({ str, start, end }) => {
  const startSubStrMatch = str.match(start);
  const startSubStr = startSubStrMatch ? startSubStrMatch[0] : '';
  const endSubStrMatch = str.match(end);
  const endSubStr = endSubStrMatch ? endSubStrMatch[0] : '';
  const indexOfStrStart = str.indexOf(startSubStr) + startSubStr.length;
  const indexOfStrEnd = str.indexOf(endSubStr);
  return str.substring(indexOfStrStart, indexOfStrEnd);
};

export const convertBooleanString = (value?: string): boolean | undefined => {
  if (!value) {
    return undefined;
  }
  const valueLowerCase = value.toLowerCase();
  if (!['true', 'false'].includes(valueLowerCase)) {
    throw new Error('Value must be a boolean string');
  }
  return valueLowerCase === 'true';
};

export const encodeStringToBase64 = (str: string): string => Buffer.from(str, 'utf-8').toString('base64');
