import { ExtendedIssuedCertificate, IssuedCertificateStatus } from '@trustwise/design-system';

import { Model } from 'core/model';

interface IssuedCertificateProps {
  /**
   * Unique ID of the certificate
   */
  certificateId: string;
  /**
   * Blockchain address of certificate type
   */
  certificateTypeAddress: string;
  /**
   * Name of the certificate type
   */
  certificateTypeName: string;
  /**
   * Certificate issuing date
   */
  issuedAt: string;
  /**
   * Certificate expiry date
   */
  expiresAt?: string;
  /**
   * Certificate status
   */
  status: IssuedCertificateStatus;
}

export class IssuedCertificateModel extends Model implements ExtendedIssuedCertificate {
  certificateId: string;
  certificateTypeAddress: string;
  certificateType: string;
  issuedAt: Date;
  expiresAt?: Date;
  status: IssuedCertificateStatus;

  constructor({ issuedAt, expiresAt, certificateTypeName, ...restProps }: IssuedCertificateProps) {
    super(restProps);
    this.certificateType = certificateTypeName;
    this.issuedAt = new Date(issuedAt);
    this.expiresAt = expiresAt ? new Date(expiresAt) : undefined;
  }
}
