import {
  CertificateTypeStatus,
  CertificateIdentityRequirements,
  CertificateIssuingSettings,
  EncryptedDataSchema,
  CertificateTypeDetail,
} from '@trustwise/design-system';
import { SimpleLegalEntityModelProps } from 'contacts/interfaces/types';
import { SimpleLegalEntityModel } from 'contacts/models/legalEntity';

import { Model } from 'core/model';

import { IFileModel } from 'media/interfaces';
import { FileModel } from 'media/models';

interface CertificateTypeDetailModelProps extends Omit<CertificateTypeDetail, 'website'> {
  file?: IFileModel;
  managedBy: SimpleLegalEntityModelProps;
  url?: string;
}

export class CertificateTypeDetailModel extends Model implements CertificateTypeDetail {
  id: number;
  name: string;
  description: string;
  address: string;
  status: CertificateTypeStatus;
  encryptedDataSchema: EncryptedDataSchema[];
  website?: string;
  file?: FileModel;
  identityRequirements: CertificateIdentityRequirements;
  issuingSettings: CertificateIssuingSettings;
  managedBy?: SimpleLegalEntityModel;

  constructor({ file, managedBy, url, ...restProps }: CertificateTypeDetailModelProps) {
    super(restProps);
    this.website = url;
    this.file = file ? new FileModel({ type: undefined, timestamp: undefined, ...file }) : undefined;
    this.managedBy = managedBy ? new SimpleLegalEntityModel(managedBy) : undefined;
  }
}
