import { documentStore } from './documentStore';
import { consortiumMembershipStore } from './consortiumMembershipStore';
import { tokenTransferStore } from './tokenTransferStore';
import { accountSwitcherStore } from './accountSwitcherStore';
import { fxRatesStore } from './markets/fxRatesStore';
import { inboxItemsCountStore } from './inboxItemsCountStore';

// @todo why two exports?

export {
  documentStore,
  consortiumMembershipStore,
  tokenTransferStore,
  accountSwitcherStore,
  fxRatesStore,
  inboxItemsCountStore,
};

export const stores = {
  documentStore,
  consortiumMembershipStore,
  tokenTransferStore,
  accountSwitcherStore,
  fxRatesStore,
  inboxItemsCountStore,
};

window.stores = stores;
