import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { PaymentTokenTransactionsTable } from '@trustwise/design-system';
import { getUrl } from 'utils/urls';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import { getPaymentTokensTransfers } from './actions';

const PaymentTokenTransactionsListView = () => {
  const [transfers, setTransfers] = useState();
  const { state } = useLocation();

  useEffect(() => {
    getPaymentTokensTransfers(setTransfers);
  }, []);

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Transactions')}
      headerLeft={<BackButton href={state ? state.previousPath : getUrl('/')} />}
    >
      <PaymentTokenTransactionsTable transactions={transfers} />
    </PageContent>
  );
};

export default PaymentTokenTransactionsListView;
