import { convertKeysToCamelCase } from 'core/utils';
import { LegalEntityAvatarModel } from 'contacts/models/legalEntity';
import { Model } from 'core/model';
import {
  BoardMemberDetailModelProps,
  BoardMemberListItemModelProps,
  IBoardMemberDetailModel,
  IBoardMemberListItemModel,
} from 'boardroom/interfaces';

export class BoardMemberListItemModel extends Model implements IBoardMemberListItemModel {
  id: number;
  avatar: LegalEntityAvatarModel;
  name: string;
  legalEntityId: number;
  email: string;
  isChairman: boolean;
  hasCastingVote: boolean;

  constructor({
    legalEntityId, avatar, name, ...restProps
  }: BoardMemberListItemModelProps) {
    super(restProps);
    this.id = legalEntityId;
    this.name = name;
    this.avatar = new LegalEntityAvatarModel(convertKeysToCamelCase(avatar));
  }
}

export class BoardMemberDetailModel extends BoardMemberListItemModel implements IBoardMemberDetailModel {
  blockchainAddress: string;
  electionDate: Date;

  constructor({
    address, electionDate, ...restProps
  }: BoardMemberDetailModelProps) {
    super(restProps);
    this.blockchainAddress = address;
    this.electionDate = new Date(electionDate);
  }
}
