/* eslint-disable react/button-has-type */
/**
 * A generic button.
 *
 * TODO add other props as necessary (className, name, value)
 */

import PropTypes from 'prop-types';
import { Icon } from 'components/common/icon';
import { icons } from 'core/icons';


const BaseButton = ({ children, className, disabled, type, onClick, ...props }) => (
  <button
    className={className}
    type={type}
    disabled={disabled}
    onClick={onClick}
    {...props}
  >
    {children}
  </button>
);

BaseButton.defaultProps = {
  className: '',
  disabled: false,
  type: 'button',
  onClick: () => { },
};

BaseButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit']),
  onClick: PropTypes.func,
};

const Button = ({ children, className, disabled, type, onClick, ...props }) => (
  <BaseButton
    className={`button ${className}`}
    type={type}
    disabled={disabled}
    onClick={onClick}
    {...props}
  >
    {children}
  </BaseButton>
);

Button.defaultProps = {
  className: '',
  disabled: false,
  type: 'button',
  onClick: () => {},
};

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  type: PropTypes.oneOf(['button', 'submit']),
  onClick: PropTypes.func,
};


const IconButton = ({ iconName, iconWeight, onClick, className }) => (
  <BaseButton onClick={onClick} className={className}>
    {iconName ? <Icon weight={iconWeight} name={iconName} className="icon" /> : icons.backButton}
  </BaseButton>
);

IconButton.defaultProps = {
  iconName: null,
  iconWeight: 'bold',
  className: '',
};

IconButton.propTypes = {
  iconName: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  iconWeight: PropTypes.string,
  className: PropTypes.string,
};

export { BaseButton, Button, IconButton };
