import { useQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { EquityDetailView, AssetDetailTabsPages, AssetDetailTabsPage, EventType } from '@trustwise/design-system';
import { getUrl } from 'utils/urls';
import { getLinkInteraction, getRouterLinkInteraction } from 'core/utils';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import { getGlobalContext } from 'core/globals';
import {
  getDetailUrl,
  getEquityDetails,
  getShareholdersEvents,
  getEquityTransactions,
  onEquityFileDownload,
  getValuations,
} from 'investments/equity/actions';
import { getGovernanceEventDetailPath } from 'governanceEvents/utils';

export const EquityAssetsDetail = () => {
  const [activePage, setActivePage] = useState<AssetDetailTabsPage>(AssetDetailTabsPages.OVERVIEW);

  const { portfolioId, tokenId } = useParams();
  if (!portfolioId || !tokenId) {
    throw new Error('Portfolio ID and Token ID are required');
  }
  const detailUrl = getDetailUrl(portfolioId, tokenId);
  const valuationsListUrl = getUrl(`${detailUrl}valuation/`);
  const { activeEntity: { baseCurrency } } = getGlobalContext();

  const { data: equity } = useQuery({
    queryKey: ['equityAssets', portfolioId, tokenId],
    queryFn: getEquityDetails,
  });

  const { data: transactions } = useQuery({
    queryKey: ['equityTransactions', portfolioId, tokenId],
    queryFn: getEquityTransactions,
  });

  const { data: events } = useQuery({
    queryKey: ['equityEvents', portfolioId, tokenId],
    queryFn: () => getShareholdersEvents(String(equity?.issuer.id), tokenId),
    enabled: !!equity?.issuer.id,
  });

  const { data: valuations } = useQuery({
    queryKey: ['equityValuations', tokenId],
    queryFn: () => getValuations(tokenId),
    enabled: !!equity,
  });

  const assetValuations = useMemo(() => (
    valuations?.map((valuation) => {
      const { id, dateTime, pricePerUnit, timeSeries: { valuationType, estimatedBy, quotation } } = valuation;

      return ({
        id,
        isActive: id === equity?.valuation.id,
        dateTime,
        valuationType,
        byIssuer: equity?.issuer.id === estimatedBy.id,
        pricePerUnit,
        quotation,
      });
    })
  ), [valuations, equity?.valuation.id, equity?.issuer.id]);

  const transferUrl = useMemo(() => {
    const urlPart = `${detailUrl}transfer/`;
    if (equity?.hasVotingRights) {
      return `${urlPart}${equity?.isRestricted ? 'restricted/' : 'unrestricted/'}`;
    }
    return urlPart;
  }, [detailUrl, equity?.hasVotingRights, equity?.isRestricted]);

  const actions = useMemo(() => ({
    onTabSwitch: (tabId: AssetDetailTabsPage) => setActivePage(tabId),
    transferInteraction: getLinkInteraction(getUrl(transferUrl)),
    onFileDownload: (fileId: number) => onEquityFileDownload(fileId, equity?.documents || [], portfolioId, tokenId),
    valuationChangeInteraction: getLinkInteraction(valuationsListUrl),
    seeAllInteraction: getLinkInteraction(valuationsListUrl),
    valuationAddInteraction: getLinkInteraction(`${valuationsListUrl}add/`),
    onValuationClick: (valuationId: number) => getLinkInteraction(`${valuationsListUrl}${valuationId}`),
    onEventClick: (id: number, type: EventType) => (
      getRouterLinkInteraction(getGovernanceEventDetailPath(id, type, 'meetings/'))
    ),
    boReportsInteraction: getLinkInteraction(`${detailUrl}beneficial-owner-reports/`),
  }), [detailUrl, transferUrl, valuationsListUrl, equity?.documents, portfolioId, tokenId]);

  return (
    <PageContent
      fullscreen
      pageHeading={equity?.name || ''}
      headerLeft={<BackButton href="/equity/" useRouterLink />}
    >
      <EquityDetailView
        activePage={activePage}
        position={equity}
        events={events}
        valuations={assetValuations}
        instrumentDetails={equity?.instrumentDetails}
        transactions={transactions}
        documents={equity?.documents}
        baseCurrency={baseCurrency}
        actionsDisabledReason={equity?.suspendedReason}
        actions={actions}
      />
    </PageContent>
  );
};
