import PropTypes from 'prop-types';
import TextField from './text';

/**
 * Email field.
 *
 */
const EmailField = ({ autoComplete, id, label, name, hideErrors, ...props }) => (
  <TextField
    id={id || name}
    name={name}
    label={label}
    type="email"
    autoComplete={autoComplete}
    hideErrors={hideErrors}
    {...props}
  />
);

EmailField.defaultProps = {
  autoComplete: '',
  id: '',
  label: '',
  hideErrors: false,
};

EmailField.propTypes = {
  autoComplete: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  hideErrors: PropTypes.bool,
};

export default EmailField;
