/* eslint-disable react/no-danger */

import { InlineNotification } from '@trustwise/design-system';
import { icons } from '../icons';

const TransactionAlreadyConfirmed = () => (
  <InlineNotification
    icon={icons.checkMarkCircled}
    message={gettext('The transaction has already been confirmed.')}
  />
);

export default TransactionAlreadyConfirmed;
