import { useContext, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ProductOfferingDetailView } from '@trustwise/design-system';
import axios from 'core/axios';
import { getApiUrl } from 'utils/urls';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import { onFileDownload } from 'media/utils';
import { ProductOfferingsContext } from 'pricing/productOffering/context';
import { onApplicationUpdate, onContact, onSubscribe } from './actions';
import { PRODUCT_OFFERINGS_BASE_PATH } from './consts';

const ProductOfferingDetail = () => {
  const navigate = useNavigate();
  const { state: routerState } = useLocation();

  const { productOfferingId } = useParams();
  const {
    state: { offeringsDetails },
    dispatch,
  } = useContext(ProductOfferingsContext);
  const productOffering = offeringsDetails[productOfferingId] || {};

  const detailUrlPath = `${PRODUCT_OFFERINGS_BASE_PATH}${productOfferingId}/`;
  const applicationPath = `${detailUrlPath}application/`;

  useEffect(() => {
    axios.get(getApiUrl(detailUrlPath))
      .then(({ data }) => dispatch({ type: 'fetchDetails', data }))
      .catch(console.error);
  }, [detailUrlPath, dispatch]);


  return (
    <PageContent
      fullscreen
      pageHeading={productOffering.title || ''}
      headerLeft={<BackButton onClick={() => { navigate(PRODUCT_OFFERINGS_BASE_PATH, { state: routerState }); }} />}
    >
      <ProductOfferingDetailView
        {...productOffering.asObject}
        actions={{
          onSubscribe: (planId) => onSubscribe(planId, productOfferingId, dispatch),
          onUnsubscribe: onContact, // Currently possible only via contact
          onDowngrade: onContact, // Currently possible only via contact
          onContact,
          onAgreementDownload: (id) => onFileDownload(id, productOffering.documents, detailUrlPath),
          onApply: (id) => onApplicationUpdate(`${applicationPath}${id}/apply/`, dispatch, productOfferingId),
          onApplicationWithdraw: () => onApplicationUpdate(`${applicationPath}withdraw/`, dispatch, productOfferingId, true),
        }}
      />
    </PageContent>
  );
};

export default ProductOfferingDetail;
