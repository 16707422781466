const companyIcon = (
  <svg className="svg-icon icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352.38 333.23">
    <title>
      company
    </title>
    <path
      d="M0 325.7h352.4M62.6 129h104v196.7h-104zM167.5 45.8h145v280h-145zM91 91.8h76.2V129H91zM167.4 7.5H268v37.2H167.4z"
      className="cls-1"
    />
    {/* eslint-disable-next-line max-len */}
    <path d="M222.8 262.8h52.8v55.4h-52.8zM196 207.6h91M196 153.6h91M196 105.4h91M85.2 172.6h57M85.2 212.2h57M85.2 253.4h57M85.2 292h57" />
  </svg>
);

export default companyIcon;
