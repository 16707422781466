/* Document list component */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import Header from 'components/common/header';
import ContentLoadingMessage from 'utils/contentLoadingMessage';
import { Icon } from 'components/common/icon';
import ScrollableContent from 'core/page/scrollableContent';


const DocumentsListComponent = ({ state, isLoading, fetchMoreData }) => {
  useEffect(() => {
    // @todo avoid this workaround
    document.body.classList.remove('page-content-overlay-open');
  }, []);
  const { pathname } = useLocation();
  const { documents, nextPage, queryParams } = state;
  const sortedDocuments = Object.keys(documents)
    .reduce((array, key) => [...array, documents[key]], [])
    .sort((a, b) => b.documentDate - a.documentDate);

  return (
    <section>
      <Header title={gettext('Documents')} className="top-margin underline" />
      {sortedDocuments.length > 0 ? (
        <ScrollableContent
          dataLength={sortedDocuments.length}
          fetchNext={fetchMoreData}
          hasMore={!!nextPage}
          scrollableTarget="grid-container"
        >
          <ol>
            {sortedDocuments.map((doc) => (
              <li key={doc.id} className="document-file">
                <Link
                  to={{ pathname: `${pathname}documents/${doc.id}/`, search: queryParams }}
                  className="row-flex underline"
                >
                  <span className="row-item-extended">
                    <Icon weight="regular" name={doc.noOfFiles > 1 ? 'copy' : 'file'} className="right-padding icon" />
                    {`${doc.formattedDate} ${doc.subject || doc.typeText}`}
                  </span>
                  <i className="fa text-tiny fa-chevron-right" aria-hidden="true" />
                </Link>
              </li>
            ))}
          </ol>
        </ScrollableContent>
      ) : (
        <ContentLoadingMessage
          isLoading={isLoading}
          loadedMessage={gettext('No documents available')}
        />
      )}
    </section>
  );
};

DocumentsListComponent.defaultProps = {
  fetchMoreData: () => { },
  isLoading: false,
};

DocumentsListComponent.propTypes = {
  state: PropTypes.shape().isRequired,
  fetchMoreData: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default DocumentsListComponent;
