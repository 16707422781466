/* Document delete modal */
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Formik, Form, ErrorMessage } from 'formik';
import { ModalWithContent as Modal } from '@trustwise/design-system';
import axios from 'core/axios';
import FormErrors from 'components/common/formikErrors';
import { getApiUrl } from 'utils/urls';
import { SubmitButton } from 'core/forms/fields';


const DocumentDelete = ({ isOpen, onClose, queryParams, isEditable, docTypeText, onSuccessResponse }) => {
  const { documentId } = useParams();

  const onSubmit = () => axios.delete(
    getApiUrl(`/new-documents/${documentId}/`, queryParams),
  ).then(onSuccessResponse()).catch(console.error);

  return (
    <Modal
      id="documentDeleteModal"
      isOpen={isOpen}
      onClose={onClose}
      title={gettext('Remove document')}
    >
      {isEditable ? (
        <Formik
          initialValues={{
            nonFieldErrors: '',
          }}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <ErrorMessage component={FormErrors} name="nonFieldErrors" />
              <div className="text-small">
                {gettext(`Are you sure you want to remove ${docTypeText}?`)}
              </div>
              <SubmitButton disabled={isSubmitting}>{gettext('Remove')}</SubmitButton>
            </Form>
          )}
        </Formik>
      ) : gettext('Document cannot be deleted.')}
    </Modal>
  );
};

DocumentDelete.defaultProps = {
  queryParams: '',
  isEditable: false,
  isOpen: false,
};

DocumentDelete.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  queryParams: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isEditable: PropTypes.bool,
  docTypeText: PropTypes.string.isRequired,
  onSuccessResponse: PropTypes.func.isRequired,
};

export default DocumentDelete;
