import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import { getGlobalContext } from 'core/globals';
import { FullPageView } from 'core/page';
import CustodialAccountList from './accountsList';
import ShareholderAccountForm from './form';


const CustodianServiceNavigation = () => {
  const { activeEntity: { id: activeEntityId } } = getGlobalContext();
  const indexPath = `/company/${activeEntityId}/custodian/`;
  return (
    <FullPageView>
      <Router>
        <Routes>
          <Route path={indexPath}>
            <Route index element={<CustodialAccountList />} />
            <Route path="shareholders/add/" element={<ShareholderAccountForm />} />
            <Route path="shareholders/:accountId/edit/" element={<ShareholderAccountForm />} />
          </Route>
        </Routes>
      </Router>
    </FullPageView>
  );
};

export default CustodianServiceNavigation;
