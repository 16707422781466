import { snakeCase } from 'lodash';

import { addMultipleDocuments } from 'media/utils';
import { convertKeysCase, convertKeysToCamelCase } from 'core/utils';
import { getApiUrl } from 'utils/urls';
import { isNativeAppWebview } from 'utils/general';
import { signFormTransaction } from 'utils/signTransaction';
import axios from 'core/axios';
import { RegisteredObjectCoOwner } from '../../models/registeredObject';

export const fetchObject = ({ objectPath, dispatch }) => {
  axios.get(getApiUrl(objectPath))
    .then(({ data }) => dispatch({ type: 'fetchObject', data }))
    .catch(console.error);
};

export const fetchCoOwners = ({ url, urlParams = {} }) => (
  axios.get(getApiUrl(url, { page_size: 1000, ...urlParams }))
    .then(({ data }) => data.map(
      (coOwner) => (new RegisteredObjectCoOwner(convertKeysToCamelCase(coOwner))),
    ))
    .catch(console.error)
);

export const onCoOwnersSearch = ({ search, url }) => (
  fetchCoOwners({ url, urlParams: { search } })
);

export const onCoOwnersSort = ({ column, order, url }) => (
  fetchCoOwners({ url, urlParams: { order, order_by: column } })
);

export const onDetailsEdit = ({ values, actions, objectPath }) => {
  const data = convertKeysCase(values, snakeCase);
  signFormTransaction({
    urlPath: `${objectPath}update-details/`,
    navigatePath: objectPath,
    data,
    actions,
  });
};

export const onMediaSort = ({ updatedIds, objectPath, dispatch }) => {
  dispatch({ type: 'updateMediaOrder', data: updatedIds });
  axios.post(getApiUrl(`${objectPath}media/sort/`), updatedIds)
    .then(({ data }) => dispatch({ type: 'updateMediaOrder', data }))
    .catch(console.error);
};

export const onMediaSubmit = ({ values, actions, objectPath }) => {
  const { media } = values;
  const submitData = new FormData();
  addMultipleDocuments({ documents: media, submitData, isUpdate: true, fieldName: 'media' });

  signFormTransaction({
    urlPath: `${objectPath}media/`,
    navigatePath: objectPath,
    data: submitData,
    actions,
  });
};

export const onRecordAdd = ({ values, actions, objectPath, urlPath }) => {
  const { files, ...restValues } = values;
  const submitData = new FormData();

  const convertedRestValues = convertKeysCase(restValues, snakeCase);
  Object.entries(convertedRestValues).forEach(
    ([key, value]) => submitData.append(key, value),
  );

  files && files.forEach((newFile, newFileIndex) => {
    submitData.append(`new_files[${newFileIndex}]`, newFile);
  });

  signFormTransaction({
    urlPath,
    navigatePath: objectPath,
    data: submitData,
    actions,
  });
};

export const onFileDownload = ({ obj, fileId, objectPath }) => {
  const file = obj.files.find((eventFile) => eventFile.id === fileId);
  window.location = isNativeAppWebview ? file.nativeUrl : getApiUrl(`${objectPath}${obj.id}/files/${fileId}/download/`);
};

export const onAgreementDocumentDownload = ({ docId, documents, objectPath }) => {
  const doc = documents.find((document) => document.id === docId);
  const [file] = doc.files;
  window.location = isNativeAppWebview ? file.nativeUrl : getApiUrl(`${objectPath}files/${file.id}/download/`);
};
