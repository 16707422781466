import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { FormikHelpers } from 'formik';

import { SubmitVerdictView as SubmitVerdictViewDS } from '@trustwise/design-system';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import { VerdictValues } from 'dataRooms/types';
import { getGrantTokens, submitVerdict } from '../actions';
import { useDataSetQuery } from '../queries';

export const SubmitVerdictView = () => {
  const navigate = useNavigate();
  const { dataSetId } = useParams();

  if (dataSetId === undefined) {
    throw new Error('Property "dataSetId" is not defined.');
  }

  const { data: tokens } = useQuery({
    queryKey: ['dataSet', dataSetId, 'grantTokens'],
    queryFn: getGrantTokens,
  });
  const { data: dataSet } = useDataSetQuery(dataSetId);
  const { dataRoom, name, createdAt } = dataSet || {};
  const detailsUrl = `/data-sets/${dataSetId}/`;

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Submit Verdict')}
      headerLeft={<BackButton href={detailsUrl} useRouterLink />}
    >
      <SubmitVerdictViewDS
        dataRoom={dataRoom}
        name={name}
        createdAt={createdAt}
        tokens={tokens}
        onCancel={() => navigate(detailsUrl)}
        onVerdictSubmit={(values, actions) => (
          submitVerdict(values as VerdictValues, actions as FormikHelpers<VerdictValues>, dataSetId)
        )}
      />
    </PageContent>
  );
};
