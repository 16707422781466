import { SimpleLegalEntityModelProps } from 'contacts/interfaces/types';
import { SimpleLegalEntityModel } from 'contacts/models/legalEntity';
import { Model } from 'core/model';

interface BoardMembershipListItemModelProps {
  /**
   * Board membership company
   */
  company: SimpleLegalEntityModelProps;
  /**
   * Indicates if the user has casting vote in the company
   */
  hasCastingVote: boolean;
  /**
   * Indicates if the user is chairman in the company
   */
  isChairman: boolean;
}

/**
 * Board membership list item model
 *
 */
export class BoardMembershipListItemModel extends Model {
  company: SimpleLegalEntityModel;

  constructor({ company, ...restProps }: BoardMembershipListItemModelProps) {
    super(restProps);
    this.company = new SimpleLegalEntityModel(company);
  }
}
