import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { SignatoriesListView as SignatoriesListViewDS } from '@trustwise/design-system';
import { AddSignatoryFormHelpers, AddSignatoryFormValues } from 'signatories/types';
import { getUrl } from 'utils/urls';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import { getRouterLinkInteraction } from 'core/utils';
import useContacts from 'contacts/hooks';
import { IMultisigTransactionListItemModel } from 'signatories/models';
import { addSignatory, getSignatories, getTransactions } from './actions';

export const SignatoriesListView = () => {
  const navigate = useNavigate();
  const { onFetch } = useContacts(false, { 'entity-type': 'person', 'exclude-custodial': 1 });

  const { data: signatories } = useQuery({
    queryKey: ['signatories'],
    queryFn: getSignatories,
  });
  const { data: pendingTransactions } = useQuery({
    queryKey: ['pendingTransactions'],
    queryFn: () => getTransactions(['pending'], '3'),
  });
  const { data: executedTransactions } = useQuery({
    queryKey: ['executedTransactions'],
    queryFn: () => getTransactions(['executed'], '3'),
  });

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Signatories')}
      headerLeft={<BackButton href={getUrl()} />}
    >
      <SignatoriesListViewDS
        signatories={signatories}
        onSignatoryClick={(id: number) => getRouterLinkInteraction(`${id}/`)}
        onContactsFetch={onFetch}
        onContactSearch={({ searchString }) => onFetch(searchString)}
        onAdd={(values, actions) => addSignatory(values as AddSignatoryFormValues, actions as AddSignatoryFormHelpers)}
        pendingTransactions={pendingTransactions}
        executedTransactions={executedTransactions as IMultisigTransactionListItemModel[]}
        onTransactionClick={(id: number) => getRouterLinkInteraction(`multisig-transactions/${id}`)}
        onSeeAll={() => { navigate('multisig-transactions/'); }}
      />
    </PageContent>
  );
};
