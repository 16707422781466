import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import PageNotFoundContent from 'core/page/contentParts/404';
import { getUrl } from 'utils/urls';
import { FullPageView } from 'core/page';
import { MembershipOverview } from './views/overview';
import { MembershipsList } from './views/list';
import { BoardEvents } from './views/events';
import { BoardMembersList } from './views/members';
import { BoardMemberDetails } from './views/memberDetails';

const queryClient = new QueryClient();

const BoardroomMembershipView = () => {
  const basePath = '/memberships/boardroom';

  return (
    <FullPageView>
      <QueryClientProvider client={queryClient}>
        <Router basename={getUrl(basePath)}>
          <Routes>
            <Route path="/">
              <Route index element={<MembershipsList />} />
              <Route path=":companyId/">
                <Route index element={<MembershipOverview />} />
                <Route path="members/" element={<BoardMembersList />} />
                <Route path="members/:memberId/" element={<BoardMemberDetails />} />
                <Route path="meetings/*" element={<BoardEvents basePath={basePath} />} />
              </Route>
            </Route>
            <Route path="*" element={<PageNotFoundContent />} />
          </Routes>
        </Router>
      </QueryClientProvider>
    </FullPageView>
  );
};

export default BoardroomMembershipView;
