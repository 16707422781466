import { useState } from 'react';
import PropTypes from 'prop-types';
import { icons } from 'core/icons';
import DocumentUpload from './upload';


const SwitchableDocumentUpload = ({
  showDocumentType,
  showDocumentDate,
  documentDateFieldName,
  documentSubjectFieldName,
  documentTypeFieldName,
}) => {
  const [showDocumentForm, setShowDocumentForm] = useState(false);
  return (
    <div className="form-section document-upload-form-section">
      <div className="form-section-header action" onClick={() => setShowDocumentForm(!showDocumentForm)}>
        <h4 className="header-with-icon">
          <span className="icon">{icons.document}</span>
          {gettext('Add document')}
        </h4>
        {showDocumentForm ? icons.chevronUp : icons.chevronDown}
      </div>
      {showDocumentForm && (
        <div className="form-section-content">
          <DocumentUpload
            showDocumentType={showDocumentType}
            showDocumentDate={showDocumentDate}
            documentDateFieldName={documentDateFieldName}
            documentSubjectFieldName={documentSubjectFieldName}
            documentTypeFieldName={documentTypeFieldName}
          />
        </div>
      )}
    </div>
  );
};

SwitchableDocumentUpload.defaultProps = {
  documentDateFieldName: 'date',
  documentSubjectFieldName: 'subject',
  documentTypeFieldName: 'documentType',
  showDocumentType: false,
  showDocumentDate: true,
};

SwitchableDocumentUpload.propTypes = {
  documentDateFieldName: PropTypes.string,
  documentSubjectFieldName: PropTypes.string,
  documentTypeFieldName: PropTypes.string,
  showDocumentType: PropTypes.bool,
  showDocumentDate: PropTypes.bool,
};

export default SwitchableDocumentUpload;
