/* Paid Items List */
import PropTypes from 'prop-types';

import Link from 'components/common/link';
import { icons } from 'core/icons';
import { formatNumber } from 'core/utils';
import { APP_PRICING_LINK, PRICING_CURRENCY } from '../consts';


const PaidItemsList = ({ planItems, showSeparator }) => (
  <>
    <div className="tile-label-with-icon pricing-plan-items">
      {icons.plus}
      <span className="label">{gettext('Transaction fees')}</span>
    </div>
    <ul className={showSeparator ? 'pricing-plan-tile-section-separator' : undefined}>
      {planItems.map((planItem) => (
        <li className="pricing-plan-item paid-item" key={planItem.id}>
          <span>{planItem.name}</span>
          <span className="paid-item-price">{`${formatNumber(planItem.price)} ${PRICING_CURRENCY}`}</span>
        </li>
      ))}
      <li className="pricing-plan-item paid-item">
        <Link href={APP_PRICING_LINK} target="_blank">
          {gettext('See the full price list')}
        </Link>
      </li>
    </ul>
  </>
);

PaidItemsList.defaultProps = {
  showSeparator: true,
};

PaidItemsList.propTypes = {
  planItems: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  showSeparator: PropTypes.bool,
};

export default PaidItemsList;
