import { useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';

import FieldErrors from './errors';
import { BaseButton } from './button';
import FieldLabel from './label';

/**
 * Password field with label, errors and unmasking functionality.
 *
 * @todo add other props as necessary (className, placeholder, etc.)
 */
const PasswordField = ({ autoComplete, id, label, name, ...props }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  return (
    <>
      <div className="justify-content-space-between">
        {label && (<FieldLabel id={id || name} label={label} />)}
        <BaseButton className="text-secondary text-tiny" onClick={() => { setPasswordVisible(!passwordVisible); }}>
          {passwordVisible ? gettext('Hide password') : gettext('Show password')}
        </BaseButton>
      </div>
      <FieldErrors name={name} />
      <Field autoComplete={autoComplete} type={passwordVisible ? 'text' : 'password'} id={id || name} name={name} {...props} />
    </>
  );
};

PasswordField.defaultProps = {
  autoComplete: '',
  id: '',
  label: gettext('Password'),
};

PasswordField.propTypes = {
  autoComplete: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default PasswordField;
