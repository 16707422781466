/* Page header component */
import Link from 'components/common/link';
import { getUrl } from 'utils/urls';
import { getGlobalContext } from 'core/globals';
import { icons } from '../../icons';


const ManagedAccountBanner = () => {
  const {
    custodian: { id: custodianId },
    activeEntity: { name: activeEntityName },
  } = getGlobalContext();
  const custodianUrlBase = `/company/${custodianId}/custodian/`;

  return (
    <div className="managed-account-banner">
      <div className="managed-account-text">
        {/* @todo Find a way to interpolate string with JSX inside */}
        {gettext('Managing portfolio of ')}
        <strong>{activeEntityName}</strong>
      </div>
      <Link
        className="button button-small button-borderless button-inverted"
        href={getUrl(`${custodianUrlBase}switch/reset/`, { next: getUrl(custodianUrlBase) })}
      >
        {icons.exit}
        {gettext('Leave the account')}
      </Link>
    </div>
  );
};


export default ManagedAccountBanner;
