import ConfirmOffchainTransfer from '../../components/liabilities/restrictedTokenTransfer/confirmOffchainTransfer';
import { renderComponent } from '../../utils/renderComponent';
import { tokenTransferStore } from '../../stores/tokenTransferStore';
import ConfirmOffchainTransferForm from '../../forms/tokens/restrictedTransfer/confirmOffchainTransfer';


export default class ConfirmOffchainTransferComponent {
  constructor({
    companyId = null,
    businessTransactionId = null,
    businessTransactionType = null,
  }) {
    this.companyId = companyId;
    this.businessTransactionId = businessTransactionId;
    this.businessTransactionType = businessTransactionType;
  }

  render(elementId) {
    tokenTransferStore.form = new ConfirmOffchainTransferForm({
      companyId: this.companyId,
      businessTransactionId: this.businessTransactionId,
      businessTransactionType: this.businessTransactionType,
    });

    return renderComponent({
      elementId,
      component: ConfirmOffchainTransfer,
      props: { form: tokenTransferStore.form },
    });
  }
}
