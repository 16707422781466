import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { Field } from 'formik';

import { primaryColor, colorPrimary80, colorNeutral20, colorPrimary50, colorNeutral50 } from 'core/styles';
import FieldErrors from './errors';
import { icons } from '../../icons';
import FieldLabel from './label';


/**
 * Basic select field to choose one of the provided options.
 *
 * TODO check options implementation in formik
 * TODO add other props as necessary (className, etc.)
 */
const SelectField = ({ id, label, name, options, ...props }) => (
  <>
    {label && (<label htmlFor={id || name}>{label}</label>)}
    <FieldErrors name={name} />
    <Field as="select" id={id || name} name={name} options={options} {...props} />
  </>
);

SelectField.defaultProps = {
  id: '',
  label: '',
};

SelectField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

/**
 * Enhanced select field based on react-select.
 */

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? primaryColor : state.isFocused && colorPrimary80,
  }),
  // @todo Replace with proper from styles once available
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: '15px',
    lineHeight: '16px',
    fontWeight: 600,
    color: state.isDisabled ? colorNeutral50 : colorNeutral20,
    margin: 0,
  }),
  indicatorSeparator: () => ({ display: 'none' }),
  control: (provided, state) => ({
    ...provided,
    padding: '12px 14px',
    height: '48px',
    marginBottom: '32px',
    boxShadow: 'none',
    borderColor: state.isFocused ? colorPrimary50 : provided.borderColor,
    borderRadius: '6px',
    outline: state.isFocused ? `2px solid ${colorPrimary50}` : 'none',
    backgroundColor: state.isDisabled ? 'transparent' : provided.backgroundColor,
    '&:hover': {
      borderColor: colorNeutral20,
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: 0,
  }),
  menu: (provided) => ({
    ...provided,
    top: '50px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    borderRadius: '2px',
    minWidth: '258px',
    margin: 0,
    fontSize: '15px',
    lineHeight: '20px',
    fontWeight: 600,
    color: '#3C3C3E',
  }),
  input: (provided) => ({
    ...provided,
    margin: 0,
    padding: 0,
    input: {
      margin: 0,
      height: '20px',
    },
  }),
};

// Custom icon for dropdown indicator
const DropdownIndicator = (props) => {
  const { isDisabled } = props;
  return (
    <components.DropdownIndicator {...props}>
      {isDisabled ? icons.chevronDownDisabled : icons.chevronDown}
    </components.DropdownIndicator>
  );
};

DropdownIndicator.defaultProps = {
  isDisabled: false,
};

DropdownIndicator.propTypes = {
  isDisabled: PropTypes.bool,
};


const ReactSelectField = ({
  getOptionLabel,
  getOptionValue,
  id,
  isSearchable,
  label,
  isOptional,
  name,
  options,
  className,
  setValue,
  disabled,
  ...props
}) => {
  const classNames = `react-select-container ${className}`;

  // Needed to handle grouped options
  const getSelectedOption = (fieldValue) => {
    let selectedOption = '';
    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      if (option.options) {
        selectedOption = option.options.find((groupOption) => getOptionValue(groupOption) === fieldValue);
        if (selectedOption) { break; }
      }
      if (getOptionValue(option) === fieldValue) {
        selectedOption = option;
        break;
      }
    }
    return selectedOption;
  };

  return (
    <>
      {label && (<FieldLabel id={id || name} label={label} isOptional={isOptional} />)}
      <FieldErrors name={name} />
      <Field
        id={id || name}
        name={name}
        component={({ field, form }) => (
          <Select
            styles={customStyles}
            components={{ DropdownIndicator }}
            value={getSelectedOption(field.value)}
            options={options}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            className={classNames}
            isSearchable={isSearchable}
            isDisabled={disabled}
            onChange={(option) => {
              form.setFieldValue(field.name, getOptionValue(option));
              setValue(option);
            }}
            {...props}
          />
        )}
      />
    </>
  );
};

ReactSelectField.defaultProps = {
  getOptionLabel: (option) => option.label,
  getOptionValue: (option) => option.value,
  setValue: () => { },
  setFieldValue: () => { },
  id: '',
  isSearchable: false,
  label: '',
  options: [],
  className: '',
  disabled: false,
  isOptional: false,
};

ReactSelectField.propTypes = {
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  setValue: PropTypes.func,
  setFieldValue: PropTypes.func,
  id: PropTypes.string,
  isSearchable: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isOptional: PropTypes.bool,
};


export { SelectField, ReactSelectField, customStyles };
