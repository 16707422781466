import { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

class FxScopeSelector extends Component {
  getClassNames = (option) => {
    const { selected } = this.props;

    let cssClassName = 'option';
    if (option === selected) {
      cssClassName += ' selected';
    }

    return cssClassName;
  };

  render() {
    const { options, onSelect } = this.props;

    return (
      <div className="fx-options">
        {options.map(
          (o) => <div key={o} className={this.getClassNames(o)} onClick={() => onSelect(o)}>{o}</div>,
        )}
      </div>
    );
  }
}

FxScopeSelector.defaultProps = {
  selected: null,
  options: [],
  onSelect: () => {},
};

FxScopeSelector.propTypes = {
  selected: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array,
  onSelect: PropTypes.func,
};

export default observer(FxScopeSelector);
