import { CreateCertificateTypeView as CreateCertificateTypeViewDS } from '@trustwise/design-system';

import { PageContent } from 'core/page';
import { getRouterLinkInteraction } from 'core/utils';
import BackButton from 'core/page/parts/backButton';
import { createCertificateType } from '../actions';

export const CreateCertificateTypeView = () => (
  <PageContent
    fullscreen
    pageHeading={gettext('Create New Certificate Type')}
    headerLeft={<BackButton href="/management/types/" useRouterLink />}
  >
    <CreateCertificateTypeViewDS
      backInteraction={getRouterLinkInteraction('management/types/')}
      onSubmit={createCertificateType}
    />
  </PageContent>
);
