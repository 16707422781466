import PropTypes from 'prop-types';

import { getNativeFileUrl } from 'media/utils';
import { getUrl, getApiUrl } from 'utils/urls';
import { isNativeAppWebview } from 'utils/general';
import { getInboxActionContext, getMultiSigActionContext } from './actionsContext/actionContext';

export default class InboxItemModel {
  constructor({
    processId = null,
    itemId = null,
    date = null,
    avatar = { color: '#ccc', initials: '?', image: '' },
    title = '',
    content = '',
    notSeen = false,
    successful = true,
    progress = 0,
    relatedObj = { id: null, model: '' },
    topic = '',
    context = { },
    status = '',
    role = '',
    isRemovable = false,
  }) {
    this.processId = parseInt(processId, 10) || processId;
    this.itemId = parseInt(itemId, 10) || itemId;
    this.date = date ? new Date(date) : date;
    this.avatar = avatar;
    this.title = title;
    this.content = content;
    this.notSeen = notSeen;
    this.successful = successful;
    this.progress = progress;
    this.relatedObj = relatedObj;
    this.topic = topic;
    this.context = context;
    this.status = status;
    this.role = role;
    this.isRemovable = isRemovable;
  }

  update(data) { Object.entries(data).forEach(([key, value]) => { this[key] = value; }); }

  // Properties definitions
  static propType = PropTypes.shape({
    processId: PropTypes.number,
    date: PropTypes.instanceOf(Date),
    avatar: PropTypes.shape({
      color: PropTypes.string,
      initials: PropTypes.string,
      image: PropTypes.string,
    }),
    title: PropTypes.string,
    content: PropTypes.string,
    notSeen: PropTypes.bool,
    successful: PropTypes.bool,
    progress: PropTypes.number,
    relatedObj: PropTypes.shape({
      id: PropTypes.number,
      model: PropTypes.string,
    }),
    topic: PropTypes.string,
    context: PropTypes.shape({}),
    status: PropTypes.string,
  });

  get actionContext() {
    const {
      multisig_blockchain_id: blockchainId,
      delegated_contract_address: delegatedContractAddress,
      multisig_tx_id: multisigTxId,
      custodial_entity_id: custodialEntityId,
      custodial_entity_name: custodialEntityName,
    } = this.context;
    if (blockchainId || multisigTxId) {
      const multiSigContext = getMultiSigActionContext(blockchainId, delegatedContractAddress, multisigTxId);
      const { path, queryArgs, label } = multiSigContext;
      return {
        label,
        url: getUrl(path, queryArgs),
      };
    }

    const custodianLabel = gettext(`Switch to ${custodialEntityName} account`);
    const custodianPath = `/custodian/switch/${custodialEntityId}/`;

    if (this.topic === 'file_download') {
      const downloadUrl = getApiUrl(`/inbox/${this.itemId}/download-file/`);
      const { media_type: mediaType, file_name: fileName } = this.context;
      return custodialEntityId ? {
        url: getUrl(custodianPath),
        label: custodianLabel,
      } : {
        label: gettext('Download'),
        url: isNativeAppWebview
          ? getNativeFileUrl(downloadUrl, fileName, mediaType)
          : downloadUrl,
      };
    }

    const context = getInboxActionContext(this);
    if (!context) return null;

    const { path, queryArgs, label } = context;

    if (custodialEntityId) {
      return {
        url: getUrl(custodianPath, { next: `${path}${queryArgs ? `&${queryArgs}` : ''}` }),
        label: custodianLabel,
      };
    }
    return {
      label,
      url: getUrl(path, queryArgs),
    };
  }
}
