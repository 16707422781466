import { camelCase } from 'lodash';

import { convertKeysCase } from 'core/utils';
import convertResponseToModel from 'utils/responseToModel';
import { UtilityTokenIssuerModel, UtilityTokenTransactionModel } from './model';

const reducer = (currentState, action) => {
  const { data, type } = action;

  switch (type) {
    case 'fetchToken':
      return {
        ...currentState,
        token: new UtilityTokenIssuerModel(convertKeysCase(data, camelCase)),
      };

    case 'fetchTransactions':
      return {
        ...currentState,
        transactions: convertResponseToModel(data, UtilityTokenTransactionModel),
      };

    case 'fetchInCirculation':
      return {
        ...currentState,
        inCirculationData: {
          ...currentState.inCirculationData,
          ...data,
        },
      };

    case 'fetchBalance':
      return {
        ...currentState,
        balanceData: {
          ...currentState.balanceData,
          ...data,
        },
      };

    case 'resetInCirculation':
      return {
        ...currentState,
        inCirculationData: {
          ...currentState.inCirculationData,
          timeSeries: undefined,
        },
      };

    case 'resetBalance':
      return {
        ...currentState,
        balanceData: {
          ...currentState.balanceData,
          timeSeries: undefined,
        },
      };

    default:
      return currentState;
  }
};

export default reducer;
