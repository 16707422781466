import { useState, useCallback } from 'react';

import { getApiUrl } from 'utils/urls';
import axios from 'core/axios';
import { CONFIRMED } from 'contacts/models/contacts';

const useContacts = (withPortfolios = false, queryArgs = {}) => {
  const [contacts, setContacts] = useState(undefined);
  const url = getApiUrl('/contacts/', {
    ...queryArgs,
    status: CONFIRMED,
    ordering: 'legal_entity__name',
  });

  const getContacts = useCallback((response) => (response.map((contact) => {
    const legalEntity = contact.legal_entity;
    const legalEntityData = {
      id: legalEntity.id,
      name: legalEntity.name,
      avatar: {
        backgroundColor: legalEntity.color,
        initials: legalEntity.initials,
        image: legalEntity.image,
      },
    };
    return withPortfolios ?
      contact.allowed_portfolios.map((portfolio) => ({ ...legalEntityData, portfolio }))
      : legalEntityData;
  }).flat()), [withPortfolios]);

  const onFetch = useCallback((search) => {
    setContacts(undefined);
    let searchUrl = url;
    if (search) {
      searchUrl = `${url}&search=${search}`;
    }
    return axios.get(searchUrl)
      .then(({ data }) => {
        const contactsData = getContacts(data);
        setContacts(contactsData);
        return contactsData;
      })
      .catch(console.error);
  }, [url, getContacts]);

  return {
    contacts,
    onFetch,
  };
};

export default useContacts;
