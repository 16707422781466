import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { SubscriptionDetailView as SubscriptionDetailViewDS } from '@trustwise/design-system';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import { confirmSubscriptionPayment, getSubscription, getSubscriptionDocuments, onFileDownload } from './actions';
import { SubscriptionsModel } from './models';

export const SubscriptionDetailView = () => {
  const queryClient = useQueryClient();
  const { subscriptionId } = useParams();

  if (!subscriptionId) {
    throw new Error('Subscription relationship ID is missing');
  }

  const { data: subscription } = useQuery({
    queryKey: ['subscription', subscriptionId],
    queryFn: () => getSubscription(subscriptionId),
  });

  const { data: documents } = useQuery({
    queryKey: ['subscriptionDocs', subscriptionId],
    queryFn: () => getSubscriptionDocuments(subscription?.subscriptionId),
    enabled: !!subscription,
  });

  const { mutateAsync: markAsPaid } = useMutation({
    mutationFn: confirmSubscriptionPayment,
    onSuccess: () => {
      queryClient.setQueryData(
        ['subscription', subscriptionId],
        (prevState?: SubscriptionsModel) => ({ ...prevState, payed: true }),
      );
    },
  });

  const allowPayment = (
    subscription &&
    !subscription.payed &&
    new Date() < subscription.closingDate &&
    subscription?.status === 'subscriptions_allotted'
  );

  /** @todo re enabled when the requirements for the update are clarified
  const allowPowerOfAttorneyUpdate = (
    new Date() < subscription.closingDate &&
    && ['subscriptions_allotted', 'created'].includes(subscription.status)
  );
  */

  return (
    <PageContent
      fullscreen
      pageHeading={subscription?.name ? `${subscription.name} Subscription` : ''}
      headerLeft={<BackButton href="/" useRouterLink />}
    >
      <SubscriptionDetailViewDS
        subscription={subscription}
        onPaymentConfirm={
          allowPayment ? (_ev, setDisabled) => markAsPaid({ subscriptionId, setDisabled }) : undefined
        }
        documents={documents}
        onFileDownload={(fileId) => onFileDownload(fileId, documents)}
        // onPowerOfAttorneyToggle={}
      />
    </PageContent>
  );
};
