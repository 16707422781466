import PropTypes from 'prop-types';
import PhoneInput from 'react-phone-input-2';

import { Field } from 'formik';
import FieldErrors from './errors';
import FieldLabel from './label';


const PhoneNumberInput = (
  {
    autoFormat,
    country,
    disabled,
    disableSearchIcon,
    enableSearch,
    enableTerritories,
    name,
    placeholder,
    preferredCountries,
    label,
    isOptional,
    setPhoneCountryCode,
  },
) => (
  <>
    <FieldLabel id={name} label={label} isOptional={isOptional} />
    {/* <FieldLabel htmlFor="phoneNumber">{gettext('Phone Number')}</label> */}
    <FieldErrors name={name} />
    <Field name={name}>
      {({ field: { value }, form: { handleBlur, setFieldValue } }) => (
        <PhoneInput
          autoFormat={autoFormat}
          country={country}
          inputProps={{}}
          enableSearch={enableSearch}
          disableSearchIcon={disableSearchIcon}
          enableTerritories={enableTerritories}
          placeholder={placeholder}
          preferredCountries={preferredCountries}
          value={value}
          disabled={disabled}
          onBlur={handleBlur(name)}
          onChange={(newValue, selectedCountry) => {
            setPhoneCountryCode(selectedCountry.countryCode);
            setFieldValue(name, newValue);
          }}
        />
      )}
    </Field>
  </>
);


PhoneNumberInput.defaultProps = {
  autoFormat: true,
  disabled: false,
  disableSearchIcon: true,
  enableTerritories: false,
  enableSearch: true,
  placeholder: gettext('Enter phone number'),
  preferredCountries: [],
  label: '',
  isOptional: false,
};

PhoneNumberInput.propTypes = {
  autoFormat: PropTypes.bool,
  disabled: PropTypes.bool,
  disableSearchIcon: PropTypes.bool,
  enableSearch: PropTypes.bool,
  enableTerritories: PropTypes.bool,
  country: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  preferredCountries: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  isOptional: PropTypes.bool,
  setPhoneCountryCode: PropTypes.func.isRequired,
};

export default PhoneNumberInput;
