const actionRequired = 'action_required';
const inProgress = 'in_progress';
const completed = 'completed';

const tabOptions = {
  [actionRequired]: { label: gettext('To do'), emptyContent: gettext('No action items') },
  [inProgress]: { label: gettext('Pending'), emptyContent: gettext('No pending items') },
  [completed]: { label: gettext('Done'), emptyContent: gettext('No completed items') },
};

export { actionRequired, inProgress, completed, tabOptions };
