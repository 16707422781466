import { extendObservable } from 'mobx';
import Store from './store';

class InboxItemsCountStore extends Store {
  constructor() {
    super();
    extendObservable(this, {
      collection: {},
    });
    this.countById = this.countById.bind(this);
    this.countExceptId = this.countExceptId.bind(this);
  }

  setCounts(counts) {
    this.collection = counts;
  }

  get counts() {
    return this.collection;
  }

  get totalCount() {
    return Object.values(this.counts).reduce((sum, val) => sum + val, 0);
  }

  countById(id) {
    return this.counts[id];
  }

  countExceptId(id) {
    const reducer = (sum, [k, v]) => sum + (id && id.toString() !== k ? v : 0);
    return Object.entries(this.counts).reduce(reducer, 0);
  }
}

export const inboxItemsCountStore = new InboxItemsCountStore();
export { InboxItemsCountStore };
