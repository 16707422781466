import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { shareholderMeeting } from '../constants';
import Loader from '../../components/common/loader';
import { ReactSelectField, TextField } from '../../core/forms/fields';


export const validationGeneralInfo = Yup.object({
  subject: Yup.string()
    .max(128, gettext('Line is too long'))
    .required(gettext('Required')),
  eventType: Yup.string()
    .max(2, gettext('Invalid value'))
    .required(gettext('Required')),
});

const GeneralInfo = ({ isSubmitting, eventTypesOptions, setEventTypeState }) => (
  <Loader isLoading={isSubmitting}>
    <TextField label={gettext('Subject')} name="subject" />
    <ReactSelectField
      label={gettext('Type')}
      name="eventType"
      defaultValue={eventTypesOptions.find((option) => option.value === shareholderMeeting)}
      options={eventTypesOptions}
      setValue={(option) => setEventTypeState(option.value)}
    />
  </Loader>
);

GeneralInfo.defaultProps = {
  isSubmitting: false,
  setEventTypeState: () => {},
  eventTypesOptions: [],
};

GeneralInfo.propTypes = {
  isSubmitting: PropTypes.bool,
  setEventTypeState: PropTypes.func,
  eventTypesOptions: PropTypes.arrayOf(PropTypes.shape()),
};

export default GeneralInfo;
