import { convertKeysToCamelCase } from 'core/utils';
import { getUrlWithoutOrigin } from 'utils/urls';
import convertResponseToModel from 'utils/responseToModel';
import { ContactsModel } from './models/contacts';

const reducer = (currentState, action) => {
  const { data } = action;
  switch (action.type) {
    case 'fetch': {
      const contacts = data.results || data;
      return {
        ...currentState,
        contacts: convertResponseToModel(contacts, ContactsModel),
        nextPage: getUrlWithoutOrigin(data.next),
      };
    }
    case 'fetchNext':
      return {
        ...currentState,
        contacts: [...currentState.contacts, ...convertResponseToModel(data.results, ContactsModel)],
        nextPage: getUrlWithoutOrigin(data.next),
      };
    case 'fetchSingle':
      return {
        ...currentState,
        contacts: [new ContactsModel(convertKeysToCamelCase(data))],
      };
    case 'update': {
      const updatedContacts = [...currentState.contacts];
      data.activeContact.update(convertKeysToCamelCase(data.update));
      updatedContacts[updatedContacts.indexOf(data.activeContact)] = data.activeContact;
      return { ...currentState, contacts: updatedContacts };
    }
    case 'remove': {
      const updatedContacts = [...currentState.contacts];
      updatedContacts.splice(updatedContacts.indexOf(data.activeContact), 1);
      return { ...currentState, contacts: updatedContacts };
    }
    case 'cleanState':
      return {
        ...currentState,
        contacts: [],
        nextPage: null,
      };
    default:
      return currentState;
  }
};

export default reducer;
