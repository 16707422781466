/* Page content component */
import PageNotFoundContent from 'core/page/contentParts/404';
import PageMainContent, { PageMainContentProps } from 'core/page/contentParts/main';

interface PageContentProps extends PageMainContentProps {
  /**
   * Page status which is used to derive which content to render
   */
  pageStatus?: number;
}


const PageContent = ({
  pageStatus,
  ...mainContentProps
}: PageContentProps) => {

  switch (pageStatus) {
    case 404:
      return <PageNotFoundContent />;
    default:
      return <PageMainContent {...mainContentProps} />;
  }

};

export default PageContent;
