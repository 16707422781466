export const getChartData = (items) => Object.values(items.reduce((item, asset) => {
  const { id, amount } = asset;

  if (!item[id]) {
    item[id] = { ...asset };
  } else {
    item[id].amount += amount;
  }
  return item;
}, {}));
