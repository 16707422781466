import { isPlainObject, camelCase, snakeCase } from 'lodash';

type KeyConversionMethod = (value?: string) => string;

export const convertKeysCase = <T extends object>(
  obj: T,
  conversionMethod: KeyConversionMethod,
): T => Object.entries(obj).reduce(
    (accumulator, [key, value]) => ({ ...accumulator, [conversionMethod(key)]: value }),
    {} as T,
  );

export const convertKeysToCamelCase = <T extends object>(obj: T) => convertKeysCase(obj, camelCase);
export const convertKeysToSnakeCase = <T extends object>(obj: T) => convertKeysCase(obj, snakeCase);

export const convertNestedKeysCase = <T>(obj: T, conversionMethod: KeyConversionMethod) => {
  if (!conversionMethod || !obj) {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map((v) => convertNestedKeysCase(v, conversionMethod));
  }
  const result = convertKeysCase(obj, conversionMethod);
  Object.entries(result).forEach(([key, value]) => {
    const isArrayOfObjects = Array.isArray(value) && value.every((item) => typeof item === 'object' && item !== null);
    if (isPlainObject(value) || isArrayOfObjects) {
      result[key] = convertNestedKeysCase(value, conversionMethod);
    }
  });

  return result;
};

export const convertNestedKeysToCamelCase = <T>(obj: T) => convertNestedKeysCase(obj, camelCase);
