import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { DateField } from 'core/forms/fields';
import { dateToday, formatDate } from 'utils/date';

const today = dateToday();
const url = new URL(window.location.href);
const urlDate = url.searchParams.get('date');
const parseUrlDate = Date.parse(urlDate);
const initialDate = parseUrlDate ? urlDate : today;
const formattedDate = formatDate(new Date(initialDate));

const InvestmentsDateFilterForm = ({ edit }) => {
  const handleSubmit = ({ dateFilterDate }, { setSubmitting }) => {
    if (dateFilterDate !== initialDate) {
      url.searchParams.set('date', dateFilterDate);
      window.location = url.toString();
    }
    setSubmitting(false);
  };
  return (
    <Formik
      enableReinitialize
      initialValues={{ dateFilterDate: initialDate }}
      validationSchema={Yup.object({
        dateFilterDate: Yup.date()
          .max(today, gettext("Date can't be in the future."))
          .required(gettext('Please select a valid date.')),
      })}
      onSubmit={handleSubmit}
    >
      {({ submitForm }) => (
        <Form>
          {gettext('Total asset value as per ')}
          {edit ? (
            <DateField
              onBlur={submitForm}
              name="dateFilterDate"
              onFocus={(e) => { e.nativeEvent.target.defaultValue = today; }}
            />
          ) : <span>{gettext(`${formattedDate}`)}</span>}
        </Form>
      )}
    </Formik>
  );
};

InvestmentsDateFilterForm.defaultProps = {
  edit: false,
};

InvestmentsDateFilterForm.propTypes = {
  edit: PropTypes.bool,
};

export default InvestmentsDateFilterForm;
