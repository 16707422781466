import marked from 'marked';

import { SubscriptionItem, ShareClass, SubscriptionStatus } from '@trustwise/design-system';

import { SimpleLegalEntityModel } from 'contacts/models/legalEntity';
import { Model } from 'core/model';

interface SubscriptionsModelProps extends Omit<SubscriptionItem, 'closingDate' | 'endDate'> {
  /**
   * Timestamp for when the subscription is closed
   */
  closingTimestamp: number;
  /**
   * Timestamp for when the subscription is ended
   */
  endTimestamp: number;
}

export class SubscriptionsModel extends Model implements SubscriptionItem {
  closingDate: Date;
  endDate: Date;
  currency: string;
  id: number;
  name: string;
  legalText: string;
  noOfShares: number;
  powerOfAttorney: boolean;
  powerOfAttorneyText: string;
  pricePerShare: number;
  referenceNumber?: string;
  shareClass: ShareClass;
  status: SubscriptionStatus;
  subscriptionId: number;
  subscriber: SimpleLegalEntityModel;
  payed: boolean;

  constructor({
    subscriber,
    closingTimestamp,
    endTimestamp,
    shareClass,
    noOfShares,
    pricePerShare,
    legalText,
    powerOfAttorneyText,
    ...restProps
  }: SubscriptionsModelProps) {
    super(restProps);
    this.subscriber = SimpleLegalEntityModel.fromResponse(subscriber);
    this.endDate = new Date(endTimestamp * 1000);
    this.closingDate = new Date(closingTimestamp * 1000);
    this.noOfShares = Number(noOfShares);
    this.pricePerShare = Number(pricePerShare);
    this.shareClass = { ...shareClass, nominalValue: Number(shareClass.nominalValue) };
    this.legalText = marked(legalText);
    this.powerOfAttorneyText = marked(powerOfAttorneyText);
  }
}
