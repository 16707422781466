import PropTypes from 'prop-types';

const Header = ({ title, underline, className }) => (
  <header className={`row-flex ${underline ? 'underline' : ''} ${className || ''} not-padded`}>
    <h3 className="title font-size-16px">{title}</h3>
  </header>

);

Header.defaultProps = {
  underline: false,
  className: '',
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  underline: PropTypes.bool,
  className: PropTypes.string,
};

export default Header;
