import { snakeCase } from 'lodash';
import { FormikHelpers } from 'formik';

import {
  CertificateTypeStatus,
  CreateCertificateTypeSubmitValues,
  CertificateTypeStatuses,
} from '@trustwise/design-system';
import { signFormTransaction, signTransaction } from 'utils/signTransaction';
import { convertKeysToSnakeCase, convertNestedKeysCase } from 'core/utils';
import { baseCertificatesTypePath } from 'certificates/consts';

export const createCertificateType = (
  values: CreateCertificateTypeSubmitValues,
  actions: FormikHelpers<CreateCertificateTypeSubmitValues>,
) => {
  const { encryptedDataSchema, website, files, ...restValues } = values;
  const data = new FormData();
  const convertedRestValues = convertNestedKeysCase(restValues, snakeCase);
  Object.entries(convertedRestValues).forEach(
    ([key, value]) => {
      if (typeof value === 'object') {
        Object.entries(value as object).forEach(([nestedKey, nestedValue]) => {
          data.append(`${key}.${nestedKey}`, nestedValue as string);
        });
      } else if (value !== undefined) {
        data.append(key, value as string | Blob);
      }
    },
  );
  const file = files?.[0];
  file && data.append('file', file as unknown as File);
  website && data.append('url', website);
  encryptedDataSchema.length && encryptedDataSchema.forEach((item, index) => {
    Object.entries(convertKeysToSnakeCase(item)).forEach(([key, value]) => {
      data.append(`encrypted_data_schema[${index}]${key}`, value as string);
    });
    data.append(`encrypted_data_schema[${index}]key`, snakeCase(item.label));
  });

  return signFormTransaction({
    urlPath: baseCertificatesTypePath,
    navigatePath: baseCertificatesTypePath,
    data,
    actions,
  });
};

export const removeRegistryFromCertificateType = (certificateTypeId: string, issuerRegistryId: number) => signTransaction({
  urlPath: `${baseCertificatesTypePath}${certificateTypeId}/registries/remove`,
  data: { registries_ids: [issuerRegistryId] },
});

type RegistryId = { registryId: number };
export const addRegistryToCertificateType = (
  values: RegistryId,
  actions: FormikHelpers<RegistryId>,
  certificateTypeId: string,
) => {
  const data = { registries_ids: [values.registryId] };

  return signFormTransaction({
    urlPath: `${baseCertificatesTypePath}${certificateTypeId}/registries/add`,
    data,
    actions,
  });
};

export const onIssuingToggle = (status: CertificateTypeStatus, certificateTypeId: string) => {
  signTransaction({
    urlPath: `${baseCertificatesTypePath}${certificateTypeId}/update/`,
    data: { issuing_enabled: status !== CertificateTypeStatuses.ACTIVE },
  });
};

export const onInvalidate = (certificateTypeId: string) => {
  signTransaction({
    urlPath: `${baseCertificatesTypePath}${certificateTypeId}/invalidate/`,
    navigatePath: baseCertificatesTypePath,
  });
};
