import PropTypes from 'prop-types';
import { Field } from 'formik';
import TextField from './text';
import FieldLabel from './label';

/**
 * Ratio field.
 * `subLabels` and `disabled` props are lists where
 * `0` index item is for numerator and `1` index item is for denominator
 */
const RatioField = ({
  id,
  name,
  label,
  subLabels,
  disabled,
  separator,
  onChange,
  isOptional,
}) => (
  <>
    {label && (<FieldLabel id={id || name} label={label} isOptional={isOptional} />)}
    <Field name={name}>
      {({ field: { value }, form: { setFieldValue } }) => (
        <div className="ratio-fields-group">
          <TextField
            value={value[0]}
            min={1}
            step={1}
            label={subLabels[0]}
            name={`${name}-numerator`}
            type="number"
            className="ratio-input-field"
            onChange={(e) => {
              const newValues = [e.target.value, value[1]];
              setFieldValue(name, newValues);
              onChange(newValues);
            }}
            disabled={disabled[0]}
          />
          <div className="ratio-input-field-separator">{separator}</div>
          <TextField
            value={value[1]}
            label={subLabels[1]}
            min={1}
            step={1}
            name={`${name}-denominator`}
            type="number"
            className="ratio-input-field"
            onChange={(e) => {
              const newValues = [value[0], e.target.value];
              setFieldValue(name, newValues);
              onChange(newValues);
            }}
            disabled={disabled[1]}
          />
        </div>
      )}
    </Field>
  </>
);

RatioField.defaultProps = {
  id: '',
  label: '',
  subLabels: ['', ''],
  disabled: [false, false],
  separator: '/',
  onChange: () => { },
  isOptional: false,
};

RatioField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  subLabels: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.arrayOf(PropTypes.bool),
  separator: PropTypes.string,
  onChange: PropTypes.func,
  isOptional: PropTypes.bool,
};

export default RatioField;
