import { useNavigate } from 'react-router-dom';

import { UtilityTokenIssuingForm } from '@trustwise/design-system';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import { signFormTransaction } from 'utils/signTransaction';

const UtilityTokenCreateView = () => {
  const navigate = useNavigate();

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Issue Utility')}
      headerLeft={<BackButton onClick={() => navigate('/')} />}
    >
      <UtilityTokenIssuingForm
        onSubmit={(values, actions) => {
          signFormTransaction({
            urlPath: '/utility-tokens/',
            navigatePath: '/liabilities/utility/',
            data: { ...values, utility_token_type: values.type },
            actions,
          });
        }}
      />
    </PageContent>
  );
};

export default UtilityTokenCreateView;
