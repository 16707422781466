export const autoFillPaymentTokenName = {
  init(args) {
    const { target } = args;
    if (target.length) {
      this.registerModule();
    }
  },

  registerModule() {
    $('#id_currency').on('change', this.setText);
    this.setText(true);
  },

  setText(firstRun) {
    const currRegexp = /[A-Z]{3}\/[A-Z]{3}$/;
    if (currRegexp.test($('#id_title')[0].value) || firstRun === true) {
      const tokenCurrency = $('span.payment-token-currency')[0].textContent;
      const valuationCurrency = $('#id_currency')[0].value;
      $('#id_title')[0].value = `${valuationCurrency}/${tokenCurrency}`;
    }
  },

};
