import { observer } from 'mobx-react';

import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { industryOptions } from 'core/options';
import Step from './step';
import CheckBox from '../common/checkBox';
import DocumentUploadComponent from '../documentUpload';
import NonFieldErrors from '../common/nonFieldErrors';
import { ReactSelectField } from '../../core/forms/fields';


const MembershipAgreement = observer(class MembershipAgreement extends Step {
  constructor(props) {
    super(props);
    this.state = {
      agreementFetched: false,
      agreementText: '',
    };
  }

  componentDidMount() {
    const { model } = this.props;

    if (!model || !model.membershipType || !model.companyId) {
      return;
    }

    model.getAgreementText().then(
      ({ data: { data: agreementText } }) => {
        this.setState({
          agreementText,
          agreementFetched: true,
        });
      },
      console.error,
    );
  }

  render() {
    const { model } = this.props;
    const { agreementFetched, agreementText } = this.state;

    if (!agreementFetched) {
      return null;
    }

    return (
      <div className="md-content">
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: agreementText }} />
        <form>
          <CheckBox
            label={gettext('I Agree')}
            attrName="agreementGiven"
            handleChange={this.handleFormChange}
            isRequired
            value={model.agreementGiven}
            model={model}
          />
        </form>
      </div>
    );
  }
});

const Industry = observer(class Industry extends Step {
  constructor(props) {
    super(props);
    this.state = {
      options: industryOptions,
    };
  }

  render() {
    const { model } = this.props;
    const { options } = this.state;

    return (
      <Formik
        initialValues={{ industry: model.industry }}
        validationSchema={Yup.object({
          industry: Yup.string().required('This field is required'),
        })}
      >
        {() => (
          <Form>
            <NonFieldErrors errors={model.nonFieldErrors} />
            <ReactSelectField
              label={gettext('Industry')}
              name="industry"
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.id}
              options={options}
              isSearchable
              setValue={(option) => model.setProperty('industry', option.id)}
            />
          </Form>
        )}
      </Formik>
    );
  }
});

const Documents = observer(class Documents extends Step {
  get documentTypesContext() {
    const { model } = this.props;

    if (model.membershipType === 'consortium-member') {
      return 'CONSORTIUM_MEMBER_TYPES';
    }

    if (model.membershipType === 'validator') {
      return 'VALIDATOR_TYPES';
    }

    return [];
  }

  render() {
    const { model } = this.props;

    return (
      <form>
        <NonFieldErrors errors={model.nonFieldErrors} />
        <DocumentUploadComponent
          documentTypesContext={this.documentTypesContext}
          defaultDocumentType={model.document.defaultDocumentType}
          showSaveDocumentButton={false}
          document={model.document}
        />
      </form>
    );
  }
});

export { MembershipAgreement, Industry, Documents };
