import web3 from 'web3';

import { MIN_PASSWORD_LENGTH, ALPHANUMERIC_CHARACTERS } from 'certificates/issuing/constants';

export const generateRandomHex = (length: number): string => {
  const randomBytes = new Uint8Array(length);
  window.crypto.getRandomValues(randomBytes);
  return web3.utils.bytesToHex(randomBytes);
};

export const generatePassword = (length: number): string => {
  if (length < MIN_PASSWORD_LENGTH) {
    throw new Error(`String length must be at least ${MIN_PASSWORD_LENGTH} characters`);
  }
  const randomBytes = new Uint32Array(length);
  window.crypto.getRandomValues(randomBytes);

  return Array.from(randomBytes).map((byte) => {
    const randomIndex = byte % ALPHANUMERIC_CHARACTERS.length;
    return ALPHANUMERIC_CHARACTERS[randomIndex];
  }).join('');
};
