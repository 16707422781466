export default class Store {
  _isLoading = false;

  setProperty(attrName, val) {
    this[attrName] = val;
  }

  setLoading(val) {
    this.setProperty('_isLoading', val);
  }
}
