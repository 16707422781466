import { convertKeysToCamelCase } from 'core/utils';
import { RegisteredObjectDetailModel } from './models/registeredObject';

const reducer = (currentState, action) => {
  const { data, type } = action;

  switch (type) {
    case 'fetchObject': {

      return {
        registeredObject: new RegisteredObjectDetailModel(convertKeysToCamelCase(data)),
      };
    }

    case 'updateMediaOrder': {
      const { registeredObject } = currentState;
      const sortedMedia = data.map((updatedId) => registeredObject.media.find((item) => updatedId === item.id));
      registeredObject.media = sortedMedia;

      return { registeredObject };
    }

    default:
      return currentState;
  }
};

export default reducer;
