import { getGlobalContext } from 'core/globals';
import AccountSwitcher from '../../components/accountSwitcher';
import { renderComponent } from '../../utils/renderComponent';
import { stores } from '../../stores';

export default class AccountSwitcherComponent {
  constructor() {
    stores.accountSwitcherStore.setCurrentAccount(getGlobalContext().activeEntity.id);
  }

  render(elementId) {
    return renderComponent({
      elementId,
      component: AccountSwitcher,
    });
  }
}
