import { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

class NonFieldErrors extends Component {
  // eslint-disable-next-line class-methods-use-this
  renderErrorItem = (errorText, index) => {
    const idx = `non-field-error-${index}`;
    return <li key={idx} className="error">{errorText}</li>;
  };

  render() {
    const { errors } = this.props;

    if (!errors.length) return null;

    return (
      <ul className="messages-list">
        {errors.map(this.renderErrorItem)}
      </ul>
    );
  }
}

NonFieldErrors.defaultProps = {
  errors: [],
};

NonFieldErrors.propTypes = {
  errors: PropTypes.array, // eslint-disable-line react/forbid-prop-types
};

export default observer(NonFieldErrors);
