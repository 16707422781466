import { snakeCase } from 'lodash';

import { convertKeysCase } from 'core/utils';
import { signFormTransaction } from 'utils/signTransaction';
import { LIMIT_ORDER_SELL, OFF_CHAIN } from 'markets/consts';

export const onSellOrderCreate = ({ values, actions, submitPath, navigatePath }) => {
  const { arbiter, noOfUnits, validityDate, ...restProps } = values;
  const data = convertKeysCase(restProps, snakeCase);
  const submitData = new FormData();

  data.valid_until = validityDate;
  data.arbiter_id = arbiter.id;
  data.order_type = LIMIT_ORDER_SELL;
  data.payment_method = OFF_CHAIN;

  Object.entries(data).forEach(
    ([key, value]) => submitData.append(key, value),
  );

  submitData.append('amount', noOfUnits);

  signFormTransaction({
    urlPath: submitPath,
    navigatePath,
    data: submitData,
    actions,
  });
};
