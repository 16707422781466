import { DateTime } from 'luxon';

export const getTimeSeriesQueryArgs = (span = '1y') => {
  const now = DateTime.now();
  let interval = '1m';
  let startDate;
  switch (span) {
    case '1m':
      interval = '1d';
      startDate = now.minus({ months: 1 });
      break;
    case '6m':
      startDate = now.minus({ months: 6 });
      break;
    case '5y':
      interval = '1y';
      startDate = now.minus({ years: 5 });
      break;
    case 'all':
      interval = '1y';
      startDate = '';
      break;
    default:
      interval = '1m';
      startDate = now.minus({ years: 1 });
      break;
  }

  return {
    date_start: startDate ? startDate.toISODate() : '',
    date_end: now.toISODate(),
    interval,
  };
};
