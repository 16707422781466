/* Add and edit a whitelist */
import PropTypes from 'prop-types';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { ModalWithContent as Modal } from '@trustwise/design-system';
import { TextField, SubmitButton } from 'core/forms/fields';
import { parseErrorResponse } from 'core/forms/utils';
import axios from 'core/axios';
import FormErrors from 'components/common/formikErrors';

const EditWhitelist = ({ isOpen, onClose, listName, listDetailUrl, updateWhitelist }) => {
  const onSubmit = (values, actions) => {
    axios.patch(listDetailUrl, { name: values.name })
      .then(
        () => { updateWhitelist(); },
        ({ response: { data: errors } }) => { parseErrorResponse(errors, actions.setFieldError); },
      )
      .catch(console.error)
      .finally(() => { actions.setSubmitting(false); });
  };

  return (
    <Modal
      id="renameList"
      isOpen={isOpen}
      onClose={onClose}
      title={gettext('Edit whitelist')}
    >
      <Formik
        initialValues={{
          nonFieldErrors: '',
          listType: 'EL',
          name: listName,
        }}
        validationSchema={Yup.object({
          listType: Yup.string().matches(/(EL)/).required('This field is required'),
          name: Yup.string().required('This field is required'),
        })}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <ErrorMessage component={FormErrors} name="nonFieldErrors" />
            <TextField label={gettext('Name')} name="name" />
            <SubmitButton disabled={isSubmitting}>{gettext('Save')}</SubmitButton>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

EditWhitelist.defaultProps = {
  listName: '',
  listDetailUrl: '',
  isOpen: false,
};

EditWhitelist.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  listName: PropTypes.string,
  listDetailUrl: PropTypes.string,
  updateWhitelist: PropTypes.func.isRequired,
};

export default EditWhitelist;
