/* eslint-disable max-len */
import PropTypes from 'prop-types';

const UsersIcon = ({ className }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" className={className}>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.43868 8.30509C6.09251 8.30509 5.00123 7.2138 5.00123 5.86763C5.00123 4.52146 6.09251 3.43018 7.43868 3.43018C8.78485 3.43018 9.87614 4.52146 9.87614 5.86763C9.87614 7.2138 8.78485 8.30509 7.43868 8.30509ZM7.43868 9.24259C5.57475 9.24259 4.06373 7.73157 4.06373 5.86763C4.06373 4.0037 5.57475 2.49268 7.43868 2.49268C8.47537 2.49268 9.40288 2.96009 10.022 3.69567L10.022 3.69564C10.2385 3.95282 10.4172 4.24279 10.5498 4.55703L10.5497 4.5571C10.7197 4.96003 10.8136 5.40286 10.8136 5.86763C10.8136 6.33241 10.7197 6.77524 10.5497 7.17817L10.5498 7.17826C10.4173 7.4925 10.2385 7.78248 10.022 8.03967L10.022 8.0396C9.40288 8.77518 8.47537 9.24259 7.43868 9.24259ZM13.2111 14.1711C13.2111 12.2553 11.6581 10.7023 9.74235 10.7023H5.13535C3.21961 10.7023 1.6666 12.2553 1.6666 14.1711V16.9567C1.6666 17.2156 1.87646 17.4254 2.13535 17.4254H12.7423C13.0012 17.4254 13.2111 17.2156 13.2111 16.9567V14.1711ZM9.74235 11.6398C11.1403 11.6398 12.2736 12.7731 12.2736 14.1711V16.4879H2.6041V14.1711C2.6041 12.7731 3.73738 11.6398 5.13535 11.6398H9.74235ZM14.9089 10.7023H13.5857C13.8519 10.9915 14.0918 11.3052 14.3015 11.6398H14.9089C16.3069 11.6398 17.4402 12.7731 17.4402 14.1711V16.4879H15.2732V16.9568C15.2732 17.1169 15.2583 17.2736 15.2299 17.4254H17.9089C18.1678 17.4254 18.3777 17.2156 18.3777 16.9567V14.1711C18.3777 12.2553 16.8247 10.7023 14.9089 10.7023ZM12.6053 2.49268C12.2704 2.49268 11.9468 2.54146 11.6414 2.63231C11.8424 2.89303 12.0197 3.17298 12.1701 3.46892C12.3113 3.44347 12.4567 3.43018 12.6053 3.43018C13.9514 3.43018 15.0427 4.52146 15.0427 5.86763C15.0427 7.2138 13.9514 8.30509 12.6053 8.30509C12.4567 8.30509 12.3113 8.2918 12.1701 8.26635C12.0198 8.5623 11.8425 8.84225 11.6415 9.10297C11.9469 9.19381 12.2704 9.24259 12.6053 9.24259C14.4692 9.24259 15.9802 7.73157 15.9802 5.86763C15.9802 4.0037 14.4692 2.49268 12.6053 2.49268Z" fill="#5C5C5F" />
  </svg>
);

UsersIcon.defaultProps = {
  className: '',
};

UsersIcon.propTypes = {
  className: PropTypes.string,
};

export default UsersIcon;
