import PropTypes from 'prop-types';
import AccountAvatarRowPart from './accountAvatar';


const ListViewRow = ({ legalEntity, requestedEmail, isAnonymous, children }) => (
  <>
    <div className="cell small-2">
      <AccountAvatarRowPart legalEntity={legalEntity} isAnonymous={isAnonymous} />
    </div>
    {isAnonymous ? (
      <div className="cell small-8 no-overflow">
        <div className="text-small">{requestedEmail}</div>
      </div>
    ) : (
      <div className="cell small-8 no-overflow">
        <span className="title">{legalEntity.name}</span>
        <div className="text-tiny">{legalEntity.email}</div>
        <div className="text-tiny">{legalEntity.typeText}</div>
      </div>
    )}
    <div className="cell small-2 justify-content-flex-end">{children}</div>
  </>
);

ListViewRow.defaultProps = {
  isAnonymous: false,
  children: null,
  requestedEmail: '',
};

ListViewRow.propTypes = {
  legalEntity: PropTypes.shape({
    color: PropTypes.string,
    image: PropTypes.string,
    initials: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    typeText: PropTypes.string,
  }).isRequired,
  isAnonymous: PropTypes.bool,
  children: PropTypes.element,
  requestedEmail: PropTypes.string,
};


const SimpleListRow = ({ legalEntity, requestedEmail, isAnonymous, children }) => (
  isAnonymous ? (
    <>
      <div className="text-smaller">
        {requestedEmail}
        <span className="font-italic">
          {` (${gettext('pending request')})`}
        </span>
      </div>
      {children}
    </>
  ) : (
    <>
      <div className="text-smaller">
        {legalEntity.name}
        <span className="font-italic">
          {` (${gettext('approved request')})`}
        </span>
      </div>
      {children}
    </>
  )
);

SimpleListRow.defaultProps = {
  isAnonymous: false,
  children: null,
  requestedEmail: '',
};

SimpleListRow.propTypes = {
  legalEntity: PropTypes.shape({
    color: PropTypes.string,
    image: PropTypes.string,
    initials: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    typeText: PropTypes.string,
  }).isRequired,
  isAnonymous: PropTypes.bool,
  children: PropTypes.element,
  requestedEmail: PropTypes.string,
};


export { SimpleListRow, ListViewRow };
