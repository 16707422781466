export const red = '#f43f0a';
export const green = '#00bf96';
export const blue = '#2e9bfa';
export const plum = '#6a50f9';
export const lightGreen = '#3ade90';
export const yellow = '#ffd842';
export const darkBlue = '#1974c4';
export const white = '#ffffff';
export const grey = '#eae7e6';

export const chartColors = [
  red,
  green,
  blue,
  plum,
  lightGreen,
  yellow,
  darkBlue,
];
