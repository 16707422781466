// business types

import {
  AUSTRIA,
  CYPRUS,
  GERMANY,
  LIECHTENSTEIN,
  SWITZERLAND,
  NORWAY,
} from './jurisdictions';

export const MUNICIPALITY = { key: 'municipality', label: gettext('Municipality') };
export const LIMITED_COMPANY = { key: 'limited_company', label: gettext('Limited Company') };
export const COOPERATIVE = { key: 'cooperative', label: gettext('Cooperative') };
export const AKTIENGESELLSCHAFT = { key: 'aktiengesellschaft', label: gettext('AG') };
export const GMBH = { key: 'gmbh', label: gettext('GmbH') };
export const SOLE_PROPRIETORSHIP = { key: 'sole_proprietorship', label: gettext('Sole Proprietorship') };
export const ASSOCIATION = { key: 'association', label: gettext('Association (with equal membership)') };
export const UNREGISTERED_PARTNERSHIP = { key: 'unregistered_partnership', label: gettext('Unregistered Partnership') };
export const COMMUNITY_OF_HEIRS = { key: 'community_of_heirs', label: gettext('Community of Heirs') };
export const PUBLIC_CORPORATION = { key: 'public_corporation', label: gettext('Public Corporation') };
export const KOMMANDITGESELLSCHAFT = { key: 'kommanditgesellschaft', label: gettext('KG') };
export const CONSORTIUM_ASSOCIATION = { key: 'consortium_association', label: gettext('BC Governance Association') };
export const PRIVATE_LIMITED_LIABILITY_COMPANY = { key: 'private_limited_liability_company', label: gettext('AS') };
export const PUBLIC_LIMITED_LIABILITY_COMPANY = { key: 'public_limited_liability_company', label: gettext('ASA') };
export const NORWEGIAN_BRANCH_OF_A_FOREIGN_COMPANY = { key: 'norwegian_branch_of_a_foreign_company', label: gettext('NUF') };
export const PARTNERSHIPS = { key: 'partnerships', label: gettext('ANS/DA') };
export const INTERNAL_PARTNERSHIP = { key: 'internal_partnership', label: gettext('IS') };
export const LIMITED_PARTNERSHIP = { key: 'limited_partnership', label: gettext('KS') };
export const DEFAULT = { key: 'default', label: gettext('Default') };

export const businessTypesOptions = {
  [CYPRUS]: [
    LIMITED_COMPANY,
    COOPERATIVE,
    MUNICIPALITY,
  ],
  [SWITZERLAND]: [
    AKTIENGESELLSCHAFT,
    GMBH,
    SOLE_PROPRIETORSHIP,
    ASSOCIATION,
    CONSORTIUM_ASSOCIATION,
    UNREGISTERED_PARTNERSHIP,
    COMMUNITY_OF_HEIRS,
    PUBLIC_CORPORATION,
  ],
  [AUSTRIA]: [
    AKTIENGESELLSCHAFT,
    GMBH,
    KOMMANDITGESELLSCHAFT,
  ],
  [GERMANY]: [
    AKTIENGESELLSCHAFT,
    GMBH,
    KOMMANDITGESELLSCHAFT,
  ],
  [LIECHTENSTEIN]: [
    AKTIENGESELLSCHAFT,
    GMBH,
  ],
  [NORWAY]: [
    SOLE_PROPRIETORSHIP,
    PRIVATE_LIMITED_LIABILITY_COMPANY,
    PUBLIC_LIMITED_LIABILITY_COMPANY,
    NORWEGIAN_BRANCH_OF_A_FOREIGN_COMPANY,
    PARTNERSHIPS,
    INTERNAL_PARTNERSHIP,
    LIMITED_PARTNERSHIP,
  ],
};

