/* eslint-disable max-len */
const chevronUp = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.5977 14.6637C18.8418 14.9078 18.8418 15.3035 18.5977 15.5476C18.3537 15.7917 17.9579 15.7917 17.7138 15.5476L11.9687 9.80239L6.22344 15.5476C5.97936 15.7917 5.58363 15.7917 5.33955 15.5476C5.09548 15.3035 5.09547 14.9078 5.33955 14.6637L11.52 8.48325C11.5222 8.481 11.5244 8.47875 11.5267 8.47652C11.705 8.29821 11.9642 8.25016 12.1861 8.33236C12.268 8.36269 12.3448 8.41075 12.4106 8.47654C12.4121 8.47806 12.4136 8.47958 12.4151 8.48111L18.5977 14.6637Z" fill="#3C3C3E" />
  </svg>
);

const chevronDown = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.5977 9.36043C18.8418 9.11635 18.8418 8.72062 18.5977 8.47654C18.3537 8.23247 17.9579 8.23247 17.7138 8.47654L11.9687 14.2217L6.22344 8.47652C5.97936 8.23244 5.58363 8.23244 5.33955 8.47652C5.09548 8.72059 5.09547 9.11632 5.33955 9.3604L11.52 15.5409C11.5222 15.5431 11.5244 15.5454 11.5267 15.5476C11.705 15.7259 11.9642 15.774 12.1861 15.6918C12.268 15.6614 12.3448 15.6134 12.4106 15.5476C12.4121 15.5461 12.4136 15.5445 12.4151 15.543L18.5977 9.36043Z" fill="#3C3C3E" />
  </svg>
);

const chevronRight = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.31697 5.38301C9.07289 5.13893 8.67716 5.13893 8.43309 5.38301C8.18901 5.62709 8.18901 6.02282 8.43309 6.26689L14.1783 12.0121L8.43306 17.7573C8.18898 18.0014 8.18898 18.3971 8.43306 18.6412C8.67714 18.8853 9.07286 18.8853 9.31694 18.6412L15.4974 12.4607C15.4997 12.4585 15.5019 12.4563 15.5042 12.4541C15.6825 12.2758 15.7305 12.0165 15.6483 11.7946C15.618 11.7127 15.5699 11.6359 15.5041 11.5701C15.5026 11.5686 15.5011 11.5671 15.4996 11.5656L9.31697 5.38301Z" fill="#3C3C3E" />
  </svg>
);

const chevronLeft = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.6202 5.38301C14.8643 5.13893 15.26 5.13893 15.5041 5.38301C15.7482 5.62709 15.7482 6.02282 15.5041 6.26689L9.75893 12.0121L15.5042 17.7573C15.7482 18.0014 15.7482 18.3971 15.5042 18.6412C15.2601 18.8853 14.8643 18.8853 14.6203 18.6412L8.4398 12.4607C8.43754 12.4585 8.43529 12.4563 8.43306 12.4541C8.25476 12.2758 8.20671 12.0165 8.28891 11.7946C8.31923 11.7127 8.36729 11.6359 8.43309 11.5701C8.4346 11.5686 8.43612 11.5671 8.43765 11.5656L14.6202 5.38301Z" fill="#3C3C3E" />
  </svg>
);


export { chevronUp, chevronDown, chevronRight, chevronLeft };
