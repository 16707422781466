import { getApiUrl } from 'utils/urls';
import axios from 'core/axios';

export const onTransfersFetch = ({ portfolioId, tokenId, dispatch }) => {
  axios.get(getApiUrl(`/assets/utility-tokens/${portfolioId}/${tokenId}/events/`))
    .then(({ data }) => dispatch({ type: 'fetchTransactions', data }))
    .catch(console.error);
};

export const getUtilityTokenDetail = ({ portfolioId, tokenId, dispatch }) => (
  axios.get(getApiUrl(`/assets/utility-tokens/${portfolioId}/${tokenId}/`))
    .then(({ data }) => dispatch({ type: 'fetchDetails', data }))
    .catch(console.error)
);
