import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import { PricingTriggersKey } from '@trustwise/design-system';
import { FullPageView } from 'core/page';
import { getUrl } from 'utils/urls';
import PageNotFoundContent from 'core/page/contentParts/404';
import { AllowedPricingActionsDataProps } from 'pricing/types';
import { DataRoomCreateView, DataRoomsListView, DataRoomDetailView, DataSetDetailView } from './views';

const queryClient = new QueryClient();

const DataRoomNavigation = ({ allowedPricingActions }: AllowedPricingActionsDataProps) => {
  const pricingActions = allowedPricingActions?.split(' ') as PricingTriggersKey[];

  return (
    <FullPageView>
      <QueryClientProvider client={queryClient}>
        <Router basename={getUrl('/data-rooms')}>
          <Routes>
            <Route path="/">
              <Route index element={<DataRoomsListView />} />
              <Route path="create/" element={<DataRoomCreateView allowedPricingActions={pricingActions} />} />
              <Route path=":dataRoomId/">
                <Route index element={<DataRoomDetailView allowedPricingActions={pricingActions} />} />
                <Route path="data-sets/:dataSetId/" element={<DataSetDetailView />} />
              </Route>
            </Route>
            <Route path="*" element={<PageNotFoundContent />} />
          </Routes>
        </Router>
      </QueryClientProvider>
    </FullPageView>
  );
};

export default DataRoomNavigation;
