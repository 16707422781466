/**
 * File field and related components.
 */

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import filesize from 'filesize';

// @todo improve import
import { Field } from 'formik';
import { FileModel } from 'media/models';
import emptyStateIcons from 'core/svgs/emptyStateIcons';
import { icons } from 'core/icons';
import { isNativeAppWebview } from 'utils/general';
import { mediaTypes } from 'media/constants/fileTypes';
import FieldErrors from './errors';
import { BaseButton, Button } from './button';
import FieldLabel from './label';


/**
 * Preview of a single file.
 *
 */
const FilePreview = ({ altText, file }) => {
  const fileObject = FileModel.fromNativeFile(file);
  if (fileObject.isImage) {
    return <img src={fileObject.preview || fileObject.fetchUrl} alt={altText} />;
  }
  const icon = fileObject.iconName ? `fa-file-${fileObject.iconName}` : 'fa-file';
  return <i className={`far fa-fw icon ${icon}`} aria-hidden="true" />;
};

FilePreview.defaultProps = {
  altText: gettext('File Preview'),
};

FilePreview.propTypes = {
  altText: PropTypes.string,
  file: PropTypes.shape({
    iconName: PropTypes.string,
    isImage: PropTypes.bool,
    preview: PropTypes.string,
    fetchUrl: PropTypes.string,
  }).isRequired,
};

/**
 * Preview of multiple files.
 *
 * @todo consider renaming to FileList
 * @todo improve wrapper class name
 */
const FilesPreview = ({ files, onRemove }) => {
  useEffect(() => () => {
    // Revoke the data uris to avoid memory leaks
    files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const mediaTypeDisplay = (file) => {
    if (file.mediaTypeDisplayName) {
      return file.mediaTypeDisplayName;
    }
    const mediaTypeName = mediaTypes.find((option) => option.id === file.type);
    return mediaTypeName ? mediaTypeName.text : '';
  };

  return (
    !!files.length && files.map((file, index) => (
      <div key={file.path || file.fetchUrl} className="file-content-container">
        <div className="file"><FilePreview file={file} /></div>
        <div className="file-info">
          <div className="file-name">{file.name}</div>
          <div className="file-type">{mediaTypeDisplay(file)}</div>
          <BaseButton className="action" onClick={() => { onRemove(index); }}>
            {gettext('Remove')}
          </BaseButton>
        </div>
        <div className="file-size">{file.readableFileSize || filesize(file.size)}</div>
      </div>
    ))
  );
};

FilesPreview.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      mediaType: PropTypes.string,
      preview: PropTypes.string,
    }),
  ).isRequired,
  onRemove: PropTypes.func.isRequired,
};


/**
 * HTML5 Dropzone for one or multiple files
 *
 * @todo consider renaming to plural
 * @todo investigate why maxFiles setting doesn't work by itself
 */
const FileDropzone = ({ acceptedMediaTypes, maxFiles, multiple, name, setFieldValue, files }) => {
  const [dropzoneText, setDropzoneText] = useState('');

  useEffect(() => {
    if (isNativeAppWebview) {
      setDropzoneText(gettext('Upload files from your device.'));
    } else {
      setDropzoneText(gettext('Drag and drop or upload files from your device.'));
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedMediaTypes.join(),
    maxFiles,
    multiple,
    onDrop: (acceptedFiles) => {
      const newFiles = acceptedFiles.slice(0, maxFiles).map((file) => (
        Object.assign(file, { preview: URL.createObjectURL(file) })
      ));
      setFieldValue(
        name,
        [...files, ...newFiles],
      );
    },
  });

  return (
    <>
      <input {...getInputProps()} />
      {maxFiles > 1 && files.length ? (
        <BaseButton className="action" onClick={getRootProps().onClick}>
          <span className="icon">{icons.plus}</span>
          {gettext('Add file')}
        </BaseButton>
      ) : (
        <>
          <div {...getRootProps({ className: 'dropzone' })}>
            {emptyStateIcons.document}
            <p className="dropzone-text">{dropzoneText}</p>
          </div>
          <Button className="button-outlined button-small" onClick={getRootProps().onClick}>
            <span className="icon">{icons.arrowUp}</span>
            {gettext('Upload')}
          </Button>
        </>
      )}
    </>
  );
};

FileDropzone.defaultProps = {
  acceptedMediaTypes: ['application/pdf', 'image/png', 'image/jpeg'],
  maxFiles: 0,
  multiple: true,
  files: [],
};

FileDropzone.propTypes = {
  acceptedMediaTypes: PropTypes.arrayOf(PropTypes.string),
  maxFiles: PropTypes.number,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    mediaType: PropTypes.string,
    preview: PropTypes.string,
  })),
};


/**
 * File input
 *
 * @todo consider renaming to plural
 */
const FileInput = (props) => {
  const { maxFiles, name } = props;

  return (
    <Field name={name}>
      {({ field: { value }, form: { setFieldValue } }) => (
        <div className="document-upload-section">
          <header className="section-header">
            <h3 className="title">
              {gettext('Files')}
            </h3>
            {
                value.length < maxFiles
                && value.length > 0
                && <FileDropzone {...props} files={value} setFieldValue={setFieldValue} />
              }
          </header>
          <FilesPreview
            files={value}
            onRemove={(index) => setFieldValue(name, [...value.slice(0, index), ...value.slice(index + 1)])}
          />
          {
              value.length < maxFiles
              && value.length === 0
              && <FileDropzone {...props} files={value} setFieldValue={setFieldValue} />
            }
        </div>
      )}
    </Field>
  );
};

FileInput.defaultProps = {
  acceptedMediaTypes: ['application/pdf', 'image/png', 'image/jpeg'],
  maxFiles: 0,
  multiple: true,
};

FileInput.propTypes = {
  acceptedMediaTypes: PropTypes.arrayOf(PropTypes.string),
  maxFiles: PropTypes.number,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
};


/**
 * File field with label and error component.
 *
 * @todo consider renaming to plural
 */
const FileField = (props) => {
  const { name, id, label, isOptional } = props;
  return (
    <>
      {label && (<FieldLabel id={id || name} label={label} isOptional={isOptional} />)}
      <FieldErrors name={name} />
      <FileInput {...props} />
    </>
  );
};

FileField.defaultProps = {
  acceptedMediaTypes: ['application/pdf', 'image/png', 'image/jpeg'],
  id: '',
  label: '',
  isOptional: false,
  maxFiles: 0,
  multiple: true,
};

FileField.propTypes = {
  acceptedMediaTypes: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string,
  label: PropTypes.string,
  isOptional: PropTypes.bool,
  maxFiles: PropTypes.number,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
};


export { FileDropzone, FileField, FileInput, FilePreview };
