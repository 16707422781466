import PropTypes from 'prop-types';

import { Button } from './button';

/**
 * Submit button.
 *
 * TODO add other props as necessary (name, value, etc.)
 */
const SubmitButton = ({ children, className, disabled, onClick, ...props }) => (
  <Button type="submit" disabled={disabled} className={className} onClick={onClick} {...props}>{children}</Button>
);

SubmitButton.defaultProps = {
  className: 'primary',
  disabled: false,
  children: gettext('Submit'),
  onClick: () => {},
};

SubmitButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default SubmitButton;
