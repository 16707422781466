/**
 * Complete beneficial owners report form.
 *
 * TODO improve validation, add cross-field validation
 * TODO improve api errors handling
 *
 * TODO consider separating some of the logic to hooks or container
 * TODO consider un-flattening checkboxes and collections
 * TODO consider one nested form for all sections, consider modal, consider react portals
 */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, ErrorMessage } from 'formik';
import { invert, mapKeys } from 'lodash';

import { CheckboxField, RadioButtonGroup, SubmitButton } from 'core/forms/fields';
import axios from 'core/axios';
import { handleTransactionCreation } from 'core/page/transactionSigning/utils';
import FormErrors from '../../components/common/formikErrors';
import BeneficialOwnersFormSection from './formSection';
import { sections, validationSchema, fieldMapping } from '../constants/boReporting';
import { transferTypes } from '../constants/generic';


const BeneficialOwnersReportForm = ({
  businessTransactionId,
  companyId,
  portfolioId,
  tokenId,
  businessTransactionType,
}) => {

  const btrxType = transferTypes[businessTransactionType];
  const createReportUrls = {
    transfer: `/api/v1/${companyId ? `company/${companyId}/` : ''}transfers/${businessTransactionId}/${btrxType}/`,
    distribution: `/api/v1/${companyId ? `company/${companyId}/` : ''}transfers/${businessTransactionId}/accept-distribution/`,
  };

  const toServerData = (values) => ({
    beneficial_owner: {
      ...mapKeys(values, (_value, key) => fieldMapping[key]),
      // TODO consider alternative radio buttons component with support for boolean to avoid this
      will_have_significant_control: values.willHaveSignificantControl === 'yes',
    },
    portfolio_id: portfolioId,
    token_id: tokenId,
  });
  const fromServerData = (data) => ({
    ...mapKeys(data.beneficial_owner, (_value, key) => invert(fieldMapping)[key]),
    // TODO consider alternative radio buttons component with support for boolean to avoid this
    willHaveSignificantControl: data.beneficial_owner.will_have_significant_control ? 'yes' : 'no',
  });
  const updateServerData = (data, onFulfilled, onRejected) => {
    const url = `/api/v1/${companyId ? `company/${companyId}/` : ''}transfers/${businessTransactionId}/update_bo_report/`;
    axios.patch(url, data).then(onFulfilled, onRejected).catch(console.error);
  };
  const postToServer = (data, onFulfilled, onRejected) => {
    const url = businessTransactionType in transferTypes ? createReportUrls.transfer : createReportUrls.distribution;
    axios.post(url, data).then(onFulfilled, onRejected).catch(console.error);
  };

  /**
  * Fetch and set initial values when component is loaded.
  */
  const [initialValues, setInitialValues] = useState({
    willHaveSignificantControl: 'no',
    actingOnItsOwn: false,
    actingInConcert: false,
    actingAsTrustee: false,
    actingOnItsOwnCollection: companyId ?
      [{ name: '', address: '', additional_address: '', postal_code: '', city: '', country_code: '' }]
      : [],
    actingInConcertCollection: [{ name: '', address: '', additional_address: '', postal_code: '', city: '', country_code: '' }],
    actingAsTrusteeCollection: [{ name: '', address: '', additional_address: '', postal_code: '', city: '', country_code: '' }],
  });
  useEffect(() => {
    const url = `/api/v1/${companyId ? `company/${companyId}/` : ''}transfers/${businessTransactionId}/`;
    if (!businessTransactionType) {
      axios.get(url)
        .then(({ data }) => { setInitialValues(fromServerData(data)); })
        .catch(console.error);
    }
  }, [companyId, businessTransactionId, businessTransactionType]);

  /**
   * Sanitize values.
   */
  const sanitizeValues = (values) => {
    const updatedValues = { ...values };
    sections(companyId).forEach((section) => {
      // clean up all beneficial owners types checkboxes if user doesn't declare significant control
      if (updatedValues.willHaveSignificantControl !== 'yes') updatedValues[section.fieldName] = false;
      // clean up collections for any beneficial owners types that were unchecked
      if (!updatedValues[section.fieldName]) updatedValues[`${section.fieldName}Collection`] = [];
    });
    return updatedValues;
  };

  const onSuccessResponse = (txHash, redirectUrl, actions) => {
    handleTransactionCreation(txHash, redirectUrl);
    actions.setSubmitting(false);
  };
  /**
   * Sanitize and update server data on form submit.
   */
  const onSubmit = (values, actions) => {
    actions.setSubmitting(true);
    const updatedValues = sanitizeValues(values);
    actions.setValues(updatedValues);
    const postData = {
      ...mapKeys(updatedValues, (_updatedValue, key) => fieldMapping[key]),
      will_have_significant_control: updatedValues.willHaveSignificantControl === 'yes',
      legal_confirmation: updatedValues.legalConfirmation,
    };
    delete updatedValues.legalConfirmation; // TODO is this necessary?

    if (businessTransactionType) {
      postToServer(
        postData,
        ({ data: { redirect_url: redirectUrl, tx_hash: txHash } }) => { onSuccessResponse(txHash, redirectUrl, actions); },
        ({ response: { data: errors, status } }) => {
          if (status < 500) {
            const mappedErrors = { ...mapKeys(errors, (_value, key) => invert(fieldMapping)[key]) };
            Object.keys(mappedErrors).forEach((key) => {
              actions.setFieldError(key, mappedErrors[key].join(' '));
            });
            actions.setSubmitting(false);
          }
        },
      );
    } else {
      updateServerData(
        toServerData(updatedValues),
        ({ data: { redirect_url: redirectUrl, tx_hash: txHash } }) => { onSuccessResponse(txHash, redirectUrl, actions); },
        ({ response: { status } }) => {
          if (status < 500) {
            actions.setSubmitting(false);
          }
        },
      );
    }
  };

  /**
   * Handle which section should display a form to add a new beneficial owner.
   */
  const [focusedSection, setFocusedSection] = useState('');
  const onAddItemClick = (_e, fieldName) => { setFocusedSection(fieldName); };

  return (
    <Formik
      enableReinitialize
      initialValues={{ ...initialValues, legalConfirmation: false, nonFieldErrors: '' }}
      validationSchema={validationSchema(companyId)}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, setFieldValue, values }) => {
        /**
        * Add new beneficial owner to a collection if it's not already there and hide nested form.
        */
        const addBeneficialOwner = (collectionName, beneficialOwner) => {
          if (!values[collectionName].includes(beneficialOwner)) {
            setFieldValue(collectionName, [...values[collectionName], beneficialOwner]);
          }
          setFocusedSection('');
        };
        /**
        * Remove beneficial owner from a collection.
        */
        const removeBeneficialOwner = (collectionName, index) => {
          const collectionValues = [...values[collectionName]];
          collectionValues.splice(index, 1);
          setFieldValue(collectionName, collectionValues);
        };
        return (
          <Form>
            <ErrorMessage component={FormErrors} name="nonFieldErrors" />
            <div className="text-size-label">

              <h3>{gettext('Notification according to article 697j of the Swiss Code of Obligations')}</h3>
              <hr />

              <h3>{gettext('Beneficial Ownership Reporting')}</h3>
              <RadioButtonGroup
                className="justify-content-space-evenly align-flex-items-center"
                name="willHaveSignificantControl"
                label={businessTransactionType ?
                  gettext(`Following and including this acquisition the Buyer will hold alone or together with third
                    parties (acting in concert) 25% or more of the share capital or the voting rights of the Company.`)
                  : gettext(`The Buyer holds alone or together with third parties (acting in concert) 25% or more of the
                    share capital or the voting rights of the Company.`)}
                options={[
                  { label: gettext('No'), value: 'no' },
                  { label: gettext('Yes'), value: 'yes' },
                ]}
              />
              <hr />

              {values.willHaveSignificantControl === 'yes' && (
                <>
                  <h3>{gettext('Beneficial Owners')}</h3>
                  <div className="font-italic margin-y">{gettext('Please select all applicable options:')}</div>
                  {sections(companyId).map((section) => (
                    <BeneficialOwnersFormSection
                      key={section.fieldName}
                      fieldName={section.fieldName}
                      collectionName={`${section.fieldName}Collection`}
                      label={section.label}
                      values={values}
                      // TODO consider showing the form by default when collection is empty
                      formOpen={section.fieldName === focusedSection}
                      formEnabled={section.formEnabled}
                      onAddItemClick={onAddItemClick}
                      addBeneficialOwner={addBeneficialOwner}
                      removeBeneficialOwner={removeBeneficialOwner}
                    />
                  ))}
                  <hr />
                </>
              )}

              <h3>{gettext('Confirmation')}</h3>
              <CheckboxField
                name="legalConfirmation"
                label={gettext(
                  'I have taken note of the registration agreement and any other documents provided by the Issuer'
                  + ' regarding the opportunities and risk of the register value rights and'
                  + ' I confirm to be bound by the registration agreement.',
                )}
              />
              <SubmitButton disabled={isSubmitting}>
                { businessTransactionType ? gettext('Submit') : gettext('Update')}
              </SubmitButton>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

BeneficialOwnersReportForm.defaultProps = {
  companyId: null,
  portfolioId: null,
  tokenId: null,
  businessTransactionType: null,
};

BeneficialOwnersReportForm.propTypes = {
  businessTransactionId: PropTypes.string.isRequired,
  companyId: PropTypes.string,
  portfolioId: PropTypes.string,
  tokenId: PropTypes.string,
  businessTransactionType: PropTypes.string,
};

export default BeneficialOwnersReportForm;
