import axios from 'core/axios';
import { getUrl, getApiUrl } from 'utils/urls';
import { signTransaction } from 'utils/signTransaction';
import { handleTransactionCreation } from 'core/page/transactionSigning/utils';
import { getEventModalData, inviteesPageSize } from '../parts/eventModalsData';
import reducer from '../../reducers';

export const onInvitationSend = (eventBasePath, navigatePath, setDisabled, setPageMessages) => {
  signTransaction({
    urlPath: `${eventBasePath}send-invitation/`,
    navigatePath,
    setDisabled,
    setPageMessages,
  });
};

export const onIndicateParticipation = (eventBasePath, dispatch, setDisabled) => {
  axios.post(getApiUrl(`${eventBasePath}indicate-participation/`))
    .then(({ data }) => dispatch({ type: 'indicateParticipation', data }))
    .catch((error) => {
      console.error(error);
      setDisabled(false);
    });
};

export const onCheckIn = (eventBasePath, dispatch, setDisabled) => {
  axios.post(getApiUrl(`${eventBasePath}check-in/`))
    .then(({ data }) => dispatch({ type: 'checkIn', data }))
    .catch((error) => {
      console.error(error);
      setDisabled(false);
    });
};

export const onMeetingStart = (eventBasePath, navigatePath, setDisabled, setPageMessages) => {
  signTransaction({
    urlPath: `${eventBasePath}open/`,
    navigatePath,
    setDisabled,
    setPageMessages,
  });
};

export const onMeetingClose = (eventBasePath, navigatePath, setDisabled, setPageMessages) => {
  signTransaction({
    urlPath: `${eventBasePath}close/`,
    navigatePath,
    setDisabled,
    setPageMessages,
  });
};

export const onMeetingDelete = (eventBasePath, navigatePath, setDisabled) => {
  axios.delete(getApiUrl(eventBasePath))
    .then(() => { window.location = getUrl(navigatePath); })
    .catch((error) => {
      console.error(error);
      setDisabled(false);
    });
};

export const onMeetingCancel = (eventBasePath, navigatePath, setDisabled) => {
  signTransaction({
    urlPath: `${eventBasePath}cancel/`,
    navigatePath,
    setDisabled,
  });
};

export const onIntroTextSeen = (eventBasePath, dispatch, setDisabled) => {
  axios.post(getApiUrl(`${eventBasePath}intro-text-seen/`))
    .then(({ data }) => dispatch({ type: 'introTextSeen', data }))
    .catch((error) => {
      console.error(error);
      setDisabled(false);
    });
};

export const onProxyVotesRelease = (eventBasePath, navigatePath, setDisabled) => {
  signTransaction({
    urlPath: `${eventBasePath}manage-proxy-votes/`,
    navigatePath,
    setDisabled,
  });
};

export const onProxyVotesSummaryModalOpen = (eventBasePath) => (
  axios.get(getApiUrl(`${eventBasePath}manage-proxy-votes/`))
    .then(({ data }) => data)
    .catch(console.error)
);

export const onProxyVotingDisable = (eventBasePath, navigatePath, setDisabled) => {
  axios.delete(getApiUrl(`${eventBasePath}proxy-voting/`))
    .then(({ data: { tx_hash: txHash } }) => {
      handleTransactionCreation(txHash, getUrl(navigatePath));
    })
    .catch(console.error)
    .finally(() => { setDisabled(false); });
};

export const onProxyInstructionsSubmit = (basePath, navigatePath, setDisabled, settings, votes) => {
  const { proxyBoardProposal, proxyShareholderProposal } = settings;
  const defaultSettings = {
    board_proposal: proxyBoardProposal,
    shareholder_proposal: proxyShareholderProposal,
  };
  signTransaction({
    urlPath: `${basePath}proxy-voting/`,
    navigatePath,
    setDisabled,
    data: { votes, default_settings: defaultSettings },
  });
};

export const onHeaderItemClick = (modalId, eventBasePath, onContactsFetch, meeting, dispatch) => {
  const modalData = getEventModalData(modalId, meeting, dispatch);
  switch (modalId) {
    case 'shareClasses':
      return axios.get(getApiUrl(`${eventBasePath}share-classes/`))
        .then(({ data }) => {
          dispatch({ type: 'fetchShareClasses', data });
          // @todo revisit this workaround, which was needed because the updated state meeting becomes available
          // after the final data for share classes is returned
          const newState = reducer({ meeting }, { type: 'fetchShareClasses', data });
          return { ...modalData, shareClasses: newState.meeting.shareClasses };
        })
        .catch(console.error);
    case 'invitees':
      return axios.get(getApiUrl(`${eventBasePath}invitees/`, inviteesPageSize))
        .then(({ data: { results } }) => {
          dispatch({ type: 'fetchInvitees', data: results });
          // @todo revisit this workaround, which was needed because the updated state meeting becomes available
          // after the final data for invitees is returned
          const newState = reducer({ meeting }, { type: 'fetchInvitees', data: results });
          const { invitees } = newState.meeting;
          let totalInvitationReceived = 0;
          let totalCheckedIn = 0;
          let totalVotedByProxy = 0;
          const noOfShares = { total: 0, represented: 0, byProxy: 0 };
          invitees.forEach((invitee) => {
            noOfShares.total += invitee.noOfShares;
            if (invitee.acceptedInvitation) {
              totalInvitationReceived += 1;
            }
            if (invitee.checkedIn) {
              totalCheckedIn += 1;
              noOfShares.represented += invitee.noOfShares;
            }
            if (invitee.votedByProxy) {
              totalVotedByProxy += 1;
              noOfShares.represented += invitee.noOfShares;
              noOfShares.byProxy += invitee.noOfShares;
            }
          });
          return {
            ...modalData,
            invitees,
            totalInvitationReceived,
            totalCheckedIn,
            totalVotedByProxy,
            noOfShares,
          };
        })
        .catch(console.error);
    case 'proxyVoting':
    case 'assignedRoles':
      return {
        ...modalData,
        onContactsFetch,
        onContactSearch: ({ searchString }) => onContactsFetch(searchString),
      };
    default:
      return modalData;
  }
};

export const onMinutesSigningRequest = (eventBasePath, navigatePath, setDisabled, setPageMessages) => {
  signTransaction({
    urlPath: `${eventBasePath}request-minutes-signing/`,
    navigatePath,
    setDisabled,
    setPageMessages,
  });
};

export const onMinutesSigningCancel = (eventBasePath, setDisabled, dispatch) => {
  axios.post(getApiUrl(`${eventBasePath}cancel-minutes-signing/`))
    .then(({ data }) => dispatch({ type: 'updateMeeting', data }))
    .catch((error) => {
      console.error(error);
      setDisabled(false);
    });
};

export const onMinutesSign = (eventBasePath, navigatePath, setDisabled, setPageMessages) => {
  signTransaction({
    urlPath: `${eventBasePath}sign-minutes/`,
    navigatePath,
    setDisabled,
    setPageMessages,
  });
};

export const onMinutesSend = (eventBasePath, navigatePath, setDisabled, setPageMessages) => {
  signTransaction({
    urlPath: `${eventBasePath}send-minutes/`,
    navigatePath,
    setDisabled,
    setPageMessages,
  });
};

export const onResultsSend = (eventBasePath, navigatePath, setDisabled, setPageMessages) => {
  signTransaction({
    urlPath: `${eventBasePath}send-results/`,
    navigatePath,
    setDisabled,
    setPageMessages,
  });
};
