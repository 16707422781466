export const REGISTERED_TOKEN_TYPE = 'RS';
export const RVR_NON_EQUITY = 'NEQ';
export const RVR_EQUITY = 'EQ';
export const EMPLOYEE_STOCK_OPTION = 'EO';

export const tokenTypes = [
  { id: RVR_EQUITY, text: gettext('Register value right (Equity)') },
  { id: RVR_NON_EQUITY, text: gettext('Register value right (Non-equity)') },
  { id: EMPLOYEE_STOCK_OPTION, text: gettext('Employee Stock Options'), disabled: true },
];

export const getTokenType = (representsEquity = true, contractType = 'VRRS') => {
  if (contractType === 'VRRS' && !representsEquity) {
    return tokenTypes.find((type) => type.id === RVR_NON_EQUITY);
  }
  return tokenTypes.find((type) => type.id === RVR_EQUITY);
};

export const blockedAmountOwnerOptions = [
  { id: 'none', text: gettext('None') },
  { id: 'shag', text: gettext("Shareholder's agreement"), disabled: true },
];
