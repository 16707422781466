import PropTypes from 'prop-types';


const ContentLoadingMessage = ({ isLoading, loadingMessage, loadedMessage }) => {
  if (isLoading) {
    return <p className="text-center">{loadingMessage}</p>;
  }
  return <p className="text-center">{loadedMessage}</p>;
};

ContentLoadingMessage.defaultProps = {
  loadingMessage: gettext('Loading...'),
  loadedMessage: gettext('Nothing found'),
};

ContentLoadingMessage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  loadingMessage: PropTypes.string,
  loadedMessage: PropTypes.string,
};

export default ContentLoadingMessage;
