/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prop-types */
import { Component } from 'react';
import { observer } from 'mobx-react';
import { mediaTypes } from '../../media/constants/fileTypes';
import { ImagePreview } from '../documentUpload/document';

class Document extends Component {
  removeButton = () => {
    const { model, remove } = this.props;

    if (!remove) {
      return null;
    }

    return (
      <button
        type="button"
        onClick={() => remove(model)}
        className="action inline-block primary-color"
      >
        {gettext('Remove')}
      </button>
    );
  };

  render() {
    const { model } = this.props;
    let fileTypeDisplay = '';

    if (mediaTypes.length > 0 && model.file.type.length > 0) {
      const mediaType = mediaTypes.find((option) => option.id === model.file.type);
      if (mediaType) {
        fileTypeDisplay = mediaType.text;
      }
    }

    return (
      <div className="grid-x padded-y">
        <ImagePreview src={model.source} />
        <div className="cell small-6 text-smaller">
          <div className="no-overflow">{model.name}</div>
          <div className="no-overflow">{fileTypeDisplay}</div>
          <span>
            {model.isRemovable ? this.removeButton() : null}
          </span>
        </div>
      </div>
    );
  }
}

export default observer(Document);
