/**
 * Component that handles signing transaction with custodial wallet and checking of transaction status.
 *
 * @todo remove in favor of automatic dev-only signing
 * @todo consider rewriting checking of transaction status as HOC
 */

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'core/forms/fields';
import axios from 'core/axios';
import { getGlobalContext } from 'core/globals';
import SubmitButton from 'core/forms/fields/submit';
import { isNativeAppWebview } from 'utils/general';
import SignerPhone from 'transactions/signer-phone';


const SignTransactionComponent = ({ redirectUrl, unsignedHash, signerUrl }) => {
  const { actingPerson: { walletType } } = getGlobalContext();
  const isCustodialWallet = walletType === 'custodial';
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState(null);
  window.trustwise.unsignedTransaction.signingStatusOld = setTransactionStatus;

  /**
   * @todo docstring
   */
  const submitTransaction = () => {
    setTransactionStatus('unknown');
    axios.post(`/api/v1/ethereum/transactions/${unsignedHash}/custodial/sign/`)
      .catch((error) => {
        console.error(error);
        setIsSubmitting(false);
      });
  };


  /**
   * Submit transaction when button is pressed.
   */
  const handleSubmit = (_e) => { setIsSubmitting(true); submitTransaction(); };

  const handleCancel = (_e) => {
    setIsSubmitting(true);
    axios.delete(`/api/v1/ethereum/transactions/${unsignedHash}/`)
      .then(() => { window.location.replace(document.referrer); })
      .catch((error) => {
        console.error(error);
        setIsSubmitting(false);
      });
  };

  /**
   * Redirect user when transaction status changes to 'processed' or initialize another check.
   */
  useEffect(
    () => {
      switch (transactionStatus) {
        case 'processed':
        case 'skipped':
          window.location = redirectUrl;
          break;
        case 'cancelled':
          window.location.replace(document.referrer);
          break;
        default:
          break;
      }
    },
    [transactionStatus, redirectUrl],
  );
  const signLabel = gettext('Confirm');
  const selfManagedSignAction = signerUrl && isNativeAppWebview ? (
    <a className="button" data-unsigned-hash={unsignedHash} href={signerUrl}>
      {signLabel}
    </a>
  ) : null;

  /**
   * Display button to sign transaction.
   */
  return (
    <div className="text-smaller">
      {!isNativeAppWebview && !isCustodialWallet && (
        <div className="centered align-flex-items-center">
          <SignerPhone />
          <div className="left-margin">
            {gettext('Keep this dialog open and use your mobile application to confirm the transaction')}
          </div>
        </div>

      )}
      {!transactionStatus && (
        <>
          {isCustodialWallet ? (
            <SubmitButton onClick={handleSubmit} disabled={isSubmitting}>{signLabel}</SubmitButton>
          ) : selfManagedSignAction}
          <Button className="button-outlined" onClick={handleCancel} disabled={isSubmitting}>
            {gettext('Cancel')}
          </Button>
        </>
      )}
      {transactionStatus && transactionStatus !== 'processed' && <div className="loader" />}
      <div className="align-text-center">
        {
          transactionStatus && {
            unknown: gettext('Your transaction is being sent...'),
            accepted: gettext('Your transaction is being processed, which will take on average four seconds.'),
            mined: gettext('Your transaction is being processed, which will take on average four seconds.'),
            processed: gettext('Transaction completed. You will be redirected automatically.'),
          }[transactionStatus]
        }
        {['accepted', 'mined'].includes(transactionStatus) && (
          <>
            {gettext('You can skip waiting and will be notified when the transaction is processed')}
            <Button className="top-margin button-outlined" onClick={(_e) => { window.location = redirectUrl; }}>
              {gettext('Skip')}
            </Button>
          </>
        )}
      </div>

    </div>
  );
};


SignTransactionComponent.propTypes = {
  redirectUrl: PropTypes.string.isRequired,
  unsignedHash: PropTypes.string.isRequired,
  signerUrl: PropTypes.string.isRequired,
};


export default SignTransactionComponent;
