import { convertKeysToCamelCase, convertNestedKeysToCamelCase } from 'core/utils';

export class Model {
  constructor(props) {
    Object.entries(props).forEach(([fieldName, fieldValue]) => {
      this[fieldName] = fieldValue;
    });
  }

  static fromResponse(data) {
    if (data === undefined) {
      return null;
    }
    if (Array.isArray(data)) {
      return data.map((item) => this.fromResponse(item));
    }
    const convertedCase = convertNestedKeysToCamelCase(data);
    const filteredEntries = Object.entries(convertedCase).filter(([_, v]) => v !== null);
    return new this(Object.fromEntries(filteredEntries));
  }

  toObject() {
    const plainObject = { ...this };
    let prototype = Object.getPrototypeOf(this);

    while (prototype && prototype !== Object.prototype) {
      const prototypeDescriptors = Object.getOwnPropertyDescriptors(prototype);
      Object.keys(prototypeDescriptors).forEach((key) => {
        const descriptor = prototypeDescriptors[key];
        if (descriptor.get) {
          plainObject[key] = descriptor.get.call(this);
        }
      });

      prototype = Object.getPrototypeOf(prototype);
    }

    return plainObject;
  }

  updatePlainValues(data) {
    const convertedData = convertKeysToCamelCase(data);
    Object.entries(convertedData).forEach(([key, value]) => { this[key] = value; });
  }
}
