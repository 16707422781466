import { camelCase } from 'lodash';

import { convertKeysCase } from 'core/utils';
import TransactionModel from 'core/page/transactionSigning/model';
import { getGlobalContext } from 'core/globals';

const getTxStatus = (status) => {
  switch (status) {
    case 'accepted':
    case 'mined':
      return 'processing';
    case 'processed':
      return 'completed';
    default:
      return undefined;
  }
};

const reducer = (currentState, action) => {
  const { data, type } = action;
  const { unsignedTransaction: { enabled: unsignedTrxEnabled } } = getGlobalContext();
  const showSigningModal = {
    isPendingOpen: false,
    isSigningOpen: !!unsignedTrxEnabled,
  };

  switch (type) {
    case 'fetch_unsigned': {
      const { modalState: currentModalState } = currentState;
      return {
        ...currentState,
        transactionDetails: new TransactionModel(convertKeysCase(data, camelCase)),
        modalState: {
          ...currentModalState,
          isPendingOpen: !!unsignedTrxEnabled,
        },
      };
    }
    case 'fetch':
      return {
        ...currentState,
        transactionDetails: new TransactionModel(convertKeysCase(data, camelCase)),
      };
    case 'created': {
      const convertedData = convertKeysCase(data, camelCase);
      const { unsignedHash: newHash, redirectUrl, ...restData } = convertedData;
      const { baseTxInfo: { unsignedHash: stateHash } } = currentState;
      const state = newHash === stateHash ? currentState : {};
      return {
        ...state,
        redirect: {
          ...state.redirect,
          url: redirectUrl,
        },
        baseTxInfo: { unsignedHash: newHash, ...restData },
        modalState: showSigningModal,
      };
    }
    case 'status_updated':
      return {
        ...currentState,
        txStatus: getTxStatus(data),
      };
    case 'fetch_signing_url':
      return {
        ...currentState,
        nativeSigningUrl: data,
      };
    case 'reset': {
      const { redirect: { url }, modalState } = currentState;
      return {
        modalState: url ? modalState : {
          isPendingOpen: false,
          isSigningOpen: false,
        },
        redirect: {
          url,
          shouldRedirect: true,
        },
        baseTxInfo: {},
      };
    }
    case 'removed':
      return {
        baseTxInfo: {},
        redirect: {},
        modalState: {},
      };
    case 'switch_modals':
      return {
        ...currentState,
        modalState: showSigningModal,
      };
    default:
      return currentState;
  }
};

export default reducer;
