import * as Yup from 'yup';

import { DocumentModel } from 'media/models';
import { dateToday } from 'utils/date';
import { currencyOptions } from 'core/options';

const currencyOptionsKeys = currencyOptions.map(({ code }) => code);

const valuationAddValidation = (allowedValuationTypes) => Yup.object({
  timeSeriesDate: Yup.date().max(dateToday(), gettext("Date can't be in the future")).required('This field is required'),
  timeSeriesTime: Yup.string().test('empty', gettext("Time can't be empty"), (startTime) => !!startTime),
  valuationType: Yup.string().oneOf(allowedValuationTypes),
  value: Yup.number().positive('Price should be above 0').required('This field is required'),
  currency: Yup.string().oneOf(currencyOptionsKeys).required('This field is required'),
  comment: Yup.string(),
}).concat(DocumentModel.simpleUploadValidation);

export default valuationAddValidation;
