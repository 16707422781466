import PropTypes from 'prop-types';
import { icons } from 'core/icons';
import { BaseButton } from 'core/forms/fields';
import PageSizeSelect from './pageSizeSelect';


const PaginationController = ({
  currentPageNumber,
  pageCount,
  fetchItems,
  itemsRange,
  totalItems,
  setPageSize,
  defaultPageSize,
  perPageOptions,
}) => {
  const { rangeStart, rangeEnd } = itemsRange;
  const isForwardDisabled = currentPageNumber === pageCount;
  const isBackwardDisabled = currentPageNumber === 1;
  return (
    <div className="pagination-controller">
      <PageSizeSelect
        setPageSize={setPageSize}
        defaultPageSize={defaultPageSize}
        perPageOptions={perPageOptions}
      />
      <div className="pagination-items-counter">
        {`${rangeStart}${rangeStart !== rangeEnd ? `-${rangeEnd}` : ''} of ${totalItems}`}
      </div>
      <BaseButton disabled={isBackwardDisabled} onClick={() => { fetchItems(1); }}>
        {isBackwardDisabled ? icons.firstPageDisabled : icons.firstPage}
      </BaseButton>
      <BaseButton disabled={isBackwardDisabled} onClick={() => { fetchItems(currentPageNumber - 1); }}>
        {isBackwardDisabled ? icons.chevronLeftDisabled : icons.chevronLeft}
      </BaseButton>
      <BaseButton disabled={isForwardDisabled} onClick={() => { fetchItems(currentPageNumber + 1); }}>
        {isForwardDisabled ? icons.chevronRightDisabled : icons.chevronRight}
      </BaseButton>
      <BaseButton disabled={isForwardDisabled} onClick={() => { fetchItems(pageCount); }}>
        {isForwardDisabled ? icons.lastPageDisabled : icons.lastPage}
      </BaseButton>
    </div>
  );
};

PaginationController.defaultProps = {
  perPageOptions: [
    { value: 10, label: gettext('10 per page') },
    { value: 20, label: gettext('20 per page') },
    { value: 50, label: gettext('50 per page') },
    { value: 100, label: gettext('100 per page') },
  ],
  defaultPageSize: 10,
};

PaginationController.propTypes = {
  currentPageNumber: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  fetchItems: PropTypes.func.isRequired,
  itemsRange: PropTypes.shape({
    rangeStart: PropTypes.number.isRequired,
    rangeEnd: PropTypes.number.isRequired,
  }).isRequired,
  totalItems: PropTypes.number.isRequired,
  perPageOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  })),
  setPageSize: PropTypes.func.isRequired,
  defaultPageSize: PropTypes.number,
};

export default PaginationController;
