import { CustomSchemaFieldTypes } from '@trustwise/design-system';

export const DEFAULT_TIME_ZONE = 'UTC';

export const getTimeZonesOptions = () => {
  // @todo discuss how to reasonably extend the list here and in python
  const filteredOptions = Intl.supportedValuesOf('timeZone').filter(
    (timeZone) => ['Nicosia', 'London', 'Zurich', 'Berlin', 'Prague', 'Kiev'].some(
      (item) => timeZone.includes(item),
    ),
  );
  return [
    { value: DEFAULT_TIME_ZONE, label: gettext(`Default (${DEFAULT_TIME_ZONE})`) },
    ...filteredOptions.map((option) => ({ value: option, label: option })),
  ];
};

export const ASC_SORTING_ORDER = 'asc';
export const DESC_SORTING_ORDER = 'desc';

export const ReactQueryMutationStatuses = {
  IDLE: 'idle',
  PENDING: 'pending',
  SUCCESS: 'success',
  ERROR: 'error',
};

export const CustomSchemaFieldTypeChoices = {
  [CustomSchemaFieldTypes.STRING]: 0,
  [CustomSchemaFieldTypes.BOOLEAN]: 1,
  [CustomSchemaFieldTypes.NUMBER]: 2,
  [CustomSchemaFieldTypes.DECIMAL]: 3,
  [CustomSchemaFieldTypes.DATE_TIME]: 4,
  // add missing unsupported types once they are supported
  [CustomSchemaFieldTypes.FILES]: 8,
};
