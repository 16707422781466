import { useLocation, useParams } from 'react-router-dom';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import {
  CertificateTypeDetailPage,
  CertificateTypeDetailPages,
  CertificateTypeDetailView as CertificateTypeDetailViewDS,
} from '@trustwise/design-system';
import { PageContent } from 'core/page';
import { getButtonInteraction, getRouterLinkInteraction } from 'core/utils';
import { useRefetchOnTxProcessed } from 'core/hooks';
import BackButton from 'core/page/parts/backButton';
import {
  addRegistryToCertificateType,
  getCertificateDetails,
  getCertificateRegistries,
  getRegistries,
  onFileDownload,
  onInvalidate,
  onIssuingToggle,
  removeRegistryFromCertificateType,
} from '../actions';

export const CertificateTypeDetailView = () => {
  const { state: routerState } = useLocation();
  const [activeTab, setActiveTab] = useState<CertificateTypeDetailPage>(
    routerState?.activeTab || CertificateTypeDetailPages.DETAILS,
  );

  const { certificateTypeId } = useParams();

  if (!certificateTypeId) throw new Error('certificateTypeId is required');

  const setRefetchQueryKeys = useRefetchOnTxProcessed();

  const { data: details } = useQuery({
    queryKey: ['certificate-type-details', certificateTypeId],
    queryFn: () => getCertificateDetails(certificateTypeId),
    enabled: activeTab === CertificateTypeDetailPages.DETAILS,
  });

  const { data: registries } = useQuery({
    queryKey: ['certificate-type-registries', certificateTypeId],
    queryFn: getCertificateRegistries,
    enabled: activeTab === CertificateTypeDetailPages.ISSUER_REGISTRIES,
  });

  const onRegistryDelete = (ev: React.MouseEvent<Element, MouseEvent>, issuerRegistryId: number) => {
    ev.preventDefault();
    return removeRegistryFromCertificateType(certificateTypeId, issuerRegistryId)
      .then(() => setRefetchQueryKeys(['certificate-type-registries', certificateTypeId]));
  };

  return (
    <PageContent
      fullscreen
      pageHeading={details?.name ?? ''}
      headerLeft={<BackButton href="management/types/" useRouterLink />}
    >
      <CertificateTypeDetailViewDS
        details={details}
        registries={registries}
        onInvalidate={() => onInvalidate(certificateTypeId)}
        onIssuingToggle={() => {
          if (!details) throw Error('Details do not exist');
          onIssuingToggle(details.status, certificateTypeId);
        }}
        activePage={activeTab}
        onTabSwitch={setActiveTab}
        onFileDownload={() => {
          const { file } = details || {};
          if (!file) throw Error('File does not exist');
          onFileDownload(certificateTypeId, file.nativeUrl);
        }}
        onRegistriesFetch={() => getRegistries()}
        onRegistryAdd={(values, actions) => (
          addRegistryToCertificateType(values, actions, certificateTypeId)
            .then(() => setRefetchQueryKeys(['certificate-type-registries', certificateTypeId]))
        )}
        onRegistryClick={(id) => getRouterLinkInteraction(`issuer-registries/${id}/`, { activeTab })}
        onRegistryDelete={(issuerRegistryId) => getButtonInteraction((ev) => onRegistryDelete(ev, issuerRegistryId))}
      />
    </PageContent>
  );
};
