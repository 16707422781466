import { SimpleLegalEntityModel } from 'contacts/models/legalEntity';
import { SimpleDocumentModel } from 'media/models';
import { getGlobalContext } from 'core/globals';

const {
  activeEntity: { id: activeEntityId },
} = getGlobalContext();

/**
 * Utility Token Model
 *
 * Props under restProps are: id, name, unit, address, status
 */
export class UtilityTokenIssuerModel {
  constructor({
    utilityTokenType, totalSupply, ...restProps
  }) {
    this.type = utilityTokenType;
    this.totalAmount = Number(totalSupply);
    Object.entries(restProps).forEach(([fieldName, fieldValue]) => {
      this[fieldName] = fieldValue;
    });
  }
}

/**
 * Utility Token Transactions Model
 */
export class UtilityTokenTransactionModel {
  constructor({
    eventType, dateTime, sender, recipient, amount, documents,
  }) {
    this.id = Number(
      `${new Date(dateTime).getTime()}${sender ? sender.id : 0}${recipient ? recipient.id : 0}`,
    );
    this.date = new Date(dateTime);
    this._amount = Number(amount);
    this.entity = new SimpleLegalEntityModel(sender || recipient);
    this.documents = SimpleDocumentModel.fromArrayResponse(documents);
    this.eventType = eventType;
    this.recipient = recipient;
  }

  get amount() {
    if (this.isRecipient || this.eventType === 'mint') {
      return this._amount;
    }
    return this._amount * -1;
  }

  get isRecipient() {
    return !!this.recipient && this.recipient.id === activeEntityId;
  }
}
