/* A simple row. */

import PropTypes from 'prop-types';

const Row = ({ classNames, item, rowLink, title, ...props }) => {
  const { date, additionalData, right } = props;

  const styles = `row-flex card-base ${classNames}`;
  const link = rowLink(item) || item.url;
  const rowBody = (
    <>
      <span className="no-overflow">
        {date}
        <span className="title">
          {title(item) || item.name}
        </span>
        {additionalData}
      </span>
      {right}
    </>
  );

  return (
    link ? (
      <a className={styles} href={link}>
        {rowBody}
      </a>
    ) : (
      <span className={styles}>
        {rowBody}
      </span>
    )
  );
};

Row.defaultProps = {
  classNames: '',
  additionalData: null,
  date: null,
  right: null,
  title: () => {},
  rowLink: () => {},
};

Row.propTypes = {
  item: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  classNames: PropTypes.string,
  title: PropTypes.func,
  rowLink: PropTypes.func,
  additionalData: PropTypes.element,
  date: PropTypes.element,
  right: PropTypes.element,
};

export default Row;
