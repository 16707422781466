import PropTypes from 'prop-types';

const FormErrors = (props) => {
  const { children } = props;

  if (!children.length) return null;
  return (
    <div className="error">{children}</div>
  );
};

FormErrors.defaultProps = {
  children: '',
};

FormErrors.propTypes = {
  children: PropTypes.string,
};

export default FormErrors;
