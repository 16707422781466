import { convertNestedKeysToCamelCase, convertKeysToCamelCase } from 'core/utils';
import convertResponseToModel from 'utils/responseToModel';
import { ProductOfferingModel, PricingPlanModel } from '../models';

const reducer = (currentState, action) => {
  const { data, type } = action;

  switch (type) {
    case 'fetchList':
      return {
        ...currentState,
        productOfferings: convertResponseToModel(data, ProductOfferingModel),
      };

    case 'fetchDetails': {
      const { offeringsDetails } = currentState;
      return {
        ...currentState,
        offeringsDetails: {
          ...offeringsDetails,
          [data.id]: new ProductOfferingModel(convertKeysToCamelCase(data)),
        },
      };
    }

    case 'updateSubscribedPlan': {
      const { offeringId, ...restData } = data;
      const convertedData = convertNestedKeysToCamelCase(restData);

      const { offeringsDetails } = currentState;
      const productOffering = offeringsDetails[offeringId];

      const filteredPlans = productOffering.pricingPlans
        .filter((plan) => plan.id !== convertedData.pricingPlan.id)
        .map((plan) => { // Destructuring of pricing plan is not used because if there are getters they will be lost
          plan.isSubscribed = false;
          return plan;
        });

      const updatedPricingPlans = [...filteredPlans, new PricingPlanModel({ ...convertedData.pricingPlan, isSubscribed: true })];
      updatedPricingPlans.sort((a, b) => (a.tier - b.tier));
      productOffering.pricingPlans = updatedPricingPlans;

      return {
        ...currentState,
        offeringsDetails: {
          ...offeringsDetails,
          [offeringId]: productOffering,
        },
      };
    }

    case 'updateSubscriptionStatus': {
      const { offeringId } = data;
      const { offeringsDetails } = currentState;

      const productOffering = offeringsDetails[offeringId];
      const currentStatus = productOffering.applicationStatus;
      productOffering.applicationStatus = currentStatus === 'ongoing' ? undefined : 'ongoing';

      return {
        ...currentState,
        ...offeringsDetails,
        [offeringId]: productOffering,
      };
    }

    default:
      return currentState;
  }
};

export default reducer;
