import PropTypes from 'prop-types';

export const Icon = ({ iconName }) => <i className={`fa icon fa-${iconName}`} aria-hidden="true" />;

Icon.propTypes = {
  iconName: PropTypes.string.isRequired,
};


export const ActionLabel = ({ text }) => <span className="button-label">{text}</span>;

ActionLabel.propTypes = {
  text: PropTypes.string.isRequired,
};
