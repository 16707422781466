/* eslint-disable no-underscore-dangle */
import { extendObservable } from 'mobx';
import uuid from 'uuid';
import axios from 'core/axios';
import { documentStoreUrls } from '../stores/config';
import { interpolate } from '../utils/interpolation';
import { Model } from './model';

/**
 * @deprecated Deprecated, do not use.
 */
export class DocumentFileModel extends Model {
  constructor({
    id = null,
    file = null,
    name = null,
    uid = null,
    src = null,
    documentId = null,
    removable = true,
  }) {
    super();
    extendObservable(this, {
      isUploading: false,
      file,
      id,
      name,
      src,
      documentId,
      isRemovable: removable,
    });
    this.uid = uid || uuid.v4();
  }

  get source() {
    return this.file ? this.file : this.src;
  }
}

/**
 * @deprecated Deprecated, do not use.
 */
export class DocumentModel extends Model {
  // eslint-disable-next-line class-methods-use-this
  get fieldMapping() {
    return [
      { name: 'subject', serverName: 'subject' },
      { name: 'companyId', serverName: 'company_id' },
      { name: 'defaultDocumentType', serverName: 'document_type' },
    ];
  }

  constructor({
    documentFiles = [],
    id = null,
    companyId = null,
    date = new Date(),
    subject = '',
    uid = null,
    defaultDocumentType = null,
    isFinalized = false,
    documentObject = {},
  }) {
    super();
    extendObservable(this, {
      documentFiles: documentFiles.map((file) => new DocumentFileModel(file)),
      subject,
      companyId,
      _day: null,
      _month: null,
      _year: null,
      defaultDocumentType,
      documentObject,
      isFinalized,
    });
    this.uid = uid || uuid.v4();
    this.id = id;
    this.setDate(date);

    this.setDocumentObject = this.setDocumentObject.bind(this);
    this.setDocumentType = this.setDocumentType.bind(this);
    this.addFile = this.addFile.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.setDate = this.setDate.bind(this);
    this.setSubject = this.setSubject.bind(this);
    this.successUploadCallback = this.successUploadCallback.bind(this);
    this.failUploadCallback = this.failUploadCallback.bind(this);
    this.finalize = this.finalize.bind(this);
    this.upload = this.upload.bind(this);

  }

  setDocumentObject(val = {}) {
    this.documentObject = val;
  }

  setDocumentType(val) {
    this.defaultDocumentType = val;
  }

  get date() {
    return `${this._year}-${this._month}-${this._day}`;
  }

  addFile(fileObject) {
    this.documentFiles.push(
      new DocumentFileModel({
        file: fileObject,
        name: fileObject.name,
      }),
    );
  }

  getFileRemovalUrl(docId) {
    let url = '';
    let args = {};
    switch (this.documentObject.documentObjectType) {
      case 'person':
        url = documentStoreUrls.personal.DELETE_FILE;
        args = { documentId: this.id, fileId: docId };
        break;
      case 'profile_registration':
        url = documentStoreUrls.profile_registration.DELETE_FILE;
        args = { documentId: this.id, fileId: docId };
        break;
      case 'company_registration':
        url = documentStoreUrls.company_registration.DELETE_FILE;
        args = { documentId: this.id, fileId: docId, companyId: this.documentObject.documentObjectId };
        break;
      case 'company':
        url = documentStoreUrls.company.DELETE_FILE;
        args = { documentId: this.id, fileId: docId, companyId: this.documentObject.documentObjectId };
        break;
      case 'governance_event':
        url = documentStoreUrls.governance_event.DELETE_FILE;
        args = { companyId: this.companyId, eventId: this.documentObject.documentObjectId, id: this.id, fileId: docId };
        break;
      case 'agenda_item':
        url = documentStoreUrls.agenda_item.DELETE_FILE;
        args = { companyId: this.companyId, agendaItemId: this.documentObject.documentObjectId, id: this.id, fileId: docId };
        break;
      default:
        throw new Error(
          `File Removal URL could not be determined. Related object type: "${this.documentObject.documentObjectType}".`,
        );
    }
    return interpolate(url, args);
  }

  removeFile(documentFile) {
    const searchedIndex = this.documentFiles.findIndex((doc) => doc.uid === documentFile.uid);
    if (searchedIndex >= 0) {
      const doc = this.documentFiles[searchedIndex];
      if (doc.isPersisted) {
        axios.delete(this.getFileRemovalUrl(doc.id))
          .then(() => { this.documentFiles.splice(searchedIndex, 1); })
          .catch(console.error);
      } else {
        this.documentFiles.splice(searchedIndex, 1);
      }
    }
  }

  setDate(date) {
    if (!date) return;

    let newDate = date;

    if (typeof (newDate) === 'string') {
      newDate = new Date(newDate);
    }

    if (newDate.getDate()) {
      this._day = (`0${newDate.getDate()}`).slice(-2);
    }

    if (newDate.getYear()) {
      this._year = (`000${newDate.getFullYear()}`).slice(-4);
    }

    if (newDate.getMonth() || newDate.getMonth() === 0) {
      this._month = (`0${newDate.getMonth() + 1}`).slice(-2);
    }
  }

  setSubject(subject) {
    this.subject = subject;
  }

  /**
   * @TODO refactor
   */
  get uploadUrl() {
    let url = '';
    if (this.documentObject.documentObjectType === 'person') {
      if (this.isPersisted) {
        url = interpolate(
          documentStoreUrls.personal.UPDATE,
          { id: this.id },
        );
      } else {
        url = documentStoreUrls.personal.CREATE;
      }
    } else if (this.documentObject.documentObjectType === 'company') {
      if (this.isPersisted) {
        url = interpolate(
          documentStoreUrls.company.UPDATE,
          { id: this.id, companyId: this.documentObject.documentObjectId },
        );
      } else {
        url = interpolate(
          documentStoreUrls.company.CREATE,
          { companyId: this.documentObject.documentObjectId },
        );
      }
    } else if (this.documentObject.documentObjectType === 'company_registration') {
      if (this.isPersisted) {
        url = interpolate(
          documentStoreUrls.company_registration.UPDATE,
          { id: this.id },
        );
      } else {
        url = documentStoreUrls.company_registration.CREATE;
      }
    } else if (this.documentObject.documentObjectType === 'profile_registration') {
      if (this.isPersisted) {
        url = interpolate(
          documentStoreUrls.profile_registration.UPDATE,
          { id: this.id },
        );
      } else {
        url = documentStoreUrls.profile_registration.CREATE;
      }
    } else if (this.documentObject.documentObjectType === 'consortium_membership') {
      if (this.isPersisted) {
        console.warn('url not defined');
      } else {
        url = interpolate(
          documentStoreUrls.consortium_membership.CREATE,
          { companyId: this.documentObject.documentObjectId },
        );
      }
    } else if (this.documentObject.documentObjectType === 'governance_event') {
      if (this.isPersisted) {
        url = interpolate(
          documentStoreUrls.governance_event.UPDATE,
          { companyId: this.companyId, eventId: this.documentObject.documentObjectId, id: this.id },
        );
      } else {
        url = interpolate(
          documentStoreUrls.governance_event.CREATE,
          { companyId: this.companyId, eventId: this.documentObject.documentObjectId },
        );
      }
    } else if (this.documentObject.documentObjectType === 'agenda_item') {
      if (this.isPersisted) {
        url = interpolate(
          documentStoreUrls.agenda_item.UPDATE,
          { companyId: this.companyId, agendaItemId: this.documentObject.documentObjectId, id: this.id },
        );
      } else {
        url = interpolate(
          documentStoreUrls.agenda_item.CREATE,
          { companyId: this.companyId, agendaItemId: this.documentObject.documentObjectId },
        );
      }
    }
    return url;
  }

  // eslint-disable-next-line consistent-return
  successUploadCallback(successResponse) {
    if (['person', 'company', 'governance_event', 'agenda_item'].includes(this.documentObject.documentObjectType)) {
      window.location = successResponse.redirect_url;
    } else {
      return successResponse;
    }
  }

  failUploadCallback(failResponse) {
    this.setResponseErrors(failResponse);
  }

  finalize() {
    this.setProperty('isFinalized', true);
    return this.upload();
  }

  upload() {
    const data = new FormData();

    this.documentFiles.filter((d) => !d.isPersisted).forEach((doc) => {
      data.append('files', doc.file);
    });
    data.append('date', this.date);
    data.append('subject', this.subject);
    if (this.defaultDocumentType) data.append('document_type', this.defaultDocumentType);
    data.append('is_finalized', this.isFinalized);

    const method = this.isPersisted ? 'patch' : 'post';
    return axios[method](this.uploadUrl, data).then(
      ({ data: responseData }) => {
        if (!this.isPersisted) {
          this.id = responseData.id;
        }
        return this.successUploadCallback(responseData);
      },
      ({ response: { data: errors } }) => this.failUploadCallback(errors),
    ).catch(console.error);
  }
}
