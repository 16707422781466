/* Governance event navigation view */
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import { getUrl } from 'utils/urls';
import { FullPageView } from 'core/page';
import EntityMessageDetail from '../entityMessages/views/detail';
import {
  GovernanceEventCreate,
  GovernanceEventDetail,
  GovernanceEventsList,
  AgendaItemCreate,
  AgendaItemUpdate,
} from './views';
import { EventsContext } from './context';
import { BOARD, SHAREHOLDERS } from './const';
import { getStringBetweenSubstrings } from '../core/utils';

export const GovEventsRoutes = ({ ownerId, tokenId, previousPath }) => (
  <Routes>
    <Route path="/">
      <Route index element={<GovernanceEventsList ownerId={ownerId} tokenId={tokenId} previousPath={previousPath} />} />
      <Route path="create/" element={<GovernanceEventCreate />} />
      <Route path=":eventId/" element={<GovernanceEventDetail />} />
      <Route path=":eventId/agenda/create/" element={<AgendaItemCreate />} />
      <Route path=":eventId/agenda/:agendaId/update/" element={<AgendaItemUpdate />} />
      <Route path="informations/:messageId/" element={<EntityMessageDetail />} />
    </Route>
  </Routes>
);

GovEventsRoutes.defaultProps = {
  previousPath: '',
  ownerId: null,
  tokenId: null,
};

GovEventsRoutes.propTypes = {
  previousPath: PropTypes.string,
  ownerId: PropTypes.number,
  tokenId: PropTypes.number,
};

export const GovernanceEventsNavigation = ({ allowedPricingActions, targetGroup, ownerId }) => {
  const indexPathPart = '/meetings';
  const previousPath = getStringBetweenSubstrings({
    str: window.location.pathname,
    start: /\/company\/[0-9]+/g,
    end: indexPathPart,
  });
  const basePath = `${previousPath}${indexPathPart}`;

  const contextValue = useMemo(() => ({
    basePath,
    indexPath: '/',
    allowedPricingActions,
    targetGroup,
  }), [basePath, allowedPricingActions, targetGroup]);

  return (
    <EventsContext.Provider value={contextValue}>
      <Router basename={getUrl(basePath)}>
        <GovEventsRoutes ownerId={ownerId} previousPath={previousPath} />
      </Router>
    </EventsContext.Provider>
  );
};

GovernanceEventsNavigation.defaultProps = {
  allowedPricingActions: [],
  targetGroup: '',
  ownerId: null,
};

GovernanceEventsNavigation.propTypes = {
  allowedPricingActions: PropTypes.arrayOf(PropTypes.string),
  targetGroup: PropTypes.oneOf([BOARD, SHAREHOLDERS, '']),
  ownerId: PropTypes.number,
};


const GovernanceEventsView = ({ allowedPricingActions, targetGroup, ownerId }) => (
  <FullPageView>
    <GovernanceEventsNavigation
      allowedPricingActions={allowedPricingActions ? allowedPricingActions.split(' ') : []}
      targetGroup={targetGroup}
      ownerId={ownerId ? parseInt(ownerId, 10) : null}
    />
  </FullPageView>
);

GovernanceEventsView.defaultProps = {
  allowedPricingActions: '',
  targetGroup: '',
  ownerId: null,
};

GovernanceEventsView.propTypes = {
  allowedPricingActions: PropTypes.string,
  targetGroup: PropTypes.oneOf([BOARD, SHAREHOLDERS, '']),
  ownerId: PropTypes.string,
};

export default GovernanceEventsView;
