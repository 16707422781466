import { Model } from 'core/model';
import { AssetValuation, TimeSeriesValuationType } from '@trustwise/design-system';
import { ITimeSeriesModelProps, TimeSeriesModel, TimeSeriesModelProps } from './timeSeries';


export interface BaseTokenValuationModelProps {
  /**
   * Database ID
   */
  id: number;
  /**
   * Comment
   */
  comment: string;
}

export interface TokenValuationModelProps extends BaseTokenValuationModelProps {
  /**
   * Date and time of the valuation
   * @format date-time
   */
  dateTime: string;
  /**
   * Value
   * @format decimal
   */
  value: string;
  /**
   * Time series
   */
  timeSeries: TimeSeriesModelProps;
}

export interface ITokenValuationModelProps extends BaseTokenValuationModelProps {
  /**
   * Date and time of the valuation
   */
  dateTime: Date;
  /**
   * Price per unit
   */
  pricePerUnit: number;
  /**
   * Time series
   */
  timeSeries: ITimeSeriesModelProps;
}

export class TokenValuationModel extends Model implements ITokenValuationModelProps {
  id: number;
  comment: string;
  timeSeries: ITimeSeriesModelProps;
  dateTime: Date;
  value: number;
  pricePerUnit: number;

  constructor({
    timeSeries,
    dateTime,
    value,
    ...restProps
  }: TokenValuationModelProps) {
    super(restProps);
    this.timeSeries = TimeSeriesModel.fromResponse(timeSeries);
    this.dateTime = new Date(dateTime);
    this.pricePerUnit = Number(value);
  }
}

export interface SimpleTokenValuationProps extends Omit<AssetValuation, 'pricePerUnit' | 'quotation'> {
  /**
   * Valuation ID
   */
  id: number;
  /**
   * Equity currency
   */
  currency: string;
  /**
   * Price per unit
   */
  pricePerUnit: string;
}

export interface IAssetValuation extends AssetValuation {
  /**
   * Valuation ID
   */
  id: number;
}

export class SimpleTokenValuationModel extends Model implements IAssetValuation {
  id: number;
  valuationType: TimeSeriesValuationType;
  byIssuer: boolean;
  pricePerUnit: number;
  quotation: string;

  constructor({ pricePerUnit, currency, ...restProps }: SimpleTokenValuationProps) {
    super(restProps);
    this.quotation = currency;
    this.pricePerUnit = Number(pricePerUnit);
  }
}

