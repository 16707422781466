import { useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import ReconnectingWebSocket from 'reconnecting-websocket';

import { GovEventDetail } from '@trustwise/design-system';
import { getApiUrl, getUrl } from 'utils/urls';
import { PageContent } from 'core/page';
import { SOCKETS_URL, WS_CONFIG } from 'services/constants';
import axios from 'core/axios';
import BackButton from 'core/page/parts/backButton';
import useContacts from 'contacts/hooks';
import { getGlobalContext } from 'core/globals';
import { onPdfDownload } from 'media/utils';
import { useAbortController, useResponseStatus } from 'core/hooks';
import { EventsContext } from 'governanceEvents/context';
import reducer from '../reducers';
import {
  onAgendaItemDelete,
  onAgendaItemsSort,
  onAgendaItemUpdate,
  onMeetingCancel,
  onCheckIn,
  onMeetingClose,
  onMeetingDelete,
  onAgendaFileDownload,
  onIndicateParticipation,
  onIntroTextSeen,
  onMarkAsResolved,
  onProxyVotesSummaryModalOpen,
  onProxyVotingDisable,
  onProxyVotesRelease,
  onInvitationSend,
  onMeetingStart,
  onVotingStart,
  onVotingStop,
  onSuggestedItemAdd,
  onVote,
  onProxyInstructionsSubmit,
  onHeaderItemClick,
  onMinutesSigningRequest,
  onMinutesSign,
  onMinutesSigningCancel,
  onMinutesSend,
  onResultsSend,
  onProposalsUpdate,
} from './actions';
import { IN_PREPARATION } from '../const';

const GovernanceEventDetail = () => {
  const { eventId } = useParams();
  const { state: routerState } = useLocation();
  const navigate = useNavigate();

  const { indexPath, basePath: routerBasePath } = useContext(EventsContext);
  const [responseStatus] = useResponseStatus();
  const [abortController] = useAbortController();
  const { signal } = abortController;

  const { onFetch } = useContacts(false, { 'entity-type': 'person', 'exclude-custodial': 1 });
  const {
    actingPerson: { id: actingPersonId },
    activeEntity: { isCompany, id: entityId },
    custodian: { active: custodianActive },
  } = getGlobalContext();
  const sidebarId = `suggestions-sidebar-meeting-${eventId}-${entityId}${isCompany ? `-${actingPersonId}` : ''}`;

  const [state, dispatch] = useReducer(reducer, {
    agendaItems: undefined,
    meeting: {
      user: {
        id: entityId,
        isOwner: false,
        isCustodial: custodianActive,
      },
      eventDetails: { id: parseInt(eventId, 10) },
      hasCustodialInvitees: false,
    },
    proxyVoting: {
      settings: undefined,
      votesSummary: undefined,
    },
  });

  const { agendaItems, meeting, suggestedAgenda, proxyVoting } = state;
  const { proxyVotingStatus } = meeting.user;

  const [pageMessages, setPageMessages] = useState([]);

  const eventBasePath = `/meetings/${eventId}/`;
  const agendaItemsPath = `${eventBasePath}agenda-items/`;
  const baseNavigationPath = `${routerBasePath}${indexPath}`;
  const eventNavigationPath = `${baseNavigationPath}${eventId}/`;
  const urlPaths = [eventBasePath, eventNavigationPath];

  useEffect(() => {
    responseStatus === 404 && abortController.abort();
  }, [abortController, responseStatus]);

  const fetchEvent = useCallback(() => {
    axios.get(getApiUrl(eventBasePath), { signal })
      .then(({ data }) => dispatch({ type: 'fetchMeeting', data }))
      .catch(console.error);
  }, [signal, eventBasePath]);

  const fetchAgenda = useCallback(() => {
    axios.get(getApiUrl(agendaItemsPath), { signal })
      .then(({ data }) => { dispatch({ type: 'fetchAgenda', data }); })
      .catch(console.error);
  }, [signal, agendaItemsPath]);

  useEffect(() => {
    proxyVotingStatus === 'proxy' && (
      axios.get(getApiUrl(`${eventBasePath}proxy-voting/`), { signal })
        .then(({ data }) => { dispatch({ type: 'fetchProxyVotesSettings', data }); })
        .catch(console.error)
    );
  }, [signal, eventBasePath, proxyVotingStatus]);

  useEffect(fetchEvent, [eventBasePath, fetchEvent]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const visibilityId = urlParams.get('visibility_id');
    visibilityId && (
      axios
        .post(getApiUrl(`/inbox/${visibilityId}/mark-completed/`))
        .catch(console.error)
    );
  }, []);

  useEffect(() => {
    custodianActive && (
      axios
        .post(getApiUrl(`${eventBasePath}complete-custodian-process/`))
        .catch(console.error)
    );
  }, [custodianActive, eventBasePath]);

  useEffect(fetchAgenda, [agendaItemsPath, fetchAgenda]);

  useEffect(() => {
    meeting && meeting.status === IN_PREPARATION && (
      axios.get(getApiUrl(`${agendaItemsPath}suggested/`))
        .then(({ data }) => { dispatch({ type: 'fetchSuggestedAgenda', data }); })
        .catch(console.error)
    );
  }, [agendaItemsPath, meeting]);

  useEffect(() => {
    const clientUrl = `${SOCKETS_URL}${getUrl(eventBasePath)}`;
    const ws = new ReconnectingWebSocket(clientUrl, [], WS_CONFIG);
    ws.onerror = (error) => {
      console.error('[WS GOV EVENTS] Error', error);
    };
    ws.onmessage = (e) => {
      const data = JSON.parse(e.data);
      switch (data.type) {
        case 'meeting.invalidate':
          fetchEvent();
          break;
        case 'agenda_item.invalidate_all':
          fetchAgenda();
          break;
        default:
          console.error('Get unexpected WS message', e.data);
      }
    };
    return () => {
      ws.close();
    };
  }, [eventBasePath, fetchEvent, fetchAgenda]);

  const userDetails = {
    ...meeting.user,
    onVote: (agendaItemId, votes, setDisabled) => onVote(`${agendaItemsPath}${agendaItemId}/proposals/vote/`, setDisabled, votes),
    onIntroTextSeen: (_ev, setDisabled) => { onIntroTextSeen(eventBasePath, dispatch, setDisabled); },
    onCheckIn: (_ev, setDisabled) => { onCheckIn(eventBasePath, dispatch, setDisabled); },
    onIndicateParticipation: (_ev, setDisabled) => { onIndicateParticipation(eventBasePath, dispatch, setDisabled); },
  };

  return (
    <PageContent
      fullscreen
      noPadding
      pageStatus={responseStatus}
      pageHeading={meeting.subject || ''}
      headerLeft={<BackButton onClick={() => { navigate(indexPath, { state: routerState }); }} />}
      pageMessages={pageMessages.map((message) => (
        <li className="error" key={message}>
          {message}
        </li>
      ))}
    >
      <GovEventDetail
        id={sidebarId}
        // @todo consider adding method to convert class together with getters to a plain object
        user={userDetails}
        eventDetails={meeting.eventDetails}
        proxyVoting={meeting.proxy ? {
          ...proxyVoting,
          selected: meeting.proxy,
          onProxyVotesSummaryModalOpen: () => onProxyVotesSummaryModalOpen(eventBasePath),
          onProxyVotesRecord: (votes, settings, setDisabled) => (
            onProxyInstructionsSubmit(...urlPaths, setDisabled, settings, votes)
          ),
          onProxyVotesCancel: (_ev, setDisabled) => onProxyVotingDisable(...urlPaths, setDisabled),
          onProxyVotesRelease: (setDisabled) => onProxyVotesRelease(...urlPaths, setDisabled),
          proxyVotesReleased: meeting.proxyVotesReleased,
        } : undefined}
        items={meeting.status && agendaItems ? agendaItems.map((agendaItem) => ({
          ...agendaItem,
          isVotingOpen: agendaItem.isVotingOpen,
          documents: agendaItem.documents,
          proposals: agendaItem.proposals,
        })) : undefined}
        onHeaderItemClick={(modalId) => onHeaderItemClick(modalId, eventBasePath, onFetch, meeting, dispatch)}
        suggestedItems={suggestedAgenda}
        onSuggestedItemAdd={(item) => onSuggestedItemAdd(item, agendaItemsPath, dispatch)}
        owner={meeting.owner}
        onFileDownload={(fileId) => onAgendaFileDownload(fileId, agendaItems, eventBasePath)}
        eventActions={{
          onMeetingDelete: (_ev, setDisabled) => onMeetingDelete(eventBasePath, baseNavigationPath, setDisabled),
          onMeetingStart: (_ev, setDisabled) => onMeetingStart(...urlPaths, setDisabled, setPageMessages),
          onMeetingCancel: (_ev, setDisabled) => onMeetingCancel(...urlPaths, setDisabled),
          onMeetingClose: (_ev, setDisabled) => onMeetingClose(...urlPaths, setDisabled, setPageMessages),
          onMinutesSigningRequest: (_ev, setDisabled) => onMinutesSigningRequest(...urlPaths, setDisabled, setPageMessages),
          onMinutesSign: (_ev, setDisabled) => onMinutesSign(...urlPaths, setDisabled, setPageMessages),
          onMinutesSigningCancel: (_ev, setDisabled) => onMinutesSigningCancel(eventBasePath, setDisabled, dispatch),
          onMinutesSend: (_ev, setDisabled) => onMinutesSend(...urlPaths, setDisabled, setPageMessages),
          onResultsSend: (_ev, setDisabled) => onResultsSend(...urlPaths, setDisabled, setPageMessages),
          onMinutesDownload: () => onPdfDownload(getApiUrl(`${eventBasePath}minutes/pdf/`), gettext('Minutes')),
          onResultsDownload: () => onPdfDownload(getApiUrl(`${eventBasePath}minutes/pdf/`), gettext('Results')),
          onVotingSheetDownload: meeting.hasCustodialInvitees
            ? () => onPdfDownload(getApiUrl(`${eventBasePath}voting-sheet/pdf`), gettext('Voting sheet'))
            : undefined,
        }}
        agendaItemsActions={{
          onItemDelete: (id) => { onAgendaItemDelete(id, agendaItemsPath, dispatch); },
          onItemEdit: (id) => { navigate(`agenda/${id}/update/`, { state: { event: meeting } }); },
          onSort: (updatedIds) => { onAgendaItemsSort(updatedIds, agendaItemsPath, dispatch); },
          onItemCreate: () => navigate('agenda/create/', { state: { event: meeting } }),
          onVotingStart: (agendaItemId, setDisabled, proposalId) => {
            // @todo Update design-system to pass proposals IDs instead of agendaItemId
            const agendaItem = agendaItems.find((item) => item.id === agendaItemId);
            const proposalsIds = proposalId ? [proposalId] : agendaItem.proposals.map((proposal) => proposal.id);
            return onVotingStart(`${agendaItemsPath}${agendaItemId}/proposals/open/`, setDisabled, proposalsIds);
          },
          onVotingStop: (agendaItemId, setDisabled, proposalId) => {
            // @todo Consider updating design-system to pass proposals IDs instead of agendaItemId
            const agendaItem = agendaItems.find((item) => item.id === agendaItemId);
            const proposalsIds = proposalId ? [proposalId] : agendaItem.proposals.map((proposal) => proposal.id);
            return onVotingStop(`${agendaItemsPath}${agendaItemId}/proposals/close/`, setDisabled, proposalsIds);
          },
          onItemResolve: (agendaItemId, setDisabled) => onMarkAsResolved(agendaItemId, agendaItemsPath, dispatch, setDisabled),
          onProposalsUpdate: (values, actions) => onProposalsUpdate(values, actions, dispatch, agendaItemsPath),
          onProtocolEdit: (values, actions) => onAgendaItemUpdate(
            `${agendaItemsPath}${values.agendaItemId}/update-protocol/`,
            { protocol: values.protocol },
            actions,
            dispatch,
          ),
        }}
        invitationActions={{
          onInvitationPreview: () => onPdfDownload(getUrl(`${eventBasePath}invitation/pdf/`), gettext('Invitation')),
          onInvitationSend: (_ev, setDisabled) => onInvitationSend(...urlPaths, setDisabled, setPageMessages),
        }}
      />
    </PageContent>
  );
};

export default GovernanceEventDetail;
