import { useState, useEffect } from 'react';

import {
  PriorityActionsBar,
  DocumentGenerationModal,
} from '@trustwise/design-system';
import axios from 'core/axios';
import { getApiUrl } from 'utils/urls';
import { getNativeFileUrl } from 'media/utils';
import { isNativeAppWebview } from 'utils/general';
import { actionsIcons } from '../core/icons';
import { useCeleryTaskStatus } from '../core/hooks';
import ShareholdersExcerptModal from './modals/shareholdersExcerptModal';

const companyShareholdersUrlPart = '/ownership/shareholders/';

const documentsData = {
  exportAllExcerpts: {
    label: gettext('Export All Excerpts'),
    iconName: 'pdf',
    url: `${companyShareholdersUrlPart}excerpts/`,
  },
  exportSummary: {
    label: gettext('Export Summary'),
    iconName: 'pdf',
    modalTitle: gettext('Shareholders Summary'),
    url: `${companyShareholdersUrlPart}summary/`,
  },
  exportTitleChain: {
    label: gettext('Export Title Chain'),
    iconName: 'pdf',
    modalTitle: gettext('Title Chain Report'),
    url: `${companyShareholdersUrlPart}title-chain/`,
  },
  exportEmails: {
    label: gettext('Export Emails'),
    iconName: 'csv',
    invertProp: 'fill',
    modalTitle: gettext('Shareholders E-mails'),
    mediaType: 'application/csv',
    url: `${companyShareholdersUrlPart}emails/`,
  },
};

const CompanyShareholdersActions = () => {
  const [openModal, setOpenModal] = useState('');
  const [documentStatus, setDocumentStatus] = useState(undefined);
  const [celeryTaskId, setCeleryTaskId] = useState('');
  const { status: taskStatus, maxRetriesReached, result } = useCeleryTaskStatus(celeryTaskId, 5);

  useEffect(() => {
    if (['unknown', 'pending'].includes(taskStatus)) {
      maxRetriesReached && setDocumentStatus('pending');
    } else {
      setDocumentStatus(taskStatus);
    }
  }, [taskStatus, maxRetriesReached]);

  const onModalClose = () => {
    setOpenModal('');
    setDocumentStatus(undefined);
    setCeleryTaskId('');
  };

  const actions = Object.entries(documentsData).map(
    ([key, data]) => ({
      id: key,
      label: data.label,
      mode: 'secondaryButton',
      iconLeft: actionsIcons[data.iconName],
      invertProp: data.invertProp || 'stroke',
      onClick: (_ev, _setDisabled) => {
        setOpenModal(key);
        if (key !== 'exportAllExcerpts') {
          setDocumentStatus('started');
          axios.post(getApiUrl(data.url))
            .then(({ data: { task_id: taskId } }) => { setCeleryTaskId(taskId); })
            .catch((error) => {
              console.error(error);
              setDocumentStatus('failed');
            });
        }
      },
    }),
  );

  return (
    <>
      <PriorityActionsBar items={actions} />
      <ShareholdersExcerptModal
        isOpen={openModal === 'exportAllExcerpts'}
        onClose={() => { setOpenModal(''); }}
        submitUrlPath={documentsData.exportAllExcerpts.url}
      />
      <DocumentGenerationModal
        isOpen={['exportSummary', 'exportTitleChain', 'exportEmails'].includes(openModal)}
        onClose={onModalClose}
        title={openModal && documentsData[openModal].modalTitle}
        documentStatus={documentStatus}
        statusData={{
          documentStatus,
          initialStatus: 'started',
          onDocumentStatusUpdate: setDocumentStatus,
        }}
        onDocumentDownload={() => {
          const { modalTitle, mediaType } = documentsData[openModal];
          const { file_id: fileId } = result;
          const downloadUrl = getApiUrl(`${companyShareholdersUrlPart}files/${fileId}/download/`);
          window.location = isNativeAppWebview
            ? getNativeFileUrl(downloadUrl, modalTitle, mediaType || 'application/pdf')
            : downloadUrl;
        }}
      />
    </>
  );
};

export default CompanyShareholdersActions;
