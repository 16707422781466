/* eslint-disable react/no-danger */

import PropTypes from 'prop-types';
import { InlineNotification } from '@trustwise/design-system';
import { icons } from '../icons';
import { bp, colorNeutral10, colorNeutral30 } from '../styles';

const InlineNotificationWithLock = ({ title, description, infoMessage }) => {
  const message = (
    <div style={{ display: 'flex', flexDirection: 'column', gap: bp, alignItems: 'flex-start' }}>
      <h4 style={{ color: colorNeutral10, fontSize: 14, lineHeight: '16px' }}>{title}</h4>
      {description && <span style={{ color: colorNeutral30 }}>{description}</span>}
    </div>
  );

  return (
    <InlineNotification
      icon={icons.lock}
      message={message}
      infoMessage={infoMessage}
      extraCss={{ alignItems: 'flex-start', gap: bp * 3 }}
    />
  );
};

InlineNotificationWithLock.defaultProps = {
  description: '',
  infoMessage: '',
};

InlineNotificationWithLock.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  infoMessage: PropTypes.string,
};

export default InlineNotificationWithLock;
