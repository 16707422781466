import PropTypes from 'prop-types';
import GovEventTemplateCreateForm from './form';

const ShareholdersEventTemplateCreateForm = ({ companyId, companyTimezone }) => (
  <GovEventTemplateCreateForm
    companyId={companyId}
    companyTimezone={companyTimezone}
    templateCategory="shareholders"
    leftUrl={`/company/${companyId}/ownership/templates/`}
  />
);

ShareholdersEventTemplateCreateForm.defaultProps = {
  companyTimezone: '',
};

ShareholdersEventTemplateCreateForm.propTypes = {
  companyId: PropTypes.string.isRequired,
  companyTimezone: PropTypes.string,
};

export default ShareholdersEventTemplateCreateForm;
