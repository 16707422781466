import { DataRoomStatus, DataRoomType } from '@trustwise/design-system';
import { BaseLegalEntityWithID } from 'contacts/interfaces/types';
import { SimpleLegalEntityModel } from 'contacts/models/legalEntity';
import { Model } from 'core/model';
import { DataRoomSchema } from 'dataRooms/types';

export interface BaseDataRoomModelProps {
  /**
   * Data room ID
   */
  id: number;
  /**
   * Data room name
   */
  name: string;
  /**
   * Data room status
   */
  status: DataRoomStatus;
  /**
   * Indicates whether the data room is private
   */
  isPrivate: boolean;
  /**
   * Data room description
   */
  description?: string;
  /**
   * Data room schema
   */
  schema: DataRoomSchema[];
}

export interface VerifierDataRoom extends BaseDataRoomModelProps {
  /**
   * Data room type
   */
  roomType: DataRoomType;
  /**
   * Data room owner
   */
  owner: BaseLegalEntityWithID;
}

export interface VerifierDataRoomModelProps extends BaseDataRoomModelProps {
  /**
   * Data room type
   */
  type: DataRoomType;
  /**
   * Data room owner
   */
  owner: SimpleLegalEntityModel;
}


export class VerifierDataRoomModel extends Model implements VerifierDataRoomModelProps {
  id: number;
  name: string;
  type: DataRoomType;
  status: DataRoomStatus;
  isPrivate: boolean;
  description?: string;
  owner: SimpleLegalEntityModel;
  schema: DataRoomSchema[];

  constructor({ owner, roomType, ...restProps }: VerifierDataRoom) {
    super(restProps);
    this.owner = SimpleLegalEntityModel.fromResponse(owner);
    this.type = roomType;
  }
}
