import PropTypes from 'prop-types';
import ContentLoader from 'react-content-loader';
import { colorBackground20, colorNeutral80, bp } from 'core/styles';

/**
 * Content Loading
 *
 * Generic component which should be used when the data is being loaded.
 * Children must be valid SVG components.
 * When passing multiple SVG components wrap them in .
 *
 */
const ContentLoading = ({ children, width, height, padded }) => (
  // Don't render content if the width of the parent is 0
  // to prevent incorrect rendering of SVGs, because their sizes depend on this width
  width > 0 && (
  <div className={`${padded ? 'padded-y' : ''}`}>
    <ContentLoader
      height={height}
      backgroundColor={colorNeutral80}
      foregroundColor={colorBackground20}
      viewBox={`0 0 ${width} ${height}`}
    >
      {children || <DefaultLoadingContent width={width} />}
    </ContentLoader>
  </div>
  )
);

ContentLoading.defaultProps = {
  padded: true,
  children: null,
  height: 221,
};

ContentLoading.propTypes = {
  children: PropTypes.element,
  width: PropTypes.number.isRequired,
  height: PropTypes.number,
  padded: PropTypes.bool,
};


const DefaultLoadingContent = ({ width }) => {
  const rightColumnPosition = width - 66;
  const longLineWidth = width < 381 ? 150 : 305;
  const topYPosition = bp * 6;
  return (
    <>
      <rect y={topYPosition} width="32" height="32" rx="16" />
      <rect y={topYPosition} x="44" width="98" height="8" rx="4" />
      <rect y={topYPosition} x={rightColumnPosition} width="66" height="8" rx="4" />
      <rect x="44" y={`${topYPosition + 16}`} width={longLineWidth} height="8" rx="4" />
      <rect x="44" y={`${topYPosition + 32}`} width="67" height="8" rx="4" />
      <rect y={`${topYPosition + 72}`} width="32" height="32" rx="16" />
      <rect x="44" y={`${topYPosition + 72}`} width="98" height="8" rx="4" />
      <rect x={rightColumnPosition} y={`${topYPosition + 72}`} width="66" height="8" rx="4" />
      <rect x="44" y={`${topYPosition + 88}`} width={longLineWidth} height="8" rx="4" />
      <rect x="44" y={`${topYPosition + 104}`} width="67" height="8" rx="4" />
      <rect y={`${topYPosition + 144}`} width="32" height="32" rx="16" />
      <rect x="44" y={`${topYPosition + 144}`} width="98" height="8" rx="4" />
      <rect x={rightColumnPosition} y={`${topYPosition + 144}`} width="66" height="8" rx="4" />
      <rect x="44" y={`${topYPosition + 160}`} width={longLineWidth} height="8" rx="4" />
      <rect x="44" y={`${topYPosition + 176}`} width="67" height="8" rx="4" />
    </>
  );
};

DefaultLoadingContent.propTypes = {
  width: PropTypes.number.isRequired,
};


export default ContentLoading;
