import { ContactSelectField as DSContactSelectField } from '@trustwise/design-system';
import useContacts from 'contacts/hooks';
import { ProvidedService } from 'core/providedServices';
import { LegalEntityTypes } from 'contacts/constants';
import { IncludeOnlyOption } from './types';

export interface BaseContactSelectFieldProps {
  /**
   * The name of the field
   */
  name: string;
  /**
   * Service type provided by the legal entity on the contact
   */
  serviceType?: ProvidedService;
  /**
   * Label for the select contact field
   */
  selectContactLabel?: string;
  /**
   * Label for the field when no value is selected
   */
  emptyValueLabel?: string;
  /**
   * Defines the type of contacts to display
   */
  includeOnly?: IncludeOnlyOption;
}

interface ContactSelectFieldProps extends BaseContactSelectFieldProps {
  /**
   * The ID of the field
   */
  id?: string;
  /**
   * Label for the field
   */
  label?: string;
  /**
   * Indicates whether the field is optional
   */
  isOptional?: boolean;
  /**
   * Indicates whether the field is disabled
   */
  isDisabled?: boolean;
  /**
   * Indicates whether to include company wallets
   */
  includeCompanyWallets?: boolean;
  /**
   * Indicates whether to include own contacts
   */
  includeOwn?: boolean;
  /**
   * Indicates whether to exclude custodial contacts
   */
  excludeCustodial?: boolean;
  /**
   * Indicates whether to show portfolios
   */
  withPortfolios?: boolean;
}

export const ContactSelectField = ({
  id,
  name,
  isOptional,
  isDisabled,
  includeOwn,
  serviceType,
  includeCompanyWallets,
  selectContactLabel,
  excludeCustodial,
  emptyValueLabel,
  withPortfolios,
  label,
  includeOnly,
}: ContactSelectFieldProps) => {

  const urlQueryArgs = {};
  if (!includeCompanyWallets) {
    urlQueryArgs['entity-type'] = LegalEntityTypes.PERSON;
  }
  if (serviceType) {
    urlQueryArgs['service-type'] = serviceType;
  }
  if (includeOnly) {
    const onlyCompany = includeOnly === 'company';
    const argKey = onlyCompany ? 'entity-type' : `only-${includeOnly}`;
    urlQueryArgs[argKey] = onlyCompany ? LegalEntityTypes.COMPANY : 1;
  }
  if (includeOwn) {
    urlQueryArgs['include-own'] = 1;
  }
  if (excludeCustodial) {
    urlQueryArgs['exclude-custodial'] = 1;
  }

  const { onFetch } = useContacts(withPortfolios, urlQueryArgs);

  return (
    <>
      <label htmlFor={id}>{label}</label>
      <div className="contact-select-field">
        <DSContactSelectField
          id={id}
          name={name}
          disabled={isDisabled}
          isOptional={isOptional}
          emptyValueLabel={emptyValueLabel}
          emptyValueAction={selectContactLabel}
          onFetch={onFetch}
          contactsListTitle={selectContactLabel}
          onSearch={({ searchString }) => onFetch(searchString)}
          emptyStateActionLabel={selectContactLabel}
        />
      </div>
    </>
  );
};
