import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import { getUrl } from 'utils/urls';
import { FullPageView } from 'core/page';
import {
  RegisteredObjectsTypesListView,
  RegisteredObjectsListView,
  RegisterObjectFormView,
  RegisteredObjectDetailView,
} from './views';
import RegisteredObjectTokenizationView from './views/tokenization';

const RegisteredObjectsView = () => (
  <FullPageView>
    <Router basename={getUrl('/services/registered-objects/')}>
      <Routes>
        <Route path="/">
          <Route path=":catalogType/">
            <Route index element={<RegisteredObjectsTypesListView />} />
            <Route path=":objectType/">
              <Route index element={<RegisteredObjectsListView />} />
              <Route path="register/" element={<RegisterObjectFormView />} />
              <Route path=":objectId/" element={<RegisteredObjectDetailView />} />
              <Route path=":objectId/create-quotas/" element={<RegisteredObjectTokenizationView />} />
            </Route>
          </Route>
        </Route>
      </Routes>
    </Router>
  </FullPageView>
);

export default RegisteredObjectsView;
