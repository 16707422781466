import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PricingTransactionsTable } from '@trustwise/design-system';
import axios from 'core/axios';
import { getApiUrl } from 'utils/urls';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import PricingTransactionsModel from '../models/transactions';

const PricingTransactionsListView = () => {
  const navigate = useNavigate();
  const [transactions, setTransaction] = useState();

  useEffect(() => {
    axios.get(getApiUrl('/pricing/transactions/'))
      .then(({ data }) => setTransaction(PricingTransactionsModel.fromArrayResponse(data)))
      .catch(console.error);
  }, []);

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Credit Transactions')}
      headerLeft={<BackButton onClick={() => { navigate('/', { state: { activeTab: 'billing' } }); }} />}
    >
      <PricingTransactionsTable items={transactions} />
    </PageContent>
  );
};

export default PricingTransactionsListView;

