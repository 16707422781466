import PropTypes from 'prop-types';
import { currencyOptions } from 'core/options';
import DecimalField from './decimal';
import { ReactSelectField } from './select';
import FieldErrors from './errors';
import FieldLabel from './label';

/**
 * Monetary field, which is a combination of currency select and decimal field.
 *
 */
const MonetaryField = ({ label, isOptional, currencyName, valueName, step, disabled, ...props }) => (
  <>
    <FieldLabel id={`${currencyName}-${valueName}`} label={label} isOptional={isOptional} />
    <FieldErrors name={valueName} />
    <div className="monetary-value-input">
      <ReactSelectField
        className="currency"
        name={currencyName}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.code}
        options={currencyOptions}
        disabled={disabled}
        isSearchable
        {...props}
      />
      <div className="price-value">
        <DecimalField name={valueName} step={step} hideErrors disabled={disabled} />
      </div>
    </div>
  </>
);

MonetaryField.defaultProps = {
  step: '.01',
  disabled: false,
  isOptional: false,
};

MonetaryField.propTypes = {
  label: PropTypes.string.isRequired,
  currencyName: PropTypes.string.isRequired,
  valueName: PropTypes.string.isRequired,
  step: PropTypes.string,
  disabled: PropTypes.bool,
  isOptional: PropTypes.bool,
};

export default MonetaryField;
