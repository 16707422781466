import DocumentsSigningList from 'media/documents/signingList';
import Widget from './base';


const DocumentsWidget = () => (
  <Widget
    id="documents"
    title={gettext('Contracts')}
    action={{ label: gettext('See all'), urlName: '/documents-signing/' }}
  >
    <DocumentsSigningList />
  </Widget>
);

export default DocumentsWidget;
