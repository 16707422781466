/**
 * List of archived documents with the search.
 */

import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import SearchForm from 'core/forms/searchForm';
import ContentLoadingMessage from 'utils/contentLoadingMessage';
import { PageContent } from 'core/page';
import { getUrl, getApiUrl } from 'utils/urls';
import axios from 'core/axios';
import { Icon } from 'components/common/icon';
import ScrollableContent from 'core/page/scrollableContent';
import { DocumentInCircleIcon } from 'core/svgs';
import BackButton from 'core/page/parts/backButton';


const DocumentsArchiveList = ({ state, dispatch, isLoading, fetchMoreData }) => {
  const [searchResultLoading, setSearchResultLoading] = useState(false);
  const { pathname } = useLocation();
  const { queryParams, documents, nextPage } = state;
  const sortedDocuments = Object.keys(documents)
    .reduce((array, key) => [...array, documents[key]], [])
    .sort((a, b) => b.documentDate - a.documentDate);

  const onSubmit = ({ searchString }) => {
    setSearchResultLoading(true);
    let searchParams = new URLSearchParams(queryParams);
    searchParams.set('search', searchString);
    searchParams = searchParams.toString();
    axios.get(getApiUrl('/new-documents/', searchParams))
      .then(({ data }) => { dispatch({ data: { ...data, queryParams: `${searchParams}` }, type: 'getSearchResult' }); })
      .catch(console.error)
      .finally(() => { setSearchResultLoading(false); });
  };

  return (
    <PageContent
      pageHeading={gettext('Archive')}
      headerLeft={<BackButton href={getUrl()} />}
    >
      <>
        <SearchForm onSubmit={onSubmit} />
        {(!searchResultLoading && sortedDocuments.length > 0) ? (
          <ScrollableContent
            dataLength={sortedDocuments.length}
            fetchNext={fetchMoreData}
            hasMore={!!nextPage}
          >
            <ul className="padded-top">
              {sortedDocuments.map((doc) => (
                <li key={doc.id} className="document-file">
                  <Link
                    to={{ pathname: `${pathname}documents/${doc.id}/`, search: queryParams }}
                    className="document-list-row"
                  >
                    <DocumentInCircleIcon className="row-content-icon" />
                    <div>
                      <div className="widget-row-title">{`${doc.formattedDate} ${doc.title}`}</div>
                      {doc.subtitle && (
                        <div className="widget-row-main-content">
                          <Icon name={doc.icon.name} weight={`${doc.icon.weight}`} className="icon" />
                          <span className="tiny-left-padding">{doc.subtitle}</span>
                        </div>
                      )}
                      {doc.tags.length > 0 && (
                        <div className="text-tiny padded-top font-italic">
                          <Icon name="tags" className="icon" />
                          <span className="tiny-left-padding">{doc.tags.map((tag) => (tag.name)).join(', ')}</span>
                        </div>
                      )}
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </ScrollableContent>
        ) : (
          <ContentLoadingMessage
            isLoading={isLoading || searchResultLoading}
            loadedMessage={gettext('No documents available')}
          />
        )}
      </>
    </PageContent>
  );
};


DocumentsArchiveList.defaultProps = {
  fetchMoreData: () => { },
  isLoading: false,
};

DocumentsArchiveList.propTypes = {
  state: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
  fetchMoreData: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default DocumentsArchiveList;
