import { DateTime } from 'luxon';

import {
  DataSetAllowedAction,
  DataRoomAccessRole,
  DataRoomAccessRoles,
  DataSetVerificationStatus,
  DataSetVerificationDecision,
} from '@trustwise/design-system';
import { BaseLegalEntityWithID } from 'contacts/interfaces/types';
import { DocumentModelDataProps } from 'media/interfaces/modelDataTypes';
import { Model } from 'core/model';
import { FileModel, SimpleDocumentModel } from 'media/models';
import { SimpleLegalEntityModel } from 'contacts/models/legalEntity';
import { getReportsFiles } from 'dataRooms/utils';
import { GrantItemResponse } from 'dataRooms/externalAccess/types';
import { GrantModel } from 'dataRooms/externalAccess/models';

interface BaseDataSetItemModelProps {
  /**
   * Data set ID
   */
  id: number;
  /**
   * Data set name
   */
  name: string;
  /**
   * Indicates whether data set is sealed
   */
  isSealed: boolean;
  /**
   * Data room type
   */
  verificationStatus: DataSetVerificationStatus;
  /**
   * Number of data entries the data set has
   */
  noOfEntries: number;
  /**
   * Granted token amount
   */
  grantedTokenAmount?: number;
  /**
   * Blockchain ID
   */
  blockchainId?: number;
}

interface DataSetItemModelProps extends BaseDataSetItemModelProps {
  /**
   * Date time in which data set was created
   */
  createdAt: string;
  /**
   * Date time in which data set was reviewed
   */
  reviewedAt?: string;
  /**
   * Date and time of the last recorded access of the reader
   */
  lastRecordedAccess?: string;
}

interface IDataSetItemModelProps extends BaseDataSetItemModelProps {
  /**
   * Date time in which data set was created
   */
  createdAt: Date;
  /**
   * Date time in which data set was reviewed
   */
  reviewedAt?: Date;
  /**
   * Date and time of the last recorded access of the reader
   */
  lastRecordedAccess?: Date;
}

export class DataSetItemModel extends Model implements IDataSetItemModelProps {
  id: number;
  name: string;
  isSealed: boolean;
  verificationStatus: DataSetVerificationStatus;
  noOfEntries: number;
  grantedTokenAmount?: number;
  blockchainId?: number;
  createdAt: Date;
  grantAmount?: number;
  entriesCount: number;
  reviewedAt?: Date;
  lastRecordedAccess?: Date;

  constructor({
    createdAt, reviewedAt, noOfEntries, grantedTokenAmount, lastRecordedAccess, ...restProps
  }: DataSetItemModelProps) {
    super(restProps);
    this.createdAt = new Date(createdAt);
    this.lastRecordedAccess = lastRecordedAccess ? new Date(lastRecordedAccess) : undefined;
    this.grantAmount = grantedTokenAmount ? Number(grantedTokenAmount) : undefined;
    this.reviewedAt = reviewedAt ? new Date(reviewedAt) : undefined;
    this.entriesCount = noOfEntries;
  }

  getShouldConfirmAccess(accessRole: DataRoomAccessRole) {
    if (accessRole !== DataRoomAccessRoles.READ) {
      return false;
    }
    if (!this.lastRecordedAccess) {
      return true;
    }
    const now = DateTime.now();
    const lastRecordedAccess = DateTime.fromJSDate(this.lastRecordedAccess);
    return now.diff(lastRecordedAccess, 'hours').hours > 10;
  }
}

interface BaseDataSetModelProps {
  /**
   * Granted token address
   */
  grantedTokenAddress?: string;
  /**
   * Allowed actions for the data set
   */
  allowedActions: DataSetAllowedAction[];
}

interface DataSetModelProps extends BaseDataSetModelProps, DataSetItemModelProps {
  /**
   * Legal entity (person) responsible for reviewing the data set
   */
  reviewedBy: BaseLegalEntityWithID;
  /**
   * Legal entity (company) responsible for reviewing the data set
   */
  reviewedByCompany: BaseLegalEntityWithID;
  /**
   * Data set verifier reports
   */
  verifierReports?: DocumentModelDataProps[];
  /**
   * Granted token
   */
  grantedToken?: GrantItemResponse;
}

interface IDataSetModelProps extends BaseDataSetModelProps, IDataSetItemModelProps {
  /**
   * Legal entity (person) responsible for reviewing the data set
   */
  reviewedBy: SimpleLegalEntityModel;
  /**
   * Legal entity (company) responsible for reviewing the data set
   */
  reviewedByCompany: SimpleLegalEntityModel;
  /**
   * Data set verifier reports
   */
  verifierReports?: FileModel[];
  /**
   * Granted token
   */
  grantedToken?: GrantModel;
  /**
   * Data set allowed actions
   */
  allowedActions: DataSetAllowedAction[];
}

export class DataSetModel extends DataSetItemModel implements IDataSetModelProps {
  name: string;
  reviewedBy: SimpleLegalEntityModel;
  reviewedByCompany: SimpleLegalEntityModel;
  isSealed: boolean;
  grantedTokenAddress?: string | undefined;
  allowedActions: DataSetAllowedAction[];
  grantedToken?: GrantModel;
  decision?: DataSetVerificationDecision;
  _verifierReports: SimpleDocumentModel[];

  constructor({
    reviewedBy, reviewedByCompany, verifierReports, grantedToken, allowedActions, ...restProps
  }: DataSetModelProps) {
    super({ ...restProps });
    this.allowedActions = allowedActions;
    this.reviewedBy = SimpleLegalEntityModel.fromResponse(reviewedBy);
    this.reviewedByCompany = SimpleLegalEntityModel.fromResponse(reviewedByCompany);
    this.grantedToken = grantedToken ? GrantModel.fromResponse(grantedToken) : undefined;
    this._verifierReports = verifierReports ? SimpleDocumentModel.fromArrayResponse(verifierReports) : undefined;
  }

  get verifierReports() {
    return getReportsFiles(this._verifierReports);
  }

  get verdict() {
    if (!this.decision) return undefined;

    return {
      reviewedAt: this.reviewedAt,
      decision: this.decision,
      reviewedBy: this.reviewedBy,
      reviewedByCompany: this.reviewedByCompany,
      verifierReports: this.verifierReports,
      grant: this.grantedToken,
    };
  }
}
