
/* Generic component for header urls */
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Icon } from 'components/common/icon';
import { icons } from 'core/icons';
import Link from 'components/common/link';

const LinkWithContent = ({ to, state, children, className, useRouterLink }) => {
  let url = {};
  if (useRouterLink) {
    url = new URL(`${to}`, window.location.origin);
  }
  return (
    useRouterLink ? (
      <RouterLink to={{ pathname: url.pathname, search: url.search }} state={state} className={className}>
        {children}
      </RouterLink>
    ) : (
      <Link href={to} className={className}>
        {children}
      </Link>
    )
  );
};
LinkWithContent.defaultProps = {
  className: '',
  useRouterLink: true,
};

LinkWithContent.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  useRouterLink: PropTypes.bool,
  state: PropTypes.shape({}),
};


const IconLink = ({ to, iconName, iconClassName, useRouterLink, className, state }) => (
  <LinkWithContent className={className} to={to} useRouterLink={useRouterLink} state={state}>
    {iconName ? <Icon name={iconName} className={iconClassName} /> : icons.backButton}
  </LinkWithContent>
);

IconLink.defaultProps = {
  className: '',
  iconName: null,
  iconClassName: 'icon',
  useRouterLink: true,
};

IconLink.propTypes = {
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  iconName: PropTypes.string,
  iconClassName: PropTypes.string,
  useRouterLink: PropTypes.bool,
  state: PropTypes.shape({}),
};

export { LinkWithContent, IconLink };
