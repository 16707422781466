import { getGlobalContext } from 'core/globals';
import { Model } from 'core/model';
import { SimpleLegalEntityModel } from '../contacts/models/legalEntity';
import { SimpleDocumentModel } from '../media/models/document';
import { BOARD_INFORMATION } from './const';

export default class EntityMessageModel extends Model {
  constructor({
    messageType,
    sender,
    subject,
    content,
    dateTime,
    confirmationRequired,
    isConfirmed,
    isSeen,
    documents,
    ...restProps
  }) {
    super(restProps);
    this.type = messageType;
    this.sender = new SimpleLegalEntityModel(sender);
    this.subject = subject;
    this.content = content;
    this.dateTime = dateTime ? new Date(dateTime) : dateTime;
    this.confirmationRequired = confirmationRequired;
    this.isConfirmed = isConfirmed;
    this.isSeen = isSeen;
    this.documents = SimpleDocumentModel.fromArrayResponse(documents);
  }

  get isSender() {
    const { activeEntity: { id: activeEntityId } } = getGlobalContext();
    return this.sender.id === activeEntityId;
  }

  // @todo consider moving this logic to design system
  get recipientsLabel() {
    return this.type === BOARD_INFORMATION ? gettext('All Board Members') : gettext('All Shareholders');
  }

  get files() {
    return this.documents.map((doc) => doc.files).flat();
  }

  get isDraft() {
    return this.status === 'draft';
  }
}
