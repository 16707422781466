import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import PropTypes from 'prop-types';

import { getUrl } from 'utils/urls';
import { convertBooleanString } from 'core/utils';
import { FullPageView } from 'core/page';
import PropertyTokenTransferDetailView from './views/detail';

const PropertyTokenTransfersView = ({ isArbiter }) => {
  const isArbiterView = convertBooleanString(isArbiter);
  return (
    <FullPageView>
      <Router basename={getUrl(isArbiterView ? '/arbiter/' : '/transfers/')}>
        <Routes>
          <Route path="/">
            <Route index path=":id/" element={<PropertyTokenTransferDetailView isArbiter={isArbiterView} />} />
          </Route>
        </Routes>
      </Router>
    </FullPageView>
  );
};

PropertyTokenTransfersView.propTypes = {
  isArbiter: PropTypes.string.isRequired,
};

export default PropertyTokenTransfersView;
