import axios from 'core/axios';
import { getApiUrl } from 'utils/urls';
import { convertKeysToCamelCase } from 'core/utils';

export const onBillingOverviewMount = async () => {
  const getBillingData = async () => {
    const billingData = {};

    await axios.get(getApiUrl('/invoice/', { no_of_items: 1 }))
      .then(({ data }) => {
        const [lastInvoice] = data;
        if (lastInvoice) {
          const { due_date: dueDate, status } = lastInvoice;
          billingData.lastInvoice = {
            dueDate: new Date(dueDate),
            status,
          };
        }
      })
      .catch(console.error);

    await axios.get(getApiUrl('/pricing/credits/'))
      .then(({ data }) => {
        billingData.creditBalance = convertKeysToCamelCase(data).balance;
      })
      .catch(console.error);

    return billingData;
  };

  return getBillingData();
};

export const onContactsOverviewMount = async () => {
  const pendingContacts = {};

  await axios.get(getApiUrl('/contacts/requests/', { status: 'PEN' }))
    .then(({ data }) => { pendingContacts.pendingRequestsCount = data.count; })
    .catch(console.error);

  return pendingContacts;
};
