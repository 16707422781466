/* Actions bar component */
import PropTypes from 'prop-types';
import ActionTypePart from './parts/actionType';


const ActionsBarComponent = ({ actionsList, className }) => (
  actionsList.length > 0 && (
  <ul className={`actions-bar ${className} align-center expanded icons icon-top`}>
    <div className="actions-bar-label align-text-center text-big font-medium bottom-padding">{gettext('Actions')}</div>
    {actionsList.map((action) => (
      <li key={action.icon}>
        <ActionTypePart action={action} />
      </li>
    ))}
  </ul>
  )
);

ActionsBarComponent.defaultProps = {
  className: '',
};

ActionsBarComponent.propTypes = {
  actionsList: PropTypes.arrayOf(PropTypes.shape).isRequired,
  className: PropTypes.string,
};

export default ActionsBarComponent;
