import { getGlobalContext } from 'core/globals';

const {
  activeEntity: { id: activeEntityId },
} = getGlobalContext();

/**
 * Utility Token Asset Model
 *
 * Props under restProps are:
 * address, currency, id, issuerId, marketListings, name, portfolioId,
 * status, unit, valuationType
 *
 */
export class UtilityTokenAssetModel {
  constructor({
    utilityTokenType, amount, valuePerUnit, ...restProps
  }) {
    this.type = utilityTokenType;
    // @todo remove once we have market listings
    this.marketType = 'carbon_emission_allowance';
    this.totalAmount = Number(amount);
    this.valuePerUnit = Number(valuePerUnit);
    Object.entries(restProps).forEach(([fieldName, fieldValue]) => {
      this[fieldName] = fieldValue;
    });
  }

  get isIssuer() {
    return activeEntityId === this.issuerId;
  }

  get overviewDetails() {
    return ({
      type: this.type,
      totalAmount: this.totalAmount,
      valuePerUnit: this.valuePerUnit,
      address: this.address,
      status: this.status,
      isIssuer: this.isIssuer,
      currency: this.currency,
    });
  }

  get previewDetails() {
    return ({
      name: this.name,
      type: this.type,
    });
  }
}
