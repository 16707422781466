import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { DonutChart } from '@trustwise/design-system';
import { getApiUrl } from 'utils/urls';
import axios from 'core/axios';
import { colorBackground20 } from 'core/styles';
import processChartData from 'core/charts/utils';


const AssetsAllocationChart = ({ baseCurrency, urlSearch }) => {
  const [totalChartValue, setTotalChartValue] = useState(0);
  const [chartData, setChartData] = useState({
    values: undefined,
    labels: undefined,
    descriptions: undefined,
  });

  useEffect(() => {
    axios.get(getApiUrl('/assets/equity/portfolio-chart-data/', urlSearch))
      .then(({ data }) => {
        setTotalChartValue(data.values.length);
        setChartData(processChartData(data));
      })
      .catch(console.error);
  }, [baseCurrency, urlSearch]);

  return (
    <DonutChart
      showLegend
      totalValue={totalChartValue}
      totalLabel={totalChartValue === 1 ? gettext('Company') : gettext('Companies')}
      strokeColor={colorBackground20}
      {...chartData}
    />
  );
};

AssetsAllocationChart.defaultProps = {
  urlSearch: '',
};


AssetsAllocationChart.propTypes = {
  baseCurrency: PropTypes.string.isRequired,
  urlSearch: PropTypes.string,
};

export default AssetsAllocationChart;
