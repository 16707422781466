import { getDocumentSubmitData } from 'media/utils';
import { signFormTransaction } from 'utils/signTransaction';

export const onPaymentUpload = ({ urlPath, values, actions, navigatePath }) => {
  const { documents } = values;
  const data = documents && documents.length ? getDocumentSubmitData(documents[0]) : {};

  signFormTransaction({
    urlPath,
    navigatePath,
    actions,
    data,
  });
};
