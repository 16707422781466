/**
 * Detail of issuer in issuer registry.
 */

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'core/axios';
import { FullPageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import { signTransaction } from 'utils/signTransaction';
import { getApiUrl, getUrl } from 'utils/urls';


const IssuerDetail = ({ companyId, issuerRegistryId, issuerId }) => {
  const [issuer, setIssuer] = useState({});
  if (!companyId) {
    throw new Error('companyId is required');
  }
  const [isActionDisabled, setIsActionDisabled] = useState(false);
  const basePath = `/certificates/issuer-registries/${issuerRegistryId}`;
  const issuerUrl = `${basePath}/issuers/${issuerId}/`;

  useEffect(() => {
    axios.get(getApiUrl(issuerUrl)).then(({ data }) => { setIssuer(data); }).catch(console.error);
  }, [issuerUrl, issuerId]);

  return (
    <FullPageContent
      pageHeading={issuer.name}
      headerLeft={<BackButton href={getUrl(basePath)} />}
      actions={[{
        type: 'form',
        label: gettext('Remove'),
        isDisabled: isActionDisabled,
        icon: 'trash',
        onClick: () => {
          setIsActionDisabled(true);
          signTransaction({
            urlPath: `${issuerUrl}remove`,
            navigateUrl: basePath,
            setDisabled: setIsActionDisabled,
          });
        },
      }]}
    >
      <span className="row-flex padded-y">
        <span className="title no-overflow">
          {gettext('Name')}
        </span>
        <span className="font-bold">{issuer.name}</span>
      </span>
    </FullPageContent>
  );
};

IssuerDetail.defaultProps = {
  companyId: null,
};

IssuerDetail.propTypes = {
  companyId: PropTypes.string,
  issuerRegistryId: PropTypes.string.isRequired,
  issuerId: PropTypes.string.isRequired,
};

export default IssuerDetail;
