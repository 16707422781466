export const NOTIFICATIONS_URL = '/notifications';
export const RELAY_URL = '/sign-up-relay';

const protocol = window.location.protocol === 'http:' ? 'ws://' : 'wss://';
let { host } = window.location;
if (['localhost', '0.0.0.0', '127.0.0.1'].includes(window.location.hostname)) {
  host = `${window.location.hostname}:5000`;
}

export const SOCKETS_URL = `${protocol}${host}/ws`;

export const WS_CONFIG = {
  minReconnectionDelay: 500,
  maxReconnectionDelay: 500,
  maxRetries: 60,
  debug: false,
};
