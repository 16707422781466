import { snakeCase } from 'lodash';

import { addMultipleDocuments } from 'media/utils';
import { convertKeysCase } from 'core/utils';
import { signFormTransaction } from 'utils/signTransaction';

export const onSubmitArtwork = ({ values, actions, submitPath, navigatePath }) => {
  const { media, ...restValues } = values;
  const data = convertKeysCase(restValues, snakeCase);
  const submitData = new FormData();

  Object.entries(data).forEach(
    ([key, value]) => submitData.append(key, value),
  );

  addMultipleDocuments({ documents: media, submitData, fieldName: 'media' });

  signFormTransaction({
    urlPath: submitPath,
    navigatePath,
    data: submitData,
    actions,
  });
};
