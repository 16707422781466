import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getApiUrl } from 'utils/urls';
import axios from 'core/axios';
import { parseErrorResponse } from 'core/forms/utils';
import {
  shareholderMeeting,
  shareholderEvents,
  boardEvents,
  getEventTypesOptions,
} from '../constants';
import Wizard from './wizardComponent';
import { dateToday, roundedTime } from '../../utils/date';
import GeneralInfo, { validationGeneralInfo } from '../formPages/general';
import Quorum, { validationQuorum } from '../formPages/quorum';
import DateLocation, { validationDateLocation } from '../formPages/dateLocation';
import ShareClassesEditFormPart, { validationShareClasses } from '../parts/shareClassesEditFrom';


const validationArray = [validationGeneralInfo, validationShareClasses, validationQuorum, validationDateLocation];

const GovEventTemplateCreateForm = ({ companyId, companyTimezone, templateCategory, defaultEvent }) => {
  const [timeZonesOptions, setTimeZonesOptions] = useState([]);
  const eventTypesOptions = getEventTypesOptions(templateCategory);
  const [allShareClasses, setAllShareClasses] = useState([]);
  const [eventType, setEventType] = useState(defaultEvent);

  useEffect(() => {
    axios.get('/api/v1/options/time-zones/')
      .then(({ data: { data } }) => { setTimeZonesOptions(data); })
      .catch(console.error);
  }, []);

  useEffect(() => {
    if (templateCategory !== 'board') {
      axios.get(getApiUrl('/all-share-classes/'))
        .then(({ data }) => { setAllShareClasses(data); })
        .catch(console.error);
    }
  }, [templateCategory]);

  const onSubmit = (values, actions) => {
    const data = {
      subject: values.subject,
      datetime: `${values.date} ${values.time}:00`,
      time_zone: values.timeZone,
      location: values.location,
      event_type: values.eventType,
      legal_entity: companyId,
    };
    data.data = {
      voting_threshold: {
        base: 'VC',
        numerator: values.votingType === 'fraction' ? values.votingRatio[0] : values.votingPercent,
        denominator: values.votingType === 'fraction' ? values.votingRatio[1] : 100,
        type: values.votingType,
      },
      votes_distribution: boardEvents.includes(values.eventType) ? 'HC' : values.votesDistribution,
      quorum: {
        numerator: values.quorumType === 'fraction' ? values.quorumRatio[0] : values.quorumPercent,
        denominator: values.quorumType === 'fraction' ? values.quorumRatio[1] : 100,
        type: values.quorumType,
        base: boardEvents.includes(values.eventType) ? 'HC' : values.votesDistribution,
      },
    };

    if (shareholderEvents.includes(values.eventType)) {
      data.share_classes = values.shareClasses;
    }

    axios.post(`/api/v1/company/${companyId}/gov-event-template/`, data)
      .then(
        ({ data: { object_id: objectId } }) => {
          window.location = `/company/${companyId}/templates/${objectId}/`;
        },
        ({ response: { data: errors } }) => { parseErrorResponse(errors, actions.setFieldError); },
      )
      .catch(console.error)
      .finally(() => { actions.setSubmitting(false); });
  };

  return (
    <Wizard
      initialValues={{
        nonFieldErrors: '',
        subject: '',
        date: dateToday(),
        time: roundedTime(),
        timeZone: companyTimezone,
        eventType: defaultEvent,
        location: '',
        votesDistribution: '',
        quorumType: 'percent',
        quorumRatio: [2, 3],
        quorumPercent: 0,
        votingType: 'percent',
        votingRatio: [2, 3],
        votingPercent: 0,
        shareClasses: [],
      }}
      validationSchema={validationArray}
      onSubmit={onSubmit}
        // eslint-disable-next-line no-return-assign
      ref={(stepperComponent) => (window.stepperComponent = stepperComponent)}
    >
      <Wizard.Page>
        {(form) => (
          <GeneralInfo
            isSubmitting={form.isSubmitting}
            eventTypesOptions={eventTypesOptions}
            setEventTypeState={(value) => { setEventType(value); }}
          />
        )}
      </Wizard.Page>
      {(shareholderEvents.includes(eventType)) && (
      <Wizard.Page>
        {(form) => (
          <ShareClassesEditFormPart
            allShareClasses={allShareClasses}
            values={form.values}
          />
        )}
      </Wizard.Page>
      )}
      <Wizard.Page>
        {(form) => (
          <Quorum
            isSubmitting={form.isSubmitting}
            eventType={eventType}
            quorumType={form.values.quorumType}
            votingType={form.values.votingType}
          />
        )}
      </Wizard.Page>
      <Wizard.Page>
        {(form) => (
          <DateLocation
            isSubmitting={form.isSubmitting}
            eventType={eventType}
            timeZonesOptions={timeZonesOptions}
          />
        )}
      </Wizard.Page>
    </Wizard>
  );
};

GovEventTemplateCreateForm.defaultProps = {
  companyTimezone: '',
  templateCategory: 'all',
  defaultEvent: shareholderMeeting,
};

GovEventTemplateCreateForm.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  companyTimezone: PropTypes.string,
  templateCategory: PropTypes.string,
  defaultEvent: PropTypes.string,
};

export default GovEventTemplateCreateForm;
