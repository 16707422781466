import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import { getUrl } from 'utils/urls';
import PageNotFoundContent from 'core/page/contentParts/404';
import {
  UtilityTokenCreateView,
  UtilityTokensListView,
  UtilityTokenDetailView,
  UtilityTokenMintView,
} from 'liabilities/utilityTokens/views';
import { FullPageView } from 'core/page';

const UtilityTokensLiabilityView = () => (
  <FullPageView>
    <Router basename={getUrl('/liabilities/utility/')}>
      <Routes>
        <Route path="/">
          <Route index element={<UtilityTokensListView />} />
          <Route path="create/" element={<UtilityTokenCreateView />} />
          <Route path=":tokenId/" element={<UtilityTokenDetailView />} />
          <Route path=":tokenId/mint/" element={<UtilityTokenMintView />} />
        </Route>
        <Route path="*" element={<PageNotFoundContent />} />
      </Routes>
    </Router>
  </FullPageView>
);

export default UtilityTokensLiabilityView;
