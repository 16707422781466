/* Remove document signatories component */
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { ModalWithContent as Modal } from '@trustwise/design-system';
import { parseErrorResponse } from 'core/forms/utils';
import { getApiUrl } from 'utils/urls';
import { FieldErrors, SubmitButton } from 'core/forms/fields';
import axios from 'core/axios';


const RemoveSignatory = ({ isOpen, onClose, modalId, signatoryName, onSuccessResponse, signatoryId }) => {
  const { documentId } = useParams();

  const onSubmit = (actions) => (
    axios.delete(getApiUrl(`/new-documents/${documentId}/signatories/${signatoryId}/`))
      .then(
        onSuccessResponse(),
        ({ response: { data: errors } }) => { parseErrorResponse(errors, actions.setFieldError); },
      )
      .catch(console.error)
      .finally(() => { actions.setSubmitting(false); })
  );

  return (
    <Modal
      id={modalId}
      isOpen={isOpen}
      onClose={onClose}
      title={gettext('Remove signatory')}
    >
      {signatoryName ? (
        <Formik
          initialValues={{
            nonFieldErrors: '',
          }}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <FieldErrors name="nonFieldErrors" />
              <span className="text-small">
                {gettext(`Are you sure you want to remove ${signatoryName} from document signatories ?`)}
              </span>
              <SubmitButton disabled={isSubmitting}>{gettext('Remove')}</SubmitButton>
            </Form>
          )}
        </Formik>
      ) : <div>{gettext('Signatory cannot be deleted.')}</div>}
    </Modal>
  );
};

RemoveSignatory.defaultProps = {
  isOpen: false,
};

RemoveSignatory.propTypes = {
  modalId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  signatoryName: PropTypes.string.isRequired,
  signatoryId: PropTypes.number.isRequired,
  onSuccessResponse: PropTypes.func.isRequired,
};

export default RemoveSignatory;
