/**
 * Section of beneficial owners report form with checkbox, list and form to add new beneficial owners.
 *
 * TODO consider generic onSubmit and onRemove props instead of passing addBeneficialOwner and removeBeneficialOwner
 */

import PropTypes from 'prop-types';

import { FieldArray } from 'formik';
import { Button, CheckboxField, CountrySelectField, TextField } from '../../core/forms/fields';
import { textInputs } from '../constants/boReporting';
import { Icon } from '../../components/common/icon';


const BeneficialOwnersFormSection = ({ fieldName, collectionName, label, values, formEnabled }) => (
  <>
    <CheckboxField name={fieldName} label={label} />
    {formEnabled && (
      <div className="form-nested">
        {values[fieldName] && (
        <>
          <div className="font-italic margin-y">
            {gettext('Please specify the name(s) and address(es) of all beneficial owner(s) (individuals only):')}
          </div>
          <FieldArray
            name={collectionName}
            render={(arrayHelpers) => {
              const getId = (name, index) => (fieldName ? `${fieldName}_${name}_${index}` : `${name}_${index}`);
              return (
                <>
                  {values[collectionName].map((_collection, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={`${collectionName}_${index}`} className="bottom-padding">
                      <hr className="dotted-border-bottom" />
                      <Icon
                        className="justify-content-flex-end primary-color"
                        name="trash"
                        onClick={() => arrayHelpers.remove(index)}
                      />
                      {textInputs.map(
                        (input) => {
                          const inputName = input.name;
                          return (
                            <TextField
                              key={inputName}
                              label={input.label}
                              name={`${collectionName}.${index}.${inputName}`}
                              id={getId(inputName, index)}
                              placeholder={input.placeholder}
                            />
                          );
                        },
                      )}
                      <CountrySelectField
                        name={`${collectionName}.${index}.country_code`}
                        id={getId('country_code', index)}
                      />
                    </div>
                  ))}
                  <Button
                    className="button-outlined"
                    onClick={() => arrayHelpers.push({
                      name: '',
                      address: '',
                      additional_address: '',
                      postal_code: '',
                      city: '',
                      country_code: '',
                    })}
                  >
                    {gettext('Add new beneficial owner')}
                  </Button>
                </>
              );
            }}
          />
          <br />
        </>
        )}
      </div>
    )}
  </>
);

const collectionPropType = PropTypes.arrayOf(
  PropTypes.shape({
    address: PropTypes.string,
    additional_address: PropTypes.string,
    city: PropTypes.string,
    country_code: PropTypes.string,
    name: PropTypes.string,
    postal_code: PropTypes.string,
  }),
);

BeneficialOwnersFormSection.defaultProps = {
  formEnabled: true,
};

BeneficialOwnersFormSection.propTypes = {
  fieldName: PropTypes.oneOf([
    'actingOnItsOwn',
    'actingInConcert',
    'actingAsTrustee',
  ]).isRequired,
  collectionName: PropTypes.oneOf([
    'actingOnItsOwnCollection',
    'actingInConcertCollection',
    'actingAsTrusteeCollection',
  ]).isRequired,
  formEnabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  values: PropTypes.shape({
    willHaveSignificantControl: PropTypes.string,
    actingOnItsOwn: PropTypes.bool,
    actingInConcert: PropTypes.bool,
    actingAsTrustee: PropTypes.bool,
    actingOnItsOwnCollection: collectionPropType,
    actingInConcertCollection: collectionPropType,
    actingAsTrusteeCollection: collectionPropType,
    legalConfirmation: PropTypes.bool,
  }).isRequired,
};

export default BeneficialOwnersFormSection;
