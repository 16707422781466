import PropTypes from 'prop-types';
import { Icon } from 'components/common/icon';


const ThumbnailPreview = ({ file }) => {
  if (file.isImage) {
    return <img alt="" src={file.fetchUrl} />;
  }
  if (file.thumbnail && file.isPdf) {
    return <img alt="" src={`data:;base64,${file.thumbnail}`} />;
  }
  return <Icon weight="regular" name={`file-${file.iconName}`} className="icon" />;
};

ThumbnailPreview.propTypes = {
  file: PropTypes.shape({
    isImage: PropTypes.bool,
    isPdf: PropTypes.bool,
    iconName: PropTypes.string,
    fetchUrl: PropTypes.string.isRequired,
    thumbnail: PropTypes.string,
  }).isRequired,
};

export default ThumbnailPreview;
