import { useState, useEffect } from 'react';

import { PaymentTokenSummaryWidget } from '@trustwise/design-system';
import { getApiUrl } from 'utils/urls';
import { getGlobalContext } from 'core/globals';
import { PaymentTokenModel } from 'investments/paymentTokens/models';
import axios from 'core/axios';
import { redirectTo } from 'core/utils';

const PaymentTokensWidget = () => {
  const { activeEntity: { baseCurrency } } = getGlobalContext();

  const [tokens, setTokens] = useState();

  useEffect(() => {
    axios.get(getApiUrl('/assets/payment-tokens/'))
      .then(({ data }) => { setTokens(PaymentTokenModel.fromResponse(data.results)); })
      .catch(console.error);
  }, []);

  return (
    <PaymentTokenSummaryWidget
      tokens={tokens}
      baseCurrency={baseCurrency}
      onManage={() => redirectTo('/assets/cash')}
    />
  );
};

export default PaymentTokensWidget;
