/* A simple row. */


const RowOptionToggle = () => {
  const toggleOptions = (e) => {
    const oldActive = document.getElementsByClassName('move-left');
    const currentRow = e.target.parentElement.parentElement.parentElement;

    for (let i = 0; i < oldActive.length; i += 1) {
      if (oldActive[i] !== currentRow) {
        oldActive[i].classList.remove('move-left');
      }
    }
    currentRow.classList.toggle('move-left');
  };

  return (
    <i className="row-options-toggle fa text-tiny fa-ellipsis-h left-padding" onClick={toggleOptions} />
  );
};

export default RowOptionToggle;
