/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import { Component } from 'react';

/**
 * @deprecated DO NOT USE
 */
export default class FieldErrors extends Component {
  render() {
    const { errors } = this.props;

    if (!errors) return null;

    return errors.map((e, idx) => <span key={idx} className="form-error is-visible">{e}</span>);
  }
}
