/* Voting form component */
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import DOMPurify from 'dompurify';
import { Formik, ErrorMessage, Form } from 'formik';
import * as Yup from 'yup';
import { SubmitButton, RadioButtonGroup } from 'core/forms/fields';
import { getApiUrl, getUrl } from 'utils/urls';
import { parseErrorResponse } from 'core/forms/utils';
import axios from 'core/axios';
import FormErrors from '../components/common/formikErrors';


const AgendaVotingForm = ({ agendaItemId, accountId }) => {
  const [votingChoices, setVotingChoices] = useState([]);
  const [agendaTitle, setAgendaTitle] = useState('');
  const [agendaDescription, setAgendaDescription] = useState('');
  const currentPath = window.location.pathname;

  useEffect(() => {
    axios.get(
      getApiUrl(`/accounts/${accountId}/agenda-items/${agendaItemId}/`),
    ).then(
      ({ data: { voting_options: votingOptions, title, desc } }) => {
        setVotingChoices(votingOptions);
        setAgendaTitle(title);
        setAgendaDescription(desc);
      },
    )
      .catch(console.error);
  }, [agendaItemId, accountId]);

  const onSubmit = async (values, actions) => {
    axios.post(
      getApiUrl(`/accounts/${accountId}/agenda-items/${agendaItemId}/vote/`),
      { vote_id: values.votingChoices },
    )
      .then(
        ({ data: { tx_hash: txHash } }) => {
          window.location = getUrl(`/sign/${txHash}/`, { redirect_url: currentPath });
        },
        ({ response: { data: errors } }) => { parseErrorResponse(errors, actions.setFieldError); },
      )
      .catch(console.error)
      .finally(() => { actions.setSubmitting(false); });
  };

  return (
    <>
      <span className="font-medium">{agendaTitle}</span>
      <br />
      {/* eslint-disable-next-line react/no-danger */}
      <span className="description-style" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(agendaDescription) }} />
      <Formik
        initialValues={{
          nonFieldErrors: '',
          votingChoices: '',
        }}
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={Yup.object({
          votingChoices: Yup.string()
            .max(128, gettext('Line is too long'))
            .required(gettext('Required')),
        })}
      >
        {({ isSubmitting }) => (
          <Form>
            <ErrorMessage component={FormErrors} name="nonFieldErrors" />
            <RadioButtonGroup
              className="justify-content-space-evenly align-flex-items-center"
              name="votingChoices"
              options={votingChoices}
              groupId={agendaItemId}
            />
            <SubmitButton disabled={isSubmitting}>{gettext('Submit vote')}</SubmitButton>
          </Form>
        )}
      </Formik>
    </>
  );
};

AgendaVotingForm.propTypes = {
  agendaItemId: PropTypes.string.isRequired,
  accountId: PropTypes.string.isRequired,
};

export default AgendaVotingForm;
