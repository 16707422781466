import { camelCase } from 'lodash';

import axios from 'axios';
import { convertKeysCase } from 'core/utils';
import { UtilityTokenIssuerModel } from 'liabilities/utilityTokens/model';
import { getApiUrl } from 'utils/urls';

export const onUtilityTokenFetch = (tokenId, setToken) => {
  axios.get(getApiUrl(`/utility-tokens/${tokenId}/`))
    .then(({ data }) => {
      setToken(new UtilityTokenIssuerModel(convertKeysCase(data, camelCase)));
    })
    .catch(console.error);
};
