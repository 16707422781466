import { getGlobalContext } from 'core/globals';

export const handleTransactionCreation = (txHash, redirectUrl) => {
  const { activeEntity: { id, name } } = getGlobalContext();
  const { unsignedTransaction: { signingDispatch } } = window.trustwise;

  if (signingDispatch) {
    signingDispatch({
      type: 'created',
      data: {
        unsignedHash: txHash,
        accountId: id,
        accountName: name,
        redirectUrl,
      },
    });
  }
};

export const getTxStatus = (status) => ({
  isCompleted: status === 'completed',
  isProcessing: status === 'processing',
});

export const updateUnsignedTransaction = (value) => {
  // Update window.trustwise.unsignedTransaction
  const { unsignedTransaction } = window.trustwise;
  window.trustwise.unsignedTransaction = { ...unsignedTransaction, ...value };

  // Create a new event
  const event = new CustomEvent('trustwise.unsignedTransaction', {
    detail: { value },
  });

  // Dispatch the event
  window.dispatchEvent(event);
};
