/**
 * Display names of files selected for upload
 *
 */
export const displayFileInfo = {
  init() {
    $('[data-enhanced-upload]').on('change', (e) => this.updateInfo(e));
  },
  updateInfo(e) {
    const input = $(e.target);
    const info = $(`#${input.attr('id')}_info`);
    const fileNames = $.map(input.prop('files'), (f) => f.name);
    if (fileNames) {
      info.data('original-text', info.text());
      info.html($('<ol/>', {
        class: fileNames.length > 1 ? 'numbered text-smaller' : '',
        html: `<li>${fileNames.join('</li><li>')}</li>`,
      }));
    } else {
      info.text(info.data('original-text'));
    }
  },
};

/**
 * Prevent double submission
 *
 * @todo re-enable after coming back to the page via browser/js back button
 * @todo get string from localized dictionary
 */
export const preventDoubleSubmission = {
  init(args) {
    this.setup(args);
    $(this.args.forms_selector).submit((e) => this.on.submit(e.target));
  },
  setup(args) {
    this.args = {
      forms_selector: '[data-prevent-double-submit]',
      submitted_form_class: 'submitted',
      submit_button_alt_text: gettext('Loading...'),
      submit_button_disable: true,
      ...args,
    };
    this.is = {
      submitted: false,
    };
    this.on = {
      submit: (form) => {
        if (this.is.submitted) {
          return false;
        }
        this.is.submitted = true;
        const newForm = $(form);
        newForm.addClass(this.args.submitted_form_class);
        const submitButton = $('[type="submit"]', newForm);
        if (this.args.submit_button_disable) submitButton.attr('disabled', true);
        if (this.args.submit_button_alt_text) submitButton.text(this.args.submit_button_alt_text);
        return true;
      },
    };
  },
};
