import { capitalize } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';

import { ArtworkRegistrationForm } from '@trustwise/design-system';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import { onSubmitArtwork } from './actions';

const RegisterObjectFormView = () => {
  const navigate = useNavigate();
  const { catalogType, objectType } = useParams();

  const basePath = `/${catalogType}/${objectType}/`;
  const submitPath = `/services/registered-objects${basePath}`;

  const onCancel = () => navigate(basePath);

  let form = null;

  switch (catalogType) {
    case 'artworks':
      form = (
        <ArtworkRegistrationForm
          onCancel={onCancel}
          onSubmit={(values, actions) => onSubmitArtwork({
            values, actions, submitPath, navigatePath: submitPath,
          })}
        />
      );
      break;
    default:
      throw new Error('Unexpected catalog type');
  }

  return (
    <PageContent
      fullscreen
      pageHeading={gettext(capitalize(catalogType))}
      headerLeft={<BackButton onClick={onCancel} />}
    >
      {form}
    </PageContent>
  );
};

export default RegisterObjectFormView;
