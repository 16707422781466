import PropTypes from 'prop-types';
import { DateField, TextField, ReactSelectField, FileField } from 'core/forms/fields';
import { getDocumentTypeOptions } from 'media/constants/documentTypes';

const MAX_FILES = 10;


const DocumentUpload = ({
  showDocumentType,
  showDocumentDate,
  documentDateFieldName,
  documentSubjectFieldName,
  documentTypeFieldName,
}) => (
  <div className="document-upload-form">
    <div className="document-upload-section">
      <header className="section-header">
        <h3 className="title">
          {gettext('Properties')}
        </h3>
      </header>
      <TextField label={gettext('Subject')} name={documentSubjectFieldName} />
      {showDocumentDate && (
        <DateField label={gettext('Document date')} name={documentDateFieldName} />
      )}
      {showDocumentType && (
        <ReactSelectField
          label={gettext('Document type')}
          name={documentTypeFieldName}
          getOptionLabel={(option) => option.text}
          getOptionValue={(option) => option.id}
          options={getDocumentTypeOptions()}
        />
      )}
    </div>
    <FileField
      name="files"
      maxFiles={MAX_FILES}
    />
  </div>
);

DocumentUpload.defaultProps = {
  documentDateFieldName: 'date',
  documentSubjectFieldName: 'subject',
  documentTypeFieldName: 'documentType',
  showDocumentType: false,
  showDocumentDate: true,
};

DocumentUpload.propTypes = {
  documentDateFieldName: PropTypes.string,
  documentSubjectFieldName: PropTypes.string,
  documentTypeFieldName: PropTypes.string,
  showDocumentType: PropTypes.bool,
  showDocumentDate: PropTypes.bool,
};

export default DocumentUpload;
