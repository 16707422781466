import { camelCase } from 'lodash';

import { convertKeysCase } from 'core/utils';
import { UtilityTokenTransactionModel } from 'liabilities/utilityTokens/model';
import convertResponseToModel from 'utils/responseToModel';
import { UtilityTokenAssetModel } from './models/utilityTokenAsset';

const reducer = (currentState, action) => {
  const { data, type } = action;

  switch (type) {
    case 'fetchDetails':
      return {
        ...currentState,
        asset: new UtilityTokenAssetModel(convertKeysCase(data, camelCase)),
      };

    case 'fetchTransactions':
      return {
        ...currentState,
        transactions: convertResponseToModel(data, UtilityTokenTransactionModel),
      };

    default:
      return currentState;
  }
};

export default reducer;
