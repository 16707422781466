
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import RemoveSignatory from 'media/signatories/remove';
import { SignerContractModel, SignatoryModel } from 'media/models';
import Tooltip from 'core/tooltip';
import { BaseButton } from '../../core/forms/fields';


const DocumentSignatory = ({ signatory, signerContract, dispatch }) => {
  const { documentId } = useParams();
  const [showSignatoryDeleteModal, setShowSignatoryDeleteModal] = useState(0);
  const getSignatoryStatus = () => {
    if (signatory.hasSigned) {
      return gettext('Signed');
    } if (signerContract.address) {
      return gettext('Invited');
    }
    return '';
  };

  return (
    <>
      <div className="no-overflow">
        <div>{`${signatory.name} (${signatory.role})`}</div>
        <div className="text-tiny">
          {signatory.signedBy.length > 0 && (
            `${gettext('Signed by')} ${signatory.signedBy.map((signer) => signer.name).join(',')}`
          )}
        </div>
      </div>
      <div className="no-wrap">
        {getSignatoryStatus()}
        {signerContract.isEditable && (
          <Tooltip actions={[{
            key: `delete-${signatory.id}`,
            content: (
              <BaseButton className="text-tiny dark-grey" onClick={() => { setShowSignatoryDeleteModal(signatory.id); }}>
                <i className="fa right-padding text-tiny fa-user-times" />
                {gettext('Remove signatory')}
              </BaseButton>
            ),
          }]}
          />
        )}
      </div>
      <RemoveSignatory
        modalId={`signatoryDeleteModal${signatory.id}`}
        isOpen={showSignatoryDeleteModal === signatory.id}
        onClose={() => { setShowSignatoryDeleteModal(0); }}
        signatoryName={signatory.name}
        signatoryId={signatory.id}
        onSuccessResponse={() => {
          setShowSignatoryDeleteModal(0);
          dispatch({ data: { id: signatory.id, documentId }, type: 'removeSignatory' });
        }}
      />
    </>
  );
};

DocumentSignatory.defaultProps = {
  signerContract: null,
};

DocumentSignatory.propTypes = {
  signerContract: SignerContractModel.propType,
  signatory: SignatoryModel.propType.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default DocumentSignatory;
