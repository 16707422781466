import { RefetchQueryFilters, useQueryClient } from '@tanstack/react-query';
import { updateUnsignedTransaction } from 'core/page/transactionSigning/utils';
import { useEffect, useState } from 'react';

export const useRefetchOnTxProcessed = (filters: RefetchQueryFilters = { exact: true }) => {

  const queryClient = useQueryClient();
  const [isTxProcessed, setIsTxProcessed] = useState(window.trustwise.unsignedTransaction.isProcessed);
  const [refetchQueryKeys, setRefetchQueryKeys] = useState<string[] | undefined>();
  const [refetchCompleted, setRefetchCompleted] = useState(false);
  const stringifiedFilters = JSON.stringify(filters);

  useEffect(() => {
    const listener = () => {
      setIsTxProcessed(window.trustwise.unsignedTransaction.isProcessed);
    };

    window.addEventListener('trustwise.unsignedTransaction', listener);

    return () => {
      window.removeEventListener('trustwise.unsignedTransaction', listener);
    };
  }, []);

  useEffect(() => {
    if (!isTxProcessed) {
      return;
    }
    if (refetchQueryKeys?.length) {
      const parsedFilters = JSON.parse(stringifiedFilters);
      queryClient.refetchQueries({ queryKey: refetchQueryKeys, ...parsedFilters });
      setRefetchQueryKeys(undefined);
      setRefetchCompleted(true);
      updateUnsignedTransaction({ isProcessed: false });
    } else if (!refetchCompleted) {
      queryClient.refetchQueries();
      updateUnsignedTransaction({ isProcessed: false });
    }
  }, [isTxProcessed, queryClient, refetchQueryKeys, refetchCompleted, stringifiedFilters]);

  return setRefetchQueryKeys;

};
