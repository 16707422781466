import { Model } from 'core/model';
import { CHARGING_PERIODS } from 'pricing/consts';

/**
 * Base Product Offering Model
 *
 * Props under restProps are:
 * id, name, isPercentageBased, quotationDisplay, group,
 * isRecurring, isHighlighted, usageLimit, isSubscription, priceType
 */
export default class PricingPlanItemModel extends Model {
  constructor({ price, minPrice, chargingPeriod, ...restProps }) {
    super(restProps);
    this.price = price ? Number(price) : null;
    this.minPrice = minPrice ? Number(minPrice) : null;
    this.chargingPeriod = CHARGING_PERIODS[chargingPeriod];
  }
}
