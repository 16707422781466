import { convertKeysToCamelCase, convertNestedKeysToCamelCase } from 'core/utils';
import convertResponseToModel from 'utils/responseToModel';
import { GovernanceEventDetailModel, AgendaItemModel } from './models';
import { SimpleDocumentModel } from '../media/models/document';

const reducer = (currentState, action) => {
  const { data, type } = action;

  switch (type) {
    case 'fetchMeeting': {

      return {
        ...currentState,
        meeting: new GovernanceEventDetailModel(convertKeysToCamelCase(data)),
      };
    }

    case 'updateMeeting': {
      const { meeting } = currentState;
      const caseConvertedData = convertKeysToCamelCase(data);
      Object.entries(caseConvertedData).forEach(([key, value]) => {
        meeting[key] = value;
        if (key === 'dateTime') {
          meeting.time = { dateTime: value, timeZone: caseConvertedData.timeZone };
        }
      });
      return {
        ...currentState,
        meeting,
      };
    }

    case 'fetchAgenda':
      return {
        ...currentState,
        agendaItems: convertResponseToModel(data, AgendaItemModel),
      };

    case 'fetchSuggestedAgenda':
      return {
        ...currentState,
        suggestedAgenda: data.map((item) => convertNestedKeysToCamelCase(item)),
      };

    case 'fetchShareClasses': {
      const { meeting } = currentState;
      meeting.shareClasses = data;
      return {
        ...currentState,
        meeting,
      };
    }

    case 'fetchInvitees': {
      const { meeting } = currentState;
      meeting.invitees = data;
      return {
        ...currentState,
        meeting,
      };
    }

    case 'sortAgenda': {
      const sortedList = data.map((updatedId) => currentState.agendaItems.find((item) => updatedId === item.id));
      return {
        ...currentState,
        agendaItems: sortedList,
      };
    }

    case 'addAgendaItem':
      return {
        ...currentState,
        agendaItems: [...currentState.agendaItems, new AgendaItemModel(data)],
      };

    case 'updateAgendaItem': {
      const { agendaItems } = currentState;
      const updatedItemIndex = agendaItems.findIndex((item) => item.id === data.id);
      if (updatedItemIndex === -1) {
        console.warn(`Could not update agenda item ${data.id} because it's not present in current agenda items.`);
        return currentState;
      }
      const agendaItem = agendaItems[updatedItemIndex];
      const caseConvertedData = convertKeysToCamelCase(data);
      Object.entries(caseConvertedData).forEach(([key, value]) => {
        agendaItem[key] = value;
      });

      return {
        ...currentState,
        agendaItems,
      };
    }

    case 'deleteAgendaItem': {
      const updatedList = currentState.agendaItems.filter((item) => item.id !== data);
      return {
        ...currentState,
        agendaItems: updatedList,
      };
    }

    case 'indicateParticipation': {
      const { meeting } = currentState;
      meeting.indicatedParticipation = true;

      return {
        ...currentState,
        meeting,
      };
    }

    case 'checkIn': {
      const { meeting } = currentState;
      meeting.checkedIn = true;

      return {
        ...currentState,
        meeting,
      };
    }

    case 'resolveAgendaItem': {
      const updatedItemIndex = currentState.agendaItems.findIndex((item) => item.id === data.id);
      if (updatedItemIndex === -1) {
        console.warn(`Could not mark item ${data.id} as resolved because it's not present in current agenda items.`);
        return currentState;
      }
      const updatedItems = [...currentState.agendaItems];
      updatedItems[updatedItemIndex].isResolved = true;
      return {
        ...currentState,
        agendaItems: updatedItems,
      };
    }

    case 'introTextSeen': {
      const { meeting } = currentState;
      meeting.introTextSeen = true;

      return {
        ...currentState,
        meeting,
      };
    }

    case 'fetchProxyVotesSummary': {
      const { proxyVoting } = currentState;

      return {
        ...currentState,
        proxyVoting: {
          ...proxyVoting,
          votesSummary: data,
        },
      };
    }

    case 'fetchProxyVotesSettings': {
      const { proxyVoting } = currentState;
      const {
        board_proposal: boardProposal,
        shareholder_proposal: shareholderProposal,
      } = data;

      return {
        ...currentState,
        proxyVoting: {
          ...proxyVoting,
          settings: {
            proxyBoardProposal: boardProposal,
            proxyShareholderProposal: shareholderProposal,
          },
        },
      };
    }

    case 'updateDocuments': {
      const { meeting } = currentState;
      const updatedDocIndex = meeting.documents.findIndex((item) => item.id === data.id);
      if (updatedDocIndex === -1) {
        console.warn(`Could not update document ${data.id}.`);
        return currentState;
      }
      meeting.documents[updatedDocIndex] = SimpleDocumentModel.fromResponse(data);
      return {
        ...currentState,
        meeting,
      };
    }

    case 'addDocument': {
      const { meeting } = currentState;
      const newDocument = SimpleDocumentModel.fromResponse(data);
      meeting.documents = [
        ...meeting.documents,
        newDocument,
      ];
      return {
        ...currentState,
        meeting,
      };
    }

    case 'deleteDocument': {
      const { meeting } = currentState;
      const { id } = data;
      meeting.documents = meeting.documents.filter((doc) => doc.id !== id);
      return {
        ...currentState,
        meeting,
      };
    }

    default:
      return currentState;
  }
};

export default reducer;
