import { useFormikContext } from 'formik';
import { CheckboxField, FileField, TextField } from 'core/forms/fields';


const LegalDocuments = () => {
  const { values } = useFormikContext();
  return (
    <>
      <CheckboxField
        label={gettext('I am registering a company in foundation')}
        name="companyInFoundation"
      />
      {values.companyInFoundation ? (
        <>
          <p className="padded-y">
            {gettext('Please upload one of the following documents')}
          </p>
          <FileField
            name="intentionFiles"
            label={gettext(
              'Intended articles of association, contracts, bank account numbers and/or any similar proof of existence.',
            )}
            maxFiles={1}
          />
          <CheckboxField
            label={gettext(
              'I, the Registering User, assume full responsibility for any action on behalf of the company in foundation. '
              + 'The Registering User becomes personally fully liable for any fees incurred by the company in foundation. '
              + 'The Registering User must update company data and status '
              + 'as soon as the company is registered in the commercial registry.',
            )}
            name="registeringUserResponsibility"
          />
        </>
      ) : (
        <>
          <p className="padded-y">
            {gettext(
              '1. Please provide a link to a public registry '
              + 'or an excerpt of the commercial registry, not older than one month:',
            )}
          </p>
          <TextField
            name="publicRegistryLink"
            label={gettext('Link to a public registry:')}
          />
          <FileField
            name="excerptFiles"
            label={gettext('Excerpt of the commercial registry, not older than one month')}
            maxFiles={1}
          />
          <CheckboxField
            label={gettext("I don't have the right of a single signature for this company")}
            name="noSingleSignatureRight"
            className="top-margin"
          />
          {values.noSingleSignatureRight && (
            <>
              <p className="text-smaller">
                {gettext('2. Power of Attorney:')}
              </p>
              <FileField name="poaFiles" maxFiles={1} />
            </>
          )}
        </>
      )}
    </>
  );
};

export default LegalDocuments;
