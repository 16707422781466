import { getApiUrl } from 'utils/urls';
import axios from 'core/axios';
import { TRANSFER_COMPLETED } from 'businessTransactions/consts';

export const onTransfersFetch = ({ portfolioId, tokenId, dispatch }) => {
  const queryArgs = { portfolio_id: portfolioId, token_id: tokenId, stage: TRANSFER_COMPLETED };
  axios.get(getApiUrl('/assets/transfers/', queryArgs))
    .then(({ data }) => dispatch({ type: 'fetchTransactions', data }))
    .catch(console.error);
};

export const getAssetDetail = ({ portfolioId, tokenId, dispatch }) => (
  axios.get(getApiUrl(`/assets/artworks/${portfolioId}/${tokenId}/`))
    .then(({ data }) => dispatch({ type: 'fetchAssetDetail', data }))
    .catch(console.error)
);

export const getObjectDetail = ({ objectPath, dispatch }) => axios.get(getApiUrl(objectPath))
  .then(({ data }) => dispatch({ type: 'fetchObject', data }))
  .catch(console.error);
