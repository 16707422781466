import { snakeCase } from 'lodash';
import PropTypes from 'prop-types';

import { ShareholdersExcerptModal as Modal } from '@trustwise/design-system';
import axios from 'core/axios';
import { getApiUrl } from 'utils/urls';
import { convertKeysCase } from 'core/utils';
import { parseErrorResponse } from 'core/forms/utils';
import { SimpleLegalEntityModel } from '../../contacts/models/legalEntity';

const ShareholdersExcerptModal = ({
  isOpen,
  onClose,
  shareholder,
  submitUrlPath,
}) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    shareholder={shareholder}
    onExcerptGenerate={(values, actions, setExcerptStatus) => {
      axios.post(getApiUrl(submitUrlPath), convertKeysCase(values, snakeCase))
        .then(
          () => { setExcerptStatus('pending'); },
          ({ response: { data: errors } }) => {
            parseErrorResponse(errors, actions.setFieldError);
            actions.setSubmitting(false);
          },
        )
        .catch((error) => {
          console.error(error);
          setExcerptStatus('failed');
        });
    }}
  />
);

ShareholdersExcerptModal.defaultProps = {
  shareholder: undefined,
};

ShareholdersExcerptModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  shareholder: SimpleLegalEntityModel,
  submitUrlPath: PropTypes.string.isRequired,
};


export default ShareholdersExcerptModal;
