import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { DataRoomsListView as DataRoomsListViewDS, PricingTriggersKeys } from '@trustwise/design-system';
import { getUrl } from 'utils/urls';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import { getGlobalContext } from 'core/globals';
import { Features } from 'core/page/features';
import { getRouterLinkInteraction } from 'core/utils';
import { getTriggersStatus, getTriggerStatusMessage } from 'pricing/utils';
import { TriggerAllowanceStatuses } from 'pricing/consts';
import { getDataRooms } from './actions';

export const DataRoomsListView = () => {
  const { activeEntity: { allowedFeatures } } = getGlobalContext();
  const hasCreateFeature = useMemo(() => allowedFeatures.includes(Features.DATA_ROOM_CREATOR), [allowedFeatures]);

  const { data: dataRooms } = useQuery({
    queryKey: ['dataRooms'],
    queryFn: getDataRooms,
  });

  const { data: creationTriggerStatus } = useQuery({
    queryKey: ['dataRooms', 'creationTriggerStatus'],
    queryFn: () => getTriggersStatus({ triggersKeys: [
      PricingTriggersKeys.DATA_ROOM_DOCUMENTS_CREATION,
      PricingTriggersKeys.DATA_ROOM_CREATION,
    ] }),
    enabled: hasCreateFeature,
  });

  const triggerStatusMessage = useMemo(() => getTriggerStatusMessage(creationTriggerStatus), [creationTriggerStatus]);

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Data Rooms')}
      headerLeft={<BackButton href={getUrl()} />}
    >
      <DataRoomsListViewDS
        dataRooms={dataRooms}
        newRoomInteraction={
          hasCreateFeature ? {
            ...getRouterLinkInteraction('create/'),
            infoMessage: triggerStatusMessage,
            disabled: creationTriggerStatus !== TriggerAllowanceStatuses.ALLOWED,
          } : undefined
        }
        onDataRoomClick={(id: number) => getRouterLinkInteraction(`${id}/`)}
      />
    </PageContent>
  );
};
