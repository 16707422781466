import { getGlobalContext } from 'core/globals';
import { Model } from 'core/model';

const {
  activeEntity: { id: activeEntityId },
} = getGlobalContext();

/**
 * Asset Transaction Model
 */
export class AssetTransactionModel extends Model {
  constructor({
    id, dateTime, amount, sender, ...restProps
  }) {
    super(restProps);
    this.id = id;
    this.datetime = new Date(dateTime);
    this.units = activeEntityId === sender.id ? -1 * amount : amount;
  }
}
