import { useQuery } from '@tanstack/react-query';
import { useState, useEffect } from 'react';

import { EquitiesTable } from '@trustwise/design-system';
import { getApiUrl, getUrl } from 'utils/urls';
import { getLinkInteraction } from 'core/utils';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import axios from 'core/axios';
import { getGlobalContext } from 'core/globals';
import { getEquityList } from 'investments/equity/actions';
import InvestmentsDateFilterForm from '../InvestmentsDateFilterForm';
import AssetsAllocationChart from './parts/chart';
import AssetsValue from './parts/assetsValue';

export const EquityAssetsList = () => {
  const [totalAssetsValue, setTotalAssetsValue] = useState(undefined);
  const [editDate, setEditDate] = useState(false);
  const { activeEntity: { baseCurrency } } = getGlobalContext();

  const urlSearch = new URL(window.location.href).search;

  const { data: equities } = useQuery({
    queryKey: ['equityAssets'],
    queryFn: () => getEquityList(urlSearch),
  });

  useEffect(() => {
    axios.get(getApiUrl('/assets/equity/total-assets-value/', urlSearch))
      .then(({ data }) => { setTotalAssetsValue(data); })
      .catch(console.error);
  }, [urlSearch]);

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Equity Investments')}
      headerLeft={<BackButton href={getUrl()} />}
      actions={[
        {
          type: 'button',
          onClick: () => { setEditDate(!editDate); },
          icon: 'calendar-day',
          label: gettext('As per date'),
        },
      ]}
    >
      <>
        <div style={{ marginBottom: '40px' }}>
          <InvestmentsDateFilterForm edit={editDate} />
          <AssetsValue totalAssetsValue={totalAssetsValue} currency={baseCurrency} />
          <AssetsAllocationChart baseCurrency={baseCurrency} urlSearch={urlSearch} />
        </div>
        <EquitiesTable
          equities={equities}
          baseCurrency={baseCurrency}
          onEquityClick={(id, portfolioId) => getLinkInteraction(getUrl(`/assets/equity/${portfolioId}/${id}/`))}
        />
      </>
    </PageContent>
  );
};
