import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ProductOfferingTileList } from '@trustwise/design-system';
import axios from 'core/axios';
import { getApiUrl } from 'utils/urls';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import { ProductOfferingModel } from 'pricing/models';
import { ProductOfferingsContext } from 'pricing/productOffering/context';

const ProductOfferingsList = () => {
  const navigate = useNavigate();
  const { state: routerState } = useLocation();
  const navigateOptions = { state: routerState };

  const {
    state: { productOfferings },
    dispatch,
  } = useContext(ProductOfferingsContext);

  useEffect(() => {
    axios.get(getApiUrl('/product-offerings/'))
      .then(({ data: { results } }) => dispatch({ type: 'fetchList', data: results }))
      .catch(console.error);
  }, [dispatch]);

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Browse Services')}
      headerLeft={<BackButton onClick={() => { navigate('/', navigateOptions); }} />}
    >
      <ProductOfferingTileList
        offeringItems={ProductOfferingModel.toArrayAsObject(productOfferings)}
        onTileClick={(id) => { navigate(`/product-offerings/${id}/`, navigateOptions); }}
      />
    </PageContent>
  );
};

export default ProductOfferingsList;
