import { useLocation, useParams } from 'react-router-dom';

import { getApiUrl } from 'utils/urls';
import BaseAgendaItemUpdate from './baseUpdate';


const AgendaItemCreate = () => {
  const { eventId } = useParams();
  const { state: { event: { eventType } } } = useLocation();
  return (
    <BaseAgendaItemUpdate eventType={eventType} submitUrl={getApiUrl(`/meetings/${eventId}/agenda-items/`)} />
  );
};

export default AgendaItemCreate;
