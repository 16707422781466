/* Governance event related constants */

const boardMeeting = 'BM';
const boardResolution = 'BR';
const shareholderMeeting = 'SM';
const shareholderResolution = 'SR';
const resolutions = [shareholderResolution, boardResolution];
const shareholderEvents = [shareholderResolution, shareholderMeeting];
const boardEvents = [boardMeeting, boardResolution];
const votesDistributionOptions = [
  { value: 'CA', label: gettext('Capital') },
  { value: 'NS', label: gettext('Number of shares') },
];
const quorumTypesChoices = [
  { value: 'percent', label: gettext('Percent') },
  { value: 'fraction', label: gettext('Fraction') },
];
const eventParticipantRolesValues = ['CR', 'SM'];
const eventParticipantRoles = [
  { value: 'CR', label: gettext('Chairman') },
  { value: 'SM', label: gettext('Secretary of minutes') },
];

const boardEventTypesOptions = [
  { value: boardMeeting, label: gettext('Board Meeting') },
  { value: boardResolution, label: gettext('Board Resolution') },
];

const shareholderEventTypesOptions = [
  { value: shareholderMeeting, label: gettext('Shareholders Meeting') },
  // { value: shareholderResolution, label: gettext('Shareholders Resolution') },
];

const eventTypesOptions = [
  { value: boardMeeting, label: gettext('Board Meeting') },
  { value: boardResolution, label: gettext('Board Resolution') },
  { value: shareholderMeeting, label: gettext('Shareholders Meeting') },
  // { value: shareholderResolution, label: gettext('Shareholders Resolution') },
];

const getEventTypesOptions = (category = 'all') => {
  switch (category) {
    case 'board':
      return boardEventTypesOptions;
    case 'shareholders':
      return shareholderEventTypesOptions;
    default:
      return eventTypesOptions;
  }
};

export {
  boardMeeting,
  boardResolution,
  shareholderMeeting,
  shareholderResolution,
  resolutions,
  shareholderEvents,
  boardEvents,
  votesDistributionOptions,
  quorumTypesChoices,
  eventParticipantRolesValues,
  eventParticipantRoles,
  getEventTypesOptions,
};
