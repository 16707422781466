import { useMemo, useReducer } from 'react';
import {
  Routes,
  Route,
} from 'react-router-dom';
import { ProductOfferingsContext } from 'pricing/productOffering/context';
import ProductOfferingsList from './views/list';
import ProductOfferingDetail from './views/detail';
import reducer from './reducer';


const ProductOfferingsNavigation = () => {
  const [state, dispatch] = useReducer(reducer, {
    productOfferings: [],
    offeringsDetails: {},
  });

  const contextProviderValue = useMemo(() => ({ state, dispatch }), [state]);

  return (
    <ProductOfferingsContext.Provider value={contextProviderValue}>
      <Routes>
        <Route path="/">
          <Route index element={<ProductOfferingsList />} />
          <Route path=":productOfferingId/" element={<ProductOfferingDetail />} />
        </Route>
      </Routes>
    </ProductOfferingsContext.Provider>
  );
};

export default ProductOfferingsNavigation;
