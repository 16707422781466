import { useQuery } from '@tanstack/react-query';

import { getDataSet } from './actions';

export const useDataSetQuery = (dataSetId: string) => (
  useQuery({
    queryKey: ['dataSet', dataSetId],
    queryFn: getDataSet,
  })
);
