/* File thumbnail component */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import axios from 'core/axios';
import { isNativeAppWebview } from 'utils/general';
import { getApiUrl } from 'utils/urls';
import { LinkWithContent } from 'core/link';
import { FileModel } from 'media/models';
import ThumbnailPreview from './thumbnailPreview';

const ThumbnailDetails = ({ file, dispatch }) => {
  const { search, pathname } = useLocation();
  const { documentId } = useParams();

  const fileDetailUrl = getApiUrl(`/new-documents/${documentId}/attachments/${file.id}/`);
  useEffect(() => {
    if (file.isPdf && !file.thumbnail) {
      axios.get(`${fileDetailUrl}thumbnail/${search}`)
        .then(({ data }) => {
          dispatch({ data: { thumbnail: data, fileId: file.id, documentId }, type: 'getThumbnail' });
        })
        .catch(console.error);
    }
  }, [fileDetailUrl, documentId, file.isPdf, file.id, search, dispatch, file.thumbnail]);

  const fileUrl = isNativeAppWebview ? file.nativeUrl : `${pathname}attachments/${file.id}/${search}`;

  return (
    <LinkWithContent className="row-flex padded-y" useRouterLink={!isNativeAppWebview} to={fileUrl}>
      <>
        <div className="document-attachment-wrapper">
          <ThumbnailPreview file={file} />
        </div>
        <div className="document-attachment-detail-wrapper text-smaller">
          <div className="no-overflow">{file.name}</div>
          <div className="no-overflow">
            {file.iconName}
          </div>
          {file.readableFileSize}
        </div>
      </>
    </LinkWithContent>
  );
};

ThumbnailDetails.propTypes = {
  file: FileModel.propType.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default ThumbnailDetails;
