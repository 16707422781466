import { extendObservable } from 'mobx';
import uuid from 'uuid';
import BaseForm from '../form';


export default class DocumentFileForm extends BaseForm {
  constructor({
    id = null,
    file = null,
    name = null,
    uid = null,
    src = null,
    documentId = null,
    removable = true,
  }) {
    super();
    this.uid = uid || uuid.v4();

    extendObservable(this, {
      isUploading: false,
      file,
      id,
      name,
      src,
      documentId,
      isRemovable: removable,
    });
  }

  get source() {
    return this.file ? this.file : this.src;
  }
}
