import { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import ContentLoadingMessage from 'utils/contentLoadingMessage';
import { PageContent } from 'core/page';
import { getApiUrl } from 'utils/urls';
import { ContactsModel, allStatuses } from 'contacts/models/contacts';
import { ListViewRow } from 'contacts/parts/listViewRow';
import ScrollableContent from 'core/page/scrollableContent';
import SearchForm from 'core/forms/searchForm';
import BackButton from 'core/page/parts/backButton';
import { ContactsContext } from 'contacts/context';


/**
  * List of contacts which sent requests to the user (i.e. the user is "legal entity")
  *
  * @todo DRY with RequestedList
*/
const RequestsList = ({
  state,
  isLoading,
  onInitialLoad,
  fetchMore,
  onSearchSubmit,
  contactsByStatuses,
}) => {
  const { pathname } = useLocation();
  const { indexPath } = useContext(ContactsContext);
  const { nextPage, contacts } = state;

  useEffect(() => {
    onInitialLoad(getApiUrl('/contacts/requests/'));
  }, [onInitialLoad]);

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Contact requests')}
      headerLeft={<BackButton href={indexPath} useRouterLink />}
    >
      <>
        <SearchForm onSubmit={({ searchString }) => {
          const queryParam = searchString ? { search: searchString } : {};
          onSearchSubmit(getApiUrl('/contacts/requests/', queryParam));
        }}
        />
        {contactsByStatuses.length > 0 ? (
          <ScrollableContent
            dataLength={contacts.length}
            fetchNext={fetchMore}
            hasMore={!!nextPage}
          >
            <>
              {contactsByStatuses.map((contactByStatus) => (
                <section key={contactByStatus.status} className="padded-top">
                  <header className="row-flex not-padded">
                    <h3 className="title font-size-16px">{allStatuses[contactByStatus.status]}</h3>
                  </header>
                  <ol>
                    {contactByStatus.contacts.map((contact) => (
                      <li key={contact.id}>
                        <Link
                          to={{
                            pathname: `${pathname}${contact.id}/`,
                            state: { listView: pathname },
                          }}
                          className="row-flex card-base grid-x"
                        >
                          <ListViewRow legalEntity={contact.requestedBy} />
                        </Link>
                      </li>
                    ))}
                  </ol>
                </section>
              ))}
            </>
          </ScrollableContent>
        ) : <ContentLoadingMessage isLoading={isLoading} loadedMessage={gettext('No contacts')} />}
      </>
    </PageContent>
  );
};

RequestsList.propTypes = {
  state: PropTypes.shape({
    contacts: PropTypes.arrayOf(ContactsModel.propType),
    nextPage: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onInitialLoad: PropTypes.func.isRequired,
  fetchMore: PropTypes.func.isRequired,
  onSearchSubmit: PropTypes.func.isRequired,
  contactsByStatuses: PropTypes.arrayOf(PropTypes.shape).isRequired,
};

export default RequestsList;
