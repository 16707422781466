
import { FullPageContent } from 'core/page';
import { getUrl } from 'utils/urls';
import BackButton from 'core/page/parts/backButton';
import InboxNavigation from './navigation';
import InboxItemsListOld from './listOld';


const InboxView = () => (
  <FullPageContent
    pageHeading={gettext('Inbox')}
    headerLeft={<BackButton href={getUrl()} />}
  >
    <InboxNavigation listComponent={InboxItemsListOld} />
  </FullPageContent>
);

export default InboxView;
