import {
  EquityItem,
  EquityIssuer,
  SuspendedAssetActionsReason,
  ContractStatus,
  ContractStatuses,
} from '@trustwise/design-system';
import { Model } from 'core/model';
import { Portfolio } from 'investments/interfaces';
import { SimpleLegalEntityModel } from 'contacts/models/legalEntity';
import { TokenContractSubType } from 'investments/types';
import { TokenContractSubTypeChoices } from 'investments/consts';
import { SimpleDocumentModel } from 'media/models';
import { DocumentResponseProps } from 'media/interfaces';
import { SimpleLegalEntityModelProps } from 'contacts/interfaces/types';
import { IAssetValuation, SimpleTokenValuationModel, SimpleTokenValuationProps } from 'valuation/models/valuation';

export interface EquityItemModelProps {
  /**
   * Equity item ID
   */
  id: number;
  /**
   * Equity item name
   */
  name: string;
  /**
   * Token contract type
   */
  contractType: string;
  /**
   * Equity issuer
   */
  issuer: SimpleLegalEntityModelProps;
  /**
   * Token contract subtype
   */
  tokenSubType: TokenContractSubType;
  /**
   * Portfolio on which the equity is allocated
   */
  portfolio: Portfolio;
  /**
   * Number of units owned by active entity on the given portfolio
   */
  amount: number;
  /**
   * Equity valuation
   */
  valuation: SimpleTokenValuationProps;
  /**
   * Equity price per unit (in base currency of the legal entity)
   */
  pricePerUnitBase: string;
  /**
   * Indicates whether equity represents equity
   */
  representsEquity: boolean;
  /**
   * Blockchain address of the token contract
   */
  address: string;
}

export interface IEquityItemModelProps extends EquityItem {
  /**
   * Token contract subtype
   */
  tokenSubType: TokenContractSubType;
}

export class EquityItemModel extends Model implements IEquityItemModelProps {
  id: number;
  name: string;
  tokenSubType: TokenContractSubType;
  portfolio: Portfolio;
  amount: number;
  valuation: IAssetValuation;
  pricePerUnitBase: number;
  hasVotingRights: boolean;
  isRestricted: boolean;
  issuer: EquityIssuer;
  blockchainAddress: string;
  blockedAmount?: number | undefined;
  isValid: boolean;

  constructor({
    issuer, pricePerUnitBase, amount, valuation, representsEquity, tokenSubType, address, ...restProps
  }: EquityItemModelProps) {
    super(restProps);
    this.issuer = SimpleLegalEntityModel.fromResponse(issuer);
    this.amount = Number(amount);
    this.pricePerUnitBase = Number(pricePerUnitBase);
    this.valuation = SimpleTokenValuationModel.fromResponse(valuation);
    this.hasVotingRights = representsEquity;
    this.isRestricted = tokenSubType === TokenContractSubTypeChoices.RESTRICTED;
    this.blockchainAddress = address;
  }
}

export interface EquityDetailModelProps extends EquityItemModelProps {
  /**
   * Equity status
   */
  status: ContractStatus;
  /**
   * Equity nominal value
   */
  nominalValue: SimpleTokenValuationProps;
  /**
   * Equity documents
   */
  documents: DocumentResponseProps[];
  /**
   * Equity blocked amount
   */
  blockedAmount: string;
  /**
   * Date of the last equity issuing
   */
  lastIssued: string;
  /**
   * Total number of equity units
   */
  totalUnits: string;
  /**
   * Reason for suspended transfers
   */
  suspendedReason?: string;
}

export interface IEquityDetailModel extends IEquityItemModelProps {
  /**
   * Equity status
   */
  status: ContractStatus;
  /**
   * Equity nominal value
   */
  nominalValue: IAssetValuation;
  /**
   * Equity documents
   */
  documents: SimpleDocumentModel[];
  /**
   * Equity blocked amount
   */
  blockedAmount?: number;
  /**
   * Date of the last equity issuing
   */
  lastIssued: Date;
  /**
   * Total number of equity units
   */
  totalUnits: number;
  /**
   * Reason for suspended transfers
   */
  suspendedReason?: SuspendedAssetActionsReason;
  /**
   * Indicates whether equity has the status valid
   */
  isValid: boolean;
}

export class EquityDetailModel extends EquityItemModel implements IEquityDetailModel {
  status: ContractStatus;
  nominalValue: IAssetValuation;
  documents: SimpleDocumentModel[];
  lastIssued: Date;
  totalUnits: number;
  suspendedReason?: SuspendedAssetActionsReason;

  constructor({
    status, nominalValue, documents, blockedAmount, lastIssued, totalUnits, ...restProps
  }: EquityDetailModelProps) {
    super(restProps);
    this.isValid = status === ContractStatuses.VALID;
    this.documents = SimpleDocumentModel.fromArrayResponse(documents);
    this.blockedAmount = blockedAmount ? Number(blockedAmount) : undefined;
    this.lastIssued = new Date(lastIssued);
    this.nominalValue = SimpleTokenValuationModel.fromResponse(nominalValue);
    this.totalUnits = Number(totalUnits);
  }

  get instrumentDetails() {
    return {
      nominalValue: this.nominalValue,
      totalUnits: this.totalUnits,
      lastIssuingDate: this.lastIssued,
    };
  }
}
