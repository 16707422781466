import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { camelCase } from 'lodash';

import { getGlobalContext } from 'core/globals';
import { DateTimeField,
  ReactSelectField,
  SubmitButton,
  TextField,
  FieldErrors,
  TextEditorComponent,
  MonetaryField } from 'core/forms/fields';
import { PageContent } from 'core/page';
import { parseErrorResponse } from 'core/forms/utils';
import axios from 'core/axios';
import { dateToTimestamp, dateToday, roundedTime } from 'utils/date';
import createSubscriptionValidation from 'subscriptions/owner/validation';
import { getApiUrl } from 'utils/urls';
import { legalTextDefault, powerOfAttorneyDefault } from 'subscriptions/const';
import { DocumentModel } from 'media/models';
import SwitchableDocumentUpload from 'media/documents/switchableUpload';
import BackButton from 'core/page/parts/backButton';
import { handleTransactionCreation } from 'core/page/transactionSigning/utils';


const createSubscriptionInitialValues = {
  nonFieldErrors: '',
  name: '',
  whitelistId: '',
  shareClassAddress: '',
  pricePerShare: (0).toFixed(2),
  minNumberOfShares: 0,
  maxNumberOfShares: 0,
  maxNumberPerSubscriber: 0,
  minNumberPerSubscription: 1,
  startDate: dateToday(),
  startTime: roundedTime(),
  endDate: dateToday(),
  endTime: roundedTime(),
  closingDate: dateToday(),
  closingTime: roundedTime(),
  legalText: legalTextDefault,
  powerOfAttorney: powerOfAttorneyDefault,
  ...DocumentModel.uploadInitialValues,
  subject: gettext('Additional terms & conditions'),
};

const CreateSubscription = ({ companyId, initialCurrency }) => {
  const [shareClassesOptions, setShareClassesOptions] = useState([]);
  const [whitelistsOptions, setWhitelistsOptions] = useState([]);
  const redirectUrl = `/company/${companyId}/liabilities/equity/`;
  createSubscriptionInitialValues.currency = initialCurrency;

  useEffect(() => {
    axios.get(getApiUrl('/subscriptions/options/share-classes/'))
      .then(({ data }) => { setShareClassesOptions(data); })
      .catch(console.error);
  }, [companyId]);

  useEffect(() => {
    axios.get(getApiUrl('/subscriptions/options/whitelists/'))
      .then(({ data }) => { setWhitelistsOptions(data); })
      .catch(console.error);
  }, []);

  const submitSubscription = (data, actions) => {
    axios.post(getApiUrl('/subscriptions/'), data)
      .then(
        ({ data: { tx_hash: txHash } }) => handleTransactionCreation(txHash, redirectUrl),
        ({ response: { data: errResponse } }) => {
          Object.keys(errResponse).forEach((key) => {
            switch (key) {
              case 'start_timestamp':
                actions.setFieldError('startDate', errResponse[key].join(' '));
                break;
              case 'end_timestamp':
                actions.setFieldError('endDate', errResponse[key].join(' '));
                break;
              case 'closing_timestamp':
                actions.setFieldError('closingDate', errResponse[key].join(' '));
                break;
              default:
                actions.setFieldError(camelCase(key), errResponse[key].join(' '));
                break;
            }
          });
        },
      )
      .catch(console.error)
      .finally(() => { actions.setSubmitting(false); });
  };

  const submitSubscriptionDocument = (documentData, data, actions) => {
    const subscriptionData = data;
    axios.post(getApiUrl('/new-documents/'), documentData)
      .then(
        ({ data: { document_id: documentId } }) => {
          subscriptionData.document_id = documentId;
          submitSubscription(subscriptionData, actions);
        },
        ({ response: { data: errResponse } }) => {
          actions.setSubmitting(false);
          parseErrorResponse(errResponse, actions.setFieldError);
        },
      )
      .catch(console.error);
  };

  const onSubmit = (values, actions) => {
    actions.setSubmitting(true);
    const { activeEntity: { timeZone } } = getGlobalContext();
    const data = {
      name: values.name,
      whitelist_id: values.whitelistId,
      share_class_address: values.shareClassAddress,
      price_per_share: values.pricePerShare,
      currency: values.currency,
      allotment_procedure: 'FS',
      oversubscription_factor: 1.0,
      min_number_of_shares: values.minNumberOfShares,
      max_number_of_shares: values.maxNumberOfShares,
      max_number_per_subscriber: values.maxNumberPerSubscriber,
      min_number_per_subscription: values.minNumberPerSubscription,
      start_timestamp: dateToTimestamp(values.startDate, values.startTime, timeZone),
      end_timestamp: dateToTimestamp(values.endDate, values.endTime, timeZone),
      closing_timestamp: dateToTimestamp(values.closingDate, values.closingTime, timeZone),
      legal_text: values.legalText,
      power_of_attorney: values.powerOfAttorney,
    };
    // @todo DRY
    if (values.files.length) {
      const documentData = new FormData();
      documentData.append('doc_type', values.documentType);
      values.files.forEach((file) => {
        documentData.append('files', file);
      });
      documentData.append('doc_date', values.date);
      documentData.append('subject', values.subject);
      submitSubscriptionDocument(documentData, data, actions);
    } else {
      submitSubscription(data, actions);
    }
  };

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Create offering')}
      headerLeft={<BackButton href={redirectUrl} />}
    >
      <Formik
        initialValues={createSubscriptionInitialValues}
        validationSchema={createSubscriptionValidation}
        onSubmit={onSubmit}
      >
        {({ values, isSubmitting }) => (
          <Form>
            <FieldErrors name="nonFieldErrors" />
            <TextField label={gettext('Name')} name="name" />
            <ReactSelectField
              label={gettext('Allowed subscribers')}
              name="whitelistId"
              options={whitelistsOptions}
            />
            <ReactSelectField
              label={gettext('Share class')}
              name="shareClassAddress"
              options={shareClassesOptions}
            />
            <MonetaryField label={gettext('Price per share')} currencyName="currency" valueName="pricePerShare" />
            <TextField label={gettext('Minimum number of shares to be issued')} name="minNumberOfShares" type="number" />
            <TextField
              label={gettext('Maximum number of shares to be issued (0 means no limit)')}
              name="maxNumberOfShares"
              type="number"
            />
            <TextField
              label={gettext('Minimum number of shares per single subscription')}
              name="minNumberPerSubscription"
              type="number"
            />
            <TextField
              label={gettext('Maximum number of shares per subscriber  (0 means no limit)')}
              name="maxNumberPerSubscriber"
              type="number"
            />
            <DateTimeField label={gettext('Start date of subscription period')} dateName="startDate" timeName="startTime" />
            <DateTimeField label={gettext('End date of subscription period')} dateName="endDate" timeName="endTime" />
            <DateTimeField label={gettext('Closing date')} dateName="closingDate" timeName="closingTime" />
            <TextEditorComponent
              textEditorIndex={0}
              label={gettext('Legal text')}
              fieldName="legalText"
              initialValue={values.legalText}
            />
            <TextEditorComponent
              textEditorIndex={1}
              label={gettext('Power of attorney text')}
              fieldName="powerOfAttorney"
              initialValue={values.powerOfAttorney}
            />
            <SwitchableDocumentUpload />
            <SubmitButton disabled={isSubmitting}>{gettext('Create')}</SubmitButton>
          </Form>
        )}
      </Formik>
    </PageContent>
  );
};

CreateSubscription.propTypes = {
  companyId: PropTypes.string.isRequired,
  initialCurrency: PropTypes.string.isRequired,
};

export default CreateSubscription;
