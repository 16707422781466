import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import { formatDateFromTimestamp } from 'utils/date';
import { BasicRow } from 'core/lists/rows/basicRow';
import ContentLoadingMessage from 'utils/contentLoadingMessage';
import RowLink from 'core/lists/rows/rowLink';
import { toMonetaryValue } from 'core/utils';
import DocumentsRoutes from 'media/routes';
import LegalAgreementsPart from './parts/legalAgreements';
import { allotmentProcedureOptions } from '../const';


const SubscriptionDetailContent = ({ subscription, ownerView, isLoading }) => {
  const { pathname } = useLocation();
  const { subscriptionId } = useParams();
  const minCapital = (subscription.price_per_share * subscription.min_number_of_shares).toLocaleString('de-ch');
  const maxCapital = subscription.max_number_of_shares ?
    (subscription.price_per_share * subscription.max_number_of_shares).toLocaleString('de-ch')
    : null;
  const maxCapitalPerSubscriber = subscription.max_number_per_subscriber ?
    (subscription.price_per_share * subscription.max_number_per_subscriber).toLocaleString('de-ch')
    : null;
  const minNumberPerSubscription = (
    subscription.price_per_share * subscription.min_number_per_subscription
  ).toLocaleString('de-ch');
  return (
    <>
      {subscription.issuer && (
        <div className="subscription-subheading">
          <div className="avatar right-padding">
            <span
              className="avatar-image centered"
              style={
                subscription.issuer.avatar.image ? { background: 'none' } : { backgroundColor: subscription.issuer.avatar.color }
              }
            >
              {subscription.issuer.avatar.image ? <img alt="profile" className="image" src={subscription.issuer.avatar.image} />
                : <span className="avatar-initials">{subscription.issuer.avatar.initials}</span>}
            </span>
          </div>
          {subscription.issuer.name}
          &bull;
          {subscription.share_class.name}
        </div>
      )}
      {Object.entries(subscription).length > 0 ? (
        <>
          {ownerView &&
            <BasicRow title={gettext('Whitelist')} value={subscription.whitelist_name} />}
          {/* TODO */}
          <BasicRow title={gettext('Issuer')} value={subscription.issuer.name} />
          <BasicRow title={gettext('Share class')} value={subscription.share_class.name} />
          <BasicRow
            title={gettext('Nominal value')}
            value={toMonetaryValue(subscription.share_class.currency, subscription.share_class.nominal_value)}
          />
          <BasicRow
            title={gettext('Issue price per share')}
            value={toMonetaryValue(subscription.currency, subscription.price_per_share)}
          />
          <BasicRow
            title={gettext('Min capital/no. of shares to be issued')}
            value={`
            ${toMonetaryValue(subscription.currency, minCapital)}
            / ${subscription.min_number_of_shares.toLocaleString('de-ch')}
            `}
          />
          <BasicRow
            title={gettext('Max capital/no. of shares to be issued')}
            value={maxCapital ? `
            ${toMonetaryValue(subscription.currency, maxCapital)}
            / ${subscription.max_number_of_shares.toLocaleString('de-ch')}
          ` : gettext('Not specified')}
          />
          <BasicRow
            title={gettext('Min investment/no. of shares per subscription')}
            value={`
            ${toMonetaryValue(subscription.currency, minNumberPerSubscription)}
            / ${subscription.min_number_per_subscription.toLocaleString('de-ch')}
            `}
          />
          <BasicRow
            title={gettext('Max investment/no. of shares per investor')}
            value={maxCapitalPerSubscriber ? `
            ${toMonetaryValue(subscription.currency, maxCapitalPerSubscriber)}
            / ${subscription.max_number_per_subscriber.toLocaleString('de-ch')}` : gettext('Not specified')}
          />
          {(ownerView || subscription.oversubscription_factor > 1) && (
            <>
              <BasicRow title={gettext('Oversubscription factor')} value={(subscription.oversubscription_factor).toFixed(1)} />
              <BasicRow
                title={gettext('Allotment procedure')}
                value={allotmentProcedureOptions.find((option) => option.value === subscription.allotment_procedure).label}
              />
            </>
          )}
          <BasicRow
            title={gettext('Start of subscription period')}
            value={formatDateFromTimestamp(subscription.start_timestamp)}
          />
          <BasicRow title={gettext('End of subscription period')} value={formatDateFromTimestamp(subscription.end_timestamp)} />
          <BasicRow title={gettext('Expected closing date')} value={formatDateFromTimestamp(subscription.closing_timestamp)} />
          {ownerView && <RowLink to={`${pathname}subscription-certificates/`} title={gettext('Subscription certificates')} />}
          {ownerView && (
            <LegalAgreementsPart
              powerOfAttorneyText={subscription.power_of_attorney}
              legalText={subscription.legal_text}
            />
          )}
          <DocumentsRoutes
            relatedObjectModel="contract"
            relatedObjectId={subscriptionId}
            queryParams={{ subscription_id: subscriptionId }}
            isNested
          />
        </>
      ) : (
        <ContentLoadingMessage
          isLoading={isLoading}
          loadedMessage={gettext('Subscription not found.')}
        />
      )}
    </>
  );
};

SubscriptionDetailContent.defaultProps = {
  ownerView: false,
};

SubscriptionDetailContent.propTypes = {
  ownerView: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  subscription: PropTypes.shape({
    issuer: PropTypes.shape(),
    whitelist_name: PropTypes.string,
    share_class: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      currency: PropTypes.string,
      nominal_value: PropTypes.number,
    }),
    currency: PropTypes.string,
    price_per_share: PropTypes.string,
    oversubscription_factor: PropTypes.number,
    min_number_of_shares: PropTypes.number,
    min_number_per_subscription: PropTypes.number,
    max_number_of_shares: PropTypes.number,
    max_number_per_subscriber: PropTypes.number,
    allotment_procedure: PropTypes.string,
    start_timestamp: PropTypes.number,
    end_timestamp: PropTypes.number,
    closing_timestamp: PropTypes.number,
    legal_text: PropTypes.string,
    power_of_attorney: PropTypes.string,
  }).isRequired,
};

export default SubscriptionDetailContent;
