import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';

import FormErrors from '../../../components/common/formikErrors';

/**
 * Formik based field error messages component.
 */
const FieldErrors = ({ name }) => (
  <ErrorMessage name={name} component={FormErrors} />
);

FieldErrors.propTypes = {
  name: PropTypes.string.isRequired,
};

export default FieldErrors;
