/**
 * Scrollable content part
 */

import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';


const ScrollableContent = ({ dataLength, fetchNext, hasMore, children, ...props }) => (
  <InfiniteScroll
    dataLength={dataLength}
    next={fetchNext}
    hasMore={hasMore}
    loader={<div className="loader" />}
    {...props}
  >
    {children}
  </InfiniteScroll>
);

ScrollableContent.propTypes = {
  children: PropTypes.node.isRequired,
  dataLength: PropTypes.number.isRequired,
  fetchNext: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
};

export default ScrollableContent;

