import PropTypes from 'prop-types';
import { Field } from 'formik';

import FieldErrors from './errors';
import FieldLabel from './label';

/**
 * Radio button input with label.
 *
 */
const RadioButtonInput = ({ id, label, name, isOptional, value, groupId, className, ...props }) => {
  const inputId = id || `${name}_${value}_${groupId}`;
  // <CheckboxInput type="radio" id={id} label={label} name={name} value={value} {...props} />
  return (
    <div className={className}>
      <Field className="radio-input" type="radio" name={name} value={value} id={inputId} {...props} />
      {label && (<FieldLabel className="radio-checkbox-label" id={inputId} label={label} isOptional={isOptional} />)}
    </div>
  );
};

RadioButtonInput.defaultProps = {
  id: '',
  label: '',
  groupId: '',
  className: 'radio-checkbox-wrapper',
  isOptional: false,
};

RadioButtonInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  groupId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isOptional: PropTypes.bool,
};


/**
 * Group of mutually exclusive radio buttons with single name.
 *
 * TODO consider <fieldset> tag instead of react
 */
const RadioButtonGroup = ({ label, name, groupId, options, className, disabled }) => (
  <>
    <FieldErrors name={name} />
    {label && (<label htmlFor={groupId} className="radio-button-group-label">{label}</label>)}
    <div className={className}>
      {options.map((option) => (
        <RadioButtonInput
          key={`${name}_${option.value}`}
          name={name}
          value={option.value}
          label={option.label}
          groupId={groupId}
          disabled={disabled}
        />
      ))}
    </div>
  </>
);

RadioButtonGroup.defaultProps = {
  label: '',
  groupId: '',
  className: '',
  disabled: false,
};

RadioButtonGroup.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  groupId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export { RadioButtonInput, RadioButtonGroup };
