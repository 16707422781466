import { DataSetVerificationDecision } from '@trustwise/design-system';
import { SimpleLegalEntityModel } from 'contacts/models/legalEntity';
import { Model } from 'core/model';
import { FileModel } from 'media/models';
import { getReportsFiles } from 'dataRooms/utils';
import { DataSetExternalItemResponse, ExternalAccessDataRoom, GrantItemResponse } from './types';

export class ExternalAccessDataRoomModel extends Model {
  name: string;
  owner: SimpleLegalEntityModel;
  description?: string;
  isPrivate: boolean;

  constructor({ owner, ...restProps }: ExternalAccessDataRoom) {
    super(restProps);
    this.owner = SimpleLegalEntityModel.fromResponse(owner);
    this.isPrivate = false;
  }
}

export class GrantModel extends Model {
  amount: number;
  tokenName: string;
  tokenAddress: string;

  constructor({ amount, name, ...restProps }: GrantItemResponse) {
    super(restProps);
    this.amount = Number(amount);
    this.tokenName = name;
  }
}

export class ExternalAccessDataSetModel extends Model {
  id: number;
  reviewedAt: Date;
  reviewedBy: SimpleLegalEntityModel;
  reviewedByCompany?: SimpleLegalEntityModel;
  verifierReports?: FileModel[];
  grant?: GrantModel;
  privateData?: string;
  publicData?: string;
  decision: DataSetVerificationDecision;
  name: string;

  constructor({
    id, reviewedAt, reviewedBy, reviewedByCompany, publicData, privateData,
    verifierReports, grantedToken, ...restProps
  }: DataSetExternalItemResponse) {
    super(restProps);
    this.id = id;
    this.reviewedAt = new Date(reviewedAt);
    this.reviewedBy = SimpleLegalEntityModel.fromResponse(reviewedBy);
    this.reviewedByCompany = SimpleLegalEntityModel.fromResponse(reviewedByCompany);
    this.verifierReports = getReportsFiles(verifierReports);
    this.grant = GrantModel.fromResponse(grantedToken);
    this.privateData = privateData;
    this.publicData = publicData;
  }
}
