import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import axios from 'core/axios';
import { convertKeysToCamelCase } from 'core/utils';
import {
  TextField,
  TextAreaField,
  ReactSelectField,
  SubmitButton,
  FieldErrors,
  RadioButtonGroup,
  DateField,
  EmailField,
} from 'core/forms/fields';
import { countryOptions } from 'core/options';
import { dateToday } from 'utils/date';
import { DocumentModel } from 'media/models';
import SwitchableDocumentUpload from 'media/documents/switchableUpload';
import CustodialAccountModel from './models';


const BaseAccountEditForm = ({ accountId, urlBase, isEditing, onSubmit, submitLabel }) => {
  const [initValues, setInitValues] = useState({
    name: '',
    email: '',
    countryCode: '',
    city: '',
    postalCode: '',
    street: '',
    additionalAddress: '',
    entityType: 'person',
    dateOfBirth: '',
    custodianComment: '',
    ...DocumentModel.uploadInitialValues,
    subject: gettext('Custodial Account Document'),
  });

  useEffect(() => {
    if (accountId) {
      axios.get(`${urlBase}/custodian/shareholders/${accountId}/`)
        .then(({ data }) => {
          const accountData = new CustodialAccountModel(convertKeysToCamelCase(data));
          setInitValues(accountData);
        })
        .catch(console.error);
    }
  }, [accountId, urlBase]);

  return (
    <Formik
      initialValues={{
        ...initValues,
        nonFieldErrors: '',
      }}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={Yup.object({
        entityType: Yup.string().oneOf(['person', 'company']).required(gettext('This field is required')),
        name: Yup.string().required(gettext('This field is required')),
        dateOfBirth: Yup.date().when('entityType', {
          is: 'person',
          then: Yup.date()
            .max(dateToday(), gettext('Date cannot be in the future.'))
            .nullable()
            .required(gettext('This field is required')),
          otherwise: Yup.date().nullable(),
        }),
        email: Yup.string().email().required(gettext('This field is required')),
        countryCode: Yup.string().required(gettext('This field is required')),
        city: Yup.string().required(gettext('This field is required')),
        postalCode: Yup.string(),
        street: Yup.string().required(gettext('This field is required')),
        additionalAddress: Yup.string(),
        custodianComment: Yup.string(),
      })}
    >
      {({ values, isSubmitting }) => (
        <Form>
          <FieldErrors name="nonFieldErrors" />
          <RadioButtonGroup
            name="entityType"
            label={gettext('Account type')}
            disabled={isEditing}
            options={[
              { label: gettext('Person'), value: 'person' },
              { label: gettext('Company'), value: 'company' },
            ].filter((option) => !isEditing || option.value === values.entityType)}
          />
          <TextField label={gettext('Name')} name="name" />
          {values.entityType === 'person' && <DateField label={gettext('Date of Birth')} name="dateOfBirth" />}
          <EmailField label={gettext('E-mail')} name="email" />
          <TextField
            label={gettext('Address Line 1')}
            name="street"
            placeholder={gettext('Street and number, P.O. box, c/o')}
          />
          <TextField
            label={gettext('Address Line 2')}
            name="additionalAddress"
            placeholder={gettext('Apartment, unit, building, floor')}
            isOptional
          />
          <TextField label={gettext('Postal Code')} name="postalCode" isOptional />
          <TextField label={gettext('City')} name="city" />
          <ReactSelectField
            label={gettext('Country')}
            name="countryCode"
            options={countryOptions}
            getOptionValue={(option) => option.code}
            getOptionLabel={(option) => option.label}
            isSearchable
          />
          <TextAreaField label={gettext('Comment')} name="custodianComment" isOptional />
          {isEditing || (
            <>
              <p className="help-text">
                {gettext(
                  `Please upload all documents of the shareholders identification process
                    (according to the rules set by your supervisory body) such as copies of passports,
                    video recording or company forms including beneficial owner statement.`,
                )}
              </p>
              <SwitchableDocumentUpload showDocumentDate={false} />
            </>
          )}
          <SubmitButton disabled={isSubmitting}>{submitLabel}</SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

BaseAccountEditForm.defaultProps = {
  accountId: null,
  submitLabel: gettext('Create'),
  isEditing: false,
};

BaseAccountEditForm.propTypes = {
  accountId: PropTypes.number,
  isEditing: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  submitLabel: PropTypes.string,
  urlBase: PropTypes.string.isRequired,
};

export default BaseAccountEditForm;
