/* eslint-disable no-nested-ternary */
import { useState, useEffect } from 'react';
import { SkeletonItem } from '@trustwise/design-system';
import axios from 'core/axios';
import { EmptyContent } from 'core/page';
import { getApiUrl } from 'utils/urls';
import { formatNumber } from 'core/utils';


const MintedTokensList = () => {
  const [mintedTokens, setMintedTokens] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios.get(getApiUrl('/payment-tokens/issuer/'))
      .then(({ data }) => { setMintedTokens(data); })
      .catch(console.error)
      .finally(() => { setIsLoading(false); });
  }, []);

  if (isLoading) {
    return [1, 2, 3].map((item) => (<SkeletonItem largerPadding key={item} />));
  }

  return (
    mintedTokens.length > 0 ? (
      <ul>
        {mintedTokens.map((mintedToken) => (
          <div
            key={mintedToken.id}
            className="widget-content-row"
          >
            <div className="widget-row-title">{mintedToken.name}</div>
            <div className="widget-left-column">{formatNumber(mintedToken.amount)}</div>
          </div>
        ))}
      </ul>
    ) : (
      <EmptyContent text={gettext('No tokens to display')} />
    )
  );
};

export default MintedTokensList;
