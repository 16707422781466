import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import { GetTwexAppToSignUp, SignUpInProgress } from '@trustwise/design-system';
import { FullPageView } from '../core/page';


export const SignUpInProgressView = ({ email }) => (
  <FullPageView>
    <SignUpInProgress
      email={email}
      onClick={() => { window.location.href = '/logout/'; }}
    />
  </FullPageView>
);


export const SignUpLandingView = ({ appStoreUrl, playStoreUrl }) => {
  const isMobile = (
    window.navigator.userAgent.includes('iPad')
    || window.navigator.userAgent.includes('iPhone')
    || window.navigator.userAgent.includes('Android')
  );
  return (
    <FullPageView>
      <GetTwexAppToSignUp
        qr={QRCode({
          value: `${window.location.protocol}//${window.location.host}/redirect-app-store/`,
          level: 'L',
        })}
        showQR={!isMobile}
        onAppStoreClick={() => { window.location.href = appStoreUrl; }}
        onPlayStoreClick={() => { window.location.href = playStoreUrl; }}
        onSignInClick={() => { window.location.href = '/login/'; }}
      />
    </FullPageView>
  );
};


SignUpInProgressView.propTypes = {
  email: PropTypes.string,
};

SignUpLandingView.propTypes = {
  appStoreUrl: PropTypes.string,
  playStoreUrl: PropTypes.string,
};
