import { formatDate } from '../../utils/date';
import { Model } from '../model';

export default class Rate extends Model {
  constructor() {
    super();
    this.date = null;
  }

  setDate(date) {
    this.setProperty('date', new Date(date));
  }

  get formattedDate() {
    return formatDate(this.date);
  }

  get formattedValue() {
    return this.value.toFixed(4);
  }
}
