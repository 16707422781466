/* Submit shares registration date */
import PropTypes from 'prop-types';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { ModalWithContent as Modal } from '@trustwise/design-system';
import { SubmitButton, DateField } from 'core/forms/fields';
import { parseErrorResponse } from 'core/forms/utils';
import axios from 'core/axios';
import FormErrors from 'components/common/formikErrors';
import { dateToday } from 'utils/date';
import { txHashResponseHandler } from 'core/utils';
import { getApiUrl } from 'utils/urls';


const MintageEventDateForm = ({ isOpen, onClose, redirectUrl, subscriptionId, batchSize }) => {
  const onSubmit = (values, actions) => {
    axios.post(
      getApiUrl(`/subscriptions/${subscriptionId}/distribute/`),
      { batch_size: batchSize, event_date: values.eventDate },
    )
      .then(
        ({ data }) => { txHashResponseHandler(data, redirectUrl); },
        ({ response: { data: errors } }) => { parseErrorResponse(errors, actions.setFieldError); },
      )
      .catch(console.error)
      .finally(() => { actions.setSubmitting(false); });
  };

  return (
    <Modal
      id="mintageReasonDate"
      isOpen={isOpen}
      onClose={onClose}
      title={gettext('Registration date of shares')}
    >
      <Formik
        initialValues={{
          nonFieldErrors: '',
          eventDate: dateToday(),
        }}
        validationSchema={Yup.object({
          eventDate: Yup.date().required('This field is required'),
        })}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <ErrorMessage component={FormErrors} name="nonFieldErrors" />
            <p>{gettext('Please, enter the date when the shares have been registered in the commercial registry')}</p>
            <DateField label={gettext('Date')} name="eventDate" />
            <SubmitButton disabled={isSubmitting}>{gettext('Submit')}</SubmitButton>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

MintageEventDateForm.defaultProps = {
  isOpen: false,
  batchSize: 0,
};

MintageEventDateForm.propTypes = {
  redirectUrl: PropTypes.string.isRequired,
  subscriptionId: PropTypes.string.isRequired,
  batchSize: PropTypes.number,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default MintageEventDateForm;
