const getGlobalContext = () => window.trustwise || {
  actingPerson: {},
  activeEntity: {
    providedServices: [],
    allowedActions: [],
  },
  blockchainErrors: {},
  custodian: {},
  delegate: {},
  unsignedTransaction: {},
  twex: {},
};

export { getGlobalContext };
