import { observer } from 'mobx-react';

import { MainMenu } from '@trustwise/design-system';
import { inboxItemsCountStore } from 'stores/inboxItemsCountStore';
import { getUrl, getUrlBase } from 'utils/urls';
import { getGlobalContext } from 'core/globals';
import { getMenuFavoriteItems, filterMenuItems } from 'core/page/menu/utils';
import { FormattedMenuItemsProps, MenuItemProps } from 'core/page/menu/types';

type MenuComponentProps = { inboxBadgeCount: number }

const MenuComponent = ({ inboxBadgeCount }: MenuComponentProps) => {
  const path = window.location.pathname;
  const menuItems = filterMenuItems();
  const favoriteMenuItems = getMenuFavoriteItems();
  const urlPrefix = getUrlBase();

  const isActive = (item: MenuItemProps) => {
    const urlWithPrefix = `${urlPrefix}${item.url}`;
    switch (item.id) {
      case 'dashboard':
        return path === urlWithPrefix;
      default:
        if (item.subitems) {
          return item.subitems.some((i) => path.startsWith(`${urlPrefix}${i.url}`));
        }
        return path.startsWith(urlWithPrefix);
    }
  };

  const formatMenuItem = (item: MenuItemProps): FormattedMenuItemsProps => ({
    ...item,
    url: getUrl(item.url),
    isActive: isActive(item),
    badge: item.id === 'inbox' ? inboxBadgeCount : undefined,
  });

  const formattedMenuItems: FormattedMenuItemsProps[] = menuItems.map((item) => ({
    ...formatMenuItem(item),
    subitems: item.subitems && item.subitems.map(formatMenuItem),
  }));

  const formattedFavoriteMenuItems: FormattedMenuItemsProps[] = favoriteMenuItems.map(formatMenuItem);

  return <MainMenu menuItems={formattedMenuItems} favoriteMenuItems={formattedFavoriteMenuItems} />;
};

const AppNavigation = observer(() => (
  <MenuComponent
    inboxBadgeCount={inboxItemsCountStore.countById(getGlobalContext().activeEntity.id)}
  />
));

export default AppNavigation;
