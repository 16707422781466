import { Buffer } from 'buffer';
import { FormikHelpers } from 'formik';
import web3 from 'web3';

import {
  IssuedCertificatesColumn,
  SortingOrder,
  CertificateIdentityType,
  CustomSchemaFieldTypes,
  EncryptedDataSchema,
  CertificateIdentity as CertificateIdentityDS,
  CertificateIdentityValues,
  CertificateIssuingValues,
} from '@trustwise/design-system';
import axios from 'core/axios';
import { getApiUrl } from 'utils/urls';
import { signFormTransaction, signTransaction } from 'utils/signTransaction';
import { SetDisabled, SetStateType } from 'types';
import { CertificateTypeListModel } from 'certificates/types/models';
import { CertificateTypeDetailModel } from 'certificates/types/models/detail';
import { onPdfDownload } from 'media/utils';
import { convertBooleanString } from 'core/utils';
import { CustomSchemaFieldEncodingData, encodeData } from 'core/utils/encoding';
import { encrypt, encryptFile } from 'certificates/utils/encryption';
import { IssuedCertificateModel } from './models';
import { certificateIssuingPath } from './constants';
import { CertificateIdentity } from './dataStructures';

type GetIssuedCertificatesParams = {
  column?: IssuedCertificatesColumn;
  order?: SortingOrder;
}

export const getIssuedCertificates = (
  { column, order }: GetIssuedCertificatesParams,
): Promise<IssuedCertificateModel[]> => {
  let queryArgs: Record<string, string> | undefined;
  if (column && order) {
    queryArgs = {
      order_by: column,
      order,
    };
  }
  return (
    axios
      .get(getApiUrl(certificateIssuingPath, queryArgs))
      .then(({ data }) => IssuedCertificateModel.fromResponse(data))
      .catch(console.error)
  );
};

export const revokeCertificate = (
  certificateTypeAddress: string,
  certificateId: string,
  setDisabled: SetDisabled,
) => signTransaction({
  urlPath: `${certificateIssuingPath}revoke/`,
  setDisabled,
  data: {
    certificate_type_address: certificateTypeAddress,
    certificate_id: certificateId,
  },
});

export const getIssuerCertificateTypes = (noOfItems?: string): Promise<CertificateTypeListModel[]> => {
  const queryArgs = noOfItems ? { no_of_items: noOfItems } : undefined;
  return (
    axios
      .get(getApiUrl(`${certificateIssuingPath}types/`, queryArgs))
      .then(({ data }) => CertificateTypeListModel.fromResponse(data))
      .catch(console.error)
  );
};

export const getIssuerCertificateTypeDetails = (
  id: string | number,
  setCertificateType?: SetStateType<CertificateTypeDetailModel | undefined>,
): Promise<CertificateTypeDetailModel> => (
  axios
    .get(getApiUrl(`${certificateIssuingPath}types/${id}`))
    .then(({ data }) => {
      const certificateType = CertificateTypeDetailModel.fromResponse(data);
      setCertificateType?.(certificateType);
      return certificateType;
    })
    .catch(console.error)
);

export const downloadCertificateTypeFile = (certificateType?: CertificateTypeDetailModel) => {
  if (!certificateType?.file) {
    throw new Error('Certificate type file is missing');
  }
  certificateType.file.download({ downloadUrl: `${certificateIssuingPath}types/${certificateType.id}/file-download` });
};

export const onGenerateIdentity = (
  identityValues: CertificateIdentityValues,
  setIdentity: SetStateType<CertificateIdentity | undefined>,
  identityType?: CertificateIdentityType,
) => {
  if (!identityType) {
    throw new Error('Identity type is missing');
  }
  const identity = CertificateIdentity.create(identityType, identityValues);
  setIdentity(identity);
  return identity.displayValues[0];
};

export const onScanIdentity = (
  identity: string,
  setIdentity: SetStateType<CertificateIdentity | undefined>,
): CertificateIdentityDS => {
  const scannedIdentity = CertificateIdentity.fromString(identity);
  setIdentity(scannedIdentity);
  return {
    preview: scannedIdentity.displayValues[0],
    values: scannedIdentity.formValues,
  };
};

export const onDownloadIdentityPdf = (identity: CertificateIdentity) => {
  onPdfDownload(
    getApiUrl(`${certificateIssuingPath}download-identity-pdf/`, { identity: identity.toBase64String() }),
    gettext('identity'),
  );
};

export const onSendIdentityEmail = (
  email: string,
  identity?: CertificateIdentity,
): Promise<void> => {
  if (!identity) {
    throw new Error('Identity is missing');
  }
  return axios.post(
    getApiUrl(`${certificateIssuingPath}send-identity-email/`),
    { identity: identity.toBase64String(), email },
  );
};

export const onCertificateIssue = async (
  values: CertificateIssuingValues,
  actions: FormikHelpers<CertificateIssuingValues>,
  identity?: CertificateIdentity,
  encryptedDataSchema?: EncryptedDataSchema[],
) => {
  if (!identity) {
    throw new Error('Identity is missing');
  }

  // Getting of additional fields from values might need an update once ds is ready
  const {
    certificateTypeAddress,
    issuedAt,
    expiresAt,
    certificateId,
    encryptedData,
  } = values;
  const password = identity.securityCode;
  const encryptedFiles: {fileData: Uint8Array, name: string}[] = [];
  const dataToEncode: CustomSchemaFieldEncodingData[] = [];
  const promises = encryptedData ? encryptedDataSchema?.map(async ({ fieldType, key }) => {
    const value = encryptedData[key];
    let fieldData: string | number | boolean | string[];
    switch (fieldType) {
      case CustomSchemaFieldTypes.DATE_TIME: {
        const date = encryptedData[`${key}_date`];
        const time = encryptedData[`${key}_time`];
        fieldData = new Date(`${date}T${time}`).getTime() / 1000;
        break;
      }
      case CustomSchemaFieldTypes.BOOLEAN:
        fieldData = convertBooleanString(value as string) as boolean;
        break;
      case CustomSchemaFieldTypes.FILES: {
        const files = value as File[];
        // Encrypt each file and collect the results
        const encryptedFilesPromises = files.map(async (file) => {
          const encryptedFile = await encryptFile(file, password);
          return { fileData: encryptedFile, name: file.name };
        });
        const encryptedFilesResults = await Promise.all(encryptedFilesPromises);
        // Push each encrypted file to the encryptedFiles array, which will be submitted separately
        // and used on the backend to create files records
        encryptedFilesResults.forEach((result) => {
          encryptedFiles.push(result);
        });
        console.info('encryptedFiles', encryptedFiles);
        // Hash each encrypted file
        fieldData = encryptedFilesResults.map(({ fileData }) => web3.utils.keccak256(fileData));
        break;
      }
      default:
        if (typeof value === 'object') {
          throw new Error(`Invalid value for field ${key} of type ${fieldType} is provided`);
        }
        fieldData = value;
        break;
    }
    dataToEncode.push({ fieldType, data: fieldData });
  }) : undefined;

  promises && await Promise.all(promises);

  console.info('dataToEncode', dataToEncode);
  console.info('identity', identity);
  console.info('identity_hash', identity.hash);

  let encryptedDataBytes: Uint8Array | undefined;
  if (dataToEncode.length) {
    encryptedDataBytes = await encrypt(web3.utils.hexToBytes(encodeData(dataToEncode)), password);
  }

  const data = {
    identity_hash: identity.hash,
    file_access_token: identity.fileAccessToken,
    certificate_type_address: certificateTypeAddress,
    issued_at: issuedAt,
    expires_at: expiresAt,
    certificate_id: certificateId,
    encrypted_data: encryptedDataBytes ? `0x00${web3.utils.bytesToHex(encryptedDataBytes).slice(2)}` : undefined,
    files: encryptedFiles.map(({ fileData, name }) => ({
      file_data: Buffer.from(fileData).toString('base64'),
      name,
    })),
  };

  signFormTransaction({
    urlPath: certificateIssuingPath,
    navigatePath: certificateIssuingPath,
    actions,
    data,
  });
};
