/**
 * Link Signer component
 */

import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';

const getQRRecoveryString = (address) => (
  `tw-signer://recovery/?version=1&masterAddress=${address}&url=${window.location.protocol}//${window.location.host}/api/v1/`
);

const LinkSignerAppComponent = ({ masterAddress }) => (
  <div className="row-flex align-text-center">
    <QRCode value={getQRRecoveryString(masterAddress)} className="qr-code-img large" level="L" />
  </div>
);

LinkSignerAppComponent.propTypes = {
  masterAddress: PropTypes.string.isRequired,
};

export default LinkSignerAppComponent;
