import PropTypes from 'prop-types';

/**
 * Field label.
 *
 */
const FieldLabel = ({ id, label, className, isOptional }) => (
  <label className={className} htmlFor={id}>
    {label}
    {isOptional ? ' (optional)' : ''}
  </label>
);

FieldLabel.defaultProps = {
  className: '',
  isOptional: false,
};

FieldLabel.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  className: PropTypes.string,
  isOptional: PropTypes.bool,
};

export default FieldLabel;
