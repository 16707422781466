import { extendObservable } from 'mobx';
import AccountsCollection from '../collections/accountSwitcher/accounts';
import Store from './store';


class AccountSwitcherStore extends Store {
  constructor() {
    super();
    extendObservable(this, {
      collection: new AccountsCollection(),
      open: false,
      currentAccountId: null,
    });
    this.setOpen = this.setOpen.bind(this);
    this.setCurrentAccount = this.setCurrentAccount.bind(this);
  }

  setOpen(val) {
    this.setProperty('open', val);
    document.body.classList[val ? 'add' : 'remove']('acc-switcher-opened');
  }

  setCurrentAccount(id) {
    this.setProperty('currentAccountId', id);
  }

  get currentAccount() {
    return this.collection.find((account) => account.id === this.currentAccountId);
  }
}

export const accountSwitcherStore = new AccountSwitcherStore();
export { AccountSwitcherStore };
