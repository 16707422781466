import web3 from 'web3';

import {
  CertificateIdentityTypes,
  CertificateIdentityType,
  CertificateIdentityValues,
  CertificateIdentityLastFirstDobValues,
} from '@trustwise/design-system';
import { IdentityParsingError } from 'certificates/exceptions';
import {
  FILE_ACCESS_TOKEN_LENGTH,
  MIN_PASSWORD_LENGTH,
  SEPARATOR,
  VERSION,
} from 'certificates/issuing/constants';
import { generatePassword, generateRandomHex } from 'certificates/utils/generators';
import { Model } from 'core/model';
import { encodeStringToBase64 } from 'core/utils';

const splitIdentityDate = (date: string) => {
  if (date.includes('-')) {
    const [year, month, day] = date.split('-');
    return { year, month, day };
  }
  if (date.length !== 8) {
    throw new Error('Invalid date format');
  }
  return {
    day: date.slice(0, 2),
    month: date.slice(2, 4),
    year: date.slice(4, 8),
  };
};

type IdentityCodeType = '00' | '01';

// Order of the `fields` array should match the order of the fields in the generated identity
export const IDENTITY_TYPES = {
  [CertificateIdentityTypes.ID_DOC_NUMBER]: {
    code: '00',
    fields: ['idNumber'],
  },
  [CertificateIdentityTypes.LAST_NAME_FIRST_NAME_DOB]: {
    code: '01',
    fields: ['lastName', 'firstName', 'dob'],
  },
};

interface CertificateIdentityProps {
  version: string;
  type: CertificateIdentityType;
  values: string[];
  securityCode: string;
  fileAccessToken: string;
}

interface ICertificateIdentity extends CertificateIdentityProps {
  identityTypeCode: IdentityCodeType;
}

export class CertificateIdentity extends Model implements ICertificateIdentity {
  identityTypeCode: IdentityCodeType;
  version: string;
  type: CertificateIdentityType;
  values: string[];
  securityCode: string;
  fileAccessToken: string;

  constructor({ type, ...restProps }: CertificateIdentityProps) {
    super(restProps);
    this.type = type;
    this.identityTypeCode = IDENTITY_TYPES[type].code as IdentityCodeType;
  }

  get hash(): string {
    const identityString = [
      this.identityTypeCode,
      ...this.values,
      this.securityCode,
    ].join(SEPARATOR);
    console.info('identity string for hash', identityString);

    return web3.utils.keccak256(identityString);
  }

  toBase64String(): string {
    const identityString = [
      this.version,
      this.identityTypeCode,
      ...this.values,
      this.securityCode,
      this.fileAccessToken,
    ].join(SEPARATOR);
    console.info('identity string', identityString);
    return encodeStringToBase64(identityString);
  }

  static create(
    identityType: CertificateIdentityType,
    identityValues: CertificateIdentityValues,
  ) {
    console.info('identityType', identityType);
    console.info('identityValues', identityValues);

    const { fields: identityFields } = IDENTITY_TYPES[identityType];
    const values = { ...identityValues };
    if (identityType === CertificateIdentityTypes.LAST_NAME_FIRST_NAME_DOB) {
      const [year, month, day] = (values as CertificateIdentityLastFirstDobValues).dob.split('-');
      (values as CertificateIdentityLastFirstDobValues).dob = `${day}${month}${year}`;
    }

    const identity = identityFields.map((field) => values[field]);
    let passwordLength = MIN_PASSWORD_LENGTH;

    if (identity.join('').length + MIN_PASSWORD_LENGTH < 20) {
      passwordLength = 20 - identity.join('').length;
    }

    return new this({
      version: VERSION,
      type: identityType,
      values: identity,
      securityCode: generatePassword(passwordLength),
      fileAccessToken: generateRandomHex(FILE_ACCESS_TOKEN_LENGTH),
    });
  }

  static fromString(identity: string): CertificateIdentity {
    if (!identity.includes(SEPARATOR)) {
      throw new IdentityParsingError('Invalid identity format.');
    }
    const [version, identityTypeCode, ...identityValues] = identity.split(SEPARATOR);
    if (version !== '0001') {
      throw new IdentityParsingError(`Invalid identity version: ${version}.`);
    }
    const identityType = Object.keys(IDENTITY_TYPES).find((key) => IDENTITY_TYPES[key].code === identityTypeCode);
    if (!identityType) {
      throw new IdentityParsingError(`Unknown identity type: ${identityTypeCode}`);
    }
    const fileAccessToken = identityValues.pop()!;
    const securityCode = identityValues.pop()!;
    if (identityValues.length !== IDENTITY_TYPES[identityType].fields.length) {
      throw new IdentityParsingError('Invalid identity fields.');
    }
    if ((identityValues.join('') + securityCode).length < 20) {
      throw new IdentityParsingError('Security code is too short.');
    }

    return new this({
      version,
      type: identityType as CertificateIdentityType,
      values: identityValues,
      securityCode,
      fileAccessToken,
    });
  }

  get formValues() {
    switch (this.type) {
      case CertificateIdentityTypes.ID_DOC_NUMBER:
        return { idNumber: this.values[0] };
      case CertificateIdentityTypes.LAST_NAME_FIRST_NAME_DOB: {
        const [lastName, firstName, dob] = this.values;
        const { year, month, day } = splitIdentityDate(dob);
        return { lastName, firstName, dob: `${year}-${month}-${day}` };
      }
      default:
        throw new Error('Unknown identity type');
    }
  }

  get displayValues() {
    switch (this.type) {
      case CertificateIdentityTypes.ID_DOC_NUMBER:
        return [{ label: gettext('ID Number'), value: this.values[0] }];
      case CertificateIdentityTypes.LAST_NAME_FIRST_NAME_DOB: {
        const [lastName, firstName, dob] = this.values;
        const { year, month, day } = splitIdentityDate(dob);
        return [
          { label: gettext('Name'), value: `${firstName} ${lastName}` },
          { label: gettext('Date of Birth'), value: `${day}.${month}.${year}` },
        ];
      }
      default:
        throw new Error('Unknown identity type');

    }
  }
}
