import { Avatar } from '@trustwise/design-system';

const processChartData = (data) => {
  const { values, labels, descriptions } = data;
  const threshold = values.length > 5 ? 4 : 5;
  // Record values with their original indexes before sorting
  const valuesWithIndexes = [];
  for (let i = 0; i < values.length; i++) {
    valuesWithIndexes.push({ value: values[i], idx: i });
  }
  // get elements with top values, but not more then specified in threshold
  const topElements = valuesWithIndexes.sort((n, p) => p.value - n.value).slice(0, threshold);

  const newLabels = [];
  const newValues = [];
  const newDescriptions = [];
  const usedIndexes = [];

  // push to new data store values corresponding to those top elements
  topElements.forEach(
    (dataItem) => {
      usedIndexes.push(dataItem.idx);
      newLabels.push(labels[dataItem.idx]);
      newValues.push(values[dataItem.idx]);
      newDescriptions.push({
        ...descriptions[dataItem.idx],
        representation: (
          <Avatar
            {...descriptions[dataItem.idx].avatar}
            backgroundColor={descriptions[dataItem.idx].avatar.color}
          />
        ),
      });
    },
  );

  // calculate sum of elements that are not in the topElements
  const unusedSum = values.filter(
    (_item, idx) => !usedIndexes.includes(idx),
  ).reduce((a, b) => a + b, 0);

  if (unusedSum > 0) {
    // push "Other" value in case the sum of remaining elements is not 0
    newLabels.push(gettext('Other'));
    newValues.push(unusedSum);
    const total = newValues.reduce((sum, a) => sum + a, 0);
    const remainingInPercent = ((unusedSum / total) * 100).toFixed(2);
    newDescriptions.push({ percent: remainingInPercent });
  }
  return {
    labels: newLabels,
    values: newValues,
    descriptions: newDescriptions,
  };
};

export default processChartData;
