import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useReducer, useState } from 'react';

import { UtilityTokenAssetDetailView as UtilityTokenAssetDetail } from '@trustwise/design-system';
import { PageContent } from 'core/page';
import { onFileDownload } from 'media/utils';
import { useResponseStatus } from 'core/hooks';
import BackButton from 'core/page/parts/backButton';
import { getUtilityTokenDetail, onTransfersFetch } from './actions/detail';
import reducer from '../reducers';

const UtilityTokensAssetDetailView = () => {
  const navigate = useNavigate();

  const { portfolioId, tokenId } = useParams();
  const [responseStatus] = useResponseStatus();
  const [activePage, setActivePage] = useState('overview');

  const [state, dispatch] = useReducer(reducer, { asset: {} });
  const { asset, transactions } = state;
  const { name } = asset;

  useEffect(() => {
    getUtilityTokenDetail({ portfolioId, tokenId, dispatch });
  }, [portfolioId, tokenId]);

  useEffect(() => {
    onTransfersFetch({ tokenId, portfolioId, dispatch });
  }, [portfolioId, tokenId]);

  return (
    <PageContent
      fullscreen
      pageStatus={responseStatus}
      pageHeading={name || ''}
      headerLeft={<BackButton onClick={() => navigate('/utility/')} />}
    >
      <UtilityTokenAssetDetail
        activeTab={activePage}
        onTabSwitch={(tabId) => setActivePage(tabId)}
        overview={{
          details: asset.overviewDetails,
          previewDetails: asset.previewDetails,
        }}
        transactions={transactions}
        onTransfer={() => navigate('transfer/', { state: { asset } })}
        onBurn={() => navigate('burn/', { state: { asset } })}
        onFileDownload={(fileId, documents) => onFileDownload(
          fileId,
          documents,
          `/assets/utility-tokens/${portfolioId}/${tokenId}/events/`,
        )}
      />
    </PageContent>
  );
};

export default UtilityTokensAssetDetailView;
