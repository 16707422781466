import { getApiUrl } from 'utils/urls';
import { ArtworkListingModel, MarketsListingOrderModel } from 'markets/models';
import axios from 'core/axios';
import convertResponseToModel from 'utils/responseToModel';
import { signFormTransaction } from 'utils/signTransaction';
import { LISTINGS_PAGE_SIZE, TEMP_LISTINGS_PAGE_SIZE } from '../consts';

export const fetchOrders = ({ tokenId, dispatch, queryArgs }) => axios
  .get(getApiUrl(`/markets/${tokenId}/orders/`, queryArgs))
  .then(({ data }) => {
    dispatch({ type: 'fetchOrders', data: data.results || data });
    return MarketsListingOrderModel.fromResponse(data.results || data);
  })
  .catch(console.error);

export const fetchListings = ({ basePath, queryArgs, dispatch }) => axios
  .get(getApiUrl(basePath, { ...queryArgs, page_size: TEMP_LISTINGS_PAGE_SIZE }))
  .then(({ data: { count, results } }) => {
    dispatch({ type: 'fetchListings', data: { count, results } });
    return convertResponseToModel(results, ArtworkListingModel);
  })
  .catch(console.error);

export const onListingsFetch = (basePath, queryArgs) => axios
  .get(getApiUrl(basePath, { ...queryArgs, page_size: LISTINGS_PAGE_SIZE }))
  .then(({ data: { results } }) => (
    // TODO: add pagination
    // const page = next ? new URLSearchParams(next).get('page') : ;
    // dispatch({ type: 'updateCurrentPage', data: page });
    // return response
    convertResponseToModel(results, ArtworkListingModel)
  ))
  .catch(console.error);

export const onBuyUnitsAccept = ({ submitPath, navigatePath, actions }) => signFormTransaction({
  urlPath: `${submitPath}accept/`,
  navigatePath,
  actions,
});
