/* eslint-disable max-len */
import PropTypes from 'prop-types';

const DocumentInCircleIcon = ({ className }) => (
  <svg width="32" height="32" fill="none" className={className}>
    <rect width="32" height="32" rx="16" fill="#F3F3F7" />
    <path fillRule="evenodd" clipRule="evenodd" d="M16.3125 8.68743H11.5C10.8672 8.68743 10.3542 9.20044 10.3542 9.83326V22.1666C10.3542 22.7994 10.8672 23.3124 11.5 23.3124H20.5C21.1328 23.3124 21.6459 22.7994 21.6459 22.1666V13.6874H16.8334C16.5457 13.6874 16.3125 13.4542 16.3125 13.1666V8.68743ZM20.8496 12.6458L17.3542 9.3688V12.6458H20.8496ZM22.6875 13.1712V22.1666C22.6875 23.3747 21.7081 24.3541 20.5 24.3541H11.5C10.2919 24.3541 9.3125 23.3747 9.3125 22.1666V9.83326C9.3125 8.62514 10.2919 7.64576 11.5 7.64576H16.5038C16.5724 7.64576 16.6405 7.65097 16.7075 7.66117C16.8772 7.6189 17.0592 7.66444 17.1896 7.78663L17.43 8.01201L22.5229 12.7866C22.5327 12.7958 22.542 12.8052 22.5509 12.815C22.5965 12.8647 22.6311 12.9219 22.6542 12.9832C22.6672 13.0178 22.6765 13.0538 22.6819 13.0904C22.6859 13.117 22.6878 13.1441 22.6875 13.1712Z" fill="#41474D" />
  </svg>
);

DocumentInCircleIcon.defaultProps = {
  className: '',
};

DocumentInCircleIcon.propTypes = {
  className: PropTypes.string,
};

export default DocumentInCircleIcon;
