/* Form related utils  */
import { camelCase } from 'lodash';

export const parseErrorResponse = (responseErrors, setFieldError) => {
  responseErrors && Object.keys(responseErrors).forEach((key) => {
    const errors = Array.isArray(responseErrors[key]) ? responseErrors[key].join(' ') : responseErrors[key];
    setFieldError(camelCase(key), errors);
  });
};

export const fieldRequired = gettext('This field is required');

export const getSelectFieldOptions = (obj) => Object.entries(obj).map(
  ([key, value]) => ({ value: key, label: value }),
);
