import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import { MarketTilesListView as MarketTilesListViewDS } from '@trustwise/design-system';
import { MarketTypes } from 'markets/consts';
import { PageContent } from 'core/page';
import { getUrl } from 'utils/urls';
import BackButton from 'core/page/parts/backButton';

const MarketTilesListView = ({ availableMarketplaces }) => {
  const navigate = useNavigate();

  const onTileClick = (marketType) => {
    switch (marketType) {
      case MarketTypes.FX_RATES:
        window.location = getUrl('/markets/fx-prices/');
        break;
      case MarketTypes.PRIVATE_EQUITY_PRIMARY_MARKET:
        navigate('private-equity/subscriptions/');
        break;
      default:
        navigate(`${marketType}/`);
        break;
    }
  };

  const visibleMarketplaces = [...availableMarketplaces];

  if (['localhost', '0.0.0.0', '127.0.0.1', 'demo.twex.app', 'demo.trustwise.io'].includes(window.location.hostname)) {
    visibleMarketplaces.push('mediterranean_exchange', 'carbon_exchange');
  }

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Markets')}
      headerLeft={<BackButton href={getUrl()} />}
    >
      <MarketTilesListViewDS
        visibleMarketplaces={visibleMarketplaces}
        includedMarketplaces={availableMarketplaces}
        onTileClick={onTileClick}
      />
    </PageContent>
  );
};

MarketTilesListView.defaultProps = {
  availableMarketplaces: [],
};

MarketTilesListView.propTypes = {
  availableMarketplaces: PropTypes.arrayOf(PropTypes.string),
};

export default MarketTilesListView;
