import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useReducer } from 'react';

import { PropertyTokenBuyUnitsView } from '@trustwise/design-system';
import { getApiUrl } from 'utils/urls';
import { listingDetailReducer } from 'markets/reducers';
import { onAgreementDocumentDownload } from 'registeredObjects/views/actions';
import { PageContent } from 'core/page';
import { SetDisabled } from 'types';
import { signTransaction } from 'utils/signTransaction';
import { useMarketsListing } from 'markets/context';
import axios from 'core/axios';
import BackButton from 'core/page/parts/backButton';
import { onBuyUnitsAccept } from './actions';

const PropertyTokenOrderDetailView = () => {
  const { tokenId, orderId, marketType } = useParams();
  const navigate = useNavigate();

  const [state, dispatch] = useReducer(listingDetailReducer, {});
  const { orderDetail, estimatedFee } = state;

  const {
    marketPath,
    listingPath,
    orders,
    registeredObject: { details, media, coOwnership }, objectPath, agreements,
  } = useMarketsListing();

  const basePath = `/markets/${tokenId}/orders/`;

  useEffect(() => {
    axios.get(getApiUrl(`${basePath}${orderId}/`))
      .then(({ data }) => dispatch({ type: 'fetchOrderDetail', data }))
      .catch(console.error);
  }, [basePath, orderId]);

  useEffect(() => {
    if (orderDetail && !estimatedFee) {
      const { units, askPrice, currency } = orderDetail;
      const reqBody = { amount: units, price_per_unit: askPrice, currency };

      axios.post(getApiUrl(`${basePath}fee/`), reqBody)
        .then(({ data }) => dispatch({ type: 'fetchOrderFee', data }))
        .catch(console.error);
    }
  }, [basePath, orderDetail, estimatedFee]);
  const title = orderDetail?.isOrderCreator ? gettext('Sell Order') : gettext('Buy Units');

  return (
    <PageContent
      fullscreen
      pageStatus={undefined}
      pageHeading={orderDetail ? title : ''}
      headerLeft={<BackButton onClick={() => navigate(`/${marketType}/${tokenId}/`)} />}
    >
      <PropertyTokenBuyUnitsView
        agreements={agreements}
        details={details?.details}
        media={media}
        orderDetails={orderDetail ? { ...orderDetail.toObject(), estimatedFee } : undefined}
        onAgreementDownload={(docId: number) => onAgreementDocumentDownload({
          docId, documents: coOwnership.agreements, objectPath,
        })}
        onOrderCancel={(setDisabled: SetDisabled) => signTransaction({
          urlPath: `${basePath}${orderId}/delete/`,
          navigatePath: orders?.length === 1 ? marketPath : listingPath,
          setDisabled,
        })}
        onSubmit={(_values, actions) => onBuyUnitsAccept({
          submitPath: `${basePath}${orderId}/`,
          navigatePath: '/transfers/',
          actions,
        })}
      />
    </PageContent>
  );
};

export default PropertyTokenOrderDetailView;
