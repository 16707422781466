import { useNavigate } from 'react-router-dom';

import {
  CertificatesIndexView as CertificatesIndexViewDS,
  CertificatesPage,
  CertificatesPages,
} from '@trustwise/design-system';

import { getUrl } from 'utils/urls';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import { getGlobalContext } from 'core/globals';
import { Feature, Features } from 'core/page/features';

const servicesToCertificatesPagesMap = {
  [Features.SERVICES_CERTIFICATES_ISSUER]: [CertificatesPages.CERTIFICATES_ISSUING],
  [Features.SERVICES_CERTIFICATES_MANAGER]: [
    CertificatesPages.CERTIFICATE_TYPES_MANAGEMENT,
    CertificatesPages.ISSUER_REGISTRIES,
  ],
};

export const CertificatesIndexView = () => {
  const navigate = useNavigate();

  const {
    activeEntity: { allowedFeatures },
  } = getGlobalContext();

  const pages: CertificatesPage[] = [];
  allowedFeatures.forEach((feature: Feature) => {
    const allowedPages = servicesToCertificatesPagesMap[feature];
    if (allowedPages) {
      pages.push(...allowedPages);
    }
  });

  return (
    <PageContent fullscreen pageHeading={gettext('Certificates')} headerLeft={<BackButton href={getUrl()} />}>
      <CertificatesIndexViewDS
        pages={pages}
        onClick={(page) => {
          switch (page) {
            case CertificatesPages.CERTIFICATE_TYPES_MANAGEMENT:
              navigate('management/types/');
              break;
            case CertificatesPages.ISSUER_REGISTRIES:
              window.location.href = 'issuer-registries/';
              break;
            case CertificatesPages.CERTIFICATES_ISSUING:
              navigate('issuing/');
              break;
            default:
              break;
          }
        }}
      />
    </PageContent>
  );
};
