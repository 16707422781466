import { Routes, Route } from 'react-router-dom';
import SubscriptionsList from 'subscriptions/generic/list';
import { getUrl, getApiUrl } from 'utils/urls';
import BackButton from 'core/page/parts/backButton';
import SubscriptionCertificateForm from './subscriptionForm';
import MarketsSubscriptionDetail from './detail';

const MarketsSubscriptionsNavigation = () => (
  <Routes>
    <Route
      path="/"
      element={(
        <SubscriptionsList
          onlyActive
          fetchDataUrl={getApiUrl('/markets/subscriptions/')}
          leftUrl={<BackButton href={getUrl('/markets/')} />}
        />
      )}
    />
    <Route path=":subscriptionId/subscribe/" element={<SubscriptionCertificateForm />} />
    <Route path=":subscriptionId/*" element={<MarketsSubscriptionDetail />} />
  </Routes>
);

export default MarketsSubscriptionsNavigation;
