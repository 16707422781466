import { useEffect, useState, useCallback } from 'react';

import { CbdcLiquidityWidget } from '@trustwise/design-system';
import axios from 'axios';
import { getApiUrl } from 'utils/urls';
import { getTimeSeriesQueryArgs } from 'utils/getTimeSeriesQueryArgs';
import { useAbortController } from 'core/hooks';

const LiquidityWidget = () => {
  const [timeSeries, setTimeSeries] = useState();
  const [currentBalanceData, setCurrentBalanceData] = useState();
  const [, updateController] = useAbortController();

  const onFetchTimeSeries = useCallback(
    (tab = '1y') => {
      const signal = updateController();
      setTimeSeries();
      const queryArgs = getTimeSeriesQueryArgs(tab);
      return (
        axios.get(getApiUrl('/payment-tokens/issuer/liquidity/', queryArgs), { signal })
          .then(({ data: {
            balance: currentBalance,
            currency_code: currencyCode,
            time_series: timeSeriesRecords,
          } }) => {
            const timeSeriesData = timeSeriesRecords.map(
              ({ balance: recordBalance, date_time: dateTime }) => ({
                balance: Number(recordBalance),
                dateTime: new Date(dateTime),
              }),
            );
            setTimeSeries(timeSeriesData);
            setCurrentBalanceData({
              currentBalance: Number(currentBalance),
              currencyCode,
            });
          })
          .catch(console.error)
      );
    },
    [updateController],
  );


  useEffect(() => {
    onFetchTimeSeries();
  }, [onFetchTimeSeries]);

  return (
    <CbdcLiquidityWidget
      {...currentBalanceData}
      timeSeries={timeSeries}
      onFetch={onFetchTimeSeries}
    />
  );
};

export default LiquidityWidget;
