import { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Quill from 'quill';
import DOMPurify from 'dompurify';
import marked from 'marked';
import TurndownService from 'turndown';
import { useFormikContext } from 'formik';
import TextField from './text';

const TextEditorComponent = ({ textEditorIndex, fieldName, initialValue, label, isOptional }) => {
  const { setFieldValue } = useFormikContext();

  const initQuill = useCallback(() => {
    const quill = new Quill(`.textEditorContent-${textEditorIndex}`, {
      modules: {
        toolbar: [
          [{ header: [1, 2, false] }],
          ['bold', 'italic', 'underline'],
          [{ list: 'ordered' }, { list: 'bullet' }],
        ],
      },
      theme: 'snow',
    });
    const cleanInitialValue = DOMPurify.sanitize(marked(initialValue));
    const delta = quill.clipboard.convert(cleanInitialValue);
    quill.setContents(delta);

    quill.on('text-change', (_delta, _oldDelta) => {
      const html = quill.container.firstChild.innerHTML;
      const turndownService = new TurndownService();
      setFieldValue(fieldName, turndownService.turndown(html));
    });
  }, [fieldName, initialValue, setFieldValue, textEditorIndex]);

  useEffect(() => {
    initQuill();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bottom-margin top-margin">
      <TextField label={label} name={fieldName} style={{ display: 'none' }} isOptional={isOptional} />
      <div className={`textEditorContent-${textEditorIndex}`} />
    </div>
  );
};


TextEditorComponent.defaultProps = {
  textEditorIndex: 0,
  initialValue: '',
  isOptional: false,
};

TextEditorComponent.propTypes = {
  textEditorIndex: PropTypes.number,
  fieldName: PropTypes.string.isRequired,
  initialValue: PropTypes.string,
  label: PropTypes.string.isRequired,
  isOptional: PropTypes.bool,
};


export default TextEditorComponent;
