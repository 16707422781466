/* eslint-disable no-underscore-dangle */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { observer, PropTypes as MobxPropTypes } from 'mobx-react';

import FxRatesGraph from './graph';
import GraphDateRange from './graph/dateRange';
import FxScopeSelector from './scopeSelector';
import CurrencyPairSelect from './currencyPairSelect';
import FxScores from './fxScores';
import Loader from '../../common/loader';


const renderNoDataAvailable = () => (
  <div className="no-data-graph">{gettext('No data available selected period')}</div>
);

const CurrentCurrencyPair = ({ fromCurrency, toCurrency }) => (
  <div className="current-currency-pair">
    {fromCurrency}
    {' / '}
    {toCurrency}
  </div>
);

CurrentCurrencyPair.propTypes = {
  fromCurrency: PropTypes.string.isRequired,
  toCurrency: PropTypes.string.isRequired,
};

class FxRatesComponent extends Component {
  componentDidMount() {
    const { store } = this.props;
    store.setProperty('isRendered', true);
  }

  componentWillUnmount() {
    const { store } = this.props;
    store.setProperty('isRendered', false);
  }

  renderGraph() {
    const { store } = this.props;

    if (store.data._isLoading) return <div className="loading-graph-data"><Loader isLoading /></div>;

    if (!store.data._isLoading && !store.data.length) return renderNoDataAvailable();

    return (
      <FxRatesGraph data={store.data} />
    );
  }

  render() {
    const { store } = this.props;

    return (
      <div className="cell small-12">
        <CurrentCurrencyPair
          fromCurrency={store.fromCurrency}
          toCurrency={store.toCurrency}
        />
        <FxScores
          lowest={store.data.lowestRate}
          latest={store.data.latestRate}
          highest={store.data.highestRate}
        />
        {this.renderGraph()}
        <GraphDateRange
          from={store.data.earliest && store.data.earliest.formattedDate}
          to={store.data.earliest && store.data.latest.formattedDate}
        />
        <FxScopeSelector
          options={store.constructor.scopeOptions}
          onSelect={store.setSinceDate}
          selected={store.selectedSinceOption}
        />
        <CurrencyPairSelect
          fromCurrency={store.fromCurrency}
          toCurrency={store.toCurrency}
          options={store.currencyOptions}
          onSetFrom={store.setFromCurrency}
          onSetTo={store.setToCurrency}
          onSwitchClick={store.switchCurrencyPair}
        />
        <span className="fx-source helptext text-tiny">
          {gettext('Source: ECB Euro foreign exchange daily reference rates (16:00 CET fixing)')}
        </span>
      </div>
    );
  }
}

FxRatesComponent.propTypes = {
  store: MobxPropTypes.objectOrObservableObject.isRequired,
};

export default observer(FxRatesComponent);
