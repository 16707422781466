import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { SHAREHOLDERS } from 'governanceEvents/const';
import { EventsContext } from 'governanceEvents/context';
import { GovEventsRoutes } from 'governanceEvents/navigation';

export const AssetEvents = () => {
  const { tokenId, portfolioId } = useParams();
  const path = `/assets/equity/${portfolioId}/${tokenId}/`;

  const contextValue = useMemo(() => ({
    basePath: path,
    indexPath: `/equity/${portfolioId}/${tokenId}/`,
    targetGroup: SHAREHOLDERS,
    allowedPricingActions: [],
  }), [path, tokenId, portfolioId]);

  return (
    <EventsContext.Provider value={contextValue}>
      <GovEventsRoutes tokenId={Number(tokenId)} previousPath={path} />
    </EventsContext.Provider>
  );
};
