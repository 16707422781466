import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { PageContent } from 'core/page';
import axios from 'core/axios';
import { getApiUrl, getUrl } from 'utils/urls';
import BackButton from 'core/page/parts/backButton';
import SubscriptionDetailContent from '../generic/detail';


const MarketsSubscriptionDetail = () => {
  const [subscription, setSubscription] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { pathname } = useLocation();
  const { subscriptionId } = useParams();
  const timestampNow = new Date() / 1000;

  useEffect(() => {
    setIsLoading(true);
    axios.get(getApiUrl(`/markets/subscriptions/${subscriptionId}/`))
      .then(({ data }) => { setSubscription(data); })
      .catch(console.error)
      .finally(() => { setIsLoading(false); });
  }, [subscriptionId]);

  const actions = [];
  if (
    Object.entries(subscription).length > 0
    && timestampNow < subscription.end_timestamp
    && (!subscription.max_number_of_shares || (subscription.total_subscribed < subscription.max_number_of_shares))
  ) {
    const data = {
      app_label: 'contracts',
      model: 'contract',
      object_id: subscriptionId,
      topic: 'token_subscription',
    };
    actions.push({
      type: 'form',
      icon: 'times',
      label: gettext('Not interested'),
      onClick: () => {
        axios.post(getApiUrl('/inbox/delete-visibility/'), data)
          .then(window.location = getUrl())
          .catch(console.error);
      },
    });
    if (subscription.start_timestamp < timestampNow) {
      actions.push({
        url: `${pathname}subscribe/`,
        icon: 'check',
        label: gettext('I want to subscribe'),
      });
    }
  }

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Terms of Subscription')}
      headerLeft={<BackButton href={getUrl('/markets/private-equity/subscriptions/')} />}
      actions={actions}
    >
      <SubscriptionDetailContent
        subscription={subscription}
        isLoading={isLoading}
      />
    </PageContent>
  );
};


export default MarketsSubscriptionDetail;
