import PropTypes from 'prop-types';
import EmptyContent from 'core/page/emptyContent';
import emptyStateIcons from '../svgs/emptyStateIcons';
import TableLoading from './tableLoading';


const Table = ({ headers, rows, isLoading }) => {
  if (isLoading) {
    return <TableLoading />;
  }
  if (rows.length === 0) {
    return (
      <EmptyContent
        iconName={emptyStateIcons.shareholders}
        text={gettext('No accounts')}
      />
    );
  }

  return (
    <table className="table-content">
      <thead>
        <tr>
          {headers.map(({ key, label }) => (
            <th key={key}>{label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <tr key={row.key}>
            {row.data.map((data) => (
              <td key={data.key} className={data.className}>{data.content}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};


Table.defaultProps = {
  isLoading: true,
};

Table.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  rows: PropTypes.arrayOf(PropTypes.shape).isRequired,
  isLoading: PropTypes.bool,
};

export default Table;
