import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { ReactSelectField } from 'core/forms/fields';
import { customStyles } from 'core/forms/fields/select';
import {
  bodyFontSize2,
  colorNeutral20,
  pageSizeSelectWidth,
  pageSizeSelectHeight,
  pageSizeSelectPadding,
} from '../styles';


const pageSizeSelectStyles = {
  ...customStyles,
  container: (provided) => ({
    ...provided,
    paddingRight: pageSizeSelectPadding,
    margin: 0,
    height: pageSizeSelectHeight,
  }),
  control: () => ({
    borderBottom: 'none',
    width: pageSizeSelectWidth,
    display: 'flex',
    justifyContent: 'space-between',
  }),
  singleValue: (provided) => ({
    ...provided,
    ...bodyFontSize2,
    color: colorNeutral20,
    margin: 0,
  }),
  menu: (provided) => ({
    ...provided,
    width: pageSizeSelectWidth,
  }),
  placeholder: () => ({ display: 'none' }),
};

const PageSizeSelect = ({
  setPageSize,
  defaultPageSize,
  perPageOptions,
}) => (
  <Formik initialValues={{ pageSize: defaultPageSize }}>
    <ReactSelectField
      isSearchable={false}
      styles={pageSizeSelectStyles}
      name="pageSize"
      options={perPageOptions}
      defaultValue={perPageOptions.find((option) => option.value === defaultPageSize)}
      getOptionValue={(option) => option.value}
      getOptionLabel={(option) => option.label}
      setValue={(option) => setPageSize(option.value)}
    />
  </Formik>
);

PageSizeSelect.defaultProps = {
  perPageOptions: [
    { value: 10, label: gettext('10 per page') },
    { value: 20, label: gettext('20 per page') },
    { value: 50, label: gettext('50 per page') },
    { value: 100, label: gettext('100 per page') },
  ],
  defaultPageSize: 10,
};

PageSizeSelect.propTypes = {
  perPageOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
    label: PropTypes.string,
  })),
  setPageSize: PropTypes.func.isRequired,
  defaultPageSize: PropTypes.number,
};

export default PageSizeSelect;
