export const manualEstimate = 'ME';
export const manualLastPaid = 'MP';
export const legalNominal = 'LN';
export const legalTax = 'LT';
export const fixing = 'FX';

export const valuationOptions = [
  {
    label: gettext('Manual'),
    options: [
      { value: manualEstimate, label: gettext('Estimate') },
      { value: manualLastPaid, label: gettext('Last Paid') },
    ],
  },
  {
    label: gettext('Legal'),
    options: [
      { value: legalNominal, label: gettext('Nominal') },
      { value: legalTax, label: gettext('Tax') },
    ],
  },
  {
    label: gettext('Market'),
    options: [
      { value: fixing, label: gettext('Fixing') },
    ],
  },
];
export const valuationOptionsKeys = valuationOptions.map(({ options }) => options.map((item) => item.value)).flat(2);
export const assetValuationOptions = [{ value: manualEstimate, label: gettext('Estimate') }];
export const allValuationTypes = {
  [manualEstimate]: gettext('Estimate'),
  [manualLastPaid]: gettext('Last Paid'),
  [legalNominal]: gettext('Nominal'),
  [legalTax]: gettext('Tax'),
  [fixing]: gettext('Fixing'),
};
