import { convertKeysToCamelCase } from 'core/utils';

/**
 * @todo consider introducing a separate module
 * @todo consider adding more fields
 */
export default class InvoiceModel {
  constructor({
    id = null,
    number = '',
    startDate = null,
    endDate = null,
    dueDate = null,
    currency = '',
    total = null,
    status,
  }) {
    this.id = id;
    this.number = number;
    this.startDate = startDate ? new Date(startDate) : startDate;
    this.endDate = endDate ? new Date(endDate) : endDate;
    this.dueDate = dueDate ? new Date(dueDate) : dueDate;
    this.currency = currency;
    this.total = total;
    this.status = status;
  }

  static fromResponse(data) {
    if (Array.isArray(data)) {
      return data.map((doc) => this.fromResponse(doc));
    }
    const convertedCase = convertKeysToCamelCase(data);
    const filteredEntries = Object.entries(convertedCase).filter(([_, v]) => v !== null);
    return new InvoiceModel(Object.fromEntries(filteredEntries));
  }

}
