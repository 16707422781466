import { useState, useEffect } from 'react';

import { PaymentTokenTransactionsWidget as PaymentTokenTransactions } from '@trustwise/design-system';
import { getPaymentTokensTransfers } from 'investments/paymentTokens/views/actions';
import { redirectTo } from 'core/utils';

const PaymentTokensTransfersWidget = () => {
  const [transfers, setTransfers] = useState();

  useEffect(() => {
    getPaymentTokensTransfers(setTransfers, { no_of_items: 4 });
  }, []);

  return (
    <PaymentTokenTransactions
      transactions={transfers}
      onSeeAll={() => redirectTo('/assets/cash/transfers')}
    />
  );
};

export default PaymentTokensTransfersWidget;
