import PropTypes from 'prop-types';
import { TabButtonSkeleton } from '@trustwise/design-system';
import { BaseButton } from 'core/forms/fields/button';


const Tabs = ({ items, isLoading, classNames }) => {
  const skeleton = (
    <>
      {[1, 2, 3].map((item) => <TabButtonSkeleton key={item} />)}
    </>
  );
  return (
    <ul className={`tabs ${classNames}`}>
      {isLoading ? skeleton : (
        items.map((item) => (
          <li key={item.label} className={`item${item.isActive ? ' active' : ''}`}>
            <BaseButton
              className={`button tab-button button-borderless button-small${item.isActive ? ' active' : ''}`}
              onClick={item.onClick}
              disabled={item.isDisabled || false}
            >
              {item.label}
            </BaseButton>
          </li>
        )))}
    </ul>
  );
};

Tabs.defaultProps = {
  isLoading: true,
  classNames: '',
};

Tabs.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    isActive: PropTypes.bool,
    label: PropTypes.string.isRequired,
    isDisabled: PropTypes.bool,
    onClick: () => { },
  })).isRequired,
  isLoading: PropTypes.bool,
  classNames: PropTypes.string,
};

const TabsWithContent = ({ tabs, children, isLoading, classNames }) => (
  <>
    <Tabs items={tabs} isLoading={isLoading} classNames={classNames} />
    {children}
  </>
);

TabsWithContent.defaultProps = {
  isLoading: true,
  classNames: '',
};

TabsWithContent.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    isActive: PropTypes.bool,
    label: PropTypes.string.isRequired,
    onClick: () => {},
  })).isRequired,
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  classNames: PropTypes.string,
};

export { Tabs, TabsWithContent };
