/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';


const EventShareClassesList = ({ shareClasses, isLoading }) => (
  shareClasses.length ? (
    <div className="bottom-margin">
      <table className="v-align-middle text-tiny">
        <thead>
          <tr>
            <th className="align-text-left borderless not-padded">{gettext('Name')}</th>
            <th className="align-text-right borderless not-padded">{gettext('Nominal Value')}</th>
            <th className="align-text-right borderless not-padded">{gettext('No. of Shares')}</th>
            <th className="align-text-right borderless not-padded hide-for-small-only">{gettext('Total Capital')}</th>
          </tr>
        </thead>
        <tbody>
          {shareClasses.map((item) => (
            <tr key={item.id}>
              <td className="font-bold">
                {`${item.name}${item.status === 'deleted' ? ' (invalidated)' : ''}`}
              </td>
              <td className="align-text-right">
                {`${item.currency} ${item.nominal_value}`}
              </td>
              <td className="align-text-right">
                {item.total_supply}
              </td>
              <td className="align-text-right hide-for-small-only">
                {`${item.currency} ${item.total_capital}`}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : (
    isLoading ? <div className="loader" /> : <p className="text-center">{gettext('Share classes are not selected')}</p>
  )
);


EventShareClassesList.defaultProps = {
  shareClasses: [],
  isLoading: false,
};

EventShareClassesList.propTypes = {
  shareClasses: PropTypes.arrayOf(PropTypes.shape()),
  isLoading: PropTypes.bool,
};

export default EventShareClassesList;
