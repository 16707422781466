import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useReducer } from 'react';

import { DistributeUtilityTokenView } from '@trustwise/design-system';
import { getGlobalContext } from 'core/globals';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import useContacts from 'contacts/hooks';
import { getUtilityTokenDetail, onTransfer } from './actions';
import { UtilityTokenTypeLabels } from '../consts';
import reducer from '../reducers';

const UtilityTokensAssetsTransferView = () => {
  const navigate = useNavigate();
  const { state: { asset: stateAsset } } = useLocation();

  const { portfolioId, tokenId } = useParams();
  const { onFetch: onContactsFetch } = useContacts(true, { 'entity-type': 'company' });
  const { activeEntity: { baseCurrency } } = getGlobalContext();

  const [state, dispatch] = useReducer(reducer, { asset: stateAsset || {} });
  const { asset } = state;
  const { name, type, totalAmount, unit } = asset;

  useEffect(() => {
    if (!stateAsset) {
      getUtilityTokenDetail({ portfolioId, tokenId, dispatch });
    }
  }, [portfolioId, tokenId, stateAsset]);

  return (
    <PageContent
      fullscreen
      pageHeading={type ? gettext(`Transfer of ${UtilityTokenTypeLabels[type]}`) : ''}
      headerLeft={<BackButton onClick={() => navigate(`/utility/${portfolioId}/${tokenId}/`)} />}
    >
      <DistributeUtilityTokenView
        isAssetsView
        name={name}
        type={type}
        amount={totalAmount}
        unit={unit}
        onContactsFetch={onContactsFetch}
        onContactSearch={({ searchString }) => onContactsFetch(searchString)}
        currency={baseCurrency}
        onSubmit={(values, actions) => onTransfer({
          submitPath: `/assets/utility-tokens/${portfolioId}/${tokenId}/transfer/`,
          navigatePath: `/assets/utility/${portfolioId}/${tokenId}/`,
          values,
          actions,
        })}
      />
    </PageContent>
  );
};

export default UtilityTokensAssetsTransferView;
