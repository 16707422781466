import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { snakeCase, pick, pickBy, identity, intersection } from 'lodash';

import { FullPageContent } from 'core/page';
import {
  CheckboxField,
  TextField,
  ReactSelectField,
  DateField,
  PhoneNumberInput,
  EmailField,
  UrlField,
  SubmitButton,
} from 'core/forms/fields';
import FormRouter from 'core/forms/formRouter';
import axios from 'core/axios';
import { parseErrorResponse } from 'core/forms/utils';

import { convertKeysCase, convertKeysToCamelCase } from 'core/utils';
import DocumentationModal from 'core/documentationModal';
import { FileModel } from 'media/models';
import BackButton from 'core/page/parts/backButton';
import UserAgreement from 'registration/userAgreement';
import { initialValues } from 'registration/company/initialValues';
import { jurisdictionsOptions } from '../constants/jurisdictions';
import getCompanyRegistrationValidation from './validation';
import LegalDocuments from './parts/legalDocuments';
import PricingInfo from '../pricingInfo';
import { businessTypesOptions, DEFAULT, MUNICIPALITY } from '../constants/companyTypes';

const CompanyRegistrationForm = ({ companyId }) => {
  const baseUrl = '/api/v1/registration/company/';
  const [showInfo, setShowInfo] = useState(false);
  const [newCompanyId, setNewCompanyId] = useState(companyId);

  const [countryOptions, setCountryOptions] = useState([]);
  useEffect(() => {
    const url = '/api/v1/registration/countries/';
    axios.get(url).then(({ data }) => { setCountryOptions(data); }).catch(console.error);
  }, []);

  const [phoneCountryCode, setPhoneCountryCode] = useState('ch');

  const [initialData, setInitialData] = useState(initialValues);

  useEffect(() => {
    if (companyId) {
      const url = `${baseUrl}${companyId}`;
      axios.get(url)
        .then(({ data }) => { setInitialData(convertKeysToCamelCase(data)); })
        .catch(console.error);
    }
  }, [companyId]);

  const postData = (data, url, onSuccess, onError) => {
    axios.patch(url, data)
      .then(onSuccess(), ({ response: { data: errors } }) => { onError(errors); })
      .catch(console.error);
  };

  const createCompany = (data, onSuccess, onError) => {
    axios.post(baseUrl, data)
      .then(
        ({ data: { id } }) => { setNewCompanyId(id); onSuccess(); },
        ({ response: { data: errors } }) => { onError(errors); },
      )
      .catch(console.error);
  };

  const handleSubmit = (_values, actions) => {
    axios.patch(`${baseUrl}${newCompanyId}/`)
      .then(
        () => { window.location.href = '/'; },
        ({ response: { data: errors } }) => {
          parseErrorResponse(errors, actions.setFieldError);
          actions.setSubmitting(false);
        },
      )
      .catch((err) => {
        actions.setSubmitting(false);
        console.error(err);
      });
  };

  let pageHeading = '';
  switch (initialData.status) {
    case 'created':
      pageHeading = gettext('Continue registration');
      break;
    case 'rejected':
      pageHeading = gettext('Resubmit registration');
      break;
    default:
      pageHeading = gettext('Register business');
  }

  return (
    <FullPageContent
      pageHeading={pageHeading}
      headerLeft={<BackButton onClick={() => { window.history.back(); }} />}
    >
      <>
        {showInfo && (
          <DocumentationModal
            isOpen={showInfo}
            onClose={() => { setShowInfo(false); }}
            modalId="companyRegistrationInfo"
            documentationPath="accounts/registration/business/"
          />
        )}
        <Formik
          initialValues={{
            agreementConfirmation: false,
            warrantiesConfirmation: false,
            registeringUserResponsibility: false,
            ...initialData,
            poaFiles: (
              initialData.poaFiles.length
                ? initialData.poaFiles.map((file) => (new FileModel(convertKeysToCamelCase(file))))
                : initialData.poaFiles
            ),
            excerptFiles: (
              initialData.excerptFiles.length
                ? initialData.excerptFiles.map((file) => (new FileModel(convertKeysToCamelCase(file))))
                : initialData.excerptFiles
            ),
            intentionFiles: (
              initialData.intentionFiles.length
                ? initialData.intentionFiles.map((file) => (new FileModel(convertKeysToCamelCase(file))))
                : initialData.intentionFiles
            ),
          }}
          enableReinitialize
          onSubmit={handleSubmit}
          validationSchema={getCompanyRegistrationValidation(phoneCountryCode)}
        >
          {({ isValid, dirty, errors, isSubmitting, values, setFieldError, setTouched }) => {

            const onError = (responseErrors) => { parseErrorResponse(responseErrors, setFieldError); };
            let legalInfoFieldsNames = ['jurisdiction', 'companyType', 'name', 'legalId', 'incDate', 'domicile'];
            if (!businessTypesOptions[values.jurisdiction]) {
              legalInfoFieldsNames = ['jurisdiction', 'name', 'legalId', 'incDate', 'domicile'];
            } else if (values.companyType === MUNICIPALITY.key) {
              legalInfoFieldsNames = ['jurisdiction', 'companyType', 'name'];
            }

            const onStepSubmit = (step, navigate, to) => {
              const touchedFields = {};
              step.fieldNames.forEach((field) => { touchedFields[field] = true; });
              setTouched(touchedFields).then((err) => {
                if (!Object.keys(err).filter((fieldName) => step.fieldNames.includes(fieldName)).length) {
                  navigate(to);
                }
              });
            };

            const legalInfoFields = {
              jurisdiction: (
                <ReactSelectField
                  key="jurisdiction"
                  label={gettext('Jurisdiction')}
                  name="jurisdiction"
                  options={jurisdictionsOptions}
                  getOptionValue={(option) => option.code}
                  getOptionLabel={(option) => option.label}
                  isSearchable
                />
              ),
              companyType: (
                <ReactSelectField
                  key="companyType"
                  label={gettext('Company type:')}
                  name="companyType"
                  getOptionValue={(option) => option.key}
                  getOptionLabel={(option) => option.label}
                  options={businessTypesOptions[values.jurisdiction]}
                />
              ),
              name: <TextField key="name" label={gettext('Name')} name="name" />,
              legalId: <TextField key="legalId" label={gettext('Company registration ID')} name="legalId" />,
              incDate: <DateField key="incDate" label={gettext('Date of incorporation')} name="incDate" />,
              domicile: <TextField key="domicile" label={gettext('Domicile')} name="domicile" />,
            };

            const steps = [
              {
                pathName: 'pricing-info',
                fieldNames: [],
                component: <PricingInfo isCompany />,
              },
              {
                pathName: 'legal-info',
                onStepSubmit: (step, navigate, to) => {
                  // Used setTouched as a workaround since validateField didn't work as expected
                  const touchedFields = {};
                  step.fieldNames.forEach((field) => { touchedFields[field] = true; });
                  setTouched(touchedFields).then((err) => {
                    if (!Object.keys(err).filter((fieldName) => step.fieldNames.includes(fieldName)).length) {
                      const stepValues = pick(values, step.fieldNames);
                      const data = convertKeysCase(stepValues, snakeCase);
                      if (!('company_type' in data)) { data.company_type = DEFAULT.key; }
                      const onSuccess = () => { navigate(to); };
                      if (!newCompanyId) {
                        createCompany(data, onSuccess, onError);
                      } else {
                        postData(data, `${baseUrl}${newCompanyId}/${step.pathName}/`, onSuccess, onError);
                      }
                    }
                  });
                },
                fieldNames: legalInfoFieldsNames,
                component: <>{legalInfoFieldsNames.map((field) => (legalInfoFields[field]))}</>,
              },
              {
                pathName: 'contact-details',
                onStepSubmit: (step, navigate, to) => {
                  const touchedFields = {};
                  // eslint-disable-next-line
                  step.fieldNames.map(field => touchedFields[field] = true);
                  setTouched(touchedFields).then((err) => {
                    if (!intersection(Object.keys(err), step.fieldNames).length) {
                      const stepValues = pickBy(pick(values, step.fieldNames), identity);
                      const phoneNumberValue = values.phoneNumber.startsWith('+') ? values.phoneNumber : `+${values.phoneNumber}`;
                      const data = convertKeysCase({ ...stepValues, phoneNumber: phoneNumberValue }, snakeCase);
                      const onSuccess = () => { navigate(to); };
                      postData(data, `${baseUrl}${newCompanyId}/${step.pathName}/`, onSuccess, onError);
                    }
                  });
                },
                component: (
                  <>
                    <TextField
                      label={gettext('Address Line 1')}
                      name="street"
                      placeholder={gettext('Street and number, P.O. box, c/o')}
                    />
                    <TextField
                      label={gettext('Address Line 2')}
                      name="additionalAddress"
                      placeholder={gettext('Apartment, unit, building, floor')}
                      isOptional
                    />
                    <TextField label={gettext('Postal Code')} name="postalCode" />
                    <TextField label={gettext('City')} name="city" />
                    <TextField label={gettext('Area/District/Canton/County')} name="county" />
                    <ReactSelectField
                      label={gettext('Country')}
                      name="countryCode"
                      options={countryOptions}
                      getOptionValue={(option) => option.code}
                      getOptionLabel={(option) => option.label}
                      isSearchable
                    />
                    <PhoneNumberInput
                      label={gettext('Phone Number')}
                      name="phoneNumber"
                      country="ch"
                      preferredCountries={['ch', 'de', 'at']}
                      setPhoneCountryCode={setPhoneCountryCode}
                    />
                    <EmailField label={gettext('E-mail')} name="email" />
                    <UrlField label={gettext('Homepage')} name="homepage" />
                  </>
                ),
              },
              {
                pathName: 'legal-documents',
                onStepSubmit: (step, navigate, to) => {
                  const touchedFields = {};
                  // eslint-disable-next-line
                  step.fieldNames.map(field => touchedFields[field] = true);
                  setTouched(touchedFields).then((err) => {
                    if (!intersection(Object.keys(err), step.fieldNames).length) {
                      const data = new FormData();
                      data.append(snakeCase('noSingleSignatureRight'), values.noSingleSignatureRight);
                      data.append(snakeCase('companyInFoundation'), values.companyInFoundation);
                      data.append(snakeCase('publicRegistryLink'), values.publicRegistryLink);
                      values.excerptFiles.length > 0 ?
                        values.excerptFiles.forEach((file) => {
                          file instanceof File && data.append(snakeCase('excerptFiles'), file);
                        }) : data.append('remove_excerpt_files', true);
                      values.poaFiles.length > 0 && values.poaFiles.forEach((file) => {
                        file instanceof File && data.append(snakeCase('poaFiles'), file);
                      });
                      values.intentionFiles.length > 0 && values.intentionFiles.forEach((file) => {
                        file instanceof File && data.append(snakeCase('intentionFiles'), file);
                      });
                      axios.patch(`${baseUrl}${newCompanyId}/${step.pathName}/`, data)
                        .then(
                          () => { navigate(to); },
                          ({ response: { data: errResponse } }) => { onError(errResponse); },
                        )
                        .catch(console.error);
                    }
                  });
                },
                fieldNames: (
                  // eslint-disable-next-line no-nested-ternary
                  values.companyInFoundation
                    ? ['companyInFoundation', 'intentionFiles', 'registeringUserResponsibility']
                    : (
                      values.noSingleSignatureRight
                        ? ['noSingleSignatureRight', 'companyInFoundation', 'publicRegistryLink', 'excerptFiles', 'poaFiles']
                        : ['noSingleSignatureRight', 'companyInFoundation', 'publicRegistryLink', 'excerptFiles']
                    )
                ),
                component: <LegalDocuments />,
              },
              {
                pathName: 'corporate-user-agreement',
                onStepSubmit,
                component: (
                  <>
                    <UserAgreement type="corporate" />
                    <CheckboxField
                      label={gettext('I agree on behalf of the company I legally represent')}
                      name="agreementConfirmation"
                    />
                  </>
                ),
              },
              {
                pathName: 'warranties',
                onStepSubmit,
                component: (
                  <>
                    <div className="scrollable-content-area margin-bottom">
                      <h2>{gettext('WARRANTIES BY USER')}</h2>
                      <p>
                        {gettext(
                          'CORPORATE USER confirms that it is obliged to keep a physical copy of'
                        + ' its identity provided in TWEX®.',
                        )}
                      </p>
                    </div>
                    <CheckboxField
                      label={gettext('I confirm on behalf of the company I legally represent')}
                      name="warrantiesConfirmation"
                    />
                  </>
                ),
              },
            ];
            return (
              <Form className="limited-width">
                <FormRouter
                  steps={steps}
                  basePath={companyId ? `/registration/company/${companyId}/resubmit/` : '/registration/company/'}
                  formProps={{ dirty, errors }}
                  submitButton={(
                    <SubmitButton disabled={!isValid || isSubmitting} className="top-margin">
                      {gettext('Submit')}
                    </SubmitButton>
                  )}
                />
              </Form>
            );
          }}
        </Formik>
      </>
    </FullPageContent>
  );
};

CompanyRegistrationForm.defaultProps = {
  companyId: null,
};

CompanyRegistrationForm.propTypes = {
  companyId: PropTypes.string,
};


export default CompanyRegistrationForm;
