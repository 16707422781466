import { snakeCase } from 'lodash';

import { convertKeysCase } from 'core/utils';
import { signFormTransaction } from 'utils/signTransaction';

export const onBoardMemberEdit = ({ values, actions, submitPath, navigatePath }) => signFormTransaction({
  urlPath: submitPath,
  navigatePath,
  data: convertKeysCase(values, snakeCase),
  actions,
});

export const onBoardMemberDismiss = ({ values, actions, submitPath, navigatePath }) => signFormTransaction({
  urlPath: submitPath,
  navigatePath,
  data: convertKeysCase(values, snakeCase),
  actions,
});
