import { formatNumber } from 'core/utils';
import { RegisteredObjectDetailModel } from 'registeredObjects/models/registeredObject';
import { RegisteredObjectDetailResponseProps } from 'registeredObjects/interfaces/response';
import { DocumentResponseProps } from 'media/interfaces/response';
import { ArtworkListingResponseProps, FeeResponseProps, MarketOrderResponseProps } from 'markets/interfaces/response';
import { PaginatedResponseProps } from 'core/interfaces/response';
import { ArtworkListingModel, MarketsListingOrderModel } from './models';

interface ListingsProps {
  /**
   * List of listings
   */
  listings?: ArtworkListingModel[];
  /**
   * Total number of listings
   */
  totalListings?: number;
}

interface ListingDetailsProps {
  /**
   * Listing details
   */
  listing: ArtworkListingModel;
  /**
   * Listing related orders
   */
  orders: MarketsListingOrderModel[];
  /**
   * Listing related registered object details
   */
  registeredObject: RegisteredObjectDetailModel;
}

interface OrderDetailProps {
  /**
   * Order detail
   */
  orderDetail: MarketsListingOrderModel;
  /**
   * Estimated fee for the order
   */
  estimatedFee: { fee: number, currency: string };
}

interface ListingAction {
  /**
   * Response data
   */
  data: RegisteredObjectDetailResponseProps
  | ArtworkListingResponseProps
  | PaginatedResponseProps<ArtworkListingResponseProps>;
  /**
   * Action type
   */
  type: 'fetchListings'
    | 'fetchDetail'
    | 'fetchObjectDetail'
    | 'fetchOrders'
    | 'resetOrders';
}

interface ListingState extends ListingDetailsProps, ListingsProps, OrderDetailProps {}

export const listingsReducer = (currentState: ListingState, action: ListingAction) => {
  const { data, type } = action;

  switch (type) {
    case 'fetchListings': {
      const { results, count } = data as PaginatedResponseProps<ArtworkListingResponseProps>;
      return {
        ...currentState,
        listings: ArtworkListingModel.fromResponse(results),
        totalListings: count,
      };
    }
    case 'fetchDetail':
      return {
        ...currentState,
        listing: ArtworkListingModel.fromResponse(data),
      };

    case 'fetchObjectDetail': {
      const registeredObject = RegisteredObjectDetailModel.fromResponse(data);

      return {
        ...currentState,
        registeredObject,
        agreements: registeredObject.coOwnership.agreements.map(
          (agreement: DocumentResponseProps) => ({ ...agreement, name: agreement.subject }),
        ),
      };
    }

    case 'fetchOrders':
      return {
        ...currentState,
        orders: MarketsListingOrderModel.fromResponse(data),
      };

    case 'resetOrders':
      return {
        ...currentState,
        orders: undefined,
      };

    default:
      return currentState;
  }
};

interface OrderDetailAction {
  /**
   * Response data
   */
  data: MarketOrderResponseProps | FeeResponseProps;
  /**
   * Action type
   */
  type: 'fetchOrderDetail' | 'fetchOrderFee';
}

export const listingDetailReducer = (currentState, action: OrderDetailAction) => {
  const { data, type } = action;

  switch (type) {
    case 'fetchOrderDetail':
      return {
        ...currentState,
        orderDetail: MarketsListingOrderModel.fromResponse(data),
      };

    case 'fetchOrderFee': {
      const { fee, currency } = data as FeeResponseProps;
      return { ...currentState, estimatedFee: `${formatNumber(fee)} ${currency}` };
    }
    default:
      return currentState;
  }
};

