/* eslint-disable max-len */

const SignerPhone = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_8987_18845)">
      <rect width="32" height="32" rx="16" fill="#EFEBFA" />
      <rect x="9" y="7.5" width="14" height="33.1028" rx="3.28293" stroke="url(#paint0_linear_8987_18845)" strokeWidth="0.625" strokeLinecap="square" strokeLinejoin="round" />
      <rect x="12" y="24" width="8" height="3" rx="1.5" stroke="url(#paint1_linear_8987_18845)" strokeWidth="0.625" strokeLinecap="square" strokeLinejoin="round" />
      <circle cx="16" cy="12" r="1" fill="#453384" />
    </g>
    <defs>
      <linearGradient id="paint0_linear_8987_18845" x1="25.4779" y1="50.1236" x2="-4.33817" y2="36.3285" gradientUnits="userSpaceOnUse">
        <stop stopColor="#1B2352" />
        <stop offset="1" stopColor="#5E3CA2" />
      </linearGradient>
      <linearGradient id="paint1_linear_8987_18845" x1="21.4159" y1="27.8628" x2="19.241" y2="21.5179" gradientUnits="userSpaceOnUse">
        <stop stopColor="#1B2352" />
        <stop offset="1" stopColor="#5E3CA2" />
      </linearGradient>
      <clipPath id="clip0_8987_18845">
        <rect width="32" height="32" rx="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default SignerPhone;
