/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/button-has-type */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

const getNavStates = (indx, length) => {
  const styles = [];
  for (let i = 0; i < length; i += 1) {
    if (i < indx) {
      styles.push('complete');
    } else if (i === indx) {
      styles.push('current');
    } else {
      styles.push('upcoming');
    }
  }

  return { current: indx, styles };
};

const checkNavState = (currentStep, stepsLength) => {
  if (currentStep > 0 && currentStep < stepsLength - 1) {
    return {
      showPreviousBtn: true,
      showNextBtn: true,
      showSubmitButton: false,
    };
  } if (currentStep === 0) {
    return {
      showPreviousBtn: false,
      showNextBtn: true,
      showSubmitButton: false,
    };
  }
  return {
    showPreviousBtn: true,
    showNextBtn: false,
    showSubmitButton: true,
  };
};

class Stepper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNextBtn: true,
      compState: 0,
      showSubmitButton: false,
      navState: getNavStates(0, this.props.steps.length),
    };
  }

  setNavState = (next) => {
    const { steps } = this.props;
    this.setState({
      navState: getNavStates(next, steps.length),
    });
    if (next < steps.length) {
      this.setState({ compState: next });
    }
    this.setState(checkNavState(next, steps.length));
  };

  handleKeyDown = (evt) => {
    if (evt.which === 13) {
      evt.preventDefault();
      this.next();
    }
  };

  handleOnClick = (evt) => {
    const { allowFastForward, steps } = this.props;
    const { compState } = this.props;
    if (
      evt.currentTarget.value === steps.length - 1
      && compState === steps.length - 1
    ) {
      this.setNavState(steps.length);
    } else {
      allowFastForward && this.setNavState(evt.currentTarget.value);
    }
  };

  next = () => {
    const { compState } = this.state;
    const { onFormSubmit } = this.props.steps[compState].component.props;
    if (onFormSubmit) {
      onFormSubmit().then(() => {
        const { hasErrors } = this.props.steps[compState];
        if (!hasErrors) {
          this.setNavState(compState + 1);
        }
      });
    } else {
      this.setNavState(compState + 1);
    }
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  previous = () => {
    const { compState } = this.state;
    const { leftUrl } = this.props;

    if (compState > 0) {
      this.setNavState(compState - 1);
    } else {
      window.location.href = leftUrl;
    }
  };

  getClassName = (className, i) => {
    const { navState } = this.state;
    const { steps } = this.props;

    let cls = `progress-item ${className}-${navState.styles[i]}`;
    if (steps[i].hasErrors) {
      cls += ' has-error';
    }
    return cls;
  };

  renderSteps = () => {
    const { steps } = this.props;
    const { compState } = this.state;

    return steps.map((s, i) => (
      <li
        className={this.getClassName('progress-item', i)}
        onClick={this.handleOnClick}
        key={i}
        value={i}
      >
        <span>{compState === i ? steps[i].name : null}</span>
      </li>
    ));
  };

  submit = () => {
    const { onSubmit } = this.props;

    return onSubmit();
  };

  render() {
    const { steps, showNavigation } = this.props;
    const {
      compState, showNextBtn, showSubmitButton,
    } = this.state;

    const buttonDisabled = (
      steps[compState].component.type.name === 'WarrantiesConfirmation'
        && !steps[compState].component.props.model.warrantiesConfirmation
    ) || (
      steps[compState].component.type.name === 'CorporateUserAgreementConfirmation'
          && !steps[compState].component.props.model.agreementConfirmation
    ) || (
      steps[compState].component.type.name === 'Documents'
          && steps[compState].component.props.model.companyInFoundation
          && !steps[compState].component.props.model.registeringUserResponsibility
    );

    return (
      <div className="container" onKeyDown={this.handleKeyDown}>
        <ul className="progress horizontal">{this.renderSteps()}</ul>

        {steps[compState].component}

        <div style={showNavigation ? {} : { display: 'none' }}>
          <button
            style={showNextBtn ? {} : { display: 'none' }}
            onClick={this.next}
            className="button"
            disabled={buttonDisabled}
          >
            {gettext('Next')}
          </button>

          {showSubmitButton
            ? (
              <button
                onClick={() => this.submit()}
                className="button"
                disabled={buttonDisabled}
              >
                {gettext('Submit')}
              </button>
            ) : null}
        </div>
      </div>
    );
  }
}

Stepper.defaultProps = {
  showNavigation: true,
  allowFastForward: false,
  leftUrl: '',
  steps: [],
  compState: 0,
  onFormSubmit: () => {},
  onSubmit: () => {},
};

Stepper.propTypes = {
  showNavigation: PropTypes.bool,
  allowFastForward: PropTypes.bool,
  leftUrl: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  steps: PropTypes.array,
  compState: PropTypes.number,
  onFormSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default observer(Stepper);
