/**
 * A generic date filter form.
 */

import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { DateField } from 'core/forms/fields';
import { dateToday } from 'utils/date';

const today = dateToday();

const DateFilterForm = ({ initialDate, maxDate, onSubmit }) => (
  <Formik
    enableReinitialize
    initialValues={{ dateFilterDate: initialDate }}
    validationSchema={Yup.object({
      dateFilterDate: Yup.date()
        .max(maxDate, gettext("Date can't be in the future."))
        .required(gettext('Please select a valid date.')),
    })}
    onSubmit={onSubmit}
  >
    {({ _isSubmitting, _setFieldValue, _values, submitForm }) => (
      <Form className="filter-form">
        <DateField
          onBlur={submitForm}
          name="dateFilterDate"
          data-toggled-by="date-filter-form"
          className="deactivated"
          onFocus={(e) => { e.nativeEvent.target.defaultValue = today; }}
        />
      </Form>
    )}
  </Formik>
);


DateFilterForm.defaultProps = {
  initialDate: today,
  maxDate: today,
};

DateFilterForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialDate: PropTypes.string,
  maxDate: PropTypes.string,
};

export default DateFilterForm;
