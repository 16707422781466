
import axios from 'axios';
import { onPdfDownload } from 'media/utils';
import { convertKeysToCamelCase } from 'core/utils';
import { getApiUrl } from 'utils/urls';
import { PricingTransactionsModel, InvoiceModel } from 'pricing/models';

export const onInvoiceDownload = (id, number) => onPdfDownload(
  getApiUrl(`/invoice/${id}/download/`),
  gettext(`Invoice ${number}`),
);

export const onBillingPageMount = async () => {
  const getBillingData = async () => {
    const billingData = {};

    await axios.get(getApiUrl('/pricing/transactions/', { no_of_items: 3 }))
      .then(({ data }) => {
        billingData.transactions = PricingTransactionsModel.fromArrayResponse(data);
      })
      .catch(console.error);

    await axios.get(getApiUrl('/invoice/', { no_of_items: 2 }))
      .then(({ data }) => { billingData.invoices = InvoiceModel.fromResponse(data); })
      .catch(console.error);

    await axios.get(getApiUrl('/pricing/credits/'))
      .then(({ data }) => {
        billingData.creditBalance = convertKeysToCamelCase(data);
      })
      .catch(console.error);

    return billingData;
  };

  return getBillingData();
};
