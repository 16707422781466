import PropTypes from 'prop-types';

import { Action, DropdownMenu } from '@trustwise/design-system';
import { getApiUrl } from 'utils/urls';
import axios from 'core/axios';
import { icons } from 'core/icons';
import { useScreenSize } from 'core/hooks';
import InboxItemModel from '../model';

const InboxItemActions = ({ inboxItem, dispatch, isWidget }) => {
  const { isXSScreen } = useScreenSize();
  const removeLabel = gettext('Remove');
  const {
    itemId,
    isRemovable,
    actionContext,
  } = inboxItem;

  const onClick = () => {
    axios.post(getApiUrl(`/inbox/${itemId}/delete-process/`))
      .then((_response) => {
        dispatch({ data: { itemId }, type: 'remove' });
      })
      .catch(console.error);
  };

  return (
    <div className="inbox-actions">
      {!!actionContext && (
        <Action
          mode="link"
          {...actionContext}
        />
      )}
      {isRemovable && (
        !isWidget && !isXSScreen ? (
          <Action
            mode="link"
            onClick={onClick}
            label={removeLabel}
          />
        ) : (
          <DropdownMenu
            items={[{
              id: `${itemId}-remove`,
              label: removeLabel,
              onClick,
              mode: 'link',
              iconLeft: icons.bin,
            }]}
          />
        )
      )}
    </div>
  );
};

InboxItemActions.defaultProps = {
  isWidget: false,
};

InboxItemActions.propTypes = {
  inboxItem: InboxItemModel.propType.isRequired,
  isWidget: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
};


export default InboxItemActions;
