import { CertificateIssuerRegistry } from '@trustwise/design-system';
import { LegalEntityAvatarResponseProps } from 'contacts/interfaces/response';
import { SimpleLegalEntityModelProps } from 'contacts/interfaces/types';
import { LegalEntityAvatarModel, SimpleLegalEntityModel } from 'contacts/models/legalEntity';
import { Model } from 'core/model';

interface CertificateTypeRegistriesModelProps extends CertificateIssuerRegistry {
  avatar: LegalEntityAvatarResponseProps;
  managedBy: SimpleLegalEntityModelProps;
}

export class CertificateTypeRegistriesModel extends Model implements CertificateIssuerRegistry {
  id: number;
  name: string;
  description: string;
  address: string;
  noOfIssuers: number;
  avatar: LegalEntityAvatarModel;
  createdAt: Date;
  managedBy: SimpleLegalEntityModel;

  constructor({ ...restProps }: CertificateTypeRegistriesModelProps) {
    super(restProps);
    // this.avatar = new LegalEntityAvatarModel(avatar);
    // this.createdAt = new Date(createdAt);
    // this.managedBy = new SimpleLegalEntityModel(managedBy);
  }
}
