/**
 * Sortable List
 *
 * @todo Rewrite with react-sortablejs
 *
 */
import Sortable from 'sortablejs';
import axios from 'core/axios';


const sortableAgendaListWithAjax = (listId) => {
  const list = document.getElementById(listId);
  if (!list) return false;
  const calculateNewItemOrder = (item) => {
    const prevOrder = item.previousElementSibling ? item.previousElementSibling.dataset.agendaItemOrder : 0;
    const nextOrder = item.nextElementSibling ? item.nextElementSibling.dataset.agendaItemOrder : prevOrder + 1.00;
    return (parseFloat(prevOrder) + parseFloat(nextOrder)) / 2;
  };
  const sortable = Sortable.create(list, {
    dataIdAttr: 'data-agenda-item-id',
    onSort: ({ item }) => {
      const order = calculateNewItemOrder(item);
      axios.patch(
        `/api/v1/company/${list.dataset.companyId}/meetings/${list.dataset.eventId}/${item.dataset.agendaItemId}/order/`,
        { order },
      ).then(() => { }, console.error);
    },
  });
  return sortable;
};

export default sortableAgendaListWithAjax;
