import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { convertKeysToCamelCase } from 'core/utils';
import axios from 'core/axios';
import { getApiUrl } from 'utils/urls';
import BaseAgendaItemUpdate from './baseUpdate';
import { AgendaItemModel } from '../../models';

const AgendaItemUpdate = () => {
  const { eventId } = useParams();
  const { agendaId } = useParams();
  const { state: { event: { eventType } } } = useLocation();
  const [initialValues, setInitialValues] = useState(undefined);
  const agendaApiUrl = getApiUrl(`/meetings/${eventId}/agenda-items/${agendaId}/`);

  useEffect(() => {
    axios.get(agendaApiUrl)
      .then(({ data }) => {
        setInitialValues(new AgendaItemModel(convertKeysToCamelCase(data)));
      })
      .catch(console.error);
  }, [agendaApiUrl]);

  const votingValues = initialValues && {
    ...initialValues.quorumValues,
    votingEnabled: initialValues.votingEnabled,
    doubleQuorumEnabled: initialValues.doubleQuorumEnabled,
    quorumBase: initialValues.quorumBase,
    attendanceQuorumEnabled: initialValues.attendanceQuorumEnabled,
    proposals: initialValues.proposals,
    documents: initialValues.documents,
  };

  return (
    <BaseAgendaItemUpdate
      eventType={eventType}
      pageHeading={initialValues ? initialValues.title : gettext('Agenda Item')}
      initialValues={initialValues ? {
        ...initialValues,
        ...votingValues,
      } : initialValues}
      isUpdate
      submitUrl={agendaApiUrl}
    />
  );
};

export default AgendaItemUpdate;
