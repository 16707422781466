import { FormikHelpers } from 'formik';

import { parseErrorResponse } from 'core/forms/utils';
import { handleTransactionCreation } from 'core/page/transactionSigning/utils';

export const txHashResponseHandler = (response?: Record<string, unknown>, responseRedirect = '/') => {
  if (response && 'tx_hash' in response) {
    handleTransactionCreation(response.tx_hash, responseRedirect);
  } else {
    window.location.href = responseRedirect;
  }
};

export const catchHandler = <FormikHelpersType>(
  error: Record<string, unknown>,
  actions: FormikHelpers<FormikHelpersType>,
) => {
  if (error.response) {
    try {
      const { data } = (error.response as Record<string, unknown>);
      if (typeof data === 'string') {
        console.error(data);
      } else {
        parseErrorResponse(data, actions.setFieldError);
      }
    } catch (e) {
      console.error(e);
    }
  } else {
    console.error(error);
  }
  if (actions) {
    actions.setSubmitting(false);
  }
  return Promise.reject();
};
