import { useParams } from 'react-router-dom';
import { useEffect, useReducer } from 'react';

import {
  RegistryMemberDetailView as RegistryMemberDetailViewDS,
} from '@trustwise/design-system';
import { getApiUrl } from 'utils/urls';
import { PageContent } from 'core/page';
import axios from 'core/axios';
import BackButton from 'core/page/parts/backButton';
import reducer from 'registries/reducers';
import { onFileDownload } from 'media/utils';
import { fetchMemberRecord, onMemberRemove, onPermissionsEdit, onRecordAdd } from './actions';

const RegistryMemberDetailView = () => {
  const { registryId, memberId } = useParams();

  const [state, dispatch] = useReducer(reducer, { member: {} });
  const { records, permissions, member: {
    permissions: memberPermissions,
    details,
    documents,
  } } = state;


  const registryPath = `/registries/${registryId}/`;
  const basePath = `${registryPath}members/${memberId}/`;

  useEffect(() => {
    axios.get(getApiUrl(basePath))
      .then(({ data }) => dispatch({ type: 'fetchMember', data }))
      .catch(console.error);
  }, [basePath]);

  useEffect(() => {
    axios.get(getApiUrl(`${basePath}logs/`))
      .then(({ data }) => dispatch({ type: 'fetchRecords', data }))
      .catch(console.error);
  }, [basePath]);

  const onTabSwitch = (tabId) => {
    if (tabId !== 'permissions') return;

    if (!permissions) {
      axios.get(getApiUrl(`${registryPath}permissions/`))
        .then(({ data }) => dispatch({ type: 'fetchPermissions', data }))
        .catch(console.error);
    }

    if (!memberPermissions) {
      axios.get(getApiUrl(`${basePath}permissions/`))
        .then(({ data }) => dispatch({ type: 'fetchMemberPermissions', data }))
        .catch(console.error);
    }
  };

  const onRegistryMemberFileDownload = (fileId, logId) => onFileDownload(
    fileId,
    logId ? records : documents,
    logId ? `${basePath}logs/${logId}/` : basePath,
  );

  return (
    <PageContent
      fullscreen
      pageHeading={details ? details.name : ''}
      headerLeft={<BackButton href={`/${registryId}/`} useRouterLink />}
    >
      <RegistryMemberDetailViewDS
        memberDetails={details || {}}
        records={records}
        permissions={permissions}
        memberPermissions={memberPermissions}
        onTabSwitch={onTabSwitch}
        onFileDownload={onRegistryMemberFileDownload}
        onRecordAdd={(values, actions) => onRecordAdd({ values, actions, basePath, dispatch })}
        onRecordModalOpen={(recordId) => fetchMemberRecord(`${basePath}logs/${recordId}/`)}
        onMemberRemove={() => onMemberRemove(registryPath, basePath)}
        onPermissionsEdit={(values, actions) => onPermissionsEdit(values, actions, basePath)}
      />
    </PageContent>
  );
};

export default RegistryMemberDetailView;
