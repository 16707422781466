import { useState, useCallback } from 'react';


const useAbortController = () => {
  const [controller, setController] = useState(new window.AbortController());

  const updateController = useCallback(() => {
    const abortController = new window.AbortController();
    setController((oldController) => {
      oldController.abort();
      return abortController;
    });
    const { signal } = abortController;
    return signal;
  }, []);
  return [controller, updateController];
};

export default useAbortController;
