import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { BoardMembersSection } from '@trustwise/design-system';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import { getRouterLinkInteraction } from 'core/utils';
import { getBoardMembers } from './actions';

export const BoardMembersList = () => {
  const { companyId } = useParams();
  if (!companyId) {
    throw new Error('Url parameter companyId is required');
  }

  const { data: members } = useQuery({ queryKey: ['board_members', companyId], queryFn: () => getBoardMembers(companyId) });

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Board Members')}
      headerLeft={<BackButton href={`/${companyId}/`} useRouterLink />}
    >
      <BoardMembersSection
        members={members}
        onMemberClick={(id: number) => getRouterLinkInteraction(`${id}/`)}
      />
    </PageContent>
  );
};
