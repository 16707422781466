import {
  EquityInvestmentsWidget,
  InboxWidget,
  EventsWidget,
  DocumentsWidget,
  ShareholdersWidget,
  MintageWidget,
  CustodialAccountDataWidget,
  PaymentTokensWidget,
  PaymentTokensTransfersWidget,
} from 'widgets';
import {
  CbdcOverviewWidget,
  CreatorsWidget,
  SmartBanknotesWidget,
  LiquidityWidget,
  TokenTransfersWidget,
} from 'cbdc/widgets';

const widgetsLabels = {
  equity_investments: gettext('Assets: Equity'),
  inbox: gettext('Inbox'),
  events: gettext('Events: Upcoming'),
  documents: gettext('Documents: Contracts'),
  shareholders: gettext('Company: Shareholders'),
  custodial_account_data: gettext('Custodian: Account Details'),
  mintage: gettext('CBDC: Issued tokens'),
  cbdc_overview: gettext('CBDC: In Circulation'),
  smart_banknotes: gettext('CBDC: Smart Banknotes'),
  cbdc_logos: gettext('CBDC: Creators'),
  cbdc_liquidity: gettext('CBDC: Liquidity'),
  cbdc_transfers: gettext('CBDC: Transfers'),
  payment_token: gettext('Assets: Cash'),
  payment_token_transfers: gettext('Assets: Cash Transfers'),
};

const widgets = {
  equity_investments: EquityInvestmentsWidget,
  inbox: InboxWidget,
  events: EventsWidget,
  documents: DocumentsWidget,
  shareholders: ShareholdersWidget,
  // Custodial account
  custodial_account_data: CustodialAccountDataWidget,
  // for CBDC demo "banks"
  cbdc_logos: CreatorsWidget,
  cbdc_overview: CbdcOverviewWidget,
  smart_banknotes: SmartBanknotesWidget,
  cbdc_liquidity: LiquidityWidget,
  cbdc_transfers: TokenTransfersWidget,
  mintage: MintageWidget,
  payment_token: PaymentTokensWidget,
  payment_token_transfers: PaymentTokensTransfersWidget,
};

const companyDefaultWidgets = ['shareholders', 'inbox', 'events', 'documents', 'equity_investments'];
const companyAllWidgets = [...companyDefaultWidgets, 'payment_token', 'payment_token_transfers'];
const personDefaultWidgets = ['equity_investments', 'inbox', 'events', 'documents'];
const personAllWidgets = [...personDefaultWidgets, 'payment_token', 'payment_token_transfers'];
const custodialDefaultWidgets = ['equity_investments', 'inbox', 'events', 'custodial_account_data'];
const delegateDefaultWidgets = ['equity_investments', 'inbox', 'events'];

export {
  companyDefaultWidgets,
  custodialDefaultWidgets,
  delegateDefaultWidgets,
  personDefaultWidgets,
  companyAllWidgets,
  personAllWidgets,
  widgets,
  widgetsLabels,
};
