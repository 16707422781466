import PropTypes from 'prop-types';
import { ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Loader from 'components/common/loader';
import Errors from 'components/common/formikErrors';
import { dateToday } from 'utils/date';
import { ReactSelectField, TextField, DateTimeField } from 'core/forms/fields';
import { resolutions } from '../constants';

export const validationDateLocation = Yup.object({
  date: Yup.date()
    .min(dateToday(), gettext("Date can't be in the past")),
  time: Yup.string()
    .test(
      'not empty',
      gettext("Time can't be empty"),
      (time) => !!time,
    ),
  timeZone: Yup.string()
    .max(32, gettext('Line is too long')),
  location: Yup.string()
    .max(128, gettext('Line is too long')),
});


const DateLocation = ({ isSubmitting, eventType, timeZonesOptions }) => (
  <Loader isLoading={isSubmitting}>
    <ErrorMessage component={Errors} name="nonFieldErrors" />
    {resolutions.includes(eventType) && <h3>{gettext('Voting deadline')}</h3>}
    <DateTimeField label={gettext('Date and time')} dateName="date" timeName="time" />
    <ReactSelectField
      label={gettext('Time zone')}
      name="timeZone"
      options={timeZonesOptions}
    />
    <TextField label={gettext('Location')} name="location" />
  </Loader>
);

DateLocation.defaultProps = {
  isSubmitting: false,
  timeZonesOptions: [],
};

DateLocation.propTypes = {
  isSubmitting: PropTypes.bool,
  eventType: PropTypes.string.isRequired,
  timeZonesOptions: PropTypes.arrayOf(PropTypes.shape()),
};

export default DateLocation;
