import { snakeCase } from 'lodash';

import { catchHandler, convertNestedKeysCase, convertKeysCase } from 'core/utils';
import { getApiUrl } from 'utils/urls';
import axios from 'core/axios';

export const onBankAccountEdit = ({ basePath, values, actions, id, dispatch }) => {
  const caseConvertedData = convertKeysCase(values, snakeCase);
  const method = id ? 'patch' : 'post';
  const submitUrl = `${basePath}bank-accounts/`;

  return axios[method](
    getApiUrl(`${submitUrl}${id ? `${id}/` : ''}`),
    caseConvertedData,
  ).then(
    ({ data }) => { dispatch({ data, type: 'updateBankAccounts' }); },
  ).catch((error) => catchHandler(error, actions));
};


export const onProfileImageEdit = (basePath, values, actions, dispatch) => {
  const { croppedArea, profileImage } = values;

  const submitData = new FormData();
  submitData.append('profile_image', profileImage[0]);
  Object.entries(croppedArea).forEach(([key, value]) => {
    submitData.append(`cropped_area.${key}`, value);
  });

  return axios.post(getApiUrl(`${basePath}image/`), submitData)
    .then(({ data }) => { dispatch({ data, type: 'updateAvatar' }); })
    .catch((error) => catchHandler(error, actions));
};

export const onProfileImageRemove = (basePath, actions, dispatch) => (
  axios.delete(getApiUrl(`${basePath}image/`))
    .then(({ data }) => { dispatch({ data, type: 'updateAvatar' }); })
    .catch((error) => catchHandler(error, actions))
);

export const onPasswordUpdate = (basePath, values, actions, dispatch) => (
  axios.post(getApiUrl(`${basePath}password/`), convertNestedKeysCase(values, snakeCase))
    .then(({ data }) => { dispatch({ data, type: 'updateProfile' }); })
    .catch((error) => catchHandler(error, actions))
);

export const onLoginUpdate = (basePath, values, actions, dispatch) => (
  axios.post(getApiUrl(`${basePath}account-login/`), values)
    .then(({ data }) => { dispatch({ data, type: 'updateProfile' }); })
    .catch((error) => catchHandler(error, actions))
);
