
import { UpdateNativeAppView } from '@trustwise/design-system';
import { APP_STORE_URL, GOOGLE_PLAY_URL } from 'core/native/consts';
import { isIosWebview, isNativeAppWebview, isNewNativeAppWebview } from 'utils/general';
import { getGlobalContext } from 'core/globals';
import { openNativeView } from 'core/utils';

const UpdateNativeAppOverlay = () => {
  const {
    actingPerson: { allowReactNativeApp, selfManagedType },
    nativeUrlScheme,
  } = getGlobalContext();

  if (!isNativeAppWebview || isNewNativeAppWebview || allowReactNativeApp) {
    return null;
  }
  const hasRecovery = selfManagedType === 'recovery';
  const storeLink = isIosWebview ? APP_STORE_URL : GOOGLE_PLAY_URL;
  const recoveryPhrasePath = 'recovery-phrase-profile';

  return (
    <UpdateNativeAppView
      onShowRecoveryPhrase={!hasRecovery ? () => openNativeView(recoveryPhrasePath) : undefined}
      recoveryPhraseUrl={!hasRecovery ? `${nativeUrlScheme}${recoveryPhrasePath}` : undefined}
      onDataErase={() => openNativeView('erase_device')}
      onDownloadLatest={() => { window.open(storeLink, '_blank'); }}
    />
  );
};

export default UpdateNativeAppOverlay;
