import { Web3 } from 'web3';

import { CustomSchemaFieldType, CustomSchemaFieldTypes } from '@trustwise/design-system';
import { CustomSchemaFieldTypeChoices } from 'core/const';

const web3 = new Web3();

export interface CustomSchemaFieldEncodingData {
  /**
   * Custom schema field type
   */
  fieldType: CustomSchemaFieldType;
  /**
   * Custom schema field data
   */
  data: string | number | boolean | string[];
}

export const encodeCustomSchemaFieldData = ({ fieldType, data }: CustomSchemaFieldEncodingData) => {
  let encodedData: string;

  switch (fieldType) {
    case CustomSchemaFieldTypes.STRING: {
      encodedData = web3.eth.abi.encodeParameters([CustomSchemaFieldTypes.STRING], [data]);
      break;
    }
    case CustomSchemaFieldTypes.NUMBER: {
      encodedData = web3.eth.abi.encodeParameters(['int256'], [data]);
      break;
    }
    case CustomSchemaFieldTypes.DECIMAL: {
      const value = data as number;
      const noOfDecimalPlaces = value.toString().split('.')[1]?.length || 0;
      const convertedValue = value * 10 ** noOfDecimalPlaces;

      encodedData = web3.eth.abi.encodeParameters(['int256', 'uint8'], [convertedValue, noOfDecimalPlaces]);
      break;
    }
    case CustomSchemaFieldTypes.DATE_TIME: {
      encodedData = web3.eth.abi.encodeParameters(['uint256'], [data]);
      break;
    }
    case CustomSchemaFieldTypes.BOOLEAN: {
      encodedData = web3.eth.abi.encodeParameters(['bool'], [data]);
      break;
    }
    case CustomSchemaFieldTypes.FILES: {
      encodedData = web3.eth.abi.encodeParameters(
        ['bytes32[]'],
        [data],
      );
      break;
    }
    default: {
      throw new Error(`Unsupported fieldType: ${fieldType}`);
    }
  }

  return web3.eth.abi.encodeParameters(
    ['uint8', 'bytes'],
    [CustomSchemaFieldTypeChoices[fieldType], encodedData],
  );
};

export const encodeData = (fields: CustomSchemaFieldEncodingData[]) => {
  const encodedFields = fields.map((field) => encodeCustomSchemaFieldData(field));

  return web3.eth.abi.encodeParameters(
    ['bytes[]'],
    [encodedFields],
  );
};
