import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useReducer } from 'react';

import { UtilityTokensBurningView } from '@trustwise/design-system';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import { getUtilityTokenDetail, onBurn } from './actions';
import { UtilityTokenTypes } from '../consts';
import reducer from '../reducers';

const UtilityTokensAssetsBurnView = () => {
  const navigate = useNavigate();
  const { state: { asset: stateAsset } } = useLocation();

  const { portfolioId, tokenId } = useParams();

  const [state, dispatch] = useReducer(reducer, { asset: stateAsset || {} });
  const { asset } = state;
  const { name, type, totalAmount } = asset;

  useEffect(() => {
    if (!stateAsset) {
      getUtilityTokenDetail({ portfolioId, tokenId, dispatch });
    }
  }, [portfolioId, tokenId, stateAsset]);

  const titles = {
    [UtilityTokenTypes.CARBON]: gettext('Report Emissions'),
  };

  return (
    <PageContent
      fullscreen
      pageHeading={type ? titles[type] : ''}
      headerLeft={<BackButton onClick={() => navigate(`/utility/${portfolioId}/${tokenId}/`)} />}
    >
      <UtilityTokensBurningView
        isDocumentsOptional={false}
        name={name}
        type={type}
        amount={totalAmount}
        onSubmit={(values, actions) => onBurn({
          submitPath: `/assets/utility-tokens/${portfolioId}/${tokenId}/burn/`,
          navigatePath: `/assets/utility/${portfolioId}/${tokenId}/`,
          values,
          actions,
        })}
      />
    </PageContent>
  );
};

export default UtilityTokensAssetsBurnView;
