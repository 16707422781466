import { uniqBy } from 'lodash';

import { ASC_SORTING_ORDER } from 'core/const';
import { Model } from '../../models/model';
import Collection from '../collection';

export default class AccountsCollection extends Collection {
  model = Model;

  static get url() {
    return `${window.location.protocol}//${window.location.host}/api/v1/accounts-list/`;
  }

  get personal() {
    return uniqBy(this.chain()
      .filter((o) => o.type === 'person' && !o.delegated)
      .orderBy(['name'], [ASC_SORTING_ORDER])
      .value(), 'id');
  }

  get delegated() {
    return uniqBy(this.chain()
      .filter((o) => o.type === 'person' && o.delegated)
      .orderBy(['name'], [ASC_SORTING_ORDER])
      .value(), 'id');
  }

  get favourites() {
    return uniqBy(this.chain()
      .filter((o) => o.favourite)
      .orderBy(['name'], [ASC_SORTING_ORDER])
      .value(), 'id');
  }

  get company() {
    return uniqBy(this.chain()
      .filter((o) => o.type === 'company' && !o.favourite)
      .orderBy(['pending', 'name'], [ASC_SORTING_ORDER, ASC_SORTING_ORDER])
      .value(), 'id');
  }
}
