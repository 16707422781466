import PropTypes from 'prop-types';

const CloseButton = ({ onClick }) => (
  <svg className="close-icon action-item" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" onClick={onClick}>
    <path d="M349.2 97.1l-14.4-14.4c-3.8-3.8-9.9-3.8-13.7 0L175.8 227.9 31 83c-3.8-3.8-9.9-3.8-13.7 0L2.8 97.4c-3.8 3.8-3.8 9.9 0
     13.7L147.7 256 3.1 400.6c-3.8 3.8-3.8 9.9 0 13.7l14.4 14.4c3.8 3.8 9.9 3.8 13.7 0l144.6-144.6L320.7 429c3.8 3.8 9.9 3.8
     13.7 0l14.4-14.4c3.8-3.8 3.8-9.9 0-13.7L204 256l145.2-145.2c3.7-3.8 3.7-9.9 0-13.7z"
    />
  </svg>
);

CloseButton.propTypes = { onClick: PropTypes.func.isRequired };

export default CloseButton;
