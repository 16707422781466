/* eslint-disable react/prop-types */
import { Component } from 'react';
import { observer } from 'mobx-react';
import Stepper from '../stepper';
import {
  MembershipAgreement,
  Industry,
  Documents,
} from './steps';
import Loader from '../common/loader';

class AcceptConsortiumMembershipComponent extends Component {
  get steps() {
    const { model } = this.props;
    const componentProps = {
      model,
      validate: true,
    };

    return [
      {
        name: gettext('Membership Agreement'),
        component: <MembershipAgreement
          onFormSubmit={model.validateMembershipAgreement}
          {...componentProps}
        />,
        hasErrors: model.hasAgreementErrors,
      },
      ...(!model.hasIndustry ? [{
        name: gettext('Industry'),
        component: <Industry
          onFormSubmit={model.validateIndustry}
          {...componentProps}
        />,
        hasErrors: model.hasIndustryErrors,
      }] : []),
      {
        name: gettext('Documents'),
        component: <Documents
          onFormSubmit={model.validateMembershipDocument}
          {...componentProps}
        />,
        hasErrors: model.hasDocumentErrors,
      },
    ];
  }

  submit = () => {
    const { model } = this.props;

    model.submit();
  };

  render() {
    const { model, leftUrl } = this.props;

    return (
      <Loader isLoading={model.isLoading}>
        <Stepper
          steps={this.steps}
          allowFastForward={false}
          leftUrl={leftUrl}
            // eslint-disable-next-line no-return-assign
          ref={(stepperComponent) => (window.stepperComponent = stepperComponent)}
          onSubmit={this.submit}
        />
      </Loader>
    );
  }
}

export default observer(AcceptConsortiumMembershipComponent);
