/* Media constants */
const registrationDocuments = 'RD';
const photoOfPassport = 'POP';
const powerOfAttorney = 'POA';
const registrationDetails = 'RDT';
const selfie = 'SEL';
const govEventInvitation = 'GOI';
const govEventMinutes = 'GOM';
const fileUpload = 'FU';
const consortiumMemberApplication = 'CMA';
const validatorApplication = 'VA';
const sharePurchaseAgreement = 'SPA';
const ownershipReportingObligations = 'ORO';
const commercialRegistryExcerpt = 'CRE';
const coOwnershipAgreement = 'COA';
const coOwnerCuratorMandate = 'CCM';

const personalRegistrationTypes = [
  { id: photoOfPassport, value: gettext('Photo of passport or ID') },
  { id: powerOfAttorney, value: gettext('Proof of address') },
  { id: selfie, value: gettext('Selfie') },
];
const businessRegistrationTypes = [
  { id: registrationDocuments, value: gettext('Registration documents') },
  { id: commercialRegistryExcerpt, value: gettext('Excerpt of the commercial registry') },
];
const userUploadTypes = [
  { id: fileUpload, value: gettext('Document') },
];
const consortiumMemberTypes = [
  { id: consortiumMemberApplication, value: gettext('Association Membership Application Documents') },
];
const govEventTypes = [
  { id: govEventInvitation, value: gettext('Invitation') },
  { id: govEventMinutes, value: gettext('Minutes') },
];
const validatorTypes = [
  { id: validatorApplication, value: gettext('Validator Application Documents') },
];
const shareTransferTypes = [
  { id: sharePurchaseAgreement, value: gettext('Share Purchase Agreement') },
  { id: ownershipReportingObligations, value: gettext('Ownership Reporting Obligations') },
];
const consortiumTypes = consortiumMemberTypes.concat(validatorTypes);
const registrationTypes = personalRegistrationTypes.concat(
  businessRegistrationTypes,
  [{ id: registrationDetails, value: gettext('Registration Details') }],
);

const allTypes = registrationTypes.concat(govEventTypes, userUploadTypes, shareTransferTypes, consortiumTypes);

const getDocumentTypeOptions = (documentTypeContext = 'USER_UPLOAD_TYPES') => {
  switch (documentTypeContext) {
    case 'PERSONAL_REGISTRATION_TYPES':
      return personalRegistrationTypes;
    case 'BUSINESS_REGISTRATION_TYPES':
      return businessRegistrationTypes;
    case 'CONSORTIUM_MEMBER_TYPES':
      return consortiumMemberTypes;
    case 'GOV_EVENT_TYPES':
      return govEventTypes;
    case 'VALIDATOR_TYPES':
      return validatorTypes;
    case 'SHARE_TRANSFER_TYPES':
      return shareTransferTypes;
    case 'CONSORTIUM_TYPES':
      return consortiumTypes;
    case 'REGISTRATION_TYPES':
      return registrationTypes;
    default:
      return userUploadTypes;
  }
};

export {
  // Single values
  registrationDocuments,
  photoOfPassport,
  powerOfAttorney,
  registrationDetails,
  selfie,
  govEventInvitation,
  govEventMinutes,
  fileUpload,
  consortiumMemberApplication,
  validatorApplication,
  sharePurchaseAgreement,
  ownershipReportingObligations,
  commercialRegistryExcerpt,
  coOwnershipAgreement,
  coOwnerCuratorMandate,
  // Types groups
  personalRegistrationTypes,
  businessRegistrationTypes,
  userUploadTypes,
  consortiumMemberTypes,
  govEventTypes,
  validatorTypes,
  shareTransferTypes,
  consortiumTypes,
  registrationTypes,
  allTypes,
  // Methods
  getDocumentTypeOptions,
};
