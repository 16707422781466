import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { UtilityTokenMintingView } from '@trustwise/design-system';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import { onUtilityTokenFetch } from 'liabilities/utilityTokens/actions';
import { signFormTransaction } from 'utils/signTransaction';

const UtilityTokenMintView = () => {
  const [token, setToken] = useState();
  const { tokenId } = useParams();
  const { state: { token: stateToken } } = useLocation();
  const { name, type } = token || {};
  const navigate = useNavigate();

  const onTokenFetch = useCallback(
    () => onUtilityTokenFetch(tokenId, setToken),
    [tokenId],
  );

  useEffect(() => {
    stateToken ? setToken(stateToken) : onTokenFetch();
  }, [onTokenFetch, stateToken]);

  const onSubmit = (values, actions) => {
    signFormTransaction({
      urlPath: `/utility-tokens/${tokenId}/mint/`,
      navigatePath: `/liabilities/utility/${tokenId}/`,
      data: values,
      actions,
    });
  };

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Issue Allowances')}
      headerLeft={<BackButton onClick={() => navigate(`/${tokenId}/`)} />}
    >
      <UtilityTokenMintingView
        onSubmit={onSubmit}
        name={name}
        type={type}
      />
    </PageContent>
  );
};

export default UtilityTokenMintView;
