import { Component } from 'react';
import { reportSentryError } from '../../sentry';

export default class FallbackComponent extends Component {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    hasError: false,
  };

  static getDerivedStateFromError(_error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    reportSentryError(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    // eslint-disable-next-line react/prop-types
    const { children } = this.props;

    if (hasError) {
      return (
        <div>
          <div>
            {gettext('Page content could not be loaded.')}
          </div>
          <div>
            {gettext('Try reloading the page or contact us for support.')}
          </div>
        </div>
      );
    }

    return children;
  }
}
