export const Features = {
  ASSETS_ARTWORKS: 'assets-artworks',
  ASSETS_PAYMENT_TOKENS: 'assets-payment-tokens',
  ASSETS_UTILITY_TOKENS: 'assets-utility-tokens',
  DATA_ROOMS: 'data-rooms',
  DATA_ROOM_CREATOR: 'data-rooms-creator',
  GOVERNANCE_BOARDROOM: 'governance-boardroom',
  GOVERNANCE_OWNERSHIP: 'governance-ownership',
  LIABILITIES_EQUITY: 'liabilities-equity',
  LIABILITIES_PAYMENT_TOKENS: 'liabilities-payment-tokens',
  LIABILITIES_UTILITY_TOKENS: 'liabilities-utility-tokens',
  MEMBERSHIPS_ASSOCIATIONS: 'memberships-associations',
  MEMBERSHIPS_BOARDROOM: 'memberships-boardroom',
  SERVICES_ARBITER: 'services-arbiter',
  SERVICES_ARTWORKS: 'services-artworks',
  SERVICES_CERTIFICATES_ISSUER: 'services-certificates-issuer',
  SERVICES_CERTIFICATES_MANAGER: 'services-certificates-manager',
  SERVICES_CUSTODIAN: 'services-custodian',
  SERVICES_DATA_ROOMS_VERIFIER: 'services-data-rooms-verifier',
  SERVICES_REGISTRIES: 'services-registries',
  SERVICES_VERIFY: 'services-verify',
} as const;

export type Feature = (typeof Features)[keyof typeof Features];
