import { useCallback, useEffect, useReducer, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { snakeCase } from 'lodash';

import { ProfileView } from '@trustwise/design-system';
import { PageContent } from 'core/page';
import axios from 'core/axios';
import BackButton from 'core/page/parts/backButton';
import { convertKeysCase, catchHandler, redirectTo, openNativeView } from 'core/utils';
import { getApiUrl } from 'utils/urls';
import { useScreenSize } from 'core/hooks';
import { getGlobalContext } from 'core/globals';
import { onAgreementDownload } from 'media/utils';
import {
  onBillingOverviewMount,
  onContactsOverviewMount,
  onBankAccountEdit,
  onLoginUpdate,
  onProfileImageEdit,
  onProfileImageRemove,
  onPasswordUpdate,
  fetchSubscriptions,
  onInvoiceDownload,
  onBillingPageMount,
  getSubscriptionsInteraction,
} from './actions';
import reducer from '../reducers';

const ProfileDetail = () => {
  const navigate = useNavigate();
  const { state: routerState } = useLocation();
  const { activeTab } = routerState || {};
  const activeTabSearchParam = new URLSearchParams(window.location.search).get('active_tab');
  const [activePage, setActivePage] = useState(activeTab || activeTabSearchParam || 'overview');
  // Clear location state
  window.history.replaceState({}, document.title);

  const profileBasePath = '/profile/';

  const { isLtMScreen } = useScreenSize();
  const { activeEntity: { isCompany, status } } = getGlobalContext();

  const [state, dispatch] = useReducer(reducer, { profile: null });
  const { profile } = state;

  useEffect(() => {
    axios.get(getApiUrl(profileBasePath))
      .then(({ data }) => dispatch({ type: 'fetchProfileData', data }))
      .catch(console.error);
  }, []);

  const onProfileUpdate = (values, actions) => {
    const data = convertKeysCase(values, snakeCase);
    return axios.patch(getApiUrl(profileBasePath), data)
      .then(
        ({ data: responseData }) => {
          dispatch({ type: 'updateProfile', data: responseData });
        },
      )
      .catch((error) => catchHandler(error, actions));
  };

  const profileActions = {
    settings: {
      onProfileImageSave: (values, actions) => onProfileImageEdit(profileBasePath, values, actions, dispatch),
      onProfileImageRemove: (_values, actions) => onProfileImageRemove(profileBasePath, actions, dispatch),
      onAccountNameEdit: onProfileUpdate,
      onEmailEdit: isCompany ? onProfileUpdate : (values, actions) => onLoginUpdate(profileBasePath, values, actions, dispatch),
      onPhoneNumberEdit: onProfileUpdate,
      onAddressEdit: onProfileUpdate,
      onHomepageEdit: onProfileUpdate,
      onPasswordEdit: (values, actions) => onPasswordUpdate(profileBasePath, values, actions, dispatch),
      onContactsClick: () => navigate('/contacts/'),
      onIdentitiesClick: () => redirectTo('/identities/'),
      onUserAgreementClick: () => onAgreementDownload(),
      onEraseDataClick: () => navigate('/erase-data/'),
      onRecoveryPhraseClick: () => openNativeView('recovery-phrase-profile'),
      onSeedPhraseMigrate: () => openNativeView('migrate-seed-phrase'),
      onBankAccountEdit: (values, actions, id) => onBankAccountEdit({
        basePath: profileBasePath, values, actions, dispatch, id,
      }),
      onBaseCurrencyEdit: onProfileUpdate,
      onLanguageEdit: onProfileUpdate,
      onTimeZoneEdit: onProfileUpdate,
      onEmailNotificationsToggle: onProfileUpdate,
    },
    info: {
      onBlockchainInfoClick: () => redirectTo('/blockchain/'),
    },
    subscriptions: {
      onDetailsInteraction: (id) => getSubscriptionsInteraction(activePage, id),
      browseServicesInteraction: getSubscriptionsInteraction(activePage),
      onSubscriptionsPageMount: fetchSubscriptions,
    },
    billing: {
      onInvoiceDownload,
      onAllTransactionsClick: () => navigate('/pricing/transactions/'),
      onBillingPageMount,
    },
    onBillingOverviewMount,
    onContactsOverviewMount,
    onSubscriptionsOverviewMount: useCallback(() => fetchSubscriptions(3), []),
    setActivePage,
  };

  const pageTitles = {
    overview: gettext('Account'),
    settings: gettext('Settings'),
    billing: gettext('Billing'),
    contacts: gettext('Contacts'),
    extensions: gettext('Extensions'),
    info: gettext('Info'),
    subscriptions: gettext('Subscriptions'),
  };

  return (
    <PageContent
      fullscreen
      pageHeading={isLtMScreen ? pageTitles[activePage] : gettext('Account')}
      headerLeft={(
        <BackButton
          onClick={() => {
            !isLtMScreen || activePage === 'overview'
              ? redirectTo('')
              : setActivePage('overview');
          }}
        />
      )}
    >
      <ProfileView
        isCompany={isCompany}
        status={profile ? profile.status : status}
        statusMessage={profile ? profile.statusMessage : undefined}
        onDocumentsResubmit={() => redirectTo('/identification/')}
        profileData={profile ? profile.toObject() : undefined}
        actions={profileActions}
        activePage={activePage}
      />
    </PageContent>
  );
};

export default ProfileDetail;
