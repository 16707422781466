import { snakeCase } from 'lodash';

import { convertKeysCase } from 'core/utils';
import { signFormTransaction } from 'utils/signTransaction';
import { addMultipleDocuments } from 'media/utils';

export const onTransfer = ({ values, actions, submitPath, navigatePath }) => {
  const { recipient, documents, ...restProps } = values;
  const data = convertKeysCase(restProps, snakeCase);
  const submitData = new FormData();

  if (typeof recipient === 'undefined') {
    throw new Error("Recipient wasn't provided");
  }
  data.recipient_address = recipient.portfolio;

  Object.entries(data).forEach(
    ([key, value]) => submitData.append(key, value),
  );
  if (documents) {
    const documentWithType = documents.map((doc) => ({ ...doc, type: 'TRA' }));
    addMultipleDocuments({ documents: documentWithType, submitData });
  }

  signFormTransaction({
    urlPath: submitPath,
    navigatePath,
    data: submitData,
    actions,
  });
};
