import PropTypes from 'prop-types';

import { convertKeysToCamelCase } from 'core/utils';
import SignatoryModel from './signatory';

export default class SignerContractModel {
  constructor({ id, address, status, isEditable = true, activeSignatoryId, isSigned, signatories = [] }) {
    this.id = id;
    this.address = address || '';
    this.status = status;
    this.isEditable = isEditable;
    this.activeSignatoryId = activeSignatoryId;
    this.isSigned = isSigned;
    this._signatories = signatories.map((signatory) => (new SignatoryModel(convertKeysToCamelCase(signatory))));
  }

  static propType = PropTypes.shape({
    id: PropTypes.number,
    address: PropTypes.string,
    status: PropTypes.string,
    isEditable: PropTypes.bool,
    activeSignatoryId: PropTypes.number,
    isSigned: PropTypes.bool,
  });

  get signatories() { return this._signatories; }

  set signatories(signatories) {
    this._signatories = signatories.map((signatory) => new SignatoryModel(convertKeysToCamelCase(signatory)));
  }

  update(data) { Object.entries(data).forEach(([key, value]) => { this[key] = value; }); }

  get isDraft() { return this.status === 'draft'; }

  get numberOfSignatories() { return this.signatories.length; }

  get statusTagData() {
    return { icon: this.isSigned ? 'signed' : 'inProgress',
      label: this.isSigned ? gettext('Signed') : gettext('In progress'),
      className: this.isSigned ? 'tag-success' : '' };
  }

  get hasSignatories() { return this.signatories && this.signatories.length > 0; }

  get isValid() { return this.status === 'valid'; }
}
