// @todo avoid endless mappings by using a universal data property:
//       e.g. <div data-component="verification/person/form/PersonalVerificationForm">

import { createRoot } from 'react-dom/client';

import RowOptionToggle from 'core/lists/rows/rowOptionToggle';
import UnsignedTransaction from 'core/page/transactionSigning/unsignedTransaction';
import UpdateNativeAppOverlay from 'core/native/updateNativeAppOverlay';
import UtilityTokensLiabilityView from 'liabilities/utilityTokens/navigation';
import BoardroomMembershipView from 'boardroom/membership/navigation';
import { ContactSelectFieldForm } from 'contacts/contactSelect/selectFieldForm';
import { ContactsNavigationView } from 'contacts/navigation';
import { DataSetExternalAccessView } from 'dataRooms/externalAccess/view';
import { DataVerificationNavigation } from 'dataRooms/verifier/navigation';
import AcceptConsortiumMembershipComponent from './acceptConsortiumMembership';
import BeneficialOwnersReportForm from '../tokens/beneficialOwners/reportForm';
import { ConfirmOffchainTransferComponent } from './transfer';
import AccountSwitcherComponent from './accountSwitcher/account';
import RightSideBarComponent from './accountSwitcher/sideBar';
import FXRatesGraphRenderer from './markets/fxRatesGraph';
import ShareholdersEventTemplateCreateForm from '../govEvents/createTemplate/shareholdersMeeting';
import BoardEventTemplateCreateForm from '../govEvents/createTemplate/boardMeeting';
import BeneficialOwnersListByDate from '../ownership/beneficialOwnersListByDate';
import AgendaVotingForm from '../govEvents/votingForm';
import FlashMessage from '../components/generic/flashMessage';
import IssuerRegistryList from '../certificates/old/issuerRegistry/list';
import IssuerRegistryDetail from '../certificates/old/issuerRegistry/detail';
import IssuerDetail from '../certificates/old/issuerRegistry/issuerDetail';
import IssuerRegistryCreate from '../certificates/old/issuerRegistry/create';
import RegistryAddIssuer from '../certificates/old/issuerRegistry/addIssuer';
import SignTransactionComponent from '../transactions/SignTransactionComponent';
import AccountConfiguration from '../personRegistration/accountConfiguration';
import SaveAsTemplateForm from '../govEvents/saveAsTemplateForm';
import EventShareClasses from '../govEvents/shareClasses';
import CompanyMultisigTransactionsList from '../MultisigTransactions/companyTransactionsList';
import DelegateMultisigTransactionsList from '../MultisigTransactions/delegateTransactionsList';
import GovEventTemplateCreateForm from '../govEvents/createTemplate/form';
import DocumentsComponentRenderer from './documentsRenderer';
import PaymentTokenCreate from '../liabilities/paymentTokenCreate';
import TokenCreateForm from '../liabilities/tokenCreate';
import TokenDistributeForm from '../liabilities/distribute';
import AssignChairmanAndSecretaryForm from '../govEvents/assignChairmanAndSecretaryForm';
import DocumentsNavigation from '../media/navigation';
import ShareholdersWhitelistsNavigation from '../shareholders/shareholdersWhitelists/navigation';
import OwnerSubscriptionsNavigation from '../subscriptions/owner/navigation';
import { EquitySubscriptionsNavigation } from '../subscriptions/investments/navigation';
import PersonalVerificationForm from '../verification/person/form';
import CompanyRegistrationForm from '../registration/company/form';
import TokenConvertForm from '../liabilities/tokenConvert';
import InboxView from '../inbox/view';
import LinkSignerAppComponent from '../signer/linkSignerComponent';
import Dashboard from '../dashboard/dashboard';
import PersonaliseDashboard from '../dashboard/personaliseDashboard';
import AppNavigation from '../core/page/menu/navigation';
import CustodianServiceNavigation from '../custodian/navigation';
import CustodialAccountEdit from '../custodian/custodialEdit';
import AddLiabilityValuation from '../valuation/addLiabilityView';
import AddAssetValuation from '../valuation/addAssetView';
import EquityInvestmentValuationDetail from '../valuation/details';
import TransactionPriceInfo from '../core/page/transactionPriceInfo';
import InitializeTransfer from '../businessTransactions/initializeTransfer';
import GovernanceEventsView from '../governanceEvents/navigation';
import CompanyShareholdersActions from '../ownership/companyShareholdersActions';
import InlineNotificationWithLock from '../core/page/inlineNotificationWithLock';
import SingleShareholderExcerptModal from '../ownership/singleShareholderExcerptModal';
import ProfileView from '../profile/navigation';
import MessagesList from '../core/page/messagesList';
import RegisteredObjectsView from '../registeredObjects/navigation';
import AssetsView from '../investments/navigation';
import PropertyTokenTransfersView from '../businessTransactions/propertyToken/navigation';
import TransactionAlreadyConfirmed from '../core/page/transactionAlreadyConfirmed';
import RegistriesView from '../registries/navigation';
import MarketsView from '../markets/navigation';
import BoardroomView from '../boardroom/management/navigation';
import SignatoriesNavigation from '../signatories/navigation';
import DataRoomNavigation from '../dataRooms/navigation';
import { SignUpInProgressView, SignUpLandingView } from '../landing/views';
import CertificatesNavigation from '../certificates/navigation';

const RENDERER_MAPPING = {
  enhancedDocumentUpload: DocumentsComponentRenderer,
  consortiumMembershipApplicationComponent: AcceptConsortiumMembershipComponent,
  'transfer-confirm-offchain-transfer-component': ConfirmOffchainTransferComponent,
  'account-switcher-component': AccountSwitcherComponent,
  'right-side-bar-component': RightSideBarComponent,
  'fx-daily-rates-graph': FXRatesGraphRenderer,
};

const COMPONENTS_MAPPING = {
  'account-configuration': AccountConfiguration,
  'assign-chairman-and-secretary': AssignChairmanAndSecretaryForm,
  'beneficial-owners-list': BeneficialOwnersListByDate,
  'board-event-template-create': BoardEventTemplateCreateForm,
  certificates: CertificatesNavigation,
  'company-multisig-transactions-list': CompanyMultisigTransactionsList,
  'delegate-multisig-transactions-list': DelegateMultisigTransactionsList,
  'event-share-classes': EventShareClasses,
  'gov-event-template-create': GovEventTemplateCreateForm,
  'equity-investments-subscriptions': EquitySubscriptionsNavigation,
  'issuer-registry-create': IssuerRegistryCreate,
  'issuer-registry-detail': IssuerRegistryDetail,
  'issuer-registry': IssuerRegistryList,
  'owner-subscriptions': OwnerSubscriptionsNavigation,
  'payment-token-create-component': PaymentTokenCreate,
  'potential-shareholders-whitelists': ShareholdersWhitelistsNavigation,
  'registry-add-issuer': RegistryAddIssuer,
  'registry-issuer-detail': IssuerDetail,
  'report-update-bo-component': BeneficialOwnersReportForm,
  'save-event-as-template': SaveAsTemplateForm,
  'shareholders-event-template-create': ShareholdersEventTemplateCreateForm,
  'sign-transaction': SignTransactionComponent,
  marketplace: MarketsView,
  'token-create-component': TokenCreateForm,
  'token-conversion-component': TokenConvertForm,
  'personal-verification-form': PersonalVerificationForm,
  'company-registration-form': CompanyRegistrationForm,
  'initiate-token-distribution': TokenDistributeForm,
  inbox: InboxView,
  'link-signer': LinkSignerAppComponent,
  dashboard: Dashboard,
  'edit-dashboard': PersonaliseDashboard,
  'app-navigation': AppNavigation,
  'custodian-service': CustodianServiceNavigation,
  'custodial-account-edit': CustodialAccountEdit,
  'add-asset-valuation': AddAssetValuation,
  'add-liability-valuation': AddLiabilityValuation,
  'equity-investment-valuation-detail': EquityInvestmentValuationDetail,
  'transaction-price-info': TransactionPriceInfo,
  'transaction-already-confirmed': TransactionAlreadyConfirmed,
  'initialize-transfer-component': InitializeTransfer,
  'governance-events': GovernanceEventsView,
  'company-shareholders-actions': CompanyShareholdersActions,
  'inline-notification-lock-component': InlineNotificationWithLock,
  'profile-view': ProfileView,
  'error-messages-list': MessagesList,
  'registered-objects': RegisteredObjectsView,
  'assets-view': AssetsView,
  'property-token-transfer-view': PropertyTokenTransfersView,
  'property-token-arbiter-transfer-view': PropertyTokenTransfersView,
  'unsigned-transaction-modal': UnsignedTransaction,
  'update-native-overlay': UpdateNativeAppOverlay,
  registries: RegistriesView,
  'utility-tokens-issuer': UtilityTokensLiabilityView,
  boardroom: BoardroomView,
  'board-membership': BoardroomMembershipView,
  'company-signatories': SignatoriesNavigation,
  'custodial-contacts-view': ContactsNavigationView,
  data_rooms: DataRoomNavigation,
  'data-set-external-access': DataSetExternalAccessView,
  'data-rooms-verification': DataVerificationNavigation,
  'landing-sign-up-in-progress': SignUpInProgressView,
  'landing-sign-up': SignUpLandingView,
};

const ELEMENTS_LIST_TO_COMPONENTS_MAPPING = {
  '[data-contact-select]': ContactSelectFieldForm,
  '[data-voting-form-modal]': AgendaVotingForm,
  '[data-flash-message]': FlashMessage,
  '[data-row-option-toggle]': RowOptionToggle,
  '[data-documents-component]': DocumentsNavigation,
  '[data-single-shareholder-excerpt]': SingleShareholderExcerptModal,
};

export default class ReactComponentsEntry {
  render() {
    Object.keys(RENDERER_MAPPING).forEach(
      (elementId) => {
        const htmlElement = $(`#${elementId}`);
        if (htmlElement.length) {
          const Component = RENDERER_MAPPING[elementId];
          new Component(htmlElement.data()).render(elementId);
        }
      },
    );
    // simpler direct rendering
    Object.entries(COMPONENTS_MAPPING).forEach(
      ([elementId, Component]) => {
        const element = document.getElementById(elementId);
        if (element) {
          const root = createRoot(element);
          root.render(<Component {...element.dataset} />);
        }
      },
    );
    // special cases
    Object.entries(ELEMENTS_LIST_TO_COMPONENTS_MAPPING).forEach(
      ([elementQuerySelector, Component]) => {
        const elements = document.querySelectorAll(elementQuerySelector);
        elements.forEach((element) => {
          const root = createRoot(element);
          root.render(<Component {...element.dataset} />);
        });
      },
    );
  }
}
