import PropTypes from 'prop-types';

import { HeadlineValueSkeleton } from '@trustwise/design-system';
import { formatNumber } from 'core/utils';

const AssetsValue = ({ totalAssetsValue, currency }) => (
  <div className="assets-value">
    {typeof totalAssetsValue === 'number' ? (
      <div className="content-monetary-value">
        <span>{formatNumber(totalAssetsValue, 0)}</span>
        <span className="currency">{` ${currency}`}</span>
      </div>
    ) : <HeadlineValueSkeleton />}
  </div>
);

AssetsValue.defaultProps = {
  totalAssetsValue: null,
  currency: 'CHF',
};

AssetsValue.propTypes = {
  totalAssetsValue: PropTypes.number,
  currency: PropTypes.string,
};

export default AssetsValue;
