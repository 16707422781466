import { Component } from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import PropTypes from 'prop-types';

import emptyStateIcons from 'core/svgs/emptyStateIcons';
import { isNativeAppWebview } from 'utils/general';
import { icons } from 'core/icons';
import { getDocumentTypeOptions } from 'media/constants/documentTypes';
import CharField from '../common/charField';
import Document from './document';
import NonFieldErrors from '../common/nonFieldErrors';

class UploadTemplate extends Component {
  constructor(props) {
    super(props);
    this.documentTypeOptions = observable([]);
  }

  componentDidMount() {
    const { documentTypesContext } = this.props;
    this.documentTypeOptions.replace(getDocumentTypeOptions(documentTypesContext));
  }

  get inputId() {
    const { attrName, name } = this.props;
    if (name) return `id_${name}`;
    if (attrName) return `id_${attrName}`;
    return null;
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  get documentTypeErrors() {
    const { model } = this.props;
    return model && model.errors && model.errors.defaultDocumentType;
  }

  onChange = (e) => {
    const { model } = this.props;
    const files = Array.from(e.target.files);
    files.map((file) => model.addFile(file));
  };

  onClick = () => this.inputElement.click();

  renderTempFiles() {
    const { model: { documentFiles, removeFile } } = this.props;

    if (!documentFiles || !documentFiles.length) {
      return null;
    }

    return (
      <section id={this.inputId}>
        {documentFiles.map((document) => (
          <Document
            key={document.uid}
            model={document}
            remove={removeFile}
          />
        ))}
      </section>
    );
  }

  renderFileUploader() {
    const { model: { documentFiles } } = this.props;
    let dropzoneText = '';
    if (isNativeAppWebview) {
      dropzoneText = gettext('Upload files from your device.');
    } else {
      dropzoneText = gettext('Drag and drop or upload files from your device.');
    }
    return (
      <>
        <input
          type="file"
          name="file_upload"
          className="show-for-sr"
          data-enhanced-upload=""
          required=""
          id="id_file_upload"
          onChange={this.onChange}
          ref={(input) => { this.inputElement = input; }}
          multiple
        />
        {documentFiles && documentFiles.length ? (
          <button type="button" className="action" onClick={this.onClick}>
            <span className="icon">{icons.plus}</span>
            {gettext('Add file')}
          </button>
        ) : (
          <>
            <div className="dropzone" onClick={this.onClick}>
              {emptyStateIcons.document}
              <p className="dropzone-text">{dropzoneText}</p>
            </div>
            <button type="button" className="button button-outlined button-small" onClick={this.onClick}>
              <span className="icon">{icons.arrowUp}</span>
              {gettext('Upload')}
            </button>
          </>
        )}
      </>
    );
  }

  render() {
    const { model, isRequired } = this.props;
    const isInputRequired = isRequired || (!!model.documentFiles && !!model.documentFiles.length);

    return (
      <div className="document-upload-form">
        <NonFieldErrors errors={model.nonFieldErrors} />
        <section className="document-upload-section">
          <header className="section-header">
            <h3 className="title">{gettext('Properties')}</h3>
          </header>
          <CharField
            label={gettext('Subject')}
            maxLength="128"
            attrName="subject"
            handleChange={(e) => model.setSubject(e)}
            isRequired={isInputRequired}
            value={model.subject}
            model={model}
          />
          <label htmlFor={this.inputId}>{gettext('Document date')}</label>
          <input
            id={this.inputId}
            type="date"
            // eslint-disable-next-line max-len
            pattern="(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))"
            required={isInputRequired}
            value={model.date}
            onChange={(e) => model.setDate(e.target.value)}
          />
        </section>
        <section className="document-upload-section">
          <header className="section-header">
            <h3 className="title">{gettext('Files')}</h3>
            {model.documentFiles && model.documentFiles.length ? this.renderFileUploader() : null}
          </header>
          {this.renderTempFiles()}
          {(!model.documentFiles || !model.documentFiles.length) && this.renderFileUploader()}
        </section>
      </div>
    );
  }
}

UploadTemplate.defaultProps = {
  isRequired: true,
  model: null,
  attrName: '',
  name: '',
  documentTypesContext: 'USER_UPLOAD_TYPES',
};

UploadTemplate.propTypes = {
  isRequired: PropTypes.bool,
  model: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  documentTypesContext: PropTypes.string,
  attrName: PropTypes.string,
  name: PropTypes.string,
};

export default observer(UploadTemplate);
