import { snakeCase } from 'lodash';
import { useParams } from 'react-router-dom';

import axios from 'core/axios';
import { parseErrorResponse } from 'core/forms/utils';
import BackButton from 'core/page/parts/backButton';
import { PageContent } from 'core/page';
import { convertKeysCase } from 'core/utils';
import { getApiUrl, getUrl } from 'utils/urls';
import { handleTransactionCreation } from 'core/page/transactionSigning/utils';
import BaseAccountEditForm from './baseAccountEditForm';


const ShareholderAccountForm = () => {
  const { accountId } = useParams();
  const isEditing = Boolean(accountId);
  const submitLabel = isEditing ? gettext('Update') : gettext('Create');
  const pageHeading = isEditing ? gettext('Edit Shareholder Account') : gettext('Add Shareholder Account');
  const accountsListUrl = getUrl('/custodian/');

  const submitCustodialAccount = (data, actions) => {
    const url = getApiUrl(`/custodian/shareholders/${isEditing ? `${accountId}/` : ''}`);
    const method = isEditing ? 'patch' : 'post';
    axios[method](url, data)
      .then(
        ({ data: { tx_hash: txHash } }) => {
          if (isEditing) {
            window.location = accountsListUrl;
          } else {
            handleTransactionCreation(txHash, accountsListUrl);
          }
        },
        ({ response: { data: errors } }) => { parseErrorResponse(errors, actions.setFieldError); },
      )
      .catch(console.error)
      .finally(() => { actions.setSubmitting(false); });
  };

  const submitCustodialAccountDocument = (documentData, data, actions) => {
    const url = getApiUrl('/new-documents/');
    axios.post(url, documentData)
      .then(
        ({ data: { document_id: documentId } }) => {
          submitCustodialAccount({ ...data, document_ids: [documentId] }, actions);
        },
        ({ response: { data: errors } }) => {
          parseErrorResponse(errors, actions.setFieldError);
          actions.setSubmitting(false);
        },
      )
      .catch(console.error);
  };

  const onSubmit = (values, actions) => {
    actions.setSubmitting(true);
    values.entityType === 'company' && delete values.dateOfBirth;
    const {
      date: documentDate,
      subject: documentSubject,
      files: documentFiles,
      documentType,
      ...formData
    } = values;
    const data = convertKeysCase(formData, snakeCase);
    // @todo DRY
    if (documentFiles && documentFiles.length) {
      const documentData = new FormData();
      documentData.append('subject', documentSubject);
      documentData.append('doc_type', documentType);
      documentData.append('doc_date', documentDate);
      documentData.append('status', 'DRA');
      documentFiles.forEach((file) => { documentData.append('files', file); });
      submitCustodialAccountDocument(documentData, data, actions);
    } else {
      submitCustodialAccount(data, actions);
    }

  };

  return (
    <PageContent
      fullscreen
      pageHeading={pageHeading}
      headerLeft={<BackButton href={accountsListUrl} useRouterLink />}
    >
      <BaseAccountEditForm
        isEditing={isEditing}
        accountId={parseInt(accountId, 10)}
        urlBase={getApiUrl('')}
        onSubmit={onSubmit}
        submitLabel={submitLabel}
      />
    </PageContent>
  );
};

export default ShareholderAccountForm;
