import * as Yup from 'yup';
import { DocumentModel } from 'media/models';
import { dateToday } from 'utils/date';
import { fieldRequired } from 'core/forms/utils';
import { legalTextDefault, powerOfAttorneyDefault } from '../const';


const createSubscriptionValidation = Yup.object({
  name: Yup.string().required(fieldRequired),
  whitelistId: Yup.number().required(fieldRequired),
  shareClassAddress: Yup.number().required(fieldRequired),
  currency: Yup.string().max(3, gettext('Line is too long')).required(fieldRequired),
  pricePerShare: Yup.number().moreThan(0, gettext('Should be above 0')).required(fieldRequired),
  minNumberOfShares: Yup.number().required(fieldRequired).when('maxNumberOfShares', {
    is: 0,
    then: Yup.number().integer(),
    otherwise: Yup.number().integer().when(
      'maxNumberOfShares',
      (maxNumberOfShares, schema) => (
        schema.max(maxNumberOfShares, gettext('Should be smaller or equal to maximum number of shares'))
      ),
    ),
  }),
  maxNumberOfShares: Yup.number().integer(),
  maxNumberPerSubscriber: Yup.number().when('maxNumberOfShares', {
    is: 0,
    then: Yup.number().integer(),
    otherwise: Yup.number().integer().when(
      'maxNumberOfShares',
      (maxNumberOfShares, schema) => (
        schema.max(maxNumberOfShares, gettext('Should be smaller or equal to maximum number of shares'))
      ),
    ),
  }),
  minNumberPerSubscription: Yup.number().integer().moreThan(0, gettext('Should be above 0')),
  startDate: Yup.date().min(dateToday(), gettext("Start date can't be in the past")),
  startTime: Yup.string().test('empty', gettext("Time can't be empty"), (startTime) => !!startTime),
  endDate: Yup.date().when(
    'startDate',
    (startDate, schema) => (schema.min(startDate, gettext('End date should be greater than the start date'))),
  ),
  endTime: Yup.string().test('empty', gettext("Time can't be empty"), (endTime) => !!endTime),
  closingDate: Yup.date().when(
    'endDate',
    (endDate, schema) => (schema.min(endDate, gettext('Closing date should be greater than the end date'))),
  ),
  closingTime: Yup.string().test('empty', gettext("Time can't be empty"), (closingTime) => !!closingTime),
  legalText: Yup.string().test(
    'edited',
    gettext('Please, edit the highlighted items in the text'),
    (legalText) => legalText !== legalTextDefault,
  ),
  powerOfAttorney: Yup.string().test(
    'edited',
    gettext('Please, edit the highlighted items in the text'),
    (powerOfAttorney) => powerOfAttorney !== powerOfAttorneyDefault,
  ),
}).concat(DocumentModel.simpleUploadValidation);

export default createSubscriptionValidation;
