import PropTypes from 'prop-types';

const GraphDateRange = ({ from, to }) => (
  <div className="fx-date-range helptext text-smaller">
    <span>{from}</span>
    <span>{to}</span>
  </div>
);

GraphDateRange.propTypes = {
  from: PropTypes.string,
  to: PropTypes.string,
};

GraphDateRange.defaultProps = {
  from: null,
  to: null,
};

export default GraphDateRange;
