import { camelCase } from 'lodash';

import { convertKeysCase, convertNestedKeysCase } from 'core/utils';
import convertResponseToModel from 'utils/responseToModel';
import { RegistryDetailModel } from './models/registry';
import { RegistryMemberDetailModel, RegistryMemberRecordsModel } from './models/registryMember';

const reducer = (currentState, action) => {
  const { data, type } = action;

  switch (type) {
    case 'fetchRegistry':
      return {
        ...currentState,
        registry: new RegistryDetailModel(convertKeysCase(data, camelCase)),
      };

    case 'fetchPermissions':
      return {
        ...currentState,
        permissions: data,
      };

    case 'fetchMember':
      return {
        ...currentState,
        member: {
          ...currentState.member,
          details: new RegistryMemberDetailModel(convertNestedKeysCase(data, camelCase)),
        },
      };

    case 'fetchMemberPermissions':
      return {
        ...currentState,
        member: {
          ...currentState.member,
          permissions: data,
        },
      };

    case 'fetchRecords':
      return {
        ...currentState,
        records: convertResponseToModel(data, RegistryMemberRecordsModel),
      };

    case 'addRecord':
      return {
        ...currentState,
        records: [new RegistryMemberRecordsModel(data), ...currentState.records],
      };

    default:
      return currentState;
  }
};

export default reducer;
