/* Empty state icons */
/* eslint-disable max-len */
const emptyStateIcons = {
  shareholders: (
    <svg width="64" height="48" viewBox="0 0 64 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse opacity="0.7" cx="49" cy="23" rx="7" ry="7.00001" fill="#D6D6DC" />
      <ellipse opacity="0.5" cx="32" cy="23" rx="7" ry="7.00001" fill="#D6D6DC" />
      <ellipse opacity="0.3" cx="15" cy="23" rx="7" ry="7.00001" fill="#D6D6DC" />
      <path opacity="0.7" d="M64 48C64 44.0218 62.4196 40.2064 59.6066 37.3934C56.7936 34.5804 52.9782 33 49 33C45.0218 33 41.2064 34.5804 38.3934 37.3934C35.5804 40.2064 34 44.0218 34 48L49 48H64Z" fill="#D6D6DC" />
      <path opacity="0.5" d="M47 48C47 44.0218 45.4196 40.2064 42.6066 37.3934C39.7936 34.5804 35.9782 33 32 33C28.0218 33 24.2064 34.5804 21.3934 37.3934C18.5804 40.2064 17 44.0218 17 48L32 48H47Z" fill="#D6D6DC" />
      <path opacity="0.3" d="M30 48C30 44.0218 28.4196 40.2064 25.6066 37.3934C22.7936 34.5804 18.9782 33 15 33C11.0218 33 7.20645 34.5804 4.3934 37.3934C1.58035 40.2064 6.00699e-07 44.0218 0 48L15 48H30Z" fill="#D6D6DC" />
    </svg>
  ),
  document: (
    <svg width="40" height="48" viewBox="0 0 40 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.586511 10.2872C0.26259 8.10195 1.7715 6.06785 3.95677 5.74393L18.3665 3.60798L29.3694 11.7701L33.3993 38.9565C33.7232 41.1418 32.2143 43.1759 30.029 43.4998L9.99353 46.4696C7.80826 46.7936 5.77417 45.2847 5.45025 43.0994L0.586511 10.2872Z" fill="#E6E6EC" />
      <path d="M11.7456 4C11.7456 1.79087 13.5365 0 15.7456 0H30.3128L40 9.68722V37.1707C40 39.3798 38.2091 41.1707 36 41.1707H15.7456C13.5365 41.1707 11.7456 39.3798 11.7456 37.1707V4Z" fill="#D6D6DC" />
      <path opacity="0.7" d="M30.3126 0L39.9998 9.68722L27.0835 12.9163L30.3126 0Z" fill="#B8B8BD" />
      <path d="M30.313 0L40.0002 9.68722H30.313V0Z" fill="#F3F3F7" />
    </svg>
  ),
  calendar: (
    <svg width="64" height="48" viewBox="0 0 64 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13 12C13 10.8954 13.8954 10 15 10H50C51.1046 10 52 10.8954 52 12V46C52 47.1046 51.1046 48 50 48H15C13.8954 48 13 47.1046 13 46V12Z" fill="#F3F3F7" />
      <path d="M13 12C13 10.8954 13.8954 10 15 10H50C51.1046 10 52 10.8954 52 12V22H13V12Z" fill="#D6D6DC" />
      <circle cx="22.5" cy="29.5" r="2.5" fill="#E6E6EC" />
      <ellipse cx="22.5" cy="38.5" rx="2.5" ry="2.5" fill="#E6E6EC" />
      <circle cx="42.5" cy="29.5" r="2.5" fill="#E6E6EC" />
      <ellipse cx="32.5" cy="29.5" rx="2.5" ry="2.5" fill="#E6E6EC" />
      <ellipse cx="32.5" cy="38.5" rx="2.5" ry="2.5" fill="#E6E6EC" />
      <ellipse cx="42.5" cy="38.5" rx="2.5" ry="2.5" fill="#E6E6EC" />
      <rect x="22" y="6" width="3" height="9" rx="1.5" fill="#E6E6EC" />
      <rect x="40" y="6" width="3" height="9" rx="1.5" fill="#E6E6EC" />
    </svg>
  ),
  inbox: (
    <svg width="49" height="26" viewBox="0 0 49 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.5" d="M0 11L10 0V13L0 24V11Z" fill="#D6D6DC" />
      <path opacity="0.1" d="M49 11L39 0V13L49 24V11Z" fill="#D6D6DC" />
      <path opacity="0.3" d="M10 0H39V16H10V0Z" fill="#D6D6DC" />
      <path fillRule="evenodd" clipRule="evenodd" d="M14.6669 9H3.5C1.567 9 0 10.567 0 12.5V22.5C0 24.433 1.567 26 3.5 26H45.5C47.433 26 49 24.433 49 22.5V12.5C49 10.567 47.433 9 45.5 9H35.3335C35.3335 11.7614 33.0949 14 30.3335 14H19.6669C16.9055 14 14.6669 11.7614 14.6669 9Z" fill="#D6D6DC" />
    </svg>
  ),
};

export default emptyStateIcons;
