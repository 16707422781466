import web3, { HexString } from 'web3';

const SALT = 'X^h#5KPNpMasmtAB';
const IV_LENGTH = 12;

export const hasCryptoSupport = !!(window.crypto && window.crypto.subtle && window.TextEncoder);

const importKey = (password: string): Promise<CryptoKey> => window.crypto.subtle.importKey(
  'raw',
  new TextEncoder().encode(password),
  { name: 'PBKDF2' },
  false,
  ['deriveBits', 'deriveKey'],
);

const deriveKey = (importedKey: CryptoKey, salt: Uint8Array) => window.crypto.subtle.deriveKey(
  { name: 'PBKDF2', iterations: 2_000_000, hash: 'SHA-256', salt },
  importedKey,
  { name: 'AES-GCM', length: 256 },
  true,
  ['encrypt', 'decrypt'],
);

export const encrypt = async (buffer: BufferSource, password: string): Promise<Uint8Array> => {
  const encoder = new TextEncoder();
  const importedKey = await importKey(password);
  const key = await deriveKey(importedKey, encoder.encode(SALT));
  const iv = window.crypto.getRandomValues(new Uint8Array(IV_LENGTH));
  const cipherArrayBuffer = await window.crypto.subtle.encrypt({ name: 'AES-GCM', iv }, key, buffer);
  const cipher = new Uint8Array(cipherArrayBuffer, 0, cipherArrayBuffer.byteLength);
  const result = new Uint8Array(iv.length + cipher.length);
  result.set(iv);
  result.set(cipher, iv.length);
  return result;
};

export const encryptFile = (file: File, password: string): Promise<Uint8Array> => new Promise((resolve, reject) => {
  const reader = new FileReader();

  reader.readAsArrayBuffer(file);

  reader.onload = () => {
    if (reader.result === null) {
      reject(new Error('File is empty'));
    }
    const arrayBuffer = reader.result as ArrayBuffer;
    const buffer = new Uint8Array(arrayBuffer);
    encrypt(buffer, password).then((encrypted) => {
      resolve(encrypted);
    });
  };

  reader.onerror = (error) => {
    reject(error);
  };
});

export const decrypt = async (value: HexString, password: string): Promise<string> => {
  const result = Uint8Array.from(web3.utils.hexToBytes(value));
  const iv = result.subarray(0, IV_LENGTH);
  const cipher = result.subarray(IV_LENGTH, result.length);
  const importedKey = await importKey(password);
  const key = await deriveKey(importedKey, new TextEncoder().encode(SALT));
  try {
    const decrypted = await window.crypto.subtle.decrypt({ name: 'AES-GCM', iv }, key, cipher);
    return new TextDecoder().decode(decrypted);
  } catch (e) {
    console.error(e);
    return '';
  }
};
