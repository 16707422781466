import { useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import {
  CheckboxField,
  ReactSelectField,
  MonetaryField,
  SubmitButton,
  TextField,
  FieldErrors,
  DateField,
} from 'core/forms/fields';
import { getApiUrl, getUrl } from 'utils/urls';
import { DocumentModel } from 'media/models';
import { dateToday } from 'utils/date';
import SwitchableDocumentUpload from 'media/documents/switchableUpload';
import { parseErrorResponse } from 'core/forms/utils';
import axios from 'core/axios';
import { handleTransactionCreation } from 'core/page/transactionSigning/utils';
import { tokenTypes, RVR_EQUITY } from './constants';
import TokenCreateBase from './tokenCreateBase';


const TokenCreateForm = ({ initialCurrency }) => {
  const [tokenType, setTokenType] = useState(RVR_EQUITY);
  const [previewQueryArgs, setPreviewQueryArgs] = useState({ currency: initialCurrency });

  const onSubmit = (values, actions) => {
    actions.setSubmitting(true);
    const data = new FormData();
    data.append('name', values.name);
    data.append('currency', values.currency);
    data.append('initial_amount', values.initialAmount);
    data.append('event_date', values.eventDate);
    data.append('nominal_value', values.nominalValue);
    data.append('document_type', values.documentType);
    values.files.forEach((file) => {
      data.append('files', file);
    });
    data.append('date', values.date);
    data.append('subject', values.subject);
    data.append('represents_equity', values.tokenType === RVR_EQUITY);
    if (values.tokenType === RVR_EQUITY) {
      data.append('is_restricted', values.isRestricted);
      data.append('capital', (values.initialAmount * values.nominalValue).toFixed(2));
    }
    axios.post(getApiUrl('/tokens/'), data)
      .then(
        ({ data: { tx_hash: txHash } }) => {
          handleTransactionCreation(txHash, getUrl('/liabilities/equity/'));
        },
        ({ response: { data: errors } }) => { parseErrorResponse(errors, actions.setFieldError); },
      )
      .catch(console.error)
      .finally(() => { actions.setSubmitting(false); });
  };

  return (
    <TokenCreateBase
      pageHeading={gettext('Add equity')}
      backUrl={getUrl('/liabilities/equity/')}
      previewQueryArgs={previewQueryArgs}
    >
      <Formik
        initialValues={{
          nonFieldErrors: '',
          tokenType: RVR_EQUITY,
          isRestricted: false,
          name: '',
          initialAmount: 0,
          nominalValue: (0).toFixed(2),
          currency: initialCurrency,
          capital: (0).toFixed(2),
          eventDate: dateToday(),
          ...DocumentModel.uploadInitialValues,
        }}
        validationSchema={Yup.object({
          tokenType: Yup.string().max(4, gettext('Line is too long')).required(gettext('This field is required')),
          isRestricted: Yup.boolean(),
          name: Yup.string().required(gettext('This field is required')),
          initialAmount: Yup.number()
            .when('tokenType', {
              is: tokenType === RVR_EQUITY,
              then: Yup.number().integer(gettext('Must be integer')),
              otherwise: Yup.number(),
            }).min(0, gettext('Must be above 0')).required(gettext('This field is required')),
          nominalValue: Yup.number()
            .when('tokenType', {
              is: tokenType === RVR_EQUITY,
              then: Yup.number().moreThan(0.01, gettext('Must be above 0')).required(gettext('This field is required')),
              otherwise: Yup.number(),
            }),
          currency: Yup.string().max(3, gettext('Line is too long')).required(gettext('This field is required')),
          capital: Yup.number()
            .when('tokenType', {
              is: tokenType === RVR_EQUITY,
              then: Yup.number().min(0, gettext('Must be above 0')).required(gettext('This field is required')),
              otherwise: Yup.number(),
            }),
          eventDate: Yup.date(),
        }).concat(DocumentModel.simpleUploadValidation)}
        onSubmit={onSubmit}
      >
        {({ values, isSubmitting, touched }) => {
          if (touched.name && values.name !== previewQueryArgs.name) {
            setPreviewQueryArgs({ ...previewQueryArgs, name: values.name });
          }
          if (touched.initialAmount && values.initialAmount !== previewQueryArgs.amount) {
            setPreviewQueryArgs({ ...previewQueryArgs, amount: values.initialAmount });
          }
          if (touched.nominalValue && values.nominalValue !== previewQueryArgs.nominal_value) {
            setPreviewQueryArgs({ ...previewQueryArgs, nominal_value: values.nominalValue });
          }
          if (touched.currency && values.currency !== previewQueryArgs.currency) {
            setPreviewQueryArgs({ ...previewQueryArgs, currency: values.currency });
          }

          return (
            <Form>
              <FieldErrors name="nonFieldErrors" />
              <ReactSelectField
                label={gettext('Share type')}
                name="tokenType"
                getOptionLabel={(option) => option.text}
                getOptionValue={(option) => option.id}
                options={tokenTypes}
                isOptionDisabled={(option) => option.disabled === true}
                setValue={(option) => setTokenType(option.id)}
              />
              {tokenType === RVR_EQUITY && <CheckboxField name="isRestricted" label={gettext('Restricted')} />}
              <DateField label={gettext('Issuing date')} name="eventDate" />
              <TextField label={gettext('Name of financial instrument')} name="name" />
              <TextField label={gettext('Number of shares')} name="initialAmount" type="number" />
              <MonetaryField
                label={gettext('Nominal value')}
                currencyName="currency"
                valueName="nominalValue"
              />
              {tokenType === RVR_EQUITY && (
              <TextField
                label={gettext('Share capital')}
                name="capital"
                value={(values.initialAmount * values.nominalValue).toFixed(2)}
                type="number"
                disabled
              />
              )}
              <SwitchableDocumentUpload />
              <SubmitButton disabled={isSubmitting}>{gettext('Create')}</SubmitButton>
            </Form>
          );
        }}
      </Formik>
    </TokenCreateBase>
  );
};

TokenCreateForm.defaultProps = {
  initialCurrency: '',
};

TokenCreateForm.propTypes = {
  initialCurrency: PropTypes.string,
};


export default TokenCreateForm;
