import uuid from 'uuid';
import PropTypes from 'prop-types';
import AccountAvatar from './accountAvatar';

const AccountsSection = (props) => {
  const { collection, title } = props;
  return (
    <div className="account-switcher-section grid-x grid-padding-x">
      <div className="cell">
        <h2 className="section-header underline">{title}</h2>
      </div>
      {
        collection.map((account) => (
          <div key={uuid.v4()} className="cell small-4">
            <AccountAvatar account={account} />
          </div>
        ))
      }
    </div>
  );
};

// eslint-disable-next-line no-restricted-exports
export { AccountsSection as default };

AccountsSection.propTypes = {
  collection: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  title: PropTypes.string.isRequired,
};
