
import { CreatorsWidget as CreatorsDashboardWidget } from '@trustwise/design-system';
import { orellFussliLogo, tndLogo, trustwiseLogo } from './logos';

const CreatorsWidget = () => (
  <CreatorsDashboardWidget
    creators={[
      {
        src: orellFussliLogo,
        alt: gettext('Orell Füssli'),
        maxWidth: 112,
      },
      {
        src: tndLogo,
        alt: gettext('TND'),
        maxWidth: 90,
      },
      {
        src: trustwiseLogo,
        alt: gettext('trustwise.io ag'),
      },
    ]}
  />
);

export default CreatorsWidget;
