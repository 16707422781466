/* File thumbnail component */
import PropTypes from 'prop-types';
import ContentLoadingMessage from 'utils/contentLoadingMessage';
import ThumbnailDetails from './parts/thumbnailDetails';

const FileThumbnail = ({ files, dispatch, noOfFiles }) => {

  const filesCounter = [];
  for (let index = 0; index < noOfFiles; index++) {
    filesCounter.push(index + 1);

  }
  if (noOfFiles > 0) {
    if (Object.keys(files).length === 0) {
      return (
        <ol>
          {filesCounter.map((counter) => (
            <div key={counter} className="document-attachment-wrapper"><div className="loader" /></div>
          ))}
        </ol>
      );
    }
    return (
      <ol>
        {Object.values(files).map((file) => (
          <li key={file.id}>
            <ThumbnailDetails
              file={file}
              dispatch={dispatch}
            />
          </li>
        ))}
      </ol>
    );
  }
  return (
    <ContentLoadingMessage
      isLoading={false}
      loadedMessage={gettext('No files')}
    />
  );
};

FileThumbnail.defaultProps = {
  files: null,
  noOfFiles: 0,
};

FileThumbnail.propTypes = {
  files: PropTypes.shape(),
  dispatch: PropTypes.func.isRequired,
  noOfFiles: PropTypes.number,
};

export default FileThumbnail;
