import { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import FieldErrors from './fieldErrors';

class CheckBox extends Component {
  // TODO: DRY, introduce BaseField class
  get label() {
    const { label } = this.props;
    return label;
  }

  get additionalClasses() {
    const { additionalClasses } = this.props;
    return additionalClasses;
  }

  get isRequired() {
    const { isRequired } = this.props;

    return !!isRequired;
  }

  get getErrors() {
    const { errors, model, attrName } = this.props;

    if (errors) return errors;
    if (
      model
      && model.errors
      && Object.keys(model.errors).includes(attrName)
    ) return model.errors[attrName];

    return [];
  }

  handleCheck = (_e) => {
    const { handleChange, attrName, value } = this.props;
    const changedTo = !value;

    if (attrName) return handleChange(changedTo, attrName);

    return handleChange(changedTo);
  };

  render() {
    const { value, attrName } = this.props;
    const identifier = `id-${attrName}`;
    const checked = !!value;

    return (
      <div className={`radio-checkbox-wrapper ${this.additionalClasses}`}>
        <input
          type="checkbox"
          onChange={this.handleCheck}
          required={this.isRequired}
          checked={checked}
          className="checkbox-input"
          id={identifier}
        />
        <label htmlFor={identifier} className="radio-checkbox-label">
          {this.label}
        </label>
        <FieldErrors errors={this.getErrors} />
      </div>
    );
  }
}

CheckBox.defaultProps = {
  handleChange: () => {},
  additionalClasses: '',
  label: '',
  attrName: '',
  isRequired: false,
  errors: [],
  model: null,
  value: null,
};

CheckBox.propTypes = {
  handleChange: PropTypes.func,
  label: PropTypes.string,
  attrName: PropTypes.string,
  isRequired: PropTypes.bool,
  errors: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  model: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  additionalClasses: PropTypes.string,
};

export default observer(CheckBox);
