/* Pricing icons */

/* eslint-disable max-len */
const pricingIcons = {
  basic: (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.64 44L26.64 4L47.4246 32L26.64 44Z" fill="url(#paint0_linear_1206_48063)" />
      <path d="M26.6399 44L26.6399 4L5.99989 32L26.6399 44Z" fill="url(#paint1_linear_1206_48063)" />
      <defs>
        <linearGradient id="paint0_linear_1206_48063" x1="33.6" y1="3.44" x2="57.6448" y2="26.7571" gradientUnits="userSpaceOnUse">
          <stop stopColor="#2C156D" />
          <stop offset="0.770012" stopColor="#351A82" />
        </linearGradient>
        <linearGradient id="paint1_linear_1206_48063" x1="11.6725" y1="-7.72632" x2="29.67" y2="12.1863" gradientUnits="userSpaceOnUse">
          <stop offset="0.277418" stopColor="#A88EF1" />
          <stop offset="0.814557" stopColor="#7C5DD1" />
        </linearGradient>
      </defs>
    </svg>
  ),
  elementary: (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M29 45L10.8135 34.5L10.8135 13.5L29 3L47.1865 13.5L47.1865 34.5L29 45Z" fill="url(#paint0_linear_1206_48102)" />
      <path d="M29 45L29 24L47.1865 13.5L47.1865 34.5L29 45Z" fill="url(#paint1_linear_1206_48102)" />
      <path d="M29 45L29 24L10.94 13.5L10.94 34.5L29 45Z" fill="url(#paint2_linear_1206_48102)" />
      <defs>
        <linearGradient id="paint0_linear_1206_48102" x1="20.81" y1="-4.14" x2="34.3435" y2="23.6363" gradientUnits="userSpaceOnUse">
          <stop offset="0.277418" stopColor="#A88EF1" />
          <stop offset="0.814557" stopColor="#7C5DD1" />
        </linearGradient>
        <linearGradient id="paint1_linear_1206_48102" x1="35.09" y1="9.51" x2="56.1292" y2="29.9124" gradientUnits="userSpaceOnUse">
          <stop stopColor="#2C156D" />
          <stop offset="0.770012" stopColor="#351A82" />
        </linearGradient>
        <linearGradient id="paint2_linear_1206_48102" x1="8.84" y1="21.27" x2="26.4402" y2="13.8738" gradientUnits="userSpaceOnUse">
          <stop stopColor="#9879EE" />
          <stop offset="0.614583" stopColor="#6E4CCB" />
        </linearGradient>
      </defs>
    </svg>
  ),
  scale: (
    <svg width="50" height="48" viewBox="0 0 50 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M29.2935 23.3506L19.826 17.8845L19.826 6.95235L29.2935 1.48628L38.7611 6.95235L38.7611 17.8845L29.2935 23.3506Z" fill="url(#paint0_linear_1206_48161)" />
      <path d="M29.2935 36.4702L29.2935 12.4195L38.761 6.9534L38.761 31.0041L29.2935 36.4702Z" fill="url(#paint1_linear_1206_48161)" />
      <path d="M29.2935 36.4702L29.2935 12.4195L19.8919 6.9534L19.8919 31.0041L29.2935 36.4702Z" fill="url(#paint2_linear_1206_48161)" />
      <path d="M18.9692 39.7485L9.50169 34.2825L9.50169 23.3503L18.9692 17.8842L28.4367 23.3503L28.4367 34.2825L18.9692 39.7485Z" fill="url(#paint3_linear_1206_48161)" />
      <path d="M18.9692 40.7212L18.9692 28.8173L28.4367 23.3512L28.4367 35.2551L18.9692 40.7212Z" fill="url(#paint4_linear_1206_48161)" />
      <path d="M18.9692 40.7212L18.9692 28.8173L9.56757 23.3512L9.56757 35.2551L18.9692 40.7212Z" fill="url(#paint5_linear_1206_48161)" />
      <path d="M38.404 33.7959L28.9365 28.3298L28.9365 17.3977L38.404 11.9316L47.8716 17.3977L47.8716 28.3298L38.404 33.7959Z" fill="url(#paint6_linear_1206_48161)" />
      <path d="M38.404 40.8423L38.404 22.865L47.8715 17.3989L47.8715 35.3762L38.404 40.8423Z" fill="url(#paint7_linear_1206_48161)" />
      <path d="M38.404 40.8423L38.404 22.865L29.0024 17.3989L29.0024 35.3762L38.404 40.8423Z" fill="url(#paint8_linear_1206_48161)" />
      <path d="M28.6863 45.2158L19.2187 39.7497L19.2187 34.891L28.6863 29.4249L38.1538 34.891L38.1538 39.7497L28.6863 45.2158Z" fill="url(#paint9_linear_1206_48161)" />
      <path d="M28.6863 46.1885L28.6863 40.358L38.1538 34.8919L38.1538 40.7224L28.6863 46.1885Z" fill="url(#paint10_linear_1206_48161)" />
      <path d="M28.6863 46.1885L28.6863 40.358L19.2847 34.8919L19.2847 40.7224L28.6863 46.1885Z" fill="url(#paint11_linear_1206_48161)" />
      <defs>
        <linearGradient id="paint0_linear_1206_48161" x1="25.03" y1="-2.23065" x2="40.3274" y2="14.8355" gradientUnits="userSpaceOnUse">
          <stop offset="0.277418" stopColor="#B69FF7" />
          <stop offset="0.814557" stopColor="#7B54E9" />
        </linearGradient>
        <linearGradient id="paint1_linear_1206_48161" x1="32.4638" y1="4.87628" x2="43.4164" y2="15.4973" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5834BE" />
          <stop offset="0.770012" stopColor="#402297" />
        </linearGradient>
        <linearGradient id="paint2_linear_1206_48161" x1="18.7987" y1="10.9983" x2="45.6729" y2="29.4941" gradientUnits="userSpaceOnUse">
          <stop stopColor="#9879EE" />
          <stop offset="0.614583" stopColor="#6E4CCB" />
        </linearGradient>
        <linearGradient id="paint3_linear_1206_48161" x1="14.7057" y1="14.1673" x2="30.0031" y2="31.2334" gradientUnits="userSpaceOnUse">
          <stop offset="0.277418" stopColor="#B69FF7" />
          <stop offset="0.814557" stopColor="#7B54E9" />
        </linearGradient>
        <linearGradient id="paint4_linear_1206_48161" x1="22.1395" y1="9.12726" x2="33.0921" y2="19.7483" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5834BE" />
          <stop offset="0.770012" stopColor="#402297" />
        </linearGradient>
        <linearGradient id="paint5_linear_1206_48161" x1="8.47436" y1="15.2493" x2="35.3486" y2="33.7451" gradientUnits="userSpaceOnUse">
          <stop stopColor="#9879EE" />
          <stop offset="0.614583" stopColor="#6E4CCB" />
        </linearGradient>
        <linearGradient id="paint6_linear_1206_48161" x1="34.1405" y1="8.21466" x2="49.4379" y2="25.2808" gradientUnits="userSpaceOnUse">
          <stop offset="0.277418" stopColor="#B69FF7" />
          <stop offset="0.814557" stopColor="#7B54E9" />
        </linearGradient>
        <linearGradient id="paint7_linear_1206_48161" x1="41.5743" y1="9.24836" x2="52.5269" y2="19.8694" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5834BE" />
          <stop offset="0.770012" stopColor="#402297" />
        </linearGradient>
        <linearGradient id="paint8_linear_1206_48161" x1="27.9092" y1="15.3704" x2="54.7834" y2="33.8662" gradientUnits="userSpaceOnUse">
          <stop stopColor="#9879EE" />
          <stop offset="0.614583" stopColor="#6E4CCB" />
        </linearGradient>
        <linearGradient id="paint9_linear_1206_48161" x1="24.4228" y1="25.708" x2="39.7202" y2="42.7741" gradientUnits="userSpaceOnUse">
          <stop offset="0.277418" stopColor="#B69FF7" />
          <stop offset="0.814557" stopColor="#7B54E9" />
        </linearGradient>
        <linearGradient id="paint10_linear_1206_48161" x1="31.8566" y1="14.5945" x2="42.8092" y2="25.2156" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5834BE" />
          <stop offset="0.770012" stopColor="#402297" />
        </linearGradient>
        <linearGradient id="paint11_linear_1206_48161" x1="18.1915" y1="20.7166" x2="45.0657" y2="39.2124" gradientUnits="userSpaceOnUse">
          <stop stopColor="#9879EE" />
          <stop offset="0.614583" stopColor="#6E4CCB" />
        </linearGradient>
      </defs>
    </svg>
  ),
  advanced: (
    <svg width="53" height="49" viewBox="0 0 53 49" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.0458 15.2305L18.8739 11.6671L18.8739 4.54044L25.0458 0.977093L31.2177 4.54044L31.2177 11.6671L25.0458 15.2305Z" fill="url(#paint0_linear_1206_48241)" />
      <path d="M25.0458 27.7422L25.0458 8.1042L31.2177 4.54086L31.2177 24.1788L25.0458 27.7422Z" fill="url(#paint1_linear_1206_48241)" />
      <path d="M25.0458 27.7422L25.0458 8.1042L18.9169 4.54086L18.9169 24.1788L25.0458 27.7422Z" fill="url(#paint2_linear_1206_48241)" />
      <path d="M16.1403 24.1797L9.96845 20.6163L9.96845 13.4897L16.1403 9.92631L22.3122 13.4897L22.3122 20.6163L16.1403 24.1797Z" fill="url(#paint3_linear_1206_48241)" />
      <path d="M16.1404 32.7324L16.1404 17.0537L22.3123 13.4904L22.3123 29.1691L16.1404 32.7324Z" fill="url(#paint4_linear_1206_48241)" />
      <path d="M16.1404 32.7324L16.1404 17.0537L10.0114 13.4904L10.0114 29.1691L16.1404 32.7324Z" fill="url(#paint5_linear_1206_48241)" />
      <path d="M7.43093 33.228L1.25904 29.6647L1.25904 22.538L7.43093 18.9747L13.6028 22.538L13.6028 29.6647L7.43093 33.228Z" fill="url(#paint6_linear_1206_48241)" />
      <path d="M7.43089 37.8213L7.43089 26.1018L13.6028 22.5385L13.6028 34.2579L7.43089 37.8213Z" fill="url(#paint7_linear_1206_48241)" />
      <path d="M7.43097 37.8213L7.43097 26.1018L1.30202 22.5385L1.30202 34.2579L7.43097 37.8213Z" fill="url(#paint8_linear_1206_48241)" />
      <path d="M33.7564 23.3135L27.5845 19.7501L27.5845 12.6234L33.7564 9.0601L39.9283 12.6234L39.9283 19.7501L33.7564 23.3135Z" fill="url(#paint9_linear_1206_48241)" />
      <path d="M33.7565 32.8896L33.7565 16.1875L39.9284 12.6242L39.9284 29.3263L33.7565 32.8896Z" fill="url(#paint10_linear_1206_48241)" />
      <path d="M33.7567 32.8896L33.7567 16.1875L27.6277 12.6242L27.6277 29.3263L33.7567 32.8896Z" fill="url(#paint11_linear_1206_48241)" />
      <path d="M24.9489 32.4067L18.777 28.8434L18.777 21.7167L24.9489 18.1534L31.1208 21.7167L31.1208 28.8434L24.9489 32.4067Z" fill="url(#paint12_linear_1206_48241)" />
      <path d="M24.949 37.9785L24.949 25.2805L31.1209 21.7171L31.1209 34.4152L24.949 37.9785Z" fill="url(#paint13_linear_1206_48241)" />
      <path d="M24.949 37.9785L24.949 25.2805L18.8201 21.7171L18.8201 34.4152L24.949 37.9785Z" fill="url(#paint14_linear_1206_48241)" />
      <path d="M16.239 40.2793L10.0671 36.716L10.0671 29.5893L16.239 26.0259L22.4109 29.5893L22.4109 36.716L16.239 40.2793Z" fill="url(#paint15_linear_1206_48241)" />
      <path d="M16.2391 42.8701L16.2391 33.1527L22.411 29.5894L22.411 39.3068L16.2391 42.8701Z" fill="url(#paint16_linear_1206_48241)" />
      <path d="M16.2391 42.8701L16.2391 33.1527L10.1102 29.5894L10.1102 39.3068L16.2391 42.8701Z" fill="url(#paint17_linear_1206_48241)" />
      <path d="M42.6626 33.2432L36.4907 29.6798L36.4907 22.5531L42.6626 18.9898L48.8345 22.5531L48.8345 29.6798L42.6626 33.2432Z" fill="url(#paint18_linear_1206_48241)" />
      <path d="M42.6625 37.8369L42.6625 26.1175L48.8344 22.5541L48.8344 34.2736L42.6625 37.8369Z" fill="url(#paint19_linear_1206_48241)" />
      <path d="M42.6626 37.8369L42.6626 26.1175L36.5336 22.5541L36.5336 34.2736L42.6626 37.8369Z" fill="url(#paint20_linear_1206_48241)" />
      <path d="M33.7571 42.3892L27.5852 38.8258L27.5852 30.7205L33.7571 27.1572L39.929 30.7205L39.929 38.8258L33.7571 42.3892Z" fill="url(#paint21_linear_1206_48241)" />
      <path d="M33.7571 43.0234L33.7571 34.2847L39.929 30.7213L39.929 39.4601L33.7571 43.0234Z" fill="url(#paint22_linear_1206_48241)" />
      <path d="M33.7571 43.0234L33.7571 34.2847L27.6282 30.7213L27.6282 39.4601L33.7571 43.0234Z" fill="url(#paint23_linear_1206_48241)" />
      <path d="M25.0469 43.4648L18.875 39.9015L18.875 37.7127L25.0469 34.1494L31.2188 37.7127L31.2187 39.9015L25.0469 43.4648Z" fill="url(#paint24_linear_1206_48241)" />
      <path d="M25.047 48.0142L25.047 41.2774L31.2189 37.7141L31.2189 44.4508L25.047 48.0142Z" fill="url(#paint25_linear_1206_48241)" />
      <path d="M25.047 48.0142L25.047 41.2774L18.918 37.7141L18.918 44.4508L25.047 48.0142Z" fill="url(#paint26_linear_1206_48241)" />
      <defs>
        <linearGradient id="paint0_linear_1206_48241" x1="22.2664" y1="-1.44598" x2="32.2388" y2="9.67945" gradientUnits="userSpaceOnUse">
          <stop offset="0.277418" stopColor="#B69FF7" />
          <stop offset="0.814557" stopColor="#7B54E9" />
        </linearGradient>
        <linearGradient id="paint1_linear_1206_48241" x1="27.1125" y1="7.14606" x2="34.2525" y2="14.0699" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5834BE" />
          <stop offset="0.770012" stopColor="#402297" />
        </linearGradient>
        <linearGradient id="paint2_linear_1206_48241" x1="18.2042" y1="11.137" x2="35.7236" y2="23.1945" gradientUnits="userSpaceOnUse">
          <stop stopColor="#9879EE" />
          <stop offset="0.614583" stopColor="#6E4CCB" />
        </linearGradient>
        <linearGradient id="paint3_linear_1206_48241" x1="13.3609" y1="7.50324" x2="23.3334" y2="18.6287" gradientUnits="userSpaceOnUse">
          <stop offset="0.277418" stopColor="#B69FF7" />
          <stop offset="0.814557" stopColor="#7B54E9" />
        </linearGradient>
        <linearGradient id="paint4_linear_1206_48241" x1="18.2071" y1="12.1363" x2="25.3471" y2="19.0602" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5834BE" />
          <stop offset="0.770012" stopColor="#402297" />
        </linearGradient>
        <linearGradient id="paint5_linear_1206_48241" x1="9.29876" y1="16.1272" x2="26.8181" y2="28.1847" gradientUnits="userSpaceOnUse">
          <stop stopColor="#9879EE" />
          <stop offset="0.614583" stopColor="#6E4CCB" />
        </linearGradient>
        <linearGradient id="paint6_linear_1206_48241" x1="4.65152" y1="16.5516" x2="14.624" y2="27.677" gradientUnits="userSpaceOnUse">
          <stop offset="0.277418" stopColor="#B69FF7" />
          <stop offset="0.814557" stopColor="#7B54E9" />
        </linearGradient>
        <linearGradient id="paint7_linear_1206_48241" x1="9.49763" y1="17.2252" x2="16.6376" y2="24.149" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5834BE" />
          <stop offset="0.770012" stopColor="#402297" />
        </linearGradient>
        <linearGradient id="paint8_linear_1206_48241" x1="0.589349" y1="21.2161" x2="18.1087" y2="33.2736" gradientUnits="userSpaceOnUse">
          <stop stopColor="#9879EE" />
          <stop offset="0.614583" stopColor="#6E4CCB" />
        </linearGradient>
        <linearGradient id="paint9_linear_1206_48241" x1="30.977" y1="6.63703" x2="40.9495" y2="17.7625" gradientUnits="userSpaceOnUse">
          <stop offset="0.277418" stopColor="#B69FF7" />
          <stop offset="0.814557" stopColor="#7B54E9" />
        </linearGradient>
        <linearGradient id="paint10_linear_1206_48241" x1="35.8233" y1="12.2935" x2="42.9633" y2="19.2174" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5834BE" />
          <stop offset="0.770012" stopColor="#402297" />
        </linearGradient>
        <linearGradient id="paint11_linear_1206_48241" x1="26.915" y1="16.2845" x2="44.4344" y2="28.3419" gradientUnits="userSpaceOnUse">
          <stop stopColor="#9879EE" />
          <stop offset="0.614583" stopColor="#6E4CCB" />
        </linearGradient>
        <linearGradient id="paint12_linear_1206_48241" x1="22.1695" y1="15.7303" x2="32.142" y2="26.8557" gradientUnits="userSpaceOnUse">
          <stop offset="0.277418" stopColor="#B69FF7" />
          <stop offset="0.814557" stopColor="#7B54E9" />
        </linearGradient>
        <linearGradient id="paint13_linear_1206_48241" x1="27.0158" y1="17.3824" x2="34.1558" y2="24.3063" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5834BE" />
          <stop offset="0.770012" stopColor="#402297" />
        </linearGradient>
        <linearGradient id="paint14_linear_1206_48241" x1="18.1074" y1="21.3733" x2="35.6268" y2="33.4308" gradientUnits="userSpaceOnUse">
          <stop stopColor="#9879EE" />
          <stop offset="0.614583" stopColor="#6E4CCB" />
        </linearGradient>
        <linearGradient id="paint15_linear_1206_48241" x1="13.4596" y1="23.6028" x2="23.4321" y2="34.7283" gradientUnits="userSpaceOnUse">
          <stop offset="0.277418" stopColor="#B69FF7" />
          <stop offset="0.814557" stopColor="#7B54E9" />
        </linearGradient>
        <linearGradient id="paint16_linear_1206_48241" x1="18.3059" y1="22.274" x2="25.4459" y2="29.1979" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5834BE" />
          <stop offset="0.770012" stopColor="#402297" />
        </linearGradient>
        <linearGradient id="paint17_linear_1206_48241" x1="9.39752" y1="26.2649" x2="26.9169" y2="38.3224" gradientUnits="userSpaceOnUse">
          <stop stopColor="#9879EE" />
          <stop offset="0.614583" stopColor="#6E4CCB" />
        </linearGradient>
        <linearGradient id="paint18_linear_1206_48241" x1="39.8832" y1="16.5667" x2="49.8556" y2="27.6921" gradientUnits="userSpaceOnUse">
          <stop offset="0.277418" stopColor="#B69FF7" />
          <stop offset="0.814557" stopColor="#7B54E9" />
        </linearGradient>
        <linearGradient id="paint19_linear_1206_48241" x1="44.7293" y1="17.2408" x2="51.8693" y2="24.1647" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5834BE" />
          <stop offset="0.770012" stopColor="#402297" />
        </linearGradient>
        <linearGradient id="paint20_linear_1206_48241" x1="35.821" y1="21.2317" x2="53.3403" y2="33.2892" gradientUnits="userSpaceOnUse">
          <stop stopColor="#9879EE" />
          <stop offset="0.614583" stopColor="#6E4CCB" />
        </linearGradient>
        <linearGradient id="paint21_linear_1206_48241" x1="30.9777" y1="25.7127" x2="40.9501" y2="36.8381" gradientUnits="userSpaceOnUse">
          <stop offset="0.277418" stopColor="#B69FF7" />
          <stop offset="0.814557" stopColor="#7B54E9" />
        </linearGradient>
        <linearGradient id="paint22_linear_1206_48241" x1="35.8239" y1="22.4273" x2="42.9639" y2="29.3512" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5834BE" />
          <stop offset="0.770012" stopColor="#402297" />
        </linearGradient>
        <linearGradient id="paint23_linear_1206_48241" x1="26.9155" y1="26.4183" x2="44.4349" y2="38.4757" gradientUnits="userSpaceOnUse">
          <stop stopColor="#9879EE" />
          <stop offset="0.614583" stopColor="#6E4CCB" />
        </linearGradient>
        <linearGradient id="paint24_linear_1206_48241" x1="22.2675" y1="30.7477" x2="32.2399" y2="41.8731" gradientUnits="userSpaceOnUse">
          <stop offset="0.277418" stopColor="#B69FF7" />
          <stop offset="0.814557" stopColor="#7B54E9" />
        </linearGradient>
        <linearGradient id="paint25_linear_1206_48241" x1="27.1137" y1="27.418" x2="34.2537" y2="34.3419" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5834BE" />
          <stop offset="0.770012" stopColor="#402297" />
        </linearGradient>
        <linearGradient id="paint26_linear_1206_48241" x1="18.2054" y1="31.409" x2="35.7247" y2="43.4664" gradientUnits="userSpaceOnUse">
          <stop stopColor="#9879EE" />
          <stop offset="0.614583" stopColor="#6E4CCB" />
        </linearGradient>
      </defs>
    </svg>
  ),
  custom: (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M32 4.00098L11 8.00097L12 26.001L32 4.00098Z" fill="url(#paint0_linear_4564_62949)" />
      <path d="M5 27.001L11 8.00098L12 26.001L5 27.001Z" fill="url(#paint1_linear_4564_62949)" />
      <path d="M5 27.001L20 44.001L12 26.001L5 27.001Z" fill="url(#paint2_linear_4564_62949)" />
      <path d="M32 4.00098L37 34.001L12 26.001L32 4.00098Z" fill="url(#paint3_linear_4564_62949)" />
      <path d="M32 4.00098L37 34.001C40.5147 28.1431 42.4853 24.8588 46 19.001L32 4.00098Z" fill="url(#paint4_linear_4564_62949)" />
      <path d="M40 38.001L37 34.001L46 19.001L40 38.001Z" fill="url(#paint5_linear_4564_62949)" />
      <path d="M40 38.001L37 34.001L20 44.001L40 38.001Z" fill="url(#paint6_linear_4564_62949)" />
      <path d="M20 44.001L37 34.001L12 26.001L20 44.001Z" fill="url(#paint7_linear_4564_62949)" />
      <defs>
        <linearGradient id="paint0_linear_4564_62949" x1="32.1745" y1="-4.12693" x2="46.9696" y2="29.6512" gradientUnits="userSpaceOnUse">
          <stop stopColor="#AB93F0" />
          <stop offset="0.814411" stopColor="#8D71DB" />
        </linearGradient>
        <linearGradient id="paint1_linear_4564_62949" x1="11.6743" y1="13.0359" x2="20.3586" y2="18.4542" gradientUnits="userSpaceOnUse">
          <stop stopColor="#9879EE" />
          <stop offset="0.814411" stopColor="#6E4CCB" />
        </linearGradient>
        <linearGradient id="paint2_linear_4564_62949" x1="15.1706" y1="58.7046" x2="-5.85312" y2="38.1597" gradientUnits="userSpaceOnUse">
          <stop stopColor="#6742D0" />
          <stop offset="0.85955" stopColor="#5333AF" />
        </linearGradient>
        <linearGradient id="paint3_linear_4564_62949" x1="41.2327" y1="56.8964" x2="12.9062" y2="39.8938" gradientUnits="userSpaceOnUse">
          <stop stopColor="#9879EE" />
          <stop offset="0.85955" stopColor="#6947C9" />
        </linearGradient>
        <linearGradient id="paint4_linear_4564_62949" x1="41.2326" y1="56.8964" x2="12.9061" y2="39.8938" gradientUnits="userSpaceOnUse">
          <stop stopColor="#6742D0" />
          <stop offset="0.85955" stopColor="#5333AF" />
        </linearGradient>
        <linearGradient id="paint5_linear_4564_62949" x1="40.2922" y1="16.2526" x2="52.7815" y2="25.4504" gradientUnits="userSpaceOnUse">
          <stop stopColor="#432598" />
          <stop offset="0.770012" stopColor="#44239E" />
        </linearGradient>
        <linearGradient id="paint6_linear_4564_62949" x1="26.9609" y1="32.9988" x2="30.4692" y2="45.0563" gradientUnits="userSpaceOnUse">
          <stop stopColor="#2C156D" />
          <stop offset="0.770012" stopColor="#351A82" />
        </linearGradient>
        <linearGradient id="paint7_linear_4564_62949" x1="20.2953" y1="23.076" x2="29.3128" y2="44.0473" gradientUnits="userSpaceOnUse">
          <stop stopColor="#432598" />
          <stop offset="0.770012" stopColor="#44239E" />
        </linearGradient>
      </defs>
    </svg>

  ),
};

export default pricingIcons;
