import { Outlet, useParams } from 'react-router-dom';
import { createContext, useContext, useEffect, useMemo, useReducer } from 'react';

import { getApiUrl } from 'utils/urls';
import axios from 'core/axios';
import { listingsReducer } from './reducers';

const MarketsListingContext = createContext({
  marketPath: '',
  listingPath: '',
  objectPath: '',
  agreements: undefined || [],
  orders: undefined || [],
  registeredObject: {},
  listing: {},
});
const MarketsListingDispatchContext = createContext(null);

export const useMarketsListing = () => useContext(MarketsListingContext);
export const useMarketsListingDispatch = () => useContext(MarketsListingDispatchContext);

export const MarketsListingContextProvider = () => {
  const { marketType, tokenId } = useParams();

  const [state, dispatch] = useReducer(listingsReducer, { registeredObject: {}, listing: {} });
  const { listing } = state;

  const marketPath = `/markets/${marketType}/`;
  const listingPath = `${marketPath}${tokenId}/`;
  const objectPath = listing.objectType && listing.objectId && (
    `/registered-objects/artworks/${listing.objectType}/${listing.objectId}/`
  );

  useEffect(() => {
    axios.get(getApiUrl(listingPath))
      .then(({ data }) => dispatch({ type: 'fetchDetail', data }))
      .catch(console.error);
  }, [listingPath]);


  useEffect(() => {
    if (objectPath) {
      axios
        .get(getApiUrl(objectPath))
        .then(({ data }) => dispatch({ type: 'fetchObjectDetail', data }))
        .catch(console.error);
    }
  }, [objectPath]);

  const contextState = useMemo(() => ({
    ...state,
    marketPath,
    listingPath,
    objectPath,
  }), [state, marketPath, listingPath, objectPath]);

  return (
    <MarketsListingContext.Provider value={contextState}>
      <MarketsListingDispatchContext.Provider value={dispatch}>
        <Outlet />
      </MarketsListingDispatchContext.Provider>
    </MarketsListingContext.Provider>
  );
};
