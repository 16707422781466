import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { EraseAllDataPageNative } from '@trustwise/design-system';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import { isNativeAppWebview, isNewNativeAppWebview } from 'utils/general';
import { getGlobalContext } from 'core/globals';
import { openNativeView } from 'core/utils';

const EraseAllDataView = () => {
  const isWebView = isNativeAppWebview || isNewNativeAppWebview;
  const navigate = useNavigate();
  const {
    actingPerson: { selfManagedType },
    activeEntity: { isCompany },
    nativeUrlScheme,
  } = getGlobalContext();

  useEffect(() => {
    if (!isWebView || isCompany) {
      navigate('/');
    }
  }, [isWebView, isCompany, navigate]);

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Erase All Data')}
      headerLeft={<BackButton onClick={() => { navigate('/'); }} />}
    >
      <EraseAllDataPageNative
        onDataErase={() => openNativeView('erase_device')}
        recoveryPhraseUrl={selfManagedType !== 'recovery' ? `${nativeUrlScheme}recovery-phrase-profile` : undefined}
      />
    </PageContent>
  );
};

export default EraseAllDataView;
