import { convertKeysToCamelCase } from 'core/utils';
import { RegisteredObjectDetailModel } from 'registeredObjects/models/registeredObject';
import { AssetTransactionModel } from '../models/assetTransaction';

const reducer = (currentState, action) => {
  const { data, type } = action;

  switch (type) {
    case 'fetchObject': {
      return {
        ...currentState,
        registeredObject: new RegisteredObjectDetailModel(convertKeysToCamelCase(data)),
      };
    }

    case 'fetchAssetDetail': {
      const { asset } = currentState;

      const convertedData = convertKeysToCamelCase(data);
      Object.entries(convertedData).forEach(([key, value]) => {
        asset[key] = value;
      });

      return {
        ...currentState,
        asset,
      };
    }

    case 'fetchTransactions': {
      const { asset } = currentState;
      asset.transactions = AssetTransactionModel.fromResponse(data);

      return {
        ...currentState,
        asset,
      };
    }

    default:
      return currentState;
  }
};

export default reducer;
