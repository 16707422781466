import PropTypes from 'prop-types';

/**
 * Empty content
 *
 *
 */
const EmptyContent = ({ iconName, text }) => (
  <div className="empty-content">
    {iconName}
    <p className="text-center">{text}</p>
  </div>
);

EmptyContent.defaultProps = {
  text: '',
  iconName: <div />,
};

EmptyContent.propTypes = {
  iconName: PropTypes.element,
  text: PropTypes.string,
};


export default EmptyContent;
