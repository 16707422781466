import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, Link } from 'react-router-dom';
import axios from 'core/axios';
import { formatDateFromTimestamp } from 'utils/date';
import ContentLoadingMessage from 'utils/contentLoadingMessage';
import { PageContent } from 'core/page';


const SubscriptionsList = ({ fetchDataUrl, leftUrl, actionsList, onlyActive }) => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    const timestampNow = new Date() / 1000;
    axios.get(fetchDataUrl).then(
      ({ data }) => {
        const finalList = onlyActive ? data.filter(
          (subscription) => Object.entries(subscription).length > 0
              && subscription.start_timestamp < timestampNow
              && timestampNow < subscription.end_timestamp
              && (!subscription.max_number_of_shares || subscription.total_subscribed < subscription.max_number_of_shares),
        ) : data;
        setSubscriptions(finalList);
      },
    )
      .catch(console.error)
      .finally(() => { setIsLoading(false); });
  }, [fetchDataUrl, onlyActive]);

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Subscriptions')}
      headerLeft={leftUrl}
      actions={actionsList(pathname)}
    >
      {Object.entries(subscriptions).length > 0 ? (
        <ol>
          {subscriptions.map((subscription) => (
            <li key={subscription.id}>
              <Link to={`${pathname}${subscription.id}/`} className="row-flex card-base top-margin">
                <span>
                  {subscription.name}
                  <br />
                  <span className="text-tiny">
                    {gettext(`Start date & time ${formatDateFromTimestamp(subscription.start_timestamp)}`)}
                  </span>
                  <br />
                  <span className="text-tiny">
                    {gettext(`End date & time ${formatDateFromTimestamp(subscription.end_timestamp)}`)}
                  </span>
                </span>
                {subscription.max_number_of_shares && `${subscription.total_subscribed}/${subscription.max_number_of_shares}`}
              </Link>
            </li>
          ))}
        </ol>
      ) : (
        <ContentLoadingMessage
          isLoading={isLoading}
          loadedMessage={gettext('No subscriptions available')}
        />
      )}
    </PageContent>
  );
};

SubscriptionsList.defaultProps = {
  actionsList: () => {},
  onlyActive: false,
};

SubscriptionsList.propTypes = {
  fetchDataUrl: PropTypes.string.isRequired,
  leftUrl: PropTypes.element.isRequired,
  onlyActive: PropTypes.bool,
  actionsList: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.arrayOf(PropTypes.object),
  ]),
};

export default SubscriptionsList;
