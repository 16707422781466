import { useState, useEffect, useCallback, useReducer } from 'react';
import { Link } from 'react-router-dom';
import { ModalWithContent as Modal } from '@trustwise/design-system';
import axios from 'core/axios';
import { PageContent } from 'core/page';
import ScrollableContent from 'core/page/scrollableContent';
import SearchForm from 'core/forms/searchForm';
import { getUrl, getApiUrl } from 'utils/urls';
import { Button, BaseButton } from 'core/forms/fields';
import Tooltip from 'core/tooltip';
import PaginationController from 'core/pagination/paginationController';
import Table from 'core/tables/table';
import BackButton from 'core/page/parts/backButton';
import accountsReducer from './reducer';
import { icons } from '../core/icons';
import { useScreenSize } from '../core/hooks';


const CustodialAccountList = () => {
  const { isXSScreen } = useScreenSize();
  const [isLoading, setIsLoading] = useState(true);
  const [commentModalId, setCommentModalId] = useState(null);
  const [accountsState, accountsDispatch] = useReducer(accountsReducer, {
    pageSize: 10,
    custodialAccounts: [],
    totalAccountsCount: 0,
    pageCount: 1,
    currentPageNumber: 1,
    itemsRange: { rangeStart: 1, rangeEnd: 1 },
    nextPage: null,

  });

  const accountsListUrl = getApiUrl('/custodian/shareholders/', { page_size: accountsState.pageSize });

  const setPageSize = (numberOfItems) => {
    accountsDispatch({ data: numberOfItems, type: 'changePageSize' });
  };

  const fetchFirstPageItems = useCallback((url) => {
    axios.get(url)
      .then(({ data }) => { accountsDispatch({ data, type: 'fetchFirstPage' }); })
      .catch(console.error)
      .finally(() => { setIsLoading(false); });
  }, []);

  useEffect(() => {
    fetchFirstPageItems(accountsListUrl);
  }, [accountsListUrl, fetchFirstPageItems]);

  const fetchPageItems = (pageNumber) => {
    setIsLoading(true);
    const fetchUrl = `${accountsListUrl}&page=${pageNumber}`;
    axios.get(fetchUrl)
      .then(({ data }) => { accountsDispatch({ data: { response: data.results, pageNumber }, type: 'fetchPage' }); })
      .catch(console.error)
      .finally(() => { setIsLoading(false); });
  };

  const onSearchSubmit = ({ searchString }) => {
    fetchFirstPageItems(`${accountsListUrl}&search=${searchString}`);
  };

  const fetchMore = () => {
    axios.get(accountsState.nextPage)
      .then(({ data }) => { accountsDispatch({ data, type: 'fetchNext' }); })
      .catch(console.error);
  };

  const tableHeaders = [
    { key: 'name', label: gettext('Shareholder Name') },
    { key: 'city', label: gettext('City') },
    { key: 'country', label: gettext('Country') },
    { key: 'tags', label: '' },
    { key: 'action', label: '' },
  ];

  const tableContent = accountsState.custodialAccounts.map((account) => ({
    key: account.id,
    data: [
      { key: `name-${account.id}`, content: account.name },
      { key: `city-${account.id}`, content: `${account.city} ${account.postalCode}` },
      { key: `country-${account.id}`, content: account.countryLabel },
      { key: `tags-${account.id}`,
        content: account.custodianComment ? (
          <>
            <Modal
              id={`custodian-comment-${account.id}`}
              title={gettext('Comment')}
              isOpen={commentModalId === account.id}
              onClose={() => { setCommentModalId(null); }}
            >
              <div>{account.custodianComment}</div>
            </Modal>
            <BaseButton className="tag" onClick={() => { setCommentModalId(account.id); }}>
              {icons.comment}
              <span className="label">{gettext('Comment')}</span>
            </BaseButton>
          </>
        ) : '' },
      {
        key: `action-${account.id}`,
        className: 'table-body-actions-cell',
        content: (
          <>
            <Button
              className="button-outlined button-small"
              onClick={() => { window.location = getUrl(`/custodian/switch/${account.id}/`); }}
            >
              {gettext('Switch to account')}
            </Button>
            <Tooltip actions={[
              {
                key: `edit-${account.id}`,
                content: (
                  <Link className="tooltip-link" to={getUrl(`/custodian/shareholders/${account.id}/edit/`)}>
                    <i className="fa right-padding fa-pencil-alt" />
                    {gettext('Edit')}
                  </Link>
                ),
              },
            ]}
            />
          </>
        ),
      },
    ],
  }));

  const content = (
    <div className="main-content-area">
      <div className="main-content-area-header">
        <div className="main-content-area-title">{gettext('Accounts')}</div>
        <SearchForm onSubmit={onSearchSubmit} />
      </div>
      <Table headers={tableHeaders} rows={tableContent} isLoading={isLoading} />
      {accountsState.totalAccountsCount ? (
        <PaginationController
          currentPageNumber={accountsState.currentPageNumber}
          pageCount={accountsState.pageCount}
          fetchItems={fetchPageItems}
          itemsRange={accountsState.itemsRange}
          totalItems={accountsState.totalAccountsCount}
          setPageSize={setPageSize}
          defaultPageSize={accountsState.pageSize}
        />
      ) : null}
    </div>
  );

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Custodian Services')}
      headerLeft={<BackButton href={getUrl()} />}
      actions={[{
        url: getUrl('/custodian/shareholders/add/'),
        icon: '',
        label: gettext('Add shareholder account'),
        isPrimary: true,
      }]}
    >
      {isXSScreen ? (
        <ScrollableContent
          dataLength={accountsState.custodialAccounts.length}
          fetchNext={fetchMore}
          hasMore={isXSScreen && !!accountsState.nextPage}
        >
          {content}
        </ScrollableContent>
      ) : content}

    </PageContent>
  );
};

export default CustodialAccountList;
