
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { ModalWithContent as Modal } from '@trustwise/design-system';
import DOMPurify from 'dompurify';
import axios from 'core/axios';

const DocumentationModal = ({ isOpen, modalId, onClose, documentationPath }) => {
  const APIBaseURL = 'api/v1/documentation';
  const [content, setContent] = useState('');
  const currentUrl = `/${APIBaseURL}/${documentationPath}`;

  useEffect(() => {
    axios.get(currentUrl)
      .then(({ data }) => { setContent(data); })
      .catch(console.error);
  }, [currentUrl]);

  return (
    <Modal id={modalId} onClose={onClose} isOpen={isOpen}>
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />
    </Modal>
  );
};


DocumentationModal.propTypes = {
  modalId: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  documentationPath: PropTypes.string.isRequired,
};

export default DocumentationModal;
