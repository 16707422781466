
import { PendingTransactionModal } from '@trustwise/design-system';
import {
  onCancel,
  onSeeDetails,
} from 'core/page/transactionSigning/actions';
import { useUnsignedTransactionContext } from 'core/page/transactionSigning/context';

const PendingUnsignedTransactionModal = () => {
  const {
    baseTrxUrl, transactionDetails, dispatch, modalState: { isPendingOpen },
  } = useUnsignedTransactionContext();

  return (
    <PendingTransactionModal
      {...transactionDetails}
      isOpen={isPendingOpen}
      onSeeDetails={(_ev, setDisabled) => onSeeDetails(baseTrxUrl, dispatch, setDisabled)}
      onCancel={(_ev, setDisabled) => onCancel(baseTrxUrl, dispatch, setDisabled)}
    />
  );
};

export default PendingUnsignedTransactionModal;
