/* Page header component */
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'mobx-react';
import AccountSwitcher from 'components/accountSwitcher';
import { stores } from 'stores';
import { getGlobalContext } from 'core/globals';
import ManagedAccountBanner from './parts/managedAccountBanner';
import BackButton from './parts/backButton';


const PageHeaderComponent = ({ headerLeft }) => {
  const {
    activeEntity: { id: activeEntityId },
    custodian: { active: custodianActive },
  } = getGlobalContext();

  useEffect(() => {
    if (stores.accountSwitcherStore.currentAccountId !== activeEntityId) {
      stores.accountSwitcherStore.setCurrentAccount(activeEntityId);
    }
  }, [activeEntityId]);

  return (
    <>
      {custodianActive && <ManagedAccountBanner />}
      <div className="app-header">
        {headerLeft || <BackButton onClick={() => { window.history.go(-1); }} />}
        {custodianActive || (
          <div id="account-switcher-component">
            <Provider {...stores}>
              <AccountSwitcher />
            </Provider>
          </div>
        )}
      </div>
    </>
  );
};

PageHeaderComponent.defaultProps = {
  headerLeft: null,
};

PageHeaderComponent.propTypes = {
  headerLeft: PropTypes.element,
};

export default PageHeaderComponent;
