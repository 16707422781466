import { DateTime } from 'luxon';

export const roundDateTime = (
  date?: Date,
  roundMinutesTo?: number,
  roundingMethod?: (value: number) => number,
): Date => {
  const _date = (date || new Date()).getTime();
  const _roundMinutesTo = roundMinutesTo || 60;
  const _roundingMethod = roundingMethod || Math.floor;
  const coeff = 1000 * 60 * _roundMinutesTo;
  return new Date(_roundingMethod(_date / coeff) * coeff);
};

export const dateToday = (): string => DateTime.now().toISODate();

export const getDateRange = (startDate: Date, endDate: Date): Date[] => {
  const dates: Date[] = [];
  const start = DateTime.fromJSDate(startDate);
  const end = DateTime.fromJSDate(endDate);
  let currentDate = start;
  while (currentDate <= end) {
    dates.push(currentDate.toJSDate());
    currentDate = currentDate.plus({ days: 1 });
  }
  return dates;
};

export const formatDate = (date:Date): string => date.toLocaleString(
  'de-CH',
  { day: '2-digit', month: '2-digit', year: 'numeric' },
);
export const formatTime = (date:Date): string => date.toLocaleString(undefined, { hour: '2-digit', minute: '2-digit' });
export const formatDateTime = (date:Date): string => DateTime.fromJSDate(date).toFormat('dd.LL.yyyy HH:mm');
export const formatDateFromTimestamp = (timestamp: number): string => formatDateTime(new Date(timestamp * 1000));

export const isToday = (date: Date): boolean => date.toDateString() === new Date().toDateString();

export const isTodayTimeInFuture = (time?: string): boolean => {
  if (!time) {
    return false;
  }
  const [hours, minutes] = time.split(':');
  const now = new Date();
  const dateWithGivenTime = new Date();
  dateWithGivenTime.setHours(Number(hours), Number(minutes), 0, 0);
  return dateWithGivenTime > now;
};

export const formatDateWithWeekday = (date: Date): string => {
  if (isToday(date)) {
    return `${gettext('Today')} ${DateTime.fromJSDate(date).toFormat('T')}`;
  }
  return DateTime.fromJSDate(date).toFormat('ccc dd.L');
};

// @todo replace with roundDateTime()
export const roundedTime = (): string => `${new Date().getHours() + 1}:00`;

export const dateToTimestamp = (
  date: string,
  time: string,
  tzName: string,
): number => DateTime.fromISO(`${date}T${time}`, { zone: tzName }).toUnixInteger();

export const getTimeFromDate = (date: Date, tzName: string): string => (
  DateTime.fromJSDate(date).toLocaleString({ ...DateTime.TIME_24_WITH_SECONDS, timeZone: tzName })
);

export const datetimeToISO = (
  date: string,
  time: string,
  tzName: string,
): string => DateTime.fromISO(`${date}T${time}`, { zone: tzName }).toString();
