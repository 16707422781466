import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Formik, Form, ErrorMessage } from 'formik';
import { ModalWithContent as Modal } from '@trustwise/design-system';
import FormErrors from 'components/common/formikErrors';
import { SubmitButton } from 'core/forms/fields';
import axios from 'core/axios';
import { catchHandler } from 'core/utils';

const DeleteMember = ({ isOpen, onClose, companyId, member, updateMembers }) => {
  const { listId } = useParams();
  const { id: memberId, name: memberName } = member || {};
  const apiUrl = `/api/v1/company/${companyId}/ownership/potential-shareholders/${listId}/members/${memberId}/`;

  const onSubmit = (values, actions) => {
    const data = { name: values.name };
    if (!listId) {
      data.legal_entity = companyId;
    }
    axios.delete(apiUrl, data)
      .then(() => { updateMembers(); })
      .catch((error) => catchHandler(error, actions));
  };

  return (
    <Modal
      id={`remove-member-${memberId}`}
      isOpen={isOpen}
      onClose={onClose}
      title={gettext('Remove member')}
    >
      <Formik
        initialValues={{ nonFieldErrors: '' }}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <ErrorMessage component={FormErrors} name="nonFieldErrors" />
            {gettext(`Are you sure you want to remove ${memberName}?`)}
            <SubmitButton disabled={isSubmitting}>{gettext('Remove')}</SubmitButton>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

DeleteMember.defaultProps = {
  isOpen: false,
  member: {},
};

DeleteMember.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired,
  member: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  updateMembers: PropTypes.func.isRequired,
};

export default DeleteMember;
