import { getGlobalContext } from 'core/globals';
import { MenuSubItemProps, MenuItemProps } from 'core/page/menu/types';
import { custodialMenuItems, delegateMenuItems } from './items/managed';
import { companyMenuItems } from './items/company';
import { personalMenuItems } from './items/personal';
import { defaultCompanyMenuFavorites, defaultPersonalMenuFavorites, menuItemsFeatures } from './constants';
import { Feature } from '../features';

const {
  custodian: { active: custodianActive },
  delegate: { active: delegateActive },
  activeEntity: { isCompany, allowedFeatures, menuFavorites },
} = getGlobalContext();

const isManagedAccount = custodianActive || delegateActive;

let allMenuItems: MenuItemProps[];
let defaultFavorites = defaultPersonalMenuFavorites;
if (custodianActive) {
  allMenuItems = custodialMenuItems;
} else if (delegateActive) {
  allMenuItems = delegateMenuItems;
} else {
  allMenuItems = isCompany ? companyMenuItems : personalMenuItems;
  defaultFavorites = isCompany ? defaultCompanyMenuFavorites : defaultPersonalMenuFavorites;
}

let favorites = menuFavorites;
if (!menuFavorites?.length || isManagedAccount) {
  favorites = defaultFavorites;
}

const _hasSubitems = (obj: MenuItemProps | MenuSubItemProps): obj is MenuItemProps => 'subitems' in obj;
const _isItemVisible = (item: MenuItemProps) => {
  const allowedFeaturesSet = new Set(allowedFeatures);

  return (
    item.isDefault
    || allowedFeaturesSet.has(item.id)
    || menuItemsFeatures[item.id]?.some((feature: Feature) => allowedFeaturesSet.has(feature))
  );
};

export const filterMenuItems = (items: MenuItemProps[] | MenuSubItemProps[] = allMenuItems): MenuItemProps[] => {
  if (isManagedAccount) {
    return allMenuItems;
  }

  const hasActiveSubitem = (item: MenuItemProps) => !!item.subitems?.some(
    (subitem) => !subitem.isHidden && _isItemVisible(subitem),
  );
  const result: MenuItemProps[] = [];
  items.forEach((item) => {
    if (!item.isHidden && (_isItemVisible(item) || hasActiveSubitem(item))) {
      result.push({
        ...item,
        subitems: _hasSubitems(item) ? filterMenuItems(item.subitems) : undefined,
      });
    }
  });
  return result;
};

export const findFavouriteItemByIds = (items: MenuItemProps[] | MenuSubItemProps[] = allMenuItems): MenuItemProps[] => {
  const result: MenuItemProps[] = [];
  items.forEach((item) => {
    if (favorites.includes(item.id)) {
      result.push(item);
    }
    if (_hasSubitems(item)) {
      result.push(...findFavouriteItemByIds(item.subitems));
    }
  });
  return result.sort((a, b) => favorites.indexOf(a.id) - favorites.indexOf(b.id));
};

export const getMenuFavoriteItems = (): MenuItemProps[] => findFavouriteItemByIds();
