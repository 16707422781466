import { useEffect, useState } from 'react';

import { RegistriesListView as RegistriesListViewDS } from '@trustwise/design-system';
import { getApiUrl } from 'utils/urls';
import { PageContent } from 'core/page';
import { RegistriesListItemModel } from 'registries/models/registry';
import axios from 'core/axios';
import convertResponseToModel from 'utils/responseToModel';
import { getRouterLinkInteraction } from 'core/utils';

const RegistriesListView = () => {
  const [registries, setRegistries] = useState();

  useEffect(() => {
    axios
      .get(getApiUrl('/registries/'))
      .then(({ data }) => setRegistries(convertResponseToModel(data, RegistriesListItemModel)))
      .catch(console.error);
  }, []);

  return (
    <PageContent fullscreen pageHeading={gettext('Registry Management')}>
      <RegistriesListViewDS items={registries} onItemClick={(id) => getRouterLinkInteraction(`${id}/`)} />
    </PageContent>
  );
};

export default RegistriesListView;
