import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { groupBy, orderBy } from 'lodash';

import { DESC_SORTING_ORDER } from 'core/const';
import { formatDate } from 'utils/date';
import { getApiUrl } from 'utils/urls';
import AccountAvatarRowPart from 'contacts/parts/accountAvatar';
import axios from 'core/axios';
import ContentLoadingMessage from 'utils/contentLoadingMessage';
import ScrollableContent from 'core/page/scrollableContent';
import SearchForm from 'core/forms/searchForm';
import { actionRequired, completed, inProgress, tabOptions } from './const';
import InboxItemActions from './parts/InboxItemActions';

/**
  * List of legal entity inbox items
  * Needs to be unified with widgets list.
  *
*/
const InboxItemsListOld = ({ state, dispatch, activeTab, ..._props }) => {
  const [initialSearch, setInitialSearch] = useState('');
  const { nextPage, inboxItems } = state;
  const inboxByDate = groupBy(orderBy(inboxItems, 'date', DESC_SORTING_ORDER), (item) => formatDate(item.date));

  const fetchNext = () => {
    axios.get(nextPage)
      .then(({ data }) => { dispatch({ data, type: 'fetchNext' }); })
      .catch(console.error);
  };

  const onSubmit = ({ searchString }) => {
    setInitialSearch(searchString);
    dispatch({ type: 'resetInboxItems' });
    const urlSearchParams = { status: activeTab };
    if (searchString) {
      urlSearchParams.search = searchString;
    }
    axios.get(getApiUrl('/inbox/', urlSearchParams))
      .then(({ data }) => { dispatch({ data, type: 'fetch' }); })
      .catch(console.error);
  };

  useEffect(() => { setInitialSearch(''); }, [activeTab]);

  return (
    <div className="large-padding">
      <SearchForm onSubmit={onSubmit} initialValue={initialSearch} />
      {Object.keys(inboxByDate).length > 0 ? (
        <ScrollableContent
          dataLength={inboxItems.length}
          fetchNext={fetchNext}
          hasMore={!!nextPage}
          scrollableTarget="grid-container"
        >
          <div>
            {Object.keys(inboxByDate).map((inboxDate) => (
              <section key={inboxDate} className="padded-top">
                <header className="row-flex not-padded">
                  <h3 className="title text-small">{inboxDate}</h3>
                </header>
                <ul>
                  {inboxByDate[inboxDate].map((inboxItem) => (
                    <li key={inboxItem.itemId} className={`card-base transition grid-x ${inboxItem.notSeen && 'font-medium'}`}>
                      <div className="cell small-2">
                        <AccountAvatarRowPart legalEntity={inboxItem.avatar} />
                      </div>
                      <div className="cell small-9">
                        <span className="title">{inboxItem.title}</span>
                        <div className="text-tiny">
                          {inboxItem.content}
                        </div>
                        {inboxItem.actionContext && <InboxItemActions inboxItem={inboxItem} dispatch={dispatch} />}
                      </div>
                    </li>
                  ))}
                </ul>
              </section>
            ))}
          </div>
        </ScrollableContent>
      ) : (
        <ContentLoadingMessage
          isLoading={!inboxItems}
          loadedMessage={tabOptions[activeTab].emptyContent}
        />
      )}
    </div>
  );
};

InboxItemsListOld.defaultProps = {
  activeTab: actionRequired,
};

InboxItemsListOld.propTypes = {
  dispatch: PropTypes.func.isRequired,
  activeTab: PropTypes.oneOf([actionRequired, completed, inProgress]),
  state: PropTypes.shape().isRequired,
};

export default InboxItemsListOld;
