import { Component } from 'react';
import { extendObservable } from 'mobx';
import { observer, inject, PropTypes as MobXPropTypes } from 'mobx-react';

import PropTypes from 'prop-types';

import UploadTemplate from './uploadTemplate';
import Loader from '../common/loader';

/**
 * @deprecated DO NOT USE
 */
class DocumentUploadComponent extends Component {
  constructor(props) {
    super(props);
    extendObservable(this, {
      model: null,
    });
    this.state = {
      isMounted: false,
    };
  }

  componentDidMount() {
    const { documentStore, defaultDocumentType, document, companyId } = this.props;

    this.model = document;

    if (!this.model) {
      this.model = documentStore.addDocument({ defaultDocumentType, companyId });
    } else {
      this.model.companyId = companyId;
    }

    this.setState({ isMounted: true });
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  _handleUpload = (e) => {
    const { handleUpload } = this.props;

    if (handleUpload) {
      handleUpload(e, this.model);
    }
    this.model.finalize();
  };

  _handleSave = (e) => {
    const { handleSave } = this.props;

    if (handleSave) {
      handleSave(e, this.model);
    }
    this.model.upload();
  };

  render() {
    const {
      showSaveDocumentButton, documentTypesContext, saveDocumentLabel, hideProperties,
    } = this.props;
    const { isMounted } = this.state;

    if (!isMounted) {
      return <Loader />;
    }


    return (
      <div>
        <Loader isLoading={this.model.isLoading}>
          <UploadTemplate
            documentTypesContext={documentTypesContext}
            model={this.model}
            hideProperties={hideProperties}
          />
          {showSaveDocumentButton
            ? (
              <button
                className="button"
                type="submit"
                onClick={() => this._handleSave()}
                data-alt-text={gettext('Save')}
              >
                {saveDocumentLabel}
              </button>
            ) : null}
        </Loader>
      </div>
    );
  }
}

DocumentUploadComponent.defaultProps = {
  defaultDocumentType: 'FU',
  companyId: null,
  showSaveDocumentButton: true,
  saveDocumentLabel: gettext('Save'),
  handleSave: null,
  handleUpload: null,
  document: null,
  hideProperties: false,
};

DocumentUploadComponent.propTypes = {
  showSaveDocumentButton: PropTypes.bool,
  hideProperties: PropTypes.bool,
  defaultDocumentType: PropTypes.string,
  companyId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  saveDocumentLabel: PropTypes.string,
  documentTypesContext: PropTypes.string.isRequired,
  handleSave: PropTypes.func,
  handleUpload: PropTypes.func,
  document: PropTypes.shape({
    defaultDocumentType: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    documentObject: PropTypes.object,
  }),
  documentStore: MobXPropTypes.objectOrObservableObject.isRequired,
};

export default inject('documentStore')(observer(DocumentUploadComponent));
