// Document related actions
import { getApiUrl, getUrl } from 'utils/urls';
import axios from 'core/axios';
import { txHashResponseHandler } from 'core/utils';


export const getActions = (
  documentId,
  doc,
  url,
  queryParams,
  indexPath,
  isEditable,
  registrationEnabled,
  signatoriesEnabled,
  onDocumentDelete,
) => {
  const actions = [];
  if (isEditable && doc.isEditable) {
    actions.push(
      {
        url: `${url}edit/${queryParams}`,
        icon: 'pencil-alt',
        label: gettext('Edit'),
      },
      {
        type: 'button',
        onClick: () => { onDocumentDelete(true); },
        icon: 'trash',
        label: gettext('Delete'),
      },
    );
  }
  if (signatoriesEnabled) {
    if (!doc.signerContract || doc.signerContract.isEditable) {
      actions.push({
        url: `${url}signatories/add/${queryParams}`,
        icon: 'plus',
        label: gettext('Add Signatory'),
      });
    }
    if (doc.signerContract && doc.signerContract.isEditable && doc.signerContract.hasSignatories) {
      actions.push({
        type: 'form',
        icon: 'paper-plane',
        label: gettext('Invite Signatories'),
        disabled: doc.signerContract.signatories.length === 0,
        onClick: () => {
          axios.post(getApiUrl(`/new-documents/${documentId}/signatories/invite/`))
            .then(({ data }) => { txHashResponseHandler(data, indexPath); }, console.error);
        },
      });
    }
    const activeSignatory = doc.signerContract && doc.signerContract.signatories.find(
      (signatory) => signatory.id === doc.signerContract.activeSignatoryId,
    );
    if (activeSignatory && doc.signerContract.isValid) {
      if (!activeSignatory.hasSigned) {
        actions.push({
          type: 'form',
          icon: 'pen-alt',
          label: gettext('Sign Document'),
          onClick: () => {
            axios.post(getApiUrl(`/new-documents/${documentId}/signatories/${activeSignatory.id}/sign/`))
              .then(({ data }) => { txHashResponseHandler(data, indexPath); }, console.error);
          },
        });
      }
    }
  }

  if (registrationEnabled && !doc.blockchainInfo) {
    actions.push({
      type: 'form',
      icon: 'history',
      label: gettext('Register on blockchain'),
      onClick: () => {
        axios.post(getApiUrl(`/new-documents/${documentId}/register/`))
          .then(({ data }) => { txHashResponseHandler(data, indexPath); }, console.error);
      },
    });
  }

  if (!queryParams.includes('related_object_id')) {
    const leftUrl = `origin=${url}${queryParams}`;
    switch (doc.relatedObjectType) {
      case 'gov_event':
        actions.push({
          url: getUrl(`/meetings/${doc.relatedObjectId}/`),
          icon: 'calendar-alt',
          label: gettext('View event'),
          useRouterLink: false,
        });
        break;
      case 'business_transaction':
        actions.push({
          url: getUrl(`/transfers/${doc.relatedObjectId}/`, leftUrl),
          icon: 'exchange-alt',
          label: gettext('View transaction'),
          useRouterLink: false,
        });
        break;
      case 'time_series_entry':
        // TODO needs action link
        break;
      default:
        actions.push({
          url: getUrl(`/documents-signing/${documentId}/`, leftUrl),
          icon: 'file-contract',
          label: gettext('View contract'),
          useRouterLink: false,
        });
    }
  }
  return actions;
};
