import PropTypes from 'prop-types';
import { getNativeFileUrl } from 'media/utils';
import { FullPageContent } from 'core/page';
import { isNativeAppWebview } from 'utils/general';
import { getApiUrl } from 'utils/urls';
import BackButton from 'core/page/parts/backButton';


const TokenCreateBase = ({ pageHeading, backUrl, previewQueryArgs, children }) => {
  const previewLink = getApiUrl('/tokens/preview-agreement/', previewQueryArgs);

  const previewUrl = isNativeAppWebview
    ? getNativeFileUrl(previewLink, gettext('Registration agreement'), 'application/pdf')
    : previewLink;

  return (
    <FullPageContent
      pageHeading={pageHeading}
      headerLeft={<BackButton href={backUrl} />}
      actions={[{
        useRouterLink: false,
        url: previewUrl,
        icon: 'file-pdf',
        label: gettext('Preview registration agreement'),
      }]}
    >
      {children}
    </FullPageContent>
  );
};

TokenCreateBase.defaultProps = {
  previewQueryArgs: {},
};

TokenCreateBase.propTypes = {
  pageHeading: PropTypes.string.isRequired,
  backUrl: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  previewQueryArgs: PropTypes.shape({}),
};


export default TokenCreateBase;
