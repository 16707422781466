import { useState } from 'react';

// eslint-disable-next-line import/no-mutable-exports
let setErrorResponseErrorCode;

const useResponseStatus = () => {
  const [statusCode, setStatusCode] = useState();
  setErrorResponseErrorCode = setStatusCode;

  return [statusCode, setStatusCode];
};

export { useResponseStatus, setErrorResponseErrorCode };
