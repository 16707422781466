import { CertificateIssuer } from '@trustwise/design-system';

import { LegalEntityAvatarResponseProps } from 'contacts/interfaces/response';
import { LegalEntityType } from 'contacts/interfaces/types';
import { LegalEntityAvatarModel } from 'contacts/models/legalEntity';
import { Model } from 'core/model';

interface CertificateTypeRegistryIssuersModelProps extends CertificateIssuer {
  avatar: LegalEntityAvatarResponseProps;
}
export class CertificateTypeRegistryIssuersModel extends Model implements CertificateIssuer {
  id: number;
  name: string;
  description: string;
  address: string;
  expireAt: Date;
  avatar: LegalEntityAvatarModel;
  email: string;
  entityType: LegalEntityType;

  constructor({ ...restProps }: CertificateTypeRegistryIssuersModelProps) {
    super(restProps);
    // this.expireAt = new Date(expireAt);
    // this.avatar = new LegalEntityAvatarModel(avatar);
  }
}
