import * as Yup from 'yup';
import 'yup-phone-lite';

import { dateToday } from 'utils/date';
import { fieldRequired } from 'core/forms/utils';
import { jurisdictionsOptions } from '../constants/jurisdictions';
import { businessTypesOptions } from '../constants/companyTypes';

const jurisdictionCodes = jurisdictionsOptions.map((jurisdiction) => jurisdiction.code);

const getCompanyRegistrationValidation = (phoneCountryCode) => Yup.object({
  jurisdiction: Yup.string().oneOf(jurisdictionCodes, gettext('Please select a valid jurisdiction')).required(fieldRequired),
  companyType: Yup.string().when(
    'jurisdiction',
    (jurisdiction, schema) => (businessTypesOptions[jurisdiction] && schema.oneOf(businessTypesOptions[jurisdiction].map(
      (businessType) => businessType.key,
    ), gettext('Please select a valid company type'))),
  ).required(fieldRequired),
  name: Yup.string().required(fieldRequired),
  legalId: Yup.string().when('companyType', {
    is: (companyType) => companyType === 'municipality',
    then: Yup.string(),
    otherwise: Yup.string().required(fieldRequired),
  }),
  incDate: Yup.date().when('companyType', {
    is: (companyType) => companyType === 'municipality',
    then: Yup.date(),
    otherwise: Yup.date()
      .max(dateToday(), gettext('Date cannot be in the future.'))
      .required(gettext('Please select a valid date.')),
  }),
  domicile: Yup.string().when('companyType', {
    is: (companyType) => companyType === 'municipality',
    then: Yup.string(),
    otherwise: Yup.string().required(fieldRequired),
  }),
  countryCode: Yup.string().required(fieldRequired),
  city: Yup.string().required(fieldRequired),
  street: Yup.string().required(fieldRequired),
  additionalAddress: Yup.string(),
  postalCode: Yup.string(),
  phoneNumber:
    Yup.string()
      .required(fieldRequired)
      .phone(phoneCountryCode.toUpperCase(), gettext('Phone number format not recognized')),
  county: Yup.string().required(fieldRequired),
  email: Yup.string().email(gettext('Please enter a valid e-mail')).required(fieldRequired),
  homepage: Yup.string(),
  companyInFoundation: Yup.bool(),
  registeringUserResponsibility: Yup.bool().when('companyInFoundation', {
    is: true,
    then: Yup.bool().oneOf([true], gettext('Your concent is necessary to proceed.')),
    otherwise: Yup.bool(),
  }),
  intentionFiles: Yup.array().of(Yup.mixed()).when('companyInFoundation', {
    is: true,
    then: Yup.array().of(Yup.mixed()).required(fieldRequired),
    otherwise: Yup.array(),
  }),
  publicRegistryLink: Yup.string().when(['companyInFoundation', 'excerptFiles'], {
    is: (companyInFoundation, excerptFiles) => !companyInFoundation && !excerptFiles.length,
    then: Yup.string().required(
      gettext('Please provide either a link to a public registry or an excerpt of the commercial registry'),
    ),
    otherwise: Yup.string(),
  }),
  excerptFiles: Yup.array().of(Yup.mixed()),
  noSingleSignatureRight: Yup.bool(),
  poaFiles: Yup.array().of(Yup.mixed())
    .when('noSingleSignatureRight', {
      is: true,
      then: Yup.array().of(Yup.mixed()).required(fieldRequired),
      otherwise: Yup.array(),
    }),
  agreementConfirmation: Yup.bool().oneOf([true], gettext('Your agreement is necessary to proceed.')),
  warrantiesConfirmation: Yup.bool().oneOf([true], gettext('Your confirmation is necessary to proceed.')),
});

export default getCompanyRegistrationValidation;
