/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

const Button = ({
  label,
  altText,
  primary,
  type,
  onClick,
}) => {
  let buttonClass = 'button';
  if (!primary) {
    buttonClass += ' button-outlined';
  }
  return (
    <button
      type={type}
      className={buttonClass}
      onClick={onClick}
      data-alt-text={altText}
    >
      {label}
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  altText: PropTypes.string,
  primary: PropTypes.bool,
  type: PropTypes.string,
};

Button.defaultProps = {
  onClick: () => {},
  label: '',
  altText: '',
  primary: true,
  type: 'submit',
};

export default observer(Button);
