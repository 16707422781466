import PropTypes from 'prop-types';

import { BaseButton } from 'core/forms/fields';
import { icons } from 'core/icons';
import { onAgreementDownload } from 'media/utils';

const UserAgreement = ({ type }) => {
  const isCorporate = type === 'corporate';

  return (
    <BaseButton
      className="full-width row-flex card-base padded-y padded-x big-top-margin bottom-margin-1-2-rem"
      onClick={() => onAgreementDownload(type)}
    >
      <div className="centered">
        {icons.previewDocument}
        <span className="left-padding">
          {isCorporate ? gettext('Corporate User Agreement') : gettext('General User Agreement')}
        </span>
      </div>
      {icons.download}
    </BaseButton>
  );
};


UserAgreement.defaultProps = {
  type: 'user',
};

UserAgreement.propTypes = {
  type: PropTypes.oneOf(['user', 'corporate']),
};

export default UserAgreement;
