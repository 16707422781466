/* eslint-disable class-methods-use-this */
import { createRoot } from 'react-dom/client';
import ReconnectingWebSocket from 'reconnecting-websocket';

import { convertBooleanString } from 'core/utils';
import { SOCKETS_URL, NOTIFICATIONS_URL, WS_CONFIG } from './constants';
import { inboxItemsCountStore } from '../stores';
import FlashMessage from '../components/generic/flashMessage';
import axios from '../core/axios';


export default class ReactWsClient {
  constructor() {
    const apiKey = '__api_key__';
    let queryParams = '';
    if (window.localStorage.getItem(apiKey)) {
      queryParams = `?token=${axios.defaults.headers.common.Authorization = window.localStorage.getItem(apiKey)}`;
    }
    const clientUrl = `${SOCKETS_URL}${NOTIFICATIONS_URL}/${queryParams}`;
    this.ws = new ReconnectingWebSocket(clientUrl, [], WS_CONFIG);
    this.ws.onerror = (error) => {
      console.error('[WS NOTIFICATIONS] Error', error);
    };
    this.ws.onmessage = this.onMessage;
  }

  /**
   * Handle websocket messages.
   *
   * @todo Move websockets messages handling to related modules
   * @todo Rethink and refactor handling of different messages.
   */
  onMessage(message) {
    const data = JSON.parse(message.data);
    const flashMessagesWrapper = document.getElementById('flash-messages-wrapper');
    const flashMessagesWrapperRoot = flashMessagesWrapper && createRoot(flashMessagesWrapper);
    if ('flash_message' in data && flashMessagesWrapperRoot) {
      flashMessagesWrapperRoot.render(
        <FlashMessage
          messageType="flash-message success"
          message={data.flash_message.content}
        />,
      );
    }
    if ('inbox_items_count' in data) {
      inboxItemsCountStore.setCounts(data.inbox_items_count);
      const badgesByAccount = document.querySelectorAll('[data-inbox-count-for-id]');
      if (badgesByAccount.length) {
        badgesByAccount.forEach((badge) => {
          badge.innerText = data.inbox_items_count[badge.dataset.inboxCountForId] || '';
        });
      }
    }

    const signTxModal = document.getElementById('unsigned-transaction-modal');
    const unsignedTxNoUpdate = signTxModal.getAttribute('data-no-update');
    const { unsigned_transaction_message: unsignedTrxMessage } = data;
    const { unsignedTransaction: { signingDispatch, signingStatusOld } } = window.trustwise;

    if (!unsignedTrxMessage) { return; }

    if (signingDispatch && !convertBooleanString(unsignedTxNoUpdate)) {
      signingDispatch(unsignedTrxMessage);
    }

    // Support for the old signing view status update tracking
    if (signingStatusOld) {
      const { type, data: unsignedTrxData } = unsignedTrxMessage;
      switch (type) {
        case 'status_updated':
          signingStatusOld(unsignedTrxData);
          break;
        case 'removed':
          signingStatusOld('cancelled');
          break;
        case 'reset':
          signingStatusOld('skipped');
          break;
        default:
          break;
      }
    }
  }
}
