/**
 * List of issuer registries.
 */

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'core/axios';


const IssuerRegistryList = ({ companyId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [issuerRegistriesList, setIssuerRegistriesList] = useState([]);
  useEffect(() => {
    axios.get(`/api/v1/company/${companyId}/certificates/issuer-registries/`)
      .then(({ data }) => { setIssuerRegistriesList(data); })
      .catch(console.error)
      .finally(() => { setIsLoading(false); });
  }, [companyId]);

  return (
    <ul className="padded-top">
      {issuerRegistriesList.length > 0 ? issuerRegistriesList.map((item) => (
        <li key={item.id}>
          <a className="row-flex card-base" href={`/company/${companyId}/certificates/issuer-registries/${item.id}/`}>
            <span className="title">
              {item.name}
            </span>
          </a>
        </li>
      )) : (
        <p className="text-center">
          {isLoading ? gettext('Loading issuer registries...') : gettext('No issuer registries')}
        </p>
      )}
    </ul>
  );
};


IssuerRegistryList.defaultProps = {
  companyId: null,
};

IssuerRegistryList.propTypes = {
  companyId: PropTypes.string,
};

export default IssuerRegistryList;
