import { snakeCase } from 'lodash';

import { convertKeysCase } from 'core/utils';
import { coOwnershipAgreement } from 'media/constants/documentTypes';
import { getApiUrl } from 'utils/urls';
import { onPdfDownload } from 'media/utils';
import { signFormTransaction } from 'utils/signTransaction';

export const onTokenize = ({ values, actions, submitPath, navigatePath }) => {
  const { initialOwner, ...restProps } = values;
  const data = convertKeysCase(restProps, snakeCase);
  const submitData = new FormData();

  if (typeof initialOwner === 'undefined') {
    throw new Error("Initial owner wasn't provided");
  }
  data.initial_owner_id = initialOwner.id;

  Object.entries(data).forEach(
    ([key, value]) => submitData.append(key, value),
  );

  signFormTransaction({
    urlPath: submitPath,
    navigatePath,
    data: submitData,
    actions,
  });
};

export const onAgreementDownload = (type, objectPath) => {
  const title = type === coOwnershipAgreement
    ? gettext('Co-Ownership Agreement')
    : gettext('Curator Mandate Agreement');

  onPdfDownload(getApiUrl(`${objectPath}agreements/`, { doc_type: type }), title);
};
