import { snakeCase } from 'lodash';
import axios from 'core/axios';

import { ASC_SORTING_ORDER } from 'core/const';
import { convertKeysCase } from 'core/utils';
import { signFormTransaction } from 'utils/signTransaction';
import { getApiUrl } from 'utils/urls';
import convertResponseToModel from 'utils/responseToModel';
import { FileModel } from 'media/models';

const getSelectedPermissions = (permissions) => permissions.reduce((acc, value) => {
  const permission = Object.keys(value)[0];
  if (value[permission]) {
    acc.push(permission);
  }

  return acc;
}, []);

export const onAddNewMember = (values, actions, registryPath) => {
  const { member, permissions } = values;
  const selectedPermissions = getSelectedPermissions(permissions);

  return signFormTransaction({
    urlPath: `${registryPath}members/`,
    navigatePath: registryPath,
    data: { legal_entity_id: member.id, permissions: selectedPermissions },
    actions,
  });
};

export const onPermissionsEdit = (values, actions, basePath) => signFormTransaction({
  urlPath: `${basePath}permissions/`,
  navigatePath: basePath,
  data: { permissions: getSelectedPermissions(values.permissions) },
  actions,
});

export const onRecordAdd = ({ values, actions, basePath, dispatch }) => {
  const { files, ...restValues } = values;
  const submitData = new FormData();

  const convertedRestValues = convertKeysCase(restValues, snakeCase);
  Object.entries(convertedRestValues).forEach(([key, value]) => {
    submitData.append(key, value);
  });

  files && files.forEach((newFile, newFileIndex) => {
    submitData.append(`new_files[${newFileIndex}]`, newFile);
  });

  return axios.post(getApiUrl(`${basePath}logs/`), submitData)
    .then(({ data }) => {
      actions.setSubmitting(false);
      dispatch({ type: 'addRecord', data });
    })
    .catch(console.error);
};

export const fetchMemberRecord = (urlPath) => axios.get(getApiUrl(urlPath))
  .then(({ data: { files } }) => convertResponseToModel(files || [], FileModel))
  .catch(console.error);

export const onMembersSort = (order, members) => new Promise((resolve) => {
  const sortedMembers = members.sort((a, b) => {
    if (order === ASC_SORTING_ORDER) {
      return a.dateAdded - b.dateAdded;
    }
    return b.dateAdded - a.dateAdded;
  });
  resolve(sortedMembers);
});

export const onMemberRemove = (registryPath, basePath) => signFormTransaction({
  urlPath: `${basePath}remove/`,
  navigatePath: registryPath,
});
