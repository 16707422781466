/* File detail component */
import { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { PageContent } from 'core/page';
import { formatDateFromTimestamp } from 'utils/date';
import { getApiUrl } from 'utils/urls';
import ContentLoadingMessage from 'utils/contentLoadingMessage';
import FileDelete from 'media/file/delete';
import axios from 'core/axios';


const FilePreview = ({ file }) => {
  if (file.fetchUrl) {
    if (file.isImage) {
      return (
        <div className="document-file-image-wrapper">
          <img alt="" src={file.fetchUrl} />
        </div>
      );
    } if (file.isPdf) {
      return (
        <div className="document-file-preview-wrapper">
          <object
            aria-label={gettext('PDF Preview')}
            className="document-file-preview"
            data={`${file.fetchUrl}#zoom=100&scrollbar=1&toolbar=1&navpanes=1`}
            type="application/pdf"
            width="100%"
          />
        </div>
      );
    }
  }
  return <i className={`far fa-fw icon fa-file-${file.iconName}`} aria-hidden="true" />;
};

FilePreview.propTypes = {
  file: PropTypes.shape({
    isImage: PropTypes.bool,
    isPdf: PropTypes.bool,
    iconName: PropTypes.string,
    fetchUrl: PropTypes.string.isRequired,
  }).isRequired,
};


const FileDetailComponent = ({ state, dispatch }) => {
  const [isLoading, setIsLoading] = useState(true);
  const contentElement = document.querySelector('.app-container');
  const [showFileDeleteModal, setShowFileDeleteModal] = useState(false);
  const { documentId, fileId } = useParams();
  const { search } = useLocation();
  const navigate = useNavigate();
  const { documents, isEditable } = state;
  const file = (documents && documents[documentId] && documents[documentId].files) ? documents[documentId].files[fileId] : {};

  useEffect(() => {
    axios.get(getApiUrl(`/new-documents/${documentId}/attachments/${fileId}/`, search))
      .then(({ data }) => { dispatch({ data: { file: data, documentId, fileId }, type: 'getFile' }); })
      .catch(console.error)
      .finally(() => { setIsLoading(false); });
  }, [search, documentId, fileId, dispatch]);

  const downloadUrl = getApiUrl(`/new-documents/${documentId}/attachments/${fileId}/download/`, search);

  const actions = [
    {
      url: downloadUrl,
      icon: 'download',
      label: gettext('Download'),
      useRouterLink: false,
    },
  ];
  if (isEditable && file && file.isEditable) {
    actions.push({
      type: 'button',
      onClick: () => { setShowFileDeleteModal(true); },
      icon: 'trash',
      label: gettext('Delete'),
    });
  }
  return createPortal(
    <PageContent
      pageHeading={gettext('Attachment')}
      pageSubtitle={file && file.name}
      actions={actions}
    >
      <>
        {file && Object.entries(file).length > 0 ? (
          <>
            <div className="padded-y">
              <span className="text-smaller">
                <i className="fa fa-fw icon fa-history secondary" aria-hidden="true" />
                {file.timestamp
                  ? `${gettext('Recorded on blockchain on')} ${formatDateFromTimestamp(file.timestamp)}`
                  : gettext('No blockchain record for this file.')}
              </span>
            </div>
            <FilePreview file={file} />
          </>
        ) : (
          <ContentLoadingMessage
            isLoading={isLoading}
            loadedMessage={gettext('File not found.')}
          />
        )}
        <FileDelete
          isOpen={showFileDeleteModal}
          onClose={() => { setShowFileDeleteModal(false); }}
          queryParams={search}
          isEditable={isEditable && file.isEditable}
          fileName={file.name}
          onSuccessResponse={() => {
            setShowFileDeleteModal(false);
            dispatch({ data: { documentId, fileId }, type: 'deleteFile' });
            navigate(-1);
          }}
        />
      </>
    </PageContent>,
    contentElement,
  );
};

FileDetailComponent.propTypes = {
  state: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default FileDetailComponent;
