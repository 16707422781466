import { useMutation, useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';

import {
  IssuedCertificatesListView,
  IssuedCertificatesListViewPages,
  IssuedCertificatesListViewPage,
} from '@trustwise/design-system';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import { getRouterLinkInteraction } from 'core/utils';
import { useRefetchOnTxProcessed } from 'core/hooks';
import {
  getIssuedCertificates,
  getIssuerCertificateTypes,
  revokeCertificate,
} from '../actions';

export const CertificatesIssuingOverview = () => {
  const { state: routerState } = useLocation();
  const [activeTab, setActiveTab] = useState<IssuedCertificatesListViewPage>(
    routerState?.activeTab ||
    IssuedCertificatesListViewPages.OVERVIEW,
  );
  const navigate = useNavigate();

  const setRefetchQueryKeys = useRefetchOnTxProcessed({ exact: false });

  const { data: certificates } = useQuery({
    queryKey: ['issuerCertificates'],
    queryFn: () => getIssuedCertificates({}),
  });

  const { data: certificateTypes } = useQuery({
    queryKey: ['issuerCertificateTypes'],
    queryFn: () => getIssuerCertificateTypes('3'),
  });

  const { mutateAsync: onCertificatesSort } = useMutation({
    mutationFn: getIssuedCertificates,
  });

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Certificates Issuing')}
      headerLeft={<BackButton href="/" useRouterLink />}
    >
      <IssuedCertificatesListView
        certificates={certificates}
        onSort={(column, order) => onCertificatesSort({ column, order })}
        onRevoke={(certificateTypeAddress, certificateId, setDisabled) => (
          revokeCertificate(certificateTypeAddress, certificateId, setDisabled)
            .then(() => setRefetchQueryKeys(['issuerCertificates']))
        )}
        certificateTypes={certificateTypes}
        onCertificateTypesSeeAll={() => navigate('types/')}
        onCertificateTypeClick={(id: number) => navigate(`types/${id}/`, { state: { origin: '/issuing/' } })}
        activeTab={activeTab}
        onTabSwitch={setActiveTab}
        issueInteraction={getRouterLinkInteraction('issue/', { activeTab })}
      />
    </PageContent>
  );
};
