import axios from 'core/axios';
import { QueryKeyProp } from 'core/types';
import { ONGOING_GROUP, SHAREHOLDERS, UPCOMING_GROUP } from 'governanceEvents/const';
import { GovernanceEventListItemModel } from 'governanceEvents/models';
import { getStatusesURLParams } from 'governanceEvents/utils';
import { AssetTransactionModel } from 'investments/models/assetTransaction';
import { EquityDetailModel, EquityItemModel } from 'investments/equity/models';
import { ISimpleDocumentModel } from 'media/interfaces';
import { onFileDownload } from 'media/utils';
import { getApiUrl } from 'utils/urls';
import { TRANSFER_COMPLETED } from 'businessTransactions/consts';
import { TokenValuationModel } from 'valuation/models/valuation';

const baseUrl = '/assets/equity/';
export const getDetailUrl = (portfolioId: string, tokenId: string) => `${baseUrl}${portfolioId}/${tokenId}/`;

export const getEquityList = (urlSearch: string): Promise<EquityItemModel[]> => (
  axios.get(getApiUrl(baseUrl, urlSearch))
    .then(({ data: { results } }) => EquityItemModel.fromResponse(results))
    .catch(console.error)
);

export const getEquityDetails = ({ queryKey }: QueryKeyProp): Promise<EquityDetailModel> => {
  const [, portfolioId, tokenId] = queryKey;

  return (
    axios.get(getApiUrl(getDetailUrl(portfolioId, tokenId)))
      .then(({ data }) => EquityDetailModel.fromResponse(data))
      .catch(console.error)
  );
};

export const onEquityFileDownload = (
  fileId: number,
  documents: ISimpleDocumentModel[],
  portfolioId: string,
  tokenId: string,
) => {
  onFileDownload(fileId, documents, getDetailUrl(portfolioId, tokenId));
};

export const getEquityTransactions = ({ queryKey }: QueryKeyProp): Promise<AssetTransactionModel[]> => {
  const [, portfolioId, tokenId] = queryKey;
  const queryArgs = { portfolio_id: portfolioId, token_id: tokenId, stage: TRANSFER_COMPLETED };

  return axios.get(getApiUrl('/assets/transfers/', queryArgs))
    .then(({ data }) => AssetTransactionModel.fromResponse(data))
    .catch(console.error);
};

// @todo DRY
export const getShareholdersEvents = (
  issuerId: string,
  tokenId: string,
): Promise<GovernanceEventListItemModel[]> => {
  const urlParams = new URLSearchParams({
    no_of_items: '1',
    order_by: 'date_time',
    target_group: SHAREHOLDERS,
    owner_id: issuerId,
    token_id: tokenId,
  });
  getStatusesURLParams([ONGOING_GROUP, UPCOMING_GROUP], urlParams);

  return axios.get(`${getApiUrl('/meetings/', urlParams)}`)
    .then(({ data }) => GovernanceEventListItemModel.fromResponse(data))
    .catch(console.error);
};

export const getValuations = (tokenId: string): Promise<TokenValuationModel[]> => (
  axios.get(getApiUrl(`/assets/equity/${tokenId}/valuation`))
    .then(({ data }) => TokenValuationModel.fromResponse(data))
    .catch(console.error)
);
