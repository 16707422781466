const countryOptions = [
  { code: 'AF', label: gettext('Afghanistan') },
  { code: 'AL', label: gettext('Albania') },
  { code: 'DZ', label: gettext('Algeria') },
  { code: 'AS', label: gettext('American Samoa') },
  { code: 'AD', label: gettext('Andorra') },
  { code: 'AO', label: gettext('Angola') },
  { code: 'AI', label: gettext('Anguilla') },
  { code: 'AG', label: gettext('Antigua and Barbuda') },
  { code: 'AR', label: gettext('Argentina') },
  { code: 'AM', label: gettext('Armenia') },
  { code: 'AW', label: gettext('Aruba') },
  { code: 'AC', label: gettext('Ascension Island') },
  { code: 'AU', label: gettext('Australia') },
  { code: 'AT', label: gettext('Austria') },
  { code: 'AZ', label: gettext('Azerbaijan') },
  { code: 'BS', label: gettext('Bahamas') },
  { code: 'BH', label: gettext('Bahrain') },
  { code: 'BD', label: gettext('Bangladesh') },
  { code: 'BB', label: gettext('Barbados') },
  { code: 'BY', label: gettext('Belarus') },
  { code: 'BE', label: gettext('Belgium') },
  { code: 'BZ', label: gettext('Belize') },
  { code: 'BJ', label: gettext('Benin') },
  { code: 'BM', label: gettext('Bermuda') },
  { code: 'BT', label: gettext('Bhutan') },
  { code: 'BO', label: gettext('Bolivia') },
  { code: 'BA', label: gettext('Bosnia and Herzegovina') },
  { code: 'BW', label: gettext('Botswana') },
  { code: 'BR', label: gettext('Brazil') },
  { code: 'IO', label: gettext('British Indian Ocean Territory') },
  { code: 'VG', label: gettext('British Virgin Islands') },
  { code: 'BN', label: gettext('Brunei') },
  { code: 'BG', label: gettext('Bulgaria') },
  { code: 'BF', label: gettext('Burkina Faso') },
  { code: 'BI', label: gettext('Burundi') },
  { code: 'KH', label: gettext('Cambodia') },
  { code: 'CM', label: gettext('Cameroon') },
  { code: 'CA', label: gettext('Canada') },
  { code: 'CV', label: gettext('Cape Verde') },
  { code: 'BQ', label: gettext('Caribbean Netherlands') },
  { code: 'KY', label: gettext('Cayman Islands') },
  { code: 'CF', label: gettext('Central African Republic') },
  { code: 'TD', label: gettext('Chad') },
  { code: 'CL', label: gettext('Chile') },
  { code: 'CN', label: gettext('China') },
  { code: 'CX', label: gettext('Christmas Island') },
  { code: 'CC', label: gettext('Cocos') },
  { code: 'CO', label: gettext('Colombia') },
  { code: 'KM', label: gettext('Comoros') },
  { code: 'CD', label: gettext('Congo (DRC)') },
  { code: 'CG', label: gettext('Congo (Republic)') },
  { code: 'CK', label: gettext('Cook Islands') },
  { code: 'CR', label: gettext('Costa Rica') },
  { code: 'CI', label: "Côte d'Ivoire" },
  { code: 'HR', label: gettext('Croatia') },
  { code: 'CU', label: gettext('Cuba') },
  { code: 'CW', label: gettext('Curaçao') },
  { code: 'CY', label: gettext('Cyprus') },
  { code: 'CZ', label: gettext('Czech Republic') },
  { code: 'DK', label: gettext('Denmark') },
  { code: 'DJ', label: gettext('Djibouti') },
  { code: 'DM', label: gettext('Dominica') },
  { code: 'DO', label: gettext('Dominican Republic') },
  { code: 'EC', label: gettext('Ecuador') },
  { code: 'EG', label: gettext('Egypt') },
  { code: 'SV', label: gettext('El Salvador') },
  { code: 'GQ', label: gettext('Equatorial Guinea') },
  { code: 'ER', label: gettext('Eritrea') },
  { code: 'EE', label: gettext('Estonia') },
  { code: 'SZ', label: gettext('Eswatini') },
  { code: 'ET', label: gettext('Ethiopia') },
  { code: 'FK', label: gettext('Falkland Islands') },
  { code: 'FO', label: gettext('Faroe Islands') },
  { code: 'FJ', label: gettext('Fiji') },
  { code: 'FI', label: gettext('Finland') },
  { code: 'FR', label: gettext('France') },
  { code: 'GF', label: gettext('French Guiana') },
  { code: 'PF', label: gettext('French Polynesia') },
  { code: 'GA', label: gettext('Gabon') },
  { code: 'GM', label: gettext('Gambia') },
  { code: 'GE', label: gettext('Georgia') },
  { code: 'DE', label: gettext('Germany') },
  { code: 'GH', label: gettext('Ghana') },
  { code: 'GI', label: gettext('Gibraltar') },
  { code: 'GR', label: gettext('Greece') },
  { code: 'GL', label: gettext('Greenland') },
  { code: 'GD', label: gettext('Grenada') },
  { code: 'GP', label: gettext('Guadeloupe') },
  { code: 'GU', label: gettext('Guam') },
  { code: 'GT', label: gettext('Guatemala') },
  { code: 'GG', label: gettext('Guernsey') },
  { code: 'GN', label: gettext('Guinea') },
  { code: 'GW', label: gettext('Guinea-Bissau') },
  { code: 'GY', label: gettext('Guyana') },
  { code: 'HT', label: gettext('Haiti') },
  { code: 'HN', label: gettext('Honduras') },
  { code: 'HK', label: gettext('Hong Kong') },
  { code: 'HU', label: gettext('Hungary') },
  { code: 'IS', label: gettext('Iceland') },
  { code: 'IN', label: gettext('India') },
  { code: 'ID', label: gettext('Indonesia') },
  { code: 'IR', label: gettext('Iran') },
  { code: 'IQ', label: gettext('Iraq') },
  { code: 'IE', label: gettext('Ireland') },
  { code: 'IM', label: gettext('Isle of Man') },
  { code: 'IL', label: gettext('Israel') },
  { code: 'IT', label: gettext('Italy') },
  { code: 'JM', label: gettext('Jamaica') },
  { code: 'JP', label: gettext('Japan') },
  { code: 'JE', label: gettext('Jersey') },
  { code: 'JO', label: gettext('Jordan') },
  { code: 'KZ', label: gettext('Kazakhstan') },
  { code: 'KE', label: gettext('Kenya') },
  { code: 'KI', label: gettext('Kiribati') },
  { code: 'XK', label: gettext('Kosovo') },
  { code: 'KW', label: gettext('Kuwait') },
  { code: 'KG', label: gettext('Kyrgyzstan') },
  { code: 'LA', label: gettext('Laos') },
  { code: 'LV', label: gettext('Latvia') },
  { code: 'LB', label: gettext('Lebanon') },
  { code: 'LS', label: gettext('Lesotho') },
  { code: 'LR', label: gettext('Liberia') },
  { code: 'LY', label: gettext('Libya') },
  { code: 'LI', label: gettext('Liechtenstein') },
  { code: 'LT', label: gettext('Lithuania') },
  { code: 'LU', label: gettext('Luxembourg') },
  { code: 'MO', label: gettext('Macau') },
  { code: 'MK', label: gettext('North Macedonia') },
  { code: 'MG', label: gettext('Madagascar') },
  { code: 'MW', label: gettext('Malawi') },
  { code: 'MY', label: gettext('Malaysia') },
  { code: 'MV', label: gettext('Maldives') },
  { code: 'ML', label: gettext('Mali') },
  { code: 'MT', label: gettext('Malta') },
  { code: 'MH', label: gettext('Marshall Islands') },
  { code: 'MQ', label: gettext('Martinique') },
  { code: 'MR', label: gettext('Mauritania') },
  { code: 'MU', label: gettext('Mauritius') },
  { code: 'YT', label: gettext('Mayotte') },
  { code: 'MX', label: gettext('Mexico') },
  { code: 'FM', label: gettext('Micronesia') },
  { code: 'MD', label: gettext('Moldova') },
  { code: 'MC', label: gettext('Monaco') },
  { code: 'MN', label: gettext('Mongolia') },
  { code: 'ME', label: gettext('Montenegro') },
  { code: 'MS', label: gettext('Montserrat') },
  { code: 'MA', label: gettext('Morocco') },
  { code: 'MZ', label: gettext('Mozambique') },
  { code: 'MM', label: gettext('Myanmar') },
  { code: 'NA', label: gettext('Namibia') },
  { code: 'NR', label: gettext('Nauru') },
  { code: 'NP', label: gettext('Nepal') },
  { code: 'NL', label: gettext('Netherlands') },
  { code: 'NC', label: gettext('New Caledonia') },
  { code: 'NZ', label: gettext('New Zealand') },
  { code: 'NI', label: gettext('Nicaragua') },
  { code: 'NE', label: gettext('Niger') },
  { code: 'NG', label: gettext('Nigeria') },
  { code: 'NU', label: gettext('Niue') },
  { code: 'NF', label: gettext('Norfolk Island') },
  { code: 'KP', label: gettext('North Korea') },
  { code: 'MP', label: gettext('Northern Mariana Islands') },
  { code: 'NO', label: gettext('Norway') },
  { code: 'OM', label: gettext('Oman') },
  { code: 'PK', label: gettext('Pakistan') },
  { code: 'PW', label: gettext('Palau') },
  { code: 'PS', label: gettext('Palestine') },
  { code: 'PA', label: gettext('Panama') },
  { code: 'PG', label: gettext('Papua New Guinea') },
  { code: 'PY', label: gettext('Paraguay') },
  { code: 'PE', label: gettext('Peru') },
  { code: 'PH', label: gettext('Philippines') },
  { code: 'PL', label: gettext('Poland') },
  { code: 'PT', label: gettext('Portugal') },
  { code: 'PR', label: gettext('Puerto Rico') },
  { code: 'QA', label: gettext('Qatar') },
  { code: 'RE', label: gettext('Réunion') },
  { code: 'RO', label: gettext('Romania') },
  { code: 'RU', label: gettext('Russia') },
  { code: 'RW', label: gettext('Rwanda') },
  { code: 'BL', label: gettext('Saint Barthélemy') },
  { code: 'SH', label: gettext('Saint Helena') },
  { code: 'KN', label: gettext('Saint Kitts and Nevis') },
  { code: 'LC', label: gettext('Saint Lucia') },
  { code: 'MF', label: gettext('Saint Martin') },
  { code: 'PM', label: gettext('Saint Pierre and Miquelon') },
  { code: 'VC', label: gettext('Saint Vincent and the Grenadines') },
  { code: 'WS', label: gettext('Samoa') },
  { code: 'SM', label: gettext('San Marino') },
  { code: 'ST', label: gettext('São Tomé and Príncipe') },
  { code: 'SA', label: gettext('Saudi Arabia') },
  { code: 'SN', label: gettext('Senegal') },
  { code: 'RS', label: gettext('Serbia') },
  { code: 'SC', label: gettext('Seychelles') },
  { code: 'SL', label: gettext('Sierra Leone') },
  { code: 'SG', label: gettext('Singapore') },
  { code: 'SX', label: gettext('Sint Maarten') },
  { code: 'SK', label: gettext('Slovakia') },
  { code: 'SI', label: gettext('Slovenia') },
  { code: 'SB', label: gettext('Solomon Islands') },
  { code: 'SO', label: gettext('Somalia') },
  { code: 'ZA', label: gettext('South Africa') },
  { code: 'KR', label: gettext('South Korea') },
  { code: 'SS', label: gettext('South Sudan') },
  { code: 'ES', label: gettext('Spain') },
  { code: 'LK', label: gettext('Sri Lanka') },
  { code: 'SD', label: gettext('Sudan') },
  { code: 'SR', label: gettext('Suriname') },
  { code: 'SJ', label: gettext('Svalbard and Jan Mayen') },
  { code: 'SE', label: gettext('Sweden') },
  { code: 'CH', label: gettext('Switzerland') },
  { code: 'SY', label: gettext('Syria') },
  { code: 'TW', label: gettext('Taiwan') },
  { code: 'TJ', label: gettext('Tajikistan') },
  { code: 'TZ', label: gettext('Tanzania') },
  { code: 'TH', label: gettext('Thailand') },
  { code: 'TL', label: gettext('Timor-Leste') },
  { code: 'TG', label: gettext('Togo') },
  { code: 'TK', label: gettext('Tokelau') },
  { code: 'TO', label: gettext('Tonga') },
  { code: 'TT', label: gettext('Trinidad and Tobago') },
  { code: 'TN', label: gettext('Tunisia') },
  { code: 'TR', label: gettext('Turkey') },
  { code: 'TM', label: gettext('Turkmenistan') },
  { code: 'TC', label: gettext('Turks and Caicos Islands') },
  { code: 'TV', label: gettext('Tuvalu') },
  { code: 'VI', label: gettext('U.S. Virgin Islands') },
  { code: 'UG', label: gettext('Uganda') },
  { code: 'UA', label: gettext('Ukraine') },
  { code: 'AE', label: gettext('United Arab Emirates') },
  { code: 'GB', label: gettext('United Kingdom') },
  { code: 'US', label: gettext('United States') },
  { code: 'UY', label: gettext('Uruguay') },
  { code: 'UZ', label: gettext('Uzbekistan') },
  { code: 'VU', label: gettext('Vanuatu') },
  { code: 'VA', label: gettext('Vatican City') },
  { code: 'VE', label: gettext('Venezuela') },
  { code: 'VN', label: gettext('Vietnam') },
  { code: 'WF', label: gettext('Wallis and Futuna') },
  { code: 'EH', label: gettext('Western Sahara') },
  { code: 'YE', label: gettext('Yemen') },
  { code: 'ZM', label: gettext('Zambia') },
  { code: 'ZW', label: gettext('Zimbabwe') },
  { code: 'AX', label: gettext('Åland Islands') },
];

export default countryOptions;
