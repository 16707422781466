import { getApiUrl } from 'utils/urls';
import axios from 'core/axios';
import LegalEntityPricingPlanModel from 'pricing/models/legalEntityPricingPlan';
import convertResponseToModel from 'utils/responseToModel';
import { getRouterLinkInteraction } from 'core/utils';

export const fetchSubscriptions = (noOfItems: string) => {
  const queryArgs = noOfItems ? { no_of_items: noOfItems } : '';
  return (
    axios
      .get(getApiUrl('/product-offerings/subscriptions/', queryArgs))
      .then(({ data }) => {
        const subscriptions = convertResponseToModel(data, LegalEntityPricingPlanModel);
        return subscriptions.map((subscription: LegalEntityPricingPlanModel) => (
          { ...subscription, offering: subscription.offering }
        ));
      })
      .catch(console.error)
  );
};

export const getSubscriptionsInteraction = (activePage: string, id?: number) => (
  getRouterLinkInteraction(
    `/product-offerings/${id ? `${id}/` : ''}`,
    { activeTab: activePage },
  )
);
