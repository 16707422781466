/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/prefer-stateless-function */
import { Component } from 'react';

/**
 * @deprecated DO NOT USE
 */
export default class LoaderComponent extends Component {
  render() {
    const { isLoading } = this.props;

    return (
      <div>
        <div className="component-loader overlay">
          {isLoading ? <div className="loader" /> : null }
        </div>
        <div className={isLoading ? 'overlaid' : ''}>{this.props.children}</div>
      </div>
    );
  }
}
