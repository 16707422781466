import PropTypes from 'prop-types';
import { Field } from 'formik';

import FieldErrors from './errors';
import FieldLabel from './label';

/**
 * Checkbox input with label.
 */
const CheckboxInput = ({ id, label, name, value, isOptional, ...props }) => (
  <>
    <Field type="checkbox" className="checkbox-input" name={name} value={value} id={id || name} {...props} />
    {label && (<FieldLabel className="radio-checkbox-label" id={id || name} label={label} isOptional={isOptional} />)}
  </>
);

CheckboxInput.defaultProps = {
  id: '',
  label: '',
  value: undefined,
  isOptional: false,
};

CheckboxInput.propTypes = {
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  isOptional: PropTypes.bool,
};


/**
 * Checkbox field with label and errors.
 */
const CheckboxField = ({ id, label, name, value, className, ...props }) => (
  <>
    <FieldErrors name={name} />
    <span className={`radio-checkbox-wrapper ${className}`}>
      <CheckboxInput
        id={id}
        label={label}
        name={name}
        value={value}
        {...props}
      />
    </span>
  </>
);

CheckboxField.defaultProps = {
  id: '',
  label: '',
  value: undefined,
  className: '',
};

CheckboxField.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  className: PropTypes.string,
};

export { CheckboxInput, CheckboxField };
