import { ButtonClick } from 'types';

export const getRouterLinkInteraction = (to: string, state?: Record<string, unknown>) => ({
  routerLink: { to, state },
});

export const getLinkInteraction = (href: string) => ({ link: { href } });

export const getButtonInteraction = (
  onClick: ButtonClick,
  autoReset?: boolean,
) => ({ button: { onClick, autoReset } });
