import PropTypes from 'prop-types';
import TextField from './text';

/**
 * URL field.
 *
 */
const UrlField = ({ autoComplete, id, label, name, hideErrors, ...props }) => (
  <TextField
    id={id || name}
    name={name}
    label={label}
    type="url"
    autoComplete={autoComplete}
    hideErrors={hideErrors}
    {...props}
  />
);

UrlField.defaultProps = {
  autoComplete: '',
  id: '',
  label: '',
  hideErrors: false,
};

UrlField.propTypes = {
  autoComplete: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  hideErrors: PropTypes.bool,
};

export default UrlField;
