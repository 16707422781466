import { isMeetingType, isResolutionType } from '../../utils';
import { CLOSED, IN_PREPARATION, MINUTES_SIGNED, MINUTES_SIGNING_REQUESTED } from '../../const';

export const getAddNewDocumentEnabled = (event, allowedRoles) => {
  let allowAddNewDocumentStatuses = [];
  const { eventType, status } = event;

  if (!allowedRoles) {
    return false;
  }

  if (isMeetingType(eventType)) {
    allowAddNewDocumentStatuses = [IN_PREPARATION, CLOSED, MINUTES_SIGNING_REQUESTED, MINUTES_SIGNED];
  } else if (isResolutionType(eventType)) {
    allowAddNewDocumentStatuses = [IN_PREPARATION];
  }

  return !!status && allowAddNewDocumentStatuses.includes(status);
};
