import { convertKeysToCamelCase } from 'core/utils';
import { RegisteredObjectDetailModel } from 'registeredObjects/models/registeredObject';
import { BusinessTransactionModel } from './models/businessTransaction';

const reducer = (currentState, action) => {
  const { data, type } = action;

  switch (type) {
    case 'fetchBusinessTransaction': {
      return {
        ...currentState,
        businessTransaction: new BusinessTransactionModel(convertKeysToCamelCase(data)),
      };
    }

    case 'fetchObject': {
      return {
        ...currentState,
        registeredObject: new RegisteredObjectDetailModel(convertKeysToCamelCase(data)),
      };
    }

    default:
      return currentState;
  }
};

export default reducer;
