import PropTypes from 'prop-types';

import { LinkWithContent } from 'core/link';
import { ButtonAction } from './actions';
import { Icon, ActionLabel } from './actions/generic';


const ActionTypePart = ({ action }) => {
  let className = 'button button-small ';
  className += action.isPrimary ? '' : 'button-outlined ';
  className += action.isDisabled ? 'disabled ' : 'active ';
  if (['button', 'form'].includes(action.type)) {
    return (
      <ButtonAction
        type={action.type === 'form' ? 'submit' : 'button'}
        icon={action.icon}
        label={action.label}
        onClick={action.onClick}
        isDisabled={action.isDisabled}
        className={className}
      />
    );
  }

  return (
    <LinkWithContent
      to={action.url}
      className={className}
      useRouterLink={action.useRouterLink}
    >
      <>
        <Icon iconName={action.icon} />
        <ActionLabel text={action.label} />
      </>
    </LinkWithContent>
  );
};

ActionTypePart.propTypes = {
  action: PropTypes.shape({
    icon: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
    url: PropTypes.string,
    isDisabled: PropTypes.bool,
    isPrimary: PropTypes.bool,
    useRouterLink: PropTypes.bool,
    onClick: PropTypes.func,
  }).isRequired,
};

export default ActionTypePart;
