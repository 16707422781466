import PropTypes from 'prop-types';
import { formatDateFromTimestamp } from 'utils/date';

export default class BlockchainInfoModel {
  constructor({ timestamp, recordedBy, signatories }) {
    this.timestamp = timestamp || null;
    this.recordedBy = recordedBy || {};
    this.signatories = signatories || [];
  }

  static propType = PropTypes.shape({
    timestamp: PropTypes.number,
    recordedBy: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    signatories: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })),
  });

  update(data) { Object.entries(data).forEach(([key, value]) => { this[key] = value; }); }

  get formattedTimestamp() { return this.timestamp ? formatDateFromTimestamp(this.timestamp) : ''; }

}
