import { useEffect, useState } from 'react';

import { UtilityTokensAssetsListView as UtilityTokensAssetsList } from '@trustwise/design-system';
import convertResponseToModel from 'utils/responseToModel';
import { getChartData } from 'investments/utils';
import { getGlobalContext } from 'core/globals';
import { getUrl, getApiUrl } from 'utils/urls';
import { PageContent } from 'core/page';
import axios from 'core/axios';
import BackButton from 'core/page/parts/backButton';
import { getRouterLinkInteraction } from 'core/utils';
import { UtilityTokenAssetModel } from '../models/utilityTokenAsset';

const UtilityTokensAssetsListView = () => {
  const [tokens, setTokens] = useState();
  const { activeEntity: { baseCurrency } } = getGlobalContext();

  useEffect(() => {
    axios.get(getApiUrl('/assets/utility-tokens/'))
      .then(({ data }) => {
        setTokens(convertResponseToModel(data, UtilityTokenAssetModel));
      })
      .catch(console.error);
  }, []);

  let chartData;
  if (tokens) {
    chartData = getChartData(tokens);
  }

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Utility')}
      headerLeft={<BackButton href={getUrl('/')} />}
    >
      <UtilityTokensAssetsList
        tokens={tokens}
        chartData={chartData}
        currency={baseCurrency}
        onTokenClick={(tokenId, portfolioId) => getRouterLinkInteraction(`${portfolioId}/${tokenId}/`)}
      />
    </PageContent>
  );
};

export default UtilityTokensAssetsListView;
