import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import { useMemo } from 'react';

import { getUrl } from 'utils/urls';
import { isNativeAppWebview, isNewNativeAppWebview } from 'utils/general';
import { getGlobalContext } from 'core/globals';
import { FullPageView } from 'core/page';
import { ContactsContext } from 'contacts/context';
import ProfileDetail from './views/detail';
import { ContactsNavigation } from '../contacts/navigation';
import EraseAllDataView from './views/eraseDeviceData';
import PricingTransactionsListView from '../pricing/views/transactionsList';
import ProductOfferingsNavigation from '../pricing/productOffering/navigation';

const ProfileView = () => {
  const isWebView = isNativeAppWebview || isNewNativeAppWebview;
  const { activeEntity: { isCompany } } = getGlobalContext();
  const basePath = '/account';

  const contextValue = useMemo(() => ({
    basePath,
    indexPath: '/contacts/',
  }), [basePath]);

  return (
    <FullPageView>
      <Router basename={getUrl(basePath)}>
        <Routes>
          <Route path="/">
            <Route index element={<ProfileDetail />} />
            <Route
              path="contacts/*"
              element={(
                <ContactsContext.Provider value={contextValue}>
                  <ContactsNavigation />
                </ContactsContext.Provider>
              )}
            />
            <Route path="product-offerings/*" element={<ProductOfferingsNavigation />} />
            <Route path="pricing/transactions/" element={<PricingTransactionsListView />} />
            {isWebView && !isCompany && <Route path="erase-data/" element={<EraseAllDataView />} />}
          </Route>
        </Routes>
      </Router>
    </FullPageView>
  );
};

export default ProfileView;
