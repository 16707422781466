import { BoardMembershipsListSection } from '@trustwise/design-system';
import { getUrl } from 'utils/urls';
import BackButton from 'core/page/parts/backButton';
import { PageContent } from 'core/page';
import { getRouterLinkInteraction } from 'core/utils';
import { useMembershipsList } from './hooks';

export const MembershipsList = () => {
  const { data: memberships } = useMembershipsList();

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Board Memberships')}
      headerLeft={<BackButton href={getUrl()} />}
    >
      <BoardMembershipsListSection
        memberships={memberships}
        onItemClick={(id: number) => getRouterLinkInteraction(`${id}/`)}
      />
    </PageContent>
  );
};
