import { countryOptions } from 'core/options';

export const SWITZERLAND = 'CH';
export const AUSTRIA = 'AT';
export const GERMANY = 'DE';
export const CYPRUS = 'CY';
export const LIECHTENSTEIN = 'LI';
export const NORWAY = 'NO';
export const ANDORRA = 'AD';
export const AUSTRALIA = 'AU';
export const BELGIUM = 'BE';
export const BRAZIL = 'BR';
export const BULGARIA = 'BG';
export const CANADA = 'CA';
export const CHINA = 'CN';
export const CROATIA = 'HR';
export const CZECH_REPUBLIC = 'CZ';
export const DENMARK = 'DK';
export const ESTONIA = 'EE';
export const FINLAND = 'FI';
export const FRANCE = 'FR';
export const GREECE = 'GR';
export const HONG_KONG = 'HK';
export const HUNGARY = 'HU';
export const ICELAND = 'IS';
export const INDIA = 'IN';
export const INDONESIA = 'ID';
export const IRELAND = 'IE';
export const ISRAEL = 'IL';
export const ITALY = 'IT';
export const JAPAN = 'JP';
export const LATVIA = 'LV';
export const LITHUANIA = 'LT';
export const LUXEMBOURG = 'LU';
export const MALAYSIA = 'MY';
export const MALTA = 'MT';
export const MEXICO = 'MX';
export const MONACO = 'MC';
export const MONTENEGRO = 'ME';
export const NETHERLANDS = 'NL';
export const NEW_ZEALAND = 'NZ';
export const PHILIPPINES = 'PH';
export const POLAND = 'PL';
export const PORTUGAL = 'PT';
export const ROMANIA = 'RO';
export const RUSSIA = 'RU';
export const SAN_MARINO = 'SM';
export const SAUDI_ARABIA = 'SA';
export const SINGAPORE = 'SG';
export const SLOVAKIA = 'SK';
export const SLOVENIA = 'SI';
export const SOUTH_AFRICA = 'ZA';
export const SOUTH_KOREA = 'KR';
export const SPAIN = 'ES';
export const SWEDEN = 'SE';
export const THAILAND = 'TH';
export const TURKEY = 'TR';
export const UKRAINE = 'UA';
export const UNITED_ARAB_EMIRATES = 'AE';
export const UNITED_KINGDOM = 'GB';
export const UNITED_STATES = 'US';
export const VATICAN_CITY = 'VA';

export const jurisdictionsOptions = countryOptions;

