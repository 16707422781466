import { useState, useEffect } from 'react';
import { CustodialDataWidget } from '@trustwise/design-system';

import axios from 'core/axios';
import { convertKeysToCamelCase } from 'core/utils';
import CustodialAccountModel from 'custodian/models';
import { getUrl } from 'utils/urls';
import { getGlobalContext } from 'core/globals';


const CustodialAccountDataWidget = () => {
  const [accountData, setAccountData] = useState(null);
  const {
    activeEntity: { id: activeEntityId },
    custodian: { id: custodianId },
  } = getGlobalContext();

  useEffect(() => {
    const url = `/api/v1/company/${custodianId}/custodian/shareholders/${activeEntityId}/`;
    axios.get(url)
      .then(({ data }) => {
        setAccountData(new CustodialAccountModel(convertKeysToCamelCase(data)));
      })
      .catch(console.error);
  }, [custodianId, activeEntityId]);

  const items = accountData && ([
    {
      label: gettext('Shareholder Name'),
      value: accountData.name,
    },
    accountData.dateOfBirth && {
      label: gettext('Date of Birth'),
      value: accountData.dateOfBirthDisplay,
    },
    {
      label: gettext('Address'),
      value: accountData.fullAddress,
    },
    {
      label: gettext('E-mail'),
      value: accountData.email,
    },
    accountData.custodianComment && {
      label: gettext('Comment'),
      value: accountData.custodianComment,
    },
  ]).filter((x) => !!x);


  return (
    <CustodialDataWidget
      items={items}
      onSeeAll={() => { window.location = getUrl('custodial-account/edit/'); }}
    />
  );
};
export default CustodialAccountDataWidget;
