import { convertKeysToCamelCase } from 'core/utils';
import { DocumentModel, SignatoryModel } from 'media/models';

const reducer = (currentState, action) => {
  const { data } = action;
  const activeDocument = currentState.documents[data.documentId]
    ? currentState.documents[data.documentId] : new DocumentModel({ id: data.documentId });
  switch (action.type) {
    case 'fetch': {
      const currentStateDocuments = currentState.documents;
      data.results.forEach((doc) => {
        if (currentStateDocuments && doc.id in currentStateDocuments) {
          currentStateDocuments[doc.id].update(convertKeysToCamelCase(doc));
        } else {
          currentStateDocuments[doc.id] = new DocumentModel(convertKeysToCamelCase(doc));
        }
      });
      return {
        ...currentState,
        nextPage: data.next ? `${new URL(data.next).pathname}${new URL(data.next).search}` : null,
      };
    }
    case 'getSearchResult': {
      const documents = {};
      data.results.forEach((doc) => { documents[doc.id] = new DocumentModel(convertKeysToCamelCase(doc)); });
      return {
        ...currentState,
        documents,
        nextPage: data.next ? `${new URL(data.next).pathname}${new URL(data.next).search}` : null,
        queryParams: data.queryParams,
      };
    }
    case 'fetchNext': {
      const documents = {};
      data.results.forEach((doc) => { documents[doc.id] = new DocumentModel(convertKeysToCamelCase(doc)); });
      return {
        ...currentState,
        documents: { ...currentState.documents, ...documents },
        nextPage: data.next ? `${new URL(data.next).pathname}${new URL(data.next).search}` : null,
      };
    }
    case 'getDetails':
      Object.entries(convertKeysToCamelCase(data.doc)).forEach(([key, value]) => { activeDocument[key] = value; });
      return {
        ...currentState,
        documents: {
          ...currentState.documents,
          [data.documentId]: activeDocument,
        },
      };
    case 'getThumbnail': {
      activeDocument.files[data.fileId].thumbnail = data.thumbnail;
      return {
        ...currentState,
        documents: {
          ...currentState.documents,
          [data.documentId]: activeDocument,
        },
      };
    }
    case 'delete': {
      const currentStateDocuments = currentState.documents;
      delete currentStateDocuments[data.documentId];
      return currentState;
    }
    case 'fetchSignatories': {
      activeDocument.signerContract.signatories = data.signatories;
      return {
        ...currentState,
        documents: {
          ...currentState.documents,
          [data.documentId]: activeDocument,
        },
      };
    }
    case 'addSignatory':
      if (activeDocument.signerContract && activeDocument.signerContract.signatories) {
        activeDocument.signerContract.signatories.push(new SignatoryModel(convertKeysToCamelCase(data.signatories)));
      } else { activeDocument.signerContract = { signatories: [data.signatories] }; }
      return {
        ...currentState,
        documents: {
          ...currentState.documents,
          [data.documentId]: activeDocument,
        },
      };
    case 'removeSignatory': {
      const signatoryIndex = activeDocument.signerContract.signatories.indexOf(
        activeDocument.signerContract.signatories.find((signatory) => signatory.id === data.id),
      );
      if (signatoryIndex > -1) {
        activeDocument.signerContract.signatories.splice(signatoryIndex, 1);
      }
      return {
        ...currentState,
        documents: {
          ...currentState.documents,
          [data.documentId]: activeDocument,
        },
      };
    }
    case 'getFile': {
      const activeFile = activeDocument.files[data.fileId];
      const convertedKeys = convertKeysToCamelCase(data.file);
      if (!activeFile) {
        activeDocument.files = [convertedKeys];
      } else {
        Object.entries(convertedKeys).forEach(([key, value]) => { activeFile[key] = value; });
      }
      return {
        ...currentState,
        documents: {
          ...currentState.documents,
          [data.documentId]: activeDocument,
        },
      };
    }
    case 'deleteFile':
      delete activeDocument.files[data.fileId];
      return {
        ...currentState,
        documents: {
          ...currentState.documents,
          [data.documentId]: activeDocument,
        },
      };
    default:
      return currentState;
  }
};

export default reducer;
