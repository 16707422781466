/**
 * Form to choose a date for beneficial owners list.
 */

import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { TextField, Button } from 'core/forms/fields';


const SearchForm = ({ onSubmit }) => (
  <Formik
    enableReinitialize
    initialValues={{ searchBox: '' }}
    validationSchema={Yup.object({ searchBox: Yup.string() })}
    onSubmit={onSubmit}
  >
    {({ _isSubmitting, setFieldValue, _values, submitForm }) => (
      <Form
        className="full-width search-form padded-top justify-content-space-between align-flex-items-center deactivated"
        id="search-form"
        data-toggled-by="search-form"
      >
        <TextField className="search-box" onBlur={submitForm} name="searchBox" placeholder="Search..." />
        <Button
          className="empty-button icon-button"
          onClick={() => {
            setFieldValue('searchBox', '');
            submitForm();
          }}
        >
          <i className="fa icon fa-fw icon fa-times-circle" aria-hidden="true" />
        </Button>
        <Button
          className="hollow empty-button bottom-margin"
          onClick={() => {
            setFieldValue('searchBox', '');
            submitForm();
            document.getElementById('search-form').classList.toggle('deactivated');
          }}
        >
          {gettext('Cancel')}
        </Button>
      </Form>
    )}
  </Formik>
);

SearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default SearchForm;
