import { documentStore } from '../stores';
import DocumentUploadComponent from '../components/documentUpload';

import { renderComponent } from '../utils/renderComponent';


export default class DocumentsComponentRenderer {
  constructor({
    id = null,
    companyId = null,
    objectId = null,
    objectType = null,
    defaultDocumentType = null,
  }) {
    this.documentId = id;
    this.companyId = companyId;
    this.documentObjectId = objectId;
    this.documentObjectType = objectType;
    this.defaultDocumentType = defaultDocumentType;
    this.otherProps = {};
  }

  render(elementId) {
    if (['governance_event', 'agenda_item'].includes(this.documentObjectType)) {
      this.otherProps.saveDocumentLabel = gettext('Save');
    }
    documentStore.setDocumentObject({
      documentObjectId: this.documentObjectId,
      documentObjectType: this.documentObjectType,
    });
    if (this.documentId) {
      documentStore.fetchById(this.documentId, this.companyId).then(
        (doc) => {
          renderComponent({
            elementId,
            component: DocumentUploadComponent,
            props: {
              companyId: this.companyId,
              document: doc,
              defaultDocumentType: 'FU',
              documentTypesContext: 'USER_UPLOAD_TYPES',
              ...this.otherProps,
            },
          });
        },
      );
    } else if (this.defaultDocumentType) {
      const doc = documentStore.addDocument({ companyId: this.companyId, defaultDocumentType: this.defaultDocumentType });
      renderComponent({
        elementId,
        component: DocumentUploadComponent,
        props: {
          companyId: this.companyId,
          document: doc,
          defaultDocumentType: 'FU',
          documentTypesContext: 'USER_UPLOAD_TYPES',
          ...this.otherProps,
        },
      });
    } else {
      renderComponent({
        elementId,
        component: DocumentUploadComponent,
        props: {
          companyId: this.companyId,
          defaultDocumentType: 'FU',
          documentTypesContext: 'USER_UPLOAD_TYPES',
          ...this.otherProps,
        },
      });
    }
  }
}
