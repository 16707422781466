import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Link,
  useLocation,
} from 'react-router-dom';
import { PageContent } from 'core/page';
import axios from 'core/axios';
import ContentLoadingMessage from 'utils/contentLoadingMessage';
import BackButton from 'core/page/parts/backButton';

const PotentialShareholdersWhitelists = ({ companyId }) => {
  const [whitelists, setWhitelists] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { pathname } = useLocation();

  useEffect(() => {
    axios.get(`/api/v1/company/${companyId}/ownership/potential-shareholders/`)
      .then(({ data }) => { setWhitelists(data); })
      .catch(console.error)
      .finally(() => { setIsLoading(false); });
  }, [companyId]);

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Potential shareholders whitelists')}
      headerLeft={<BackButton href={`/company/${companyId}/ownership/`} />}
      actions={[{
        url: `${pathname}add/`,
        icon: 'plus',
        label: gettext('Add Whitelist'),
      }]}
    >
      {Object.entries(whitelists).length > 0 ? (
        <>
          <section className="padded-top">
            <header className="row-flex padded-x not-padded">
              <h3 className="title font-size-16px">
                {gettext('Private placement')}
              </h3>
            </header>
            <ol>
              {whitelists.map((whitelist) => (
                <li key={whitelist.id}>
                  <Link to={`${pathname}${whitelist.id}/members/`} className="row-flex card-base">
                    {whitelist.name}
                  </Link>
                </li>
              ))}
            </ol>
          </section>
          {/* <section className="padded-top">
            <header className="row-flex padded-x not-padded">
              <h3 className="title font-size-16px">
                {gettext('Restricted public offerings')}
              </h3>
            </header>
            <ol>
              <li>
                <Link to={`${pathname}filters-set-list/`} className="row-flex card-base">
                  {gettext('Series B capital increase')}
                </Link>
              </li>
            </ol>
          </section> */}
        </>
      ) : (
        <ContentLoadingMessage
          isLoading={isLoading}
          loadedMessage={gettext('No whitelists available')}
        />
      )}
    </PageContent>
  );
};

PotentialShareholdersWhitelists.propTypes = {
  companyId: PropTypes.string.isRequired,
};

export default PotentialShareholdersWhitelists;
