import convertResponseToModel from 'utils/responseToModel';
import { LegacyPricingPlanModel } from './models';


const reducer = (currentState, action) => {
  const { data } = action;
  switch (action.type) {
    case 'fetch': {
      let currentSubscriptionTier = null;
      const convertedPlans = convertResponseToModel(data, LegacyPricingPlanModel);
      // @todo Find a way to handle isLast, previousPlanItems and previousPlanName on the collection level
      const extendedPlans = convertedPlans.map((plan) => {
        const previousPlan = plan.tier > 0 ? convertedPlans[plan.tier - 1] : null;
        plan.isLast = plan.tier + 1 === convertedPlans.length;
        if (previousPlan) {
          plan.previousPlanItems = previousPlan.items;
          plan.previousPlanName = previousPlan.name;
        }
        if (plan.isCurrent) {
          currentSubscriptionTier = plan.tier;
        }
        return plan;
      });
      return {
        ...currentState,
        pricingPlans: extendedPlans,
        currentSubscriptionTier,
      };
    }
    default:
      return currentState;
  }
};

export default reducer;
