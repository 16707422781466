import { convertKeysToCamelCase } from 'core/utils';
import { getGlobalContext } from 'core/globals';
import { countryOptions } from 'core/options';
import { BaseBusinessTransactionModel } from 'businessTransactions/models';

const {
  activeEntity: { id: activeEntityId },
} = getGlobalContext();

/**
 * Business Transaction Model
 *
 * Props under restProps are:
 * id, amount, currency, pricePerUnit, stage, stages, tokenName, type
 *  dateTime, dueDate, documents, arbiter, recipient, sender, metadata,
 *
 */
export class BusinessTransactionModel extends BaseBusinessTransactionModel {
  constructor({
    paymentInstructions, ...restProps
  }) {
    super(restProps);
    this.paymentInstructions = {
      ...paymentInstructions,
      address: convertKeysToCamelCase(paymentInstructions.address),
    };
  }

  get participantRole() {
    return activeEntityId === this.recipient.id ? 'recipient' : 'sender';
  }

  get transferDetails() {
    const { street, city, postcode, countryCode } = this.paymentInstructions.address;

    const country = countryOptions.find(({ code }) => code === countryCode);
    const paymentDetails = {
      currency: this.currency,
      dueDate: this.dueDate,
      // @todo add bankName after MVP
      iban: this.paymentInstructions.iban,
      recipientName: this.sender.name,
      recipientAddress: `${street}, ${postcode || ''} ${city}, ${country ? country.label : ''}`,
    };

    const paymentConfirmationFiles = this.documents.reduce((acc, curr) => {
      const curatedFiles = curr.files.map((file) => ({
        id: file.id,
        name: file.name,
      }));

      return acc.concat(curatedFiles);
    }, []);

    const tradeDetails = {
      objectTitle: this.tokenName,
      amount: this.amount,
      currency: this.currency,
      pricePerUnit: this.pricePerUnit,
    };

    return {
      currentStage: this.stage,
      paymentConfirmationFiles,
      paymentDetails,
      tradeDetails,
      sender: this.sender,
      recipient: this.recipient,
      arbiter: this.arbiter,
      type: this.type,
    };
  }
}
