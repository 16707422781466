import { convertNestedKeysToCamelCase, convertKeysToCamelCase } from '../core/utils';
import ProfileModel from './models/profile';

const reducer = (currentState, action) => {
  const { data, type } = action;

  switch (type) {
    case 'fetchProfileData': {
      return {
        profile: new ProfileModel(convertNestedKeysToCamelCase(data)),
      };
    }

    case 'updateProfile': {
      const { profile } = currentState;
      const caseConvertedData = convertNestedKeysToCamelCase(data);
      Object.entries(caseConvertedData).forEach(([key, value]) => {
        profile[key] = value;
      });
      return {
        profile,
      };
    }

    case 'updateBankAccounts': {
      const { profile } = currentState;
      const updatedIndex = profile.bankAccounts.findIndex((bankAccount) => bankAccount.id === data.id);
      const caseConvertedData = convertKeysToCamelCase(data);
      if (updatedIndex === -1) {
        profile.bankAccounts.push({
          ...caseConvertedData,
        });
      } else {
        const updatedBankAccount = profile.bankAccounts[updatedIndex];
        Object.entries(caseConvertedData).forEach(([key, value]) => {
          updatedBankAccount[key] = value;
        });
      }

      return {
        profile,
      };
    }

    case 'updateAvatar': {
      const { profile } = currentState;
      profile.avatar.image = data.profile_image;
      return {
        profile,
      };
    }

    default:
      return currentState;
  }
};

export default reducer;
