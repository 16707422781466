import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { CertificateIssuingForm } from '@trustwise/design-system';
import {
  getIssuerCertificateTypes,
  onGenerateIdentity,
  onScanIdentity,
  onCertificateIssue,
  onDownloadIdentityPdf,
  onSendIdentityEmail,
  getIssuerCertificateTypeDetails,
  downloadCertificateTypeFile,
} from 'certificates/issuing/actions';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import { getRouterLinkInteraction } from 'core/utils';
import { useLocation } from 'react-router-dom';
import { CertificateTypeDetailModel } from 'certificates/types/models';
import { CertificateIdentity } from '../dataStructures';

export const IssueCertificateView = () => {
  const { state: routerState } = useLocation();
  const [identity, setIdentity] = useState<CertificateIdentity>();
  const [selectedCertificateType, setSelectedCertificateType] = useState<CertificateTypeDetailModel>();

  const { data: certificateTypes } = useQuery({
    queryKey: ['issuerCertificateTypes'],
    queryFn: () => getIssuerCertificateTypes(),
  });

  const identitySharingOptions = identity ? {
    onPDFDownload: () => onDownloadIdentityPdf(identity),
  } : undefined;

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Certificates Issuing')}
      headerLeft={<BackButton href="/issuing/" useRouterLink state={routerState} />}
    >
      <CertificateIssuingForm
        certificateTypes={certificateTypes}
        cancelInteraction={getRouterLinkInteraction('/issuing/', routerState)}
        onCertificateTypeDetailsFetch={(certificateId: number) => (
          getIssuerCertificateTypeDetails(certificateId, setSelectedCertificateType)
        )}
        onSubmit={(values, actions) => (
          onCertificateIssue(values, actions, identity, selectedCertificateType?.encryptedDataSchema)
        )}
        onScanIdentity={(identityCode) => onScanIdentity(identityCode, setIdentity)}
        onGenerateIdentity={(values) => (
          onGenerateIdentity(values, setIdentity, selectedCertificateType?.identityRequirements.identityType)
        )}
        identitySharingOptions={identitySharingOptions}
        onEmailSend={(email) => onSendIdentityEmail(email, identity)}
        onCertificateTypeFileDownload={() => downloadCertificateTypeFile(selectedCertificateType)}
      />
    </PageContent>
  );
};
