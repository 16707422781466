import { DateTime } from 'luxon';

import { getApiUrl } from 'utils/urls';
import { onFileDownload } from 'media/utils';
import { getSelectFieldOptions } from 'core/forms/utils';
import { getTimeZonesOptions } from 'core/const';
import axios from 'core/axios';
import { EVENT_LANGUAGES, EVENT_LOCATIONS } from '../../const';
import {
  prepareSubmitData,
  setResponseErrors,
} from '../../utils';
import { onDocumentAdd, onDocumentDelete, onDocumentEdit } from '../actions/documents';
import { getAddNewDocumentEnabled } from '../actions/utils';
import reducer from '../../reducers';

// @todo modify hardcoded value for pageSize once we support it on the design system
export const inviteesPageSize = { page_size: 1200 };

export const getEventModalData = (modalId, event, dispatch) => {
  const meetingUrlBase = `/meetings/${event.id}/`;

  const onEventUpdate = (values, actions) => {
    const data = prepareSubmitData(values);

    return axios.patch(getApiUrl(meetingUrlBase), data)
      .then(
        ({ data: responseData }) => {
          const updatedValues = Object.keys(data).reduce(
            (result, key) => {
              switch (key) {
                case 'proxy_id':
                  return { ...result, proxy: responseData.proxy };
                case 'chairman_id':
                  return { ...result, chairman: responseData.chairman };
                case 'secretary_id':
                  return { ...result, secretary: responseData.secretary };
                default:
                  return { ...result, [key]: responseData[key] };
              }
            },
            {},
          );
          dispatch({ type: 'updateMeeting', data: updatedValues });
        },
        ({ response: { data: errors } }) => { setResponseErrors(errors, actions); },
      )
      .catch((error) => { console.error(error); actions.setSubmitting(false); });
  };

  switch (modalId) {
    case 'status':
      return {
        currentStatus: event.status,
        statuses: event.statuses,
      };

    case 'subject':
      return {
        initialValues: {
          subject: event.subject,
        },
        onSubmit: onEventUpdate,
      };

    case 'dateTime':
      return {
        timeZoneFieldOptions: getTimeZonesOptions(),
        initialValues: {
          date: DateTime.fromJSDate(event.dateTime).toISODate(),
          time: event.time,
          timeZone: event.timeZone,
        },
        onSubmit: onEventUpdate,
      };

    case 'location':
      return {
        locationFieldOptions: getSelectFieldOptions(EVENT_LOCATIONS),
        languageFieldOptions: getSelectFieldOptions(EVENT_LANGUAGES),
        initialValues: {
          locationType: event.locationType,
          location: event.location,
          locationCustom: event.locationCustom,
          languages: event.languages,
          videoUrl: event.videoUrl,
        },
        onSubmit: onEventUpdate,
      };

    case 'shareClasses':
      return {
        shareClasses: event.shareClasses,
        ...event.noOfShareClasses,
      };

    case 'videoUrl':
      return {
        url: event.videoUrl,
      };

    case 'proxyVoting':
      return {
        isEditable: event.isEditable,
        initialValues: {
          proxyVotingEnabled: event.proxyVotingEnabled,
          proxy: event.proxy,
        },
        onSubmit: onEventUpdate,
      };

    case 'invitees': {
      const updateInvitees = (data) => {
        dispatch({ type: 'fetchInvitees', data });
        // @todo revisit this workaround, which was needed because the updated state meeting becomes available
        // after the final data for invitees is returned
        const newState = reducer({ meeting: event }, { type: 'fetchInvitees', data });
        const { invitees } = newState.meeting;
        return invitees;
      };
      const inviteesUrl = `${meetingUrlBase}invitees/`;

      return {
        invitees: event.invitees,
        noOfInvitees: event.noOfInvitees,
        onSort: (column, order) => axios
          .get(getApiUrl(inviteesUrl, { ...inviteesPageSize, order, order_by: column }))
          .then(({ data: { results } }) => updateInvitees(results))
          .catch(console.error),
        onSearch: ({ searchString }) => axios
          .get(getApiUrl(inviteesUrl, { ...inviteesPageSize, search: searchString }))
          .then(({ data: { results } }) => updateInvitees(results))
          .catch(console.error),
        onFilter: (filters, column, order) => {
          const queryParams = new URLSearchParams({ ...inviteesPageSize, order, order_by: column });
          filters.map((filter) => queryParams.append('filter', filter));
          return axios
            .get(getApiUrl(inviteesUrl, queryParams.toString()))
            .then(({ data: { results } }) => updateInvitees(results))
            .catch(console.error);
        },
      };
    }

    case 'assignedRoles':
      return {
        initialValues: {
          chairman: event.chairman,
          secretary: event.secretary,
        },
        isEditable: event.isEditable,
        onSubmit: onEventUpdate,
      };

    case 'documents': {
      const { isSecretary, isChairman, isOwner } = event.user;
      const allowedRoles = isSecretary || isChairman || isOwner;

      const updatedDocuments = event.documents.map((document) => ({
        ...document,
        isEditable: document.status === 'DRA' && document.docType === 'FU' && allowedRoles,
      }));

      const documentsBasePath = `${meetingUrlBase}documents/`;

      const docActions = updatedDocuments.length ? {
        onDocumentAdd: (values, actions) => onDocumentAdd({ basePath: documentsBasePath, values, actions, dispatch }),
        onDocumentDelete: (_ev, id, setDisabled) => onDocumentDelete({ basePath: documentsBasePath, id, setDisabled, dispatch }),
        onDocumentEdit: (values, actions) => onDocumentEdit({ basePath: documentsBasePath, values, actions, dispatch }),
      } : {
        onSubmit: (values, actions) => onDocumentAdd({ basePath: documentsBasePath, values, actions, dispatch }),
      };

      return {
        documents: updatedDocuments,
        onFileDownload: (id) => onFileDownload(id, event.documents, meetingUrlBase),
        addNewEnabled: getAddNewDocumentEnabled(event, allowedRoles),
        ...docActions,
      };

    }

    case 'introOutro': {

      return {
        ...event.introOutro,
        initialValues: {
          invitationIntro: event.introOutro.invitation.introText,
          invitationOutro: event.introOutro.invitation.outroText,
          minutesIntro: event.introOutro.minutes.introText,
          minutesOutro: event.introOutro.minutes.outroText,
        },
        onSubmit: onEventUpdate,
      };
    }

    default:
      return {};
  }
};
