import { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { CheckboxField, FieldErrors, SubmitButton } from 'core/forms/fields';
import ContentLoadingMessage from 'utils/contentLoadingMessage';
import axios from 'core/axios';
import { parseErrorResponse } from 'core/forms/utils';
import SearchForm from '../documents_archive/searchForm';
import { eventParticipantRoles, eventParticipantRolesValues } from './constants';


const AssignChairmanAndSecretaryForm = ({ companyId, eventId, accountId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [searchParticipantsList, setSearchParticipantsList] = useState([]);
  const [participantsList, setParticipantsList] = useState([]);
  const apiUrl = `/api/v1/company/${companyId}/meetings/${eventId}/assign-chairman-and-secretary/`;

  const fetchParticipantsList = useCallback(() => {
    setIsLoading(true);
    axios.get(apiUrl)
      .then(({ data }) => {
        setSearchParticipantsList(data);
        let defaultParticipants = [];
        if (data.length) {
          defaultParticipants = data.filter((item) => eventParticipantRolesValues.includes(item.role));
        }
        setParticipantsList(defaultParticipants);
      })
      .catch(console.error)
      .finally(() => { setIsLoading(false); });
  }, [apiUrl]);

  useEffect(() => {
    setIsLoading(true);
    fetchParticipantsList();
  }, [fetchParticipantsList]);

  const onFormSubmit = (values, actions) => axios
    .patch(apiUrl.concat('update_participant_role/'), { participants: values.participants })
    .then(
      () => { window.location = `/company/${companyId}/meetings/${eventId}/accounts/${accountId}/invitees/`; },
      ({ response: { data: errors } }) => {
        parseErrorResponse(errors, actions.setFieldError);
      },
    )
    .catch(console.error)
    .finally(() => { actions.setSubmitting(false); });

  const onSubmit = ({ searchBox }, { setSubmitting }) => {
    setSubmitting(false);
    setIsLoading(true);
    const searchFetchUrl = apiUrl.concat(`?name=${searchBox}`);
    axios.get(searchFetchUrl)
      .then(({ data }) => { setSearchParticipantsList(data); })
      .catch(console.error)
      .finally(() => { setIsLoading(false); });
  };
  return (
    searchParticipantsList.length ? (
      <>
        <SearchForm onSubmit={onSubmit} />
        <Formik
          enableReinitialize
          initialValues={{
            participants: participantsList,
            nonFieldErrors: '',
          }}
          onSubmit={onFormSubmit}
          validationSchema={Yup.object().shape({
            participants: Yup.array()
              .of(
                Yup.object().shape({
                  id: Yup.number(),
                  legal_entity_name: Yup.string(),
                  role: Yup.string().length(2),
                }),
              ),
          })}
        >
          {({ values, isSubmitting }) => (
            <Form>
              <FieldErrors name="nonFieldErrors" />
              <FieldArray
                name="participants"
                render={(arrayHelpers) => (
                  <div>
                    {searchParticipantsList.map((participant, index) => {
                      const participantIndex = values.participants.indexOf(
                        values.participants.find((item) => item.id === participant.id),
                      );
                      return (
                        <div key={participant.id} className="bottom-margin">
                          <FieldErrors name={`${participant.id}_${participant.role}`} />
                          <div className="cell small-4">{participant.legal_entity_name}</div>
                          {eventParticipantRoles.map((option) => (
                            <CheckboxField
                              key={option.value}
                              id={`${option.value}_${participant.id}`}
                              name={`accountPurposes[${index}].role`}
                              value={option.value}
                              label={option.label}
                              checked={participantIndex >= 0 ? values.participants.find(
                                (item) => item.id === participant.id,
                              ).role === option.value : false}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  if (participantIndex >= 0) {
                                    arrayHelpers.remove(participantIndex);
                                  }
                                  arrayHelpers.push(
                                    {
                                      id: participant.id,
                                      legal_entity_name: participant.legal_entity_name,
                                      role: option.value,
                                    },
                                  );
                                } else {
                                  arrayHelpers.remove(participantIndex);
                                }
                              }}
                            />
                          ))}
                        </div>
                      );
                    })}
                  </div>
                )}
              />
              <SubmitButton disabled={isSubmitting}>{gettext('Save')}</SubmitButton>
            </Form>
          )}
        </Formik>
      </>
    ) : (
      <ContentLoadingMessage
        isLoading={isLoading}
        loadedMessage={gettext('No participants found')}
      />
    )
  );
};

AssignChairmanAndSecretaryForm.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  eventId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  accountId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default AssignChairmanAndSecretaryForm;
