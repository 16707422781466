import { useReducer, useEffect, useMemo } from 'react';

import axios from 'core/axios';
import PendingUnsignedTransactionModal from 'core/page/transactionSigning/parts/pendingUnsignedTransactionModal';
import { UnsignedTransactionContext } from 'core/page/transactionSigning/context';
import { getGlobalContext } from 'core/globals';
import TransactionSigningModal from 'core/page/transactionSigning/parts/transactionSigningModal';
import reducer from './reducer';

const UnsignedTransaction = () => {
  const {
    unsignedTransaction: {
      enabled: unsignedTrxEnabled,
      hash: requestHash,
    },
  } = getGlobalContext();
  const url = new URL(window.location.href);
  const urlTxHash = url.searchParams.get('unsigned_tx_hash');
  const [state, dispatch] = useReducer(reducer, {
    redirect: {},
    baseTxInfo: {
      unsignedHash: requestHash || urlTxHash,
    },
    modalState: {
      isPendingOpen: !!unsignedTrxEnabled && !!requestHash && !urlTxHash,
      isSigningOpen: !!unsignedTrxEnabled && !!urlTxHash,
    },
  });
  window.trustwise.unsignedTransaction.signingDispatch = dispatch;

  const { baseTxInfo: { unsignedHash }, modalState } = state;
  const baseTrxUrl = unsignedHash ? `/api/v1/ethereum/transactions/${unsignedHash}/` : '';

  useEffect(() => {
    if (unsignedTrxEnabled && baseTrxUrl) {
      axios
        .get(baseTrxUrl)
        .then(({ data }) => dispatch({ type: 'fetch', data }))
        .catch(console.error);
    }
  }, [unsignedTrxEnabled, baseTrxUrl]);

  useEffect(() => {
    if (!window.trustwise.twex.getUnsignedTransaction) {
      window.trustwise.twex.getUnsignedTransaction = () => {
        if (Object.values(modalState).includes(true) || !!unsignedHash || !unsignedTrxEnabled) {
          return;
        }
        axios
          .get('/api/v1/ethereum/transactions/?is_signed=0')
          .then(({ data }) => {
            if (!data.length) { return; }
            dispatch({ type: 'fetch_unsigned', data: data[0] });
          })
          .catch(console.error);
      };
    }
  }, [dispatch, unsignedHash, modalState, unsignedTrxEnabled]);

  const contextValue = useMemo(() => ({ ...state, dispatch, baseTrxUrl }), [state, baseTrxUrl]);

  return (
    <UnsignedTransactionContext.Provider value={contextValue}>
      <PendingUnsignedTransactionModal />
      <TransactionSigningModal />
    </UnsignedTransactionContext.Provider>
  );
};

export default UnsignedTransaction;
