/* Document edit component */
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import FormErrors from 'components/common/formikErrors';
import { getApiUrl } from 'utils/urls';
import { SubmitButton } from 'core/forms/fields';
import ContentLoadingMessage from 'utils/contentLoadingMessage';
import { PageContent } from 'core/page';
import axios from 'core/axios';
import { parseErrorResponse } from 'core/forms/utils';
import DocumentUpload from './upload';


const DocumentEditComponent = ({ state, dispatch }) => {
  const contentElement = document.querySelector('.app-container');

  const navigate = useNavigate();
  const { documentId } = useParams();
  const { search } = useLocation();
  const { documents, isEditable } = state;
  const doc = (documents && documents[documentId]) ? documents[documentId] : {};

  const onSubmit = (values, actions) => {
    const filesToKeep = Object.values(doc.files).filter(
      (documentFile) => values.files.some((valuesFile) => valuesFile.id === documentFile.id),
    );
    const data = new FormData();
    filesToKeep.map(({ id }) => data.append('kept_files_ids', id));
    data.append('doc_date', values.date);
    data.append('subject', values.subject);
    data.append('doc_type', values.documentType);
    values.files.forEach((file) => {
      file instanceof File && data.append('files', file);
    });
    axios.patch(getApiUrl(`/new-documents/${documentId}/edit/`, search), data)
      .then(
        ({ data: responseData }) => {
          dispatch({ data: { doc: responseData, documentId }, type: 'getDetails' });
          navigate(-1);
        },
        ({ response: { data: errors } }) => { parseErrorResponse(errors, actions.setFieldError); },
      )
      .catch(console.error)
      .finally(() => { actions.setSubmitting(false); });
  };

  return createPortal(
    <PageContent pageHeading={gettext('Edit document')}>
      {(isEditable && Object.entries(doc).length > 0) ? (
        <Formik
          initialValues={{
            nonFieldErrors: '',
            files: Object.values(doc.files),
            date: doc.documentDate.toISOString().split('T')[0],
            subject: doc.subject,
            documentType: doc.docType,
          }}
          validationSchema={Yup.object({
            files: Yup.array(),
            date: Yup.string().required('This field is required'),
            subject: Yup.string().required('This field is required'),
            documentType: Yup.string().required('This field is required'),
          })}
          enableReinitialize
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <ErrorMessage component={FormErrors} name="nonFieldErrors" />
              <DocumentUpload />
              <SubmitButton disabled={isSubmitting}>{gettext('Save')}</SubmitButton>
            </Form>
          )}
        </Formik>
      ) : (
        <ContentLoadingMessage
          isLoading={false}
          loadedMessage={gettext('Document not found.')}
        />
      )}
    </PageContent>,
    contentElement,
  );
};

DocumentEditComponent.defaultProps = {
  isEditable: false,
};

DocumentEditComponent.propTypes = {
  isEditable: PropTypes.bool,
  state: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default DocumentEditComponent;
