import PropTypes from 'prop-types';

import { convertKeysToCamelCase } from 'core/utils';
import { LegalEntityModel } from './legalEntity';

export const PENDING = 'PEN';
export const CONFIRMED = 'CON';
export const REJECTED = 'REJ';
export const REVOKED = 'REV';

export const allStatuses = {
  [PENDING]: gettext('Pending'),
  [CONFIRMED]: gettext('Confirmed'),
  [REJECTED]: gettext('Rejected'),
  [REVOKED]: gettext('Revoked'),
};


export class ContactsModel {
  constructor({
    id = null,
    createdAt = '',
    legalEntity = new LegalEntityModel({}),
    requestedBy = new LegalEntityModel({}),
    requestedEmail = '',
    requestedPurposes = [],
    allowedPortfolios = [],
    status = '',
  }) {
    this.id = id;
    this.createdAt = createdAt ? new Date(createdAt) : null;
    this.legalEntity = new LegalEntityModel(convertKeysToCamelCase(legalEntity || {}));
    this.requestedBy = new LegalEntityModel(convertKeysToCamelCase(requestedBy || {}));
    this.requestedEmail = requestedEmail;
    this.requestedPurposes = requestedPurposes;
    this.allowedPortfolios = allowedPortfolios;
    this.status = status;
  }

  update(data) { Object.entries(data).forEach(([key, value]) => { this[key] = value; }); }

  static propType = PropTypes.shape({
    id: PropTypes.number,
    createdAt: PropTypes.instanceOf(Date),
    legalEntity: PropTypes.instanceOf(LegalEntityModel),
    requestedBy: PropTypes.instanceOf(LegalEntityModel),
    requestedEmail: PropTypes.string,
    requestedPurposes: PropTypes.arrayOf(PropTypes.string),
    allowedPortfolios: PropTypes.arrayOf(PropTypes.string),
    status: PropTypes.string,
  }).isRequired;

  get statusText() {
    return allStatuses[this.status];
  }

  get isAnonymous() {
    return [PENDING, REJECTED].includes(this.status);
  }

}
