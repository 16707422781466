import { createContext } from 'react';

interface ContactsContextProps {
  /**
   * Index path of the contacts route
   */
  indexPath: string;
}

export const ContactsContext = createContext<ContactsContextProps>({} as ContactsContextProps);
