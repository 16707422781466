import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { getUrl } from 'utils/urls';
import { FullPageView } from 'core/page';
import { RegistriesListView, RegistryDetailView, RegistryMemberDetailView } from './views';

const RegistriesView = () => (
  <FullPageView>
    <Router basename={getUrl('/registries/')}>
      <Routes>
        <Route path="/">
          <Route index element={<RegistriesListView />} />
          <Route path=":registryId/">
            <Route index element={<RegistryDetailView />} />
            <Route path="members/:memberId/" element={<RegistryMemberDetailView />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  </FullPageView>
);

export default RegistriesView;
