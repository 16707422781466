import { camelCase, startCase } from 'lodash';
import marked from 'marked';

import { SimpleLegalEntityModel } from 'contacts/models/legalEntity';
import { convertKeysCase } from 'core/utils';
import { SimpleDocumentModel } from 'media/models/document';
import { Model } from 'core/model';

class TransactionPriceInfoModel {
  constructor({
    finalPrice,
    isReserved,
    reservationContext,
  }) {
    this.price = parseFloat(finalPrice);
    this.isReserved = isReserved;
    this.reservationContext = reservationContext;
  }
}

/**
 * Transaction Model
 *
 * Props under restProps are:
 * description, details
 *
 */
export default class TransactionModel extends Model {
  constructor({
    documents,
    legalEntity,
    technicalDetails,
    batch,
    priceInfo,
    title,
    description,
    createdAt,
    ...restProps
  }) {
    super(restProps);
    this.documents = SimpleDocumentModel.fromArrayResponse(documents);
    this.signingAccount = new SimpleLegalEntityModel(convertKeysCase(legalEntity, camelCase));
    this.technicalDetails = convertKeysCase(technicalDetails, camelCase);
    this.batch = batch ? convertKeysCase(batch, camelCase) : null;
    this.priceInfo = priceInfo ? new TransactionPriceInfoModel(convertKeysCase(priceInfo, camelCase)) : null;
    this.title = title ? startCase(title) : gettext('Confirm transaction');
    this.description = marked(description);
    this.createdAt = new Date(createdAt);
  }
}
