import { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { snakeCase } from 'lodash';
import * as Yup from 'yup';

import axios from 'core/axios';
import { CheckboxField, FileField, SubmitButton } from 'core/forms/fields';
import FormRouter from 'core/forms/formRouter';
import { parseErrorResponse } from 'core/forms/utils';


const PersonalVerificationForm = () => {

  const [verifiers, setVerifiers] = useState([]);
  useEffect(() => {
    axios.get('/api/v1/registration/verifiers/')
      .then(({ data }) => { setVerifiers(data); })
      .catch(console.error);
  }, []);

  const handleSubmit = (values, actions) => {
    const documentsData = new FormData();
    documentsData.append('registering_other_person', values.registeringOtherPerson);
    ['identityFiles', 'selfieFiles', 'poaFiles'].forEach((fieldName) => {
      values[fieldName].forEach((file) => { documentsData.append(snakeCase(fieldName), file); });
    });
    axios.post('/api/v1/registration/identification/', documentsData)
      .then(
        () => { window.location.href = '/'; },
        ({ responseJSON }) => {
          actions.setSubmitting(false);
          parseErrorResponse(responseJSON, actions.setFieldError);
        },
      )
      .catch((error) => {
        console.error(error);
        actions.setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={{
        identityFiles: [],
        selfieFiles: [],
        poaFiles: [],
        registeringOtherPerson: false,
        // @todo avoid the need to set nonFieldErrors here, consider formik status:
        // https://jaredpalmer.com/formik/docs/api/formik#setstatus-status-any-void
        nonFieldErrors: '',

      }}
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={Yup.object({
        registeringOtherPerson: Yup.bool(),
        identityFiles: Yup.array().of(Yup.mixed()).required(gettext('This field is required')),
        selfieFiles: Yup.array().of(Yup.mixed()).required(gettext('This field is required')),
        poaFiles: Yup.array().of(Yup.mixed())
          .when('registeringOtherPerson', {
            is: true,
            then: Yup.array().of(Yup.mixed()).required(gettext('This field is required')),
            otherwise: Yup.array(),
          }),
      })}
    >
      {({ isValid, dirty, errors, isSubmitting, values, setFieldValue }) => {

        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          !values.registeringOtherPerson && setFieldValue('poaFiles', []);
        }, [setFieldValue, values.registeringOtherPerson]);

        const steps = [
          {
            pathName: '',
            component: (
              <>
                <p className="text-smaller padded-y">
                  {gettext('Please upload photos of the following documents '
                  + '(taken not earlier than 30 minutes before submission)')}
                </p>
                <FileField name="identityFiles" label={gettext('1. Photo of Passport/ID')} maxFiles={1} />
                <FileField className="margin-bottom" name="selfieFiles" label={gettext('2. Selfie')} maxFiles={1} />
                <br />
                <CheckboxField
                  label={gettext('I am registering for other person')}
                  name="registeringOtherPerson"
                />
                {values.registeringOtherPerson && (
                  <FileField name="poaFiles" label={gettext('3. Power of Attorney')} maxFiles={1} />
                )}
                {!!verifiers.length && (
                  <p className="text-smaller top-margin">
                    {gettext(`The account will be verified within 24h by ${verifiers[0].name}.`)}
                  </p>
                )}
              </>
            ),
          },
        ];

        return (
          <Form className="limited-width">
            <FormRouter
              steps={steps}
              basePath="/identification/"
              formProps={{ dirty, errors }}
              submitButton={(
                <SubmitButton disabled={!isValid || isSubmitting} className="top-margin" />
              )}
            />
          </Form>
        );

      }}
    </Formik>
  );

};


export default PersonalVerificationForm;
