import { useField } from 'formik';

interface HiddenInputProps {
  /**
   * The ID of the field
   */
  id?: string;
  /**
   * The name of the field
   */
  name: string;
  /**
   * Indicates whether to show portfolios
   */
  withPortfolios?: boolean;
  /**
   * Indicates whether the field is optional
   */
  isOptional: boolean;
}

/**
 * Hidden input field, which is used inside the ContactSelectFieldForm component
 * and allows django form to get the value of the selected contact
 * because the select contact field doesn't have an associated input
 */
export const HiddenInput = ({
  id,
  name,
  withPortfolios,
  isOptional,
}: HiddenInputProps) => {
  const [{ value }] = useField(name);

  let inputValue = '';
  if (value) {
    inputValue = withPortfolios ? value.portfolio : value.id;
  }

  return (
    <input
      id={id || name}
      name={name}
      required={!isOptional}
      value={inputValue}
      readOnly
      className="hidden"
    />
  );
};
