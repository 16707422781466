/* A generic list. */

import PropTypes from 'prop-types';
import Row from './rows/row';

const List = ({
  className,
  collection,
  isLoading,
  loadingText,
  emptyText,
  rowTitle,
  rowLink,
  RowComponent,
  rowKey,
  ...props
}) => {
  let classNames = 'padded-top';
  classNames += className ? ` ${className}` : '';
  return (
    <>
      <ul className={classNames}>
        {collection.map((item) => (
          <li key={rowKey(item) || item.id}>
            <RowComponent item={item} title={rowTitle} rowLink={rowLink} {...props} />
          </li>
        ))}
      </ul>
      {!collection.length && (
      <p className="text-center">
          {isLoading ? loadingText : emptyText}
      </p>
      )}
    </>
  );
};

List.defaultProps = {
  className: '',
  collection: [],
  isLoading: true,
  loadingText: gettext('Loading items...'),
  emptyText: gettext('No items'),
  rowTitle: () => {},
  rowLink: () => {},
  RowComponent: Row,
  rowKey: () => { },
};

List.propTypes = {
  collection: PropTypes.arrayOf(PropTypes.shape()),
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  loadingText: PropTypes.string,
  emptyText: PropTypes.string,
  rowTitle: PropTypes.func,
  rowLink: PropTypes.func,
  RowComponent: PropTypes.func,
  rowKey: PropTypes.func,
};

export default List;
