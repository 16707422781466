import PropTypes from 'prop-types';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import { FullPageView } from 'core/page';
import PotentialShareholdersWhitelists from './list';
import WhitelistMembers from './members/list';
import FilterBasedList from './filterBasedList';
import AddMember from './members/add';
import AddWhitelist from './add';


const ShareholdersWhitelistsNavigation = ({ companyId }) => (
  <FullPageView>
    <Router basename={`/company/${companyId}/ownership/potential-shareholders/`}>
      <Routes>
        <Route path="/">
          <Route index element={<PotentialShareholdersWhitelists companyId={companyId} />} />
          <Route path="add/" element={<AddWhitelist companyId={companyId} />} />
          <Route path="filters-set-list/" element={<FilterBasedList />} />
          <Route path=":listId/members/">
            <Route index element={<WhitelistMembers companyId={companyId} />} />
            <Route path="add/" element={<AddMember companyId={companyId} />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  </FullPageView>
);

ShareholdersWhitelistsNavigation.propTypes = {
  companyId: PropTypes.string.isRequired,
};

export default ShareholdersWhitelistsNavigation;
