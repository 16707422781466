/* eslint-disable no-underscore-dangle */
import { extendObservable } from 'mobx';
import uuid from 'uuid';
import axios from 'core/axios';
import BaseForm from '../form';
import DocumentFile from './file';


export default class DocumentForm extends BaseForm {
  constructor({
    documentFiles = [],
    id = null,
    date = new Date(),
    subject = '',
    uid = null,
    defaultDocumentType = 'FU',
    isFinalized = false,
    documentObject = {},
  }) {
    super();
    this.uid = uid || uuid.v4();
    this.id = id;

    extendObservable(this, {
      documentFiles: documentFiles.map((fileHash) => new DocumentFile({ ...fileHash })),
      subject,
      _day: null,
      _month: null,
      _year: null,
      defaultDocumentType,
      documentObject,
      isFinalized,
    });

    this.setDate(date);

    this.setDocumentObject = this.setDocumentObject.bind(this);
    this.setDocumentType = this.setDocumentType.bind(this);
    this.addFile = this.addFile.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.setDate = this.setDate.bind(this);
    this.setSubject = this.setSubject.bind(this);
    this.successUploadCallback = this.successUploadCallback.bind(this);
    this.failUploadCallback = this.failUploadCallback.bind(this);
    this.finalize = this.finalize.bind(this);
    this.upload = this.upload.bind(this);
  }

  static get formFields() {
    return ['subject', 'files', 'defaultDocumentType'];
  }

  static get fieldMapping() {
    return [
      { name: 'subject', serverName: 'subject' },
      { name: 'companyId', serverName: 'company_id' },
      { name: 'defaultDocumentType', serverName: 'document_type' },
      { name: 'files', servName: 'files' },
    ];
  }

  setDocumentObject(val = {}) {
    this.documentObject = val;
  }

  setDocumentType(val) {
    this.defaultDocumentType = val;
  }

  get date() {
    return `${this._year}-${this._month}-${this._day}`;
  }

  addFile(fileObject) {
    this.documentFiles.push(
      new DocumentFile({
        file: fileObject,
        name: fileObject.name,
      }),
    );
  }

  // eslint-disable-next-line class-methods-use-this
  getFileRemovalUrl(_docId) {
    throw new Error('should be overwritten');
  }

  removeFile(documentFile) {
    const searchedIndex = this.documentFiles.findIndex((doc) => doc.uid === documentFile.uid);
    if (searchedIndex >= 0) {
      const doc = this.documentFiles[searchedIndex];
      if (doc.isPersisted) {
        axios.delete(this.getFileRemovalUrl(doc.id))
          .then(() => { this.documentFiles.splice(searchedIndex, 1); })
          .catch(console.error);
      } else {
        this.documentFiles.splice(searchedIndex, 1);
      }
    }
  }

  setDate(date) {
    if (!date) return;

    let newDate = date;

    if (typeof (date) === 'string') {
      newDate = new Date(date);
    }

    if (newDate.getDate()) {
      this._day = (`0${newDate.getDate()}`).slice(-2);
    }

    if (newDate.getYear()) {
      this._year = (`000${newDate.getFullYear()}`).slice(-4);
    }

    if (newDate.getMonth() || newDate.getMonth() === 0) {
      this._month = (`0${newDate.getMonth() + 1}`).slice(-2);
    }
  }

  setSubject(subject) {
    this.subject = subject;
  }

  // eslint-disable-next-line class-methods-use-this
  get uploadUrl() {
    throw new Error('should be overwritten');
  }

  // eslint-disable-next-line class-methods-use-this
  successUploadCallback(successResponse) {
    return successResponse;
  }

  failUploadCallback(failResponse) {
    this.setResponseErrors(failResponse);
  }

  finalize() {
    this.setProperty('isFinalized', true);
    return this.upload();
  }

  get formData() {
    const data = new FormData();
    this.documentFiles.filter((d) => !d.isPersisted).forEach((doc) => {
      data.append('files', doc.file);
    });

    data.append('date', this.date);
    data.append('subject', this.subject);

    if (this.defaultDocumentType) data.append('document_type', this.defaultDocumentType);

    data.append('is_finalized', this.isFinalized);

    return data;
  }

  upload() {
    const method = this.isPersisted ? 'patch' : 'post';
    return axios[method](this.uploadUrl, this.formData)
      .then((response) => {
        if (!this.isPersisted) {
          this.id = response.id;
        }
        return this.successUploadCallback(response);
      })
      .catch((e) => this.failUploadCallback(e));
  }
}
