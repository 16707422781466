import { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'core/axios';
import { getApiUrl } from 'utils/urls';
import PricingWidget from 'pricing/base';
import { icons } from 'core/icons';
import reducer from 'pricing/reducer';

const PricingInfo = ({ isCompany }) => {
  const [state, dispatch] = useReducer(reducer, { pricingPlans: [] });

  useEffect(() => {
    axios(getApiUrl('/pricing/plans/'))
      .then(({ data }) => { dispatch({ data, type: 'fetch' }); })
      .catch(console.error);
  }, []);

  return (
    <>
      <h3>{gettext('Your pricing plan')}</h3>
      <div className="registration-pricing-info">
        <div className="pricing-plan-tiles">
          {state.pricingPlans[0] && (
            <PricingWidget
              key={state.pricingPlans[0].order}
              pricingPlan={state.pricingPlans[0]}
              isCompany={isCompany}
            />
          )}
        </div>
        {isCompany && (
          <div className="tile-component">
            <div className="tile-header">
              <div className="tile-label-with-icon">
                {icons.info}
                <span className="label">{gettext('Corporate Users')}</span>
              </div>
            </div>
            <div className="pricing-plan-tile-content pricing-plan-tile-sub-header">
              {gettext(
                'To issue ledger-based securities and use our corporate governance features'
                + ' upgrade your price plan later',
              )}
            </div>
          </div>
        )}
        <p className="info-text">
          {gettext('Price list can be amended from time to time. The terms and conditions from the user agreement apply.')}
        </p>
      </div>
    </>
  );
};

PricingInfo.defaultProps = {
  isCompany: false,
};

PricingInfo.propTypes = {
  isCompany: PropTypes.bool,
};

export default PricingInfo;
