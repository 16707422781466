/* Add and edit a whitelist */
import PropTypes from 'prop-types';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { CheckboxField, TextField, SubmitButton, ReactSelectField } from 'core/forms/fields';
import { parseErrorResponse } from 'core/forms/utils';
import axios from 'core/axios';
import { PageContent } from 'core/page';
import FormErrors from 'components/common/formikErrors';
import BackButton from 'core/page/parts/backButton';


const AddWhitelist = ({ companyId }) => {
  const whitelistsTypes = [
    { id: 'PP', text: gettext('Private placement') },
    { id: 'RO', text: gettext('Restricted public offerings'), disabled: true },
  ];
  const redirectUrl = `/company/${companyId}/ownership/potential-shareholders/`;

  const onSubmit = (values, actions) => {
    const url = `/api/v1/company/${companyId}/ownership/potential-shareholders/`;
    const data = {
      name: values.name,
      from_existing_shareholders: values.fromExistingShareholders,
    };
    axios.post(url, data)
      .then(
        () => { window.location = redirectUrl; },
        ({ response: { data: errors } }) => {
          parseErrorResponse(errors, actions.setFieldError);
          actions.setSubmitting(false);
        },
      )
      .catch((error) => {
        console.error(error);
        actions.setSubmitting(false);
      });
  };

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Add whitelist')}
      className="top-margin"
      headerLeft={<BackButton href="/" useRouterLink />}
    >
      <Formik
        initialValues={{
          nonFieldErrors: '',
          listType: 'PP',
          name: '',
          fromExistingShareholders: false,
        }}
        validationSchema={Yup.object({
          listType: Yup.string().matches(/(PP)/).required('This field is required'),
          name: Yup.string().required('This field is required'),
        })}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            <ErrorMessage component={FormErrors} name="nonFieldErrors" />
            <ReactSelectField
              label={gettext('List type')}
              name="listType"
              getOptionLabel={(option) => option.text}
              getOptionValue={(option) => option.id}
              options={whitelistsTypes}
              isOptionDisabled={(option) => option.disabled === true}
            />
            <TextField label={gettext('Name')} name="name" />
            <CheckboxField
              label={gettext('Add existing shareholders to this list')}
              name="fromExistingShareholders"
            />
            <SubmitButton disabled={isSubmitting}>{gettext('Save')}</SubmitButton>
          </Form>
        )}
      </Formik>
    </PageContent>
  );
};


AddWhitelist.propTypes = {
  companyId: PropTypes.string.isRequired,
};

export default AddWhitelist;
