/* eslint-disable react/no-multi-comp */
/* eslint-disable react/prop-types */
import { Component, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import filesize from 'filesize';

import { mediaTypes, imageTypes, iconNames } from 'media/constants/fileTypes';

export default observer(class Document extends Component {
  removeButton = () => {
    const { model, remove } = this.props;

    if (!remove) {
      return null;
    }

    return (
      <button
        type="button"
        onClick={() => remove(model)}
        className="action inline-block primary-color"
      >
        {gettext('Remove')}
      </button>
    );
  };

  render() {
    const { model } = this.props;
    let fileTypeDisplay = '';
    if (model.file && mediaTypes.length > 0 && model.file.type.length > 0) {
      const mediaType = mediaTypes.find((option) => option.id === model.file.type);
      if (mediaType) {
        fileTypeDisplay = mediaType.text;
      }
    }

    return (
      <div className="file-content-container">
        <div className="file"><ImagePreview src={model.source} /></div>
        <div className="file-info">
          <div className="file-name">{model.name}</div>
          <div className="file-type">{fileTypeDisplay}</div>
          {model.isRemovable ? this.removeButton() : null}
        </div>
        <div className="file-size">{model.file && filesize(model.file.size)}</div>
      </div>
    );
  }
});

export const ImagePreview = ({ src }) => {
  const [loaded, setLoaded] = useState(false);
  const [imageSource, setImageSource] = useState('');
  const [iconClasses, setIconClasses] = useState('');

  useEffect(() => {
    if (!src) {
      setImageSource('');
      return;
    }

    if (imageTypes.includes(src.type)) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setImageSource(e.target.result);
        setLoaded(true);
      };
      reader.readAsDataURL(src);
    } else if (
      typeof src === 'string' &&
      ['jpeg', 'jpg', 'png'].includes(src.split('.').pop().replace('/', ''))
    ) {
      setImageSource(src);
      setLoaded(true);
    } else {
      let fileExtension = '';
      if (src.name) {
        fileExtension = src.name.split('.').pop();
      } else if (typeof src === 'string') {
        fileExtension = src.split('.').pop().replace('/', '');
      }
      const iconName = iconNames.find((option) => option.id === fileExtension.split('%3F')[0]);
      let icon = 'fa-file';
      if (iconName) {
        icon = `fa-file-${iconName.text}`;
      }
      setIconClasses(`far fa-fw icon ${icon}`);
      setImageSource('');
      setLoaded(true);
    }
  }, [src]);

  if (!loaded) {
    return null;
  }

  return (
    <div className="document-attachment-wrapper text-center">
      {imageSource ? <img src={imageSource} alt="" /> : <i className={iconClasses} aria-hidden="true" />}
    </div>
  );
};
