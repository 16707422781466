import { useEffect, useState } from 'react';

import { UnsignedTransactionModal } from '@trustwise/design-system';
import { getGlobalContext } from 'core/globals';
import { isNativeAppWebview } from 'utils/general';
import axios from 'core/axios';
import {
  onCancel,
  onConfirm,
  onFileDownload,
  onSkip,
} from 'core/page/transactionSigning/actions';
import { useUnsignedTransactionContext } from 'core/page/transactionSigning/context';
import { getTxStatus } from 'core/page/transactionSigning/utils';

const TransactionSigningModal = () => {
  const {
    actingPerson: { walletType },
    unsignedTransaction: {
      enabled: unsignedTrxEnabled,
      accountId: requestAccountId,
      accountName: requestAccountName,
    },
  } = getGlobalContext();

  const isCustodialWallet = walletType === 'custodial';
  const [allowSkip, setAllowSkip] = useState(false);
  const [timeoutId, setTimeoutId] = useState();
  const {
    baseTrxUrl, dispatch, txStatus, baseTxInfo: { accountId, accountName }, modalState: { isSigningOpen },
    transactionDetails, nativeSigningUrl, redirect: { url, shouldRedirect },
  } = useUnsignedTransactionContext();

  const { isCompleted, isProcessing } = getTxStatus(txStatus);

  useEffect(() => {
    if (!isSigningOpen && timeoutId) {
      setAllowSkip(false);
      clearTimeout(timeoutId);
    }
  }, [isSigningOpen, timeoutId]);

  useEffect(() => {
    if (unsignedTrxEnabled && baseTrxUrl && !transactionDetails) {
      axios
        .get(baseTrxUrl)
        .then(({ data }) => dispatch({ type: 'fetch', data }))
        .catch(console.error);
    }
  }, [unsignedTrxEnabled, baseTrxUrl, transactionDetails, dispatch]);

  useEffect(() => {
    if (baseTrxUrl && isNativeAppWebview && !isCustodialWallet) {
      axios
        .get(`${baseTrxUrl}signing-url/`)
        .then(({ data }) => dispatch({ type: 'fetch_signing_url', data }))
        .catch(console.error);
    }
  }, [isCustodialWallet, baseTrxUrl, dispatch]);

  useEffect(() => {
    if (isCompleted && !!baseTrxUrl) {
      setTimeout(() => { onSkip(baseTrxUrl, dispatch); }, 2000);
    }
  }, [isCompleted, baseTrxUrl, dispatch]);

  useEffect(() => {
    if (isProcessing) {
      const id = setTimeout(() => { setAllowSkip(true); }, 12000);
      setTimeoutId(id);
    }
  }, [isProcessing]);

  useEffect(() => {
    if (shouldRedirect && url) { window.location = url; }
  }, [shouldRedirect, url]);

  useEffect(() => {
    if (!window.trustwise.twex.setTxAccepted) {
      window.trustwise.twex.setTxAccepted = () => {
        dispatch({ type: 'status_updated', data: 'accepted' });
      };
    }
  }, [dispatch]);

  return (
    <UnsignedTransactionModal
      isOpen={isSigningOpen}
      onClose={isCompleted ? () => onSkip(baseTrxUrl, dispatch) : undefined}
      allowSkip={allowSkip}
      status={txStatus}
      allowSigning={isNativeAppWebview || isCustodialWallet}
      signingAccount={{
        id: requestAccountId || accountId,
        name: requestAccountName || accountName,
      }}
      {...transactionDetails}
      onFileDownload={((fileId) => onFileDownload(fileId, transactionDetails.documents, baseTrxUrl))}
      onConfirm={(_ev, setDisabled) => onConfirm(setDisabled, baseTrxUrl, nativeSigningUrl, dispatch)}
      onSkip={(_ev, setDisabled) => onSkip(baseTrxUrl, dispatch, setDisabled)}
      onCancel={(_ev, setDisabled) => onCancel(baseTrxUrl, dispatch, setDisabled)}
    />
  );
};

export default TransactionSigningModal;
