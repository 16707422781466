import {
  CompanySignatorySignatureType,
  EditSignatoryFormValues,
  MultisigTransactionType,
  MultisigTransactionTypeGroup,
} from 'signatories/types';
import { CompanySignatorySignatureTypes, MultisigTransactionTypeGroups } from 'signatories/consts';

export const getSignatureWeight = (signatureType: CompanySignatorySignatureType) => (
  signatureType === CompanySignatorySignatureTypes.COLLECTIVE ? 50 : 100
);

export const getSignatoryEditData = (values: EditSignatoryFormValues) => {
  const { role, signatureType } = values;

  return ({
    role,
    signature_type: getSignatureWeight(signatureType),
  });
};

export const getMultisigTransactionGroup = (type: MultisigTransactionType): MultisigTransactionTypeGroup => {
  const group: MultisigTransactionTypeGroup | undefined = Object
    .entries(MultisigTransactionTypeGroups)
    .find(([_groupKey, groupTransactions]) => groupTransactions.includes(type))
    ?.[0] as MultisigTransactionTypeGroup;

  return group ?? 'default';
};
