import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useReducer } from 'react';

import { MarketsListView as MarketsListViewDS } from '@trustwise/design-system';
import { PageContent } from 'core/page';
import { listingsReducer } from 'markets/reducers';
import BackButton from 'core/page/parts/backButton';
import { getRouterLinkInteraction } from 'core/utils';
import { fetchListings, onListingsFetch } from './actions';
import { LISTINGS_PAGE_SIZE, ListingsPageTitles } from '../consts';

const MarketListingsView = () => {
  const navigate = useNavigate();
  const { marketType } = useParams();
  const [state, dispatch] = useReducer(listingsReducer, {});
  const { listings, totalListings } = state;

  const basePath = `/markets/${marketType}/`;

  useEffect(() => {
    fetchListings({ basePath, dispatch });
  }, [basePath]);

  return (
    <PageContent
      fullscreen
      pageHeading={gettext(ListingsPageTitles[marketType])}
      headerLeft={<BackButton onClick={() => navigate('/')} />}
    >
      <MarketsListViewDS
        items={listings}
        marketType={marketType}
        // @todo replace this temporary solution for proper marketplace currency once marketplace API is implemented
        marketCurrency={listings && listings.length && listings[0].currency}
        totalCount={totalListings}
        countPerPage={LISTINGS_PAGE_SIZE}
        onItemClick={(tokenId) => getRouterLinkInteraction(`${tokenId}/`)}
        onListingsFetch={(searchString) => onListingsFetch(basePath, { search: searchString })}
        onSearch={(searchString) => fetchListings({ basePath, dispatch, queryArgs: { search: searchString } })}
        onSort={(column, order) => fetchListings({ basePath, queryArgs: { order, order_by: column }, dispatch })}
      />
    </PageContent>
  );
};

export default MarketListingsView;
