import { PropTypes, inject, observer } from 'mobx-react';

import { isNativeAppWebview } from 'utils/general';
import SidebarContent from './sidebarContent';

const AccountSwitcherSidebar = ({ accountSwitcherStore }) => {
  const { setOpen, collection } = accountSwitcherStore;

  return (
    <div>
      <div className="account-switcher-container">
        <SidebarContent
          collection={collection}
          withLogout={!isNativeAppWebview}
        />
      </div>
      <div
        className="account-switcher-overlay"
        onClick={() => { setOpen(false); }}
      />
    </div>
  );
};

AccountSwitcherSidebar.propTypes = {
  accountSwitcherStore: PropTypes.objectOrObservableObject.isRequired,
};

export default inject('accountSwitcherStore')(observer(AccountSwitcherSidebar));
