import { getGlobalContext } from 'core/globals';

export const AllowedCompanyActions = {
  SIGNATORIES: 'signatories',
  DOCUMENTS: 'documents',
  EVENTS: 'events',
  EQUITY: 'equity',
  CASH: 'cash',
  VOTING: 'voting',
  DIVIDENDS: 'dividends',
  SUPERVISORY_BOARD: 'supervisory_board',
  EXECUTIVE_BOARD: 'executive_board',
  LIABILITIES_ISSUE: 'liabilities_issue',
  SHAREHOLDERS_REGISTRY: 'shareholders_registry',
  VERIFIER: 'verifier',
  PREFERRED_VENDOR: 'preferred_vendor',
  PAYMENT_TOKEN: 'payment_token',
  MEMBERSHIP: 'membership',
} as const;

export type AllowedCompanyAction = typeof AllowedCompanyActions[keyof typeof AllowedCompanyActions];

export const isCompanyActionAllowed = (action: AllowedCompanyAction) => {
  const { activeEntity: { allowedActions, isCompany } } = getGlobalContext();
  return isCompany && allowedActions.includes(action);
};
