/* Basic row component */
import PropTypes from 'prop-types';
import { Icon } from 'components/common/icon';


const BasicRow = ({ title, value, className }) => (
  <div className={`row-flex ${className}`}>
    <div>{title}</div>
    <div className="font-bold">{value}</div>
  </div>
);

BasicRow.defaultProps = {
  className: '',
};

BasicRow.propTypes = {
  title: PropTypes.node.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  className: PropTypes.string,
};

const BasicRowWithModal = ({ title, value, iconName, className, onClick }) => (
  <BasicRow
    className={className}
    title={(
      <div>
        {title}
        <Icon
          name={iconName}
          className="text-tiny disabled-blue reduced-padding padded-x"
          onClick={onClick}
        />
      </div>
   )}
    value={value}
  />
);

BasicRowWithModal.defaultProps = {
  iconName: 'info-circle',
  className: '',
};

BasicRowWithModal.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  iconName: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export { BasicRow, BasicRowWithModal };

