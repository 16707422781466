import PropTypes from 'prop-types';
import { getUrl, getApiUrl } from 'utils/urls';
import BaseAddValuation from './baseAdd';


const AddLiabilityValuation = ({ defaultCurrency, tokenId }) => {
  const urlBase = `/liabilities/equity/${tokenId}/valuation/`;

  return (
    <BaseAddValuation
      defaultCurrency={defaultCurrency}
      redirectUrl={getUrl(urlBase)}
      submitUrl={getApiUrl(urlBase)}
    />
  );
};

AddLiabilityValuation.defaultProps = {
  defaultCurrency: '',
};

AddLiabilityValuation.propTypes = {
  defaultCurrency: PropTypes.string,
  tokenId: PropTypes.string.isRequired,
};

export default AddLiabilityValuation;
