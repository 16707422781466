/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';

import { ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import FormErrors from '../../components/common/formikErrors';
import { CheckboxField } from '../../core/forms/fields';


export const validationShareClasses = Yup.object({
  shareClasses: Yup.array().of(Yup.number().positive().integer()),
});

const ShareClassesEditFormPart = ({ allShareClasses, values }) => (
  <>
    <ErrorMessage component={FormErrors} name="nonFieldErrors" />
    <ErrorMessage component={FormErrors} name="shareClasses" />
    <table className="v-align-middle text-tiny">
      <thead>
        <tr>
          <th className="align-text-left borderless not-padded">{gettext('Name')}</th>
          <th className="align-text-right borderless not-padded">{gettext('Nominal Value')}</th>
          <th className="align-text-right borderless not-padded">{gettext('No. of Shares')}</th>
          <th className="align-text-right borderless not-padded hide-for-small-only">{gettext('Total Capital')}</th>
        </tr>
      </thead>
      <tbody>
        <FieldArray
          name="shareClasses"
          render={(arrayHelpers) => (
            <>
              {allShareClasses.map((shareClass) => (
                <tr key={shareClass.id}>
                  <td>
                    <CheckboxField
                      id={shareClass.id}
                      name="shareClasses"
                      value={shareClass.id}
                      label={shareClass.name}
                      checked={values.shareClasses.includes(shareClass.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          arrayHelpers.push(shareClass.id);
                        } else {
                          const index = values.shareClasses.indexOf(shareClass.id);
                          arrayHelpers.remove(index);
                        }
                      }}
                    />
                  </td>
                  <td className="align-text-right">
                    {`${shareClass.currency} ${shareClass.nominal_value}`}
                  </td>
                  <td className="align-text-right">
                    {shareClass.total_supply}
                  </td>
                  <td className="align-text-right hide-for-small-only">
                    {`${shareClass.currency} ${shareClass.total_capital}`}
                  </td>
                </tr>
              ))}
            </>
          )}
        />
      </tbody>
    </table>
  </>
);


ShareClassesEditFormPart.defaultProps = {
  allShareClasses: [],
  values: {},
};

ShareClassesEditFormPart.propTypes = {
  allShareClasses: PropTypes.arrayOf(PropTypes.shape()),
  values: PropTypes.shape(),
};

export default ShareClassesEditFormPart;
