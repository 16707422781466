import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { camelCase } from 'lodash';
import { AddContactsModal } from '@trustwise/design-system';

import axios from 'core/axios';
import { getApiUrl } from 'utils/urls';

const AddNewContacts = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const onSubmit = (values, { setSubmitting, setStatus, setFieldError }) => {
    axios.post(getApiUrl('/contacts/create-multiple/'), values.emails.map((item) => ({ email: item })))
      .then(
        () => { setStatus({ newRequestSent: true }); },
        ({ response: { data: errors } }) => {
          const emailsErrors = [];
          errors && Object.keys(errors).forEach((key) => {
            if (key === 'email') {
              emailsErrors.push(errors[key].join(' '));
            } else {
              setFieldError(camelCase(key), errors[key].join(' '));
            }
          });
          setFieldError('emails', emailsErrors);
          setSubmitting(false);
        },
      )
      .catch(console.error);
  };

  return (
    <AddContactsModal
      name="emails"
      isOpen={isOpen}
      validationSchema={Yup.object({
        emails: Yup.array().of(
          Yup.string().email(gettext('Please enter a valid email address')),
        ).min(1, gettext('Email is required')),
      })}
      showInputLabel={false}
      onSubmit={onSubmit}
      onClose={() => {
        onClose();
        navigate(pathname, { replace: true });
      }}
      infoMessage={gettext('You can manage your consent under Account -> Contacts')}
    />
  );
};


AddNewContacts.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddNewContacts;
