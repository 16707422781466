import {
  DataRoomAllowedAction,
  DataRoomPermissionType,
  DataRoomStatus,
  DataRoomType,
  DataRoomStatuses,
  DataRoomTypes,
} from '@trustwise/design-system';
import { getGlobalContext } from 'core/globals';
import { BaseLegalEntityWithID } from 'contacts/interfaces/types';
import { Model } from 'core/model';
import { SimpleLegalEntityModel } from 'contacts/models/legalEntity';
import { DataRoomSchema } from '../types';

interface BaseDataRoomItemModelProps {
  /**
   * Data room ID
   */
  id: number;
  /**
   * Data room name
   */
  name: string;
  /**
   * Data room status
   */
  status: DataRoomStatus;
  /**
   * Indicates whether the data room is private
   */
  isPrivate: boolean;
  /**
   * Array of permission types granted to the current entity
   */
  currentEntityPermissions: DataRoomPermissionType[];
}

interface DataRoomItemModelProps extends BaseDataRoomItemModelProps {
  /**
   * Data room owner
   */
  owner: BaseLegalEntityWithID;
  /**
   * Date time in which the data room was opened
   */
  openedAt: string;
  /**
   * Date time in which the data room was closed
   */
  closedAt?: string;
}

export interface IDataRoomItemModelProps extends BaseDataRoomItemModelProps {
  /**
   * Date time in which the data room was closed
   */
  closedAt?: Date;
  /**
   * Date time in which the data room was opened
   */
  openedAt?: Date;
  /**
   * Data room owner
   */
  owner: SimpleLegalEntityModel;
}

export class DataRoomItemModel extends Model implements IDataRoomItemModelProps {
  closedAt?: Date;
  openedAt?: Date;
  owner: SimpleLegalEntityModel;
  status: DataRoomStatus;
  id: number;
  name: string;
  isPrivate: boolean;
  currentEntityPermissions: DataRoomPermissionType[];

  constructor({
    closedAt, openedAt, owner, ...restProps
  }: DataRoomItemModelProps) {
    super(restProps);
    this.closedAt = closedAt ? new Date(closedAt) : undefined;
    this.openedAt = openedAt ? new Date(openedAt) : undefined;
    this.owner = SimpleLegalEntityModel.fromResponse(owner);
  }

  get isClosed() {
    return this.status === DataRoomStatuses.CLOSED;
  }

  get isOwner() {
    const { activeEntity: { id: activeEntityId } } = getGlobalContext();
    return this.owner.id === activeEntityId;
  }

  get accessRole() {
    return this.isOwner ? 'owner' : this.currentEntityPermissions[0];
  }
}

interface BaseDataRoomDetailModelProps {
  /**
   * Data room description
   */
  description?: string;
  /**
   * Data room contract address
   */
  contractAddress: string;
  /**
   * Data room external access token
   */
  externalAccessToken?: string;
  /**
   * Data room type
   */
  roomType: DataRoomType;
  /**
   * Indicates whether verifiers are enabled for the data room
   */
  verifiersEnabled: boolean;
  /**
   * Indicates whether external readers are enabled for the data room
   */
  externalReadersEnabled: boolean;
  /**
   * Indicates whether contributors are enabled for the data room
   */
  contributorsEnabled: boolean;
  /**
   * Indicates whether devices are enabled for the data room
   */
  devicesEnabled: boolean;
  /**
   * Allowed actions for the data room
   */
  allowedActions: DataRoomAllowedAction[];
}

interface DataRoomDetailModelProps extends DataRoomItemModelProps, BaseDataRoomDetailModelProps { }

export interface IDataRoomDetailModelProps extends IDataRoomItemModelProps, BaseDataRoomDetailModelProps { }

export class DataRoomDetailModel extends DataRoomItemModel implements IDataRoomDetailModelProps {
  id: number;
  name: string;
  type: DataRoomType;
  externalAccessToken?: string;
  isPrivate: boolean;
  description?: string | undefined;
  contractAddress: string;
  roomType: DataRoomType;
  verifiersEnabled: boolean;
  externalReadersEnabled: boolean;
  contributorsEnabled: boolean;
  devicesEnabled: boolean;
  currentEntityPermissions: DataRoomPermissionType[];
  allowedActions: DataRoomAllowedAction[];
  schema: DataRoomSchema[];

  constructor({
    name, roomType, externalAccessToken, allowedActions, ...restProps
  }: DataRoomDetailModelProps) {
    super({ name, ...restProps });
    this.name = name;
    this.type = roomType;
    this.externalAccessToken = externalAccessToken;
    this.allowedActions = allowedActions;
  }

  get link() {
    if (!this.externalAccessToken) {
      return '';
    }
    return `${window.location.origin}/data-rooms/external-access/data-sets/?key=${this.externalAccessToken}`;
  }

  get isRoomTypeDocuments() {
    return this.type === DataRoomTypes.DOCUMENTS;
  }
}

export interface ReaderAccessLogDataSetProps {
  /**
   * Accessed data set ID
   */
  id: number;
  /**
   * Accessed data set name
   */
  name: string;
  /**
   * Date time in which the data set was created
   */
  createdAt: Date;
}

interface DataRoomAccessLogModelProps {
  /**
   * Data set
   */
  dataSet: ReaderAccessLogDataSetProps;
  /**
   * Date time in which access to data set was requested
   */
  requestedAt: Date;
  /**
   * Legal entity (company) that requested access to the data set
   */
  reader: BaseLegalEntityWithID;
  /**
   * Legal entity (person) that requested access to the data set for the company reader
   */
  signatories?: BaseLegalEntityWithID[];
}

export class DataRoomAccessLogModel extends Model {
  requestedAt: Date;
  dataSetName: string;
  reader: SimpleLegalEntityModel;
  signatories?: SimpleLegalEntityModel[];

  constructor({
    requestedAt, dataSet, reader, signatories, ...restProps
  }: DataRoomAccessLogModelProps) {
    super(restProps);
    this.requestedAt = new Date(requestedAt);
    this.dataSetName = dataSet.name;
    this.reader = SimpleLegalEntityModel.fromResponse(reader);
    this.signatories = SimpleLegalEntityModel.fromResponse(signatories);
  }
}
