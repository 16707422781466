import { Model } from 'core/model';
import PricingPlanItemModel from './pricingPlanItem';

/**
 * Pricing Plan Model
 *
 * Props under restProps are:
 * id, name, tier, isCustom, isCustomizable, isSubscribed, minSubscriptionPeriod, desc
 */
export default class PricingPlanModel extends Model {
  constructor({ pricingItems, desc, ...restProps }) {
    super(restProps);
    this.pricingItems = PricingPlanItemModel.fromResponse(pricingItems);
    this.description = desc;
  }
}
