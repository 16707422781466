import { useQuery } from '@tanstack/react-query';
import { useLocation, useParams } from 'react-router-dom';

import { IssuingCertificateTypeDetailView } from '@trustwise/design-system';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import { downloadCertificateTypeFile, getIssuerCertificateTypeDetails } from '../actions';

export const CertificateTypeDetailsView = () => {
  const { certificateTypeId } = useParams();
  const { state: routerState } = useLocation();
  if (!certificateTypeId) {
    throw new Error('certificateTypeId is required');
  }

  const { data: certificateType } = useQuery({
    queryKey: ['issuerCertificateTypes', certificateTypeId],
    queryFn: () => getIssuerCertificateTypeDetails(certificateTypeId),
  });

  return (
    <PageContent
      fullscreen
      pageHeading={gettext(certificateType?.name || '')}
      headerLeft={<BackButton href={routerState?.origin || '/issuing/types/'} useRouterLink />}
    >
      <IssuingCertificateTypeDetailView
        {...certificateType?.toObject()}
        onFileDownload={() => downloadCertificateTypeFile(certificateType)}
      />
    </PageContent>
  );
};
