import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import AvatarComponent from '../common/avatar';

import { inboxItemsCountStore } from '../../stores/inboxItemsCountStore';

const AccountAvatar = observer((props) => {
  const {
    account, size, namePosition, onClick,
  } = props;
  // @todo introduce generic badge component
  return (
    <AvatarComponent
      color={account.color}
      image={account.image}
      initials={account.initials}
      itemClass={`${account.class ? account.class : ''} ${account.pending ? 'pending' : ''}`}
      size={size}
      onClick={onClick}
      href={account.link}
      name={{
        position: namePosition,
        text: account.name,
      }}
      badge={<span className="badge-notification-counter">{inboxItemsCountStore.countById(account.id) || ''}</span>}
    />
  );
});

// eslint-disable-next-line no-restricted-exports
export { AccountAvatar as default };

AccountAvatar.defaultProps = {
  onClick: () => { },
  namePosition: 'bottom',
  size: 'large',
};

AccountAvatar.propTypes = {
  account: PropTypes.shape({
    class: PropTypes.string,
    color: PropTypes.string,
    image: PropTypes.string,
    initials: PropTypes.string,
    link: PropTypes.string,
    notificationsCount: PropTypes.number,
    pending: PropTypes.bool,
    name: PropTypes.string,
  }).isRequired,
  namePosition: PropTypes.string,
  onClick: PropTypes.func,
  size: PropTypes.string,
};
