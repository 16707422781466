import { FileModel, SimpleDocumentModel } from 'media/models';
import { SimpleLegalEntityModel } from 'contacts/models/legalEntity';
import convertResponseToModel from 'utils/responseToModel';

/**
 * Registry Member Model
 *
 * Props under restProps are:
 * 'id', 'name', 'entityType', 'email', 'avatar'
 */
export class RegistryMemberModel extends SimpleLegalEntityModel {
  constructor({
    dateAdded, ...restProps
  }) {
    super(restProps);
    this.dateAdded = new Date(dateAdded);
  }
}

/**
 * Registry Member Detail Model
 *
 * Props under restProps are:
 * 'name', 'jurisdiction', 'email', 'phoneNumber', 'registeredBy'
 */
export class RegistryMemberDetailModel {
  constructor({
    walletAddress, registeredAt, documents, entityType, ...restProps
  }) {
    this.id = walletAddress;
    this.documents = SimpleDocumentModel.fromArrayResponse(documents || []);
    this.registeredAt = new Date(registeredAt);
    this.isCompany = entityType === 'company';
    Object.entries(restProps).forEach(([fieldName, fieldValue]) => {
      this[fieldName] = fieldValue;
    });
  }
}

/**
 * Registry Member Records Model
 *
 * Props under restProps are:
 * 'note', 'filesCount', 'id'
 */
export class RegistryMemberRecordsModel {
  constructor({
    date, files, filesCount, ...restProps
  }) {
    this.date = new Date(date);
    this.files = convertResponseToModel(files || [], FileModel);
    this.filesCount = filesCount || (files && files.length);
    Object.entries(restProps).forEach(([fieldName, fieldValue]) => {
      this[fieldName] = fieldValue;
    });
  }
}
