import axios from 'core/axios';
import { SimpleLegalEntityModel } from 'contacts/models/legalEntity';
import { QueryKeyProp } from 'core/types';
import { ExternalAccessDataRoomModel, ExternalAccessDataSetModel, GrantModel } from './models';

const baseUrl = '/api/v1/data-rooms/external-access';

export interface VerificationSummary {
  /**
   * Data room details
   */
  dataRoom: ExternalAccessDataRoomModel;
  /**
   * List of verifiers
   */
  verifiers: SimpleLegalEntityModel[];
  /**
   * List of grants
   */
  grants: GrantModel[];
}

export const getVerificationsSummary = ({ queryKey: [, accessKey] }: QueryKeyProp) => (
  axios.get(`${baseUrl}/?key=${accessKey}`)
    .then(({ data }) => {
      const { verifiers, total_granted_tokens: grants, ...dataRoomData } = data;
      return {
        dataRoom: ExternalAccessDataRoomModel.fromResponse(dataRoomData),
        verifiers: SimpleLegalEntityModel.fromResponse(verifiers),
        grants: GrantModel.fromResponse(grants),
      } as VerificationSummary;
    })
    .catch(console.error)
);

export const getDataSets = ({ queryKey: [, accessKey] }: QueryKeyProp): Promise<ExternalAccessDataSetModel[]> => (
  axios.get(`${baseUrl}/data-sets/?key=${accessKey}`)
    .then(({ data }) => ExternalAccessDataSetModel.fromResponse(data))
    .catch(console.error)
);

export const getDataSet = (
  { dataSetId, accessKey }: { dataSetId: number, accessKey: string },
): Promise<ExternalAccessDataSetModel> => (
  axios.get(`${baseUrl}/data-sets/${dataSetId}/?key=${accessKey}`)
    .then(({ data }) => ExternalAccessDataSetModel.fromResponse(data))
    .catch(console.error)
);
