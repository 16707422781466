/* Legal Agreement part component */
import { useState } from 'react';
import PropTypes from 'prop-types';
import DOMPurify from 'dompurify';
import marked from 'marked';
import { ModalWithContent as Modal } from '@trustwise/design-system';
import Header from 'components/common/header';


const LegalAgreementsPart = ({ powerOfAttorneyText, legalText }) => {
  const [showPowerOfAttorneyText, setShowPowerOfAttorneyText] = useState(false);
  const [showLegalText, setShowLegalText] = useState(false);

  return (
    <section>
      <Header title={gettext('Legal Agreements')} className="top-margin" />
      <button
        className="card-base full-width text-smaller align-text-left dark-grey"
        type="button"
        onClick={() => { setShowPowerOfAttorneyText(true); }}
      >
        {gettext('Power of attorney')}
      </button>
      <button
        className="card-base full-width text-smaller align-text-left dark-grey"
        type="button"
        onClick={() => { setShowLegalText(true); }}
      >
        {gettext('Legal text')}
      </button>
      <Modal
        id="powerOfAttorney"
        isOpen={showPowerOfAttorneyText}
        onClose={() => { setShowPowerOfAttorneyText(false); }}
      >
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(marked(powerOfAttorneyText)) }} />
      </Modal>
      <Modal
        id="legalText"
        isOpen={showLegalText}
        onClose={() => { setShowLegalText(false); }}
      >
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(marked(legalText)) }} />
      </Modal>
    </section>
  );
};

LegalAgreementsPart.propTypes = {
  powerOfAttorneyText: PropTypes.string.isRequired,
  legalText: PropTypes.string.isRequired,
};

export default LegalAgreementsPart;
