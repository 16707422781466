import { useState, useEffect } from 'react';

import { Formik, ErrorMessage, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { SubmitButton, Button, CheckboxField, EmailField } from 'core/forms/fields';
import axios from 'core/axios';
import { parseErrorResponse } from 'core/forms/utils';
import FormErrors from '../components/common/formikErrors';


const AccountConfiguration = () => {
  const [allowedPurposes, setAllowedPurposes] = useState([]);

  useEffect(() => {
    axios.get('/api/v1/initial-configuration/account-purposes/')
      .then(({ data }) => { setAllowedPurposes(data); })
      .catch(console.error);
  }, []);

  const onSubmit = (values, actions) => {
    const data = {
      account_purposes: values.accountPurposes,
      business_partner_emails: values.businessPartnerEmails.filter(Boolean),
    };
    axios.post('/api/v1/initial-configuration/', data)
      .then(
        ({ data: { redirect_url: redirectUrl } }) => { window.location = redirectUrl; },
        ({ response: { data: errors } }) => { parseErrorResponse(errors, actions.setFieldError); },
      )
      .catch(console.error)
      .finally(() => { actions.setSubmitting(false); });
  };

  return (
    <Formik
      initialValues={{
        nonFieldErrors: '',
        accountPurposes: [],
        businessPartnerEmails: [''],
      }}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={Yup.object({
        accountPurposes: Yup.array().of(Yup.string().length(2)),
        businessPartnerEmails: Yup.array().of(
          Yup.string()
            .email('Please, make sure the email is valid')
            .max(254, 'E-mail is too long'),
        ),
      })}
    >
      {({ values, isSubmitting }) => (
        <Form>
          <ErrorMessage component={FormErrors} name="nonFieldErrors" />
          <ErrorMessage component={FormErrors} name="accountPurposes" />
          <label className="bottom-padding text-smaller" htmlFor="accountPurposes">
            <div className="primary-color">
              {gettext('What would you like to use the app for?')}
            </div>
            <div className="text-tiny">
              {gettext('You can select multiple options, which will create corresponding wallets for you')}
            </div>
          </label>
          <FieldArray
            name="accountPurposes"
            render={(arrayHelpers) => (
              <div>
                {allowedPurposes.map((allowedAction) => (
                  <CheckboxField
                    key={allowedAction.value}
                    id={allowedAction.value}
                    name="accountPurposes"
                    value={allowedAction.value}
                    label={allowedAction.label}
                    checked={values.accountPurposes.includes(allowedAction.value)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        arrayHelpers.push(allowedAction.value);
                      } else {
                        const index = values.accountPurposes.indexOf(allowedAction.value);
                        arrayHelpers.remove(index);
                      }
                    }}
                  />
                ))}
              </div>
            )}
          />
          <div className="text-smaller primary-color padded-y">
            {gettext(
              `Would you like to connect with your business partners
              and share your accounts and addresses for convenience reasons?`,
            )}
          </div>
          <div className="text-tiny padded-y">
            {gettext(
              `Please, ask your business partners for their registered e\u2011mails and add them below.
                You will be informed when they accepted your request.`,
            )}
          </div>
          <FieldArray
            name="businessPartnerEmails"
            render={(arrayHelpers) => (
              <>
                {values.businessPartnerEmails.map((_businessPartnerEmail, index) => (
                  <EmailField
                    // eslint-disable-next-line react/no-array-index-key
                    key={`businessPartnerEmails[${index}]`}
                    label={gettext('E-mail:')}
                    name={`businessPartnerEmails[${index}]`}
                    onChange={(e) => arrayHelpers.replace(index, e.target.value)}
                  />
                ))}
                <Button className="button-outlined" onClick={() => arrayHelpers.push('')}>
                  {gettext('Add partner')}
                </Button>
              </>
            )}
          />
          <br />
          <SubmitButton disabled={isSubmitting}>{gettext('Save')}</SubmitButton>
        </Form>
      )}
    </Formik>
  );
};


export default AccountConfiguration;
