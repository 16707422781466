import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import { getUrl } from 'utils/urls';
import { FullPageView } from 'core/page';
import { SubscriptionsListView } from './list';
import { SubscriptionDetailView } from './detail';

const queryClient = new QueryClient();

export const EquitySubscriptionsNavigation = () => (
  <FullPageView>
    <QueryClientProvider client={queryClient}>
      <Router basename={getUrl('/assets/subscriptions/')}>
        <Routes>
          <Route path="/">
            <Route index element={<SubscriptionsListView />} />
            <Route path=":subscriptionId/" element={<SubscriptionDetailView />} />
          </Route>
        </Routes>
      </Router>
    </QueryClientProvider>
  </FullPageView>
);
