import { useEffect, useMemo } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { SubscriptionsTable } from '@trustwise/design-system';
import { PageContent } from 'core/page';
import { getUrl } from 'utils/urls';
import BackButton from 'core/page/parts/backButton';
import { getRouterLinkInteraction } from 'core/utils';
import { getSubscriptions } from './actions';

export const SubscriptionsListView = () => {
  const { data: subscriptions } = useQuery({
    queryKey: ['subscriptions'],
    queryFn: getSubscriptions,
  });

  const queryClient = useQueryClient();
  useEffect(() => {
    subscriptions?.forEach((subscription) => {
      queryClient.setQueryData(['subscription', `${subscription.id}`], subscription);
    });
  }, [queryClient, subscriptions]);

  const sortedSubscriptions = useMemo(() => (
    subscriptions?.sort((a, b) => b.closingDate.getTime() - a.closingDate.getTime())
  ), [subscriptions]);

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Subscriptions')}
      headerLeft={<BackButton href={getUrl()} />}
    >
      <SubscriptionsTable subscriptions={sortedSubscriptions} onInteraction={(id) => getRouterLinkInteraction(`${id}/`)} />
    </PageContent>
  );
};
