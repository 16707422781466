import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';


const FlashMessage = ({ messageType, message, url }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [additionalClass, setAdditionalClass] = useState('');

  useEffect(() => {
    if (messageType.includes('flash-message')) {
      setIsVisible(true);
      if (!messageType.includes('fixed')) {
        setTimeout(() => { setAdditionalClass('move-message'); }, 1000);
        setTimeout(() => { setIsVisible(false); }, 7000);
      }
    }
  }, [message, messageType]);

  return isVisible && (
    <li className={`${messageType} ${additionalClass}`}>
      <p>
        {url ? <a href={url}>{message}</a> : message}
      </p>
    </li>
  );
};

FlashMessage.defaultProps = {
  url: null,
};


FlashMessage.propTypes = {
  messageType: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  url: PropTypes.string,
};

export default FlashMessage;
