import { snakeCase } from 'lodash';
import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { GovEventsTypesList, GroupInformationFormContainer } from '@trustwise/design-system';
import axios from 'core/axios';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import { convertKeysCase, convertKeysToCamelCase } from 'core/utils';
import { getUrl, getApiUrl } from 'utils/urls';
import { addMultipleDocuments } from 'media/utils';
import { dateToday } from 'utils/date';
import { handleTransactionCreation } from 'core/page/transactionSigning/utils';
import { SimpleLegalEntityModel } from 'contacts/models/legalEntity';
import {
  EventTypes,
  EVENT_TYPES_LABELS,
  EVENTS_BY_PRICING_ACTIONS,
  EVENTS_BY_GROUP,
} from '../const';
import GovernanceEventUpdate from './update';
import { prepareSubmitData, setResponseErrors } from '../utils';
import { EventsContext } from '../context';

const GovernanceEventCreate = () => {
  const [eventType, setEventType] = useState(undefined);
  const [eventOwner, setEventOwner] = useState(undefined);
  const navigate = useNavigate();
  const { allowedPricingActions, targetGroup, indexPath, basePath } = useContext(EventsContext);
  const backButtonAction = eventType
    ? { onClick: () => { setEventType(undefined); } }
    : { href: indexPath };

  useEffect(() => {
    axios.get(getApiUrl('/account-data/'))
      .then(({ data }) => { setEventOwner(new SimpleLegalEntityModel(convertKeysToCamelCase(data))); })
      .catch(console.error);
  }, []);

  const onSubmit = (values, actions) => {
    const { documents, ...restValues } = values;
    const [group, type] = eventType.split('_');
    const { languages, ...preparedData } = prepareSubmitData(restValues);

    const data = {
      type,
      group,
      ...preparedData,
    };
    const submitData = new FormData();
    Object.entries(data).forEach(
      ([key, value]) => submitData.append(key, value),
    );
    if (languages && !!languages.length) {
      languages.forEach(
        (language, index) => submitData.append(`languages[${index}]`, language),
      );
    }

    addMultipleDocuments({ documents: documents || [], submitData });
    axios.post(getApiUrl('/meetings/'), submitData)
      .then(
        ({ data: respData }) => { navigate(`${indexPath}${respData.id}/`); },
        ({ response: { data: errors } }) => { setResponseErrors(errors, actions); },
      )
      .catch((error) => { console.error(error); actions.setSubmitting(false); });
  };

  const onInformationSend = (values, actions) => {
    const { files, subject, ...restValues } = values;
    const data = new FormData();
    data.append('subject', subject);
    data.append('message_type', eventType);
    Object.entries(convertKeysCase(restValues, snakeCase)).forEach(
      ([key, value]) => data.append(key, value),
    );
    if (files.length) {
      addMultipleDocuments({
        documents: [{ files, status: 'FIN', date: dateToday(), subject, type: 'EM' }],
        submitData: data,
      });
    }
    axios.post(getApiUrl('/messages/send/'), data)
      .then(
        ({ data: { tx_hash: txHash } }) => {
          handleTransactionCreation(txHash, getUrl(`${basePath}${indexPath}`));
        },
        ({ response: { data: errors } }) => { setResponseErrors(errors, actions); },
      )
      .catch(console.error)
      .finally(() => { actions.setSubmitting(false); });
  };

  const onInformationPDFPreview = (values) => {
    const data = {
      message_type: eventType,
      ...prepareSubmitData(values),
    };
    // @todo DRY with download PDF action, introduce generic download handler
    axios.post(getApiUrl('/messages/preview/'), data, { responseType: 'blob' })
      .then(
        (response) => {
          const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'preview.pdf');
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
          link.remove();
        },
      )
      .catch(console.error);
  };

  let component;
  switch (eventType) {
    case EventTypes.BOARD_MEETING:
    case EventTypes.BOARD_RESOLUTION:
    case EventTypes.SHAREHOLDERS_MEETING:
    case EventTypes.SHAREHOLDERS_RESOLUTION:
      component = (
        <GovernanceEventUpdate
          eventType={eventType}
          eventOwner={eventOwner}
          onCancel={() => { navigate(indexPath); }}
          onSubmit={onSubmit}
        />
      );
      break;
    case EventTypes.BOARD_INFORMATION:
    case EventTypes.SHAREHOLDERS_INFORMATION:
      component = (
        <GroupInformationFormContainer
          sender={eventOwner}
          recipientsLabel={
            eventType === EventTypes.BOARD_INFORMATION
              ? gettext('All Board Members')
              : gettext('All Shareholders')
          }
          onInformationSend={onInformationSend}
          onPDFPreview={onInformationPDFPreview}
        />
      );
      break;
    default:
      component = (
        <GovEventsTypesList
          onTileClick={(type) => setEventType(type)}
          enabledTypes={allowedPricingActions.map((allowedAction) => EVENTS_BY_PRICING_ACTIONS[allowedAction])}
          includedTypes={targetGroup ? EVENTS_BY_GROUP[targetGroup] : undefined}
          onPlanUpgrade={() => { window.location = getUrl('/account/product-offerings/'); }}
        />
      );
      break;
  }

  return (
    <PageContent
      fullscreen
      pageHeading={!eventType ? gettext('New Event') : EVENT_TYPES_LABELS[eventType]}
      headerLeft={<BackButton useRouterLink {...backButtonAction} />}
    >
      {component}
    </PageContent>
  );
};

export default GovernanceEventCreate;
