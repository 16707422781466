/* Base pricing tile */
import PropTypes from 'prop-types';

import { PRICING_CURRENCY } from './consts';
import PaidItemsList from './parts/paidItemsList';
import FreeItemsList from './parts/freeItemsList';

const PricingWidget = ({ pricingPlan, isCompany }) => {
  const {
    id, name, icon, paidItemsDifference, freeItems, desc,
    subscriptionFee: {
      initSlabPrice, price, quotationDisplay, isSlabBased,
      slabsDescriptions, quotationTypePluralDisplay,
    },
  } = pricingPlan;

  const showFreeItems = freeItems.length > 0 || !isCompany;

  return (
    <div
      id={`tile-${id}`}
      className="pricing-plan-tile default-plan"
    >
      <div className="tile-header">
        <div>
          <h4>{name}</h4>
          <div className="pricing-plan-tile-sub-header">{desc}</div>
        </div>
        {icon}
      </div>
      <div className="pricing-plan-tile-content">
        <div className="content-monetary-value pricing-plan-tile-section-separator">
          <span>
            {`${initSlabPrice || price} ${PRICING_CURRENCY}`}
            <span className="pricing-plan-tile-sub-header">{gettext('excl. VAT')}</span>
          </span>
          <div className="pricing-plan-tile-sub-header">
            {quotationDisplay}
          </div>
          {isSlabBased && (
            <div className="pricing-plan-tile-sub-header">
              {slabsDescriptions.map((slab, index) => (
                index !== 0 && (
                <div key={slab.amount}>
                  {`*${slab.amount} ${quotationTypePluralDisplay} the price is ${slab.price}`}
                </div>
                )
              ))}
            </div>
          )}
        </div>
        <PaidItemsList planItems={paidItemsDifference} showSeparator={showFreeItems} />
        {showFreeItems && <FreeItemsList planItems={freeItems} isCompany={isCompany} />}
      </div>
    </div>
  );
};

PricingWidget.defaultProps = {
  isCompany: false,
};

PricingWidget.propTypes = {
  pricingPlan: PropTypes.shape().isRequired,
  isCompany: PropTypes.bool,
};

export default PricingWidget;
