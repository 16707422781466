import PropTypes from 'prop-types';
import { useMemo } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import PageNotFoundContent from 'core/page/contentParts/404';
import { BOARD } from 'governanceEvents/const';
import { convertBooleanString } from 'core/utils';
import { EventsContext } from 'governanceEvents/context';
import { getGlobalContext } from 'core/globals';
import { getUrl } from 'utils/urls';
import { GovEventsRoutes } from 'governanceEvents/navigation';
import { FullPageView } from 'core/page';
import {
  Boardroom,
  BoardMemberDetail,
} from './views';

const BoardroomView = ({ allowedEventsPricingActions, isLocked }) => {
  const { activeEntity: { id: activeEntityId } } = getGlobalContext();
  const basePath = '/boardroom';
  const eventsContextValue = useMemo(() => ({
    basePath,
    indexPath: '/meetings/',
    targetGroup: BOARD,
    allowedPricingActions: allowedEventsPricingActions ? allowedEventsPricingActions.split(' ') : [],
  }), [allowedEventsPricingActions]);

  return (
    <FullPageView>
      <Router basename={getUrl(basePath)}>
        <Routes>
          <Route path="/">
            <Route index element={<Boardroom isLocked={convertBooleanString(isLocked)} />} />
            <Route path=":memberId/" element={<BoardMemberDetail />} />
          </Route>
          <Route
            path="meetings/*"
            element={(
              <EventsContext.Provider value={eventsContextValue}>
                <GovEventsRoutes ownerId={activeEntityId} previousPath={basePath} />
              </EventsContext.Provider>
          )}
          />
          <Route path="*" element={<PageNotFoundContent />} />
        </Routes>
      </Router>
    </FullPageView>
  );
};

BoardroomView.defaultProps = {
  allowedEventsPricingActions: '',
};

BoardroomView.propTypes = {
  allowedEventsPricingActions: PropTypes.string,
  isLocked: PropTypes.string.isRequired,
};

export default BoardroomView;
