/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-destructuring */
import { reject } from 'lodash';
import { DonutChart } from './slicedCharts';
import { green, red, grey } from '../utils/palette';


export default class VotingChart {
  constructor({ targetElement = null, chartData = [], options = {} }) {
    this.chartData = chartData;
    this.targetElement = targetElement;
    this.labels = [];
    this.colors = [green, red, grey]; // set color for chart slices
    this.values = [];
    this.descriptions = [];
    this.options = options;
  }

  render() {
    if (!this.chartData || !this.chartData.length) {
      return;
    }

    this.labels = this.chartData[2];
    const _values = this.chartData[1];
    this.names = this.chartData[0];

    this.values = reject(_values, (val, index) => {
      if (val === 0) {
        this.labels.splice(index, 1);
        this.names.splice(index, 1);
        this.colors.splice(index, 1);
        return true;
      }
      return false;
    }); // remove zero values, colors and labels
    if (this.values.length === 1) {
      this.colors[0] = grey;
    }
    this._draw();
  }

  _draw() {
    new DonutChart({
      element: this.targetElement,
      labels: this.labels,
      descriptions: this.descriptions,
      values: this.values,
      colors: this.colors,
      names: this.names,
      options: this.options,
    }).build();
  }
}

export { VotingChart };
