// Styles related constants

// Color Settings
const colorPrimary40 = '#5834BE';
const colorPrimary50 = '#8A71D2';
const colorPrimary80 = '#EEEBF9';

const colorNeutral10 = '#1E1E1F';
const colorNeutral20 = '#3C3C3E';
const colorNeutral30 = '#5C5C5F';
const colorNeutral50 = '#99999D';
const colorNeutral60 = '#B8B8BD';
const colorNeutral80 = '#E6E6EC';

const colorBackground20 = '#F4F4F5';

const primaryColor = colorPrimary40;
// not part of the original style guide but need it for non transparent backgrounds, should be removed
const colorWhite06 = '#FBFBFB';
const colorWhite100 = '#FFFFFF';
// Data colors
const colorDataBlue = '#006BCD';
const colorDataLightBlue = '#35C7F5';
const colorDataGreen = '#00BE91';
const colorDataYellow = '#EECA0D';

// Screen Sizes
const screenXSMin = 0;
const screenSMin = 600;
const screenMMin = 905;
const screenLMin = 1240;
const screenXLMin = 1440;
const screenXSMax = screenSMin - 1;
const screenSMax = screenMMin - 1;
const screenMMax = screenLMin - 1;
const screenLMax = screenXLMin - 1;

// Layout Settings
const bp = 4;

// Widgets settings
const widgetPadding = bp * 8;
const widgetPaddingS = bp * 5;

const getWidgetPadding = (screenSize) => (screenSize < screenSMin ? widgetPaddingS : widgetPadding);

// Font & Typography
const fontFamily = 'Manrope, sans-serif';

const numbersSizes = {
  d1: { fontSize: '48px', lineHeight: `${bp * 14}px` },
  d2: { fontSize: '36px', lineHeight: `${bp * 10}px` },
  d3: { fontSize: '20px', lineHeight: `${bp * 7}px` },
};
const numbersSizesSmall = {
  d1: { fontSize: '42px', lineHeight: `${bp * 12}px` },
  d2: { fontSize: '32px', lineHeight: `${bp * 10}px` },
  d3: { fontSize: '20px', lineHeight: `${bp * 7}px` },
};

const bodyFontSize2 = { fontSize: '14px', lineHeight: `${bp * 5}px` };

// Number of Items Per Page Select Settings
const pageSizeSelectWidth = `${bp * 29}px`;
const pageSizeSelectHeight = `${bp * 6}px`;
const pageSizeSelectPadding = `${bp * 4}px`;

export {
  bp,
  colorNeutral10,
  colorBackground20,
  colorDataBlue,
  colorDataGreen,
  colorDataLightBlue,
  colorDataYellow,
  colorNeutral20,
  colorNeutral30,
  colorNeutral50,
  colorNeutral60,
  colorNeutral80,
  primaryColor,
  colorPrimary80,
  colorPrimary50,
  fontFamily,
  screenXSMin,
  screenSMin,
  screenMMin,
  screenLMin,
  screenXLMin,
  screenXSMax,
  screenSMax,
  screenMMax,
  screenLMax,
  numbersSizes,
  bodyFontSize2,
  numbersSizesSmall,
  colorWhite06,
  colorWhite100,
  getWidgetPadding,
  pageSizeSelectWidth,
  pageSizeSelectHeight,
  pageSizeSelectPadding,
};
