import PropTypes from 'prop-types';
import { countryOptions } from 'core/options';


export default class CustodialAccountModel {
  constructor({
    id = null,
    name = '',
    entityType = '',
    countryCode = '',
    city = '',
    postalCode = '',
    street = '',
    additionalAddress = '',
    email = '',
    dateOfBirth = '',
    custodianComment = '',
  }) {
    this.id = parseInt(id, 10) || id;
    this.name = name;
    this.entityType = entityType;
    this.countryCode = countryCode.toUpperCase();
    this.city = city;
    this.postalCode = postalCode;
    this.street = street;
    this.additionalAddress = additionalAddress;
    this.email = email;
    this.dateOfBirth = dateOfBirth;
    this.custodianComment = custodianComment || '';
  }

  update(data) { Object.entries(data).forEach(([key, value]) => { this[key] = value; }); }

  // Properties definitions
  static propType = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    email: PropTypes.string,
    countryCode: PropTypes.string,
    city: PropTypes.string,
    postalCode: PropTypes.string,
    street: PropTypes.string,
    additionalAddress: PropTypes.string,
    dateOfBirth: PropTypes.string,
    custodianComment: PropTypes.string,
  });

  get countryLabel() {
    const country = countryOptions.find((countryOption) => countryOption.code === this.countryCode);
    return country ? country.label : '';
  }

  get fullAddress() {
    return (
      `${this.street}\n` +
      `${this.additionalAddress ? `${this.additionalAddress}\n` : ''}` +
      `${this.postalCode ? `${this.postalCode} ` : ''}${this.city}\n` +
      `${this.countryLabel}`
    );
  }

  get dateOfBirthDisplay() {
    return new Date(this.dateOfBirth).toLocaleDateString('de-CH');
  }
}
