/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import * as d3 from 'd3';
import { chartColors, blue } from '../utils/palette';


export class SlicedChart {
  constructor({
    element = null,
    labels = [],
    descriptions = [],
    values = [],
    colors = [],
    names = [],
    options = {},
  }) {
    this.values = values; // [26,25,10];
    this.labels = labels; // ['pp', 'aa', 'aa']
    this.descriptions = descriptions; // ['text', 'text', 'text']
    this.colors = colors; // ['black', 'red', 'green']
    this.names = names;
    this.root = d3.select(element); // #chart
    this.options = options; // {}
  }

  build() {
    this.svg = this.root
      .append('svg')
      .attr('viewBox', '0 0 400 200')
      .attr('class', 'sliced-chart-root')
      .append('g'); // create initial svg

    this.svg.append('g').attr('class', 'slices');
    this.svg.append('g').attr('class', 'labels');
    this.svg.append('g').attr('class', 'lines');

    this.pie = d3.pie().sort(null).value((d) => d);
    this.arc = this.buildArc();
    this.outerArc = d3.arc().outerRadius(this.radius * 0.9).innerRadius(this.radius * 0.9);

    this.svg.attr('transform', `translate(${this.chartWidth / 2},${this.chartHeight / 2})`);
    this.svg.style('transform', 'translate(50%, 50%)');

    this._drawSlices();
    this._drawLabels();
    this._drawLines();

    return this.svg;
  }

  _midAngle(d) {
    return d.startAngle + (d.endAngle - d.startAngle) / 2;
  }

  _drawLabels() {
    const ctx = this;

    this.svg.select('.labels').selectAll('text')
      .data(this.pie(this.values))
      .enter()
      .append('text')
      .attr('dy', '.35em')
      .html((d) => ctx.labels[d.index])
      .attr('transform', (d) => {
        const pos = ctx.outerArc.centroid(d);
        pos[0] = ctx.radius * 0.95 * (ctx._midAngle(d) < Math.PI ? 1 : -1);
        return `translate(${pos})`;
      })
      .style('text-anchor', (d) => ((ctx._midAngle(d)) < Math.PI ? 'start' : 'end'))
      .style('font-size', '12px')
      .style('font-family', 'ubuntu-light, "Helvetica Neue", Calibri-light, Arial, Roboto, sans-serif');
  }

  _drawLines() {
    const ctx = this;

    this.svg.select('.lines')
      .selectAll('polyline')
      .data(this.pie(this.values))
      .enter()
      .append('polyline')
      .attr('points', (d) => {
        const pos = ctx.outerArc.centroid(d);
        pos[0] = ctx.radius * 0.95 * (ctx._midAngle(d) < Math.PI ? 1 : -1);
        return [ctx.arc.centroid(d), ctx.outerArc.centroid(d), pos];
      })
      .style('stroke-width', '0.5px');
  }

  _drawSlices() {
    const ctx = this;

    this.svg.selectAll('path')
      .data(this.pie(this.values))
      .enter()
      .append('path')
      .attr('d', this.arc)
      .attr('fill', (d, i) => ctx.getColor(i))
      .on('mouseenter', (data) => {
        ctx.svg.append('text')
          .attr('class', 'toolCircle')
          .html(ctx.toolTipHTML(data.index)) // add text to the circle.
          .style('font-size', '12px')
          .style('text-anchor', 'middle'); // centres text in tooltip

        ctx.svg.append('circle')
          .attr('class', 'toolCircle')
          .attr('r', ctx.radius * 0.55) // radius of tooltip circle
          .style('fill', ctx.getColor(data.index)) // colour based on category mouse is over
          .style('fill-opacity', 0.35);
      })
      .on('mouseout', () => {
        d3.selectAll('.toolCircle').remove(); // remove tooltip
      })
      .transition()
      .duration(2000)
      .attrTween('d', (d) => {
        const i = d3.interpolate(d.startAngle + 0.1, d.endAngle);
        return function transit(t) {
          // eslint-disable-next-line no-param-reassign
          d.endAngle = i(t);
          return ctx.arc(d);
        };
      }); // super fancy transition
  }

  getColor(i) {
    if (this.colors && this.colors.length > i) {
      return this.colors[i];
    }

    if (chartColors.length > i) {
      return chartColors[i];
    }

    return this.d3Palette(i);
  }

  toolTipHTML(index) {
    let hintText = '';
    if (this.descriptions && this.descriptions.length > index) {
      hintText += this.descriptions[index];
    } else {
      const key = this.labels[index];
      const value = this.values[index];
      hintText += `${key}: ${value}`;
    }
    return `<tspan x="0">${hintText}</tspan>`;
  }

  get d3Palette() {
    if (!Object.prototype.hasOwnProperty.call(this, '_d3Palette')) {
      this._d3Palette = d3.scaleOrdinal(d3.schemeCategory10);
    }

    return this._d3Palette;
  }

  get width() {
    if (Object.prototype.hasOwnProperty.call(this.options, 'width')) {
      return this.options.width;
    }
    return '100%';
  }

  get height() {
    if (Object.prototype.hasOwnProperty.call(this.options, 'height')) {
      return this.options.height;
    }
    return '14em';
  }

  get chartWidth() {
    if (Object.prototype.hasOwnProperty.call(this.options, 'chartWidth')) {
      return this.options.chartWidth;
    }
    return 300;
  }


  get chartHeight() {
    if (Object.prototype.hasOwnProperty.call(this.options, 'chartHeight')) {
      return this.options.chartHeight;
    }
    return 200;
  }

  get radius() {
    return Math.min(this.chartWidth, this.chartHeight) / 2;
  }
}

export class DonutChart extends SlicedChart {
  buildArc() {
    return d3.arc().innerRadius(this.radius * 0.8).outerRadius(this.radius * 0.6);
  }

  _appendStatsCircle() {
    const ctx = this;

    const text = this.svg.append('text')
      .attr('class', 'statsCircle')
      .style('font-size', '12px')
      .style('text-anchor', 'middle') // centres text in tooltip
      .append('tspan');

    text.append('tspan')
      .text((_d) => {
        const total = ctx.values.reduce((acc, a) => acc += a, 0);
        let voted = 0;

        if (ctx.names.includes('revoked')) {
          const _idx = ctx.names.indexOf('revoked');
          voted += ctx.values[_idx];
        }

        if (ctx.names.includes('confirmed')) {
          const _idx = ctx.names.indexOf('confirmed');
          voted += ctx.values[_idx];
        }

        return `${voted} / ${total}`;
      })
      .attr('class', 'statsCircle');

    text.append('tspan')
      .text((_d) => 'Show votes')
      .attr('class', 'statsCircle')
      .attr('x', 0)
      .attr('dx', 0)
      .attr('dy', 15);

    this.svg.append('circle')
      .attr('class', 'statsCircle')
      .attr('r', ctx.radius * 0.55) // radius of tooltip circle
      .style('fill', blue) // colour based on category mouse is over
      .style('fill-opacity', 0.35)
      .style('cursor', 'pointer')
      .on('click', () => {
        if (
          Object.prototype.hasOwnProperty.call(ctx.options, 'href')
          && Object.prototype.hasOwnProperty.call(ctx.options.href, 'votingStats')
        ) {
          window.location = ctx.options.href.votingStats;
        }
      });
  }

  _drawSlices() {
    const ctx = this;

    this._appendStatsCircle();

    this.svg.selectAll('path')
      .data(this.pie(this.values))
      .enter()
      .append('path')
      .attr('d', this.arc)
      .attr('fill', (d, i) => ctx.getColor(i))
      .on('mouseenter', (data) => {
        d3.selectAll('.statsCircle').remove(); // remove tooltip
        ctx.svg.append('text')
          .attr('class', 'toolCircle')
          .html(ctx.toolTipHTML(data.index)) // add text to the circle.
          .style('font-size', '12px')
          .style('text-anchor', 'middle'); // centres text in tooltip

        ctx.svg.append('circle')
          .attr('class', 'toolCircle')
          .attr('r', ctx.radius * 0.55) // radius of tooltip circle
          .style('fill', ctx.getColor(data.index)) // colour based on category mouse is over
          .style('fill-opacity', 0.35);
      })
      .on('mouseout', () => {
        d3.selectAll('.toolCircle').remove(); // remove tooltip
        ctx._appendStatsCircle();
      })
      .transition()
      .duration(2000)
      .attrTween('d', (d) => {
        const i = d3.interpolate(d.startAngle + 0.1, d.endAngle);
        return function transit(t) {
          d.endAngle = i(t);
          return ctx.arc(d);
        };
      }); // super fancy transition
  }

  _drawLabels() {
    const ctx = this;

    this.svg.select('.labels').selectAll('text')
      .data(this.pie(this.values))
      .enter()
      .append('text')
      .attr('dy', '.35em')
      .html((d) => ctx.values[d.index])
      .attr('transform', (d) => {
        const pos = ctx.outerArc.centroid(d);
        pos[0] = ctx.radius * 0.95 * (ctx._midAngle(d) < Math.PI ? 1 : -1);
        return `translate(${pos})`;
      })
      .style('text-anchor', (d) => ((ctx._midAngle(d)) < Math.PI ? 'start' : 'end'))
      .style('font-size', '12px')
      .style('font-family', 'ubuntu-light, "Helvetica Neue", Calibri-light, Arial, Roboto, sans-serif');
  }
}

export class PieChart extends SlicedChart {
  buildArc() {
    return d3.arc().innerRadius(this.radius * 0.8).outerRadius(0);
  }

  _drawSlices() {
    const ctx = this;

    const tooltip = this.root.append('div').attr('class', 'tooltip');

    this.svg.selectAll('path')
      .data(this.pie(this.values))
      .enter()
      .append('path')
      .attr('d', this.arc)
      .attr('fill', (d, i) => ctx.getColor(i))
      .on('mousemove', (d) => {
        tooltip.style('left', `${d3.event.pageX + 10}px`);
        tooltip.style('top', `${d3.event.pageY - 25}px`);
        tooltip.style('display', 'inline-block');
        tooltip.style('font-size', '12px');
        tooltip.style('font-family', 'ubuntu-light, "Helvetica Neue", Calibri-light, Arial, Roboto, sans-serif');
        tooltip.html(ctx.toolTipHTML(d.index));
      })
      .on('mouseout', (_d) => {
        tooltip.style('display', 'none');
      })
      .transition()
      .duration(2000)
      .attrTween('d', (d) => {
        const i = d3.interpolate(d.startAngle + 0.1, d.endAngle);
        return function transit(t) {
          d.endAngle = i(t);
          return ctx.arc(d);
        };
      }); // super fancy transition
  }
}
