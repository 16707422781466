import { useState } from 'react';
import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import { icons } from 'core/icons';


const Tooltip = ({ actions, interactive }) => {
  const [visible, setVisible] = useState(false);

  return (
    <Tippy
      content={(
        <ul className="no-margin">
          {actions.map((action) => (
            <li key={action.key} className="padded-y padded-x">{action.content}</li>
          ))}
        </ul>
      )}
      placement="bottom"
      visible={visible}
      onClickOutside={() => setVisible(false)}
      theme="light"
      interactive={interactive}
      zIndex={20}
    >
      <i className="tooltip-trigger" onClick={() => { setVisible(!visible); }}>{icons.ellipsisH}</i>
    </Tippy>
  );
};

Tooltip.defaultProps = {
  interactive: true,
};

Tooltip.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape).isRequired,
  interactive: PropTypes.bool,
};


export default Tooltip;
