import { SimpleLegalEntityModelProps } from 'contacts/interfaces/types';
import { SimpleLegalEntityModel } from 'contacts/models/legalEntity';
import { Model } from 'core/model';
import { convertKeysToCamelCase } from 'core/utils';
import { SimpleDocumentModel } from 'media/models';
import {
  BaseBusinessTransactionModelDataProps,
  BoardBusinessTransactionModelDataProps,
  BusinessTransactionStageType,
  BusinessTransactionType,
  IBaseBusinessTransactionModelData,
  IBoardBusinessTransactionModelData,
} from './interfaces';

export class BaseBusinessTransactionModel extends Model implements IBaseBusinessTransactionModelData {
  dateTime: Date;
  dueDate: Date;
  documents: SimpleDocumentModel[];
  arbiter: SimpleLegalEntityModel;
  recipient: SimpleLegalEntityModel;
  sender: SimpleLegalEntityModel;
  metadata: Record<string, string | number | boolean>;

  constructor({
    dateTime, dueDate, documents, arbiter, recipient, sender,
    metadata, ...restProps
  }: BaseBusinessTransactionModelDataProps) {
    super(restProps);
    this.dateTime = new Date(dateTime);
    this.dueDate = new Date(dueDate);
    this.documents = documents ? SimpleDocumentModel.fromArrayResponse(documents) : [];
    this.arbiter = new SimpleLegalEntityModel(convertKeysToCamelCase(arbiter) as SimpleLegalEntityModelProps);
    this.recipient = new SimpleLegalEntityModel(convertKeysToCamelCase(recipient) as SimpleLegalEntityModelProps);
    this.sender = new SimpleLegalEntityModel(convertKeysToCamelCase(sender) as SimpleLegalEntityModelProps);
    this.metadata = convertKeysToCamelCase(metadata || {});
  }
  id: number;
  amount: number;
  currency: string;
  pricePerUnit: number;
  stage: BusinessTransactionStageType;
  stages: BusinessTransactionStageType[];
  tokenName: string;
  type: BusinessTransactionType;
}

export class BoardBusinessTransactionModel extends BaseBusinessTransactionModel implements IBoardBusinessTransactionModelData {
  id: number;
  amount: number;
  currency: string;
  pricePerUnit: number;
  stage: BusinessTransactionStageType;
  stages: BusinessTransactionStageType[];
  tokenName: string;
  type: BusinessTransactionType;
  isWaitingForApproval: boolean;

  constructor({
    isWaitingForApproval, ...restProps
  }: BoardBusinessTransactionModelDataProps) {
    super(restProps);
    this.isWaitingForApproval = isWaitingForApproval;
  }

  get senderName() {
    return this.sender.name;
  }

  get recipientName() {
    return this.recipient.name;
  }

  get isPending() {
    return this.isWaitingForApproval;
  }
}
