import { DocumentResponseProps } from 'media/interfaces/response';
import { FileModel, SimpleDocumentModel } from 'media/models';

export const getFiles = (files) => files.map((file) => new FileModel(file));

export const getReportsFiles = (
  reports?: DocumentResponseProps[] | SimpleDocumentModel[],
): FileModel[] | undefined => (
  reports?.map((report: DocumentResponseProps | SimpleDocumentModel) => getFiles(report.files)).flat()
);
