/* eslint-disable react/require-default-props */
import { Component } from 'react';
import { inject, observer, PropTypes } from 'mobx-react';

import { icons } from 'core/icons';
import { getGlobalContext } from 'core/globals';
import AvatarComponent from '../common/avatar';

class AccountSwitcher extends Component {
  componentDidMount() {
    const { accountSwitcherStore: { collection } } = this.props;
    collection.fetch();
  }

  onClick = () => {
    const { accountSwitcherStore: { open, setOpen } } = this.props;
    setOpen(!open);
  };

  render() {
    const { accountSwitcherStore: { currentAccount }, inboxItemsCountStore } = this.props;

    if (!currentAccount) {
      return null;
    }
    // @todo introduce generic badge components
    // @todo consider prop for `displayBadgeCounts` or remove unused variant
    const displayBadgeCounts = false;
    let badge = null;
    if (displayBadgeCounts) {
      badge = <span className="badge-notification-counter">{inboxItemsCountStore.totalCount || ''}</span>;
    } else if (inboxItemsCountStore.countExceptId(currentAccount.id)) {
      badge = <span className="badge-dot" />;
    }
    const { activeEntity: { isCompany, name: activeEntityName } } = getGlobalContext();
    return (
      <div onClick={this.onClick} className="account-switcher-trigger">
        { isCompany ? icons.accountCompany : icons.accountPerson }
        <span className="account-switcher-entity-name">
          {activeEntityName}
        </span>
        <AvatarComponent
          itemClass={`${currentAccount.class ? currentAccount.class : ''} ${currentAccount.pending ? 'pending' : ''}`}
          initials={currentAccount.initials}
          color={currentAccount.color}
          image={currentAccount.image}
          badge={badge}
        />
      </div>
    );
  }
}

AccountSwitcher.defaultProps = {};

AccountSwitcher.propTypes = {
  accountSwitcherStore: PropTypes.objectOrObservableObject,
  inboxItemsCountStore: PropTypes.objectOrObservableObject,
};

export default inject('accountSwitcherStore', 'inboxItemsCountStore')(observer(AccountSwitcher));
