import axios from 'core/axios';
import { getApiUrl } from 'utils/urls';
import { getDocumentSubmitData } from 'media/utils';

export const onDocumentAdd = ({ basePath, values, actions, dispatch }) => {
  const submitData = getDocumentSubmitData(values);
  return axios.post(getApiUrl(basePath), submitData)
    .then(({ data }) => { dispatch({ type: 'addDocument', data }); })
    .catch((error) => {
      console.error(error);
      actions.setSubmitting(false);
    });
};

export const onDocumentDelete = ({ basePath, id, setDisabled, dispatch }) => (
  axios.delete(getApiUrl(`${basePath}${id}`))
    .then(({ data }) => { dispatch({ type: 'deleteDocument', data: { ...data, id } }); })
    .catch((error) => {
      console.error(error);
      setDisabled(false);
    })
);

export const onDocumentEdit = ({ basePath, values, actions, dispatch }) => {
  const submitData = getDocumentSubmitData(values, true);
  const { id } = values;
  return axios.patch(getApiUrl(`${basePath}${id}`), submitData)
    .then(({ data }) => { dispatch({ type: 'updateDocuments', data }); })
    .catch((error) => {
      console.error(error);
      actions.setSubmitting(false);
    });
};
