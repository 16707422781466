import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';

import { parseErrorResponse, fieldRequired } from 'core/forms/utils';
import { SubmitButton, TextField, FieldErrors } from 'core/forms/fields';
import axios from 'core/axios';
import { convertKeysToCamelCase } from 'core/utils';
import { FullPageContent } from 'core/page';
import { getUrl, getApiUrl } from 'utils/urls';
import { BasicRow } from 'core/lists/rows/basicRow';
import { ContactSelectField } from 'contacts/contactSelect/selectField';
import { contactSelectValidation } from 'contacts/contactSelect/validation';
import BackButton from 'core/page/parts/backButton';
import { handleTransactionCreation } from 'core/page/transactionSigning/utils';


const TokenDistributeForm = ({ tokenId }) => {
  const [token, setToken] = useState({ name: '', undistributedAmount: 0 });
  const baseUrl = getApiUrl(`/tokens/${tokenId}/`);
  const tokenDetailUrl = getUrl(`/liabilities/equity/${tokenId}/`);

  useEffect(() => {
    axios.get(`${baseUrl}undistributed/`)
      .then(({ data }) => { setToken(convertKeysToCamelCase(data)); })
      .catch(console.error);
  }, [baseUrl]);

  const onSubmit = (values, actions) => {
    actions.setSubmitting(true);
    const data = new FormData();
    data.append('address', values.recipient.portfolio);
    data.append('number_of_tokens', values.numberOfShares);
    axios.post(`${baseUrl}distribute/`, data)
      .then(
        ({ data: { tx_hash: txHash } }) => {
          handleTransactionCreation(txHash, tokenDetailUrl);
        },
        ({ response: { data: errors } }) => { parseErrorResponse(errors, actions.setFieldError); },
      )
      .catch(console.error)
      .finally(() => { actions.setSubmitting(false); });
  };

  return (
    <FullPageContent
      pageHeading={gettext(`Distribution of ${token.name}`)}
      headerLeft={<BackButton href={tokenDetailUrl} />}
    >
      <>
        {token.undistributedAmount > 0 && (
        <div className="underline">
          <BasicRow title={gettext('Undistributed')} value={(token.undistributedAmount).toLocaleString('de-ch')} />
        </div>
        )}
        <Formik
          initialValues={{
            nonFieldErrors: '',
            recipient: {},
            numberOfShares: 0,
          }}
          validationSchema={Yup.object({
            recipient: contactSelectValidation(),
            numberOfShares: Yup.number().positive(gettext('Must be above 0')).max(
              token.undistributedAmount,
              gettext(`Maximum available amount is ${token.undistributedAmount}`),
            ).required(fieldRequired),
          })}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <FieldErrors name="nonFieldErrors" />
              <ContactSelectField
                name="recipient"
                includeCompanyWallets
                withPortfolios
                includeOwn
                selectContactLabel={gettext('Assign a recipient')}
                addNewLabel={gettext('Add new recipient')}
                emptyValueLabel={gettext('No recipient yet')}
                label={gettext('Recipient')}
              />
              <TextField label={gettext('Number of shares')} name="numberOfShares" type="number" />
              <SubmitButton disabled={isSubmitting}>{gettext('Distribute')}</SubmitButton>
            </Form>
          )}
        </Formik>
      </>
    </FullPageContent>
  );
};

TokenDistributeForm.propTypes = {
  tokenId: PropTypes.string.isRequired,
};

export default TokenDistributeForm;
