import PropTypes from 'prop-types';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';
import SubscriptionsList from 'subscriptions/generic/list';
import BackButton from 'core/page/parts/backButton';
import { FullPageView } from 'core/page';
import CreateSubscription from './create';
import SubscriptionDetail from './detail';
import SubscriptionCertificatesList from './subscriptionCertificates/list';
import EditFinalSubscriptions from './subscriptionCertificates/editFinalSubscriptions';
import SubscriptionDistribute from './distribute';


const OwnerSubscriptionsNavigation = ({ companyId, initialCurrency }) => {
  const indexPath = window.location.pathname.match(/.*?subscriptions\//)[0];
  return (
    <FullPageView>
      <Router basename={indexPath}>
        <Routes>
          <Route path="/">
            <Route
              index
              element={(
                <SubscriptionsList
                  fetchDataUrl={`/api/v1/company/${companyId}/subscriptions/`}
                  leftUrl={<BackButton href={`/company/${companyId}/liabilities/equity/`} />}
                  actionsList={(url) => [{
                    url: `${url}create/`,
                    icon: 'plus',
                    label: gettext('Add'),
                  }]}
                />
          )}
            />
            <Route
              path="create/"
              element={<CreateSubscription companyId={companyId} initialCurrency={initialCurrency} />}
            />
            <Route
              path=":subscriptionId/subscription-certificates/"
              element={<SubscriptionCertificatesList companyId={companyId} />}
            />
            <Route
              path=":subscriptionId/subscription-certificates/edit-final-subscriptions/"
              element={<EditFinalSubscriptions />}
            />
            <Route path=":subscriptionId/distribute/" element={<SubscriptionDistribute />} />
            <Route path=":subscriptionId/*" element={<SubscriptionDetail />} />
          </Route>
        </Routes>
      </Router>
    </FullPageView>
  );
};

OwnerSubscriptionsNavigation.defaultProps = {
};

OwnerSubscriptionsNavigation.propTypes = {
  companyId: PropTypes.string.isRequired,
  initialCurrency: PropTypes.string.isRequired,
};

export default OwnerSubscriptionsNavigation;
