import { CertificateType, CertificateTypeStatus } from '@trustwise/design-system';

import { Model } from 'core/model';

export class CertificateTypeListModel extends Model implements CertificateType {
  id: number;
  name: string;
  description: string;
  address: string;
  status: CertificateTypeStatus;
}
