import { capitalize } from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';

import { ObjectTypesList } from '@trustwise/design-system';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import { getUrl } from 'utils/urls';

const RegisteredObjectsTypesListView = () => {
  const navigate = useNavigate();

  const { catalogType } = useParams();

  return (
    <PageContent
      fullscreen
      pageHeading={gettext(capitalize(catalogType))}
      headerLeft={<BackButton href={getUrl()} />}
    >
      <ObjectTypesList
        catalogType={catalogType}
        onTileClick={(objectType) => navigate(`${objectType}/`)}
        // @todo pass enabled and included types once we have more than 1
      />
    </PageContent>
  );
};

export default RegisteredObjectsTypesListView;
