import { Component } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import Loader from '../../common/loader';
import DocumentUploadForm from '../../documents';

class ConfirmOffchainTransfer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
    };
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  handleFormChange = (val, attr) => {
    const { form } = this.props;
    form.setProperty(attr, val);
  };

  handleSubmit = (e) => {
    const { form } = this.props;
    e.preventDefault();
    form.submit((newValue) => this.setState({ isSubmitting: newValue }));
  };

  render() {
    const { form } = this.props;
    const { isSubmitting } = this.state;

    if (!form) return null;

    return (
      <Loader isLoading={form.isLoading}>
        <form onSubmit={this.handleSubmit}>
          <DocumentUploadForm
            showSaveDocumentButton={false}
            form={form.document}
            isRequired={false}
          />
          <button
            type="submit"
            id="button"
            className="button"
            disabled={isSubmitting}
          >
            {gettext('Submit')}
          </button>
        </form>
      </Loader>
    );
  }
}

ConfirmOffchainTransfer.defaultProps = {
  form: null,
};

ConfirmOffchainTransfer.propTypes = {
  form: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

export default observer(ConfirmOffchainTransfer);
