import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';

import { Formik, ErrorMessage, Form } from 'formik';
import * as Yup from 'yup';
import { parseErrorResponse } from 'core/forms/utils';
import { SubmitButton, TextField } from 'core/forms/fields';
import axios from 'core/axios';
import FormErrors from '../components/common/formikErrors';
import FlashMessage from '../components/generic/flashMessage';


const SaveAsTemplateForm = ({ eventId, companyId, eventSubject, actionType }) => {
  const onSubmit = (values, actions) => {
    const data = {
      subject: values.subject,
    };
    const apiUrl = actionType === 'create_from_event' ?
      `/api/v1/company/${companyId}/meetings/${eventId}/save-as-template/`
      : `/api/v1/company/${companyId}/templates/${eventId}/copy/`;
    axios.post(apiUrl, data)
      .then(
        ({ data: { flash_message_content: flashMessageContent } }) => {
          if (flashMessageContent) {
            const root = createRoot(document.getElementById('flash-messages-wrapper'));
            root.render(
              <FlashMessage
                messageType="flash-message success"
                message={flashMessageContent}
              />,
            );
          }
        },
        ({ response: { data: errors } }) => { parseErrorResponse(errors, actions.setFieldError); },
      )
      .catch(console.error)
      .finally(() => { actions.setSubmitting(false); });
  };

  return (
    <Formik
      initialValues={{
        nonFieldErrors: '',
        subject: eventSubject,
      }}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={Yup.object({
        subject: Yup.string()
          .max(128, gettext('Line is too long'))
          .required(gettext('Required')),
      })}
    >
      {({ isSubmitting }) => (
        <Form>
          <ErrorMessage component={FormErrors} name="nonFieldErrors" />
          <TextField
            name="subject"
            label={gettext('Subject')}
          />
          <SubmitButton data-close="" disabled={isSubmitting}>{gettext('Save')}</SubmitButton>
        </Form>
      )}
    </Formik>
  );
};

SaveAsTemplateForm.defaultProps = {
  eventSubject: '',
};

SaveAsTemplateForm.propTypes = {
  eventId: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  eventSubject: PropTypes.string,
  actionType: PropTypes.string.isRequired,
};

export default SaveAsTemplateForm;
