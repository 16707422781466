export const MarketTypes = {
  PRIVATE_EQUITY_BULLETIN_BOARD: 'private_equity_bulletin_board',
  ARTWORKS: 'artworks',
  INSURANCES: 'insurances',
  FX_RATES: 'fx_rates',
  PRIVATE_EQUITY_PRIMARY_MARKET: 'private_equity_primary_market',
};

export const ListingsPageTitles = {
  [MarketTypes.PRIVATE_EQUITY_BULLETIN_BOARD]: gettext('Private Equity Bulletin Board'),
  [MarketTypes.ARTWORKS]: gettext('Artworks'),
  [MarketTypes.INSURANCES]: gettext('Insurances'),
  [MarketTypes.FX_RATES]: gettext('FX rates'),
  [MarketTypes.PRIVATE_EQUITY_PRIMARY_MARKET]: gettext('Primary market'),
};

export const TEMP_LISTINGS_PAGE_SIZE = 1000;

export const LISTINGS_PAGE_SIZE = 20;

export const LIMIT_ORDER_SELL = 'LOS';
export const OFF_CHAIN = 'off_chain';
