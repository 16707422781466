import { getGlobalContext } from 'core/globals';
import { redirectTo } from 'core/utils';

const onBannerClick = (bannerId) => {
  const { custodian: { active: custodianActive } } = getGlobalContext();

  const redirectUrl = {
    add_contact: `${custodianActive ? '' : '/account'}/contacts/my-contacts/#add-contacts-modal-open`,
    register_company: '/registration/company/',
    add_signatory: '/signatories/',
  }[bannerId];

  return redirectTo(redirectUrl);
};

const companyDefaultActions = ['add_contact', 'add_signatory'];
const personDefaultActions = ['add_contact', 'register_company'];
const managedAccountDefaultActions = ['add_contact'];

export {
  onBannerClick,
  companyDefaultActions,
  personDefaultActions,
  managedAccountDefaultActions,
};
