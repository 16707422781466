import { useState } from 'react';
import PropTypes from 'prop-types';

import { SkeletonItem } from '@trustwise/design-system';
import { EmptyContent } from 'core/page';
import { emptyStateIcons, chevronDown, chevronUp } from 'core/svgs';
import { formatDateWithWeekday } from 'utils/date';
import AccountAvatarRowPart from 'contacts/parts/accountAvatar';
import { actionRequired, completed, inProgress, tabOptions } from './const';
import InboxItemActions from './parts/InboxItemActions';


const InboxItemsList = ({ state, dispatch, activeTab }) => {
  const { inboxItems } = state;
  const [expandedId, setExpandedId] = useState(0);
  const isExpanded = (itemId) => (expandedId === itemId);


  if (!inboxItems) {
    return [1, 2, 3].map((item) => (<SkeletonItem largerPadding key={item} />));
  }

  return (
    inboxItems.length > 0 ? (
      <ul>
        {inboxItems.map((inboxItem) => (
          <li
            style={{ position: 'relative' }}
            key={inboxItem.itemId}
            className={`widget-content-row ${
              isExpanded(inboxItem.itemId)
                ? 'widget-row-expanded' : ''
            } ${inboxItem.notSeen && 'inbox-not-seen'}`}
          >
            <div className="widget-row-avatar"><AccountAvatarRowPart legalEntity={inboxItem.avatar} /></div>
            <div className="inbox-content">
              <div className="widget-row-title">{inboxItem.title}</div>
              <div className="widget-row-main-content">{inboxItem.content}</div>
              {inboxItem.actionContext && (
                <InboxItemActions
                  inboxItem={inboxItem}
                  dispatch={dispatch}
                  isWidget
                />
              )}
            </div>
            <div className="widget-left-column">
              <div>{formatDateWithWeekday(inboxItem.date)}</div>
              <button
                type="button"
                onClick={() => { setExpandedId(expandedId === inboxItem.itemId ? 0 : inboxItem.itemId); }}
              >
                {isExpanded(inboxItem.itemId) ? chevronUp : chevronDown}
              </button>
            </div>
          </li>
        ))}
      </ul>
    ) : (
      <EmptyContent
        iconName={emptyStateIcons.inbox}
        text={tabOptions[activeTab].emptyContent}
      />
    )
  );
};

InboxItemsList.defaultProps = {
  activeTab: actionRequired,
};

InboxItemsList.propTypes = {
  state: PropTypes.shape().isRequired,
  activeTab: PropTypes.oneOf([actionRequired, completed, inProgress]),
  dispatch: PropTypes.func.isRequired,
};

export default InboxItemsList;
