import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { DataSetView } from '@trustwise/design-system';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import { SimpleDocumentModel } from 'media/models';
import { IFileModel } from 'media/interfaces';
import { getDataEntries, onDataEntryFileDownload, onReportDownload, onSchemaEntryFilesOpen } from '../actions';
import { useDataSetQuery } from '../queries';

export const DataSetDetailsView = () => {
  const navigate = useNavigate();
  const { dataSetId } = useParams();
  const { state } = useLocation();

  if (dataSetId === undefined) {
    throw new Error('Url param for "dataSetId" is not provided.');
  }

  const { data: dataEntries } = useQuery({
    queryKey: ['dataEntries', dataSetId],
    queryFn: getDataEntries,
  });

  const { data: dataSet } = useDataSetQuery(dataSetId);

  return (
    <PageContent
      fullscreen
      pageHeading={dataSet?.name || ''}
      headerLeft={<BackButton href="/" useRouterLink state={state} />}
    >
      <DataSetView
        {...dataSet?.toObject()}
        schema={dataSet?.dataRoom?.schema}
        dataEntries={dataEntries}
        onSubmitVerdictNavigate={() => navigate('verdict/', { state })}
        onFileDownload={(dataEntryId, file) => (
          onDataEntryFileDownload(dataEntryId, file as unknown as IFileModel, dataSetId)
        )}
        onReportDownload={(fileId) => (
          onReportDownload(fileId, dataSetId, dataSet?._verifierReports as SimpleDocumentModel[])
        )}
        onSchemaFilesOpen={(dataEntryId, fieldKey) => onSchemaEntryFilesOpen(dataEntryId, fieldKey, dataSetId)}
      />
    </PageContent>
  );
};
