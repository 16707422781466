export const industryOptions = [
  { id: 'agr', label: gettext('Agriculture') },
  { id: 'ade', label: gettext('Arts & Design') },
  { id: 'con', label: gettext('Construction') },
  { id: 'cnslt', label: gettext('Consulting') },
  { id: 'cos', label: gettext('Corporate Services') },
  { id: 'edu', label: gettext('Education') },
  { id: 'fin', label: gettext('Finance') },
  { id: 'foo', label: gettext('Foods & Beverages') },
  { id: 'ga', label: gettext('Gastronomy') },
  { id: 'go', label: gettext('Goods') },
  { id: 'gpus', label: gettext('Government & Public Services') },
  { id: 'hm', label: gettext('Health & Medical') },
  { id: 'it', label: gettext('IT') },
  { id: 'le', label: gettext('Legal') },
  { id: 'man', label: gettext('Manufacturing') },
  { id: 'med', label: gettext('Media') },
  { id: 'rea', label: gettext('Real Estate') },
  { id: 'tec', label: gettext('Technology') },
  { id: 'trm', label: gettext('Transport & Mobility') },
  { id: 'ngo', label: gettext('NGOs') },
  { id: 'tre', label: gettext('Tourism & Recreation') },
  { id: 'oth', label: gettext('Other') },
];

export default industryOptions;
