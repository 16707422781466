/* eslint-disable no-redeclare */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable consistent-return */
/**
 * Sortable TextArea Enhancement
 *
 * @type {Object}
 */
const Sortable = require('sortablejs/Sortable.min');

export const sortableTextArea = {
  init(args) {
    if (!this.setup(args)) {
      return false;
    }
    this.createSortable(args);
    this.registerFormEvents();
    this.registerKeyboardEvents();
    this.dom.form_submit.attr('disabled', false);
    return true;
  },
  setup(args) {
    this.args = args;
    this.conf = {
      handleSelector: '.sortable-handle',
      rowInputName: 'voting_option_row',
      inputSelector: null,
    };
    this.conf.inputSelector = `li input[name^="${this.conf.rowInputName}"]`;
    this.dom = {
      textarea: $(args.textarea),
      list: $(args.list),
      newInput: $(args.newInput),
      form: null,
      inputs: [],
    };
    if (!this.dom.list.length) {
      return false;
    }
    this.dom.form = this.dom.list.closest('form');
    this.dom.form_submit = $('input[type=submit]', this.dom.form);
    this.dom.inputs = this.getListInputs();
    return true;
  },
  getListInputs() {
    return $(this.conf.inputSelector, this.dom.list);
  },
  createSortable(args) {
    this.sortable = Sortable.create(
      args.list,
      { handle: this.conf.handleSelector, onSort: (_e) => { this.update(); } },
    );
  },
  registerFormEvents() {
    const self = this;
    // Update textarea value when inputs change
    this.dom.form.on('change', this.conf.inputSelector, (e) => {
      const t = $(e.target);
      t.val(t.val().trim());
      self.update();
    });
    // Remove empty input row on blur
    this.dom.form.on('blur', this.conf.inputSelector, (e) => {
      const t = $(e.target);
      if (t.val().trim() === '') {
        t.closest('li').slideUp('fast');
      }
    });
    // Blur a focused new input on form submit and prevent double submission
    this.dom.form.on('submit', this.dom.newInput, (_e) => {
      if (self.dom.newInput.is(':focus')) return false;
      this.dom.form_submit.attr('disabled', true);
    });
    // Add row on new input change and clear out the input
    this.dom.newInput.change((e) => {
      e.preventDefault();
      const t = $(e.target);
      self.addRow(t.val());
      t.val('').click();
    });
  },
  registerKeyboardEvents() {
    const self = this;
    let counter = 2;
    // eslint-disable-next-line consistent-return
    this.dom.form.on('keyup', this.conf.inputSelector, (e) => {
      const t = $(e.target);
      // eslint-disable-next-line default-case
      switch (e.keyCode) {
        case 8: {
          // Backspace
          counter = t.val() ? 2 : counter - 1;
          if (counter) {
            return true;
          }
          let focusTo = self.getPrevInput(t);
          if (!focusTo) { focusTo = self.getNextInput(t); }
          if (!focusTo) { t.blur(); } else { focusTo.focus(); }
          break;
        }
        case 13: {
          // Enter
          e.preventDefault();
          t.blur();
          return false;
        }
        case 38:
          // Up
          e.preventDefault();
          var focusTo = self.getPrevInput(t);
          focusTo && focusTo.focus();
          break;
        case 40:
          // Down
          e.preventDefault();
          var focusTo = self.getNextInput(t) || self.dom.newInput;
          focusTo && focusTo.focus();
          break;
      }
    });
    this.dom.form.on('keyup', this.args.newInput, (e) => {
      // eslint-disable-next-line default-case
      switch (e.keyCode) {
        case 38:
          // Up
          e.preventDefault();
          $(this.conf.inputSelector).last().focus();
          break;
      }
    });
  },
  getPrevInput(input) {
    const prev = $('input', input.closest('li').prev('li'));
    return prev.length ? prev : false;
  },
  getNextInput(input) {
    const next = $('input', input.closest('li').next('li'));
    return next.length ? next : false;
  },
  getPrevOrNextInput(input) {
    return this.getPrevInput(input) || this.getNextInput(input);
  },
  update() {
    this.dom.textarea.text(this.inputsToText(this.getListInputs()));
  },
  inputsToText(inputs) {
    const rows = [];
    inputs.each((index, input) => {
      const txt = $(input).val().trim();
      if (txt) rows.push(txt);
    });
    return rows.join('\n');
  },
  addRow(text) {
    const lastRow = this.getLastRow();
    const lastRowIndex = lastRow.length ? this.getRowIndex(lastRow) : -1;
    const newRowIndex = lastRowIndex * 1 + 1;
    const newInput = $('<input/>', {
      type: 'text',
      name: `voting_option_row[${newRowIndex}]`,
      value: text.trim(),
    });
    const newRow = $('<li/>', {
      class: 'row-flex full-width',
      html: '<i class="fa icon fa-sort secondary sortable-handle padded" aria-hidden="true"></i>',
    });
    newRow.prepend(newInput);
    if (lastRow.length) {
      newRow.insertAfter(lastRow);
    } else {
      newRow.prependTo(this.dom.list);
    }
    this.update();
  },
  getLastRow() {
    return $('li', this.dom.list).last();
  },
  getRowIndex(row) {
    return $('input', row).attr('name').match(/\[(\d)\]/)[1];
  },
};

export default sortableTextArea;
