import PropTypes from 'prop-types';

import { countryOptions } from 'core/options';
import { ReactSelectField } from './select';


/**
 * A select field to choose a country.
 *
 * TODO improve response error handling
 */
const CountrySelectField = ({ id, label, name, ...props }) => (
  <ReactSelectField
    id={id}
    label={label}
    name={name}
    options={countryOptions}
    getOptionValue={(option) => option.code}
    getOptionLabel={(option) => option.label}
    isSearchable
    {...props}
  />
);

CountrySelectField.defaultProps = {
  id: '',
  label: gettext('Country'),
};

CountrySelectField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default CountrySelectField;
