import { useEffect, useState } from 'react';
import { camelCase } from 'lodash';

import { CbdcSmartBanknotesWidget } from '@trustwise/design-system';
import axios from 'axios';
import { getApiUrl } from 'utils/urls';
import { convertKeysCase } from 'core/utils';

class SmartBanknotesModel {
  constructor({ blockedForBanknotes, currencyCode, banknotes }) {
    this.blockedForBanknotes = Number(blockedForBanknotes);
    this.currencyCode = currencyCode;
    this.banknotes = banknotes.map(({ denomination, issued, redeemed }) => ({
      denomination: Number(denomination),
      issued: Number(issued),
      redeemed: Number(redeemed),
    }));
  }
}

const SmartBanknotesWidget = () => {
  const [banknotesData, setBanknotesData] = useState();

  useEffect(() => {
    axios
      .get(getApiUrl('/payment-tokens/issuer/cbdc/banknotes/'))
      .then(({ data }) => {
        setBanknotesData(new SmartBanknotesModel(convertKeysCase(data, camelCase)));
      })
      .catch(console.error);
  }, []);

  return (
    <CbdcSmartBanknotesWidget {...banknotesData} />
  );
};

export default SmartBanknotesWidget;
