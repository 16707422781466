/**
 * Creating of issuer registry.
 */

import { Formik, ErrorMessage, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'core/axios';
import { getApiUrl, getUrl } from 'utils/urls';
import { parseErrorResponse } from 'core/forms/utils';
import { handleTransactionCreation } from 'core/page/transactionSigning/utils';
import FormErrors from '../../../components/common/formikErrors';
import { TextField, SubmitButton } from '../../../core/forms/fields';


const IssuerRegistryCreate = () => {
  const onSubmit = (values, actions) => {
    axios.post(getApiUrl('/certificates/issuer-registries/'), { name: values.name })
      .then(
        ({ data: { tx_hash: txHash } }) => {
          handleTransactionCreation(txHash, getUrl('/certificates/issuer-registries/'));
        },
        ({ response: { data: errors } }) => { parseErrorResponse(errors, actions.setFieldError); },
      )
      .catch(console.error)
      .finally(() => { actions.setSubmitting(false); });
  };

  return (
    <Formik
      initialValues={{
        nonFieldErrors: '',
        name: '',
      }}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={Yup.object({
        name: Yup.string()
          .max(128, gettext('Line is too long'))
          .required(gettext('Required')),
      })}
    >
      {({ isSubmitting }) => (
        <Form>
          <ErrorMessage component={FormErrors} name="nonFieldErrors" />
          <TextField name="name" label={gettext('Registry name:')} />
          <SubmitButton disabled={isSubmitting}>{gettext('Create')}</SubmitButton>
        </Form>
      )}
    </Formik>
  );
};


export default IssuerRegistryCreate;
