import { extendObservable } from 'mobx';
import axios from 'core/axios';
import { handleTransactionCreation } from 'core/page/transactionSigning/utils';
import DocumentForm from '../../documents/document';
import BaseForm from '../../form';


const businessTransactionTypePath = {
  restricted_transfer: 'confirm-offchain-transfer-restricted',
  unrestricted_transfer: 'confirm-offchain-transfer-unrestricted',
};


export default class ConfirmOffchainTransferForm extends BaseForm {
  constructor({
    companyId = null,
    businessTransactionId = null,
    businessTransactionType = null,
  }) {
    super();
    extendObservable(this, {
      companyId,
      businessTransactionId,
      businessTransactionType,
      document: new DocumentForm({}),
    });

    this.submit = this.submit.bind(this);
  }

  static get formFields() {
    return ['companyId', 'businessTransactionId'];
  }

  static get fieldMapping() {
    return [
      { name: 'companyId', serverName: 'company_id' },
      { name: 'businessTransactionId', serverName: 'btrx_id' },
    ];
  }

  static get namedNestedForms() {
    return ['document'];
  }

  get url() {
    if (!this.companyId) {
      return `/api/v1/transfers/${this.businessTransactionId}/${this.path}/`;
    }
    return `/api/v1/company/${this.companyId}/transfers/${this.businessTransactionId}/${this.path}/`;
  }

  get path() {
    return businessTransactionTypePath[this.businessTransactionType];
  }

  get formData() {
    const data = super.formData;

    const thisFormData = new FormData();

    Object.keys(data).forEach((k) => thisFormData.append(k, data[k]));

    if (this.document.subject || this.document.documentFiles.length) {
      // eslint-disable-next-line guard-for-in, no-restricted-syntax
      for (const pair of this.document.formData.entries()) {
        thisFormData.append(`document[${pair[0]}]`, pair[1]);
      }
    }

    return thisFormData;
  }

  submit(setIsSubmitting) {
    this.cleanErrors();
    setIsSubmitting(true);

    return axios.post(this.url, this.formData)
      .then(({ data: { redirect_url: redirectUrl, tx_hash: txHash } }) => {
        handleTransactionCreation(txHash, redirectUrl);
        setIsSubmitting(false);
      })
      .catch(({ response: { data: errors, status } }) => {
        if (status < 500) {
          this.setResponseErrors(errors);
          setIsSubmitting(false);
        }
      });
  }
}
