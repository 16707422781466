/* eslint-disable no-nested-ternary */
/**
 * List of company multisig transactions.
 */

import PropTypes from 'prop-types';
import MultisigTransactionsList from './transactionsList';


const CompanyMultisigTransactionsList = ({ companyId, itemNotificationSource }) => (
  <MultisigTransactionsList
    fetchUrl={`/api/v1/company/${companyId}/bc-transactions/`}
    itemNotificationSource={itemNotificationSource}
  />
);


CompanyMultisigTransactionsList.propTypes = {
  companyId: PropTypes.string.isRequired,
  itemNotificationSource: PropTypes.string.isRequired,
};

export default CompanyMultisigTransactionsList;
