import { DataSetVerificationDecision } from '@trustwise/design-system';
import { Model } from 'core/model';
import { SimpleLegalEntityModel } from 'contacts/models/legalEntity';
import { LegalEntityResponseProps } from 'contacts/interfaces/response';
import { FileModel, SimpleDocumentModel } from 'media/models';
import { ExternalAccessDataRoom, GrantItemResponse } from 'dataRooms/externalAccess/types';
import { GrantModel } from 'dataRooms/externalAccess/models';
import { getReportsFiles } from 'dataRooms/utils';
import { DocumentResponseProps } from 'media/interfaces/response';
import { VerifierDataRoomModel } from './dataRoom';

interface BaseDataSetDetails {
  /**
   * Data set ID
   */
  id: number;
  /**
   * Data set name
   */
  name: string;
  /**
   * Public comment from the verifier
   */
  publicData?: string;
  /**
   * Private comment from the verifier
   */
  privateData?: string;
  /**
   * Data set verification decision
   */
  decision?: DataSetVerificationDecision;
}

interface DataSetDetailsProps extends BaseDataSetDetails {
  /**
   * Date on which data set was created
   */
  createdAt: string;
  /**
   * Data set reviewer (person or company signatory)
   */
  reviewedBy?: LegalEntityResponseProps;
  /**
   * Data set reviewer (company)
   */
  reviewedByCompany?: LegalEntityResponseProps;
  /**
   * Reports attached to the verification
   */
  verifierReports?: DocumentResponseProps[];
  /**
   * Date and time on which the data set was reviewed
   */
  reviewedAt?: string;
  /**
   * Granted token
   */
  grantedToken?: GrantItemResponse;
  /**
   * Data room details
   */
  dataRoom: ExternalAccessDataRoom;
}

interface IDataSetDetails extends BaseDataSetDetails {
  /**
   * Data room details
   */
  dataRoom: VerifierDataRoomModel;
  /**
   * Date on which data set was created
   */
  createdAt: Date;
  /**
   * Data set reviewer (person or company signatory)
   */
  reviewedBy?: SimpleLegalEntityModel;
  /**
   * Data set reviewer (company)
   */
  reviewedByCompany?: SimpleLegalEntityModel;
  /**
   * Reports attached to the verification
   */
  verifierReports?: FileModel[];
  /**
   * Date and time on which the data set was reviewed
   */
  reviewedAt?: Date;
  /**
   * Granted token
   */
  grantedToken?: GrantModel;
}

export class DataSetDetailsModel extends Model implements IDataSetDetails {
  id: number;
  name: string;
  createdAt: Date;
  dataRoom: VerifierDataRoomModel;
  decision?: DataSetVerificationDecision;
  privateData?: string;
  publicData?: string;
  _verifierReports?: SimpleDocumentModel[];
  reviewedAt?: Date;
  reviewedBy?: SimpleLegalEntityModel;
  reviewedByCompany?: SimpleLegalEntityModel;
  grantedToken?: GrantModel;

  constructor({
    createdAt,
    grantedToken,
    reviewedBy,
    reviewedByCompany,
    verifierReports,
    reviewedAt,
    dataRoom,
    ...restProps
  }: DataSetDetailsProps) {
    super(restProps);
    this.createdAt = new Date(createdAt);
    this.grantedToken = GrantModel.fromResponse(grantedToken);
    this.reviewedBy = SimpleLegalEntityModel.fromResponse(reviewedBy);
    this.reviewedByCompany = SimpleLegalEntityModel.fromResponse(reviewedByCompany);
    this._verifierReports = verifierReports ? SimpleDocumentModel.fromArrayResponse(verifierReports) : undefined;
    this.reviewedAt = reviewedAt ? new Date(reviewedAt) : undefined;
    this.dataRoom = VerifierDataRoomModel.fromResponse(dataRoom);
  }

  get verifierReports() {
    return getReportsFiles(this._verifierReports);
  }

  get verdict() {
    if (!this.decision || !this.reviewedAt) {
      return undefined;
    }
    return {
      decision: this.decision,
      publicData: this.publicData,
      privateData: this.privateData,
      grant: this.grantedToken,
      reviewedAt: this.reviewedAt,
      verifierReports: this.verifierReports,
    };
  }
}
