// Axios Settings
import axios from 'axios';
import { setErrorResponseErrorCode } from 'core/hooks/useResponseStatus';

/**
 * How to use axiosInstance:
 * All request methods are available through aliases, e.g. `axiosInstance.get(url)`, `axiosInstance.post(url, data)` etc.
 * For the cases where it's not possible to use aliases the following form is available: `axiosInstance({url, data, method})`
 *
 * Axios instance can be extended with more global settings.
 * If any of the specified defaults need to be overridden for some specific case it can be done as follows:
 * e.g. `axiosInstance.defaults.xsrfCookieName` = "specificCsrfCookieName"
 * @see https://axios-http.com/docs/config_defaults
 *
 * Any custom headers can be added to axios instance as well, e.g. axios.create({ headers: {'X-Custom-Header': 'custom-value'} })
 * All available properties to configure:
 * @see https://axios-http.com/docs/req_config
 *
 */

const apiKey = '__api_key__';

const params = {
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
};

if (window.localStorage.getItem(apiKey)) {
  params.headers = {
    Authorization: `Token ${window.localStorage.getItem(apiKey)}`,
  };
}

const axiosInstance = axios.create(params);
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    if (!response) { return Promise.reject(error); }
    const { status } = response;
    if (status === 401) {
      window.location = `/login/?next=${window.location.pathname}`;
    }
    !!setErrorResponseErrorCode && setErrorResponseErrorCode(status);
    return Promise.reject(error);
  },
);

export default axiosInstance;
