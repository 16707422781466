import { useQuery } from '@tanstack/react-query';

import { MultisigTransactionsListTableView } from '@trustwise/design-system';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import { getRouterLinkInteraction } from 'core/utils';
import { getTransactions } from './actions';

export const MultisigTransactionsListView = () => {
  // @todo check how pagination and infinite scroll works in react query
  const { data: transactions } = useQuery({
    queryKey: ['transactions'],
    queryFn: () => getTransactions(['pending', 'executed']),
  });

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Transactions Approvals & History')}
      headerLeft={<BackButton href="/" useRouterLink />}
    >
      <MultisigTransactionsListTableView
        transactions={transactions}
        onTransactionClick={(id: number) => getRouterLinkInteraction(`${id}/`)}
      />
    </PageContent>
  );
};
