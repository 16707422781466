/* eslint-disable max-len */
const registriesIcons = {
  artworks_curator: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_17_13573)">
        <circle opacity="0.1" cx="16" cy="16" r="16" fill="url(#paint0_linear_17_13573)" />
        <path d="M36 12.4L18 2.00001V28.8L36 39.2V12.4Z" fill="#D9E6F8" />
        <path d="M33 14.08L15 3.68V30.48L33 40.88V14.08Z" fill="#B2CBF1" />
        <path d="M29.5 15.8L11.5 5.39997V32.2L29.5 42.6V15.8Z" fill="#92B5EA" />
        <path d="M25 18.16L7 7.76001V36.56L25 46.96V18.16Z" fill="url(#paint1_linear_17_13573)" />
        <path d="M25 18.16L26.2 17.52V44.32L25 44.96V18.16Z" fill="#1B4AA7" />
        <path d="M25 18.16L26.2 17.52L8.2 7.11766L7 7.75766L25 18.16Z" fill="#67A5EB" />
        <path opacity="0.16" d="M7 34.5577L20.6 22.1577L25 29.7577V44.9577L7 36.75V34.5577Z" fill="white" />
        <path opacity="0.07" d="M7.0002 34.5577L16.2002 26.1577L11.4002 15.3577L7.0002 19.3577V34.5577Z" fill="white" />
        <ellipse opacity="0.2" cx="1.36264" cy="2.27107" rx="1.36264" ry="2.27107" transform="matrix(-0.998652 0.0518975 0.0518975 0.998652 18.5215 16.5577)" fill="white" />
      </g>
      <defs>
        <linearGradient id="paint0_linear_17_13573" x1="4.07447" y1="7.34745" x2="24.9998" y2="32.1178" gradientUnits="userSpaceOnUse">
          <stop stopColor="#6570D6" />
          <stop offset="0.614583" stopColor="#375EAC" />
        </linearGradient>
        <linearGradient id="paint1_linear_17_13573" x1="9.2784" y1="39.3486" x2="31.4698" y2="27.9001" gradientUnits="userSpaceOnUse">
          <stop stopColor="#3D96E8" />
          <stop offset="0.614583" stopColor="#2759B9" />
        </linearGradient>
        <clipPath id="clip0_17_13573">
          <rect width="32" height="32" rx="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  certificates_issuers: (
    <svg width="36" height="38" viewBox="0 0 36 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity="0.1" cx="18" cy="16" r="16" fill="url(#paint0_linear_17_13505)" />
      <circle opacity="0.1" cx="18" cy="15.5" r="12.5" fill="url(#paint1_linear_17_13505)" />
      <path d="M14 18.1666H22V30.7L18 28.0142L14 30.7V18.1666Z" fill="url(#paint2_radial_17_13505)" />
      <g filter="url(#filter0_d_17_13505)">
        <path fillRule="evenodd" clipRule="evenodd" d="M8.76124 12.8162C8.99242 13.3743 8.86461 14.0168 8.43745 14.4439C7.85421 15.0272 7.85417 15.9728 8.43741 16.5561C8.86454 16.9832 8.9923 17.6256 8.76114 18.1837C8.44546 18.9458 8.80745 19.8196 9.56957 20.1353C10.1276 20.3665 10.4916 20.9111 10.4916 21.5151C10.4916 22.3399 11.1603 23.0086 11.9851 23.0086C12.5892 23.0086 13.1338 23.3725 13.3649 23.9305C13.6806 24.6926 14.5542 25.0545 15.3163 24.7388C15.8744 24.5076 16.5168 24.6355 16.9439 25.0626C17.5272 25.6458 18.4728 25.6459 19.0561 25.0627C19.4832 24.6355 20.1256 24.5078 20.6837 24.7389C21.4457 25.0546 22.3194 24.6927 22.6351 23.9306C22.8662 23.3725 23.4109 23.0086 24.015 23.0086C24.8399 23.0086 25.5087 22.3398 25.5087 21.5149C25.5087 20.9109 25.8725 20.3663 26.4306 20.1351C27.1927 19.8194 27.5546 18.9458 27.2389 18.1837C27.0077 17.6256 27.1355 16.9832 27.5626 16.5561C28.1459 15.9728 28.1458 15.0271 27.5626 14.4439C27.1354 14.0167 27.0076 13.3743 27.2388 12.8162C27.5544 12.0542 27.1926 11.1806 26.4306 10.865C25.8725 10.6338 25.5087 10.0892 25.5087 9.48519C25.5087 8.66032 24.84 7.99151 24.0151 7.99151C23.411 7.99151 22.8664 7.6276 22.6352 7.06949C22.3196 6.3074 21.4458 5.94549 20.6837 6.26116C20.1257 6.49233 19.4833 6.36455 19.0561 5.93741C18.4729 5.35416 17.5272 5.35421 16.9439 5.93747C16.5168 6.36463 15.8743 6.49244 15.3162 6.26126C14.5541 5.94561 13.6804 6.30751 13.3648 7.06957C13.1336 7.62762 12.589 7.99151 11.985 7.99151C11.1602 7.99151 10.4916 8.66017 10.4916 9.48496C10.4916 10.089 10.1277 10.6336 9.5696 10.8648C8.80752 11.1804 8.44558 12.0542 8.76124 12.8162Z" fill="url(#paint3_linear_17_13505)" />
      </g>
      <circle opacity="0.5" cx="18" cy="15.5" r="6.5" transform="rotate(-180 18 15.5)" fill="url(#paint4_linear_17_13505)" />
      <circle opacity="0.5" cx="18" cy="15.5" r="6.15597" transform="rotate(-180 18 15.5)" stroke="url(#paint5_linear_17_13505)" strokeWidth="0.688067" />
      <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M17.1285 16.527L15.9179 15.4163L15.4004 15.8878L17.1285 17.4733L20.8837 14.0281L20.3698 13.5566L17.1285 16.527Z" fill="white" />
      <defs>
        <filter id="filter0_d_17_13505" x="0.0317917" y="1.5159" width="35.9364" height="35.9365" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="3.9841" />
          <feGaussianBlur stdDeviation="3.9841" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_17_13505" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_17_13505" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_17_13505" x1="6.07447" y1="7.34745" x2="26.9998" y2="32.1178" gradientUnits="userSpaceOnUse">
          <stop stopColor="#6570D6" />
          <stop offset="0.614583" stopColor="#375EAC" />
        </linearGradient>
        <linearGradient id="paint1_linear_17_13505" x1="8.68318" y1="8.7402" x2="25.0311" y2="28.092" gradientUnits="userSpaceOnUse">
          <stop stopColor="#6570D6" />
          <stop offset="0.614583" stopColor="#375EAC" />
        </linearGradient>
        <radialGradient id="paint2_radial_17_13505" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(17.7446 22.75) rotate(4.79751) scale(8.96759 6.10895)">
          <stop offset="0.0260417" stopColor="#133E90" />
          <stop offset="0.787073" stopColor="#3D96E8" />
        </radialGradient>
        <linearGradient id="paint3_linear_17_13505" x1="10.5465" y1="10.0922" x2="23.6249" y2="25.5736" gradientUnits="userSpaceOnUse">
          <stop stopColor="#3D96E8" />
          <stop offset="0.89111" stopColor="#2759B9" />
        </linearGradient>
        <linearGradient id="paint4_linear_17_13505" x1="13.1553" y1="11.9849" x2="21.6562" y2="22.0478" gradientUnits="userSpaceOnUse">
          <stop stopColor="#3C91E4" />
          <stop offset="0.614583" stopColor="#3366CB" />
        </linearGradient>
        <linearGradient id="paint5_linear_17_13505" x1="13.1553" y1="11.9849" x2="21.6562" y2="22.0478" gradientUnits="userSpaceOnUse">
          <stop stopColor="#3C91E4" />
          <stop offset="0.614583" stopColor="#3366CB" />
        </linearGradient>
      </defs>
    </svg>
  ),
  marketplace: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_524_39360)">
        <circle opacity="0.1" cx="16" cy="16" r="16" fill="url(#paint0_linear_524_39360)" />
        <g filter="url(#filter0_b_524_39360)">
          <path d="M6.76974 23.7369L4.24347 18.8948L-2.07227 37.4211L22.2432 26.2632L21.9276 10.4738L19.4013 4.36856L18.3487 7.52639L17.2961 6.47376L15.6119 12.7895L12.0329 5.84218L6.76974 23.7369Z" fill="url(#paint1_linear_524_39360)" fillOpacity="0.9" />
          <path d="M6.76974 23.7369L4.24347 18.8948L-2.07227 37.4211L22.2432 26.2632L21.9276 10.4738L19.4013 4.36856L18.3487 7.52639L17.2961 6.47376L15.6119 12.7895L12.0329 5.84218L6.76974 23.7369Z" stroke="#00DBA6" strokeWidth="0.8" />
        </g>
        <path d="M24.1334 19.5902L19.9755 17.1897L19.9755 12.3885L24.1334 9.98797L28.2913 12.3885L28.2913 17.1897L24.1334 19.5902Z" fill="#94C9FA" />
        <path d="M24.1331 37.8429L24.1331 14.7898L28.291 12.3893L28.291 35.4424L24.1331 37.8429Z" fill="#1F5CD4" />
        <path d="M24.1328 37.8429L24.1328 14.7898L20.0038 12.3893L20.0038 35.4424L24.1328 37.8429Z" fill="#3F81DD" />
        <path d="M18.1341 27.4357L13.9762 25.0352L13.9762 19.5748L18.1341 17.1742L22.292 19.5748L22.292 25.0352L18.1341 27.4357Z" fill="#94C9FA" />
        <path d="M18.1341 35.4419L18.1341 21.9757L22.292 19.5752L22.292 33.0413L18.1341 35.4419Z" fill="#1F5CD4" />
        <path d="M18.1338 35.4419L18.1338 21.9757L14.0048 19.5752L14.0048 33.0413L18.1338 35.4419Z" fill="#3F81DD" />
        <path d="M12.2659 29.8447L8.10802 27.4441L8.10802 25.9696L12.2659 23.569L16.4238 25.9696L16.4238 27.4442L12.2659 29.8447Z" fill="#94C9FA" />
        <path d="M12.2659 37.12L12.2659 28.371L16.4238 25.9705L16.4238 34.7194L12.2659 37.12Z" fill="#1F5CD4" />
        <path d="M12.2656 37.12L12.2656 28.371L8.13665 25.9705L8.13665 34.7194L12.2656 37.12Z" fill="#3F81DD" />
      </g>
      <defs>
        <filter id="filter0_b_524_39360" x="-11.751" y="-5.77522" width="43.3994" height="52.9479" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="4.5" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_524_39360" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_524_39360" result="shape" />
        </filter>
        <linearGradient id="paint0_linear_524_39360" x1="4.07447" y1="7.34745" x2="24.9998" y2="32.1178" gradientUnits="userSpaceOnUse">
          <stop stopColor="#6570D6" />
          <stop offset="0.614583" stopColor="#375EAC" />
        </linearGradient>
        <linearGradient id="paint1_linear_524_39360" x1="12.2435" y1="37.6317" x2="17.0855" y2="12.5791" gradientUnits="userSpaceOnUse">
          <stop offset="0.49" stopColor="#01E996" />
          <stop offset="1" stopColor="#01B876" />
        </linearGradient>
        <clipPath id="clip0_524_39360">
          <path d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
};

export default registriesIcons;
