import { createRoot } from 'react-dom/client';
import { Provider } from 'mobx-react';
import FallbackComponent from '../components/common/fallback';
import { stores } from '../stores';

export const renderComponent = ({ elementId, component, props }) => {
  const Component = component;
  const contentElement = document.getElementById(elementId);
  const root = createRoot(contentElement);
  root.render(
    <FallbackComponent>
      <Provider {...stores}>
        <Component
          {...props}
        />
      </Provider>
    </FallbackComponent>,
  );
};

export default renderComponent;
