import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { DataVerifierView } from '@trustwise/design-system';
import { getUrl } from 'utils/urls';
import { PageContent } from 'core/page';
import BackButton from 'core/page/parts/backButton';
import { getRouterLinkInteraction } from 'core/utils';
import { getVerifications } from '../actions';
import { DataVerifierNavigationPage } from '../types';
import { DataVerifierNavigationPages } from '../consts';
import { PendingVerificationsModel, ReviewedDataSetModel } from '../models';

export const DataVerificationsListView = () => {
  const { state } = useLocation();
  const [activeTab, setActivePageId] = useState<DataVerifierNavigationPage>(
    state?.lastActiveTab || DataVerifierNavigationPages.PENDING_VERIFICATIONS,
  );

  const { data: reviewedVerifications } = useQuery({
    queryKey: ['verificationsHistory'],
    queryFn: () => getVerifications(false) as Promise<ReviewedDataSetModel[]>,
    enabled: activeTab === DataVerifierNavigationPages.HISTORY,
  });

  const { data: pendingVerifications } = useQuery({
    queryKey: ['pendingVerifications'],
    queryFn: () => getVerifications(true) as Promise<PendingVerificationsModel[]>,
    enabled: activeTab === DataVerifierNavigationPages.PENDING_VERIFICATIONS,
  });

  return (
    <PageContent
      fullscreen
      pageHeading={gettext('Data Verification')}
      headerLeft={<BackButton href={getUrl()} />}
    >
      <DataVerifierView
        activePageId={activeTab}
        reviewed={reviewedVerifications}
        pending={pendingVerifications}
        onDataSetClick={(id: number) => getRouterLinkInteraction(`data-sets/${id}/`, { lastActiveTab: activeTab })}
        onTabSwitch={(id: DataVerifierNavigationPage) => setActivePageId(id)}
      />
    </PageContent>
  );
};
