import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useReducer } from 'react';

import { RegistryDetailView as RegistryDetailViewDS } from '@trustwise/design-system';
import { getApiUrl } from 'utils/urls';
import { PageContent } from 'core/page';
import axios from 'core/axios';
import BackButton from 'core/page/parts/backButton';
import reducer from 'registries/reducers';
import useContacts from 'contacts/hooks';
import { getRouterLinkInteraction } from 'core/utils';
import { onAddNewMember, onMembersSort } from './actions';

const RegistryDetailView = () => {
  const { registryId } = useParams();
  const { onFetch: onContactsFetch } = useContacts(false, { 'entity-type': 'company', 'exclude-custodial': 1 });

  const [state, dispatch] = useReducer(reducer, { registry: {} });
  const { registry, permissions } = state;

  const basePath = `/registries/${registryId}/`;

  useEffect(() => {
    axios.get(getApiUrl(basePath))
      .then(({ data }) => dispatch({ type: 'fetchRegistry', data }))
      .catch(console.error);
  }, [basePath]);

  useEffect(() => {
    axios.get(getApiUrl(`${basePath}permissions/`))
      .then(({ data }) => dispatch({ type: 'fetchPermissions', data }))
      .catch(console.error);
  }, [basePath]);

  const getFilteredContacts = useCallback((searchString) => onContactsFetch(searchString)
    .then((contacts) => contacts.filter((contact) => !registry.members.find((member) => member.id === contact.id)))
    .catch(console.error), [onContactsFetch, registry.members]);

  return (
    <PageContent
      fullscreen
      pageHeading={registry.label || ''}
      headerLeft={<BackButton href="/" useRouterLink />}
    >
      <RegistryDetailViewDS
        members={registry.members}
        onMemberClick={(id) => getRouterLinkInteraction(`members/${id}/`)}
        onSort={(_column, order) => onMembersSort(order, registry.members)}
        permissions={permissions || []}
        addMemberModalActions={{
          onSubmit: (values, actions) => onAddNewMember(values, actions, basePath),
          onContactsFetch: getFilteredContacts,
          onContactSearch: ({ searchString }) => getFilteredContacts(searchString),
        }}
      />
    </PageContent>
  );
};

export default RegistryDetailView;
