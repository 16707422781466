import { snakeCase } from 'lodash';

import { convertKeysCase } from 'core/utils';
import { getApiUrl } from 'utils/urls';
import { signFormTransaction } from 'utils/signTransaction';
import axios from 'core/axios';

export const onTransferInitialize = ({ values, actions, submitPath, navigatePath }) => {
  const { recipient, arbiter, noOfUnits, ...restProps } = values;
  const data = convertKeysCase(restProps, snakeCase);
  const submitData = new FormData();

  if (typeof recipient === 'undefined') {
    throw new Error("Recipient wasn't provided");
  }
  data.recipient_address = recipient.portfolio;

  if (typeof arbiter === 'undefined') {
    throw new Error("Arbiter wasn't provided");
  }
  data.arbiter_id = arbiter.id;

  Object.entries(data).forEach(
    ([key, value]) => submitData.append(key, value),
  );

  submitData.append('amount', noOfUnits);

  signFormTransaction({
    urlPath: submitPath,
    navigatePath,
    data: submitData,
    actions,
  });
};

export const onTransferFeeCalculate = ({ portfolioId, tokenId, amount, pricePerUnit, currency }) => {
  const reqBody = {
    amount,
    pricePerUnit,
    currency,
  };
  return axios.post(getApiUrl(`/assets/transfers/${portfolioId}/${tokenId}/fee`), convertKeysCase(reqBody, snakeCase))
    .then(({ data }) => (`${data.fee} ${data.currency}`))
    .catch(console.error);
};
