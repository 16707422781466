/* Messages list component */
import PropTypes from 'prop-types';
import { getUrl } from 'utils/urls';
import { getGlobalContext } from 'core/globals';


const MessagesList = ({ pageMessages }) => {
  const {
    activeEntity: { status },
    blockchainErrors: {
      noConnection: noBlockchainConnection,
      notSynced: blockchainNotSynced,
    },
  } = getGlobalContext();

  const activeEntityIsCreated = status === 'created';

  return (
    <ul className="messages-list">
      {activeEntityIsCreated && window.location.pathname !== '/identification/' && (
        <a href={getUrl('/identification/')}>
          <li className="flash-message fixed warning">
            {gettext('Please submit verification details for your account. Please, click here to submit the data.')}
          </li>
        </a>
      )}
      {noBlockchainConnection && (
      <li className="error">{gettext('No connection to the blockchain.')}</li>
      )}
      {blockchainNotSynced && (
      <li className="error">
          {gettext('Waiting for data to be updated. You can use the app as usual')}
      </li>
      )}
      {pageMessages}
    </ul>
  );
};
MessagesList.defaultProps = {
  pageMessages: null,
};

MessagesList.propTypes = {
  pageMessages: PropTypes.node,
};

export default MessagesList;
